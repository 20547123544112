import {GlobalHydrateService} from '@services/common/global-hydrate'
import type {AffiliateStatisticsPageProperties} from '@slices/affiliate/statistics/lib/types'
import {store} from '@store/index'
import {api} from '@api/index'
import {DEFAULT_FIRST_PAGE_ITEMS} from '@constants/common'

type InitialHydrationData = NonNullable<
  AffiliateStatisticsPageProperties['hydrationData']
>

export class AffiliateStatisticsService extends GlobalHydrateService<InitialHydrationData> {
  private get state() {
    return store().pages.affiliate.statistics
  }

  public hydrate(initialData: InitialHydrationData) {
    if (initialData.statistics) {
      this.state.setData(initialData.statistics.data)
      this.state.setPaginatorInfo(initialData.statistics.paginatorInfo)
    }
    super.hydrate(initialData)
  }

  public loadMore(): void {
    if (!this.state.data) {
      return
    }

    if (!this.state.paginatorInfo?.hasMorePages) {
      return
    }

    const updatedPage = this.state.page + 1
    this.state.setIsLoading(true)
    api.affiliate
      .getReferralStatistics({
        variables: {
          page: updatedPage,
          first: DEFAULT_FIRST_PAGE_ITEMS,
        },
      })
      .then((statistics) => {
        if (statistics) {
          this.state.setPage(updatedPage)
          this.state.setData([...(this.state.data || []), ...statistics.data])
          this.state.setPaginatorInfo(statistics.paginatorInfo)
        }
      })
      .finally(() => {
        this.state.setIsLoading(false)
      })
  }
}
