import {GlobalHydrateService} from '@services/common/global-hydrate'
import type {AffiliateInvitesPageProperties} from '@slices/affiliate/invites/lib/types'
import {store} from '@store/index'
import {api} from '@api/index'
import {DEFAULT_FIRST_PAGE_ITEMS} from '@constants/common'
import type {CreateAffiliateLinkFormData} from '@constants/form-validation/create-affiliate-link'
import {errorService} from '@services/common/error'
import type {UpdateAffiliateLinkFormData} from '@constants/form-validation/update-affiliate-link'
import {isNumber} from '@utils/guards/types'

type InitialHydrationData = NonNullable<
  AffiliateInvitesPageProperties['hydrationData']
>

export class AffiliateInvitesService extends GlobalHydrateService<InitialHydrationData> {
  private get state() {
    return store().pages.affiliate.invites
  }

  public hydrate(initialData: InitialHydrationData) {
    if (initialData.links) {
      this.state.setData(initialData.links.data)
      this.state.setPaginatorInfo(initialData.links.paginatorInfo)
    }
    super.hydrate(initialData)
  }

  public updateLink(data: UpdateAffiliateLinkFormData): void {
    if (!this.state.update.selectId) {
      this.state.update.setIsOpen(false)
      return
    }

    this.state.update.setIsLoading(true)

    api.affiliate
      .updateReferralLink({
        variables: {
          id: this.state.update.selectId,
          name: data.companyName,
        },
      })
      .then((updatedLink) => {
        if (updatedLink && this.state.data) {
          this.state.update.setIsOpen(false)
          this.state.update.setError('')

          const updateData = [...this.state.data]

          const findItemIndex = updateData.findIndex(
            (item) => item.id === this.state.update.selectId,
          )

          if (isNumber(findItemIndex)) {
            this.state.setNameCompanyByIndex(
              findItemIndex,
              this.state.update.companyName,
            )
          }

          this.state.update.setSelectId(null)
          this.state.update.setCompanyName('')
        }
      })
      .catch((error) => {
        const firstError = errorService.getFirstError(error)
        if (firstError?.message) {
          this.state.create.setError(firstError?.message)
        }
      })
      .finally(() => this.state.update.setIsLoading(false))
  }

  public createLink(data: CreateAffiliateLinkFormData): void {
    this.state.create.setIsLoading(true)
    api.affiliate
      .addReferralLink({
        variables: {
          name: data.companyName,
          url: data.referralLink,
        },
      })
      .then((newLink) => {
        this.state.create.setIsOpen(false)
        this.state.create.setReferralLink('')
        this.state.create.setCompanyName('')
        this.state.create.setError('')

        if (newLink) {
          this.state.setData([newLink, ...(this.state.data || [])])
        }
      })
      .catch((error) => {
        const firstError = errorService.getFirstError(error)
        if (firstError?.message) {
          this.state.create.setError(firstError?.message)
        }
      })
      .finally(() => this.state.create.setIsLoading(false))
  }

  public remove(id: number): void {
    this.state.remove.setIsLoading(true)
    api.affiliate
      .removeReferralLink({
        variables: {
          id,
        },
      })
      .then((isDelete) => {
        if (isDelete && this.state.data) {
          const updatingReferralsLinkData = this.state.data.filter(
            (link) => link.id !== id,
          )
          this.state.setData(updatingReferralsLinkData)
        }
      })
      .finally(() => this.state.remove.setIsLoading(false))
  }

  public loadMore(): void {
    if (!this.state.data) {
      return
    }

    if (!this.state.paginatorInfo?.hasMorePages) {
      return
    }

    const updatedPage = this.state.page + 1
    this.state.setIsLoading(true)
    api.affiliate
      .getReferralLinks({
        variables: {
          page: updatedPage,
          first: DEFAULT_FIRST_PAGE_ITEMS,
        },
      })
      .then((links) => {
        if (links) {
          this.state.setPage(updatedPage)
          this.state.setData([...(this.state.data || []), ...links.data])
          this.state.setPaginatorInfo(links.paginatorInfo)
        }
      })
      .finally(() => {
        this.state.setIsLoading(false)
      })
  }
}
