import styled from '@emotion/styled'
import type {ComponentProps, FC, HTMLAttributes, PropsWithChildren} from 'react'
import {
  Carousel,
  CarouselContent,
  CarouselDots,
  CarouselNext,
  CarouselPrevious,
} from '@ui/carousel'
import {css} from '@emotion/react'
import {MEDIA_DEVICE_QUERY} from '@assets/theme/media'
import Image from 'next/image'
import {ImageWithSkeleton} from '@ui/image-with-skeleton'
import {NO_HOTEL_IMAGE_SOURCE} from '@/library/constants/hotel'

const Wrapper = styled.div<{
  coverImage?: string | null
  linearShadow?: boolean
}>`
  position: relative;
  overflow: hidden;

  ${({coverImage, theme}) =>
    coverImage
      ? css`
          background-image: url(${coverImage});
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
        `
      : css`
          background-color: ${theme.palette.backgroundQuaternary};
        `}

  border: 1px solid ${({theme}) => theme.palette.borderTertiary};
  border-radius: 12px;

  transition: 0.3s box-shadow ease-in-out;

  width: 320px;
  height: 342px;

  @media ${MEDIA_DEVICE_QUERY.LAPTOP_LARGE} {
    width: 250px;
    height: 320px;
  }

  @media ${MEDIA_DEVICE_QUERY.LAPTOP} {
    width: 375px;
    height: 266px;
  }

  @media ${MEDIA_DEVICE_QUERY.MOBILE_LARGE} {
    width: 100%;
    height: 266px;
  }

  img {
    transition: 0.3s transform ease-in-out;
  }

  &:hover {
    box-shadow: 0px 4px 6px 0px ${({theme}) => theme.palette.boxShadowPrimary};

    img {
      transform: scale(1.1);
    }
  }

  ${({linearShadow, theme}) =>
    linearShadow &&
    css`
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: ${theme.palette.backgroundLinearShadow};
        border-radius: 12px; /* если нужно, применяйте радиус скругления, соответствующий Wrapper */
        pointer-events: none;
        z-index: 1;
      }
    `}
`

const StyledBackgroundImage = styled(ImageWithSkeleton)`
  position: absolute;
  z-index: 1;

  width: 100%;
  height: 100%;
  object-fit: cover;
`

const Container: FC<PropsWithChildren & ComponentProps<typeof Wrapper>> = ({
  children,
  coverImage,
  ...properties
}) => {
  return (
    <Wrapper {...properties}>
      {coverImage && (
        <StyledBackgroundImage
          src={coverImage}
          alt="card image"
          priority
          fill
        />
      )}
      {children}
    </Wrapper>
  )
}

Container.displayName = 'Card'

const StyledCarousel = styled(Carousel)`
  width: 100%;
  height: 100%;
`

const StyledCarouselContent = styled(CarouselContent)`
  height: 227px;
`

const CarouselImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`

interface CarouselSliderProps extends HTMLAttributes<HTMLDivElement> {
  images: {
    id: string | number | bigint
    source?: string | null
  }[]
}

const CarouselImage: FC<CarouselSliderProps> = ({images}) => {
  return (
    <StyledCarousel orientation="horizontal">
      <StyledCarouselContent>
        {images.map((image) => {
          return (
            <CarouselImageWrapper key={image.id}>
              <Image
                src={image.source || NO_HOTEL_IMAGE_SOURCE}
                fill
                alt="carousel image"
                objectFit="cover"
              />
            </CarouselImageWrapper>
          )
        })}
      </StyledCarouselContent>
      <CarouselPrevious />
      <CarouselNext />
      <CarouselDots />
    </StyledCarousel>
  )
}

CarouselImage.displayName = 'CardCarouselImage'

const ContentWrapper = styled.div`
  position: relative;
  z-index: 2;
  height: 100%;
`

const Content: FC<PropsWithChildren> = ({children}) => {
  return <ContentWrapper>{children}</ContentWrapper>
}

Content.displayName = 'CardContentWrapper'

export const Card = {
  Container,
  CarouselImage,
  Content,
}
