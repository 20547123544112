import {store} from '@store/index'
import {observer} from 'mobx-react'
import {useLocalObservable} from 'mobx-react-lite'
import {CitizenshipInput} from '@/library/components/features'
import useTranslation from 'next-translate/useTranslation'

const selectCitizenship = () => ({
  get citizenship() {
    return store().pages.profile.settings.citizenship
  },
})

export const SettingsCitizenshipInput = observer(() => {
  const {t} = useTranslation('profile.settings')
  const {citizenship} = useLocalObservable(selectCitizenship)

  return (
    <CitizenshipInput
      label={t('inputs.citizenship')}
      citizenship={citizenship}
      setCitizenship={(citizenship) =>
        store().pages.profile.settings.setCitizenship(citizenship)
      }
    />
  )
})
