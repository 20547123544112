import styled from '@emotion/styled'
import {type Option, Select} from '@ui/select'
import {observer} from 'mobx-react'
import {store} from '@store/index'
import useTranslation from 'next-translate/useTranslation'

const Wrapper = styled.div`
  margin-bottom: 20px;
`

const DEFAULT_OPTIONS: Option<boolean | null>[] = [
  {
    label: 'global.hotel:rates.filters.meal.all_variants',
    value: null,
  },
  {
    label: 'global.hotel:rates.filters.meal.with_meal',
    value: true,
  },
]

export const HotelScenarioRatesFiltersMeal = observer(() => {
  const {mealIncludes} = store().pages.global.hotel.rates.filters
  const {t} = useTranslation('global.hotel')
  return (
    <Wrapper>
      <Select
        label={t('rates.filters.meal.label')}
        value={mealIncludes || DEFAULT_OPTIONS[0]}
        options={DEFAULT_OPTIONS}
        setValue={(option) =>
          store().pages.global.hotel.rates.filters.setMealIncludes(option)
        }
      >
        free-cancellation
      </Select>
    </Wrapper>
  )
})
