import type {GetDestinationAutoCompleteQuery} from '@/library/api/gql/generate-types'
import {makeAutoObservable} from 'mobx'

type DestinationData =
  | GetDestinationAutoCompleteQuery['destinationAutocomplete']
  | null

export type DestinationSelectPlace = {
  name: string
  id: number
} | null

type InitialStateType = {
  isLoading: boolean
  error: string
  isSuccess: boolean
  isOpen: boolean
  cityId: number | null
  inputValue: string
  selectPlace: DestinationSelectPlace | null
  data: DestinationData
}

const INITIAL_STATE: InitialStateType = {
  isLoading: false,
  error: '',
  isSuccess: false,
  isOpen: false,
  cityId: null,
  inputValue: '',
  selectPlace: null,
  data: null,
}

export class CCitySubscriptionStore {
  constructor() {
    makeAutoObservable(this)
  }

  private _inputValue = INITIAL_STATE.inputValue

  public get inputValue(): string {
    return this._inputValue
  }

  private _data: DestinationData = INITIAL_STATE.data

  public get data(): DestinationData {
    return this._data
  }

  private _isOpen = false

  public get isOpen(): boolean {
    return this._isOpen
  }

  private _cityId = INITIAL_STATE.cityId

  public get cityId(): number | null {
    return this._cityId
  }

  private _selectPlace: DestinationSelectPlace = INITIAL_STATE.selectPlace

  public get selectPlace(): DestinationSelectPlace {
    return this._selectPlace
  }

  private _isLoading = INITIAL_STATE.isLoading

  public get isLoading(): InitialStateType['isLoading'] {
    return this._isLoading
  }

  private _isSuccess = INITIAL_STATE.isSuccess

  public get isSuccess(): InitialStateType['isSuccess'] {
    return this._isSuccess
  }

  private _error = INITIAL_STATE.error

  public get error(): InitialStateType['error'] {
    return this._error
  }

  public setIsSuccess(isSuccess: InitialStateType['isSuccess']) {
    this._isSuccess = isSuccess
  }

  public setError(error: InitialStateType['error']) {
    this._error = error
  }

  public setData(data: DestinationData): void {
    this._data = data
  }

  public setSelectPlace(value: DestinationSelectPlace) {
    this._selectPlace = value
  }

  public setIsLoading(isLoading: InitialStateType['isLoading']) {
    this._isLoading = isLoading
  }

  public setIsOpen(isOpen: InitialStateType['isOpen']) {
    this._isOpen = isOpen
  }

  public setInputValue(value: string): void {
    this._inputValue = value
  }

  public setCityId(value: number | null): void {
    this._cityId = value
  }

  public resetState() {
    this.setIsLoading(false)
    this.setError('')
    this.setIsSuccess(false)
    this.setIsOpen(false)
    this.setCityId(null)
    this.setInputValue('')
    this.setSelectPlace(null)
    this.setData(null)
  }
}
