import type {FC, MouseEvent, PropsWithChildren} from 'react'
import styled from '@emotion/styled'
import {Icon} from '@ui/icon'
import {TRANSITION_STYLES} from '@constants/css'
import {Tooltip} from '@ui/tooltip'
import {isNumber} from '@utils/guards/types'
import {css} from '@emotion/react'

const Wrapper = styled.div<{$disabled?: boolean}>`
  display: flex;
  justify-content: start;
  align-items: start;
  cursor: pointer;
  gap: 10px;

  span {
    transition: color ${TRANSITION_STYLES};
  }

  &:hover {
    .box {
      border: 2px solid ${({theme}) => theme.palette.backgroundAccentPrimary};
    }

    span {
      color: ${({theme}) => theme.palette.backgroundAccentPrimary};
    }
  }

  ${({$disabled}) =>
    $disabled &&
    css`
      opacity: 0.4;
      cursor: not-allowed;
    `}
`

const BoxWrapper = styled.div<{$isActive: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  min-width: 22px;
  min-height: 22px;
  border: 2px solid
    ${({theme, $isActive}) =>
      $isActive
        ? theme.palette.borderAccentPrimary
        : theme.palette.borderSecondary};
  background-color: ${({theme, $isActive}) =>
    $isActive ? theme.palette.backgroundAccentPrimary : 'transparent'};
  transition: border ${TRANSITION_STYLES};
`

const StyledCheckboxIcon = styled(Icon)`
  color: ${({theme}) => theme.palette.backgroundQuaternary};
`

const LabelWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 5px;
`

const Label = styled.span`
  color: ${({theme}) => theme.palette.textPrimary};
`

const CountWrapper = styled.div`
  color: ${({theme}) => theme.palette.textSecondary};
  font-size: 12px;
`

const StyledQuestionIcon = styled(Icon)`
  transition: color ${TRANSITION_STYLES};

  :hover {
    color: ${({theme}) => theme.palette.backgroundAccentPrimary};
  }
`

interface Props extends PropsWithChildren {
  setValue?: (value: boolean, event: MouseEvent<HTMLDivElement>) => void
  value: boolean
  label?: string
  count?: number
  disabled?: boolean
  description?: string | null
  className?: string
}

export const Checkbox: FC<Props> = ({
  label,
  value,
  setValue,
  count,
  disabled,
  description,
  children,
  className,
}) => {
  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    if (disabled) {
      return
    }

    setValue?.(!value, event)
  }

  return (
    <Wrapper $disabled={disabled} className={className} onClick={handleClick}>
      <BoxWrapper className="box" $isActive={value}>
        {value && (
          <StyledCheckboxIcon name="acceptMark" width={10} height={10} />
        )}
      </BoxWrapper>
      <LabelWrapper className="label">
        {label && <Label>{label}</Label>}
        {children}
        {description && (
          <Tooltip trigger="hover" title={description}>
            <StyledQuestionIcon name="question" width={16} height={16} />
          </Tooltip>
        )}
      </LabelWrapper>
      {isNumber(count) && (
        <CountWrapper>
          <span>{count}</span>
        </CountWrapper>
      )}
    </Wrapper>
  )
}

Checkbox.displayName = 'Checkbox'
