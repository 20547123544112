import type {IncomingHttpHeaders} from 'node:http'
import {parse} from 'accept-language-parser'
import {CookieNames, getCookie} from '@library/utils/cookie'
import {ELanguage} from '@/types/languages'
import {services} from '@services/index'
import {ENVIRONMENT} from '@utils/guards/environment'
import {isClient, isServer} from '../next'

type MimeKind = 'json' | 'html'

export enum AuthHeaderNames {
  AUTHORIZATION = 'authorization',
  TEMPORARY_AUTHORIZATION = 'x-access-token',
}

const mimes: Record<MimeKind, string> = {
  html: 'plain/html',
  json: 'application/json',
}

export const mime = (type: MimeKind): string => mimes[type]

export const formatToken = (token?: string): string =>
  token ? ['Bearer', token].join(' ') : ''

const UUID_REGEX = /^[\dA-Fa-f]{8}(?:-[\dA-Fa-f]{4}){3}-[\dA-Fa-f]{12}$/

export const isValidApplicationPushUUID = (
  uuid?: string | null,
): uuid is string => (uuid ? UUID_REGEX.test(uuid) : false)

export const clientHeaders = (
  initHeaders: IncomingHttpHeaders = {},
): Headers => {
  if (!isClient()) {
    throw new Error(
      'Function was called in wrong environment, ensure you are in browser process!',
    )
  }

  const temporaryToken = getCookie(CookieNames.TEMPORARY_AUTHORIZATION)
  const token = formatToken(getCookie(CookieNames.AUTHORIZATION))
  const content = initHeaders['content-type'] ?? mime('json')

  const headers = new Headers([
    ['accept', mime('json')],
    ['content-type', content],
    [AuthHeaderNames.AUTHORIZATION, token],
  ])

  const uuid = window.localStorage.getItem(ENVIRONMENT.APPLICATION_PUSH_UUID)
  if (isValidApplicationPushUUID(uuid)) {
    headers.set(ENVIRONMENT.APPLICATION_PUSH_UUID, uuid)
  }

  // При временной авторизации передаём заголовок x-access-token вместо authorization
  if (temporaryToken && temporaryToken.length > 0) {
    headers.delete(AuthHeaderNames.AUTHORIZATION)
    headers.set(AuthHeaderNames.TEMPORARY_AUTHORIZATION, temporaryToken)
  }

  // TODO: Найти нормальный фикс
  return Object.fromEntries(headers) as unknown as Headers
}

export const serverHeaders = (
  initHeaders: IncomingHttpHeaders = {},
  cookies: Partial<Record<string, string>> = {},
) => {
  if (!isServer()) {
    throw new Error(
      'Function was called in wrong environment, ensure you are in nodejs process!',
    )
  }

  const language =
    parse(initHeaders['accept-language'])[0]?.code ?? ELanguage.RU

  const token = formatToken(cookies[CookieNames.AUTHORIZATION])
  const temporaryToken = cookies[CookieNames.TEMPORARY_AUTHORIZATION]

  const headers = new Headers([
    ['accept', mime('json')],
    ['accept-language', language],
    ['content-type', 'application/json'],
  ])

  if (token) {
    headers.set('authorization', token)
  }

  const fingerprint = cookies[services.common.fingerprint.storageKey]
  if (fingerprint) {
    headers.set(services.common.fingerprint.storageKey, fingerprint)
  }

  // При временной авторизации передаём заголовок x-access-token вместо authorization
  if (temporaryToken && temporaryToken.length > 0) {
    headers.delete('authorization')
    headers.set('x-access-token', temporaryToken)
  }

  // TODO: Найти нормальный фикс
  return Object.fromEntries(headers) as unknown as Headers
}
