import {type UserProfile} from '@/library/api/gql/generate-types'
import {DEFAULT_SEX} from '@/library/components/features/sex-input/lib/constants'
import type {UpdateMyUserProfileFormData} from '@/library/constants/form-validation/update-my-user-profile-form'
import {makeAutoObservable} from 'mobx'
import {isSex} from '@utils/guards/business'
import {addPlus} from '@utils/phone-numbers'
import {DEFAULT_COUNTRY} from '@components/features/citizenship-input/lib/constants'

type FirstName = UpdateMyUserProfileFormData['firstname']
type LastName = UpdateMyUserProfileFormData['lastname']
type Email = UpdateMyUserProfileFormData['email']
type Citizenship = UpdateMyUserProfileFormData['citizenship']
type Phone = UpdateMyUserProfileFormData['phone']
type Birthday = UpdateMyUserProfileFormData['birthday']
type Sex = UpdateMyUserProfileFormData['sex']

export class CProfileSettingsStore {
  constructor() {
    makeAutoObservable(this)
  }

  private _isLoading = false

  public get isLoading(): boolean {
    return this._isLoading
  }

  private _isDataSubmitted = false

  public get isDataSubmitted(): boolean {
    return this._isDataSubmitted
  }

  private _error = ''

  public get error(): string {
    return this._error
  }

  private _firstName: FirstName = ''

  public get firstName(): FirstName {
    return this._firstName
  }

  private _lastName: LastName = ''

  public get lastName(): LastName {
    return this._lastName
  }

  private _email: Email = ''

  public get email(): Email {
    return this._email
  }

  private _citizenship: Citizenship = ''

  public get citizenship(): Citizenship {
    return this._citizenship
  }

  private _birthday: Birthday = null

  public get birthday(): Birthday {
    return this._birthday
  }

  private _phone: Phone = ''

  public get phone(): Phone {
    return this._phone
  }

  private _sex: Sex = DEFAULT_SEX

  public get sex(): Sex {
    return this._sex
  }

  public get formData(): UpdateMyUserProfileFormData {
    return {
      firstname: this._firstName,
      lastname: this._lastName,
      phone: this._phone,
      email: this._email,
      birthday: this._birthday,
      sex: this._sex,
      citizenship: this._citizenship,
    }
  }

  public setIsLoading(flag: boolean): void {
    this._isLoading = flag
  }

  public setError(error: string): void {
    this._error = error
  }

  public setIsDataSubmitted(flag: boolean): void {
    this._isDataSubmitted = flag
  }

  public setFirstName(value: FirstName): void {
    this._firstName = value
  }

  public setLastName(value: LastName): void {
    this._lastName = value
  }

  public setEmail(value: Email): void {
    this._email = value
  }

  public setCitizenship(value: Citizenship): void {
    this._citizenship = value
  }

  public setBirthday(value: Birthday): void {
    this._birthday = value
  }

  public setPhone(value: Phone): void {
    this._phone = value
  }

  public setSex(value: Sex): void {
    this._sex = value
  }

  public initializeInputValues(value: UserProfile): void {
    if (value.firstname) {
      this.setFirstName(value.firstname)
    }

    if (value.lastname) {
      this.setLastName(value.lastname)
    }

    this.setCitizenship(value.citizenship || DEFAULT_COUNTRY)

    if (isSex(value.sex)) {
      this.setSex(value.sex)
    }

    if (value.birthday) {
      this.setBirthday(value.birthday)
    }

    if (value.phone) {
      this.setPhone(addPlus(value.phone))
    }

    if (value.email) {
      this.setEmail(value.email)
    }
  }

  public reset() {
    this.setIsDataSubmitted(false)
    this.setIsLoading(false)
    this.setError('')
    this.setFirstName('')
    this.setLastName(null)
    this.setCitizenship(null)
    this.setSex(null)
    this.setBirthday(null)
    this.setPhone(null)
    this.setEmail(null)
  }
}
