import {type Utilities} from '@/types/utilities'

export class DeviceDetectionService {
  public readonly MOBILE_MAX_SCREEN_WIDTH = 480

  public readonly TABLET_MAX_SCREEN_WIDTH = 1280

  public readonly DESKTOP_MAX_SCREEN_WIDTH = 1920

  public isMobile(userAgent: Utilities['userAgent'] | null): boolean {
    return userAgent?.device.type === 'mobile'
  }

  public isTablet(userAgent: Utilities['userAgent'] | null): boolean {
    return userAgent?.device.type === 'touch'
  }

  public isTouch(userAgent: Utilities['userAgent'] | null): boolean {
    return this.isMobile(userAgent) || this.isTablet(userAgent)
  }
}
