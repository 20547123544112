import {ApolloClient, from, InMemoryCache} from '@apollo/client'
import {setContext} from '@apollo/client/link/context'
import {ENVIRONMENT} from '@utils/guards/environment'
import {services} from '@services/index'
import {isServer} from '@utils/next'
import {onError} from '@apollo/client/link/error'
import {createUploadLink} from 'apollo-upload-client'

const cache = new InMemoryCache()

const uploadLink = createUploadLink({
  uri: ENVIRONMENT.GRAPHQL_API,
  credentials: 'include',
})

const authorizationLink = setContext(async (_, {headers}) => {
  if (isServer()) {
    return {headers}
  }

  const fingerprintService = services.common.fingerprint
  const fingerprint = fingerprintService.get()

  if (fingerprintService.isValid(fingerprint)) {
    return {
      headers: {
        ...headers,
        [fingerprintService.storageKey]: fingerprint,
      },
    }
  }

  const updatedFingerprint = await fingerprintService.load()

  return {
    headers: {
      ...headers,
      [fingerprintService.storageKey]: updatedFingerprint,
    },
  }
})

const errorLink = onError(({graphQLErrors, networkError}) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((error) => {
      Object.entries(error).forEach(([key, value]) => {
        if (key !== 'trace') {
          console.log(`[${key}]:`, value)
        }
      })
    })
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`)
  }
})

export const $gqlClient = () =>
  new ApolloClient({
    cache,
    link: from([errorLink, authorizationLink, uploadLink]),
  })
