import styled from '@emotion/styled'
import {observer} from 'mobx-react'
import {store} from '@store/index'
import {HOTEL_RATING_FRACTION_DIGITS} from '@constants/hotel'
import {Icon} from '@ui/icon'
import {Button} from '@ui/button'
import {TRANSITION_STYLES} from '@constants/css'
import {Tooltip} from '@ui/tooltip'
import {useMemo} from 'react'
import useTranslation from 'next-translate/useTranslation'
import {MEDIA_DEVICE_QUERY} from '@assets/theme/media'
import {useRouter} from 'next/router'
import {
  HOTEL_SCENARIO_PLACE_ID,
  HOTEL_SCENARIO_REVIEWS_ID,
} from '@constants/dom-ids'
import {ButtonOnMap, FavoriteHeart, LinkForCallTaxi} from '@components/features'
import {HotelScenarioMainInformationShare} from '@slices/global/ui/hotel-scenario/main-information/ui/share'
import {isImage} from '@utils/guards/business'
import {CarouselImage} from '@components/ui'
import {isNumber} from '@utils/guards/types'
import {useDeviceDetection} from '@/library/hooks/use-device-detection'
import {Title} from '@/library/components/ui/typography'
import {CallYaTaxiAnalyzingEventEnum} from '@/library/api/gql/generate-types'
import {NavigationModal} from './ui/navigation-modal'

const Wrapper = styled.div``

const HotelNameWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 7px;

  .actions {
    display: none;
  }

  @media ${MEDIA_DEVICE_QUERY.MOBILE_LARGE} {
    .actions {
      display: flex;
    }
  }
`

const HotelName = styled(Title)`
  font-size: 36px;
  font-weight: 500;
  line-height: 39px;
  text-align: left;
  color: ${({theme}) => theme.palette.textPrimary};

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 100%;
  display: block;
  justify-content: center;
  align-content: center;

  @media ${MEDIA_DEVICE_QUERY.LAPTOP} {
    font-size: 32px;
  }

  @media ${MEDIA_DEVICE_QUERY.TABLET} {
    font-size: 24px;
    line-height: 26px;
  }

  @media ${MEDIA_DEVICE_QUERY.MOBILE_LARGE} {
    font-size: 16px;
    max-width: 70%;
  }

  @media ${MEDIA_DEVICE_QUERY.MOBILE_MEDIUM} {
    font-size: 16px;
  }

  @media ${MEDIA_DEVICE_QUERY.MOBILE_SMALL} {
    font-size: 14px;
  }
`

const ReviewsWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 8px;
`

const ReviewsRatingWrapper = styled.div`
  cursor: pointer;
  color: ${({theme}) => theme.palette.textAccentPrimary};

  svg {
    color: ${({theme}) => theme.palette.textAccentPrimary};
    margin-top: 4px;
  }

  font-weight: 700;
  display: flex;
  justify-content: center;
  align-content: center;
  gap: 5px;
`

const ReviewsCount = styled.div`
  color: ${({theme}) => theme.palette.textSecondary};
  font-weight: 500;
  cursor: pointer;
`

const AddressAndActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  width: 100%;
  color: ${({theme}) => theme.palette.textPrimary};

  .actions {
    display: flex;
  }

  @media ${MEDIA_DEVICE_QUERY.MOBILE_LARGE} {
    .actions {
      display: none;
    }
  }

  margin-bottom: 13px;
`

const HotelAddress = styled.div`
  display: flex;
  gap: 20px;

  @media ${MEDIA_DEVICE_QUERY.TABLET} {
    gap: 16px;
  }

  @media ${MEDIA_DEVICE_QUERY.MOBILE_MEDIUM} {
    flex-direction: column;
    gap: 0;
    width: 100%;
  }
`

const HotelAddressTooltipWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 5px;

  .address {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 325px;

    @media ${MEDIA_DEVICE_QUERY.MOBILE_VERY_LARGE} {
      max-width: 225px;
    }

    @media ${MEDIA_DEVICE_QUERY.MOBILE_LARGE} { 
      max-width: 200px;
    }

    @media ${MEDIA_DEVICE_QUERY.MOBILE_MEDIUM} {
      max-width: 100%;
    }
  }

  @media ${MEDIA_DEVICE_QUERY.MOBILE_MEDIUM} {
    width: 100%;
    margin-bottom: 8px;
  }
`

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  gap: 17px;

  @media ${MEDIA_DEVICE_QUERY.TABLET} {
    gap: 10px;
  }
`

const ActionItem = styled(Button)`
  display: flex;
  justify-content: center;
  font-family: ${({theme}) => theme.font.main};
  align-content: center;
  gap: 5px;

  svg {
    display: flex;
    justify-content: center;
    align-content: center;
    transition: color ${TRANSITION_STYLES};
  }

  cursor: pointer;

  &:hover {
    div {
      color: ${({theme}) => theme.palette.textAccentPrimary};
    }

    svg {
      color: ${({theme}) => theme.palette.textAccentPrimary};
    }
  }
`
const ActionIcon = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  color: ${({theme}) => theme.palette.textSecondary};
  transition: color ${TRANSITION_STYLES};
`

const StyledFavoriteHeart = styled(FavoriteHeart)`
  position: relative;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 0;

  svg {
    margin-bottom: 2px;
  }
`

const ActionText = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  color: ${({theme}) => theme.palette.textPrimary};
  transition: color ${TRANSITION_STYLES};
  font-weight: 500;
  font-size: 16px;
  @media ${MEDIA_DEVICE_QUERY.TABLET} {
    display: none;
  }
`

const NameWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 2px;
  width: 77%;
`

const AddressButton = styled(Button)`
  display: block;
  text-align: left;
`

const HotelStarsWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: start;
  height: 100%;
  gap: 5px;

  svg {
    color: ${({theme}) => theme.palette.backgroundYellow};
  }
`

const HotelImageWrapper = styled.div``

export const HotelScenarioMainInformation = observer(() => {
  const router = useRouter()
  const isTouch = useDeviceDetection('touch')

  const {t} = useTranslation('global.hotel')
  const {data} = store().pages.global.hotel
  const imageAlt = data ? `${data.city.name}: ${data.name}` : 'hotel image'

  const images = useMemo(() => {
    const mainImage = data?.mainImage

    if (!data?.images && mainImage?.url && mainImage?.id) {
      return [
        {
          id: mainImage.id,
          url: mainImage.url,
          alt: imageAlt,
        },
      ]
    }

    return data?.images?.filter(isImage).map((item) => ({
      id: item.id,
      url: item.url,
      alt: imageAlt,
    }))
  }, [data, imageAlt])

  const handleReviewsClick = () => {
    router.push('', {
      hash: HOTEL_SCENARIO_REVIEWS_ID,
    })
  }

  const handleOnMapClick = () => {
    if (!isTouch || !data?.lng || !data?.lat) {
      router.push('', {
        hash: HOTEL_SCENARIO_PLACE_ID,
      })
      return
    }

    store().pages.global.hotel.setIsOpenNavigationModal(true)
  }

  return (
    <Wrapper>
      <HotelNameWrapper>
        <NameWrapper>
          {!!data?.name && (
            <Tooltip trigger="hover" title={data.name}>
              <HotelName type="h1">{data.name}</HotelName>
            </Tooltip>
          )}
          {isNumber(data?.stars) && (
            <HotelStarsWrapper>
              {Array.from({length: data.stars}).map((_, index) => {
                return (
                  <Icon
                    // eslint-disable-next-line react/no-array-index-key
                    key={`$hotel_${data.name}_star_${index}`}
                    name="star"
                    width={12}
                    height={12}
                  />
                )
              })}
            </HotelStarsWrapper>
          )}
        </NameWrapper>
        <ActionsWrapper className="actions">
          {data?.lat && data?.lng && (
            <LinkForCallTaxi
              event={CallYaTaxiAnalyzingEventEnum.HotelPage}
              id={data?.id}
              lat={data?.lat}
              lng={data?.lng}
            />
          )}
          <ActionItem variant="transparent">
            <ActionIcon>
              {data?.id && (
                <StyledFavoriteHeart
                  hotelId={data.id}
                  parentIsFavorite={Boolean(data?.is_favourite_hotel)}
                  size={16}
                />
              )}
            </ActionIcon>
            <ActionText>{t('main_information.to_favourite')}</ActionText>
          </ActionItem>
          <ActionItem
            variant="transparent"
            onClick={() => store().pages.global.hotel.setIsOpenShareModal(true)}
          >
            <ActionIcon>
              <Icon name="download" width={16} height={16} />
            </ActionIcon>
            <ActionText>{t('main_information.share')}</ActionText>
          </ActionItem>
        </ActionsWrapper>
      </HotelNameWrapper>
      <ReviewsWrapper>
        {data?.rating && (
          <ReviewsRatingWrapper onClick={handleReviewsClick}>
            <Icon name="star" width={14} height={14} />
            <span>{data.rating.toFixed(HOTEL_RATING_FRACTION_DIGITS)}</span>
          </ReviewsRatingWrapper>
        )}
        <ReviewsCount onClick={handleReviewsClick}>
          {t('main_information.reviews.label', {
            count: data?.reviews_count || 0,
          })}
        </ReviewsCount>
      </ReviewsWrapper>
      <AddressAndActionsWrapper>
        <HotelAddress>
          <HotelAddressTooltipWrapper>
            <Icon name="point" width={11} height={15} />
            <Tooltip trigger="hover" title={data?.address} className="address">
              <AddressButton variant="transparent" onClick={handleOnMapClick}>
                {data?.address}
              </AddressButton>
            </Tooltip>
          </HotelAddressTooltipWrapper>
          <ButtonOnMap onClick={handleOnMapClick}>
            {t('main_information.on_map')}
          </ButtonOnMap>
        </HotelAddress>
        <ActionsWrapper className="actions">
          {data?.lat && data?.lng && (
            <LinkForCallTaxi
              event={CallYaTaxiAnalyzingEventEnum.HotelPage}
              id={data?.id}
              lat={data?.lat}
              lng={data?.lng}
            >
              <ActionText>{t('main_information.taxi')}</ActionText>
            </LinkForCallTaxi>
          )}
          <ActionItem variant="transparent">
            <ActionIcon>
              {data?.id && (
                <StyledFavoriteHeart
                  hotelId={data.id}
                  parentIsFavorite={Boolean(data?.is_favourite_hotel)}
                  size={16}
                >
                  <ActionText>{t('main_information.to_favourite')}</ActionText>
                </StyledFavoriteHeart>
              )}
            </ActionIcon>
          </ActionItem>
          <ActionItem
            variant="transparent"
            onClick={() => store().pages.global.hotel.setIsOpenShareModal(true)}
          >
            <ActionIcon>
              <Icon name="download" width={14} height={13} />
            </ActionIcon>
            <ActionText>{t('main_information.share')}</ActionText>
          </ActionItem>
        </ActionsWrapper>
      </AddressAndActionsWrapper>
      {images && (
        <HotelImageWrapper>
          <CarouselImage images={images} alt={imageAlt} addMiniSlider />
        </HotelImageWrapper>
      )}
      <HotelScenarioMainInformationShare />
      <NavigationModal />
    </Wrapper>
  )
})
