import {makeAutoObservable} from 'mobx'
import {store} from '@store/index'

export class CAffiliateInvitesUpdate {
  constructor() {
    makeAutoObservable(this)
  }

  private _selectId: number | null = null

  public get selectId(): number | null {
    return this._selectId
  }

  private _error = ''

  public get error(): string {
    return this._error
  }

  private _isOpen = false

  public get isOpen(): boolean {
    return this._isOpen
  }

  private _isLoading = false

  public get isLoading(): boolean {
    return this._isLoading
  }

  private _companyName = ''

  public get companyName(): string {
    return this._companyName
  }

  public setSelectId(id: number | null): void {
    this._selectId = id
  }

  public setError(error: string): void {
    this._error = error
  }

  public setIsOpen(flag: boolean): void {
    this._isOpen = flag
  }

  public setIsLoading(flag: boolean): void {
    this._isLoading = flag
  }

  public setCompanyName(name: string): void {
    this._companyName = name
  }

  public resetState(): void {
    store().pages.affiliate.invites.update.setIsOpen(false)
    store().pages.affiliate.invites.update.setCompanyName('')
    store().pages.affiliate.invites.update.setError('')
  }
}
