import type {MapPoints} from '@/library/components/ui/yandex-map'
import type {Hotel} from '@/slices/global/lib/types'

export const buildHotelToMapPoint = (
  hotel?: Hotel | null,
): MapPoints | null => {
  if (!hotel || !hotel.lng || !hotel.lat) {
    return null
  }

  const {id, lat, lng, name, rating, stars, mainImage} = hotel

  return {
    id: String(id),
    type: 'Feature',
    properties: {
      id,
      image_url: mainImage?.url,
      lat,
      lng,
      name,
      rating,
      stars,
    },
    geometry: {
      type: 'Point',
      coordinates: [lng, lat],
    },
  }
}
