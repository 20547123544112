import type {FC} from 'react'
import useTranslation from 'next-translate/useTranslation'
import {
  EtInclusion,
  EtInternetType,
  EtPriceUnit,
  EtWorkArea,
} from '@/library/api/gql/generate-types'
import Trans from 'next-translate/Trans'
import type {OstrovokInternet} from '../lib/types'
import {InlineAmount, InlineCurrency, InlineText} from '../../typography'
import {getCurrency} from '../../../lib/helpers'
import {
  getInclusion,
  getInternetArea,
  getInternetType,
  getPriceUnit,
} from '../lib/helpers'

interface InternetProps {
  internet: OstrovokInternet
}

export const Internet: FC<InternetProps> = ({internet}) => {
  const {t} = useTranslation('global.hotel')
  const {t: currenciesT} = useTranslation('currencies')

  const isInclusionSpecified =
    internet.inclusion && internet.inclusion !== EtInclusion.Unspecified

  if (internet.price && isInclusionSpecified) {
    return (
      <Trans
        i18nKey="rules.et.internet_with_price_and_inclusion"
        ns="global.hotel"
        components={[
          <InlineAmount
            key={`internet_${internet.internetType}_${internet.price}_${internet.priceUnit}_amount`}
          />,
          <InlineCurrency
            key={`internet_${internet.internetType}_${internet.price}_${internet.priceUnit}_currency`}
          />,
          <InlineText
            key={`internet_${internet.internetType}_${internet.price}_${internet.priceUnit}`}
          />,
        ]}
        values={{
          internetType: getInternetType(
            t,
            internet.internetType || EtInternetType.Unspecified,
          ),
          internetArea: getInternetArea(
            t,
            internet.workArea || EtWorkArea.Unspecified,
          ),
          price: internet.price?.toLocaleString(),
          currency: getCurrency(currenciesT, internet.currency),
          unit: getPriceUnit(t, internet.priceUnit || EtPriceUnit.Unspecified),
          inclusion: getInclusion(
            t,
            internet.inclusion || EtInclusion.Unspecified,
          ),
        }}
      />
    )
  }

  if (internet.price) {
    return (
      <Trans
        i18nKey="rules.et.internet_with_price"
        ns="global.hotel"
        components={[
          <InlineAmount
            key={`internet_${internet.internetType}_${internet.price}_${internet.priceUnit}_amount`}
          />,
          <InlineCurrency
            key={`internet_${internet.internetType}_${internet.price}_${internet.priceUnit}_currency`}
          />,
          <InlineText
            key={`internet_${internet.internetType}_${internet.price}_${internet.priceUnit}`}
          />,
        ]}
        values={{
          internetType: getInternetType(
            t,
            internet.internetType || EtInternetType.Unspecified,
          ),
          internetArea: getInternetArea(
            t,
            internet.workArea || EtWorkArea.Unspecified,
          ),
          price: internet.price?.toLocaleString(),
          currency: getCurrency(currenciesT, internet.currency),
          unit: getPriceUnit(t, internet.priceUnit || EtPriceUnit.Unspecified),
        }}
      />
    )
  }

  if (isInclusionSpecified) {
    return (
      <Trans
        i18nKey="rules.et.internet_with__inclusion"
        ns="global.hotel"
        components={[
          <InlineText
            key={`internet_${internet.internetType}_${internet.inclusion}`}
          />,
        ]}
        values={{
          internetType: getInternetType(
            t,
            internet.internetType || EtInternetType.Unspecified,
          ),
          internetArea: getInternetArea(
            t,
            internet.workArea || EtWorkArea.Unspecified,
          ),

          inclusion: getInclusion(
            t,
            internet.inclusion || EtInclusion.Unspecified,
          ),
        }}
      />
    )
  }

  return (
    <Trans
      i18nKey="rules.et.internet"
      ns="global.hotel"
      values={{
        internetType: getInternetType(
          t,
          internet.internetType || EtInternetType.Unspecified,
        ),
        internetArea: getInternetArea(
          t,
          internet.workArea || EtWorkArea.Unspecified,
        ),
      }}
    />
  )
}
