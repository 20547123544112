import type {IHttpStatusCodeHandlers} from '@api/request/interceptors/catch/status-code/lib/types'
import type {HttpStatusCode} from '@/types/enums/http'
import {HttpStatusCodeHandlers} from '@api/request/interceptors/catch/status-code/lib/constants/handlers'
import {isApiException} from '@utils/guards/api'
import {handleApiException} from '@utils/exceptions/api'

export const interceptHttpsStatusCodes = (
  parameters: HttpRequestParameters,
  customHandlers?: Partial<IHttpStatusCodeHandlers>,
) => {
  const [response] = parameters
  const status = response.status as HttpStatusCode
  const handlers = {...HttpStatusCodeHandlers, ...customHandlers}
  const handler = handlers[status]

  if (handler && isApiException(parameters)) {
    handler({
      parameters,
    })
  }

  if (!handler && isApiException(parameters)) {
    handleApiException(parameters)
  }

  throw parameters
}
