import {emailSubscriptionForm} from '@/library/constants/form-validation/email-subscription'
import useTranslation from 'next-translate/useTranslation'
import {type FC, useEffect} from 'react'

import styled from '@emotion/styled'
import {Button, CircularLoader, Link, Text} from '@/library/components/ui'
import {FormProvider, useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'

import {Input} from '@/library/components/ui/input'
import {css, useTheme} from '@emotion/react'
import {Illustration} from '@/library/components/ui/illustration'
import {Routes} from '@/types/enums/routes'
import {Icon} from '@/library/components/ui/icon'
import {LOADING_STYLES, TRANSITION_STYLES} from '@/library/constants/css'
import {store} from '@/library/store'
import {observer, useLocalObservable} from 'mobx-react'
import {services} from '@/library/services'

import type {EmailSubscriptionFormData} from '../../lib/types'
import {
  selectError,
  selectIsLoading,
  selectIsSuccess,
} from '../../lib/selectors'

const Container = styled.div<{$isLoading: boolean}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 20px;
  width: 100%;
  min-height: 460px;

  ${({$isLoading}) =>
    $isLoading &&
    css`
      ${LOADING_STYLES}
    `}
`

const SubscriptionTitle = styled(Text)`
  text-align: center;
`

const CloseIcon = styled(Icon)`
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  transition: color ${TRANSITION_STYLES};

  &:hover {
    color: ${({theme}) => theme.palette.textAccentPrimary};
  }
`

const SubscriptionForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 20px;
`

const ServerErrorMessageWrapper = styled.div`
  min-height: 16px;
  margin: 2px 0;
  display: flex;
  justify-content: start;
  align-items: center;
`

const SubscribeButton = styled(Button)`
  width: 100%;
  text-transform: uppercase;
`

const PersonalDataLink = styled(Link)`
  text-decoration: underline;
`

const StyledCircuralLoader = styled(CircularLoader)`
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
`

interface IProps {
  resetIsExpiredTime: () => void
}

export const EmailSubscriptionForm: FC<IProps> = observer(
  ({resetIsExpiredTime}) => {
    const {t} = useTranslation('features.subscription-newsletter')
    const theme = useTheme()

    const {isSuccess} = useLocalObservable(selectIsSuccess)

    const {isLoading} = useLocalObservable(selectIsLoading)

    const {error} = useLocalObservable(selectError)

    const methods = useForm<EmailSubscriptionFormData>({
      resolver: yupResolver(emailSubscriptionForm.schema),
      defaultValues: emailSubscriptionForm.initialValues,
    })
    const {
      handleSubmit,
      formState: {errors},
      clearErrors,
      getValues,
      watch,
    } = methods

    const {email} = getValues()

    const onSubmit = handleSubmit((values) =>
      services.common.emailSubscription.subscribeToNewsletter(values),
    )

    const handleWidgetClose = () => {
      services.common.emailSubscription.closeWidget()

      resetIsExpiredTime()
    }

    useEffect(() => {
      // Очищение ошибки сервера при изменении value инпутов
      const subscription = watch(() => {
        store().emailSubscription.setError('')
        clearErrors()
      })
      return () => subscription.unsubscribe()
    }, [clearErrors, watch])

    if (isSuccess) {
      return (
        <Container $isLoading={isLoading}>
          <Illustration name="email_subscription" width={120} height={120} />
          <SubscriptionTitle size="boldM">
            {t('banner_success')}
          </SubscriptionTitle>
          <CloseIcon
            name="close"
            width={24}
            height={24}
            onClick={handleWidgetClose}
          />
        </Container>
      )
    }

    return (
      <Container $isLoading={isLoading}>
        <Illustration name="email_subscription" width={120} height={120} />
        <SubscriptionTitle size="boldM">{t('banner_title')}</SubscriptionTitle>
        <CloseIcon
          name="close"
          width={24}
          height={24}
          onClick={handleWidgetClose}
        />
        {isLoading && <StyledCircuralLoader size={32} />}
        <FormProvider {...methods}>
          <SubscriptionForm onSubmit={onSubmit}>
            <Input
              label={t('form.label_email')}
              name="email"
              type="email"
              value={email}
              error={errors.email}
              placeholder={t('form.placeholder_email')}
            />
            <SubscribeButton
              variant="primary"
              color={theme.palette.textPrimary}
              onClick={onSubmit}
            >
              {t('form.btn_text')}
            </SubscribeButton>
            <ServerErrorMessageWrapper>
              {error && (
                <Text color={theme.palette.textError} size="XS">
                  {t(error)}
                </Text>
              )}
            </ServerErrorMessageWrapper>
            <Text color={theme.palette.textSecondary} size="XS">
              {t('personal_information')}
              <PersonalDataLink href={Routes.POLITICS} target="_blank">
                {t('personal_information_link')}
              </PersonalDataLink>
            </Text>
          </SubscriptionForm>
        </FormProvider>
      </Container>
    )
  },
)
