import styled from '@emotion/styled'
import {Text} from '@components/ui'
import {Icon} from '@ui/icon'
import {Tooltip} from '@ui/tooltip'
import {observer} from 'mobx-react'
import useTranslation from 'next-translate/useTranslation'
import {store} from '@store/index'
import {HotelScenarioPlaceMap} from '@slices/global/ui/hotel-scenario/place/ui/map'
import {isEmpty} from 'ramda'

const Wrapper = styled.div`
  padding: 20px 17px;
  border: 1px solid ${({theme}) => theme.palette.borderTertiary};
  border-radius: 12px;
`

const Title = styled.h2`
  margin-bottom: 4px;
  font-weight: 500;
  font-size: 20px;
  color: ${({theme}) => theme.palette.textPrimary};
`

const HotelAddress = styled.div`
  display: flex;
  justify-content: start;
  align-content: center;
  gap: 7px;
  color: ${({theme}) => theme.palette.textPrimary};
  font-weight: 400;
  margin-bottom: 24px;

  div {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
  }

  .address {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    justify-content: start;
    align-content: start;
  }

  svg {
    color: ${({theme}) => theme.palette.textSecondary};
  }
`

const MapWrapper = styled.div`
  margin-bottom: 35px;
  height: 570px;
`

const LandmarksWrapper = styled.div``

const LandmarksTitle = styled(Text)`
  margin-bottom: 23px;
  font-weight: 500;
`

const LandmarksGroupWrapper = styled.div`
  column-gap: 32px;
  columns: 2 250px;
`

const LandmarksGroup = styled.ul`
  -webkit-column-break-inside: avoid;
  -moz-column-break-inside: avoid;
  column-break-inside: avoid;
  margin-bottom: 24px;
`

const LandmarkGroupName = styled(Text)`
  color: ${({theme}) => theme.palette.textPrimary};
  font-weight: 500;
  margin-bottom: 12px;
`

const LandmarkGroupItemWrapper = styled.li`
  display: flex;
  justify-content: space-between;
  align-content: center;
`

const LandmarkItemName = styled(Text)``

const LandmarkItemDistance = styled.div`
  color: ${({theme}) => theme.palette.textPrimary};
  font-weight: 400;
  font-size: 14px;
  min-width: fit-content;
  text-decoration: underline;
`

export const HotelScenarioPlace = observer(() => {
  const {t} = useTranslation('global.hotel')
  const {data} = store().pages.global.hotel

  return (
    <Wrapper>
      <Title>{t('place.title')}</Title>
      <HotelAddress>
        <div>
          <Icon name="point" width={11} height={15} />
        </div>
        <Tooltip trigger="hover" title={data?.address} className="address">
          {data?.address}
        </Tooltip>
      </HotelAddress>
      {data?.lng && data?.lat && (
        <MapWrapper>
          <HotelScenarioPlaceMap hotelPosition={[data.lat, data.lng]} />
        </MapWrapper>
      )}
      {!isEmpty(data?.landmarks) && (
        <LandmarksWrapper>
          <LandmarksTitle size="M" type="h3">
            {t('place.landmarks.title')}
          </LandmarksTitle>
          <LandmarksGroupWrapper>
            {data?.landmarks?.map((landmark) => (
              <LandmarksGroup key={landmark.group?.id}>
                {!!landmark.group?.name && (
                  <LandmarkGroupName size="boldS" type="p">
                    {landmark.group.name}
                  </LandmarkGroupName>
                )}

                {landmark?.marks?.map((mark) => (
                  <LandmarkGroupItemWrapper key={mark.id}>
                    <LandmarkItemName size="XS">{mark.name}</LandmarkItemName>
                    {mark.distance && (
                      <Tooltip trigger="hover" title={mark.address}>
                        <LandmarkItemDistance>
                          {mark.distance >= 1000
                            ? t('place.landmarks.distance_length', {
                                distance: (mark.distance / 1000).toFixed(1),
                              })
                            : t('place.landmarks.distance_meters', {
                                distance: mark.distance,
                              })}
                        </LandmarkItemDistance>
                      </Tooltip>
                    )}
                  </LandmarkGroupItemWrapper>
                ))}
              </LandmarksGroup>
            ))}
          </LandmarksGroupWrapper>
        </LandmarksWrapper>
      )}
    </Wrapper>
  )
})
