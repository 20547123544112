import {store} from '@/library/store'
import {observer} from 'mobx-react'
import styled from '@emotion/styled'
import {ServerErrorMessage} from '@/library/components/features/authorization-modal/ui/form'

const ErrorContainer = styled.div`
  height: 16px;
  margin: 5px 0 10px 0;
`

export const ServerError = observer(() => {
  const {error} = store().pages.profile.settings

  if (!error) {
    return null
  }

  return (
    <ErrorContainer>
      <ServerErrorMessage size="XS">{error}</ServerErrorMessage>
    </ErrorContainer>
  )
})
