import {api} from '@api/index'
import {DEFAULT_FIRST_ITEMS_HOTEL_REVIEWS} from '@slices/profile/balance/lib/constants'
import {store} from '@store/index'

export class GlobalPageHotelReviewsService {
  private get state() {
    return store().pages.global.hotel.reviews
  }

  public loadMore(): void {
    if (!this.state.data) {
      return
    }

    if (!this.state.paginatorInfo?.hasMorePages) {
      return
    }

    const hotelId = store().pages.global.hotel.data?.id

    if (!hotelId) {
      return
    }

    const updatedPage = this.state.page + 1
    this.state.setIsLoading(true)
    api.hotel
      .getReviews({
        variables: {
          id: hotelId,
          page: updatedPage,
          first: DEFAULT_FIRST_ITEMS_HOTEL_REVIEWS,
        },
      })
      .then((reviews) => {
        if (reviews?.data) {
          this.state.setPage(updatedPage)
          this.state.setData([...(this.state.data || []), ...reviews.data])
          this.state.setPaginatorInfo(reviews.paginatorInfo)
        }
      })
      .finally(() => {
        this.state.setIsLoading(false)
      })
  }
}
