import {TertiaryButton} from '@ui/button/lib/variants/button.tertiary'
import {ButtonIcon} from '@ui/button/lib/variants/button.icon'
import {ButtonTransparent} from '@ui/button/lib/variants/button.transparent'
import {TextButton} from './button.text'
import {PrimaryButton} from './button.primary'
import {SecondaryButton} from './button.secondary'
import {GreenButton} from './button.green'

export const BUTTON_VARIANTS = {
  primary: PrimaryButton,
  secondary: SecondaryButton,
  tertiary: TertiaryButton,
  text: TextButton,
  icon: ButtonIcon,
  transparent: ButtonTransparent,
  green: GreenButton,
}

export type ButtonVariant = keyof typeof BUTTON_VARIANTS
