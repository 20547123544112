import Cookies from 'js-cookie'
import {Routes} from '@/types/enums/routes'
import {ENVIRONMENT} from '@utils/guards/environment'

export const CookieNames = {
  AUTHORIZATION: `${ENVIRONMENT.COOKIE_TAG_VERSION}authorization`,
  TEMPORARY_AUTHORIZATION: `${ENVIRONMENT.COOKIE_TAG_VERSION}temporary_authorization`,
  TRANSACTION_ID: `${ENVIRONMENT.COOKIE_TAG_VERSION}transaction_id`,
} as const

type CookieNameKeys = keyof typeof CookieNames

export const getCookie = (
  name: CookieNameKeys | string,
): string | undefined => {
  return Cookies.get(name)
}

export const deleteCookie = (
  key: CookieNameKeys | string,
  path = Routes.MAIN,
) => {
  const options: Cookies.CookieAttributes = ENVIRONMENT.IS_LOCALHOST
    ? {
        expires: 365,
        path,
      }
    : {
        expires: 365,
        path,
        secure: true,
        sameSite: 'None',
        domain: `.${window.location.host}`,
      }
  Cookies.remove(key, options)
}

export const setCookie = (
  key: CookieNameKeys | string,
  content: string,
  expires: Cookies.CookieAttributes['expires'] = 365,
  path = Routes.HOME,
) => {
  const options: Cookies.CookieAttributes = ENVIRONMENT.IS_LOCALHOST
    ? {
        expires,
        path,
      }
    : {
        expires,
        path,
        secure: true,
        sameSite: 'None',
        domain: `.${window.location.host}`,
      }
  Cookies.set(key, content, options)
}
