import {makeAutoObservable} from 'mobx'

export default class CDateTo {
  constructor() {
    makeAutoObservable(this)
  }

  private _isOpen = false

  public get isOpen(): boolean {
    return this._isOpen
  }

  private _value: Date | null = null

  public get value(): Date | null {
    return this._value
  }

  public setIsOpen(isOpen: boolean): void {
    this._isOpen = isOpen
  }

  public setValue(value: Date | null): void {
    this._value = value
  }
}
