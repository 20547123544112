import styled from '@emotion/styled'
import {MEDIA_DEVICE_QUERY} from '@/assets/theme/media'
import {HotelInfo} from '../ui/hotel-info'
import {CostInfo} from '../ui/cost-info'
import {BookingForm} from '../ui/booking-form'
import {BookingSubmit} from '../ui/booking-submit'
import {BookingFormProvider} from '../ui/booking-form-provider'
import {BOOKING_MOBILE_SIZE} from '../lib/constants'

const Wrapper = styled.div``

const StyledBookingForm = styled(BookingForm)`
  margin-top: 30px;
`

const StyledCostInfo = styled(CostInfo)`
  margin-top: 30px;

  @media ${MEDIA_DEVICE_QUERY.LAPTOP_MEDIUM} {
    margin: 30px 20px 0;
  }

  @media ${BOOKING_MOBILE_SIZE} {
    margin: 30px 10px 0;
  }
`

const StyledBookingSubmit = styled(BookingSubmit)`
  margin-top: 30px;
`

export const TouchModalLayout = () => {
  return (
    <Wrapper>
      <HotelInfo />
      <BookingFormProvider>
        <StyledBookingForm />
        <StyledCostInfo />
        <StyledBookingSubmit />
      </BookingFormProvider>
    </Wrapper>
  )
}
