import styled from '@emotion/styled'
import {MEDIA_DEVICE_QUERY} from '@assets/theme/media'
import {Skeleton} from 'antd'

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
  margin-bottom: 20px;

  @media ${MEDIA_DEVICE_QUERY.TABLET} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${MEDIA_DEVICE_QUERY.MOBILE_LARGE} {
    grid-template-columns: 1fr;
  }
`

const FilterSkeleton = styled(Skeleton.Button)`
  min-height: 48px;

  & > span {
    border-radius: 12px !important;
  }
`

export const HotelScenarioRatesFiltersSkeleton = () => {
  return (
    <Wrapper>
      <FilterSkeleton active block />
      <FilterSkeleton active block />
      <FilterSkeleton active block />
      <FilterSkeleton active block />
    </Wrapper>
  )
}
