import styled from '@emotion/styled'
import {HotelInfo} from '../ui/hotel-info'
import {CostInfo} from '../ui/cost-info'
import {BookingForm} from '../ui/booking-form'
import {BookingSubmit} from '../ui/booking-submit'
import {BookingFormProvider} from '../ui/booking-form-provider'

const Wrapper = styled.div`
  display: flex;
  gap: 20px;
`

const LeftColumn = styled.div`
  padding: 16px;
  border-radius: 12px;
  border: 1px solid ${({theme}) => theme.palette.borderTertiary};
`

const RightColumn = styled.div``

const StyledBookingForm = styled(BookingForm)`
  margin-top: 30px;
`

const StyledBookingSubmit = styled(BookingSubmit)`
  margin-top: 30px;
`

const StyledCostInfo = styled(CostInfo)`
  position: sticky;
  top: 0;
`

export const DesktopModalLayout = () => {
  return (
    <Wrapper>
      <LeftColumn>
        <HotelInfo />
        <BookingFormProvider>
          <StyledBookingForm />
          <StyledBookingSubmit />
        </BookingFormProvider>
      </LeftColumn>
      <RightColumn>
        <StyledCostInfo />
      </RightColumn>
    </Wrapper>
  )
}
