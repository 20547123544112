import type {ApplicationCustomTheme} from '@assets/theme'
import {css} from '@emotion/react'
import {DEFAULT_LINK_STYLES} from '@ui/link/ui/link.base'

export const PRIMARY_LINK_STYLES = (theme: ApplicationCustomTheme) => css`
  color: ${theme.palette.textQuaternary};
  background-color: ${theme.palette.backgroundAccentPrimary};

  &:hover {
    background-color: ${theme.palette.backgroundAccentSecondary};
    color: ${theme.palette.textQuaternary};
  }

  &:active {
    background-color: ${theme.palette.backgroundAccentTertiary};
  }

  ${DEFAULT_LINK_STYLES(theme)}
`
