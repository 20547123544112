import styled from '@emotion/styled'
import {Calendar} from '@ui/calendar'
import {store} from '@store/index'
import type {DateRange} from 'react-day-picker'
import {observer, useLocalObservable} from 'mobx-react'
import {useEffect, useState} from 'react'
import {TODAY_DATE} from '@constants/date'
import {services} from '@/library/services'
import {ProviderRatesSearchingTypeEnum} from '@/library/api/gql/generate-types'
import {ENVIRONMENT} from '@/library/utils/guards/environment'
import {getEndOfTheNextYear} from '@/library/utils/date'
import {selectors} from '../../../store'
import {NUMBER_OF_MONTHS} from '../../lib/constants'

const Wrapper = styled.div`
  position: absolute;
  width: fit-content;
  max-height: 624px;
  overflow: auto;
  border: 1px solid ${({theme}) => theme.palette.borderTertiary};
  border-radius: 10px;
  background-color: ${({theme}) => theme.palette.backgroundQuaternary};
  z-index: 9;
  margin-top: 12px;
  box-shadow: 0px 4px 6px 0px ${({theme}) => theme.palette.boxShadowPrimary};

  display: flex;
  flex-direction: column;
  top: 76px;
  left: 50%;
  transform: translateX(-50%);
  gap: 24px;
`

const StyledCalendar = styled(Calendar)`
  --rdp-cell-size: 45px; /* Size of the day cells. */
`

export const SearchInputDatePopover = observer(() => {
  const {value: dateFrom} = store().search.dateFrom
  const {value: dateTo} = store().search.dateTo
  const {searchingType} = useLocalObservable(selectors.selectSearchingType)
  const isBestOffers = services.pages.global.scenario.isSearchTypeEqualValue(
    searchingType,
    ProviderRatesSearchingTypeEnum.BestOffers,
  )
  const [isDateClicked, setIsDateClicked] = useState(false)
  const [datesChosen, setDatesChosen] = useState(false)

  const [internalRange, setInternalRange] = useState<DateRange>({
    from: undefined,
    to: undefined,
  })

  // мин дата для выезда (не более месяца от въезда)
  const minDateTo = services.components.search.dateService.buildMinDateTo(
    internalRange.from,
  )

  // макс дата для выезда (не более месяца от въезда)
  const maxDateTo = services.components.search.dateService.buildMaxDateTo(
    internalRange.from,
  )

  // при первом открытии не блокировать даты
  const shouldDisableToDate = isDateClicked && internalRange.from

  const handleDayClick = (day: Date) => {
    services.components.search.dateService.handleDayClick(
      day,
      setIsDateClicked,
      setInternalRange,
      setDatesChosen,
      internalRange.from,
      internalRange.to,
    )
  }

  useEffect(() => {
    // При открытии попапа вносить даты из стора в стейт
    setInternalRange({
      from: dateFrom || undefined,
      to: dateTo || undefined,
    })
  }, [])

  useEffect(() => {
    // Когда выбраны новые даты въезда и выезда, сетит данные в стор и закрывает попап
    services.components.search.dateService.closeDateInputPopup(
      datesChosen,
      isBestOffers,
      internalRange.from,
      internalRange.to,
    )
  }, [datesChosen, isBestOffers, internalRange.from, internalRange.to])

  return (
    <Wrapper>
      <StyledCalendar
        mode="range"
        numberOfMonths={NUMBER_OF_MONTHS}
        min={ENVIRONMENT.SEARCH_CALENDAR_MIN_DAYS_COUNT}
        defaultMonth={dateFrom || TODAY_DATE}
        disabled={[
          {before: TODAY_DATE, after: getEndOfTheNextYear()},
          {
            before: isDateClicked ? minDateTo : TODAY_DATE,
            after: shouldDisableToDate ? maxDateTo : undefined,
          },
        ]}
        selected={{
          from: internalRange?.from,
          to: internalRange?.to,
        }}
        onDayClick={handleDayClick}
      />
    </Wrapper>
  )
})
