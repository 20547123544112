import {DateFormatPattern} from '@/types/enums/date-format-patterns'
import {format} from 'date-fns'
import type {Translate} from 'next-translate'
import {parseBackendDateFormat} from '@utils/date/formatting'

export const getPeriod = (
  t: Translate,
  startAt?: string | null,
  endAt?: string | null,
) => {
  if (startAt && endAt) {
    return t('period', {
      start: format(
        parseBackendDateFormat(startAt),
        DateFormatPattern.SHORT_YEAR_DATE,
      ),
      end: format(
        parseBackendDateFormat(endAt),
        DateFormatPattern.SHORT_YEAR_DATE,
      ),
    })
  }

  if (startAt) {
    return t('from', {
      from: format(
        parseBackendDateFormat(startAt),
        DateFormatPattern.SHORT_YEAR_DATE,
      ),
    })
  }

  if (endAt) {
    return t('until', {
      until: format(
        parseBackendDateFormat(endAt),
        DateFormatPattern.SHORT_YEAR_DATE,
      ),
    })
  }

  return ''
}
