// eslint-disable-next-line @typescript-eslint/ban-types
export const isFunction = (value: unknown): value is Function =>
  typeof value === 'function'

export const isNumber = (value: unknown): value is number =>
  typeof value === 'number'

export const isBoolean = (value: unknown): value is boolean =>
  typeof value === 'boolean'

export const isString = (value: unknown): value is string =>
  typeof value === 'string'

export const isNull = (value: unknown): value is null => value === null

export const isUndefined = (value: unknown): value is undefined =>
  value === undefined

export const isNotNullOrUndefined = <T>(
  value: T | null | undefined,
): value is T => value !== null && value !== undefined

export const isObject = (value: unknown): value is object =>
  typeof value === 'object' && value !== null

// eslint-disable-next-line unicorn/prefer-native-coercion-functions
export const isTruthy = <T>(value: T | null | undefined): value is T => {
  return Boolean(value)
}

export const isFalsy = (value: unknown): value is null | undefined | false =>
  !value

export const isArray = <T>(value: unknown): value is Array<T> =>
  Array.isArray(value)
