import {YandexMap} from '@components/ui'
import {observer} from 'mobx-react'
import {useLocalObservable} from 'mobx-react-lite'
import styled from '@emotion/styled'
import {
  normalizeNormalBoundsToMap,
  normalizeNormalCoordsToMap,
} from '@utils/map/normalize-coords'
import type {ComponentProps, FC} from 'react'
import {selectMapOptions} from '@slices/global/ui/search-scenario/map/lib/selectors'

const MapWrapper = styled.aside`
  height: 100%;
  width: 100%;
  padding-bottom: 12px;
  box-shadow: 0 4px 20px ${({theme}) => theme.palette.boxShadowPrimary};
  border-radius: 12px;
  position: relative;
`

interface Props {
  cluster: ComponentProps<typeof YandexMap>['cluster']
  marker: ComponentProps<typeof YandexMap>['marker']
}

export const SearchScenarioMapDesktop: FC<Props> = observer(
  ({cluster, marker}) => {
    const {zoom, center, bounds, points, loading} =
      useLocalObservable(selectMapOptions)

    return (
      <MapWrapper>
        <YandexMap
          loading={loading}
          cluster={cluster}
          marker={marker}
          map={{
            theme: 'light',
            mode: 'vector',
            location: {
              center: normalizeNormalCoordsToMap(center),
              zoom,
              easing: 'ease-in-out',
              duration: 500,
              bounds: normalizeNormalBoundsToMap(bounds),
            },
          }}
          points={points || []}
        />
      </MapWrapper>
    )
  },
)
