import {type RefObject, useEffect} from 'react'

type Event = 'mousedown' | 'click'

/**
 * useOutsideClick хук позволяет обрабатывать клики за пределами определенного элемента.
 * @param ref Ссылка на элемент, за пределами которого нужно обрабатывать клики.
 * @param callback Функция обратного вызова, которая будет вызвана при клике за пределами элемента.
 * @param isActive Флаг, указывающий, активен ли хук в данный момент. Нужен для того, что бы отключать ивент обработки клика вне элемента, если он не нужен, можно по умолчанию выставить true
 * @param event
 */
export const useOutsideClick = <Element extends HTMLElement>(
  ref: RefObject<Element>,
  callback: () => void,
  isActive: boolean,
  event: Event = 'mousedown',
): void => {
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isActive) {
      const handleClickOutside = (event: MouseEvent) => {
        if (
          ref.current &&
          event &&
          !ref.current.contains(event.target as Node)
        ) {
          callback()
        }
      }

      document.addEventListener(event, handleClickOutside)
      return () => {
        document.removeEventListener(event, handleClickOutside)
      }
    }
  }, [ref, callback, isActive, event])
}
