import styled from '@emotion/styled'
import {Button} from '@ui/button'
import {observer} from 'mobx-react'
import useTranslation from 'next-translate/useTranslation'
import {store} from '@store/index'
import {useLocalObservable} from 'mobx-react-lite'
import {Skeleton} from 'antd'

const StyledResetFilters = styled(Button)`
  width: 100%;
`

const StyledSkeleton = styled(Skeleton.Button)`
  width: 100%;
`

const selectSearchFiltersLoading = () => ({
  get loading() {
    return store().pages.global.search.filters.loading
  },
})

export const SearchScenarioFiltersResetButton = observer(() => {
  const {t} = useTranslation('global.search')
  const {loading} = useLocalObservable(selectSearchFiltersLoading)
  const {isStartSearch} = store().search

  const handleResetFilters = () => {
    store().pages.global.search.resetFilters()
  }

  if (loading) {
    return <StyledSkeleton active block />
  }

  if (!store().pages.global.search.filters.isCanResetFilters()) {
    return null
  }

  return (
    <StyledResetFilters onClick={handleResetFilters} disabled={isStartSearch}>
      {t('filters.reset_filters')}
    </StyledResetFilters>
  )
})
