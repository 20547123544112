import {GlobalHydrateService} from '@services/common/global-hydrate'
import type {ApplicationProperties} from '@/types/ui'
import type {
  BookingItem,
  BookingStatusEnum,
  HotelItem,
} from '@api/gql/generate-types'
import {store} from '@store/index'
import type {MapPoints} from '@/library/components/ui/yandex-map'
import {services} from '../..'

interface HydrationInitialData extends ApplicationProperties {
  booking: BookingItem | null
}

export class BookingPageService extends GlobalHydrateService<HydrationInitialData> {
  private get state() {
    return store().pages.booking
  }

  public hydrate(initialData: HydrationInitialData): void {
    if (initialData.booking) {
      this.state.setBooking(initialData.booking)
      this.state.setHotelMapPoint(
        services.pages.booking.buildHotelToMapPoint(initialData.booking.hotel),
      )
    }
    super.hydrate(initialData)
  }

  public setBookingStatus = (status: BookingStatusEnum) => {
    if (this.state.booking) {
      this.state.setBooking({...this.state.booking, status})
    }
  }

  public buildHotelToMapPoint = (inputHotel: HotelItem): MapPoints | null => {
    const hotel = inputHotel || this.state.booking?.hotel

    if (!hotel || !hotel.lat || !hotel.lng) {
      return null
    }

    return {
      id: String(hotel.id),
      type: 'Feature',
      properties: {
        ...hotel,
        image_url: hotel.mainImage?.url,
        seo_url: hotel.seoUrl?.url,
      },
      geometry: {
        type: 'Point',
        coordinates: [hotel.lng, hotel.lat],
      },
    }
  }
}
