import styled from '@emotion/styled'
import {Text} from '@/library/components/ui'

export const InlineText = styled.span`
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
`

export const RulesText = styled(Text)`
  font-weight: 500;
  color: ${({theme}) => theme.palette.textPrimary};
`

export const RulesListItemText = styled(RulesText)`
  margin-bottom: 8px;
  &:last-of-type {
    margin-bottom: 0;
  }
`

export const InlineAmount = styled(InlineText)`
  color: ${({theme}) => theme.palette.textAccentPrimary};
`

export const InlineCurrency = styled(InlineText)`
  color: ${({theme}) => theme.palette.textSecondary};
`
