import styled from '@emotion/styled'
import {Button, Text} from '@components/ui'
import {Icon, type IconName} from '@ui/icon'
import {type ComponentProps, type FC, memo, type PropsWithChildren} from 'react'

const Wrapper = styled.div``

const Title = styled(Text)`
  color: ${({theme}) => theme.palette.textPrimary};
  font-weight: 500;
  margin-bottom: 18px;
`

const ObjectItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 17px;
`

const ObjectItem = styled(Button)`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 15px;
  width: 100%;
`

const ObjectItemIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  min-width: 32px;
  min-height: 32px;
  border-radius: 12px;
  border: 1px solid ${({theme}) => theme.palette.borderTertiary};
  box-shadow: 0px 4px 6px 0px ${({theme}) => theme.palette.boxShadowPrimary};
`

const ObjectItemIcon = styled(Icon)`
  color: ${({theme}) => theme.palette.backgroundSecondary};
`

const ObjectItemTextWrapper = styled.div`
  width: 80%;
`

const ObjectItemTitle = styled(Text)`
  color: ${({theme}) => theme.palette.textPrimary};
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const ObjectItemSubTitle = styled(Text)`
  color: ${({theme}) => theme.palette.textSecondary};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

interface PropsContent extends PropsWithChildren {
  title: string
}

const Content: FC<PropsContent> = ({title, children}) => {
  return (
    <Wrapper>
      <Title size="S">{title}</Title>
      <ObjectItemsWrapper>{children}</ObjectItemsWrapper>
    </Wrapper>
  )
}

interface PropsItem extends ComponentProps<typeof Button> {
  iconName: IconName
  title: string
  subtitle: string
}

const Item: FC<PropsItem> = memo(
  ({iconName, title, subtitle, ...properties}) => {
    return (
      <ObjectItem variant="text" {...properties}>
        <ObjectItemIconWrapper>
          <ObjectItemIcon name={iconName} width={15} height={15} />
        </ObjectItemIconWrapper>
        <ObjectItemTextWrapper>
          <ObjectItemTitle size="S">{title}</ObjectItemTitle>
          <ObjectItemSubTitle size="XS">{subtitle}</ObjectItemSubTitle>
        </ObjectItemTextWrapper>
      </ObjectItem>
    )
  },
)

Item.displayName = 'DestinationSearchObjectItem'

export const DestinationSearchObject = {
  Content,
  Item,
}
