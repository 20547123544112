import {HotelItemPriorityEnum} from '@api/gql/generate-types'

export const BEST_OFFER_SEARCH_PRIORY_TYPES = new Set([
  HotelItemPriorityEnum.Low,
  HotelItemPriorityEnum.Medium,
  HotelItemPriorityEnum.High,
  HotelItemPriorityEnum.Highest,
])

export const NO_HOTEL_IMAGE_SOURCE = '/images/hotel/no-image.svg'

export const HOTEL_RATING_FRACTION_DIGITS = 1
