import type {IBitrix} from '@/types/bitrix'
import {isClient} from '@utils/next'

const BITRIX_MOBILE_BODY_CLASSNAME = 'bx-livechat-mobile-state'
const BITRIX_BUTTON_CLASSNAME = '.b24-widget-button-inner-container'

export class BitrixService {
  private bitrix: IBitrix | null = null

  public initBitrix() {
    if (this.bitrix) {
      return
    }

    if (isClient() && 'BX' in window) {
      this.bitrix = window.BX
    } else {
      throw new Error('Bitrix can\'t be initiazlied')
    }
  }

  public hasBeenSetup() {
    return this.bitrix !== null
  }

  public hideBitrix() {
    if (this.bitrix) {
      this.bitrix.SiteButton.shadow.hide()
      this.bitrix.SiteButton.wm.hide()
      this.bitrix.LiveChat.closeLiveChat()
      this.bitrix.SiteButton.hide()
      document.body.classList.remove(BITRIX_MOBILE_BODY_CLASSNAME)
    }
  }

  public showBitrix() {
    if (this.bitrix) {
      this.bitrix.SiteButton.show()
    }
  }

  public showBitrixMenu() {
    if (this.bitrix) {
      const buttonButton = document.querySelectorAll<HTMLButtonElement>(
        BITRIX_BUTTON_CLASSNAME,
      )[0]
      buttonButton.click()
    }
  }

  public showBitrixLiveChat() {
    if (this.bitrix) {
      this.bitrix.LiveChat.openLiveChat()
    }
  }
}
