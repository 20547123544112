import styled from '@emotion/styled'
import {Text} from '@components/ui'
import {observer} from 'mobx-react'
import {Icon} from '@ui/icon'
import {store} from '@store/index'
import useTranslation from 'next-translate/useTranslation'
import {ImageWithSkeleton} from '@ui/image-with-skeleton'

const Wrapper = styled.div`
  padding: 20px 17px;
  border: 1px solid ${({theme}) => theme.palette.borderTertiary};
  border-radius: 12px;
`

const Title = styled.h2`
  margin-bottom: 27px;
  font-weight: 500;
  font-size: 20px;
  color: ${({theme}) => theme.palette.textPrimary};
`

const FacilitiesWrapper = styled.div`
  column-gap: 32px;
  columns: 3 200px;
`

const FacilitiesGroup = styled.div`
  -webkit-column-break-inside: avoid;
  -moz-column-break-inside: avoid;
  column-break-inside: avoid;
  margin-bottom: 31px;
`

const FacilitiesGroupHeader = styled.div`
  display: flex;
  gap: 12px;
  justify-content: start;
  align-content: center;
  flex-wrap: wrap;
  margin-bottom: 18px;

  svg {
    color: ${({theme}) => theme.palette.textPrimary};
  }
`

const StyledImage = styled(ImageWithSkeleton)`
  .skeleton {
    max-width: 16px;
    max-height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const FacilitiesGroupHeaderTitle = styled(Text)`
  color: ${({theme}) => theme.palette.textPrimary};
  font-weight: 500;

  display: flex;
  justify-content: center;
  align-items: center;
`

const FacilitiesGroupHeaderIcon = styled(Icon)`
  color: ${({theme}) => theme.palette.textPrimary};
`

const FacilitiesGroupItemWrapper = styled.ul``

const FacilitiesGroupItem = styled.li`
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 400;
  color: ${({theme}) => theme.palette.textPrimary};
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
  line-height: 20px;
`

const FacilitiesGroupItemTitle = styled(Text)`
  margin-top: 2px;
`

export const HotelScenarioFacilities = observer(() => {
  const {data} = store().pages.global.hotel
  const {t} = useTranslation('global.hotel')

  return (
    <Wrapper>
      <Title>{t('facilities.title')}</Title>
      <FacilitiesWrapper>
        {data?.facilitiesGroups?.map((facilityGroup) => {
          return (
            <FacilitiesGroup key={facilityGroup.group?.slug}>
              <FacilitiesGroupHeader>
                {facilityGroup.group?.icon_url ? (
                  <StyledImage
                    src={facilityGroup.group.icon_url}
                    alt="facility group icon"
                    width={25}
                    height={25}
                  />
                ) : (
                  <FacilitiesGroupHeaderIcon
                    name="help"
                    width={25}
                    height={25}
                  />
                )}
                <FacilitiesGroupHeaderTitle size="S" type="h3">
                  {facilityGroup.group?.name}
                </FacilitiesGroupHeaderTitle>
              </FacilitiesGroupHeader>
              <FacilitiesGroupItemWrapper>
                {facilityGroup.facilities?.map((facility) => (
                  <FacilitiesGroupItem
                    key={`${facilityGroup.group?.slug}${facility.name}`}
                  >
                    {facility?.icon_url ? (
                      <StyledImage
                        src={facility.icon_url}
                        alt="facility icon"
                        width={16}
                        height={16}
                      />
                    ) : (
                      <FacilitiesGroupHeaderIcon
                        name="help"
                        width={16}
                        height={16}
                      />
                    )}
                    <FacilitiesGroupItemTitle size="XS">
                      {facility.name}
                    </FacilitiesGroupItemTitle>
                  </FacilitiesGroupItem>
                ))}
              </FacilitiesGroupItemWrapper>
            </FacilitiesGroup>
          )
        })}
      </FacilitiesWrapper>
    </Wrapper>
  )
})
