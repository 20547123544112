import {api} from '@/library/api'
import {
  AnalyticsBookingStatuses,
  BookingGuestType,
} from '@/library/api/gql/generate-types'
import {services} from '@/library/services'
import {store} from '@/library/store'
import {getPathname} from '@/library/utils/router/get-pathname'
import type {Translate} from 'next-translate'
import type {NextRouter} from 'next/router'
import {update} from 'ramda'
import {BookingGuestBuilder} from './guest-builder'
import {BalanceService} from './balance'
import {PromoCodeService} from './promo-code'
import {FormBookingService} from './form-booking'

interface StartBookingContext {
  t: Translate
  router: NextRouter
  rateProvider: HotelScenarioProvider
  room: HotelScenarioRoomRate
  rate: HotelScenarioRate
  paymentType: HotelScenarioRatePaymentType
}

export class GlobalPageHotelBookingService {
  public get bookingState() {
    return store().bookingModal.modal
  }

  public readonly guestBuilder = new BookingGuestBuilder()

  public readonly balance = new BalanceService()

  public readonly promoCode = new PromoCodeService()

  public readonly formBooking = new FormBookingService()

  public setMainGuest = (t: Translate) => {
    const {profile} = store().user
    const {guestsData} = this.bookingState
    const mainGuest = this.guestBuilder.createData({
      t,
      type: BookingGuestType.Main,
      profile,
    })

    if (!guestsData || guestsData.length === 0) {
      this.bookingState.setGuestsData([mainGuest])
      return this.guestBuilder.mapDataToForm(mainGuest)
    }

    this.bookingState.setGuestsData(update(0, mainGuest, guestsData))

    return this.guestBuilder.mapDataToForm(mainGuest)
  }

  public startBooking = ({
    t,
    router,
    rateProvider,
    room,
    rate,
    paymentType,
  }: StartBookingContext) => {
    const {guests} = store().search
    this.bookingState.setIsOpen(true)
    this.bookingState.setRateProvider(rateProvider)
    this.bookingState.setRoom(room)
    this.bookingState.setRate(rate)
    this.bookingState.setPaymentType(paymentType)
    this.bookingState.setGuestsData(
      this.guestBuilder.buildBookingData(
        t,
        guests.adults.amount,
        guests.children.children,
      ),
    )
    if (guests.children.children.length > 0) {
      this.bookingState.setShowAllGuests(true)
    }
    services.common.analytics.pushBookingStatus(
      AnalyticsBookingStatuses.BookStart,
      getPathname(router),
    )
    services.common.yandexMetric.startBooking()
  }

  public reset() {
    this.bookingState.reset()
    this.promoCode.state.reset()
    this.balance.state.reset()
    this.formBooking.state.reset()
  }

  public stopBooking = (router: NextRouter) => {
    this.reset()
    services.common.analytics.pushBookingStatus(
      AnalyticsBookingStatuses.BookFormDenied,
      getPathname(router),
    )
  }

  public getMinimumBookingAmount = async () => {
    if (!this.bookingState.minimumBookingAmount) {
      api.booking
        .getMinimumBookingAmount({})
        .then((minimumBookingAmount) =>
          this.bookingState.setMinimumBookingAmount(
            minimumBookingAmount || null,
          ),
        )
    }
  }
}
