import {css} from '@emotion/react'

export const ButtonShapeVariants = {
  default: css`
    border-radius: 12px;
  `,
  round: css`
    border-radius: 100px;
  `,
  circle: css`
    border-radius: 100%;
  `,
}

export type ButtonShape = keyof typeof ButtonShapeVariants
