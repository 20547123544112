import styled from '@emotion/styled'
import type {FC, PropsWithChildren} from 'react'
import {Text} from '@/library/components/ui'
import {Icon, type IconName} from '@/library/components/ui/icon'
import {useDeviceDetection} from '@/library/hooks/use-device-detection'

const Wrapper = styled.div``

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 12px;
`

const Title = styled(Text)``

const StyledIcon = styled(Icon)`
  color: ${({theme}) => theme.palette.textPrimary};
`

interface RuleProps {
  iconName?: IconName
  title: string
  className?: string
}

export const Rule: FC<PropsWithChildren<RuleProps>> = ({
  children,
  iconName,
  title,
  className,
}) => {
  const mobile = useDeviceDetection('mobile')
  return (
    <Wrapper className={className}>
      <TitleWrapper>
        {iconName ? (
          <StyledIcon width={24} height={24} name={iconName} />
        ) : null}
        <Title size={mobile ? 'boldS' : 'boldSM'} type="p">
          {title}
        </Title>
      </TitleWrapper>
      {children}
    </Wrapper>
  )
}
