import {Container} from '@/library/components/layouts'
import {Illustration} from '@/library/components/ui/illustration'
import {useDeviceDetection} from '@/library/hooks/use-device-detection'
import {SeoProvider} from '@/library/providers/seo'
import styled from '@emotion/styled'
import {Button, Link, Text} from '@/library/components/ui'
import useTranslation from 'next-translate/useTranslation'
import {MEDIA_DEVICE_QUERY} from '@/assets/theme/media'
import {Header} from '@/library/components/features'
import {Footer} from '@/library/components/features/footer'
import {Title} from '@/library/components/ui/typography'
import {Routes} from '../../types/enums/routes'

const InternalErrorContainer = styled(Container)`
  width: 100%;
  margin: 50px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const InternalErrorTitle = styled(Title)`
  margin-top: 36px;

  @media ${MEDIA_DEVICE_QUERY.MOBILE_LARGE} {
    margin-top: 24px;
  }
`

const InternalErrorSubtitle = styled(Text)`
  margin-top: 12px;
  @media ${MEDIA_DEVICE_QUERY.MOBILE_LARGE} {
    margin-top: 8px;
  }
`

const NavigationWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 30px;

  @media (max-width: 580px) {
    flex-direction: column;
    margin-top: 20px;
    width: 100%;
  }
`

const HomeLink = styled(Link)`
  margin-right: 20px;

  @media (max-width: 580px) {
    margin-right: unset;
    width: 100%;
  }
`

const HomeButton = styled(Button)`
  width: 190px;
  height: 58px;
  text-transform: uppercase;

  @media (max-width: 580px) {
    width: 100%;
  }
`

const SupportLink = styled(Link)`
  @media (max-width: 580px) {
    margin-top: 20px;
    width: 100%;
  }
`

const SupportButton = styled(Button)`
  width: 298px;
  height: 58px;
  text-transform: uppercase;

  @media (max-width: 580px) {
    width: 100%;
  }
`

const Content = () => {
  const {t} = useTranslation('internal-error')
  const isMobile = useDeviceDetection('mobile')

  return (
    <SeoProvider
      config={{
        title: t('seo.title'),
        description: t('seo.description'),
        author: t('seo.author'),
        keywords: t('seo.keywords'),
      }}
    >
      <Header />
      <InternalErrorContainer>
        <Illustration
          name="internal_error"
          width="100%"
          height={isMobile ? 160 : 230}
        />
        <InternalErrorTitle>{t('title')}</InternalErrorTitle>
        <InternalErrorSubtitle size={isMobile ? 'S' : 'M'}>
          {t('subtitle')}
        </InternalErrorSubtitle>
        <NavigationWrapper>
          <HomeLink href={Routes.HOME}>
            <HomeButton variant="secondary">{t('home')}</HomeButton>
          </HomeLink>
          <SupportLink href={Routes.SUPPORT}>
            <SupportButton>{t('support')}</SupportButton>
          </SupportLink>
        </NavigationWrapper>
      </InternalErrorContainer>
      <Footer />
    </SeoProvider>
  )
}

export default Content
