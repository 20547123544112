import {ENVIRONMENT} from '@/library/utils/guards/environment'
import {isClient} from '@/library/utils/next'
import type {IYanderMetric} from '@/types/yandex-metric'

export class YanderMetricService {
  private readonly yandex_metrics_id = ENVIRONMENT.YM_ZBRU

  private submitEvent(
    action: Parameters<IYanderMetric>['1'],
    value: Parameters<IYanderMetric>['2'],
  ) {
    if (isClient()) {
      window.ym(this.yandex_metrics_id, action, value)
    } else {
      throw new Error(
        'submit Event of YanderMetricService must be called in client environment',
      )
    }
  }

  public startBooking() {
    this.submitEvent('reachGoal', '_zabronirovat')
  }

  public submitBooking() {
    this.submitEvent('reachGoal', 'prodolzhit')
  }

  public submitBookingSuccess() {
    this.submitEvent('reachGoal', 'zabronirovano')
  }
}
