import {
  useEffect,
  useState,
  type Dispatch,
  type FC,
  type SetStateAction,
} from 'react'
import {type PopoverProps} from 'antd/lib/popover'
import dynamic from 'next/dynamic'
import {css, useTheme} from '@emotion/react'
import styled from '@emotion/styled'
import {isBoolean, isFunction} from '@/library/utils/guards/types'
import {LOADING_STYLES} from '@/library/constants/css'
import {throwComponentException} from '@/library/utils/exceptions/components'
import {CircularLoader} from '../loader'
import {Icon} from '../icon'
import {PrimaryClose} from './close-buttons/close.primary'
import {SecondaryClose} from './close-buttons/close.secondary'

const LibraryPopover = dynamic(() => import('antd/lib/popover'))

const PopoverContentWrapper = styled.div<{$isLoading: boolean}>`
  padding: 20px 24px;
  position: relative;

  ${({$isLoading}) =>
    $isLoading &&
    css`
      ${LOADING_STYLES}
    `}
`

const StyledCircucalLoader = styled(CircularLoader)`
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
`

export const CLOSE_VARIANTS = {
  primary: PrimaryClose,
  secondary: SecondaryClose,
}

export type CloseVariant = keyof typeof CLOSE_VARIANTS

interface IPopoverWithCloseProps extends PopoverProps {
  isLoading?: boolean
  parentOpen?: boolean
  parentSetOpen?: Dispatch<SetStateAction<boolean>>
  closeVariant: CloseVariant
}

export const PopoverWithClose: FC<IPopoverWithCloseProps> = ({
  children,
  trigger = 'click',
  content,
  className,
  isLoading = false,
  // Если нужно контроллировать открытие извне, нужно передать parentOpen и parentSetOpen
  parentOpen,
  parentSetOpen,
  closeVariant = 'primary',
  ...properties
}) => {
  const theme = useTheme()
  const [open, setOpen] = useState(false)

  const VariantClose = CLOSE_VARIANTS[closeVariant]

  if (!VariantClose) {
    throwComponentException(
      `Close button by variant: ${closeVariant} in popover with close not found`,
    )
  }

  const handleClose = () => {
    setOpen(false)

    parentSetOpen?.(false)
  }

  const handleOpenChange = (value: boolean) => {
    setOpen(value)

    parentSetOpen?.(value)
  }

  useEffect(() => {
    if (isBoolean(parentOpen)) {
      setOpen(parentOpen)
    }
  }, [parentOpen])

  return (
    <LibraryPopover
      trigger={trigger}
      open={open}
      overlayInnerStyle={{
        padding: '0',
        borderRadius: 12,
        color: theme.palette.textPrimary,
        ...properties.overlayInnerStyle,
      }}
      content={
        <PopoverContentWrapper className={className} $isLoading={isLoading}>
          <VariantClose variant="primary">
            <Icon name="close" width={24} height={24} onClick={handleClose} />
          </VariantClose>

          {isLoading && <StyledCircucalLoader size={32} />}
          {isFunction(content) ? content() : content}
        </PopoverContentWrapper>
      }
      onOpenChange={handleOpenChange}
      {...properties}
    >
      {children}
    </LibraryPopover>
  )
}
