import styled from '@emotion/styled'
import {BaseButton} from './button.base'

export const PrimaryButton = styled(BaseButton)`
  color: ${({theme}) => theme.palette.textQuaternary};
  background-color: ${({theme}) => theme.palette.backgroundAccentPrimary};

  &:hover {
    background-color: ${({theme}) => theme.palette.backgroundAccentSecondary};
  }

  &:active {
    background-color: ${({theme}) => theme.palette.backgroundAccentTertiary};
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${({theme}) => theme.palette.backgroundTertiary};
  }
`
