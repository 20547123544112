import {ENVIRONMENT} from '@/library/utils/guards/environment'

interface Address extends Record<string, unknown> {
  '@type': 'PostalAddress'
  streetAddress: string
  addressLocality: string
  addressRegion: string
  postalCode: string
  addressCountry: string
}

interface Organization extends Record<string, unknown> {
  '@context': string
  '@type': 'Organization'
  address: Address
  url: string
  name: string
  logo: string
  sameAs: string[]
}

interface OrganizationProps {
  origin: string
}

export const buildOrganizationLdProps = ({
  origin,
}: OrganizationProps): Organization => {
  return {
    '@context': ENVIRONMENT.SCHEMA_URL,
    '@type': 'Organization',
    name: ENVIRONMENT.SCHEMA_ORGANIZATION_NAME,
    url: origin,
    logo: ENVIRONMENT.SCHEMA_ORGANIZATION_LOGO,
    address: {
      '@type': 'PostalAddress',
      streetAddress: ENVIRONMENT.SCHEMA_ORGANIZATION_STREET,
      addressLocality: ENVIRONMENT.SCHEMA_ORGANIZATION_CITY,
      addressRegion: ENVIRONMENT.SCHEMA_ORGANIZATION_REGION,
      postalCode: ENVIRONMENT.SCHEMA_ORGANIZATION_POSTAL_CODE,
      addressCountry: ENVIRONMENT.SCHEMA_ORGANIZATION_COUNTRY,
    },
    sameAs: [
      ENVIRONMENT.YOUTUBE,
      ENVIRONMENT.TG,
      ENVIRONMENT.VK,
      ENVIRONMENT.DZEN,
    ],
  }
}
