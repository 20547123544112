import {store} from '@store/index'
import {api} from '@api/index'
import {GlobalPageHotelFetchQueryBuilder} from '@services/pages/global/scenario/hotel/fetch-query-builder'
import {getMapBoundsFromCoords} from '@utils/map/get-bounds-from-coords'
import {getCenterFromBounds} from '@utils/map/get-center-from-bounds'

export class GlobalPageHotelMapService {
  public get state() {
    return store().pages.global.hotel.map
  }

  // eslint-disable-next-line no-useless-constructor
  constructor(
    private readonly fetchBuilder: GlobalPageHotelFetchQueryBuilder,
  ) {}

  public async load() {
    try {
      this.state.resetState()
      this.state.setLoading(true)

      const parameters = this.fetchBuilder.buildHotelRatesMapInitQuery()

      const {searchId} = await api.map.getHotelRatesMapInit({
        variables: {
          params: parameters,
        },
      })

      const hotel = store().pages.global.hotel.data

      if (hotel && hotel.lat && hotel.lng) {
        const bounds = getMapBoundsFromCoords(
          [
            [hotel.lat, hotel.lng],
            [hotel.lat, hotel.lng],
          ],
          0.002,
        )
        if (bounds) {
          this.state.setBounds(bounds)
          const center = getCenterFromBounds(bounds)
          this.state.setCenter(center)
        }
      }

      return {searchId}
    } catch {
      this.state.resetState()
      this.state.setLoading(false)

      return null
    }
  }
}
