import {observer, useLocalObservable} from 'mobx-react'
import {store} from '@store/index'
import styled from '@emotion/styled'
import {SearchScenarioHotelCard} from '@slices/global/ui/search-scenario/hotels/hotel-card'
import {HotelCardSkeleton} from '@slices/global/ui/search-scenario/hotels/hotel-card/hotel-card.skeleton'
import {isEmpty, range} from 'ramda'
import {DEFAULT_FIRST_PAGE_ITEMS} from '@constants/common'
import {useDeviceDetection} from '@hooks/use-device-detection'
import {css} from '@emotion/react'
import {HotelCardNotFound} from '@slices/global/ui/search-scenario/hotels/hotel-card/hotel-card.not-found'
import {SearchScenarioHotelPagination} from '@slices/global/ui/search-scenario/hotels/hotel-pagination'
import {MEDIA_DEVICE_QUERY} from '@assets/theme/media'
import {SEARCH_SCENARIO_HOTELS_CARDS_ID} from '@constants/dom-ids'
import {type FC, type PropsWithChildren, useEffect} from 'react'
import {useWindowSize} from '@hooks/use-window-size'
import {TOUCH_VERSION_POINT} from '@slices/global/ui/search-scenario/constants'
import {SearchScenarioHotelsSorting} from '@slices/global/ui/search-scenario/hotels/hotel-sorting'
import {selectMapOptions} from '../map/lib/selectors'
import {HotelCardProgressBar} from './hotel-card/hotel-card.progress-bar'

const Wrapper = styled.div<{$isTouch: boolean}>`
  overflow: auto;
  overflow-x: hidden !important;
  height: 100%;
  padding-right: 10px;

  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  padding-bottom: 10px;

  ${({$isTouch}) =>
    $isTouch &&
    css`
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      overflow: hidden;
      padding-right: 0;
    `}
`

const FiltersSortWrapper = styled.div`
  display: flex;
  gap: 10px;

  width: 100%;
  margin-bottom: 10px;
`

const HotelsCardsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media ${MEDIA_DEVICE_QUERY.LAPTOP_MEDIUM} {
    gap: 10px;
  }
`

const Footer = styled.div`
  width: 100%;
  margin-top: 20px;
`

const PaginationWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ProgressBarWrapper = styled.div`
  width: 100%;
  margin-bottom: 10px;
`

const Container: FC<PropsWithChildren> = ({children}) => {
  const isTouch = useDeviceDetection('touch')
  const windowWidth = useWindowSize()
  const isTouchLayout = isTouch && windowWidth <= TOUCH_VERSION_POINT

  return (
    <Wrapper id={SEARCH_SCENARIO_HOTELS_CARDS_ID} $isTouch={isTouch}>
      {!isTouchLayout && (
        <FiltersSortWrapper>
          <SearchScenarioHotelsSorting />
        </FiltersSortWrapper>
      )}
      <HotelsCardsWrapper>{children}</HotelsCardsWrapper>
      <Footer>
        <PaginationWrapper>
          <SearchScenarioHotelPagination />
        </PaginationWrapper>
      </Footer>
    </Wrapper>
  )
}

export const SearchScenarioHotels = observer(() => {
  const isTouch = useDeviceDetection('touch')
  const {search, seoRates} = store().pages.global
  const {data, loading, isLoadedHotels, isShowSeoRates} = search.hotels
  const {loading: mapLoading} = useLocalObservable(selectMapOptions)

  useEffect(() => {
    search.hotels.setIsShowSeoRates(false)
  }, [search.hotels])

  if (isShowSeoRates) {
    return (
      <Container>
        {seoRates?.map((hotel) => (
          <SearchScenarioHotelCard
            key={hotel.id}
            hotel={hotel}
            mapLoading={mapLoading}
          />
        ))}
      </Container>
    )
  }

  if (loading || !isLoadedHotels) {
    return (
      <Wrapper $isTouch={isTouch}>
        <ProgressBarWrapper>
          <HotelCardProgressBar />
        </ProgressBarWrapper>
        {range(0, DEFAULT_FIRST_PAGE_ITEMS).map((id) => {
          return <HotelCardSkeleton key={id} />
        })}
      </Wrapper>
    )
  }

  if (!data || isEmpty(data)) {
    return (
      <Container>
        <HotelCardNotFound />
      </Container>
    )
  }

  return (
    <Container>
      {data?.map((hotel) => (
        <SearchScenarioHotelCard
          key={hotel.id}
          hotel={hotel}
          mapLoading={mapLoading}
        />
      ))}
    </Container>
  )
})
