import {css} from '@emotion/react'
import styled from '@emotion/styled'
import type {Dispatch, FC, SetStateAction} from 'react'
import {isFunction} from '@/library/utils/guards/types'
import {TRANSITION_STYLES, DISABLED_STYLES} from '@/library/constants/css'
import {Icon, type IconName} from '../../../icon'
import type {ControlType} from '../../lib/types'

export const Label = styled.label`
  width: fit-content;
  cursor: pointer;
`

const IconWrapper = styled.div<{
  disabled?: boolean
  hasClickHandleExist: boolean
}>`
  position: absolute;
  right: 12px;
  top: 0;

  width: 24px;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: ${({hasClickHandleExist}) =>
    hasClickHandleExist ? 'pointer' : 'auto'};

  svg {
    transition: color ${TRANSITION_STYLES};
  }

  &:hover {
    svg {
      color: ${({theme}) => theme.palette.textAccentPrimary};
    }
  }

  ${({disabled}) =>
    disabled &&
    css`
      ${DISABLED_STYLES}
    `}
`

interface IProps {
  iconWidth: number
  iconHeight: number
  disabled?: boolean
  icon?: IconName
  iconOnClick?: () => void
  type: ControlType
  currentType: ControlType
  setCurrentType: Dispatch<SetStateAction<ControlType>>
}

export const InputIcon: FC<IProps> = ({
  disabled,
  iconOnClick,
  iconHeight,
  iconWidth,
  icon,
  type,
  currentType,
  setCurrentType,
}) => {
  if (type === 'password') {
    const handleEyeClick = () => {
      setCurrentType(currentType === 'password' ? 'text' : 'password')
      iconOnClick?.()
    }

    return (
      <IconWrapper
        disabled={disabled}
        onClick={handleEyeClick}
        hasClickHandleExist
      >
        <Icon
          name={currentType === 'password' ? 'eye' : 'eye_close'}
          width={iconWidth}
          height={iconHeight}
        />
      </IconWrapper>
    )
  }

  if (!icon) {
    return null
  }

  return (
    <IconWrapper
      disabled={disabled}
      onClick={iconOnClick}
      hasClickHandleExist={isFunction(iconOnClick)}
    >
      <Icon name={icon} width={iconWidth} height={iconHeight} />
    </IconWrapper>
  )
}
