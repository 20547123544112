import useTranslation from 'next-translate/useTranslation'
import {useEffect, useState, type FC} from 'react'
import DOMPurify from 'isomorphic-dompurify'
import {Tooltip} from '@/library/components/ui/tooltip'
import styled from '@emotion/styled'
import {Text} from '@/library/components/ui'
import {Icon} from '@/library/components/ui/icon'
import Countdown from 'react-countdown'
import {store} from '@/library/store'
import {BookingStatusEnum} from '@/library/api/gql/generate-types'
import {TRANSITION_STYLES} from '@/library/constants/css'
import {calculatePaymentDate, isBookingStatusPayable} from '../lib/helpers'
import {MINUTES_OFFSET} from '../lib/constants'

const TimeLeftText = styled(Text)`
  color: ${({theme}) => theme.palette.textPositive};

  @media (max-width: 800px) {
    text-align: center;
  }
`

const Minutes = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: inherit;
  white-space: nowrap;
  line-height: inherit;
  font-weight: 700;
  color: ${({theme}) => theme.palette.textBlue};
`

const Question = styled(Icon)`
  margin-left: 5px;
  cursor: pointer;
  transition: all ${TRANSITION_STYLES};

  &:hover {
    color: ${({theme}) => theme.palette.textAccentPrimary};
  }
`

const TimeLeftWrapper = styled.div``

interface BookingTimerProps {
  booking: UserBooking
  className?: string
}

export const BookingTimer: FC<BookingTimerProps> = ({booking, className}) => {
  const {t} = useTranslation('profile.bookings')
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [])

  if (
    !booking.must_pay_before_finish_seconds_left ||
    !mounted ||
    !isBookingStatusPayable(booking.status)
  ) {
    return null
  }

  return (
    <TimeLeftWrapper className={className}>
      <TimeLeftText size="S">
        {t('forgot_to_pay_part_1')}{' '}
        <Minutes>
          <Countdown
            date={calculatePaymentDate(
              booking.must_pay_before_finish_seconds_left,
            )}
            renderer={({minutes}) =>
              t('forgot_to_pay_part_2', {count: minutes + MINUTES_OFFSET})
            }
            onComplete={() => {
              store().pages.profile.bookings.setBookingStatus(
                booking.id,
                BookingStatusEnum.PaymentTimeout,
              )
            }}
          />
          <Tooltip
            trigger="hover"
            title={
              <div
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(t('forgot_to_pay_tooltip')),
                }}
              />
            }
          >
            <Question name="question" width={18} height={19} />
          </Tooltip>
        </Minutes>
      </TimeLeftText>
    </TimeLeftWrapper>
  )
}
