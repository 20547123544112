import type {FC} from 'react'
import useTranslation from 'next-translate/useTranslation'
import {EtInclusion, EtTerritoryType} from '@/library/api/gql/generate-types'
import Trans from 'next-translate/Trans'
import type {OstrovokParking} from '../lib/types'
import {InlineAmount, InlineCurrency, InlineText} from '../../typography'
import {getInclusion, getTerritory} from '../lib/helpers'
import {getCurrency} from '../../../lib/helpers'

interface ParkingProps {
  parking: OstrovokParking
}

export const Parking: FC<ParkingProps> = ({parking}) => {
  const {t} = useTranslation('global.hotel')
  const {t: currenciesT} = useTranslation('currencies')

  const isInclusionSpecified =
    parking.inclusion && parking.inclusion !== EtInclusion.Unspecified

  if (parking.price && isInclusionSpecified) {
    return (
      <Trans
        i18nKey="rules.et.parking_with_price_and_inclusion"
        ns="global.hotel"
        components={[
          <InlineAmount
            key={`parking_${parking.territoryType}_${parking.price}_amount`}
          />,
          <InlineCurrency
            key={`parking_${parking.territoryType}_${parking.price}_currency`}
          />,
          <InlineText
            key={`parking_${parking.territoryType}_${parking.price}`}
          />,
        ]}
        values={{
          territoryType: getTerritory(
            t,
            parking.territoryType || EtTerritoryType.Unspecified,
          ),
          price: parking.price?.toLocaleString(),
          currency: getCurrency(currenciesT, parking.currency),
          inclusion: getInclusion(
            t,
            parking.inclusion || EtInclusion.Unspecified,
          ),
        }}
      />
    )
  }

  if (parking.price) {
    return (
      <Trans
        i18nKey="rules.et.parking_with_price"
        ns="global.hotel"
        components={[
          <InlineAmount
            key={`parking_${parking.territoryType}_${parking.price}_amount`}
          />,
          <InlineCurrency
            key={`parking_${parking.territoryType}_${parking.price}_currency`}
          />,
          <InlineText
            key={`parking_${parking.territoryType}_${parking.price}`}
          />,
        ]}
        values={{
          territoryType: getTerritory(
            t,
            parking.territoryType || EtTerritoryType.Unspecified,
          ),
          price: parking.price?.toLocaleString(),
          currency: getCurrency(currenciesT, parking.currency),
        }}
      />
    )
  }

  if (isInclusionSpecified) {
    return (
      <Trans
        i18nKey="rules.et.parking_with_inclusion"
        ns="global.hotel"
        components={[
          <InlineText
            key={`parking_${parking.territoryType}_${parking.inclusion}`}
          />,
        ]}
        values={{
          territoryType: getTerritory(
            t,
            parking.territoryType || EtTerritoryType.Unspecified,
          ),
          inclusion: getInclusion(
            t,
            parking.inclusion || EtInclusion.Unspecified,
          ),
        }}
      />
    )
  }

  return (
    <Trans
      i18nKey="rules.et.parking_with_inclusion"
      ns="global.hotel"
      components={[<InlineText key={`parking_${parking.territoryType}`} />]}
      values={{
        territoryType: getTerritory(
          t,
          parking.territoryType || EtTerritoryType.Unspecified,
        ),
      }}
    />
  )
}
