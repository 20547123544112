import {isNull, isUndefined} from './guards/types'

export const filterNullAndUndefined = <T extends object>(object: T) => {
  return Object.fromEntries(
    Object.entries(object).filter(
      ([, value]) => !isNull(value) && !isUndefined(value),
    ),
  ) as T
}

export const filterFalsy = <T extends object>(object: T) => {
  return Object.fromEntries(
    Object.entries(object).filter(([, value]) => Boolean(value)),
  ) as T
}
