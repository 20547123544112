import {
  type ButtonHTMLAttributes,
  forwardRef,
  type PropsWithChildren,
} from 'react'
import {throwComponentException} from '@utils/exceptions/components'
import type {ButtonShape} from '@ui/button/lib/variants/shape'
import type {ButtonPadding} from '@ui/button/lib/variants/padding'
import type {ButtonTextSize} from '@ui/button/lib/variants/text-size'
import {
  BUTTON_VARIANTS,
  type ButtonVariant,
} from 'library/components/ui/button/lib/variants'
import {isString} from '@utils/guards/types'

interface Props
  extends ButtonHTMLAttributes<HTMLButtonElement>,
    PropsWithChildren {
  variant?: ButtonVariant
  shape?: ButtonShape
  paddingSize?: ButtonPadding
  textSize?: ButtonTextSize
}

export const Button = forwardRef<HTMLButtonElement, Props>(
  (
    {
      children,
      variant = 'primary',
      shape = 'default',
      paddingSize = 'default',
      textSize = 'defaultBold',
      ...properties
    },
    ref,
  ) => {
    const VariantButton = BUTTON_VARIANTS[variant]

    if (!VariantButton) {
      throwComponentException(`Button by variant: ${variant} not found`)
    }

    return (
      <VariantButton
        ref={ref}
        type="button"
        aria-label={isString(children) ? children : undefined}
        $shape={shape}
        $paddingSize={paddingSize}
        $textSize={textSize}
        {...properties}
      >
        {children}
      </VariantButton>
    )
  },
)

Button.displayName = 'Button'
