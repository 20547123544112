import styled from '@emotion/styled'
import {Button} from '@ui/button'
import {Text} from '@ui/index'
import {store} from '@store/index'
import {observer} from 'mobx-react'
import {useLocalObservable} from 'mobx-react-lite'
import {selectors} from '@components/features/search-input/store'
import useTranslation from 'next-translate/useTranslation'
import {format} from 'date-fns'
import {DateFormatPattern} from '@/types/enums/date-format-patterns'
import {MEDIA_DEVICE_QUERY} from '@assets/theme/media'

const DateToWrapper = styled.div`
  height: 100%;

  @media ${MEDIA_DEVICE_QUERY.LAPTOP_LARGE} {
    min-width: fit-content;
  }
`

const DateToButton = styled(Button)<{
  $isOpen?: boolean
  $isActiveInput?: boolean
}>`
  background-color: ${({theme, $isOpen}) =>
    $isOpen ? theme.palette.backgroundQuaternary : 'transparent'};
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  padding-left: 24px;
  height: 100%;
  transition: 0.3s background-color ease-in-out;
  min-width: 145px;

  @media ${MEDIA_DEVICE_QUERY.LAPTOP_LARGE} {
    min-width: 100px;
  }

  &:hover {
    background-color: ${({theme, $isActiveInput}) =>
      $isActiveInput
        ? theme.palette.backgroundQuaternary
        : theme.palette.backgroundTertiary};
  }
`

const DateToTitle = styled(Text)`
  margin-bottom: 2px;
  color: ${({theme}) => theme.palette.textSecondary};
  font-weight: 500;
  @media ${MEDIA_DEVICE_QUERY.LAPTOP_LARGE} {
    font-size: 0.875rem;
  }
`

const DateToSubTitle = styled(Text)`
  color: ${({theme}) => theme.palette.textPrimary};
  font-weight: 500;
  @media ${MEDIA_DEVICE_QUERY.LAPTOP_LARGE} {
    font-size: 0.875rem;
  }
`

export const SearchInputDateTo = observer(() => {
  const {t} = useTranslation('features.search-input')
  const {isActiveInput} = useLocalObservable(
    selectors.selectIsActiveSearchInput,
  )
  const {isOpen, value} = store().search.dateTo

  const handleOpen = () => {
    store().search.dateTo.setIsOpen(!isOpen)
  }

  return (
    <DateToWrapper>
      <DateToButton
        $isOpen={isOpen}
        $isActiveInput={isActiveInput}
        onClick={handleOpen}
      >
        <DateToTitle size="S" type="span">
          {t('date_to.title')}
        </DateToTitle>
        <DateToSubTitle size="boldS" type="span">
          {value
            ? format(value, DateFormatPattern.DATE_PICKER_OUTPUT)
            : t('date_to.subtitle')}
        </DateToSubTitle>
      </DateToButton>
    </DateToWrapper>
  )
})
