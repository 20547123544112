import styled from '@emotion/styled'
import {XyzTransitionGroup} from '@animxyz/react'
import {store} from '@store/index'
import {observer, useLocalObservable} from 'mobx-react'
import {useEffect, useState} from 'react'
import {services} from '@/library/services'
import {ENVIRONMENT} from '@/library/utils/guards/environment'
import {useDeviceDetection} from '@/library/hooks/use-device-detection'
import {css} from '@emotion/react'
import {EmailSubscriptionForm} from './ui/email-subscription-form'
import {Portal} from '../../layouts'
import {
  selectIsAuthorized,
  selectIsOpen,
  selectIsSubscribed,
} from './lib/selectors'
import {
  EXPIRED_TIME_CHECK_INTERVAL,
  XYZ_ANIMATION_DURATION,
} from './lib/constants'

const StyledXyzTransitionGroup = styled(XyzTransitionGroup)`
  --xyz-duration-default: 0.3s;
`

const ModalWrapper = styled.div<{$isMobile: boolean}>`
  position: fixed;
  right: 20px;
  bottom: 20px;

  z-index: 21;

  width: 100%;
  max-width: 320px;

  border-radius: 12px;
  box-shadow: 0px 4px 6px 0px ${({theme}) => theme.palette.boxShadowPrimary};
  background-color: ${({theme}) => theme.palette.backgroundQuaternary};

  ${({$isMobile}) =>
    $isMobile &&
    css`
      max-width: unset;
      right: 0;
      bottom: 0;
      overflow-y: scroll;
      max-height: 100vh;
    `}
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({theme}) => theme.palette.overlayPrimary};
  opacity: 0.25;
  z-index: 21;
`

export const EmailSubscription = observer(() => {
  const [isExpiredTime, setIsExpiredTime] = useState(false)
  const isMobile = useDeviceDetection('mobile')

  const timeToDisplayWidgetFromCookies =
    services.common.emailSubscription.getTimeToDisplayWidgetFromCookies()

  const {isSubscribed} = useLocalObservable(selectIsSubscribed)
  const {isOpen} = useLocalObservable(selectIsOpen)
  const {isAuthorized} = useLocalObservable(selectIsAuthorized)

  const resetIsExpiredTime = () => {
    setIsExpiredTime(false)
  }

  const handleWidgetClose = () => {
    services.common.emailSubscription.closeWidget()

    resetIsExpiredTime()
  }

  useEffect(() => {
    if (isSubscribed) {
      services.common.emailSubscription.clearTimeToDisplayWidgetFromCookies()

      return
    }

    if (timeToDisplayWidgetFromCookies) {
      return
    }

    // Если не подписан, показываем виджет через 60 сек
    const widgetOpenTimeout = setTimeout(() => {
      store().emailSubscription.setIsOpen(true)
    }, Number(ENVIRONMENT.SUBSCRIPTION_WIDGET_DELAY))

    return () => {
      clearTimeout(widgetOpenTimeout)
    }
  }, [isSubscribed, timeToDisplayWidgetFromCookies])

  useEffect(() => {
    // Каждые 10 секунд проверяет (если есть кука) истекло ли время
    if (!timeToDisplayWidgetFromCookies || isExpiredTime || isSubscribed) {
      return
    }

    const intervalId = setInterval(() => {
      const timeNow = Date.now()
      const newIsExpiredTime = timeToDisplayWidgetFromCookies <= timeNow

      setIsExpiredTime((previous) => {
        if (previous !== newIsExpiredTime) {
          store().emailSubscription.setIsOpen(newIsExpiredTime)

          return newIsExpiredTime
        }

        return previous
      })
    }, EXPIRED_TIME_CHECK_INTERVAL)

    return () => {
      clearInterval(intervalId)
    }
  }, [timeToDisplayWidgetFromCookies, isExpiredTime, isSubscribed])

  useEffect(() => {
    // Если залогинен - проверяем подписки, если нет - берем из стораджа значение подписки
    if (isAuthorized) {
      services.common.emailSubscription.checkNewsletterSubscription()
    } else {
      const isSubscribedFromCookies =
        services.common.emailSubscription.getIsSubscribedFromCookies()

      store().emailSubscription.setIsSubscribed(isSubscribedFromCookies)
    }
  }, [isAuthorized])

  useEffect(() => {
    // Для неавторизованных пользователей брать подписку из куки
    if (!isAuthorized) {
      services.common.emailSubscription.setIsSubscribedToCookie(isSubscribed)
    }
  }, [isSubscribed, isAuthorized])

  useEffect(() => {
    if (isOpen) {
      return
    }

    // После окончания анимации закрытия ресетить флаг успеха
    const timeoutId = setTimeout(() => {
      store().emailSubscription.setIsSuccess(false)
    }, XYZ_ANIMATION_DURATION)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [isOpen])

  return (
    <Portal>
      <StyledXyzTransitionGroup xyz="fade-0">
        {isMobile && isOpen && <Overlay onClick={handleWidgetClose} />}
      </StyledXyzTransitionGroup>
      <StyledXyzTransitionGroup
        xyz={
          isMobile
            ? 'in-down-100% out-down-100%'
            : 'in-right-100% out-right-100%'
        }
      >
        {isOpen && (
          <ModalWrapper $isMobile={isMobile}>
            <EmailSubscriptionForm resetIsExpiredTime={resetIsExpiredTime} />
          </ModalWrapper>
        )}
      </StyledXyzTransitionGroup>
    </Portal>
  )
})
