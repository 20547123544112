import type {FC} from 'react'
import {
  EtPaymentType,
  EtPriceMethod,
  EtPriceUnit,
} from '@/library/api/gql/generate-types'
import useTranslation from 'next-translate/useTranslation'
import Trans from 'next-translate/Trans'
import type {OstrovokDeposit} from '../lib/types'
import {InlineAmount, InlineCurrency, InlineText} from '../../typography'
import {getCurrency} from '../../../lib/helpers'
import {getPaymentType, getPriceUnit, getDepositType} from '../lib/helpers'

interface DepositProps {
  deposit: OstrovokDeposit
}

export const Deposit: FC<DepositProps> = ({deposit}) => {
  const {t} = useTranslation('global.hotel')
  const {t: currenciesT} = useTranslation('currencies')

  if (
    deposit.priceMethod === EtPriceMethod.Fixed &&
    deposit.depositType &&
    deposit.currency &&
    deposit.price
  ) {
    return (
      <Trans
        i18nKey="rules.et.deposit_fixed"
        ns="global.hotel"
        components={[
          <InlineAmount
            key={`deposit_${deposit.depositType}_${deposit.price}_${deposit.priceUnit}_amount`}
          />,
          <InlineCurrency
            key={`deposit_${deposit.depositType}_${deposit.price}_${deposit.priceUnit}_currency`}
          />,
          <InlineText
            key={`deposit_${deposit.depositType}_${deposit.price}_${deposit.priceUnit}_unit`}
          />,
        ]}
        values={{
          price: deposit.price?.toLocaleString(),
          currency: getCurrency(currenciesT, deposit.currency),
          unit: getPriceUnit(t, deposit.priceUnit || EtPriceUnit.Unspecified),
          depositType: getDepositType(t, deposit.depositType),
          paymentType: getPaymentType(
            t,
            deposit.paymentType || EtPaymentType.Unspecified,
          ),
        }}
      />
    )
  }

  if (
    deposit.priceMethod === EtPriceMethod.Percent &&
    deposit.depositType &&
    deposit.price
  ) {
    return (
      <Trans
        i18nKey="rules.et.deposit_percent"
        ns="global.hotel"
        components={[
          <InlineAmount
            key={`deposit_${deposit.depositType}_${deposit.price}_${deposit.priceUnit}_amount`}
          />,
          <InlineCurrency
            key={`deposit_${deposit.depositType}_${deposit.price}_${deposit.priceUnit}_currency`}
          />,
          <InlineText
            key={`deposit_${deposit.depositType}_${deposit.price}_${deposit.priceUnit}_unit`}
          />,
        ]}
        values={{
          price: deposit.price?.toLocaleString(),
          unit: getPriceUnit(t, deposit.priceUnit || EtPriceUnit.Unspecified),
          depositType: getDepositType(t, deposit.depositType),
          paymentType: getPaymentType(
            t,
            deposit.paymentType || EtPaymentType.Unspecified,
          ),
        }}
      />
    )
  }

  return null
}
