import {BehaviorSubject} from 'rxjs'
import {SHA3} from 'crypto-js'
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro'
import {getCookie, setCookie} from '@utils/cookie'
import {isServer} from '@utils/next'
import {ENVIRONMENT} from '@utils/guards/environment'
import {store} from '@store/index'

export class FingerprintService {
  public readonly storageKey = ENVIRONMENT.FINGERPRINT_COOKIE_KEY

  private readonly stateManager = new BehaviorSubject('')

  private readonly fingerprintMinLength = 19

  public set(fingerprint: string) {
    setCookie(this.storageKey, fingerprint)
    localStorage.setItem(this.storageKey, fingerprint)
    sessionStorage.setItem(this.storageKey, fingerprint)
    this.stateManager.next(fingerprint)
  }

  public get() {
    const stateFingerprint = this.stateManager.getValue()
    const cookieFingerprint = getCookie(this.storageKey)

    if (isServer()) {
      return cookieFingerprint
    }

    const localStorageFingerprint = localStorage.getItem(this.storageKey)
    const sessionStorageFingerprint = sessionStorage.getItem(this.storageKey)

    return (
      cookieFingerprint ||
      sessionStorageFingerprint ||
      localStorageFingerprint ||
      stateFingerprint
    )
  }

  public isValid(fingerprint?: string | null) {
    if (!fingerprint) {
      return false
    }

    return Boolean(
      fingerprint && fingerprint.length >= this.fingerprintMinLength,
    )
  }

  public buildCustomFingerprint() {
    if (store().utilities.userAgent?.isBot) {
      return ''
    }

    if (isServer()) {
      throw new Error(
        'The FingerprintService.getCustom method cannot be called on the server side, the method is client side',
      )
    }

    const {userAgent, language} = navigator
    const {screen} = window
    const customPrefix = '_'
    const timestamp = Date.now()
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    const message = `
      ${timestamp.toString()}
      ${userAgent}
      ${screen.availWidth.toString()}
      ${screen.availHeight.toString()}
      ${language}
      ${timezone}
    `
    const hash = SHA3(message).toString().slice(0, this.fingerprintMinLength)

    return `${customPrefix}${hash}`
  }

  public async load() {
    if (isServer()) {
      throw new Error(
        'The FingerprintService.load method cannot be called on the server side, the method is client side',
      )
    }

    const fingerprintAPI = FingerprintJS.load({
      apiKey: ENVIRONMENT.FINGERPRINT_PUBLIC_KEY,
    })

    try {
      const result = await fingerprintAPI.then((publicAgent) =>
        publicAgent.get(),
      )

      const {visitorId} = result

      if (!this.isValid(visitorId)) {
        throw new Error(
          `fingerprintAPI return from request invalid visitorId: ${visitorId}`,
        )
      }

      this.set(visitorId)

      return visitorId
    } catch {
      const customFingerprint = this.buildCustomFingerprint()

      this.set(customFingerprint)

      return customFingerprint
    }
  }
}
