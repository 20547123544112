import styled from '@emotion/styled'
import type {FC} from 'react'
import {Card, CarouselItem} from '@ui/index'
import {MEDIA_DEVICE_QUERY} from '@assets/theme/media'
import {store} from '@store/index'
import {useLocalObservable} from 'mobx-react-lite'
import {observer} from 'mobx-react'
import type {TitleType} from '@ui/typography/title'
import {CarouselWrapper} from '../carousel-wrapper'
import {NUMBER_OF_SKELETONS} from '../../lib/constants'
import {CityDestination} from '../city-destination'
import {ItemSkeleton} from '../item-skeleton'

const StyledCarouselItem = styled(CarouselItem)`
  flex-basis: fit-content;

  :not(:first-of-type) {
    padding-left: 20px;
  }

  @media ${MEDIA_DEVICE_QUERY.MOBILE_LARGE} {
    padding-left: 0px;
    width: 100%;
    flex-basis: 100%;
  }
`

interface PopularCityDestinationsTabletSkeletonProps {
  className?: string
}

const PopularCityDestinationsTouchSkeleton: FC<
  PopularCityDestinationsTabletSkeletonProps
> = ({className}) => {
  return (
    <CarouselWrapper className={className}>
      {Array.from({length: NUMBER_OF_SKELETONS}).map((_, index) => (
        <StyledCarouselItem key={`skeleton-tablet-${index}`}>
          <Card.Container>
            <Card.Content>
              <ItemSkeleton active />
            </Card.Content>
          </Card.Container>
        </StyledCarouselItem>
      ))}
    </CarouselWrapper>
  )
}

const selectPopularCityDestinations = () => ({
  get popularCityDestinations() {
    return store().popularCityDestinations.popularCityDestinations
  },
  get isLoading() {
    return store().popularCityDestinations.isLoading
  },
})

interface PopularCityDestinationsTabletProps {
  className?: string
  headlineType?: TitleType
}

export const PopularCityDestinationsTouch: FC<PopularCityDestinationsTabletProps> =
  observer(({className, headlineType}) => {
    const {isLoading, popularCityDestinations} = useLocalObservable(
      selectPopularCityDestinations,
    )

    if (isLoading) {
      return <PopularCityDestinationsTouchSkeleton className={className} />
    }

    if (!popularCityDestinations) {
      return null
    }

    return (
      <CarouselWrapper className={className} headlineType={headlineType}>
        {popularCityDestinations.map((cityDestination) => (
          <StyledCarouselItem key={cityDestination.id}>
            <Card.Container
              coverImage={cityDestination.city.image?.url}
              linearShadow
            >
              <Card.Content>
                <CityDestination cityDestination={cityDestination} />
              </Card.Content>
            </Card.Container>
          </StyledCarouselItem>
        ))}
      </CarouselWrapper>
    )
  })
