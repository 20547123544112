import styled from '@emotion/styled'
import {HotelScenarioRatesFiltersPaymentTypes} from '@slices/global/ui/hotel-scenario/rates/filters/filters.payment-types'
import {HotelScenarioRatesFiltersFreeCancellation} from '@slices/global/ui/hotel-scenario/rates/filters/filters.free-cancellation'
import {HotelScenarioRatesFiltersBeds} from '@slices/global/ui/hotel-scenario/rates/filters/filters.beds'
import {HotelScenarioRatesFiltersMeal} from '@slices/global/ui/hotel-scenario/rates/filters/filters.meal'
import {MEDIA_DEVICE_QUERY} from '@assets/theme/media'

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 20px;

  @media ${MEDIA_DEVICE_QUERY.TABLET} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${MEDIA_DEVICE_QUERY.MOBILE_LARGE} {
    grid-template-columns: 1fr;
  }
`

export const HotelScenarioRatesFilters = () => {
  return (
    <Wrapper>
      <HotelScenarioRatesFiltersMeal />
      <HotelScenarioRatesFiltersBeds />
      <HotelScenarioRatesFiltersFreeCancellation />
      <HotelScenarioRatesFiltersPaymentTypes />
    </Wrapper>
  )
}
