import {Container} from '@components/layouts'
import {ProfileSideMenu} from '@components/features/profile-side-menu'
import styled from '@emotion/styled'
import {Text, Title} from '@ui/typography'
import useTranslation from 'next-translate/useTranslation'
import {useDeviceDetection} from '@/library/hooks/use-device-detection'
import {MEDIA_DEVICE_QUERY} from '@assets/theme/media'
import {useEffect} from 'react'
import {store} from '@store/index'
import {SettingsForm} from './ui/settings-form'

const StyledContainer = styled(Container)`
  min-height: 100%;
  height: fit-content;
`

const Wrapper = styled.div`
  margin-top: 40px;
  display: flex;
  gap: 60px;

  @media ${MEDIA_DEVICE_QUERY.LAPTOP} {
    margin-top: 20px;
    gap: 20px;
  }
`

const ContentWrapper = styled.div`
  width: 100%;
  padding-bottom: 20px;
`

const StyledTitle = styled(Title)`
  color: ${({theme}) => theme.palette.textPrimary};
`

const StyledSubtitle = styled(Text)`
  color: ${({theme}) => theme.palette.textPrimary};
  margin-top: 6px;
`

const StyledSettingsForm = styled(SettingsForm)`
  margin-top: 40px;
`

export const Content = () => {
  const {t} = useTranslation('profile.settings')
  const isTouch = useDeviceDetection('touch')

  useEffect(() => {
    return () => {
      store().pages.profile.settings.reset()
    }
  }, [])

  return (
    <StyledContainer>
      <Wrapper>
        <ProfileSideMenu />
        <ContentWrapper>
          <StyledTitle>{t('title')}</StyledTitle>
          <StyledSubtitle size={isTouch ? 'S' : 'M'}>
            {t('subtitle')}
          </StyledSubtitle>
          <StyledSettingsForm />
        </ContentWrapper>
      </Wrapper>
    </StyledContainer>
  )
}
