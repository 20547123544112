import {makeAutoObservable} from 'mobx'
import {type GetPopularCityDestinationsQuery} from '@/library/api/gql/generate-types'

type PopularCityDestinations =
  | GetPopularCityDestinationsQuery['popularDestinations']
  | null

export class CPopularCityDestinationsStore {
  constructor() {
    makeAutoObservable(this)
  }

  private _popularCityDestinations: PopularCityDestinations = null

  public get popularCityDestinations(): PopularCityDestinations {
    return this._popularCityDestinations
  }

  private _isLoading = false

  public get isLoading() {
    return this._isLoading
  }

  public setPopularCityDestinations(
    popularCityDestinations: PopularCityDestinations,
  ) {
    this._popularCityDestinations = popularCityDestinations
  }

  public setIsLoading(isLoading: boolean) {
    this._isLoading = isLoading
  }
}
