import {store} from '@store/index'
import {
  ConfirmationStatus,
  EAuthorizationModalAction,
  EPasswordResetStep,
} from '@/library/store/authorization/types'
import type {
  LoginFormData,
  PasswordResetConfirmCodeFormData,
  PasswordResetEmailFormData,
  RegisterFormData,
} from '@/library/components/features/authorization-modal/lib/types'
import {api} from '@/library/api'
import {CookieNames, deleteCookie, setCookie} from '@/library/utils/cookie'
// eslint-disable-next-line unicorn/prefer-node-protocol
import punycode from 'punycode'
import {
  AUTHORIZATION_STORE_INITIAL_STATE,
  REGISTER_STORE_INITIAL_STATE,
  RESET_PASSWORD_STORE_INITIAL_STATE,
} from '@/library/store/authorization/constants'
import {Routes} from '@/types/enums/routes'
import {RoleEnum} from '@/types/enums/authorization'
import Router from 'next/router'
import {
  COMMON_ERROR_TEXT,
  LOGIN_ERROR_TEXT,
  RESET_PASSWORD_ERROR_TEXT,
} from '../error/lib/constants'
import {errorService} from '../error'
import {services} from '../..'

export class AuthorizationService {
  public handleClickLogin(): void {
    store().authorization.setModalAction(EAuthorizationModalAction.LOGIN)
    store().authorization.setModalOpen(!store().authorization.modalOpen)
    store().header.setUserPopupOpen(false)
  }

  public handleClickRegister(): void {
    store().authorization.setModalAction(EAuthorizationModalAction.REGISTER)
    store().authorization.setModalOpen(!store().authorization.modalOpen)
    store().header.setUserPopupOpen(false)
  }

  public handleClearErrors(): void {
    store().authorization.login.setError('')
    store().authorization.register.setError('')
    store().authorization.resetPassword.setError('')
  }

  public handleCloseAuthModal(): void {
    store().authorization.setModalOpen(
      AUTHORIZATION_STORE_INITIAL_STATE.modalOpen,
    )
    store().authorization.setModalAction(
      AUTHORIZATION_STORE_INITIAL_STATE.modalAction,
    )
    store().authorization.resetPassword.setStep(
      RESET_PASSWORD_STORE_INITIAL_STATE.step,
    )
    store().authorization.register.setIsSuccessful(
      REGISTER_STORE_INITIAL_STATE.isSuccessful,
    )
    store().authorization.resetPassword.setResetPasswordEmail(
      RESET_PASSWORD_STORE_INITIAL_STATE.resetPasswordEmail,
    )
    store().authorization.setIsLoading(
      AUTHORIZATION_STORE_INITIAL_STATE.isLoading,
    )

    this.handleClearErrors()
  }

  public async login(values: LoginFormData, joinAffiliateProgram = false) {
    store().authorization.setIsLoading(true)

    api.authorization
      .login({
        variables: {...values, join_to_affiliate_program: joinAffiliateProgram},
      })
      .then((result) => {
        if (result) {
          store().user.setUser(result)
          setCookie(CookieNames.AUTHORIZATION, result.api_token)
          deleteCookie(CookieNames.TEMPORARY_AUTHORIZATION)
          this.handleCloseAuthModal()
        }
      })
      .catch((error) => {
        const firstError = errorService.getFirstError(error)
        const errorCategory = firstError.extensions?.category
        const text = LOGIN_ERROR_TEXT[errorCategory]

        if (text || error.message) {
          store().authorization.login.setError(text || error.message)

          return
        }

        store().authorization.login.setError(COMMON_ERROR_TEXT.WENT_WRONG)
      })
      .finally(() => {
        store().authorization.setIsLoading(false)
      })
  }

  public async register(
    values: RegisterFormData,
    joinAffiliateProgram: boolean,
  ) {
    store().authorization.setIsLoading(true)
    const verificationLink = `${punycode.toUnicode(
      window.location.origin,
    )}${Routes.EMAIL_CONFIRM}`

    api.authorization
      .register({
        variables: {
          ...values,
          join_to_affiliate_program: joinAffiliateProgram,
          verification_link: verificationLink,
        },
      })
      .then((result) => {
        if (result) {
          store().authorization.register.setIsSuccessful(true)
        }
      })
      .catch((error) => {
        const firstError = errorService.getFirstError(error)
        const emailError = firstError.extensions.validation?.email?.[0]

        if (emailError || firstError.message) {
          store().authorization.register.setError(
            emailError || firstError.message,
          )

          return
        }

        store().authorization.register.setError(COMMON_ERROR_TEXT.WENT_WRONG)
      })
      .finally(() => {
        store().authorization.setIsLoading(false)
      })
  }

  public async resetPassword(values: PasswordResetEmailFormData) {
    store().authorization.setIsLoading(true)

    api.authorization
      .passwordReset({variables: values})
      .then((result) => {
        if (result) {
          store().authorization.resetPassword.setStep(EPasswordResetStep.CODE)
          store().authorization.resetPassword.setResetPasswordEmail(
            values.email,
          )
          services.common.authorization.handleClearErrors()
        }
      })
      .catch((error) => {
        const firstError = errorService.getFirstError(error)
        const emailError = firstError.extensions.validation?.email?.[0]

        if (emailError || firstError.message) {
          store().authorization.resetPassword.setError(
            emailError || firstError.message,
          )

          return
        }

        store().authorization.resetPassword.setError(
          COMMON_ERROR_TEXT.WENT_WRONG,
        )
      })
      .finally(() => {
        store().authorization.setIsLoading(false)
      })
  }

  public async resetPasswordConfirmCode(
    values: PasswordResetConfirmCodeFormData,
  ) {
    store().authorization.setIsLoading(true)
    const email = store().authorization.resetPassword.resetPasswordEmail

    api.authorization
      .passwordResetConfirm({variables: {...values, email}})
      .then((result) => {
        if (result) {
          store().authorization.resetPassword.setStep(
            EPasswordResetStep.SUCCESS,
          )
          services.common.authorization.handleClearErrors()

          return result
        }

        store().authorization.resetPassword.setError(
          RESET_PASSWORD_ERROR_TEXT[ConfirmationStatus.WRONG_CODE],
        )

        return result
      })
      .catch((error) => {
        const firstError = errorService.getFirstError(error)
        const errorCategory = firstError.extensions?.category
        const text = RESET_PASSWORD_ERROR_TEXT[errorCategory]

        if (text || error.message) {
          store().authorization.resetPassword.setError(text || error.message)

          return
        }

        store().authorization.resetPassword.setError(
          COMMON_ERROR_TEXT.WENT_WRONG,
        )
      })
      .finally(() => {
        store().authorization.setIsLoading(false)
      })
  }

  public async logout() {
    store().header.setIsLoading(true)

    return api.authorization
      .logout()
      .then((result) => {
        if (result) {
          store().user.setUser(null)
          deleteCookie(CookieNames.AUTHORIZATION)
          deleteCookie(CookieNames.TEMPORARY_AUTHORIZATION)
          store().header.setUserPopupOpen(false)
          Router.push(Routes.MAIN)
        }
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        store().header.setIsLoading(false)
      })
  }

  public isPartner() {
    return store().user.user?.roles?.some(
      (role) => String(role.name) === RoleEnum.AFFILIATE,
    )
  }
}
