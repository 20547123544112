import useTranslation from 'next-translate/useTranslation'
import styled from '@emotion/styled'
import {Calendar, Text} from '@/library/components/ui'
import {Popover} from '@/library/components/ui/popover'
import {Icon} from '@/library/components/ui/icon'
import {type FC, useState} from 'react'
import {getDefaultMonth} from '@/library/utils/date'
import {getMaxYear, getMinYear} from './lib/helpers'

const StyledPopover = styled(Popover)`
  padding: 5px;
`

const BirthdayTrigger = styled.div`
  width: fit-content;
  cursor: pointer;
`

const BirthdayTriggerContent = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${({theme}) => theme.palette.borderTertiary};
  padding: 15px;
  border-radius: 12px;
  box-shadow: 0px 4px 6px 0px ${({theme}) => theme.palette.boxShadowPrimary};

  svg {
    color: ${({theme}) => theme.palette.backgroundSecondary};
  }
`

const BirthdayLabel = styled.label`
  width: fit-content;
  cursor: pointer;
  font-weight: 500;
  color: ${({theme}) => theme.palette.textPrimary};
`

const BirthdayTriggerText = styled(Text)`
  margin-left: 12px;
  font-weight: 500;
  color: ${({theme}) => theme.palette.textPrimary};
`

interface BirthdayInputProps {
  birthday?: Date | null
  outputFormatter: (birthday: Date) => string
  setBirthday: (birthday?: Date) => void
  className?: string
}

export const BirthdayInput: FC<BirthdayInputProps> = ({
  birthday,
  outputFormatter,
  setBirthday,
  className,
}) => {
  const {t} = useTranslation('features.birthday-input')
  const [isCalendarOpen, setCalendarOpen] = useState(false)

  const onSelect = (day?: Date) => {
    if (!day) {
      return
    }
    setBirthday(day)
    setCalendarOpen(false)
  }

  return (
    <StyledPopover
      open={isCalendarOpen}
      onOpenChange={(open) => setCalendarOpen(open)}
      content={
        <Calendar
          captionLayout="dropdown"
          fromYear={getMaxYear()}
          toYear={getMinYear()}
          defaultMonth={birthday ? getDefaultMonth(birthday) : undefined}
          selected={birthday || undefined}
          mode="single"
          onSelect={onSelect}
        />
      }
    >
      <BirthdayTrigger className={className}>
        <BirthdayLabel>{t('label')}</BirthdayLabel>
        <BirthdayTriggerContent>
          <Icon name="calendar" width={25} height={25} />
          <BirthdayTriggerText size="S">
            {birthday ? outputFormatter(birthday) : t('placeholder')}
          </BirthdayTriggerText>
        </BirthdayTriggerContent>
      </BirthdayTrigger>
    </StyledPopover>
  )
}
