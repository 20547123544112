import {makeAutoObservable} from 'mobx'
import type {RateSearchParameters} from '@api/gql/generate-types'
import {CGlobalPageStoreHotelRatesFilters} from '@store/pages/global/hotel/rates/filters'
import {CGlobalPageStoreHotelRatesFacilityModal} from '@store/pages/global/hotel/rates/facility-modal'

export class CGlobalPageStoreHotelRates {
  constructor() {
    makeAutoObservable(this)
  }

  public readonly filters: CGlobalPageStoreHotelRatesFilters =
    new CGlobalPageStoreHotelRatesFilters()

  public readonly facilityModal: CGlobalPageStoreHotelRatesFacilityModal =
    new CGlobalPageStoreHotelRatesFacilityModal()

  private _data: HotelScenarioProviders | null = null

  public get data(): HotelScenarioProviders | null {
    return this._data
  }

  public setData(data: HotelScenarioProviders | null): void {
    this._data = data
  }

  private _isLoading = false

  public get isLoading(): boolean {
    return this._isLoading
  }

  public setIsLoading(flag: boolean): void {
    this._isLoading = flag
  }

  private _ratesSearchParameters: RateSearchParameters | null = null

  public get ratesSearchParameters(): RateSearchParameters | null {
    return this._ratesSearchParameters
  }

  public setRatesSearchParameters(rates: RateSearchParameters | null): void {
    this._ratesSearchParameters = rates
  }
}
