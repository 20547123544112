import type {FC, MouseEvent, PropsWithChildren} from 'react'
import styled from '@emotion/styled'
import {XyzTransitionGroup} from '@animxyz/react'
import {css} from '@emotion/react'
import {MEDIA_DEVICE_QUERY} from '@/assets/theme/media'
import {CircularLoader} from '../loader'
import {Icon} from '../icon'
import {Portal} from '../../layouts'

type ElementProps = {
  $isFullscreen?: boolean
}

const ModalContainer = styled.div<ElementProps>`
  width: 100vw;
  height: 100%;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  display: flex;
  justify-content: flex-end;
  overflow: hidden;

  ${({$isFullscreen}) =>
    $isFullscreen &&
    css`
      justify-content: unset;
    `}
`

const Overlay = styled.div`
  z-index: 999;
  width: 100vw;
  height: 100%;
  position: fixed;
  background-color: ${({theme}) => theme.palette.overlayPrimary};
  left: 0;
  top: 0;
  overflow: hidden;
`

const ModalContent = styled.div<ElementProps>`
  height: 100%;
  background-color: ${({theme}) => theme.palette.backgroundQuaternary};
  width: fit-content;
  padding: 20px 24px;
  border-radius: 8px 0 0 8px;
  overflow: auto;

  ${({$isFullscreen}) =>
    $isFullscreen &&
    css`
      width: 100vw;
      border-radius: unset;
      padding: unset;
    `}
`

const ChildrenWrapper = styled.div``

const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const CloseWrapper = styled.div<ElementProps>`
  position: absolute;
  transform: translateX(-80px);

  ${({$isFullscreen, theme}) =>
    $isFullscreen &&
    css`
      position: sticky;
      top: 0;
      z-index: 99999;
      transform: unset;
      padding: 8px 20px;
      border-bottom: 1px solid ${theme.palette.borderTertiary};
      border-radius: 0;
      width: 100%;
      background-color: ${theme.palette.backgroundQuaternary};
    `} @media ${MEDIA_DEVICE_QUERY.MOBILE_LARGE} {
    border-bottom: unset;
    padding: 8px 4px;
  }
`

const CloseButton = styled.button<ElementProps>`
  border-radius: 100%;
  width: fit-content;
  height: fit-content;
  background-color: ${({theme}) => theme.palette.backgroundQuaternary};
  border: 1px solid ${({theme}) => theme.palette.borderTertiary};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  ${({$isFullscreen}) =>
    $isFullscreen &&
    css`
      border: unset;
    `}
`

const CloseIcon = styled(Icon)<ElementProps>`
  width: 24px;
  height: 24px;
  margin: 7px;

  ${({$isFullscreen}) =>
    $isFullscreen &&
    css`
      margin: unset;
    `}
`

interface RightModalProps {
  isLoading?: boolean
  isOpen?: boolean
  closable?: boolean
  className?: string
  isFullscreen?: boolean
  onCancel: (event: MouseEvent<HTMLButtonElement>) => void
}

export const RightModal: FC<PropsWithChildren<RightModalProps>> = ({
  isLoading,
  children,
  closable,
  isOpen,
  className,
  isFullscreen,
  onCancel,
}) => {
  return (
    <Portal>
      <XyzTransitionGroup
        appear
        xyz={`fade duration-4 ${isFullscreen ? 'in-down-100% out-down-100%' : 'in-right-100% out-right-100%'}`}
      >
        {isOpen && (
          <ModalContainer $isFullscreen={isFullscreen}>
            <ModalContent $isFullscreen={isFullscreen} className={className}>
              {closable && !isLoading ? (
                <CloseWrapper $isFullscreen={isFullscreen}>
                  <CloseButton $isFullscreen={isFullscreen} onClick={onCancel}>
                    <CloseIcon
                      $isFullscreen={isFullscreen}
                      name="close"
                      width={24}
                      height={24}
                    />
                  </CloseButton>
                </CloseWrapper>
              ) : null}
              {isLoading ? (
                <LoaderWrapper>
                  <CircularLoader size={64} />
                </LoaderWrapper>
              ) : (
                <ChildrenWrapper>{children}</ChildrenWrapper>
              )}
            </ModalContent>
          </ModalContainer>
        )}
      </XyzTransitionGroup>
      {isOpen && (
        <XyzTransitionGroup appear xyz="fade duration-4">
          <Overlay />
        </XyzTransitionGroup>
      )}
    </Portal>
  )
}
