import {NO_HOTEL_IMAGE_SOURCE} from '@/library/constants/hotel'
import {store} from '@/library/store'
import {ENVIRONMENT} from '@/library/utils/guards/environment'
import {getCanonicalUrl} from '@/library/utils/http/url'
import {
  hotelMetaBuilder,
  schemaPaymentMethods,
} from '@/library/utils/seo/schema'
import {
  buildRatingContentValue,
  buildStarRatingContentValue,
  getMinimumRatePrice,
  parseStarRating,
} from '@/slices/global/lib/helpers'
import {ECurrency} from '@/types/enums/currency'
import {observer} from 'mobx-react'
import useTranslation from 'next-translate/useTranslation'

export const SeoHotel = observer(() => {
  const {t} = useTranslation('global.hotel')
  const {data, rates, reviews} = store().pages.global.hotel
  const {origin} = store().utilities

  if (!data) {
    return null
  }

  const minimumPrice = getMinimumRatePrice(rates)

  const canonicalUrl =
    origin && data.navSeoUrls?.hotel
      ? getCanonicalUrl(data.navSeoUrls.hotel, origin)
      : null

  return (
    <div {...hotelMetaBuilder.hotelProps}>
      {hotelMetaBuilder.renderName(data.name || '')}
      {canonicalUrl ? hotelMetaBuilder.renderUrl(canonicalUrl) : null}
      {hotelMetaBuilder.renderDescription(data?.description || '')}
      {hotelMetaBuilder.renderImage(
        data.mainImage?.url || NO_HOTEL_IMAGE_SOURCE,
      )}

      {hotelMetaBuilder.renderCheckinTime(
        data.policies.policyCheckin?.from || '',
      )}
      {hotelMetaBuilder.renderCheckoutTime(
        data.policies.policyCheckout?.until || '',
      )}
      {minimumPrice
        ? hotelMetaBuilder.renderPriceRange(
            t('rates.price.seo', {
              price: minimumPrice,
              currency: ECurrency.RUB,
            }),
          )
        : null}
      {hotelMetaBuilder.renderCurrenciesAccepted(ECurrency.RUB)}

      {canonicalUrl ? hotelMetaBuilder.renderUrl(canonicalUrl) : null}
      <div {...hotelMetaBuilder.geoProps}>
        {hotelMetaBuilder.renderLatitude(data?.lat)}
        {hotelMetaBuilder.renderLongitude(data?.lng)}
      </div>
      <div {...hotelMetaBuilder.addressProps}>
        {hotelMetaBuilder.renderAddressLocality(data?.city?.name || '')}
        {hotelMetaBuilder.renderAddressRegion(data?.city?.region?.name || '')}

        <div {...hotelMetaBuilder.addressCountryProps}>
          {hotelMetaBuilder.renderName(data?.city?.region?.country?.name || '')}
        </div>
        {hotelMetaBuilder.renderStreetAddress(data.address)}
      </div>

      {data.stars ? (
        <div {...hotelMetaBuilder.starRatingProps}>
          {hotelMetaBuilder.renderRatingValue(
            buildStarRatingContentValue(data.stars),
          )}
        </div>
      ) : null}
      {hotelMetaBuilder.renderPetsAllowed(
        data.policies.policyPet?.permission?.toString() || '',
      )}
      {data.stars || data.rating ? (
        <div {...hotelMetaBuilder.starRatingProps}>
          {hotelMetaBuilder.renderRatingValue(
            data?.stars
              ? buildStarRatingContentValue(data.stars)
              : parseStarRating(data.rating),
          )}
        </div>
      ) : null}

      <div {...hotelMetaBuilder.aggregateRatingProps}>
        {hotelMetaBuilder.renderBestRating(ENVIRONMENT.BEST_RATING)}
        {hotelMetaBuilder.renderWorstRating(ENVIRONMENT.WORST_RATING)}
        {hotelMetaBuilder.renderRatingValue(
          data.rating || ENVIRONMENT.DEFAULT_RATING_VALUE,
        )}
        {hotelMetaBuilder.renderReviewCount(
          reviews.paginatorInfo?.total || ENVIRONMENT.DEFAULT_SEO_COUNT,
        )}
        {hotelMetaBuilder.renderRatingCount(
          reviews.paginatorInfo?.total || ENVIRONMENT.DEFAULT_SEO_COUNT,
        )}
      </div>
      {data.facilitiesGroups?.map((group) =>
        group.facilities?.map((facility) => (
          <div key={facility.name} {...hotelMetaBuilder.amenityFeatureProps}>
            {hotelMetaBuilder.renderName(facility?.name || '')}
            {hotelMetaBuilder.renderValue()}
          </div>
        )),
      )}
      {reviews.data?.map((review) => (
        <div key={review.id} {...hotelMetaBuilder.reviewProps}>
          <div {...hotelMetaBuilder.reviewRatingProps}>
            {hotelMetaBuilder.renderRatingValue(
              buildRatingContentValue(review?.score),
            )}
            {hotelMetaBuilder.renderBestRating(ENVIRONMENT.BEST_RATING)}
            {hotelMetaBuilder.renderWorstRating(ENVIRONMENT.WORST_RATING)}
          </div>
          {hotelMetaBuilder.renderName(review.title || '')}
          {hotelMetaBuilder.renderDatePublished(review.created_at)}
          {hotelMetaBuilder.renderReviewBody(
            review.positive || review.negative || review.title || '',
          )}
          <div {...hotelMetaBuilder.authorProps}>
            {hotelMetaBuilder.renderName(review.author)}
          </div>
        </div>
      ))}
      {rates.data?.map((rate) =>
        rate.roomRates.map((roomRate) =>
          roomRate.rates.map((roomRateRate) =>
            roomRateRate.paymentTypes.map((paymentType) => (
              <div
                key={`${paymentType.type}-${roomRateRate.bookHash}-${roomRate.roomId}-${rate.id}`}
                {...hotelMetaBuilder.makesOfferProps}
              >
                {hotelMetaBuilder.renderName(roomRate.roomName.main)}
                {hotelMetaBuilder.renderDescription(
                  roomRate.roomName.addition || '',
                )}
                {schemaPaymentMethods.map((paymentMethodUrl) => (
                  <div
                    key={paymentMethodUrl}
                    {...hotelMetaBuilder.paymentMethodProps}
                  >
                    {hotelMetaBuilder.renderUrl(paymentMethodUrl)}
                  </div>
                ))}
                <div {...hotelMetaBuilder.priceSpecificationProps}>
                  {hotelMetaBuilder.renderPrice(paymentType.totalAmount)}
                  {hotelMetaBuilder.renderPriceCurrency(
                    paymentType.currencyCode,
                  )}
                  {hotelMetaBuilder.renderUnitCode(paymentType.type)}
                </div>
              </div>
            )),
          ),
        ),
      )}
    </div>
  )
})
