import {getCanonicalUrl} from '@library/utils/http/url'
import {ENVIRONMENT} from '@/library/utils/guards/environment'
import {buildSchemaItemType} from '../helpers'

const schemaItemTypes = {
  breadcrumbList: buildSchemaItemType('BreadcrumbList'),
  itemListElement: buildSchemaItemType('Listitem'),
  thing: buildSchemaItemType('Thing'),
}

const schemaProps = {
  item: 'item',
  name: 'name',
  position: 'position',
  itemListElement: 'itemListElement',
}

interface Item {
  type: 'Thing'
  '@id': string
}

interface ItemListElement {
  type: 'ListItem'
  name: string
  position: number
  item: Item
}

interface BreadcrumbList {
  '@context': string
  '@type': 'BreadcrumbList'
  itemListElements: ItemListElement[]
}

interface BreadcrumbListBuilderInput {
  name: string
  url: string
}

interface BreadcrumbListProps {
  breadcrumbs: BreadcrumbListBuilderInput[]
  origin: string
}

export const buildBreacrumbListLdProps = ({
  breadcrumbs,
  origin,
}: BreadcrumbListProps): BreadcrumbList => {
  return {
    '@context': ENVIRONMENT.SCHEMA_URL,
    '@type': 'BreadcrumbList',
    itemListElements: breadcrumbs.map((breadcrumb, index) => {
      return {
        type: 'ListItem',
        name: breadcrumb.name,
        position: index + 1,
        item: {
          type: 'Thing',
          '@id': getCanonicalUrl(breadcrumb.url, origin),
        },
      }
    }),
  }
}

export const breadcrumbListMetaBuilder = {
  breadcrumbListProps: {
    itemScope: true,
    itemType: schemaItemTypes.breadcrumbList,
  },
  itemListElementProps: {
    itemScope: true,
    itemType: schemaItemTypes.itemListElement,
    itemProp: schemaProps.itemListElement,
  },
  itemProps: {
    itemProp: schemaProps.item,
  },
  renderBreadcrumbName: (name: string) => (
    <meta itemProp={schemaProps.name} content={name} />
  ),
  renderBreadcrumbPosition: (position: number) => (
    <meta itemProp={schemaProps.position} content={String(position)} />
  ),
}
