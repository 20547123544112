import type {Translate} from 'next-translate'

export const getDepositType = (t: Translate, key: string) =>
  t(`rules.hoteliers.deposit_type.${key}`)

export const getPetsType = (t: Translate, key: string) =>
  t(`rules.hoteliers.pets.${key}`)

export const getCalculationType = (t: Translate, key: string) =>
  t(`rules.hoteliers.calculation_type.${key}`)
