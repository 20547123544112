import {store} from '@store/index'
import {observer} from 'mobx-react'
import styled from '@emotion/styled'
import {XyzTransitionGroup} from '@animxyz/react'
import {ChildrenChipsItem} from './children.chips.item'

const StyledXyzTransitionGroup = styled(XyzTransitionGroup)`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 20px;
`

const ChildrenChipWrapper = styled.div``

export const ChildrenChips = observer(() => {
  const {children} = store().search.guests.children

  if (children.length === 0) {
    return null
  }

  return (
    <StyledXyzTransitionGroup appear appearVisible xyz="fade small out-back-3">
      {children.map((age, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <ChildrenChipWrapper key={index}>
          <ChildrenChipsItem id={index} age={age} />
        </ChildrenChipWrapper>
      ))}
    </StyledXyzTransitionGroup>
  )
})
