import {BookingStatusEnum} from '@/library/api/gql/generate-types'
import type {Theme} from '@emotion/react'

export const buildBookingStatusVariants = (
  theme: Theme,
): Record<BookingStatusEnum, Record<string, string>> => {
  return {
    [BookingStatusEnum.Booked]: {
      text: 'features.booking-badge:status.booked',
      color: theme.palette.textPositive,
    },
    [BookingStatusEnum.Cancelled]: {
      text: 'features.booking-badge:status.cancelled',
      color: theme.palette.textError,
    },
    [BookingStatusEnum.Completed]: {
      text: 'features.booking-badge:status.completed',
      color: theme.palette.textPositive,
    },
    [BookingStatusEnum.Error]: {
      text: 'features.booking-badge:status.error',
      color: theme.palette.textError,
    },
    [BookingStatusEnum.Formed]: {
      text: 'features.booking-badge:status.formed',
      color: theme.palette.textBlue,
    },
    [BookingStatusEnum.InProcess]: {
      text: 'features.booking-badge:status.in_process',
      color: theme.palette.textBlue,
    },
    [BookingStatusEnum.New]: {
      text: 'features.booking-badge:status.new',
      color: theme.palette.textBlue,
    },
    [BookingStatusEnum.PaymentTimeout]: {
      text: 'features.booking-badge:status.payment_timeout',
      color: theme.palette.textError,
    },
    [BookingStatusEnum.Rejected]: {
      text: 'features.booking-badge:status.rejected',
      color: theme.palette.textError,
    },
  }
}
