import {observer} from 'mobx-react'
import {store} from '@store/index'
import {useLocalObservable} from 'mobx-react-lite'
import styled from '@emotion/styled'
import {RangeSlider, Text} from '@components/ui'
import useTranslation from 'next-translate/useTranslation'
import {Skeleton} from 'antd'
import {Input} from '@/library/components/ui/input'
import {type ChangeEvent} from 'react'
import {ENVIRONMENT} from '@/library/utils/guards/environment'
import {useDeviceDetection} from '@/library/hooks/use-device-detection'

const PriceOfStayWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-bottom: 1px solid ${({theme}) => theme.palette.borderTertiary};
  padding-bottom: 20px;
`

const StyledSkeleton = styled(Skeleton)`
  border-bottom: 1px solid ${({theme}) => theme.palette.borderTertiary};
  padding-bottom: 20px;
`

const InputsWrapper = styled.div`
  display: flex;
  gap: 10px;
`

const SkeletonLoader = () => {
  return <StyledSkeleton active paragraph={{rows: 1, width: '100%'}} />
}

const selectPrices = () => ({
  get priceFrom() {
    return store().pages.global.search.filters.priceFrom
  },
  get priceTo() {
    return store().pages.global.search.filters.priceTo
  },
  get loading() {
    return store().pages.global.search.filters.loading
  },
})

export const SearchScenarioFiltersPriceOfStay = observer(() => {
  const {t} = useTranslation('global.search')
  const isTouch = useDeviceDetection('touch')
  const {loading, priceFrom, priceTo} = useLocalObservable(selectPrices)

  const handleChange = (value: number | number[]) => {
    // Хендлер для ползунка
    if (Array.isArray(value)) {
      const [priceFrom, priceTo] = value
      store().pages.global.search.filters.setPriceFrom(priceFrom)
      store().pages.global.search.filters.setPriceTo(priceTo)
    }
  }

  const handleInputFromChange = (event: ChangeEvent<HTMLInputElement>) => {
    const parsedValue = Number(event.target.value)
    store().pages.global.search.filters.setPriceFrom(parsedValue)

    if (!isTouch && parsedValue > priceTo) {
      // Меняет цены местами
      store().pages.global.search.filters.setPriceFrom(priceTo)
      store().pages.global.search.filters.setPriceTo(parsedValue)
    }
  }

  const handleInputToChange = (event: ChangeEvent<HTMLInputElement>) => {
    const parsedValue = Number(event.target.value)
    store().pages.global.search.filters.setPriceTo(parsedValue)

    if (!isTouch && parsedValue < priceFrom) {
      // Меняет цены местами
      store().pages.global.search.filters.setPriceFrom(parsedValue)
      store().pages.global.search.filters.setPriceTo(priceFrom)
    }
  }

  if (loading) {
    return <SkeletonLoader />
  }

  return (
    <PriceOfStayWrapper>
      <Text size="S">{t('filters.price_of_stay.title')}</Text>
      <InputsWrapper>
        <Input
          withHookForm={false}
          name="priceFrom"
          value={String(priceFrom)}
          type="number"
          onChange={handleInputFromChange}
        />
        <Input
          withHookForm={false}
          name="priceTo"
          value={String(priceTo)}
          type="number"
          onChange={handleInputToChange}
        />
      </InputsWrapper>

      <RangeSlider
        range
        step={1000}
        min={ENVIRONMENT.MIN_PRICE_VALUE}
        max={ENVIRONMENT.MAX_PRICE_VALUE}
        value={[priceFrom, priceTo]}
        onChange={handleChange}
      />
    </PriceOfStayWrapper>
  )
})
