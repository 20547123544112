import {useDeviceDetection} from '@hooks/use-device-detection'
import {Link, Text} from '@/library/components/ui'
import useTranslation from 'next-translate/useTranslation'
import {ArrowText} from '@/library/components/ui/arrow-text'
import {useWindowSize} from '@/library/hooks/use-window-size'
import {AffiliatePaths, Routes} from '@/types/enums/routes'
import {
  LinkList,
  LinkListItem,
  LinkListTitle,
  LinkListWrapper,
} from '../link-list'
import {MOBILE_LAYOUT_BREAKPOINT} from '../../lib/constants'

export const InformationColumn = () => {
  const {t} = useTranslation('features.footer')
  const isMobile = useDeviceDetection('mobile')
  const windowSize = useWindowSize()

  if (isMobile || windowSize < MOBILE_LAYOUT_BREAKPOINT) {
    return (
      <ArrowText title={t('info')} size="boldS">
        <LinkListWrapper>
          <LinkList>
            <LinkListItem>
              <Link href={Routes.ABOUT}>
                <Text size="S"> {t('about_company')}</Text>
              </Link>
            </LinkListItem>
            <LinkListItem>
              <Link href={AffiliatePaths.INDEX}>
                <Text size="S"> {t('partner_programme')}</Text>
              </Link>
            </LinkListItem>
            <LinkListItem>
              <Link href={Routes.RULES}>
                <Text size="S"> {t('terms_of_use')}</Text>
              </Link>
            </LinkListItem>
            <LinkListItem>
              <Link href={Routes.POLITICS}>
                <Text size="S"> {t('personal_data')}</Text>
              </Link>
            </LinkListItem>
            <LinkListItem>
              <Link href={Routes.SOFTWARE}>
                <Text size="S"> {t('additional_instruments')}</Text>
              </Link>
            </LinkListItem>
            <LinkListItem>
              <Link href={Routes.PUBLIC_OFFER_AGREEMENT}>
                <Text size="S">{t('public_offer_agreement')}</Text>
              </Link>
            </LinkListItem>
          </LinkList>
        </LinkListWrapper>
      </ArrowText>
    )
  }

  return (
    <LinkListWrapper>
      <LinkListTitle size="boldM">{t('info')}</LinkListTitle>
      <LinkList>
        <LinkListItem>
          <Link href={Routes.ABOUT}>
            <Text size="S">{t('about_company')}</Text>
          </Link>
        </LinkListItem>
        <LinkListItem>
          <Link href={AffiliatePaths.INDEX}>
            <Text size="S">{t('partner_programme')}</Text>
          </Link>
        </LinkListItem>
        <LinkListItem>
          <Link href={Routes.RULES}>
            <Text size="S">{t('terms_of_use')}</Text>
          </Link>
        </LinkListItem>
        <LinkListItem>
          <Link href={Routes.POLITICS}>
            <Text size="S">{t('personal_data')}</Text>
          </Link>
        </LinkListItem>
        <LinkListItem>
          <Link href={Routes.SOFTWARE}>
            <Text size="S">{t('additional_instruments')}</Text>
          </Link>
        </LinkListItem>
        <LinkListItem>
          <Link href={Routes.PUBLIC_OFFER_AGREEMENT}>
            <Text size="S">{t('public_offer_agreement')}</Text>
          </Link>
        </LinkListItem>
      </LinkList>
    </LinkListWrapper>
  )
}
