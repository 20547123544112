import {ECurrency} from '@/types/enums/currency'
import {parseTime} from '@utils/date/parse-time'
import type {Translate} from 'next-translate'

export const formatHotelScenarioRulesPolicyTime = (
  translate: Translate,
  from?: string | null,
  until?: string | null,
): string => {
  if (!from && !until) {
    return ''
  }

  const normalizeFrom = parseTime(from)

  if (from && !until) {
    return translate('rules.time_from', {time: normalizeFrom})
  }

  const normalizeUntil = parseTime(until)

  if (until && !from) {
    return translate('rules.time_until', {time: normalizeUntil})
  }

  if (from && until) {
    return translate('rules.time_from_to_until', {
      from: normalizeFrom,
      until: normalizeUntil,
    })
  }

  return ''
}

export const formatKidsAgeRange = (
  t: Translate,
  ageStart?: number | null,
  ageEnd?: number | null,
) => {
  if (ageStart && ageEnd) {
    return `${t('rules.age_from', {count: ageStart})} ${t('rules.age_until', {count: ageEnd})}`
  }

  if (ageStart) {
    return t('rules.age_from', {count: ageStart})
  }

  if (ageEnd) {
    return t('rules.age_until', {count: ageEnd})
  }

  return ''
}

export const getCurrency = (t: Translate, currencyCode?: string | null) => {
  if (!currencyCode) {
    return t('currency_ru')
  }

  return currencyCode === ECurrency.RUB ? t('currency_ru') : currencyCode
}
