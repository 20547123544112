import styled from '@emotion/styled'
import {type FC, useEffect, useState} from 'react'
import {Button} from '@/library/components/ui'
import {useTheme} from '@emotion/react'
import {Modal} from '@ui/modal'
import {useRouter} from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import {useDeviceDetection} from '@/library/hooks/use-device-detection'
import {RefreshBody} from './ui/refresh-body'

const RefreshButton = styled(Button)`
  width: 100%;
  text-transform: uppercase;
`

const StyledModal = styled(Modal)`
  width: 100%;
  max-width: 100vw;
  vertical-align: bottom;
`

interface IProps {
  delay: number
}

export const RefreshPopup: FC<IProps> = ({delay}) => {
  const isMobile = useDeviceDetection('mobile')
  const {t} = useTranslation('features.refresh')
  const router = useRouter()
  const theme = useTheme()

  const [open, setOpen] = useState(false)

  const onReload = () => {
    router.reload()
  }

  const onCancel = () => {
    setOpen(false)
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setOpen(true)
    }, delay)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [])

  return (
    <StyledModal
      style={{verticalAlign: isMobile ? 'bottom' : 'center'}}
      width={isMobile ? '100vw' : 'auto'}
      open={open}
      maskClosable={false}
      onCancel={onCancel}
    >
      <RefreshBody />
      <RefreshButton
        variant="primary"
        color={theme.palette.textPrimary}
        onClick={onReload}
      >
        {t('button')}
      </RefreshButton>
    </StyledModal>
  )
}
