import type {ComponentProps, FC} from 'react'
import {Pagination as LibraryPagination} from 'antd'
import styled from '@emotion/styled'

const StyledPagination = styled(LibraryPagination)`
  font-family: ${({theme}) => theme.font.main};

  .ant-pagination-item-active {
    border: 1px solid ${({theme}) => theme.palette.borderAccentPrimary};

    a {
      color: ${({theme}) => theme.palette.textAccentPrimary};
    }

    &:hover {
      border: 1px solid ${({theme}) => theme.palette.borderAccentPrimary};

      a {
        color: ${({theme}) => theme.palette.textAccentPrimary};
      }
    }
  }

  .ant-pagination-item {
    cursor: pointer;
  }

  .ant-pagination-item-link-icon {
    svg {
      color: ${({theme}) => theme.palette.textAccentPrimary};
    }
  }
`

type Props = ComponentProps<typeof LibraryPagination>
export const Pagination: FC<Props> = ({...properties}) => {
  return <StyledPagination {...properties} />
}
