import styled from '@emotion/styled'
import {Button} from '@ui/button'
import {Text} from '@ui/index'
import {Icon} from '@ui/icon'
import {observer} from 'mobx-react'
import {useLocalObservable} from 'mobx-react-lite'
import {selectors} from '@components/features/search-input/store'
import useTranslation from 'next-translate/useTranslation'
import {services} from '@services/index'
import {useDeviceDetection} from '@hooks/use-device-detection'
import type {MouseEvent} from 'react'

const SearchButtonWrapper = styled(Button)<{$isActiveInput?: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;

  transition: 0.2s min-width ease-in-out;

  padding: 15px;

  min-width: fit-content;

  text-transform: uppercase;

  span svg {
    color: ${({theme}) => theme.palette.backgroundQuaternary};
  }
`

const StyledButtonText = styled(Text)<{$isActiveInput?: boolean}>`
  transition:
    0.2s max-width ease-in-out,
    0.2s opacity ease-in-out,
    0.2s margin-left ease-in-out;
  max-width: ${({$isActiveInput}) => ($isActiveInput ? '64px' : '0px')};
  opacity: ${({$isActiveInput}) => ($isActiveInput ? 1 : 0)};
  margin-left: ${({$isActiveInput}) => ($isActiveInput ? 11 : 0)}px;
  color: ${({theme}) => theme.palette.textQuaternary};
`

export const SearchInputButton = observer(() => {
  const {t} = useTranslation('features.search-input')
  const {isActiveInput} = useLocalObservable(
    selectors.selectIsActiveSearchInput,
  )
  const {isStartSearch} = useLocalObservable(selectors.selectIsStartSearch)

  const isTouch = useDeviceDetection('touch')

  const handleSearch = (event: MouseEvent<HTMLButtonElement>) => {
    // При клике на поиск на свернутом инпуте в хедере не открывать его, а сразу искать
    event.stopPropagation()
    services.components.search.handleSearch({isSearchInputHandler: true})
  }

  return (
    <SearchButtonWrapper
      disabled={isStartSearch}
      shape="round"
      $isActiveInput={isActiveInput}
      onClick={handleSearch}
    >
      <Icon name="search" width={24} height={24} />
      {!isTouch && (
        <StyledButtonText
          size="boldS"
          $isActiveInput={isActiveInput}
          type="span"
        >
          {t('search_button.label')}
        </StyledButtonText>
      )}
    </SearchButtonWrapper>
  )
})
