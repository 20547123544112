import {makeAutoObservable} from 'mobx'
import type {BookingItem} from '@/library/api/gql/generate-types'
import type {MapPoints} from '@/library/components/ui/yandex-map'

type HotelMapPoint = MapPoints | null

export class CBookingStore {
  constructor() {
    makeAutoObservable(this)
  }

  private _isLoading = false

  public get isLoading(): boolean {
    return this._isLoading
  }

  private _hotelMapPoint: HotelMapPoint = null

  public get hotelMapPoint(): HotelMapPoint {
    return this._hotelMapPoint
  }

  private _isMapOpen = false

  public get isMapOpen(): boolean {
    return this._isMapOpen
  }

  private _booking: BookingItem | null = null

  public get booking(): BookingItem | null {
    return this._booking
  }

  public setIsLoading(isLoading: boolean): void {
    this._isLoading = isLoading
  }

  public setHotelMapPoint(hotelMapPoint: HotelMapPoint): void {
    this._hotelMapPoint = hotelMapPoint
  }

  public setIsMapOpen(isMapOpen: boolean): void {
    this._isMapOpen = isMapOpen
  }

  public setBooking(booking: BookingItem | null): void {
    this._booking = booking
  }
}
