import {addPlus} from '@/library/utils/phone-numbers'
import {ENVIRONMENT} from '@utils/guards/environment'

export class ShareService {
  public getPhone = (phone: string, isWithPlus = true) =>
    isWithPlus ? `tel:${addPlus(phone)}` : `tel:${phone}`

  public getEmail(email: string, body = ''): string {
    const bodyParameter = body ? `?body=${body}` : ''

    return `mailto:${email}${bodyParameter}`
  }

  public getWhatsApp(text: string): string {
    return `${ENVIRONMENT.WHATSAPP_SHARE_LINK}?text=${text}`
  }

  public getTelegram(url: string): string {
    return `${ENVIRONMENT.TELEGRAM_SHARE_LINK}?url=${url}`
  }

  public getTwitter(url: string, text: string): string {
    return `${ENVIRONMENT.TWITTER_SHARE_LINK}?url=${url}&text=${text}`
  }

  public getVK(url: string, isTouch: boolean): string {
    return `${
      isTouch ? ENVIRONMENT.VK_MOBILE_SHARE_LINK : ENVIRONMENT.VK_SHARE_LINK
    }?url=${url}`
  }

  public getViber(text: string): string {
    return `${ENVIRONMENT.VIBER_SHARE_LINK}?text=${text}`
  }
}
