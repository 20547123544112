import {makeAutoObservable} from 'mobx'
import {DEFAULT_PAGE} from '@constants/common'
import {CAffiliateInvitesUpdate} from '@store/pages/affiliate/invites/update'
import {CAffiliateInvitesRemove} from '@store/pages/affiliate/invites/remove'
import {CAffiliateInvitesCreate} from '@store/pages/affiliate/invites/create'

export class CAffiliateInvites {
  constructor() {
    makeAutoObservable(this)
  }

  public readonly remove: CAffiliateInvitesRemove =
    new CAffiliateInvitesRemove()

  public readonly create: CAffiliateInvitesCreate =
    new CAffiliateInvitesCreate()

  public readonly update: CAffiliateInvitesUpdate =
    new CAffiliateInvitesUpdate()

  private _data: AffiliateReferralLinksData | null = null

  public get data(): AffiliateReferralLinksData | null {
    return this._data
  }

  private _paginatorInfo: AffiliateReferralLinksPaginatorInfo | null = null

  public get paginatorInfo(): AffiliateReferralLinksPaginatorInfo | null {
    return this._paginatorInfo
  }

  private _page: number = DEFAULT_PAGE

  public get page(): number {
    return this._page
  }

  private _isLoading = false

  public get isLoading(): boolean {
    return this._isLoading
  }

  public setData(data: AffiliateReferralLinksData | null): void {
    this._data = data
  }

  public setPaginatorInfo(
    data: AffiliateReferralLinksPaginatorInfo | null,
  ): void {
    this._paginatorInfo = data
  }

  public setNameCompanyByIndex(id: number, name: string): void {
    if (!this._data) {
      return
    }

    this._data[id].name = name
  }

  public setPage(page: number): void {
    this._page = page
  }

  public setIsLoading(flag: boolean): void {
    this._isLoading = flag
  }
}
