import {api} from '@/library/api'
import {errorService} from '@/library/services/common/error'
import {store} from '@/library/store'
import {clientHeaders} from '@/library/utils/http/headers'
import {removePlus} from '@/library/utils/phone-numbers'
import {SETTINGS_TIMEOUT} from '@/slices/profile/settings'
import type {SetState} from '@/types/react'
import type {ApplicationProperties} from '@/types/ui'
import {GlobalHydrateService} from '@services/common/global-hydrate'
import {filterFalsy} from '@library/utils/objects'
import {type UpdateMyUserProfileFormData} from '@library/constants/form-validation/update-my-user-profile-form/index'

export class ProfileSettingsService extends GlobalHydrateService<ApplicationProperties> {
  private get state() {
    return store().pages.profile.settings
  }

  public hydrate(initialData: ApplicationProperties) {
    if (initialData.user?.profile) {
      this.state.initializeInputValues(initialData.user.profile)
    }
    super.hydrate(initialData)
  }

  public updateProfile(setSettingsTimeout: SetState<NodeJS.Timeout | null>) {
    this.state.setIsLoading(true)
    const variables = filterFalsy<UpdateMyUserProfileFormData>(
      this.state.formData,
    )

    api.user
      .updateProfile({
        headers: clientHeaders(),
        variables,
      })
      .then(() => {
        const profile = {
          ...variables,
          phone: variables.phone ? removePlus(variables.phone) : null,
        }
        store().user.setProfile(profile)
        this.state.setIsDataSubmitted(true)
        const timeoutId = setTimeout(() => {
          this.state.setIsDataSubmitted(false)
        }, SETTINGS_TIMEOUT)
        setSettingsTimeout(timeoutId)
      })
      .catch((error) => {
        const errorText = errorService.getFirstErrorText(error)
        this.state.setError(errorText)
      })
      .finally(() => this.state.setIsLoading(false))
  }

  public cleanServerError() {
    this.state.setError('')
  }
}
