import {store} from '@/library/store'
import {isString} from '@/library/utils/guards/types'
import {getCanonicalUrl} from '@/library/utils/http/url'
import {breadcrumbListMetaBuilder} from '@/library/utils/seo/schema'
import {Routes} from '@/types/enums/routes'
import type {
  BreadcrumbItemType,
  BreadcrumbSeparatorType,
} from 'antd/es/breadcrumb/Breadcrumb'
import {observer} from 'mobx-react'
import useTranslation from 'next-translate/useTranslation'
import type {FC} from 'react'
import {BreadcrumbKeyEnum} from '../../lib/types'
import {parseBreadcrumbRenderName} from '../../lib/helpers'

interface BreadcrumbsSeoProps {
  breadcrumb: Partial<BreadcrumbItemType & BreadcrumbSeparatorType>[]
}

export const BreadcrumbsSeo: FC<BreadcrumbsSeoProps> = observer(
  ({breadcrumb}) => {
    const {t} = useTranslation('breadcrumbs')
    const {origin} = store().utilities
    return (
      <div {...breadcrumbListMetaBuilder.breadcrumbListProps}>
        {[
          {
            title: t('home'),
            href: Routes.HOME,
            breadcrumbName: BreadcrumbKeyEnum.HOME,
          },
          ...breadcrumb,
        ].map((breadcrumb, index) => (
          <div
            key={
              isString(breadcrumb.title) ? breadcrumb.title : breadcrumb.path
            }
            {...breadcrumbListMetaBuilder.itemListElementProps}
          >
            {breadcrumb.href && origin ? (
              <a
                {...breadcrumbListMetaBuilder.itemProps}
                href={getCanonicalUrl(breadcrumb.href, origin)}
              >
                {breadcrumbListMetaBuilder.renderBreadcrumbName(
                  parseBreadcrumbRenderName(
                    t,
                    breadcrumb.title,
                    breadcrumb.breadcrumbName as BreadcrumbKeyEnum,
                  ),
                )}
                {breadcrumbListMetaBuilder.renderBreadcrumbPosition(index + 1)}
              </a>
            ) : null}
          </div>
        ))}
      </div>
    )
  },
)
