import styled from '@emotion/styled'
import {type FC, useEffect, useState} from 'react'
import useTranslation from 'next-translate/useTranslation'
import {Button} from '@ui/index'
import type {MapPointData} from '@services/pages/global/polling-points'
import {MarkerBalloonContent} from '../marker'

export const CLUSTER_BALLOON_INITIAL_POINT_COUNT = 10

const PointList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  max-height: 350px;
  overflow-y: scroll;
  border-radius: 12px;
`

const Point = styled.li`
  width: 100%;
`

const StyledButton = styled(Button)`
  padding: 10px 0;
`

const StyledMarkerBalloonContent = styled(MarkerBalloonContent)`
  border-radius: 0;

  img {
    border-radius: 0;
  }
`

interface IClusterBalloonProps {
  pointsData: MapPointData[]
}

export const ClusterBalloonContent: FC<IClusterBalloonProps> = ({
  pointsData,
}) => {
  const {t} = useTranslation('global.search')
  const [isShowAll, setIsShowAll] = useState(false)
  const [points, setPoints] = useState<MapPointData[]>([])
  const shouldShowAllButton =
    pointsData.length > CLUSTER_BALLOON_INITIAL_POINT_COUNT &&
    points.length < pointsData.length

  useEffect(() => {
    const parsedPoints = isShowAll
      ? pointsData
      : pointsData.slice(0, CLUSTER_BALLOON_INITIAL_POINT_COUNT)

    setPoints(parsedPoints)
  }, [pointsData, isShowAll])

  useEffect(() => {
    setIsShowAll(false)
  }, [pointsData])

  return (
    <PointList>
      {points.map(({id, ...properties}) => {
        return (
          <Point key={`cluster_balloon_${id}`}>
            <StyledMarkerBalloonContent id={id} {...properties} />
          </Point>
        )
      })}
      {shouldShowAllButton && (
        <StyledButton variant="text" onClick={() => setIsShowAll(true)}>
          {t('map.cluster.show_more')}
        </StyledButton>
      )}
    </PointList>
  )
}
