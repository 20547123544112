export const MEALS_WITH_EXPLANATION = new Set([
  'ALL-INCLUSIVE',
  'CONTINENTAL-BREAKFAST',
  'FULL-BOARD',
  'HALF-BOARD',
  'SUPER-ALL-INCLUSIVE',
  'SOFT-ALL-INCLUSIVE',
  'ULTRA-ALL-INCLUSIVE',
  'HALF-BOARD-LUNCH',
  'HALF-BOARD-DINNER',
])
