import {makeAutoObservable} from 'mobx'
import {type GetHotelRatesFiltersQuery} from '@api/gql/generate-types'
import {ENVIRONMENT} from '@/library/utils/guards/environment'

export const DEFAULT_FILTERS_PRICE_TO_VALUE = ENVIRONMENT.MAX_PRICE_VALUE

const initialState = {
  priceFrom: 0,
  priceTo: DEFAULT_FILTERS_PRICE_TO_VALUE,
}

export type HotelTypes =
  | GetHotelRatesFiltersQuery['hotelRatesFilters']['hotelTypes']
  | null

export class CGlobalPageStoreSearchFilters {
  constructor() {
    makeAutoObservable(this)
  }

  private _priceFrom = initialState.priceFrom

  public get priceFrom(): number {
    return this._priceFrom
  }

  private _priceTo = initialState.priceTo

  public get priceTo(): number {
    return this._priceTo
  }

  private _loading = false

  public get loading(): boolean {
    return this._loading
  }

  private _error = ''

  public get error(): string {
    return this._error
  }

  private _hotelTypes: HotelTypes = null

  public get hotelTypes(): HotelTypes {
    return this._hotelTypes
  }

  private _selectHotelTypes: number[] = []

  public get selectHotelTypes(): number[] {
    return this._selectHotelTypes
  }

  private _isOpen = false

  public get isOpen(): boolean {
    return this._isOpen
  }

  private _countHotelsGroupedByRating:
    | GetHotelRatesFiltersQuery['hotelRatesFilters']['countHotelsGroupedByRating']
    | null = null

  public get countHotelsGroupedByRating():
    | GetHotelRatesFiltersQuery['hotelRatesFilters']['countHotelsGroupedByRating']
    | null {
    return this._countHotelsGroupedByRating
  }

  private _selectRatingFrom: number | null = null

  public get selectRatingFrom(): number | null {
    return this._selectRatingFrom
  }

  public setPriceFrom(priceFrom: number): void {
    this._priceFrom = priceFrom
  }

  public setPriceTo(priceTo: number): void {
    this._priceTo = priceTo
  }

  public setLoading(loading: boolean): void {
    this._loading = loading
  }

  public setError(error: string): void {
    this._error = error
  }

  public setIsOpen(isOpen: boolean): void {
    this._isOpen = isOpen
  }

  private _isApply = false

  public get isApply(): boolean {
    return this._isApply
  }

  public setIsApply(isApply: boolean): void {
    this._isApply = isApply
  }

  public setHotelTypes(
    types: GetHotelRatesFiltersQuery['hotelRatesFilters']['hotelTypes'],
  ): void {
    this._hotelTypes = types
  }

  public setCountHotelsGroupedByRating(
    countHotelsGroupedByRating: GetHotelRatesFiltersQuery['hotelRatesFilters']['countHotelsGroupedByRating'],
  ): void {
    this._countHotelsGroupedByRating = countHotelsGroupedByRating
  }

  public resetState(): void {
    this.setError('')
    this.setLoading(false)
    this.setHotelTypes(null)
    this.setCountHotelsGroupedByRating(null)
  }

  public setSelectHotelTypes(types: number[]): void {
    this._selectHotelTypes = types
  }

  public addHotelType(id: number): void {
    this._selectHotelTypes.push(id)
  }

  public removeHotelType(id: number): void {
    this.setSelectHotelTypes(
      this.selectHotelTypes.filter((existId) => existId !== id),
    )
  }

  public resetHotelTypes(): void {
    this.setSelectHotelTypes([])
  }

  public setSelectedRatingFrom(rating: number | null): void {
    this._selectRatingFrom = rating
  }

  public resetQueryStates(): void {
    this.setSelectHotelTypes([])
    this.setSelectedRatingFrom(null)
    this.setPriceFrom(initialState.priceFrom)
    this.setPriceTo(initialState.priceTo)
    this.setSelectStars([])
  }

  public isCanResetFilters(): boolean {
    if (this.selectHotelTypes.length > 0) {
      return true
    }

    if (this.selectRatingFrom !== null) {
      return true
    }

    if (this.priceFrom !== initialState.priceFrom) {
      return true
    }

    if (this.priceTo !== initialState.priceTo) {
      return true
    }

    if (this.selectStars) {
      return true
    }

    return false
  }

  private _countHotelsGroupedByStars:
    | GetHotelRatesFiltersQuery['hotelRatesFilters']['countHotelsGroupedByStars']
    | null = null

  public get countHotelsGroupedByStars():
    | GetHotelRatesFiltersQuery['hotelRatesFilters']['countHotelsGroupedByStars']
    | null {
    return this._countHotelsGroupedByStars
  }

  public setCountHotelsGroupedByStars(
    countHotelsGroupedByStars: GetHotelRatesFiltersQuery['hotelRatesFilters']['countHotelsGroupedByStars'],
  ): void {
    this._countHotelsGroupedByStars = countHotelsGroupedByStars
  }

  private _selectStars: number[] = []

  public get selectStars(): number[] {
    return this._selectStars
  }

  public setSelectStars(stars: number[]): void {
    this._selectStars = stars
  }

  public addSelectStars(stars: number): void {
    this._selectStars.push(stars)
  }

  public removeSelectStars(stars: number): void {
    this.setSelectStars(
      this.selectStars.filter((existStars) => existStars !== stars),
    )
  }
}
