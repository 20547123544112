import {useDeviceDetection} from '@/library/hooks/use-device-detection'
import type {LngLat} from '@yandex/ymaps3-types'
import {useCallback, useEffect} from 'react'
import {SearchScenarioMapCluster} from '@slices/global/ui/search-scenario/map/ui/cluster'
import {SearchScenarioMapMarker} from '@slices/global/ui/search-scenario/map/ui/marker'
import type {MapPoints} from '@ui/yandex-map'
import {useRouter} from 'next/router'
import {store} from '@store/index'
import {SearchScenarioMapDesktop} from '@slices/global/ui/search-scenario/map/ui/desktop-map'
import {SearchScenarioMapTouch} from '@slices/global/ui/search-scenario/map/ui/touch-map'
import type {MapPointData} from '@services/pages/global/polling-points'

export const SearchScenarioMap = () => {
  const isTouch = useDeviceDetection('touch')
  const router = useRouter()

  const cluster = useCallback((coordinates: LngLat, features: MapPoints[]) => {
    return (
      <SearchScenarioMapCluster coordinates={coordinates} points={features} />
    )
  }, [])

  const marker = useCallback((feature: MapPoints) => {
    return (
      <SearchScenarioMapMarker
        coordinates={feature.geometry.coordinates}
        properties={feature.properties as MapPointData}
      />
    )
  }, [])

  useEffect(() => {
    const handleResetPoints = (url: string, {shallow}: {shallow: boolean}) => {
      if (!shallow) {
        store().pages.global.search.map.setPoints(null)
      }
    }
    router.events.on('routeChangeStart', handleResetPoints)
    return () => {
      router.events.off('routeChangeStart', handleResetPoints)
    }
  }, [router.events])

  if (isTouch) {
    return <SearchScenarioMapTouch cluster={cluster} marker={marker} />
  }

  return <SearchScenarioMapDesktop cluster={cluster} marker={marker} />
}
