import {TRANSITION_STYLES} from '@/library/constants/css'
import styled from '@emotion/styled'
import NextLink from 'next/link'
import {css} from '@emotion/react'
import {PRIMARY_LINK_STYLES} from '@ui/link/ui/link.primary'

const LINK_VARIANTS = {
  primary: PRIMARY_LINK_STYLES,
}

type Props = {
  $variant?: keyof typeof LINK_VARIANTS
  $disabled?: boolean
}

export const Link = styled(NextLink)<Props>`
  text-decoration: none;
  transition: color ${TRANSITION_STYLES};
  color: ${({theme}) => theme.palette.textPrimary};

  &:hover {
    cursor: pointer;
    color: ${({theme}) => theme.palette.textAccentPrimary};
  }

  ${({theme, $variant}) => $variant && LINK_VARIANTS[$variant](theme)};

  ${({theme, $disabled}) =>
    $disabled &&
    css`
      cursor: not-allowed;
      background-color: ${theme.palette.backgroundTertiary};
      pointer-events: none;
    `};
`
