import styled from '@emotion/styled'
import type {FC} from 'react'
import {YMapMarker} from 'ymap3-components'
import type {LngLat} from '@yandex/ymaps3-types'
import {ECurrency} from '@/types/enums/currency'
import {Illustration} from '@ui/illustration'
import {PopoverWithClose} from '@/library/components/ui/popover-with-close'
import type {MapPointData} from '@services/pages/global/polling-points'
import {BalloonContent} from '@components/features'
import {css} from '@emotion/react'
import {YANDEX_MAP_DATA_SOURCE_ID} from '@ui/yandex-map'

const Wrapper = styled.div<{$isSelectHotel: boolean}>`
  background-color: ${({theme}) => theme.palette.backgroundAccentPrimary};
  border-radius: 8px;
  box-shadow: 0 2px 4px ${({theme}) => theme.palette.boxShadowPrimary};
  box-sizing: border-box;
  color: ${({theme}) => theme.palette.textQuaternary};
  display: inline-block;
  font-size: 12px;
  font-weight: 700;
  height: 24px;
  line-height: 24px;
  padding: 0 8px;
  position: relative;
  top: -5px;
  transform: translate(-50%, -100%);
  white-space: nowrap;
  cursor: pointer;

  ${({$isSelectHotel, theme}) =>
    $isSelectHotel &&
    css`
      background-color: ${theme.palette.backgroundPrimary};
    `}
  &::after {
    background-color: ${({theme, $isSelectHotel}) =>
      $isSelectHotel
        ? theme.palette.backgroundPrimary
        : theme.palette.backgroundAccentPrimary};
    bottom: 5px;
    box-shadow: 4px 4px 4px ${({theme}) => theme.palette.boxShadowPrimary};
    content: '';
    height: 8px;
    left: 50%;
    position: absolute;
    transform: translate(-50%, 100%) rotate(45deg);
    width: 8px;
  }
`

const StyledIllustration = styled(Illustration)`
  cursor: pointer;
`

const StyledPopoverWithClose = styled(PopoverWithClose)`
  padding: 0;
`

interface Props {
  coordinates: LngLat
  properties: MapPointData
  isSelectHotel: boolean
}

export const HotelScenarioPlaceMapMarker: FC<Props> = ({
  properties,
  coordinates,
  isSelectHotel,
}) => {
  if (!properties.price) {
    return (
      <StyledPopoverWithClose
        closeVariant="secondary"
        content={<BalloonContent.Marker {...properties} />}
      >
        <YMapMarker
          source={YANDEX_MAP_DATA_SOURCE_ID}
          coordinates={coordinates}
        >
          <StyledIllustration
            name={isSelectHotel ? 'hotel_point' : 'logo_small'}
            width={36}
            height={36}
          />
        </YMapMarker>
      </StyledPopoverWithClose>
    )
  }

  return (
    <StyledPopoverWithClose
      closeVariant="secondary"
      content={<BalloonContent.Marker {...properties} />}
    >
      <YMapMarker source={YANDEX_MAP_DATA_SOURCE_ID} coordinates={coordinates}>
        {isSelectHotel ? (
          <StyledIllustration name="hotel_point" width={36} height={36} />
        ) : (
          <Wrapper
            $isSelectHotel={isSelectHotel}
          >{`${properties.price.toLocaleString()} ${ECurrency.RUB}`}</Wrapper>
        )}
      </YMapMarker>
    </StyledPopoverWithClose>
  )
}
