import styled from '@emotion/styled'
import {observer} from 'mobx-react'
import {store} from '@store/index'
import useTranslation from 'next-translate/useTranslation'
import {ArrowText} from '@/library/components/ui/arrow-text'
import {isEmpty} from 'ramda'
import DOMPurify from 'isomorphic-dompurify'
import type {FilteredHotelFaq} from './lib/types'

const Wrapper = styled.div`
  padding: 20px 17px 7px 17px;
  border: 1px solid ${({theme}) => theme.palette.borderTertiary};
  border-radius: 12px;
`

const Title = styled.h2`
  margin-bottom: 27px;
  font-weight: 500;
  font-size: 20px;
  color: ${({theme}) => theme.palette.textPrimary};
`

const FaqItemsWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 7px;
`

const StyledArrowText = styled(ArrowText)`
  button {
    min-height: 56px;
    padding: 12px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 11px;
  }

  &:last-of-type {
    button {
      border-bottom: none;
    }
  }
`

const FaqAnswerWrapper = styled.div`
  overflow: hidden;
  padding: 0 10px 10px 0;

  * {
    text-wrap: wrap;
  }
`

const FaqAnswer = styled.span`
  font-size: 15px;
  font-weight: 400;
  line-height: 19px;
  color: ${({theme}) => theme.palette.textPrimary};
  word-wrap: break-word;
`

export const HotelScenarioFaq = observer(() => {
  const {data} = store().pages.global.hotel
  const {t} = useTranslation('global.hotel')

  if (!data?.enabledHotelFaqs || isEmpty(data?.enabledHotelFaqs)) {
    return null
  }

  const filteredHotelFaqs = data.enabledHotelFaqs.filter(
    (faq): faq is FilteredHotelFaq => !!faq,
  )

  if (isEmpty(filteredHotelFaqs)) {
    return null
  }

  return (
    <Wrapper>
      {data.name ? (
        <Title>{t('faq.title_with_hotel', {hotelName: data.name})}</Title>
      ) : (
        <Title>{t('faq.title')}</Title>
      )}

      <FaqItemsWrapper>
        {filteredHotelFaqs.map((faqItem) => (
          <StyledArrowText
            title={faqItem.question}
            size="S"
            key={faqItem.id}
            boldTitleOnOpen
          >
            <FaqAnswerWrapper>
              <FaqAnswer
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(faqItem.answer),
                }}
              />
            </FaqAnswerWrapper>
          </StyledArrowText>
        ))}
      </FaqItemsWrapper>
    </Wrapper>
  )
})
