import NextNProgress from 'nextjs-progressbar'
import Head from 'next/head'

export const RoutingProgressBar = () => {
  const transformCSS = (css: string) => (
    <Head>
      <style>{css}</style>
    </Head>
  )

  return (
    <NextNProgress
      color="var(--loading-route-color)"
      startPosition={0.3}
      stopDelayMs={200}
      height={3}
      transformCSS={transformCSS}
      showOnShallow
    />
  )
}
