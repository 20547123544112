import {makeAutoObservable} from 'mobx'
import {ProviderRatesSearchingTypeEnum} from '@api/gql/generate-types'
import {CGlobalPageStoreSearch} from '@store/pages/global/search'
import {CGlobalPageStoreStatic} from '@store/pages/global/static'
import {CGlobalPageStoreHotel} from '@store/pages/global/hotel'
import type {DynamicGlobalPageProperties} from '@slices/global/lib/scenarios'

type Info = DynamicGlobalPageProperties['info'] | null

type Rates = DynamicGlobalPageProperties['seoRates'] | null

export class CGlobalPageStore {
  public readonly search: CGlobalPageStoreSearch = new CGlobalPageStoreSearch()

  public readonly static: CGlobalPageStoreStatic = new CGlobalPageStoreStatic()

  public readonly hotel: CGlobalPageStoreHotel = new CGlobalPageStoreHotel()

  constructor() {
    makeAutoObservable(this)
  }

  private _info: Info = null

  public get info(): Info {
    return this._info
  }

  private _seoRates: Rates = null

  public get seoRates(): Rates {
    return this._seoRates
  }

  public setInfo(info: Info): void {
    this._info = info
  }

  public setSeoRates(rates: Rates): void {
    this._seoRates = rates
  }

  private _searchingType = ProviderRatesSearchingTypeEnum.Geo

  public get searchingType(): ProviderRatesSearchingTypeEnum {
    return this._searchingType
  }

  public setSearchingType(searchingType: ProviderRatesSearchingTypeEnum): void {
    this._searchingType = searchingType
  }
}
