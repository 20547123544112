import {store} from '@/library/store'

export const selectMapOptions = () => ({
  get zoom() {
    return store().pages.global.search.map.zoom
  },
  get center() {
    return store().pages.global.search.map.center
  },
  get hotelCenter() {
    return store().pages.global.search.map.hotelCenter
  },
  get hotelBounds() {
    return store().pages.global.search.map.hotelBounds
  },
  get bounds() {
    return store().pages.global.search.map.bounds
  },
  get points() {
    return store().pages.global.search.map.points
  },
  get loading() {
    return store().pages.global.search.map.loading
  },
  get modalOpen() {
    return store().pages.global.search.map.modalOpen
  },
  get layer() {
    return store().pages.global.search.map.layer
  },
})
