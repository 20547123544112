import type {AuthUser} from '@/library/api/gql/generate-types'

export enum EAuthorizationModalAction {
  LOGIN = 'login',
  REGISTER = 'register',
  RESET_PASSWORD = 'reset-password',
}

export enum ConfirmationStatus {
  CONFIRMED = 'confirmed',
  INTERNAL = 'internal',
  WRONG_CODE = 'wrong_code',
}

export type UserState = Pick<AuthUser, 'api_token' | 'roles' | 'email'>

export enum EPasswordResetStep {
  EMAIL = 'email',
  CODE = 'code',
  SUCCESS = 'success',
}

export interface LoginStoreInitialStateType {
  error: string
}

export interface RegisterStoreInitialStateType {
  error: string
  isSuccessful: boolean
}

export interface ResetPasswordStoreInitialStateType {
  error: string
  confirmationMessage: string
  resetPasswordEmail: string
  step: EPasswordResetStep
}

export interface AuthorizationStoreInitialStateType {
  isAuthorized: boolean
  modalOpen: boolean
  modalAction: EAuthorizationModalAction
  isLoading: boolean
}
