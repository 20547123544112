import {ENVIRONMENT} from '@/library/utils/guards/environment'

interface WebSite extends Record<string, unknown> {
  '@context': string
  '@type': 'WebSite'
  url: string
}

interface WebSiteProps {
  origin: string
}

export const buildWebSiteLdProps = ({origin}: WebSiteProps): WebSite => {
  return {
    '@context': ENVIRONMENT.SCHEMA_URL,
    '@type': 'WebSite',
    url: origin,
  }
}
