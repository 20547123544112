import styled from '@emotion/styled'
import Link from 'next/link'
import {useDeviceDetection} from '@hooks/use-device-detection'
import {Routes} from '@/types/enums/routes'
import {css} from '@emotion/react'
import {LOADING_STYLES} from '@/library/constants/css'
import {useLocalObservable} from 'mobx-react-lite'
import {MEDIA_DEVICE_QUERY} from '@/assets/theme/media'
import {Illustration} from '@ui/illustration'
import {HeaderActions} from './ui/actions'
import {selectors} from './store'

const StyledHeader = styled.header<{$isLoading: boolean}>`
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 19;
  background-color: ${({theme}) => theme.palette.backgroundQuaternary};
  border-bottom: 1px solid ${({theme}) => theme.palette.borderTertiary};

  ${({$isLoading}) =>
    $isLoading &&
    css`
      ${LOADING_STYLES}
    `}
`

const Container = styled.div<{$isTouch?: boolean; maxWidth?: number}>`
  margin: 0 auto;
  max-width: ${({maxWidth}) => maxWidth || 1920}px;

  width: 100%;
  height: 80px;

  display: flex;
  justify-content: ${({$isTouch}) => ($isTouch ? 'center' : 'space-between')};
  align-items: center;

  @media ${MEDIA_DEVICE_QUERY.BIG_SCREEN} {
    padding: 0 110px;
  }
  @media ${MEDIA_DEVICE_QUERY.DESKTOP} {
    padding: 0 110px;
  }
  @media ${MEDIA_DEVICE_QUERY.LAPTOP} {
    padding: 0px 32px;
  }
  @media ${MEDIA_DEVICE_QUERY.MOBILE_LARGE} {
    padding: 0 16px;
  }
`

const StyledLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

export const Header = () => {
  const isTouch = useDeviceDetection('touch')
  const {isLoading} = useLocalObservable(selectors.selectHeaderIsLoading)

  return (
    <StyledHeader $isLoading={isLoading}>
      <Container $isTouch={isTouch}>
        <StyledLink href={Routes.HOME}>
          <Illustration name="logo_large" width={190} height="100%" />
        </StyledLink>
        {!isTouch && <HeaderActions />}
      </Container>
    </StyledHeader>
  )
}
