import styled from '@emotion/styled'
import {type FC} from 'react'
import {YMapMarker} from 'ymap3-components'
import type {LngLat} from '@yandex/ymaps3-types'
import {ECurrency} from '@/types/enums/currency'
import {Illustration} from '@ui/illustration'
import {PopoverWithClose} from '@/library/components/ui/popover-with-close'
import type {MapPointData} from '@services/pages/global/polling-points'
import {BalloonContent} from '@components/features'
import {css} from '@emotion/react'
import {store} from '@store/index'
import {observer} from 'mobx-react'
import {YANDEX_MAP_DATA_SOURCE_ID} from '@ui/yandex-map'
import {TRANSITION_STYLES} from '@/library/constants/css'

const Wrapper = styled.div<{
  $isAlreadyOpen: boolean
  $isCurrentMarker: boolean
}>`
  background-color: ${({$isCurrentMarker, theme}) =>
    $isCurrentMarker
      ? theme.palette.backgroundPrimary
      : theme.palette.backgroundAccentPrimary};
  border-radius: 8px;
  box-shadow: 0 2px 4px ${({theme}) => theme.palette.boxShadowPrimary};
  box-sizing: border-box;
  color: ${({theme}) => theme.palette.textQuaternary};
  display: inline-block;
  font-size: 12px;
  font-weight: 700;
  height: 24px;
  line-height: 24px;
  padding: 0 8px;
  position: relative;
  top: -5px;
  transform: translate(-50%, -100%);
  transition: background-color ${TRANSITION_STYLES};
  white-space: nowrap;
  cursor: pointer;

  &::after {
    background-color: ${({theme, $isAlreadyOpen, $isCurrentMarker}) =>
      $isAlreadyOpen
        ? theme.palette.backgroundSecondary
        : $isCurrentMarker
          ? theme.palette.backgroundPrimary
          : theme.palette.backgroundAccentPrimary};
    bottom: 5px;
    box-shadow: 4px 4px 4px ${({theme}) => theme.palette.boxShadowPrimary};
    content: '';
    height: 8px;
    left: 50%;
    position: absolute;
    transition: background-color ${TRANSITION_STYLES};
    transform: translate(-50%, 100%) rotate(45deg);
    width: 8px;
  }

  ${({$isAlreadyOpen, theme}) =>
    $isAlreadyOpen &&
    css`
      background-color: ${theme.palette.backgroundSecondary};
      color: ${theme.palette.textQuaternary};
      text-shadow: ${theme.palette.textPrimary} 1px 0 15px;
    `}
`

const StyledIllustration = styled(Illustration)<{$isAlreadyOpen: boolean}>`
  cursor: pointer;
`

const StyledPopoverWithClose = styled(PopoverWithClose)`
  padding: 0;
`

interface Props {
  coordinates: LngLat
  properties: MapPointData
  parentOpen?: boolean
}

export const SearchScenarioMapMarker: FC<Props> = observer(
  ({properties, coordinates, parentOpen = false}) => {
    const {map} = store().pages.global.search
    const [currentLat, currentLng] = coordinates
    const [selectedLng, selectedLat] = map.center

    const isCurrentMarker =
      currentLat === selectedLat && currentLng === selectedLng

    const isAlreadyOpened = map.isAlreadyOpenedPoint(properties.id)

    const handleCheckedIsAlreadyOpened = () => {
      if (!map.isAlreadyOpenedPoint(properties.id)) {
        map.addAlreadyOpenPoints(properties.id)
      }
    }

    if (!properties.price) {
      return (
        <StyledPopoverWithClose
          closeVariant="secondary"
          parentOpen={parentOpen}
          content={<BalloonContent.Marker {...properties} />}
        >
          <YMapMarker
            source={YANDEX_MAP_DATA_SOURCE_ID}
            coordinates={coordinates}
            onClick={handleCheckedIsAlreadyOpened}
          >
            <StyledIllustration
              $isAlreadyOpen={isAlreadyOpened}
              name="logo_small"
              width={36}
              height={36}
            />
          </YMapMarker>
        </StyledPopoverWithClose>
      )
    }

    return (
      <StyledPopoverWithClose
        closeVariant="secondary"
        content={<BalloonContent.Marker {...properties} />}
      >
        <YMapMarker
          source={YANDEX_MAP_DATA_SOURCE_ID}
          coordinates={coordinates}
          onClick={handleCheckedIsAlreadyOpened}
        >
          <Wrapper
            $isAlreadyOpen={isAlreadyOpened}
            $isCurrentMarker={isCurrentMarker}
          >{`${properties.price.toLocaleString()} ${ECurrency.RUB}`}</Wrapper>
        </YMapMarker>
      </StyledPopoverWithClose>
    )
  },
)
