import {$gqlClient} from '@api/gql/client'
import {path} from 'ramda'

import {
  ChangePasswordDocument,
  type ChangePasswordMutationVariables,
  LoginDocument,
  type LoginMutation,
  type LoginMutationVariables,
  LogoutDocument,
  type LogoutMutation,
  type LogoutMutationVariables,
  PasswordResetConfirmDocument,
  type PasswordResetConfirmMutation,
  type PasswordResetConfirmMutationVariables,
  PasswordResetDocument,
  type PasswordResetMutation,
  type PasswordResetMutationVariables,
  RegisterTemporaryAuthorizationTokenDocument,
  type RegisterTemporaryAuthorizationTokenMutation,
  type RegisterTemporaryAuthorizationTokenMutationVariables,
  RegistrationDocument,
  type RegistrationMutation,
  type RegistrationMutationVariables,
  VerifyEmailConfirmDocument,
  type VerifyEmailConfirmMutation,
  type VerifyEmailConfirmMutationVariables,
} from '@api/gql/generate-types'
import {clientHeaders} from '@utils/http/headers'

// Логин
type LoginContext = {
  variables: LoginMutationVariables
  headers?: Headers
}
const login = ({variables, headers}: LoginContext) =>
  $gqlClient()
    .mutate<LoginMutation, LoginMutationVariables>({
      mutation: LoginDocument,
      variables,
      fetchPolicy: 'no-cache',
      context: {
        headers: headers || clientHeaders(),
      },
    })
    .then(path<LoginMutation['login']>(['data', 'login']))

// Регистрация
type RegisterContext = {
  variables: RegistrationMutationVariables
  headers?: Headers
}
const register = ({variables, headers}: RegisterContext) =>
  $gqlClient()
    .mutate<RegistrationMutation, RegistrationMutationVariables>({
      mutation: RegistrationDocument,
      variables,
      fetchPolicy: 'no-cache',
      context: {
        headers: headers || clientHeaders(),
      },
    })
    .then(path<RegistrationMutation['register']>(['data', 'register']))

// Логаут
const logout = (headers?: Headers) =>
  $gqlClient()
    .mutate<LoginMutation, LogoutMutationVariables>({
      mutation: LogoutDocument,
      fetchPolicy: 'no-cache',
      context: {
        headers: headers || clientHeaders(),
      },
    })
    .then(path<LogoutMutation['logout']>(['data', 'logout']))

// Сбросить пароль
type PasswordResetContext = {
  variables: PasswordResetMutationVariables
  headers?: Headers
}
const passwordReset = ({variables, headers}: PasswordResetContext) =>
  $gqlClient()
    .mutate<PasswordResetMutation, PasswordResetMutationVariables>({
      mutation: PasswordResetDocument,
      variables,
      fetchPolicy: 'no-cache',
      context: {
        headers: headers || clientHeaders(),
      },
    })
    .then(
      path<PasswordResetMutation['resetPasswordSendRequest']>([
        'data',
        'resetPasswordSendRequest',
      ]),
    )

// Подтверждение кода при сбросе пароля
type PasswordResetConfirmContext = {
  variables: PasswordResetConfirmMutationVariables
  headers?: Headers
}
const passwordResetConfirm = ({
  variables,
  headers,
}: PasswordResetConfirmContext) =>
  $gqlClient()
    .mutate<
      PasswordResetConfirmMutation,
      PasswordResetConfirmMutationVariables
    >({
      mutation: PasswordResetConfirmDocument,
      variables,
      fetchPolicy: 'no-cache',
      context: {
        headers: headers || clientHeaders(),
      },
    })
    .then(
      path<PasswordResetConfirmMutation['resetPasswordConfirm']>([
        'data',
        'resetPasswordConfirm',
      ]),
    )

type ChangePasswordContext = {
  variables: ChangePasswordMutationVariables
  headers?: Headers
}

const changePassword = ({headers, variables}: ChangePasswordContext) =>
  $gqlClient().mutate<LoginMutation, ChangePasswordMutationVariables>({
    mutation: ChangePasswordDocument,
    variables,
    fetchPolicy: 'no-cache',
    context: {
      headers: headers || clientHeaders(),
    },
  })

type VerifyEmailContext = {
  variables: VerifyEmailConfirmMutationVariables
  headers?: Headers
}

const verifyEmail = ({headers, variables}: VerifyEmailContext) =>
  $gqlClient()
    .mutate<VerifyEmailConfirmMutation, VerifyEmailConfirmMutationVariables>({
      mutation: VerifyEmailConfirmDocument,
      variables,
      fetchPolicy: 'no-cache',
      context: {
        headers: headers || clientHeaders(),
      },
    })
    .then(path<VerifyEmailConfirmMutation>(['data']))

type RegisterTemporaryAuthorizationTokenContext = {
  variables: RegisterTemporaryAuthorizationTokenMutationVariables
  headers?: Headers
}

const registerTemporaryAuthorizationToken = ({
  headers,
  variables,
}: RegisterTemporaryAuthorizationTokenContext) =>
  $gqlClient()
    .mutate<
      RegisterTemporaryAuthorizationTokenMutation,
      RegisterTemporaryAuthorizationTokenMutationVariables
    >({
      mutation: RegisterTemporaryAuthorizationTokenDocument,
      variables,
      fetchPolicy: 'no-cache',
      context: {
        headers: headers || clientHeaders(),
      },
    })
    .then(
      path<RegisterTemporaryAuthorizationTokenMutation['temporaryAuthAsUser']>([
        'data',
        'temporaryAuthAsUser',
      ]),
    )

export const authorizationApi = {
  registerTemporaryAuthorizationToken,
  register,
  login,
  logout,
  passwordReset,
  passwordResetConfirm,
  changePassword,
  verifyEmail,
}
