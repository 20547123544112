import {Link, Text} from '@/library/components/ui'
import useTranslation from 'next-translate/useTranslation'
import {ProfilePaths} from '@/types/enums/routes'
import {store} from '@/library/store'
import {EAuthorizationModalAction} from '@/library/store/authorization/types'
import type {MouseEvent} from 'react'
import {observer, useLocalObservable} from 'mobx-react'
import {LinkListItem} from '../../link-list'
import {selectIsAuthorized} from '../lib/selectors'

export const PersonalAccountLink = observer(() => {
  const {t} = useTranslation('features.footer')
  const {isAuthorized} = useLocalObservable(selectIsAuthorized)

  const handleOpenAuthorizationModal = (
    event: MouseEvent<HTMLAnchorElement>,
  ) => {
    event.preventDefault()

    store().authorization.setModalAction(EAuthorizationModalAction.LOGIN)
    store().authorization.setModalOpen(true)
  }

  if (isAuthorized) {
    return (
      <LinkListItem>
        <Link href={ProfilePaths.BOOKINGS}>
          <Text size="boldS"> {t('personal_account')}</Text>
        </Link>
      </LinkListItem>
    )
  }

  return (
    <LinkListItem>
      <Link href={ProfilePaths.BOOKINGS} onClick={handleOpenAuthorizationModal}>
        <Text size="boldS"> {t('personal_account')}</Text>
      </Link>
    </LinkListItem>
  )
})
