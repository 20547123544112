import {Skeleton} from 'antd'
import styled from '@emotion/styled'
import {MEDIA_DEVICE_QUERY} from '@/assets/theme/media'

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 250px;
  border-radius: 12px;
  box-shadow: 0 2px 4px 1px ${({theme}) => theme.palette.boxShadowPrimary};
  border: 1px solid ${({theme}) => theme.palette.borderQuinary};
  margin-bottom: 20px;
  background-color: ${({theme}) => theme.palette.backgroundQuaternary};

  &:last-of-type {
    margin-bottom: 0px;
  }

  @media ${MEDIA_DEVICE_QUERY.LAPTOP_LARGE} {
    margin-bottom: 0;
    height: auto;
    flex-direction: column;
  }
`

const ContentWrapper = styled.div`
  padding: 10px 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 10px;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const HeaderInformationWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  gap: 10px;
  width: 70%;
`

const HeaderReviewWrapper = styled.div`
  display: flex;
  justify-content: end;
  align-items: end;
  flex-direction: column;
  gap: 10px;
  width: 30%;
`

const Main = styled.div``

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media ${MEDIA_DEVICE_QUERY.MOBILE_LARGE} {
    align-items: start;
    flex-direction: column;
    gap: 10px;
  }
`

const StyledSkeletonimage = styled(Skeleton.Image)`
  min-width: 300px;
  min-height: 250px;

  @media ${MEDIA_DEVICE_QUERY.LAPTOP_LARGE} {
    min-height: 300px;
    min-width: 100%;
  }
`

export const HotelCardSkeleton = () => {
  return (
    <Wrapper>
      <StyledSkeletonimage active />
      <ContentWrapper>
        <Header>
          <HeaderInformationWrapper>
            <Skeleton.Button active block style={{width: '100%', height: 20}} />
            <Skeleton.Button active block style={{width: '100%', height: 20}} />
          </HeaderInformationWrapper>
          <HeaderReviewWrapper>
            <Skeleton.Button active style={{width: 10, height: 20}} />
            <Skeleton.Button active style={{width: 50, height: 20}} />
          </HeaderReviewWrapper>
        </Header>
        <Main />
        <Footer>
          <Skeleton.Button active style={{width: 250}} />
          <Skeleton.Button active style={{width: 100}} />
        </Footer>
      </ContentWrapper>
    </Wrapper>
  )
}
