import {Upload as LibraryUpload} from 'antd'
import {type ComponentProps, type FC} from 'react'
import styled from '@emotion/styled'
import {Text} from '@ui/index'
import {TRANSITION_STYLES} from '@constants/css'
import {Icon} from '@ui/icon'
import {css} from '@emotion/react'
import type {UploadChangeParam, UploadFile} from 'antd/es/upload/interface'
import useTranslation from 'next-translate/useTranslation'
import {convertBytes} from '@utils/convert/bytes'

const StyleLibraryUpload = styled(LibraryUpload)`
  .ant-upload-select {
    width: 100%;
  }
`

const Error = styled(Text)`
  color: ${({theme}) => theme.palette.textError};
  text-align: right;
  margin-top: 5px;
`

const UploadWrapper = styled.div<{$disabled?: boolean}>`
  width: 100%;
  height: 156px;
  border: 1px dashed ${({theme}) => theme.palette.borderAccentPrimary};
  background-color: ${({theme}) => theme.palette.overlaySecondary};
  border-radius: 6px;
  cursor: copy;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  transition: border ${TRANSITION_STYLES};

  &:hover {
    border: 1px dashed ${({theme}) => theme.palette.textPositive};
  }

  .title {
    color: ${({theme}) => theme.palette.textAccentPrimary};
  }

  .subtitle {
    color: ${({theme}) => theme.palette.textSecondary};
  }

  ${({theme, $disabled}) =>
    $disabled &&
    css`
      .title {
        color: ${theme.palette.textSecondary};
      }

      .subtitle {
        color: ${theme.palette.textSecondary};
      }

      border: 1px dashed ${theme.palette.borderSecondary};
      cursor: no-drop;

      &:hover {
        border: 1px dashed ${theme.palette.borderSecondary};
      }
    `}
`

const createError = (
  file: UploadChangeParam<UploadFile>['file'],
  message: string,
) => {
  // eslint-disable-next-line no-param-reassign
  file.status = 'error'
  // eslint-disable-next-line no-param-reassign
  file.response = message
}

interface Props extends ComponentProps<typeof StyleLibraryUpload> {
  title?: string
  subTitle?: string
  error?: string
  maxSize: number // MB
}

export const UploadImage: FC<Props> = ({
  title,
  subTitle,
  disabled,
  multiple = true,
  onChange,
  accept,
  maxSize,
  error,
  ...properties
}) => {
  const {t} = useTranslation()
  const handleChange = ({
    file,
    fileList,
    event,
  }: UploadChangeParam<UploadFile>) => {
    if (file && file.status === 'done') {
      if (!file?.size || !file?.type || !file.response) {
        return
      }

      const isFileTypeEqualAccept = accept
        ?.replace(/ /g, '')
        .split(',')
        .includes(file.type)
      if (!isFileTypeEqualAccept) {
        createError(
          file,
          t('yup:upload_image.errors.invalid_file_format', {
            accept,
          }),
        )
      }

      const fileSize = convertBytes(file.size, 'MiB')
      if (fileSize >= maxSize) {
        createError(
          file,
          t('yup:upload_image.errors.invalid_file_format', {
            maxSize,
          }),
        )
      }
    }

    onChange?.({file, fileList, event})
  }

  return (
    <StyleLibraryUpload
      listType="picture"
      multiple={multiple}
      disabled={disabled}
      onChange={handleChange}
      accept={accept}
      {...properties}
    >
      <UploadWrapper $disabled={disabled}>
        <Icon name="image" width={24} height={24} />
        {title && (
          <Text className="title" size="boldS">
            {title}
          </Text>
        )}
        {subTitle && (
          <Text className="subtitle" size="boldS">
            {subTitle}
          </Text>
        )}
      </UploadWrapper>
      {error && <Error size="boldS">{error}</Error>}
    </StyleLibraryUpload>
  )
}
