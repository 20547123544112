import {SearchQueryParserService} from '@services/common/search-query-parser'
import type {ParsedUrlQuery} from 'node:querystring'
import {store} from '@store/index'
import type {RateSearchParameters} from '@api/gql/generate-types'
import {format} from 'date-fns'
import {DateFormatPattern} from '@/types/enums/date-format-patterns'

export class GlobalPageHotelUrlQueryParser extends SearchQueryParserService {
  public parse(query: ParsedUrlQuery): void {
    const {
      adults,
      dateFrom,
      dateTo,
      priceFrom,
      priceTo,
      kidsAge,
      searchingType,
    } = query

    const parsedAdults = this.parseAdults(adults)
    store().search.guests.adults.setAmount(parsedAdults)

    const parseDate = this.parseDate(dateFrom, dateTo)
    store().search.dateFrom.setValue(parseDate.dateFrom)
    store().search.dateTo.setValue(parseDate.dateTo)

    const children = this.parseKidsAge(kidsAge)

    store().search.guests.children.setChildren(children)

    const {parsedPriceFrom, parsedPriceTo} = this.parsePrices(
      priceFrom,
      priceTo,
    )

    const rates: RateSearchParameters = {
      adults: parsedAdults,
      checkin: format(parseDate.dateFrom, DateFormatPattern.QUERY),
      checkout: format(parseDate.dateTo, DateFormatPattern.QUERY),
      priceFrom: parsedPriceFrom,
      priceTo: parsedPriceTo,
      children,
    }

    store().pages.global.hotel.rates.setRatesSearchParameters(rates)

    const parsedSearchingType = this.parseSearchingType(searchingType)
    store().pages.global.hotel.setSearchingType(parsedSearchingType)
  }
}
