import styled from '@emotion/styled'
import {type FC, type ReactNode} from 'react'
import type {TRowBody, TRowHead} from '@ui/table/lib'

const TableWrapper = styled.table`
  border-collapse: collapse;
  width: 100%;
  border: 1px solid ${({theme}) => theme.palette.borderTertiary};
`

const TableHead = styled.thead<{hideBorder?: boolean}>`
  border-bottom: ${({theme, hideBorder}) =>
    hideBorder ? 'none' : `1px solid ${theme.palette.borderTertiary}`};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  white-space: nowrap;
  text-transform: uppercase;
  color: ${({theme}) => theme.palette.textPrimary};
`

export const RowHead = styled.tr``

export const RowHeadData = styled.td<{isFirst?: boolean; isLast?: boolean}>`
  padding: ${({isFirst, isLast}) =>
    isLast ? '22px 0 22px 22px' : isFirst ? '22px 22px 22px 0px' : '22px 22px'};
  text-align: ${({isLast}) => (isLast ? 'end' : 'start')};
  font-weight: 500;
`

export const TableBody = styled.tbody`
  color: ${({theme}) => theme.palette.textPrimary};
`

export const RowBody = styled.tr``

export const RowBodyData = styled.td<{
  isFirst?: boolean
  hideBorder?: boolean
  isLast?: boolean
}>`
  font-family: ${({theme}) => theme.font.main};
  padding: ${({isFirst, isLast}) =>
    isLast ? '22px 0 22px 22px' : isFirst ? '22px 22px 22px 0px' : '22px 22px'};
  border-bottom: ${({theme, hideBorder}) =>
    hideBorder ? 'none' : `1px solid ${theme.palette.borderTertiary}`};
  font-weight: 600;
  font-size: 16px;
  white-space: nowrap;
  line-height: 24px;
  text-align: ${({isLast}) => (isLast ? 'end' : 'start')};
  display: table-cell;
  vertical-align: middle;

  &:last-child {
    border-right: 0;
  }
`

interface Props {
  rowHead?: TRowHead[]
  rowHeadChildren?: ReactNode
  rowBody?: TRowBody[]
  rowBodyChildren?: ReactNode
  hideBorder?: boolean
}

export const Table: FC<Props> = ({
  rowHead,
  rowHeadChildren,
  rowBody,
  rowBodyChildren,
  hideBorder = false,
}) => {
  return (
    <TableWrapper>
      <TableHead hideBorder={hideBorder}>
        {rowHead?.map((row) => (
          <RowHead key={row.id}>
            {row.children.map((child) => (
              <RowHeadData key={child.label} {...child}>
                {child.label}
              </RowHeadData>
            ))}
          </RowHead>
        ))}
        {rowHeadChildren && rowHeadChildren}
      </TableHead>
      <TableBody>
        {rowBody?.map((row) => (
          <RowBody key={row.id}>
            {row.children.map((child) => (
              <RowBodyData key={child.label} {...child} hideBorder={hideBorder}>
                {child.label}
              </RowBodyData>
            ))}
          </RowBody>
        ))}
        {rowBodyChildren && rowBodyChildren}
      </TableBody>
    </TableWrapper>
  )
}
