import {store} from '@/library/store'

export const selectError = () => ({
  get error() {
    return store().emailSubscription.error
  },
})

export const selectIsSuccess = () => ({
  get isSuccess() {
    return store().emailSubscription.isSuccess
  },
})

export const selectIsLoading = () => ({
  get isLoading() {
    return store().emailSubscription.isLoading
  },
})

export const selectIsOpen = () => ({
  get isOpen() {
    return store().emailSubscription.isOpen
  },
})

export const selectIsSubscribed = () => ({
  get isSubscribed() {
    return store().emailSubscription.isSubscribed
  },
})

export const selectIsAuthorized = () => ({
  get isAuthorized() {
    return store().user.isAuthorized
  },
})
