import styled from '@emotion/styled'
import {Button} from '../../button'

export const SecondaryClose = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: -12px;
  right: -12px;
  width: 24px;
  height: 24px;
  padding: 0;

  svg {
    color: ${({theme}) => theme.palette.textQuaternary};
    width: 16px;
    height: 16px;
  }
`
