import {SearchQueryParserService} from '@services/common/search-query-parser'
import type {ParsedUrlQuery} from 'node:querystring'
import {store} from '@store/index'
import {services} from '@services/index'
import {ProviderRatesSearchingTypeEnum} from '@api/gql/generate-types'

export class GlobalPageSearchUrlQueryParser extends SearchQueryParserService {
  public parse(query: ParsedUrlQuery): void {
    const {
      dateFrom,
      dateTo,
      adults,
      kidsAge,
      searchingType,
      hotelTypes,
      ratingFrom,
      priceFrom,
      priceTo,
      page,
      stars,
    } = query
    // Здесь в мобх передаются данные из квери параметров
    const parsedAdults = this.parseAdults(adults)
    store().search.guests.adults.setAmount(parsedAdults)

    const parseDate = this.parseDate(dateFrom, dateTo)
    store().search.dateFrom.setValue(parseDate.dateFrom)
    store().search.dateTo.setValue(parseDate.dateTo)

    const parsedSearchingType = this.parseSearchingType(searchingType)
    store().pages.global.setSearchingType(parsedSearchingType)

    const isBestOffers = services.pages.global.scenario.isSearchTypeEqualValue(
      parsedSearchingType,
      ProviderRatesSearchingTypeEnum.BestOffers,
    )

    if (isBestOffers) {
      services.pages.global.scenario.handleSwitchToBestOffersSearchingType()
    }

    if (hotelTypes) {
      const parsedHotelTypes = this.parseHotelTypes(hotelTypes)

      store().pages.global.search.filters.setSelectHotelTypes(parsedHotelTypes)
    }

    const hotelTypeIdFromBackend = store().pages.global.info?.filters.type?.id
    if (!hotelTypes && hotelTypeIdFromBackend) {
      store().pages.global.search.filters.setSelectHotelTypes([
        hotelTypeIdFromBackend,
      ])
    }

    const parsedRatingFrom = this.parseRatingFrom(ratingFrom)

    if (parsedRatingFrom) {
      store().pages.global.search.filters.setSelectedRatingFrom(
        parsedRatingFrom,
      )
    }

    const {parsedPriceFrom, parsedPriceTo} = this.parsePrices(
      priceFrom,
      priceTo,
    )

    store().pages.global.search.filters.setPriceFrom(parsedPriceFrom)

    store().pages.global.search.filters.setPriceTo(parsedPriceTo)

    const children = this.parseKidsAge(kidsAge)

    store().search.guests.children.setChildren(children)

    const parsedPage = this.parsePage(page)

    store().pages.global.search.hotels.setPage(parsedPage)

    const parseHotelStart = this.parseHotelStars(stars)
    store().pages.global.search.filters.setSelectStars(parseHotelStart)
  }
}
