import {InternalErrorPageContent} from '@/slices/internal-error'
import React, {Component, type PropsWithChildren} from 'react'
import {errorService} from '@services/common/error'

type ErrorBoundaryProps = PropsWithChildren

interface ErrorBoundaryState {
  hasError: boolean
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(properties: ErrorBoundaryProps) {
    super(properties)
    this.state = {hasError: false}
  }

  static getDerivedStateFromError(error: Error) {
    console.error(error)
    return {hasError: true, error}
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    errorService.sendSentryMessage(error.message)
    console.error(error, errorInfo)
  }

  render() {
    const {hasError} = this.state
    const {children} = this.props
    if (hasError) {
      return <InternalErrorPageContent />
    }

    return children
  }
}

export default ErrorBoundary
