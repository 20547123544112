import {useEffect, useState} from 'react'
import {isUndefined} from '../utils/guards/types'

export const useIsMounted = (data?: unknown) => {
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    if (data || isUndefined(data)) {
      setIsMounted(true)
    }
  }, [data])

  return isMounted
}
