import {type FC, memo, useMemo} from 'react'
import styled from '@emotion/styled'
import {
  Carousel,
  CarouselContent,
  CarouselDots,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '@ui/carousel'
import {DISABLED_STYLES, TRANSITION_STYLES} from '@constants/css'
import {Icon} from '@ui/icon'
import {Link, Text} from '@components/ui'
import {Tooltip} from '@ui/tooltip'
import useTranslation from 'next-translate/useTranslation'
import {ECurrency} from '@/types/enums/currency'
import {
  BEST_OFFER_SEARCH_PRIORY_TYPES,
  HOTEL_RATING_FRACTION_DIGITS,
  NO_HOTEL_IMAGE_SOURCE,
} from '@constants/hotel'
import {getNightsAmount} from '@utils/date/get-nights-amount'
import {store} from '@store/index'
import {MEDIA_DEVICE_QUERY} from '@assets/theme/media'
import {isNumber} from '@utils/guards/types'
import {getCenterFromBounds} from '@utils/map/get-center-from-bounds'
import {ImageWithSkeleton} from '@/library/components/ui/image-with-skeleton'
import {ButtonOnMap, FavoriteHeart} from '@components/features'
import {services} from '@services/index'
import {useDeviceDetection} from '@/library/hooks/use-device-detection'
import {css, useTheme} from '@emotion/react'
import {HOTEL_SCENARIO_REVIEWS_ID} from '@constants/dom-ids'
import {getMapBoundsFromCoords} from '@/library/utils/map/get-bounds-from-coords'
import {HotelCardSeo} from './hotel-card-seo'
import HotelFacility from './hotel-facility'

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 320px;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 2px 4px 1px ${({theme}) => theme.palette.boxShadowPrimary};
  border: 1px solid ${({theme}) => theme.palette.borderQuinary};
  transition: border ${TRANSITION_STYLES};
  margin-bottom: 20px;
  background-color: ${({theme}) => theme.palette.backgroundQuaternary};

  &:last-of-type {
    margin-bottom: 0px;
  }

  @media ${MEDIA_DEVICE_QUERY.LAPTOP_LARGE} {
    margin-bottom: 0;
    height: auto;
    flex-direction: column;
  }

  @media ${MEDIA_DEVICE_QUERY.MOBILE_VERY_LARGE} {
    padding: 16px;
  }
`

const CarouselWrapper = styled.div`
  min-width: 280px;
  max-height: 280px;
  height: 100%;

  @media ${MEDIA_DEVICE_QUERY.LAPTOP_LARGE} {
    height: 300px;
  }

  @media ${MEDIA_DEVICE_QUERY.MOBILE_VERY_LARGE} {
    min-width: 250px;
    height: 160px;
  }
`

const StyledImageWithSkeleton = styled(ImageWithSkeleton)`
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;

  .image {
    object-fit: cover;
  }
`

const StyledCarouselContainer = styled(CarouselContent)`
  border-radius: 12px;
  cursor: pointer;
`

const ContentWrapper = styled.div`
  padding-left: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 10px;
  overflow: hidden;

  @media ${MEDIA_DEVICE_QUERY.LAPTOP_LARGE} {
    overflow: unset;
    padding: 10px;
  }

  @media ${MEDIA_DEVICE_QUERY.MOBILE_VERY_LARGE} {
    padding: 0;
  }
`

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: start;
  gap: 10px;

  @media ${MEDIA_DEVICE_QUERY.LAPTOP_LARGE} {
    margin-top: 5px;
  }

  @media ${MEDIA_DEVICE_QUERY.MOBILE_VERY_LARGE} {
    flex-direction: column-reverse;
  }
`

const HotelHeaderInformationWrapper = styled.div`
  width: 80%;

  @media ${MEDIA_DEVICE_QUERY.LAPTOP_LARGE} {
    width: 100%;
  }
`

const HotelNameWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 8px;
`

const HotelName = styled.div`
  width: fit-content;
  max-width: 310px;
  color: ${({theme}) => theme.palette.backgroundPrimary};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const HotelAddressWrapper = styled.div<{disabled?: boolean}>`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 8px;
  color: ${({theme}) => theme.palette.textAccentPrimary};
  cursor: pointer;
  width: 310px;

  svg {
    color: ${({theme}) => theme.palette.textAccentPrimary};
  }

  span {
    font-size: 14px;
    height: 100%;
    min-width: fit-content;
  }

  transition: opacity ${TRANSITION_STYLES};

  ${({disabled}) =>
    disabled &&
    css`
      ${DISABLED_STYLES}
    `} @media ${MEDIA_DEVICE_QUERY.LAPTOP_LARGE} {
    width: 100%;
  }
`

const HotelAddress = styled(Text)`
  color: ${({theme}) => theme.palette.textSecondary};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const StyledLink = styled(Link)`
  width: 30%;

  @media ${MEDIA_DEVICE_QUERY.MOBILE_VERY_LARGE} {
    width: 100%;
  }
`

const ReviewsWrapper = styled.div`
  display: flex;
  justify-content: end;
  align-items: start;
  width: 100%;
  gap: 8px;

  @media ${MEDIA_DEVICE_QUERY.MOBILE_VERY_LARGE} {
    width: 100%;
    justify-content: start;
    margin-top: 16px;
  }
`

const ReviewInformationWrapper = styled.div`
  height: 100%;
  width: fit-content;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 60px;
`

const ReviewAvgStatus = styled(Text)`
  color: ${({theme}) => theme.palette.textAccentPrimary};
  font-weight: 700;
  min-width: fit-content;
`

const ReviewsCount = styled.span`
  color: ${({theme}) => theme.palette.textPrimary};
  font-weight: 400;
  font-size: 14px;
`

const ReviewsAvgScoreWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background-color: ${({theme}) => theme.palette.backgroundAccentPrimary};
  color: ${({theme}) => theme.palette.textQuaternary};
  min-height: 40px;
  min-width: 40px;
`

const MainWrapper = styled.div`
  border-radius: 2px;
  height: 100%;
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  gap: 10px;

  color: ${({theme}) => theme.palette.textSecondary};

  @media ${MEDIA_DEVICE_QUERY.MOBILE_VERY_LARGE} {
    margin: 12px 0;
  }
`

const GuestWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 6px;
`

const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${MEDIA_DEVICE_QUERY.MOBILE_VERY_LARGE} {
    align-items: start;
    flex-direction: column;
    gap: 10px;
  }
`

const LeftFooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media ${MEDIA_DEVICE_QUERY.LAPTOP_LARGE} {
    gap: 5px;
  }

  @media ${MEDIA_DEVICE_QUERY.MOBILE_VERY_LARGE} {
    margin-bottom: 16px;
  }
`

const BestOfferWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  color: ${({theme}) => theme.palette.textAccentPrimary};

  .text {
    font-weight: 500;
    font-size: 16px;
  }
`

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  background-color: ${({theme}) => theme.palette.backgroundAccentPrimary};
  border-radius: 100%;

  .icon {
    color: ${({theme}) => theme.palette.textQuaternary};
  }
`

const Price = styled(Text)`
  display: flex;
  gap: 5px;
  color: ${({theme}) => theme.palette.textPrimary};
  font-weight: 400;

  .amount {
    font-size: 16px;
    font-weight: 600;
    color: ${({theme}) => theme.palette.textAccentPrimary};
  }
`

const ActionsWrapper = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media ${MEDIA_DEVICE_QUERY.MOBILE_VERY_LARGE} {
    width: 100%;
  }
`

const AmountPerNightWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  background-color: ${({theme}) => theme.palette.backgroundTertiarySecondary};
  padding: 8px 12px;
  border-radius: 12px;
  font-size: 16px;

  span {
    font-size: 16px;
    font-weight: 400;
  }

  .price {
    color: ${({theme}) => theme.palette.textAccentPrimary};
    font-weight: 700;
  }

  @media ${MEDIA_DEVICE_QUERY.MOBILE_VERY_LARGE} {
    width: 100%;
    text-align: center;
  }
`

const RedirectToHotelButton = styled(Link)`
  padding: 10px 20px;
  border-radius: 12px;
  color: ${({theme}) => theme.palette.textQuaternary};
  background-color: ${({theme}) => theme.palette.backgroundAccentPrimary};
  transition: background-color ${TRANSITION_STYLES};
  text-transform: uppercase;
  min-width: 210px;
  width: 100%;
  font-weight: 700;
  height: 48px;
  text-align: center;

  &:hover {
    background-color: ${({theme}) => theme.palette.backgroundAccentSecondary};
    color: ${({theme}) => theme.palette.textQuaternary};
  }

  &:active {
    background-color: ${({theme}) => theme.palette.backgroundAccentTertiary};
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${({theme}) => theme.palette.backgroundTertiary};
  }

  @media ${MEDIA_DEVICE_QUERY.MOBILE_VERY_LARGE} {
    width: 100%;
    text-align: center;
  }
`

const TooltipWrapper = styled.div`
  min-width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-family: ${({theme}) => theme.font.main};

  p {
    color: ${({theme}) => theme.palette.textQuaternary};
  }
`

const FacilitiesContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px 20px;
  cursor: default;
  margin-top: 15px;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    margin-top: 10px;
  }
`

const TooltipBestOfferContent = () => {
  const {t} = useTranslation('global.search')
  return (
    <TooltipWrapper>
      <Text size="boldS"> {t('hotels.cards.best_offer.tooltip.title')}</Text>
      <Text size="XS">{t('hotels.cards.best_offer.tooltip.subtitle')}</Text>
    </TooltipWrapper>
  )
}

interface Props {
  hotel: SearchScenarioHotel | SearchScenarioSeoHotel
  mapLoading: boolean
}

export const SearchScenarioHotelCard: FC<Props> = memo(
  ({hotel, mapLoading}) => {
    const {t} = useTranslation('global.search')
    const theme = useTheme()
    const isTouch = useDeviceDetection('touch')
    const {dateFrom, dateTo, guests} = store().search

    const images = useMemo(() => {
      return (
        hotel?.images || [
          {
            id: hotel.mainImage?.id,
            url: hotel.mainImage?.url,
          },
        ]
      )
    }, [hotel.images, hotel.mainImage])

    const initialSearchState = useMemo(
      () => ({
        dateFrom: dateFrom.value,
        dateTo: dateTo.value,
        adults: guests.adults.amount,
        kids: guests.children.children.length,
      }),
      [],
    )

    const handleClickAddress = () => {
      if (isTouch && mapLoading) {
        return
      }

      if (hotel.lng && hotel.lat) {
        const coordinates = [hotel.lat, hotel.lng]
        const bounds = getMapBoundsFromCoords(
          [coordinates, coordinates],
          0.0005,
        )

        const center = getCenterFromBounds(bounds)

        if (!isTouch) {
          store().pages.global.search.map.setBounds(bounds)
          store().pages.global.search.map.setCenter(center)
        }

        if (isTouch) {
          store().pages.global.search.map.setHotelBounds(bounds)
          store().pages.global.search.map.setHotelCenter(center)
          store().pages.global.search.map.setModalOpen(true)
        }
      }
    }

    if (!hotel.seoUrl?.url) {
      return null
    }

    const hotelLink = `${hotel.seoUrl.url}?${services.components.search.buildQueryParameters().toString()}`

    return (
      <Wrapper>
        <HotelCardSeo hotel={hotel} />
        <CarouselWrapper>
          <Carousel>
            <FavoriteHeart
              hotelId={hotel.id}
              parentIsFavorite={Boolean(hotel.is_favourite_hotel)}
            />
            <Link href={hotelLink} target="_blank">
              <StyledCarouselContainer>
                {images.map((image) => (
                  <CarouselItem key={image.id}>
                    <StyledImageWithSkeleton
                      src={image.url || NO_HOTEL_IMAGE_SOURCE}
                      alt={`carousel image ${image.id}`}
                      fill
                      priority
                    />
                  </CarouselItem>
                ))}
              </StyledCarouselContainer>
            </Link>
            <CarouselDots />
            <CarouselPrevious />
            <CarouselNext />
          </Carousel>
        </CarouselWrapper>
        <ContentWrapper>
          <HeaderWrapper>
            <HotelHeaderInformationWrapper>
              <HotelNameWrapper>
                <Tooltip trigger="hover" title={hotel.name}>
                  <Link href={hotelLink} target="_blank">
                    <HotelName>
                      {hotel.stars ? (
                        <span>
                          {hotel.stars}
                          <sup>
                            <Icon
                              name="star"
                              width={12}
                              height={11}
                              color={theme.palette.backgroundYellow}
                            />
                          </sup>
                        </span>
                      ) : null}{' '}
                      {hotel.name}
                    </HotelName>
                  </Link>
                </Tooltip>
              </HotelNameWrapper>
              {isTouch ? (
                <HotelAddressWrapper
                  onClick={handleClickAddress}
                  disabled={mapLoading}
                >
                  <Icon name="point" width={12} height={14} />
                  <HotelAddress size="XS">{hotel.address}</HotelAddress>
                  <ButtonOnMap>{t('hotels.cards.on_map')}</ButtonOnMap>
                </HotelAddressWrapper>
              ) : (
                <Tooltip trigger="hover" title={hotel.address}>
                  <HotelAddressWrapper onClick={handleClickAddress}>
                    <Icon name="point" width={12} height={14} />
                    <HotelAddress size="XS">{hotel.address}</HotelAddress>
                    <ButtonOnMap>{t('hotels.cards.on_map')}</ButtonOnMap>
                  </HotelAddressWrapper>
                </Tooltip>
              )}
            </HotelHeaderInformationWrapper>
            <StyledLink
              href={`${hotelLink}#${HOTEL_SCENARIO_REVIEWS_ID}`}
              target="_blank"
            >
              <ReviewsWrapper>
                {isNumber(hotel.rating) && (
                  <ReviewsAvgScoreWrapper>
                    <span>
                      {hotel.rating.toFixed(HOTEL_RATING_FRACTION_DIGITS)}
                    </span>
                  </ReviewsAvgScoreWrapper>
                )}
                <ReviewInformationWrapper>
                  {isNumber(hotel.rating) && (
                    <ReviewAvgStatus size="XS">
                      {t('hotels.cards.reviews.status', {
                        count: Number.parseInt(String(hotel.rating), 10),
                      })}
                    </ReviewAvgStatus>
                  )}
                  <ReviewsCount>
                    {t('hotels.cards.reviews.label', {
                      count: hotel.reviews_count || 0,
                    })}
                  </ReviewsCount>
                </ReviewInformationWrapper>
              </ReviewsWrapper>
            </StyledLink>
          </HeaderWrapper>
          <MainWrapper>
            <GuestWrapper>
              {initialSearchState.dateFrom && initialSearchState.dateTo && (
                <Text size="S">
                  {t('hotels.cards.main.night', {
                    count: getNightsAmount(
                      initialSearchState.dateFrom,
                      initialSearchState.dateTo,
                    ),
                  })}
                </Text>
              )}
              <Text size="S">
                {t('hotels.cards.main.adults', {
                  count: initialSearchState.adults,
                })}
              </Text>
              {initialSearchState.kids > 0 && (
                <Text size="S">
                  {t('hotels.cards.main.kids', {
                    count: initialSearchState.kids,
                  })}
                </Text>
              )}
            </GuestWrapper>
            <FacilitiesContent>
              {hotel.shortFacilities?.map((facility) =>
                isTouch ? (
                  <HotelFacility key={facility.id} facility={facility} />
                ) : (
                  <Tooltip
                    key={facility.id}
                    trigger="hover"
                    title={facility.name}
                  >
                    <HotelFacility facility={facility} />
                  </Tooltip>
                ),
              )}
            </FacilitiesContent>
          </MainWrapper>
          <FooterWrapper>
            <LeftFooterWrapper>
              {BEST_OFFER_SEARCH_PRIORY_TYPES.has(hotel.search_priority) && (
                <Tooltip
                  trigger="hover"
                  title={<TooltipBestOfferContent />}
                  overlayStyle={{maxWidth: 280}}
                >
                  <BestOfferWrapper>
                    <IconWrapper>
                      <Icon
                        className="icon"
                        name="acceptMark"
                        width={8}
                        height={8}
                      />
                    </IconWrapper>
                    <Text className="text" size="boldXS">
                      {t('hotels.cards.best_offer.label')}
                    </Text>
                  </BestOfferWrapper>
                </Tooltip>
              )}
              {Boolean(hotel.price) &&
                initialSearchState.dateFrom &&
                initialSearchState.dateTo && (
                  <Price size="S">
                    <span>{t('hotels.cards.price.amount_before')}</span>
                    <span className="amount">{`${Math.round(
                      hotel.price /
                        getNightsAmount(
                          initialSearchState.dateFrom,
                          initialSearchState.dateTo,
                        ),
                    )} ${ECurrency.RUB}`}</span>
                    <span>
                      {t('hotels.cards.price.amount_before_per_night')}
                    </span>
                  </Price>
                )}
            </LeftFooterWrapper>
            <ActionsWrapper>
              <RedirectToHotelButton href={hotelLink} target="_blank">
                {t('hotels.cards.redirect_to_hotel_button.label')}
              </RedirectToHotelButton>
              {initialSearchState.dateFrom && initialSearchState.dateTo && (
                <AmountPerNightWrapper>
                  <span>
                    {t('hotels.cards.price.about_night', {
                      count: getNightsAmount(
                        initialSearchState.dateFrom,
                        initialSearchState.dateTo,
                      ),
                    })}
                  </span>
                  <span className="price">
                    {`${hotel.price.toLocaleString()} ${ECurrency.RUB}`}
                  </span>
                </AmountPerNightWrapper>
              )}
            </ActionsWrapper>
          </FooterWrapper>
        </ContentWrapper>
      </Wrapper>
    )
  },
)

SearchScenarioHotelCard.displayName = 'SearchScenarioHotelCard'
