import {api} from '@/library/api'
import {store} from '@/library/store'
import {type AuthUser, RoleEnum} from '@api/gql/generate-types'

export class UserService {
  public hasPermission(user: AuthUser, role: RoleEnum): boolean {
    if (!user?.roles) {
      return false
    }

    return user.roles.some((existRole) => existRole?.name === role)
  }

  public async updateBalance() {
    const balance = await api.user.getBalance({})

    store().user.setBalance(Number(balance?.amount))
  }
}
