import {makeAutoObservable} from 'mobx'
import {DEFAULT_PAGE} from '@constants/common'

export class CProfileBalanceStore {
  constructor() {
    makeAutoObservable(this)
  }

  private _loading = false

  public get loading(): boolean {
    return this._loading
  }

  private _operations: UserBalanceOperations | null = null

  public get operations(): UserBalanceOperations | null {
    return this._operations
  }

  private _page: number = DEFAULT_PAGE

  public get page(): number {
    return this._page
  }

  private _paginatorInfo: UserBalanceOperationsPaginatorInfo | null = null

  public get paginatorInfo(): UserBalanceOperationsPaginatorInfo | null {
    return this._paginatorInfo
  }

  public setLoading(flag: boolean): void {
    this._loading = flag
  }

  public setOperations(operations: UserBalanceOperations | null): void {
    this._operations = operations
  }

  public setPage(page: number): void {
    this._page = page
  }

  public setPaginatorInfo(info: UserBalanceOperationsPaginatorInfo): void {
    this._paginatorInfo = info
  }
}
