import type {BookingStatusEnum} from '@/library/api/gql/generate-types'
import {makeAutoObservable} from 'mobx'

interface FormBookingData {
  id: number
  accessToken: string
  status: BookingStatusEnum
  mustPayBeforeFinish: boolean
}

export class CFormBooking {
  constructor() {
    makeAutoObservable(this)
  }

  private _id: number | null = null

  private _accessToken: string | null = null

  private _status: BookingStatusEnum | null = null

  private _mustPayBeforeFinish: boolean | null = null

  public get status() {
    return this._status
  }

  public setStatus(status: BookingStatusEnum | null) {
    this._status = status
  }

  public getStoreData() {
    return {
      id: this._id,
      accessToken: this._accessToken,
      status: this._status,
      mustPayBeforeFinish: this._mustPayBeforeFinish,
    }
  }

  public setStoreData(data: FormBookingData) {
    this._accessToken = data.accessToken
    this._id = data.id
    this._status = data.status
    this._mustPayBeforeFinish = data.mustPayBeforeFinish
  }

  public reset() {
    this._accessToken = null
    this._id = null
    this._status = null
    this._mustPayBeforeFinish = null
  }
}
