import {makeAutoObservable} from 'mobx'
import type {GetStaticPageByUrlQuery} from '@api/gql/generate-types'

type ContentData = GetStaticPageByUrlQuery['staticPageByUrl']

type PathList = string[] | null

export class CGlobalPageStoreStatic {
  constructor() {
    makeAutoObservable(this)
  }

  private _data: ContentData = null

  public get data(): ContentData {
    return this._data
  }

  private _pathList: PathList = null

  public get pathList(): PathList {
    return this._pathList
  }

  public setData(data: ContentData): void {
    this._data = data
  }

  public setPathList(pathList: PathList): void {
    this._pathList = pathList
  }
}
