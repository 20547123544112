import dynamic from 'next/dynamic'

export const SVG_ICONS = {
  accredited: dynamic(() => import('../assets/accredited.svg')),
  filters: dynamic(() => import('../assets/filters.svg')),
  check: dynamic(() => import('../assets/check.svg')),
  vk: dynamic(() => import('../assets/vk.svg')),
  telegram: dynamic(() => import('../assets/telegram.svg')),
  youtube: dynamic(() => import('../assets/youtube.svg')),
  apartments: dynamic(() => import('../assets/apartment.svg')),
  button_slider_left: dynamic(() => import('../assets/button_slider_left.svg')),
  button_slider_right: dynamic(
    () => import('../assets/button_slider_right.svg'),
  ),
  arrow_small: dynamic(() => import('../assets/arrow-small.svg')),
  arrow_long: dynamic(() => import('../assets/arrow-long.svg')),
  bell: dynamic(() => import('../assets/bell.svg')),
  burger: dynamic(() => import('../assets/burger.svg')),
  calendar: dynamic(() => import('../assets/calendar.svg')),
  detailed_calendar: dynamic(() => import('../assets/detailed-calendar.svg')),
  language: dynamic(() => import('../assets/language.svg')),
  like: dynamic(() => import('../assets/like.svg')),
  like_fill: dynamic(() => import('../assets/like-fill.svg')),
  like_stroke: dynamic(() => import('../assets/like-stroke.svg')),
  human: dynamic(() => import('../assets/human.svg')),
  search: dynamic(() => import('../assets/search.svg')),
  minus: dynamic(() => import('../assets/minus.svg')),
  plus: dynamic(() => import('../assets/plus.svg')),
  close: dynamic(() => import('../assets/close.svg')),
  mir: dynamic(() => import('../assets/mir.svg')),
  visa: dynamic(() => import('../assets/visa.svg')),
  mastercard: dynamic(() => import('../assets/mastercard.svg')),
  feedback: dynamic(() => import('../assets/feedback.svg')),
  knowledge_base: dynamic(() => import('../assets/knowledge_base.svg')),
  mail: dynamic(() => import('../assets/mail.svg')),
  phone: dynamic(() => import('../assets/phone.svg')),
  whats_app: dynamic(() => import('../assets/whats_app.svg')),
  on_map: dynamic(() => import('../assets/on-map.svg')),
  copy: dynamic(() => import('../assets/copy.svg')),
  eye: dynamic({
    loader: () => import('../assets/eye.svg'),
  }),
  eye_close: dynamic({
    loader: () => import('../assets/eye_close.svg'),
  }),
  profile: dynamic(() => import('../assets/profile.svg')),
  dots: dynamic(() => import('../assets/dots.svg')),
  exit: dynamic(() => import('../assets/exit.svg')),
  money: dynamic(() => import('../assets/money.svg')),
  review: dynamic(() => import('../assets/review.svg')),
  settings: dynamic(() => import('../assets/settings.svg')),
  apartment_dollar: dynamic(() => import('../assets/apartment-dollar.svg')),
  blog: dynamic(() => import('../assets/blog.svg')),
  help: dynamic(() => import('../assets/help.svg')),
  notify: dynamic(() => import('../assets/notify.svg')),
  acceptMark: dynamic(() => import('../assets/accept-mark.svg')),
  point: dynamic(() => import('../assets/point.svg')),

  star: dynamic(() => import('../assets/star.svg')),
  question: dynamic(() => import('../assets/question.svg')),

  sort: dynamic(() => import('../assets/sort.svg')),
  sad_cloud: dynamic(() => import('../assets/sad-cloud.svg')),

  bin: dynamic(() => import('../assets/bin.svg')),
  pencil: dynamic(() => import('../assets/pencil.svg')),
  statistics: dynamic(() => import('../assets/statistics.svg')),
  call_taxi: dynamic(() => import('../assets/call-taxi.svg')),
  download: dynamic(() => import('../assets/download.svg')),

  wallet: dynamic(() => import('../assets/wallet.svg')),
  stroke_star: dynamic(() => import('../assets/stroke_star.svg')),
  settings_profile: dynamic(() => import('../assets/settings_profile.svg')),
  open_lock: dynamic(() => import('../assets/open_lock.svg')),

  info: dynamic(() => import('../assets/info.svg')),
  info_2: dynamic(() => import('../assets/info_2.svg')),
  pets: dynamic(() => import('../assets/pets.svg')),
  checkin: dynamic(() => import('../assets/checkin.svg')),
  deposit: dynamic(() => import('../assets/deposite.svg')),
  deposit_2: dynamic(() => import('../assets/deposite_2.svg')),
  food: dynamic(() => import('../assets/food.svg')),
  free_cancellation: dynamic(() => import('../assets/free_cancellation.svg')),
  best_offer: dynamic(() => import('../assets/best_offer.svg')),
  foreign_citizen: dynamic(() => import('../assets/foreign_citizen.svg')),
  payment_method: dynamic(() => import('../assets/payment_method.svg')),
  taxes: dynamic(() => import('../assets/taxes.svg')),
  comfort: dynamic(() => import('../assets/comfort.svg')),
  family: dynamic(() => import('../assets/family.svg')),
  meal: dynamic(() => import('../assets/meal.svg')),
  security: dynamic(() => import('../assets/security.svg')),
  media: dynamic(() => import('../assets/media.svg')),
  outdoors: dynamic(() => import('../assets/outdoors.svg')),
  parking: dynamic(() => import('../assets/parking.svg')),
  calendar_promotion: dynamic(() => import('../assets/calendar_promotion.svg')),

  star_promotion: dynamic(() => import('../assets/star_promotion.svg')),
  fire_promotion: dynamic(() => import('../assets/fire_promotion.svg')),
  ticket_promotion: dynamic(() => import('../assets/ticket_promotion.svg')),

  hotel: dynamic(() => import('../assets/hotel.svg')),
  city: dynamic(() => import('../assets/city.svg')),
  promotion: dynamic(() => import('../assets/promotion.svg')),
  zhili_byli: dynamic(() => import('../assets/zhili-byli.svg')),
  dzen: dynamic(() => import('../assets/dzen.svg')),
  yandex: dynamic(() => import('../assets/yandex.svg')),
  otzovik: dynamic(() => import('../assets/otzovik.svg')),
  telegram_coloured: dynamic(() => import('../assets/telegram_coloured.svg')),
  vk_coloured: dynamic(() => import('../assets/vk_coloured.svg')),

  guide_owner: dynamic(() => import('../assets/guide-owner.svg')),
  guide_guest: dynamic(() => import('../assets/guide-guest.svg')),
  image: dynamic(() => import('../assets/image.svg')),

  sad_emoji: dynamic(() => import('../assets/sad-emoji.svg')),
  smile_emoji: dynamic(() => import('../assets/smile-emoji.svg')),
  resume: dynamic(() => import('../assets/resume.svg')),
  clocks: dynamic(() => import('../assets/clocks.svg')),
}
