import {css, Global, useTheme} from '@emotion/react'
import type {ApplicationCustomTheme} from '@assets/theme'
import {ENVIRONMENT} from '@utils/guards/environment'

const styles = (theme: ApplicationCustomTheme) => css`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  li {
    list-style-type: none;
  }

  html {
    font-family: ${theme.font.main};
    font-size: ${theme.textSize.medium};
    line-height: 25px;
    font-weight: 500;
  }

  &:root {
    --loading-route-color: ${ENVIRONMENT.HEADER_PROMO_IS_DISPLAY
      ? theme.palette.backgroundQuaternary
      : theme.palette.backgroundAccentPrimary};
  }

  .ant-ribbon-wrapper {
    font-family: ${theme.font.main} !important;
    font-size: 16px;
  }

  html,
  body {
    margin: 0;
    height: 100%;
  }

  #__next {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden !important;
  }

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${theme.palette.backgroundTertiary};
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: ${theme.palette.backgroundQuaternary};
  }

  a {
    text-decoration: inherit;
    color: inherit;
    cursor: auto;
  }

  .ymaps3x0--controls {
    z-index: 1 !important;
  }

  .visually-hidden {
    position: absolute;
    clip: rect(1px, 1px, 1px, 1px);
    -webkit-clip-path: inset(0px 0px 99.9% 99.9%);
    clip-path: inset(0px 0px 99.9% 99.9%);
    overflow: hidden;
    height: 1px;
    width: 1px;
    padding: 0;
  }

  .ymap-cluster-icon {
    display: flex;
    position: relative;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    color: ${theme.palette.textQuaternary};
    background: ${theme.palette.backgroundAccentPrimary};
    border-radius: 100%;
    width: 40px;
    height: 40px;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .ymap-cluster-icon::after {
    content: '';
    clear: both;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid ${theme.palette.borderAccentPrimary};
    position: absolute;
    top: 34px;
  }

  .b24-widget-button-position-bottom-right {
    right: 30px !important;
    bottom: 110px !important;
    z-index: 30 !important;
  }

  .b24-widget-button-shadow {
    z-index: 30 !important;
  }
`

export const GlobalStyles = () => {
  const theme = useTheme()

  return <Global styles={styles(theme)} />
}
