import {Checkbox} from '@/library/components/ui/checkbox'
import {services} from '@/library/services'
import {store} from '@/library/store'
import styled from '@emotion/styled'
import {observer} from 'mobx-react'
import type {FC} from 'react'
import {useIsRedirecting} from '@/library/hooks/use-redirecting'
import useTranslation from 'next-translate/useTranslation'
import {PromoCodeForm} from './promo-code-form'

const Wrapper = styled.div`
  .box {
    border-radius: 6px;
    min-width: 18px;
    min-height: 18px;
  }

  .label {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: ${({theme}) => theme.palette.textPrimary};
  }
`

interface PromoCodeProps {
  className?: string
  onlineAmount: number
  hotelId: number
  cityId: number
}

export const PromoCode: FC<PromoCodeProps> = observer(
  ({className, onlineAmount, hotelId, cityId}) => {
    const {t} = useTranslation('global.hotel')
    const {isLoading} = store().bookingModal.modal
    const isRedirecting = useIsRedirecting()
    const {isOpen} = store().bookingModal.promoCode
    return (
      <Wrapper className={className}>
        <Checkbox
          disabled={isLoading || isRedirecting}
          value={isOpen}
          setValue={(flag) => {
            services.pages.global.scenario.hotel.booking.promoCode.state.setIsOpen(
              flag,
            )
          }}
        >
          {t('booking_modal.i_have_promo_code')}
        </Checkbox>
        {isOpen && (
          <PromoCodeForm
            hotelId={hotelId}
            cityId={cityId}
            onlineAmount={onlineAmount}
          />
        )}
      </Wrapper>
    )
  },
)
