import dynamic from 'next/dynamic'

export const ILLUSTRATION_IMAGES = {
  give_key: dynamic(() => import('../assets/give_key.svg'), {
    ssr: false,
  }),
  search_money: dynamic(() => import('../assets/search_money.svg'), {
    ssr: false,
  }),
  verify: dynamic(() => import('../assets/verify.svg'), {
    ssr: false,
  }),
  full_time: dynamic(() => import('../assets/full_time.svg'), {
    ssr: false,
  }),
  app_store: dynamic(() => import('../assets/app_store.svg'), {
    ssr: false,
  }),
  app_gallery: dynamic(() => import('../assets/app_gallery.svg'), {
    ssr: false,
  }),
  get_apps: dynamic(() => import('../assets/get_apps.svg'), {
    ssr: false,
  }),
  google_play: dynamic(() => import('../assets/google_play.svg'), {
    ssr: false,
  }),
  password_reset_confirm_code: dynamic(
    () => import('../assets/password_reset_confirm_code.svg'),
    {
      ssr: false,
    },
  ),
  password_reset_email: dynamic(
    () => import('../assets/password_reset_email.svg'),
    {
      ssr: false,
    },
  ),
  password_reset_success: dynamic(
    () => import('../assets/password_reset_success.svg'),
    {
      ssr: false,
    },
  ),
  registration_success: dynamic(
    () => import('../assets/registration_success.svg'),
    {
      ssr: false,
    },
  ),
  map: dynamic(() => import('../assets/map.svg'), {
    ssr: false,
  }),
  internal_error: dynamic(() => import('../assets/internal-error.svg')),
  not_found: dynamic(() => import('../assets/not-found.svg')),
  planet: dynamic(() => import('../assets/planet.svg')),
  logo_large: dynamic(() => import('../assets/logo-with-text.svg'), {
    ssr: true,
  }),
  logo_small: dynamic(() => import('../assets/logo.svg'), {
    ssr: true,
  }),
  logo_partner: dynamic(() => import('../assets/logo-partner.svg'), {
    ssr: true,
  }),
  chain: dynamic(() => import('../assets/chain.svg'), {
    ssr: true,
  }),
  calendar: dynamic(() => import('../assets/calendar.svg'), {
    ssr: true,
  }),
  currency: dynamic(() => import('../assets/currency.svg'), {
    ssr: true,
  }),
  avatar: dynamic(() => import('../assets/avatar.svg'), {
    ssr: true,
  }),
  hotel_point: dynamic(() => import('../assets/hotel-point.svg'), {
    ssr: false,
  }),
  mail: dynamic(() => import('../assets/mail.svg'), {
    ssr: false,
  }),
  telegram: dynamic(() => import('../assets/telegram.svg'), {
    ssr: false,
  }),
  twitter: dynamic(() => import('../assets/twitter.svg'), {
    ssr: false,
  }),
  viber: dynamic(() => import('../assets/viber.svg'), {
    ssr: false,
  }),
  vk: dynamic(() => import('../assets/vk.svg'), {
    ssr: false,
  }),
  whatsapp: dynamic(() => import('../assets/whatsapp.svg'), {
    ssr: false,
  }),
  email_subscription: dynamic(
    () => import('../assets/email_subscription.svg'),
    {
      ssr: true,
    },
  ),
  subscription: dynamic(() => import('../assets/subscription.svg'), {
    ssr: false,
  }),
  subscription_success: dynamic(
    () => import('../assets/subscription_success.svg'),
    {
      ssr: false,
    },
  ),
  home_dash: dynamic(() => import('../assets/home-dash.svg'), {
    ssr: false,
  }),
  ios_share: dynamic(() => import('../assets/ios-share.svg'), {
    ssr: true,
  }),
  tab_home_ios: dynamic(() => import('../assets/tab-home-ios.svg'), {
    ssr: true,
  }),
  android: dynamic(() => import('../assets/android.svg'), {
    ssr: true,
  }),
  ios: dynamic(() => import('../assets/ios.svg'), {
    ssr: true,
  }),
  mobile_search: dynamic(() => import('../assets/mobile-search.svg'), {
    ssr: true,
  }),
  question_support: dynamic(() => import('../assets/question-support.svg'), {
    ssr: true,
  }),
  zhili_byli_ios: dynamic(() => import('../assets/zhili-byli-ios.svg'), {
    ssr: true,
  }),
  get_app: dynamic(() => import('../assets/get-app.svg'), {
    ssr: true,
  }),
  google_play_logo: dynamic(() => import('../assets/google-play-logo.svg'), {
    ssr: true,
  }),
  app_gallery_logo: dynamic(() => import('../assets/app-galery-logo.svg'), {
    ssr: true,
  }),
  freekassa: dynamic(() => import('../assets/freekassa.svg'), {
    ssr: true,
  }),
  system_fast_payment: dynamic(
    () => import('../assets/system-fast-payment.svg'),
    {
      ssr: true,
    },
  ),
  card_payment: dynamic(() => import('../assets/card-payment.svg'), {
    ssr: true,
  }),
  fkwallet: dynamic(() => import('../assets/fkwallet.svg'), {
    ssr: true,
  }),
  yandex_navigator: dynamic(() => import('../assets/yandex-navigator.svg'), {
    ssr: true,
  }),
  twogis_navigator: dynamic(() => import('../assets/twogis-navigator.svg'), {
    ssr: true,
  }),
  google_navigator: dynamic(() => import('../assets/google-navigator.svg'), {
    ssr: true,
  }),
  gosuslugi: dynamic(() => import('../assets/gosuslugi.svg'), {
    ssr: true,
  }),
  ostrovok_logo: dynamic(() => import('../assets/ostrovok_logo.svg'), {
    ssr: true,
  }),
  booking_logo: dynamic(() => import('../assets/booking_logo.svg'), {
    ssr: true,
  }),
  sutochno_logo: dynamic(() => import('../assets/sutochno_logo.svg'), {
    ssr: true,
  }),
  refresh: dynamic(() => import('../assets/refresh.svg'), {
    ssr: true,
  }),
  not_found_logo: dynamic(() => import('../assets/not_found_logo.svg'), {
    ssr: false,
  }),
}
