import {css} from '@emotion/react'
import styled from '@emotion/styled'
import {Skeleton} from 'antd'
import Image, {type ImageProps} from 'next/image'
import {type FC, useEffect, useRef, useState} from 'react'
import {NO_HOTEL_IMAGE_SOURCE} from '@constants/hotel'

const Wrapper = styled.div`
  height: 100%;
  position: relative;
`

const StyledImage = styled(Image, {
  shouldForwardProp: (properties) => properties !== '$imageLoaded',
})<{
  $imageLoaded: boolean
  width?: number
  height?: number
  fill?: boolean
}>`
  display: block;
  opacity: 0;
  visibility: hidden;
  width: 0;
  height: 0;
  object-fit: cover;

  ${({$imageLoaded, width, height, fill}) =>
    $imageLoaded &&
    css`
      visibility: visible;
      opacity: 1;
      width: ${fill ? '100%' : `${width}px`};
      height: ${fill ? '100%' : `${height}px`};
    `}
`

const StyledSkeletonImage = styled(Skeleton.Image)<{
  width?: number
  height?: number
  fill?: boolean
}>`
  min-width: ${({width, fill}) => (fill ? '100%' : `${width}px`)};
  min-height: ${({height, fill}) => (fill ? '100%' : `${height}px`)};
  width: ${({width, fill}) => (fill ? '100%' : `${width}px`)};
  height: ${({height, fill}) => (fill ? '100%' : `${height}px`)};
`

interface ImageWithSkeletonProps extends ImageProps {
  width?: number
  height?: number
  className?: string
  isIntersectionNeed?: boolean
}

export const ImageWithSkeleton: FC<ImageWithSkeletonProps> = ({
  width,
  height,
  className,
  fill,
  isIntersectionNeed = true,
  ...properties
}) => {
  const [imageLoaded, setImageLoaded] = useState(false)
  const [isIntersecting, setIsIntersecting] = useState(false)
  const imgRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!isIntersectionNeed) {
      setIsIntersecting(true)
      return
    }

    /**
     * Отслеживает находится ли изображение на экране, и если да - то отображает его
     * Сделано для оптимизации загрузки изображений, что бы те изображения которых нет на экране пользователя - не грузит при первой загрузке
     */
    const element = imgRef.current

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsIntersecting((previous) => {
          if (previous) {
            return previous
          }

          return entry.isIntersecting
        })
      },
      {threshold: 0.1},
    )

    if (element) {
      observer.observe(element)
    }

    return () => {
      if (element) {
        observer.unobserve(element)
      }
    }
  }, [isIntersectionNeed])

  return (
    <Wrapper className={className} ref={imgRef}>
      {!imageLoaded && (
        <StyledSkeletonImage
          active
          width={width}
          height={height}
          fill={fill}
          className="skeleton"
        />
      )}
      {isIntersecting && (
        <StyledImage
          $imageLoaded={imageLoaded}
          width={width}
          height={height}
          fill={fill}
          onLoad={() => setImageLoaded(true)}
          className="image"
          onError={(event) => {
            // eslint-disable-next-line no-param-reassign
            event.currentTarget.srcset = NO_HOTEL_IMAGE_SOURCE
          }}
          {...properties}
        />
      )}
    </Wrapper>
  )
}
