import {makeAutoObservable} from 'mobx'
import {type ResetPasswordStoreInitialStateType} from '../types'
import {RESET_PASSWORD_STORE_INITIAL_STATE} from '../constants'

export class CResetPasswordStore {
  constructor() {
    makeAutoObservable(this)
  }

  // Шаг сброса пароля в модалке - почта/код/успех
  private _step: ResetPasswordStoreInitialStateType['step'] =
    RESET_PASSWORD_STORE_INITIAL_STATE.step

  public get step(): ResetPasswordStoreInitialStateType['step'] {
    return this._step
  }

  public setStep(step: ResetPasswordStoreInitialStateType['step']) {
    this._step = step
  }

  // Ошибка при проверке кода сброса пароля
  private _confirmationMessage: ResetPasswordStoreInitialStateType['confirmationMessage'] =
    RESET_PASSWORD_STORE_INITIAL_STATE.confirmationMessage

  public get confirmationMessage(): ResetPasswordStoreInitialStateType['confirmationMessage'] {
    return this._confirmationMessage
  }

  public setConfirmationMessage(
    confirmationMessage: ResetPasswordStoreInitialStateType['confirmationMessage'],
  ) {
    this._confirmationMessage = confirmationMessage
  }

  // Почта на которую сбрасывается пароль (нужно при переходе с 1 шага на 2)
  private _resetPasswordEmail: ResetPasswordStoreInitialStateType['resetPasswordEmail'] =
    RESET_PASSWORD_STORE_INITIAL_STATE.resetPasswordEmail

  public get resetPasswordEmail(): ResetPasswordStoreInitialStateType['resetPasswordEmail'] {
    return this._resetPasswordEmail
  }

  public setResetPasswordEmail(
    resetPasswordEmail: ResetPasswordStoreInitialStateType['resetPasswordEmail'],
  ) {
    this._resetPasswordEmail = resetPasswordEmail
  }

  // Текст ошибки
  private _error: ResetPasswordStoreInitialStateType['error'] =
    RESET_PASSWORD_STORE_INITIAL_STATE.error

  public get error(): ResetPasswordStoreInitialStateType['error'] {
    return this._error
  }

  public setError(error: ResetPasswordStoreInitialStateType['error']) {
    this._error = error
  }
}
