/* TODO - вынести в ЕНВ настройки, которые можно вынести */
export const EMAIL_USERNAME_MAX_LENGTH = 64
export const EMAIL_MAX_LENGTH = 254
export const BACKEND_MAX_STRING_LENGTH = 255
export const MIN_PASSWORD_LENGTH = 6
export const MAX_PASSWORD_LENGTH = 40
export const MIN_USERNAME_LENGTH = 2
export const MAX_USERNAME_LENGTH = 30
export const MAX_LASTNAME_LENGTH = 32
export const MAX_FIRSTNAME_LENGTH = 32
export const MIN_WITHDRAW_SUM = 1000
export const EMAIL_CODE_LENGTH = 6
export const ALL_PAYMENT_METHODS_MIN_AMOUNT = 10
export const MIN_AFFILIATE_COMPANY_NAME = 1
export const MINIMAL_PRICE = 0
export const ADMI_AD_MIN_UUID_LENGTH = 30
export const ADMI_AD_MAX_UUID_LENGTH = 100

export const cyrillicRule = /[ЁА-яё]/
export const namesRule = /^[A-Za-zЁА-яё]+$/
export const fkwalletAccountNumberRule = /^F\d{16}$/iu
export const noSpacesRule = /^\S*$/
export const numbersRule = /\d/
export const onlyIntegers = /^[1-9]\d*$/
export const phoneWithPlus = /^\+\d+$/
