import type {FC, ReactNode} from 'react'
import useTranslation from 'next-translate/useTranslation'
import {useTheme} from '@emotion/react'
import {Badge as LibraryBadge} from 'antd'
import {BookingStatusEnum} from '@/library/api/gql/generate-types'
import styled from '@emotion/styled'
import {Badge} from '../badge'
import {buildBookingStatusVariants} from './lib/constants'

const StyledLibraryRibbone = styled(LibraryBadge.Ribbon)`
  .ant-ribbon-wrapper {
    font: ${({theme}) => theme.font.main};
    background-color: red;
  }
`

interface BookingStatusBadgeProps {
  status: BookingStatusEnum
  children?: ReactNode
  className?: string
  isRibbon?: boolean
}

// Ribbon - стикер обхватывающий контейнер, можно посмотреть на странице booking/[id]

export const BookingStatusBadge: FC<BookingStatusBadgeProps> = ({
  status,
  children,
  className,
  isRibbon = false,
}) => {
  const theme = useTheme()
  const {t} = useTranslation()
  const bookingBadgeVariants = buildBookingStatusVariants(theme)

  const {text, color} = bookingBadgeVariants[status]

  if (isRibbon) {
    return (
      <StyledLibraryRibbone
        placement="start"
        text={t(text)}
        color={color}
        className={className}
      >
        {children}
      </StyledLibraryRibbone>
    )
  }

  return <Badge text={t(text)} color={color} className={className} />
}
