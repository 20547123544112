import Script from 'next/script'
import {ENVIRONMENT} from '@utils/guards/environment'

export const MailCounterScript = () => {
  return (
    <>
      <Script
        id="mail-counter"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
              var _tmr = window._tmr || (window._tmr = []);
              _tmr.push({id: "${ENVIRONMENT.MAIL_COUNTER_TMR_ID}", type: "pageView", start: (new Date()).getTime()});
              (function (d, w, id) {
                if (d.getElementById(id)) return;
                var ts = d.createElement("script");
                ts.type = "text/javascript"; ts.async = true;
                ts.id = id;
                ts.src = "${ENVIRONMENT.MAIL_COUNTER_CODE_LINK}";
                var f = function () {
                  var s = d.getElementsByTagName("script")[0];
                  s.parentNode.insertBefore(ts, s);
                };
                if (w.opera == "[object Opera]") {
                  d.addEventListener("DOMContentLoaded", f, false); 
                } else {
                 f();
                }
              })(document, window, "tmr-code");
            `,
        }}
      />
      <noscript>
        <div>
          <img
            src={ENVIRONMENT.MAIL_COUNTER_PIXEL_LINK}
            style={{position: 'absolute', left: '-9999px'}}
            alt="Top.Mail.Ru"
          />
        </div>
      </noscript>
    </>
  )
}
