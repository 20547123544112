import {isTruthy} from '@/library/utils/guards/types'
import {COMMON_ERROR_TEXT} from '@services/common/error/lib/constants'
import {captureException, captureMessage} from '@sentry/nextjs'
import type {Error, ParsedError} from './lib/types'

export const errorService = {
  parseError: (error: Error): ParsedError => {
    if (!error || !Array.isArray(error.graphQLErrors)) {
      return {
        messages: ['yup:went_wrong'],
      }
    }

    const {extensions, message} = error.graphQLErrors[0]
    if (extensions.category === 'internal') {
      return {
        messages: ['yup:went_wrong'],
      }
    }
    if (extensions.category === 'validation' && extensions.validation) {
      const validationEntries = Object.entries(extensions.validation).map(
        ([field, error]) =>
          Array.isArray(error) ? [field, error[0]] : [field, error],
      )
      const messages = validationEntries.map((entry) => entry[1])
      const entries = [...validationEntries, ['messages', messages.flat()]]
      return Object.fromEntries(entries)
    }
    return {
      messages: [message],
    }
  },
  getFirstError: (error: Error) => {
    return error.graphQLErrors?.[0]
  },
  getFirstErrorText: (error: Error) => {
    const parsedError = errorService.parseError(error)
    return parsedError?.messages[0] || COMMON_ERROR_TEXT.WENT_WRONG
  },
  parseValidationError: (error: Error) => {
    return (
      error.graphQLErrors?.find((graphqlError) =>
        isTruthy(graphqlError.extensions.validation),
      )?.extensions.validation || null
    )
  },
  throwError: (message: string) => {
    throw new Error(message)
  },
  throwSentryError: (message: string) => {
    captureException(message)
    console.error(message)
  },
  throwSentryObjectError: (
    object?: object | null | unknown,
    message?: string,
  ) => {
    const stringifiedObject = JSON.stringify(object, null, 2)
    captureException(
      message ? `${message}: ${stringifiedObject}` : stringifiedObject,
    )
    console.error(object, message)
  },
  sendSentryMessage: (message: string) => {
    captureMessage(message)
  },
}
