import styled from '@emotion/styled'
import type {FC, HTMLAttributes} from 'react'
import {MEDIA_DEVICE_QUERY} from '@assets/theme/media'
import {observer, useLocalObservable} from 'mobx-react'
import {store} from '@store/index'
import {CircularLoader} from '@ui/loader'
import {services} from '@/library/services'
import {ProviderRatesSearchingTypeEnum} from '@/library/api/gql/generate-types'
import {SearchInputDestinationHotels} from './hotels'
import {SearchInputDestinationCities} from './cities'
import {SearchInputDestinationHistory} from './history'
import {SearchInputDestinationPromotionCities} from './promotion-cities'
import {selectors} from '../../../../store'

const Wrapper = styled.div`
  position: absolute;
  width: 35%;
  max-height: 315px;
  overflow: auto;
  border: 1px solid ${({theme}) => theme.palette.borderTertiary};
  border-radius: 10px;
  background-color: ${({theme}) => theme.palette.backgroundQuaternary};
  z-index: 9;
  margin-top: 12px;
  box-shadow: 0px 4px 6px 0px ${({theme}) => theme.palette.boxShadowPrimary};
  opacity: 0;
  animation: popover 0.5s forwards;
  padding: 19px 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media ${MEDIA_DEVICE_QUERY.LAPTOP} {
    max-height: 320px;
  }
`

const LoaderWrapper = styled(Wrapper)`
  display: flex;
  justify-content: center;
  align-items: center;
`

const ErrorWrapper = styled(Wrapper)`
  display: flex;
  justify-content: center;
  align-items: center;
`

type Props = HTMLAttributes<HTMLDivElement>

export const SearchInputDestinationPopover: FC<Props> = observer(
  ({...properties}) => {
    const {data, isLoading, error} = store().search.destination
    const {searchingType} = useLocalObservable(selectors.selectSearchingType)
    const isPromotion = services.pages.global.scenario.isSearchTypeEqualValue(
      searchingType,
      ProviderRatesSearchingTypeEnum.Promotion,
    )

    if (isLoading) {
      return (
        <LoaderWrapper {...properties}>
          <CircularLoader />
        </LoaderWrapper>
      )
    }

    if (error) {
      return (
        <ErrorWrapper>
          <p>{error}</p>
        </ErrorWrapper>
      )
    }

    if (!data) {
      return null
    }

    if (isPromotion) {
      return (
        <Wrapper {...properties}>
          <SearchInputDestinationPromotionCities />
        </Wrapper>
      )
    }

    return (
      <Wrapper {...properties}>
        <SearchInputDestinationCities />
        <SearchInputDestinationHotels />
        <SearchInputDestinationHistory />
      </Wrapper>
    )
  },
)
