import {Modal} from '@ui/modal'
import {observer} from 'mobx-react'
import styled from '@emotion/styled'
import {Illustration} from '@ui/illustration'
import {Icon} from '@ui/icon'
import {Button, Link, Text} from '@components/ui'
import {TRANSITION_STYLES} from '@constants/css'
import {services} from '@services/index'
import {useDeviceDetection} from '@hooks/use-device-detection'
import {useEffect, useRef, useState} from 'react'
import {copyService} from '@services/common/copy'
import {store} from '@store/index'
import {Tooltip} from '@ui/tooltip'
import {COPY_LINK_TOOLTIP_LIFETIME} from '@constants/common'
import {MEDIA_DEVICE_QUERY} from '@assets/theme/media'
import useTranslation from 'next-translate/useTranslation'
import {useLocalObservable} from 'mobx-react-lite'
import {ENVIRONMENT} from '@utils/guards/environment'

const Wrapper = styled.div``

const Title = styled(Text)`
  font-family: ${({theme}) => theme.font.main};
  font-weight: 500;
  margin-bottom: 10px;
`

const ListWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;

  @media ${MEDIA_DEVICE_QUERY.MOBILE_LARGE} {
    grid-template-columns: 1fr;
  }
`

const LinkCopyButton = styled(Button)`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 16px;
  border-bottom: 1px solid ${({theme}) => theme.palette.borderTertiary};
  padding: 10px 0;

  svg {
    color: ${({theme}) => theme.palette.textAccentPrimary};
  }

  p {
    transition: color ${TRANSITION_STYLES};
  }

  &:hover {
    p {
      color: ${({theme}) => theme.palette.textAccentPrimary};
    }
  }
`

const StyledLink = styled(Link)`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 16px;
  border-bottom: 1px solid ${({theme}) => theme.palette.borderTertiary};
  padding: 10px 0;

  p {
    transition: color ${TRANSITION_STYLES};
  }

  &:hover {
    p {
      color: ${({theme}) => theme.palette.textAccentPrimary};
    }
  }
`

const LinkIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const LinkTextWrapper = styled(Text)`
  font-family: ${({theme}) => theme.font.main};
  font-weight: 500;
`

const selectShareModal = () => ({
  get isOpenShareModal() {
    return store().pages.global.hotel.isOpenShareModal
  },
})

export const HotelScenarioMainInformationShare = observer(() => {
  const isTouch = useDeviceDetection('touch')
  const isMobile = useDeviceDetection('mobile')

  const textSize = isMobile ? 'XS' : 'S'

  const {share} = services.common
  const {isOpenShareModal} = useLocalObservable(selectShareModal)
  const {t} = useTranslation('global.hotel')
  const [url, setURL] = useState('')

  const timeoutRef = useRef<NodeJS.Timeout | null>(null)
  const [showTooltip, setShowTooltip] = useState(false)

  const handleCopy = () => {
    copyService.copy(url).then(() => {
      setShowTooltip(true)

      timeoutRef.current = setTimeout(() => {
        setShowTooltip(false)
        timeoutRef.current = null
      }, COPY_LINK_TOOLTIP_LIFETIME)
    })
  }

  useEffect(() => {
    setURL(window.location.href)
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
        timeoutRef.current = null
      }
    }
  }, [])

  return (
    <Modal
      onCancel={() => store().pages.global.hotel.setIsOpenShareModal(false)}
      open={isOpenShareModal}
    >
      <Wrapper>
        <Title size="M">{t('share_modal.title')}</Title>
        <ListWrapper>
          <Tooltip
            open={showTooltip}
            title={t('share_modal.copy.tooltip.title')}
          >
            <LinkCopyButton variant="transparent" onClick={handleCopy}>
              <LinkIconWrapper>
                <Icon name="copy" width={32} height={32} />
              </LinkIconWrapper>
              <LinkTextWrapper size={textSize}>
                {t('share_modal.copy.label')}
              </LinkTextWrapper>
            </LinkCopyButton>
          </Tooltip>
          <StyledLink
            href={share.getEmail(ENVIRONMENT.ZHILIBYLI_EMAIL, url)}
            target="_blank"
          >
            <LinkIconWrapper>
              <Illustration name="mail" width={32} height={32} />
            </LinkIconWrapper>
            <LinkTextWrapper size={textSize}>
              {t('share_modal.mail.label')}
            </LinkTextWrapper>
          </StyledLink>
          <StyledLink href={share.getWhatsApp(url)} target="_blank">
            <LinkIconWrapper>
              <Illustration name="whatsapp" width={32} height={32} />
            </LinkIconWrapper>
            <LinkTextWrapper size={textSize}>
              {t('share_modal.whatsapp.label')}
            </LinkTextWrapper>
          </StyledLink>
          <StyledLink href={share.getTelegram(url)} target="_blank">
            <LinkIconWrapper>
              <Illustration name="telegram" width={32} height={32} />
            </LinkIconWrapper>
            <LinkTextWrapper size={textSize}>
              {t('share_modal.telegram.label')}
            </LinkTextWrapper>
          </StyledLink>
          <StyledLink href={share.getTwitter(url, '')} target="_blank">
            <LinkIconWrapper>
              <Illustration name="twitter" width={32} height={32} />
            </LinkIconWrapper>
            <LinkTextWrapper size={textSize}>
              {t('share_modal.twitter.label')}
            </LinkTextWrapper>
          </StyledLink>
          <StyledLink href={share.getVK(url, isTouch)} target="_blank">
            <LinkIconWrapper>
              <Illustration name="vk" width={32} height={32} />
            </LinkIconWrapper>
            <LinkTextWrapper size={textSize}>
              {t('share_modal.vk.label')}
            </LinkTextWrapper>
          </StyledLink>
          <StyledLink href={share.getViber(url)} target="_blank">
            <LinkIconWrapper>
              <Illustration name="viber" width={32} height={32} />
            </LinkIconWrapper>
            <LinkTextWrapper size={textSize}>
              {t('share_modal.viber.label')}
            </LinkTextWrapper>
          </StyledLink>
        </ListWrapper>
      </Wrapper>
    </Modal>
  )
})
