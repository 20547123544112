import styled from '@emotion/styled'
import {DISABLED_STYLES} from '@/library/constants/css'
import {BaseButton} from './button.base'

export const SecondaryButton = styled(BaseButton)`
  color: ${({theme}) => theme.palette.textSecondary};
  background-color: ${({theme}) => theme.palette.backgroundQuaternary};

  border: 1px solid ${({theme}) => theme.palette.borderTertiary};

  box-shadow: 0px 4px 6px 0px ${({theme}) => theme.palette.boxShadowPrimary};

  &:hover {
    border-color: ${({theme}) => theme.palette.borderSecondary};
  }

  &:disabled {
    ${DISABLED_STYLES}
  }
`
