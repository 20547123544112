import type {FieldError} from 'react-hook-form'
import {store} from '@store/index'
import type {FC} from 'react'
import {observer} from 'mobx-react'
import useTranslation from 'next-translate/useTranslation'
import {useLocalObservable} from 'mobx-react-lite'
import {Input} from '@ui/input'

type Props = {
  error?: FieldError
}

const selectLastName = () => ({
  get lastName() {
    return store().pages.profile.settings.lastName
  },
})

export const LastNameInput: FC<Props> = observer(({error}) => {
  const {t} = useTranslation('profile.settings')
  const {lastName} = useLocalObservable(selectLastName)

  return (
    <Input
      label={t('inputs.lastname')}
      name="lastname"
      type="text"
      error={error}
      value={lastName || ''}
      onChange={({target}) => {
        store().pages.profile.settings.setLastName(target.value)
      }}
      placeholder={t('inputs.lastname')}
    />
  )
})
