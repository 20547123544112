import styled from '@emotion/styled'
import {type Option, Select} from '@ui/select'
import {RatePaymentTypeEnum} from '@api/gql/generate-types'
import {observer} from 'mobx-react'
import {store} from '@store/index'
import useTranslation from 'next-translate/useTranslation'

const Wrapper = styled.div`
  margin-bottom: 20px;
`

const StyledSelect = styled(Select)`
  min-width: max-content;
`

const DEFAULT_OPTIONS: Option<RatePaymentTypeEnum | null>[] = [
  {
    label: 'global.hotel:rates.filters.payment_types.all_variants',
    value: null,
  },
  {
    label: 'global.hotel:rates.filters.payment_types.pay_online',
    value: RatePaymentTypeEnum.PayBeforeCancellation,
  },
  {
    label: 'global.hotel:rates.filters.payment_types.pay_on_spot',
    value: RatePaymentTypeEnum.PayOnSpot,
  },
  {
    label: 'global.hotel:rates.filters.payment_types.pay_now',
    value: RatePaymentTypeEnum.PayNow,
  },
]

export const HotelScenarioRatesFiltersPaymentTypes = observer(() => {
  const {paymentTypes} = store().pages.global.hotel.rates.filters
  const {t} = useTranslation('global.hotel')
  return (
    <Wrapper>
      <StyledSelect
        label={t('rates.filters.payment_types.label')}
        value={paymentTypes || DEFAULT_OPTIONS[0]}
        options={DEFAULT_OPTIONS}
        setValue={(option) =>
          store().pages.global.hotel.rates.filters.setPaymentTypes(option)
        }
      >
        free-cancellation
      </StyledSelect>
    </Wrapper>
  )
})
