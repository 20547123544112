import styled from '@emotion/styled'
import {ChildrenChips} from './children.chips'
import {ChildrenSelect} from './children.select'

const ChildrenWrapper = styled.div``

export const SearchInputGuestsPopoverChildren = () => {
  return (
    <ChildrenWrapper>
      <ChildrenSelect />
      <ChildrenChips />
    </ChildrenWrapper>
  )
}
