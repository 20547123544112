import {
  testEmailLength,
  testNotCyrillic,
  testPhone,
  testValidName,
} from '@/library/utils/validation-rules'
import type {TestContext} from 'yup'
import type {Translate} from 'next-translate'
import {noSpacesRule} from '../validation'

// Common
export const TEST_NOT_CIRYLLIC = {
  name: 'cyrillic-test',
  test: testNotCyrillic,
  message: 'yup:incorrect_format',
}

export const TEST_VALID_NAME = {
  name: 'valid-name',
  test: testValidName,
  message: 'yup:incorrect_format',
}

export const TEST_PHONE = {
  name: 'phone',
  test: testPhone,
  message: 'yup:incorrect_format',
}

export const TEST_SPACES_ONLY_VALUES = {
  name: 'spaces-only values',
  test: (value?: string) => {
    return value === undefined || noSpacesRule.test(value)
  },
  message: 'yup:incorrect_format',
}

export const TEST_LENGTH = ({
  length,
}: {
  length: number
}): {key: string; values: {length: number}} => ({
  key: 'yup:length',
  values: {length},
})

export const TEST_MIN_LENGTH = ({
  min,
}: {
  min: number
}): {key: string; values: {count: number}} => ({
  key: 'yup:min',
  values: {count: min},
})

export const TEST_MAX_LENGTH = ({
  max,
}: {
  max: number
}): {key: string; values: {count: number}} => ({
  key: 'yup:max',
  values: {count: max},
})

// Email
export const TEST_EMAIL_LENGTH = {
  name: 'email-length-test',
  test: testEmailLength,
  message: 'yup:incorrect_format',
}

// Password
export const TEST_PASSWORD_REPEAT_EQUALS = {
  test: (value: string, context: TestContext) =>
    value === context.parent.password,
  message: 'yup:password_repeat',
}

export const maxValueTest = (
  value: string | null | undefined,
  maxValue: number,
) => {
  if (!value) return true
  return Number.parseFloat(value) <= maxValue
}

export const TEST_MAX_VALUE = (t: Translate, maxValue: number) => ({
  name: 'max-amount',
  message: t('yup:max_amount', {amount: maxValue}),
  test: (value?: string | null) => {
    if (!value) return true
    return Number.parseFloat(value) <= maxValue
  },
})
