import {makeAutoObservable} from 'mobx'
import {CFooterActionsPages} from '@store/footer-actions/pages'

export class CFooterActions {
  public readonly pages: CFooterActionsPages = new CFooterActionsPages()

  constructor() {
    makeAutoObservable(this)
  }
}
