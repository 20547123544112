import {type FC, type PropsWithChildren, useEffect} from 'react'
import {ENVIRONMENT} from '@utils/guards/environment'
import {setCookie} from '@utils/cookie'
import {useRouter} from 'next/router'
import {services} from '@services/index'

export const UtmProvider: FC<PropsWithChildren> = ({children}) => {
  const router = useRouter()

  const handleSaveUtmSource = () => {
    const query = new URLSearchParams(window.location.search)
    const utmSource = query.get(ENVIRONMENT.UTM_SOURCE_COOKIE_KEY)

    if (utmSource) {
      setCookie(ENVIRONMENT.UTM_SOURCE_COOKIE_KEY, utmSource)
    }
  }

  useEffect(() => {
    handleSaveUtmSource()
    services.common.admitAd.check()
  }, [router.query])

  return <>{children}</>
}
