import {makeAutoObservable} from 'mobx'
import type {RoomRate} from '@api/gql/generate-types'

export class CGlobalPageStoreHotelRatesFacilityModal {
  constructor() {
    makeAutoObservable(this)
  }

  private _isOpen = false

  public get isOpen(): boolean {
    return this._isOpen
  }

  public setIsOpen(flag: boolean): void {
    this._isOpen = flag
  }

  private _selectFacility: RoomRate['facilities'] = null

  public get selectFacility(): RoomRate['facilities'] {
    return this._selectFacility
  }

  public setSelectFacility(facility: RoomRate['facilities']): void {
    this._selectFacility = facility
  }
}
