import styled from '@emotion/styled'
import {useRouter} from 'next/router'
import {useEffect} from 'react'
import {store} from '@/library/store'
import {useTheme, css} from '@emotion/react'
import {observer, useLocalObservable} from 'mobx-react-lite'
import useTranslation from 'next-translate/useTranslation'
import {Text} from '@/library/components/ui'
import {Skeleton} from 'antd'
import {Icon} from '@/library/components/ui/icon'
import {selectFiltersOptions} from './lib/selectors'

const FilterTrigger = styled.div<{isFiltersCheck: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  position: fixed;
  right: 120px;
  bottom: 100px;
  padding: 10px;

  height: 42px;
  background-color: ${({theme}) => theme.palette.backgroundPrimary};
  z-index: 19;
  border-radius: 12px;
  cursor: pointer;

  ${({theme, isFiltersCheck}) =>
    isFiltersCheck &&
    css`
      &:before {
        position: absolute;
        content: '';
        top: -5px;
        right: 0;
        width: 10px;
        height: 10px;
        background-color: ${theme.palette.backgroundAccentPrimary};
        border-radius: 50%;
        border: 2px solid ${theme.palette.borderQuaternary};
      }
    `}
`

const FiltersSkeletonWrapper = styled.div`
  position: fixed;
  right: 120px;
  bottom: 100px;

  width: 100px !important;
  height: 42px !important;
  background-color: ${({theme}) => theme.palette.backgroundQuaternary};
  border-radius: 12px;
  overflow: hidden;
  z-index: 19;
`

const TriggerSkeleton = styled(Skeleton.Button)`
  height: 100% !important;
`

export const MobileFiltersTrigger = observer(() => {
  const router = useRouter()
  const theme = useTheme()
  const {t} = useTranslation('global.search')

  const {isApply, loading, hotelTypes} =
    useLocalObservable(selectFiltersOptions)

  useEffect(() => {
    const {stars, hotelTypes, ratingFrom} = router.query
    if (stars || hotelTypes || ratingFrom) {
      store().pages.global.search.filters.setIsApply(true)
    } else {
      store().pages.global.search.filters.setIsApply(false)
    }
  }, [router])

  if (!hotelTypes) {
    return null
  }

  return loading ? (
    <FiltersSkeletonWrapper>
      <TriggerSkeleton active block />
    </FiltersSkeletonWrapper>
  ) : (
    <FilterTrigger
      isFiltersCheck={isApply}
      onClick={() => store().pages.global.search.filters.setIsOpen(true)}
    >
      <Text size="boldS" color={theme.palette.textQuaternary}>
        {t('filters.title')}
      </Text>
      <Icon
        name="filters"
        width={18}
        height={18}
        color={theme.palette.textQuaternary}
      />
    </FilterTrigger>
  )
})
