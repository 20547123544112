import {useDeviceDetection} from '@hooks/use-device-detection'
import {Link, Text} from '@/library/components/ui'
import useTranslation from 'next-translate/useTranslation'
import {ArrowText} from '@/library/components/ui/arrow-text'
import {useWindowSize} from '@/library/hooks/use-window-size'
import {ENVIRONMENT} from '@/library/utils/guards/environment'
import {Routes} from '@/types/enums/routes'
import {
  LinkList,
  LinkListItem,
  LinkListTitle,
  LinkListWrapper,
} from '../link-list'
import {MOBILE_LAYOUT_BREAKPOINT} from '../../lib/constants'

export const HoteliersColumn = () => {
  const {t} = useTranslation('features.footer')
  const isMobile = useDeviceDetection('mobile')
  const windowSize = useWindowSize()

  if (isMobile || windowSize < MOBILE_LAYOUT_BREAKPOINT) {
    return (
      <ArrowText title={t('hoteliers')} size="boldS">
        <LinkListWrapper>
          <LinkList>
            <LinkListItem>
              <Link href={ENVIRONMENT.KNOWLEDGE_BASE}>
                <Text size="boldS"> {t('help_hoteliers')}</Text>
              </Link>
            </LinkListItem>
            <LinkListItem>
              <Link href={ENVIRONMENT.HOTELIERS_REGISTRATION}>
                <Text size="boldS"> {t('own_hotel_registration')}</Text>
              </Link>
            </LinkListItem>
            <LinkListItem>
              <Link href={ENVIRONMENT.HOTELIERS_AUTH}>
                <Text size="S"> {t('personal_account')}</Text>
              </Link>
            </LinkListItem>
            <LinkListItem>
              <Link href={ENVIRONMENT.VERIFICATION_ADVANTAGES}>
                <Text size="S"> {t('verification_advantages')}</Text>
              </Link>
            </LinkListItem>
          </LinkList>
        </LinkListWrapper>
      </ArrowText>
    )
  }

  return (
    <LinkListWrapper>
      <LinkListTitle size="boldM">{t('hoteliers')}</LinkListTitle>
      <LinkList>
        <LinkListItem>
          <Link href={ENVIRONMENT.KNOWLEDGE_BASE}>
            <Text size="boldS"> {t('help_hoteliers')}</Text>
          </Link>
        </LinkListItem>
        <LinkListItem>
          <Link href={ENVIRONMENT.HOTELIERS_REGISTRATION}>
            <Text size="boldS"> {t('own_hotel_registration')}</Text>
          </Link>
        </LinkListItem>
        <LinkListItem>
          <Link href={ENVIRONMENT.HOTELIERS_AUTH}>
            <Text size="S"> {t('personal_account')}</Text>
          </Link>
        </LinkListItem>
        <LinkListItem>
          <Link href={ENVIRONMENT.VERIFICATION_ADVANTAGES}>
            <Text size="S"> {t('verification_advantages')}</Text>
          </Link>
        </LinkListItem>
        <LinkListItem>
          <Link href={Routes.OFFER}>
            <Text size="S"> {t('offer')}</Text>
          </Link>
        </LinkListItem>
      </LinkList>
    </LinkListWrapper>
  )
}
