import {DEFAULT_FIRST_PAGE_ITEMS, DEFAULT_PAGE} from '@constants/common'
import {makeAutoObservable} from 'mobx'
import {
  RateSearchFieldEnum,
  type RateSearchSorting,
  SortDirectionEnum,
} from '@api/gql/generate-types'

const initialState = {
  sort: {
    direction: SortDirectionEnum.Desc,
    field: RateSearchFieldEnum.Rating,
  },
}

type Hotels = SearchScenarioHotels | null

type HotelsPaginatorInfo = SearchScenarioHotelsPaginator | null

export class CGlobalPageStoreSearchHotels {
  constructor() {
    makeAutoObservable(this)
  }

  private _sort: RateSearchSorting = initialState.sort

  public get sort(): RateSearchSorting {
    return this._sort
  }

  private _page = DEFAULT_PAGE

  public get page(): number {
    return this._page
  }

  private _first = DEFAULT_FIRST_PAGE_ITEMS

  public get first(): number {
    return this._first
  }

  private _loading = false

  public get loading(): boolean {
    return this._loading
  }

  private _error = ''

  public get error(): string {
    return this._error
  }

  private _data: Hotels = null

  public get data(): Hotels {
    return this._data
  }

  private _isLoadedHotels = false

  get isLoadedHotels(): boolean {
    return this._isLoadedHotels
  }

  private _paginatorInfo: HotelsPaginatorInfo = null

  public get paginatorInfo(): HotelsPaginatorInfo {
    return this._paginatorInfo
  }

  public setSort(sort: RateSearchSorting): void {
    this._sort = sort
  }

  public setFirst(value: number): void {
    this._first = value
  }

  public setPage(value: number): void {
    this._page = value
  }

  public setLoading(loading: boolean): void {
    this._loading = loading
  }

  public setError(error: string): void {
    this._error = error
  }

  public setData(hotels: Hotels): void {
    this._data = hotels
  }

  public resetState() {
    this.setError('')
    this.setData(null)
    this.setLoading(false)
    this.setFirst(DEFAULT_FIRST_PAGE_ITEMS)
    this.setIsLoadedHotels(false)
    this.setPaginatorInfo(null)
  }

  public resetSort(): void {
    this.setSort(initialState.sort)
  }

  public setIsLoadedHotels(flag: boolean): void {
    this._isLoadedHotels = flag
  }

  private _isShowSeoRates = true

  get isShowSeoRates(): boolean {
    return this._isShowSeoRates
  }

  public setIsShowSeoRates(flag: boolean): void {
    this._isShowSeoRates = flag
  }

  public setPaginatorInfo(paginatorInfo: HotelsPaginatorInfo): void {
    this._paginatorInfo = paginatorInfo
  }
}
