import {Breadcrumbs} from '@components/ui'
import {Routes} from '@/types/enums/routes'
import {observer} from 'mobx-react'
import {useLocalObservable} from 'mobx-react-lite'
import {store} from '@store/index'
import {isNull} from '@utils/guards/types'
import type {ItemType} from 'antd/es/breadcrumb/Breadcrumb'
import {services} from '@services/index'
import {ProviderRatesSearchingTypeEnum} from '@api/gql/generate-types'
import useTranslation from 'next-translate/useTranslation'
import type {Translate} from 'next-translate'
import {BreadcrumbsSeo} from './seo'
import {BreadcrumbKeyEnum} from '../../lib/types'

const selectBreadcrumbs = (t: Translate) => ({
  get breadcrumb(): ItemType[] {
    const urlChains = store().pages.global.info?.urlsChain

    if (!urlChains) {
      return []
    }

    const {country, city, hotel, hotelType} = urlChains

    /**
     * Если нужно добавить ещё одну крошку, то из urlChains делаем по примеру country/city
     */
    const normalizeChains = [
      country
        ? {...country, prefix: 'prefix.country', key: BreadcrumbKeyEnum.COUNTRY}
        : null,
      city
        ? {...city, prefix: 'prefix.city', key: BreadcrumbKeyEnum.CITY}
        : null,
      hotelType
        ? {
            ...hotelType,
            prefix: 'prefix.hotel_type',
            key: BreadcrumbKeyEnum.TYPE,
          }
        : null,
      hotel
        ? {...hotel, prefix: 'prefix.hotel', key: BreadcrumbKeyEnum.HOTEL}
        : null,
    ]

    const filteredChains = normalizeChains.filter((chain) => {
      const hasExistRequiredValues =
        !isNull(chain) && chain.url && chain.id && chain.name

      const isRemoveHotelType = !(
        services.pages.global.scenario.isHotelScenario() &&
        chain?.key === BreadcrumbKeyEnum.TYPE
      )

      return hasExistRequiredValues && isRemoveHotelType
    }) as NonNullable<(typeof normalizeChains)[number]>[]

    return filteredChains.map((chain) => ({
      href: chain.url,
      title: `${t(chain.prefix)} ${chain.nameForBreadcrumbs}`,
      breadcrumbName: chain.key,
      onClick: (event) => {
        event.stopPropagation()
        event.preventDefault()
        const name = chain?.name || ''
        const url = chain?.url || ''
        store().search.destination.setSelectPlace({
          url,
          name,
        })
        store().pages.global.setSearchingType(
          ProviderRatesSearchingTypeEnum.Geo,
        )
        store().search.destination.setInputValue(name)
        store().pages.global.search.resetFilters()
        services.components.search.handleSearch()
        services.common.analytics.pushBreadcrumbs()
      },
    })) as ItemType[]
  },
})

export const GlobalPageBreadcrumbs = observer(() => {
  const {t} = useTranslation('breadcrumbs')
  const {breadcrumb} = useLocalObservable(() => selectBreadcrumbs(t))

  return (
    <>
      <BreadcrumbsSeo breadcrumb={breadcrumb} />
      <Breadcrumbs
        items={[
          {
            href: Routes.HOME,
            title: t('home'),
          },
          ...breadcrumb,
        ]}
      />
    </>
  )
})
