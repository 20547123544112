import {makeAutoObservable} from 'mobx'

export class CPartnerStore {
  constructor() {
    makeAutoObservable(this)
  }

  public reset() {
    this.setIsLoading(false)
    this.setError('')
    this.setIsSuccessful(false)
  }

  private _isLoading = false

  public get isLoading(): boolean {
    return this._isLoading
  }

  private _isSuccessful = false

  public get isSuccessful(): boolean {
    return this._isSuccessful
  }

  private _error = ''

  public get error(): string {
    return this._error
  }

  public setIsLoading(flag: boolean): void {
    this._isLoading = flag
  }

  public setError(error: string): void {
    this._error = error
  }

  public setIsSuccessful(flag: boolean): void {
    this._isSuccessful = flag
  }
}
