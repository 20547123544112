import {type FC} from 'react'
import styled from '@emotion/styled'
import useTranslation from 'next-translate/useTranslation'
import {type CancellationPolicy} from '@/library/api/gql/generate-types'
import {format} from 'date-fns'
import {DateFormatPattern} from '@/types/enums/date-format-patterns'
import {parseBackendDateFormat} from '@utils/date/formatting'
import {Text} from '../../ui'
import {getPeriod} from './lib/helpers'
import {PriceWithCurrency} from '../../ui/typography'
import type {TextSize} from '../../ui/typography/text/variants/size'

const CancellationWrapper = styled.div``

const CancellationTitle = styled(Text)`
  color: ${({theme}) => theme.palette.textPrimary};
  margin-bottom: 8px;
`

const CancellationRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 6px;
  border-bottom: 1px solid ${({theme}) => theme.palette.borderTertiary};
`

const CancellationRowValue = styled(Text)<{
  isFree?: boolean
  isValue?: boolean
}>`
  white-space: nowrap;
  color: ${({theme, isFree}) =>
    isFree ? theme.palette.textPositive : theme.palette.textPrimary};
  margin-left: ${({isValue}) => (isValue ? '8px' : 'unset')};
`

const TimezoneText = styled(Text)`
  margin-top: 9px;
  color: ${({theme}) => theme.palette.textSecondary};
`

const CancellationInfo = styled(Text)`
  margin-bottom: 5px;
  color: ${({theme}) => theme.palette.textPositive};
`

interface CancellationPoliciesProps {
  cancellationPolicies?: CancellationPolicy | null
  shouldHaveHeading?: boolean
  currency?: string | null
  titleSize?: TextSize
  textSize?: TextSize
}

export const CancellationPolicies: FC<CancellationPoliciesProps> = ({
  cancellationPolicies,
  shouldHaveHeading = true,
  currency,
  titleSize,
  textSize,
  ...attributes
}) => {
  const {t} = useTranslation('feature.cancellation-policies')
  const isCancellable = cancellationPolicies?.freeCancellationBeforeTz !== null

  const size = textSize || 'S'
  return (
    <CancellationWrapper {...attributes}>
      <CancellationTitle size={titleSize || 'boldS'}>
        {t('title')}
      </CancellationTitle>
      {isCancellable ? (
        <>
          {isCancellable && shouldHaveHeading && (
            <CancellationRow>
              <CancellationRowValue size={size}>
                {t('cancellation')}
              </CancellationRowValue>
              <CancellationRowValue isValue size={size}>
                {t('fine')}
              </CancellationRowValue>
            </CancellationRow>
          )}
          {cancellationPolicies?.freeCancellationBeforeTz ? (
            <CancellationRow>
              <CancellationRowValue size={size}>
                {t('until', {
                  until: format(
                    parseBackendDateFormat(
                      cancellationPolicies.freeCancellationBeforeTz,
                    ),
                    DateFormatPattern.SHORT_YEAR_DATE,
                  ),
                })}
              </CancellationRowValue>
              <CancellationRowValue isValue isFree size={size}>
                {t('free')}
              </CancellationRowValue>
            </CancellationRow>
          ) : null}
          {cancellationPolicies?.penaltyPolicies &&
          cancellationPolicies?.penaltyPolicies?.length > 0
            ? cancellationPolicies.penaltyPolicies.map(
                (policy) =>
                  policy.amount !== 0 && (
                    <CancellationRow
                      key={`cancel-${policy.startAtTz}-${policy.endAtTz}`}
                    >
                      <CancellationRowValue size={size}>
                        {getPeriod(t, policy.startAtTz, policy.endAtTz)}
                      </CancellationRowValue>
                      <CancellationRowValue isValue size={size}>
                        <PriceWithCurrency
                          price={policy.amount}
                          currency={currency}
                        />
                      </CancellationRowValue>
                    </CancellationRow>
                  ),
              )
            : null}
          {cancellationPolicies?.timezone?.title ? (
            <TimezoneText size={size}>
              {t('timezone', {timezone: cancellationPolicies.timezone.title})}
            </TimezoneText>
          ) : null}
        </>
      ) : (
        <CancellationInfo size={size}>{t('non_refundable')}</CancellationInfo>
      )}
    </CancellationWrapper>
  )
}
