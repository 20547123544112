import {store} from '@store/index'
import {observer} from 'mobx-react'
import {useLocalObservable} from 'mobx-react-lite'
import {SexInput} from '@/library/components/features'

const selectSex = () => ({
  get sex() {
    return store().pages.profile.settings.sex
  },
})

export const SettingsSexInput = observer(() => {
  const {sex} = useLocalObservable(selectSex)
  return (
    <SexInput
      sex={sex}
      setSex={(sex) => store().pages.profile.settings.setSex(sex)}
    />
  )
})
