import {store} from '@/library/store'
import styled from '@emotion/styled'
import {observer, useLocalObservable} from 'mobx-react'
import {services} from '@/library/services'
import {Button, CircularLoader} from '@/library/components/ui'
import useTranslation from 'next-translate/useTranslation'
import {Booking} from './booking'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const LoadMoreWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`

const selectBookings = () => ({
  get bookings() {
    return store().pages.profile.bookings.bookings
  },
  get paginatorInfo() {
    return store().pages.profile.bookings.paginatorInfo
  },
  get loading() {
    return store().pages.profile.bookings.loading
  },
})

export const BookingsList = observer(() => {
  const {t} = useTranslation('profile.bookings')
  const {bookings, paginatorInfo, loading} = useLocalObservable(selectBookings)

  const isEmptyBookings =
    (!bookings || bookings.length === 0) && !paginatorInfo?.hasMorePages

  if (isEmptyBookings) {
    return <Wrapper>{t('no_bookings')}</Wrapper>
  }

  return (
    <Wrapper>
      {bookings?.map((booking) => (
        <Booking key={booking.id} booking={booking} />
      ))}
      {paginatorInfo?.hasMorePages && (
        <LoadMoreWrapper
          onClick={() => services.pages.profile.bookings.loadMore()}
        >
          <Button disabled={loading}>
            {loading ? <CircularLoader size={32} /> : t('show_more')}
          </Button>
        </LoadMoreWrapper>
      )}
    </Wrapper>
  )
})
