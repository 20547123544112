import type {GetServerSideProps} from 'next'
import type {GlobalPageProperties} from '@slices/global/lib/types'
import {getGlobalPagePropertiesBuilder} from '@slices/global/lib/scenarios'
import {redirectScenario} from '@utils/next'
import {Routes} from '@/types/enums/routes'
import {andThen, call, pipeWith} from 'ramda'
import {checkUser} from '@utils/api/user-authorization'
import type {ApplicationProperties} from '@/types/ui'
import {serverHeaders} from '@utils/http/headers'
import {api} from '@api/index'
import {userAgentFromString} from 'next/server'

export const getGlobalPageProperties: GetServerSideProps<
  GlobalPageProperties
> = async ({req, params, query, res}) => {
  const {host} = req.headers
  const headers = serverHeaders(req.headers, req.cookies)
  const pathname = `/${(params?.all as string[]).join('/')}`
  const domain = host?.split(':')[0] || ''

  try {
    const staticPagePathList = await api.staticPage.getPathList({
      headers,
    })

    if (staticPagePathList && staticPagePathList.includes(pathname)) {
      const staticPageContent = await api.staticPage.getContent({
        headers,
        variables: {
          url: pathname,
          domain,
        },
      })

      if (!staticPageContent) {
        return redirectScenario(Routes.NOT_FOUND)
      }

      return call(
        pipeWith(andThen, [
          () => checkUser(headers),
          async (properties: ApplicationProperties) => {
            return {
              props: {
                hydrationData: {
                  ...properties,
                  staticPageContent,
                  staticPagePathList,
                  query,
                },
              },
            }
          },
        ]),
      )
    }
  } catch (error) {
    console.error(error)
  }

  const {isBot} = userAgentFromString(req.headers['user-agent'])
  const {properties: dynamic, redirect} = await getGlobalPagePropertiesBuilder({
    headers,
    url: pathname,
    isBot,
  })

  if (redirect) {
    return {redirect}
  }

  if (!dynamic) {
    return redirectScenario(Routes.NOT_FOUND)
  }

  return call(
    pipeWith(andThen, [
      () => checkUser(headers),
      async (properties: ApplicationProperties) => {
        return {
          props: {
            hydrationData: {
              ...dynamic,
              ...properties,
              query,
            },
          },
        }
      },
    ]),
  )
}
