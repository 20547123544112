import {DateFormatPattern} from '@/types/enums/date-format-patterns'
import {format, formatISO, parse, parseISO} from 'date-fns'

export const getBackendDateFormat = (date?: Date) =>
  date ? format(date, DateFormatPattern.QUERY) : null

/**
 * Обязательно если получаете данные с бэка, прогонять черезе этот метод, потому что некоторые браузеры поддерживают только ISO 8601
 * @param dateString
 * @see https://conf.booking.beer/pages/viewpage.action?pageId=1638470
 */
export const parseBackendDateFormat = (dateString: string) => {
  const parseToISOString = formatISO(
    parse(dateString, 'yyyy-MM-dd HH:mm:ss', new Date()),
  )
  return parseISO(parseToISOString)
}

export const outputDateFormatter = (date: Date) =>
  format(date, DateFormatPattern.DATE_INPUT_OUTPUT)

export const parseBackendDateFormatToBookingView = (date: string) =>
  format(parseBackendDateFormat(date), DateFormatPattern.BOOKING_VIEW_DATE)
