import {makeAutoObservable} from 'mobx'

export class CAboutStore {
  constructor() {
    makeAutoObservable(this)
  }

  private _isMapModalOpen = false

  public get isMapModalOpen(): boolean {
    return this._isMapModalOpen
  }

  public setIsMapModalOpen(flag: boolean): void {
    this._isMapModalOpen = flag
  }
}
