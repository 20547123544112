import {request} from '@api/request'
import type {
  ClientAdmitadVisitsCreateRequest,
  ClientAdmitadVisitsCreateResponse,
} from '@api/rest/api-scheme'
import {url} from '@utils/http/url'
import {clientHeaders} from '@utils/http/headers'
import {path} from 'ramda'

type CreateVisitContext = {
  payload: ClientAdmitadVisitsCreateRequest
  headers?: Headers
}

const createVisit = ({payload, headers}: CreateVisitContext) =>
  request<ClientAdmitadVisitsCreateResponse>(url('client/admitad/visits'), {
    method: 'POST',
    headers: headers || clientHeaders(),
    body: JSON.stringify(payload),
  }).then(path<ClientAdmitadVisitsCreateResponse['data']>([1, 'data']))

export const admitAdApi = {
  createVisit,
}
