import {css} from '@emotion/react'

export const ButtonPaddingVariants = {
  default: css`
    padding: 12px 20px;
  `,

  XS: css`
    padding: 5px 12px;
  `,
  S: css`
    padding: 12px;
  `,
  SM: css`
    padding: 12px 20px;
  `,
  M: css`
    padding: 15px 20px;
  `,
}

export type ButtonPadding = keyof typeof ButtonPaddingVariants
