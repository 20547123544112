import {Link} from '@ui/index'
import useTranslation from 'next-translate/useTranslation'
import {ENVIRONMENT} from '@/library/utils/guards/environment'
import {Help} from '../help'

export const HeaderLinks = () => {
  const {t} = useTranslation('features.header')

  return (
    <>
      <Help />
      <Link href={ENVIRONMENT.INFO} target="_blank">
        {t('links.blog')}
      </Link>
      <Link href={ENVIRONMENT.HOTELIERS_URL} target="_blank">
        {t('links.rent_out_house')}
      </Link>
    </>
  )
}
