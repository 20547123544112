import styled from '@emotion/styled'
import {MEDIA_DEVICE_QUERY} from '@assets/theme/media'

export const Container = styled.main<{$maxWidth?: number}>`
  margin: 0 auto;
  padding: 0;
  max-width: ${({$maxWidth}) => $maxWidth || 1920}px;
  height: fit-content;
  min-height: 100%;

  @media ${MEDIA_DEVICE_QUERY.BIG_SCREEN} {
    padding: 0 110px;
  }

  @media ${MEDIA_DEVICE_QUERY.DESKTOP} {
    padding: 0 110px;
  }

  @media ${MEDIA_DEVICE_QUERY.LAPTOP} {
    padding: 0px 32px;
  }

  @media ${MEDIA_DEVICE_QUERY.MOBILE_LARGE} {
    padding: 0 16px;
  }
`
