import styled from '@emotion/styled'
import {DISABLED_STYLES, TRANSITION_STYLES} from '@/library/constants/css'
import {BaseButton} from './button.base'

export const GreenButton = styled(BaseButton)`
  background-color: ${({theme}) => theme.palette.textPositive};
  color: ${({theme}) => theme.palette.textQuaternary};

  transition: opacity ${TRANSITION_STYLES};

  &:disabled {
    ${DISABLED_STYLES}
  }

  &:hover {
    opacity: 0.85;
  }
`
