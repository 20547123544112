import styled from '@emotion/styled'
import {Icon, type IconName} from '@ui/icon'
import type {FC, HTMLAttributes, PropsWithChildren} from 'react'
import {MEDIA_DEVICE_QUERY} from '@assets/theme/media'

const Container = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: ${({theme}) => theme.palette.backgroundQuaternary};
  z-index: 21;
  overflow: hidden;
`

const HeadWrapper = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
  background-color: ${({theme}) => theme.palette.backgroundQuaternary};
  z-index: 22;
  padding: 20px 20px;
  border-bottom: 1px solid ${({theme}) => theme.palette.borderTertiary};
`

const Title = styled.div`
  color: ${({theme}) => theme.palette.textPrimary};
  font-size: 24px;
  font-weight: 500;
  line-height: 26px;

  @media ${MEDIA_DEVICE_QUERY.MOBILE_LARGE} {
    font-size: 20px;
  }
`

const IconWrapper = styled.div`
  width: 40px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

interface Props extends HTMLAttributes<HTMLDivElement>, PropsWithChildren {
  onClick?: () => void
  title?: string
  iconName?: IconName
  iconSize?: number
}

const Header: FC<Props> = ({
  onClick,
  title,
  iconName,
  iconSize = 32,
  children,
  ...properties
}) => {
  return (
    <HeadWrapper {...properties}>
      {iconName && (
        <IconWrapper onClick={onClick}>
          <Icon name={iconName} width={iconSize} height={iconSize} />
        </IconWrapper>
      )}
      {title && <Title className="title">{title}</Title>}
      {children}
    </HeadWrapper>
  )
}

export const MobileModalPage = {
  Container,
  Header,
}
