import type {BookingStatusEnum} from '@/library/api/gql/generate-types'
import useTranslation from 'next-translate/useTranslation'
import type {FC} from 'react'
import {useTheme} from '@emotion/react'
import {Text} from '../../ui'
import type {TextSize} from '../../ui/typography/text/variants/size'
import {getBookingStatusInfo} from './lib/helpers'

interface BookingStatusProps {
  status: BookingStatusEnum
  size: TextSize
  className?: string
}

export const BookingStatus: FC<BookingStatusProps> = ({
  status,
  size,
  className,
}) => {
  const {t} = useTranslation('feature.booking-status')
  const theme = useTheme()
  const bookingStatusInfo = getBookingStatusInfo(theme)[status]
  return (
    <Text className={className} size={size} color={bookingStatusInfo.color}>
      {t(bookingStatusInfo.label)}
    </Text>
  )
}
