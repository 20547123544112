import {observer} from 'mobx-react'
import {store} from '@store/index'
import {useLocalObservable} from 'mobx-react-lite'
import type {GetDestinationAutoCompleteQuery} from '@api/gql/generate-types'
import useTranslation from 'next-translate/useTranslation'
import {DestinationSearchObject} from '../ui/object'

const selectDestinationAutoCompleteCities = () => ({
  get cities() {
    return store().search.destination.data?.cities
  },
})

export const SearchInputDestinationCities = observer(() => {
  const {t} = useTranslation('features.search-input')
  const {cities} = useLocalObservable(selectDestinationAutoCompleteCities)

  if (!cities?.length) {
    return null
  }

  const handleSelectCity = (
    city: NonNullable<
      GetDestinationAutoCompleteQuery['destinationAutocomplete']['cities']
    >[number],
  ) => {
    if (city.name && city.id && city.seoUrl.url) {
      store().search.destination.setIsOpen(false)
      store().search.destination.setSelectPlace({
        name: city.name,
        url: city.seoUrl.url,
      })
      store().search.destination.setInputValue(city.name)
    }
  }

  return (
    <DestinationSearchObject.Content
      title={t('destination.popover.cities.title')}
    >
      {cities.map((city) => (
        <DestinationSearchObject.Item
          key={city.id}
          onClick={() => handleSelectCity(city)}
          iconName="city"
          title={city.name || ''}
          subtitle={city.region.name || ''}
        />
      ))}
    </DestinationSearchObject.Content>
  )
})
