import {store} from '@/library/store'
import {observer} from 'mobx-react'
import {Modal} from '@ui/modal'
import styled from '@emotion/styled'
import {Link, Text} from '@/library/components/ui'
import {TRANSITION_STYLES} from '@/library/constants/css'
import {Illustration} from '@/library/components/ui/illustration'
import {useDeviceDetection} from '@/library/hooks/use-device-detection'
import {services} from '@/library/services'
import {HOTEL_SCENARIO_PLACE_ID} from '@/library/constants/dom-ids'
import useTranslation from 'next-translate/useTranslation'

const Wrapper = styled.div``

const Title = styled(Text)`
  font-family: ${({theme}) => theme.font.main};
  font-weight: 500;
  margin-bottom: 10px;
`

const ListWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 20px;
`

const StyledLink = styled(Link)`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 16px;
  border-bottom: 1px solid ${({theme}) => theme.palette.borderTertiary};
  padding: 10px 0;

  p {
    transition: color ${TRANSITION_STYLES};
  }

  &:hover {
    p {
      color: ${({theme}) => theme.palette.textAccentPrimary};
    }
  }
`

const LinkIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const LinkTextWrapper = styled(Text)`
  font-family: ${({theme}) => theme.font.main};
  font-weight: 500;
`

export const NavigationModal = observer(() => {
  const {t} = useTranslation('global.hotel')
  const {isOpenNavigationModal, data} = store().pages.global.hotel
  const isMobile = useDeviceDetection('mobile')

  const textSize = isMobile ? 'XS' : 'S'

  if (!isOpenNavigationModal || !data || !data.lng || !data.lat) {
    return null
  }

  const {lng, lat} = data

  const handleLinkClick = () =>
    store().pages.global.hotel.setIsOpenNavigationModal(false)

  return (
    <Modal
      open={isOpenNavigationModal}
      onCancel={() =>
        store().pages.global.hotel.setIsOpenNavigationModal(false)
      }
    >
      <Wrapper>
        <Title size="M">{t('main_information.where_to_open')}</Title>
        <ListWrapper>
          <StyledLink
            onClick={handleLinkClick}
            href={`#${HOTEL_SCENARIO_PLACE_ID}`}
          >
            <LinkIconWrapper>
              <Illustration name="logo_small" width={32} height={32} />
            </LinkIconWrapper>
            <LinkTextWrapper size={textSize}>
              {t('main_information.zhilibyli')}
            </LinkTextWrapper>
          </StyledLink>
          <StyledLink
            onClick={handleLinkClick}
            href={services.common.mapNavigation.buildYandexLink(lng, lat)}
            target="_blank"
          >
            <LinkIconWrapper>
              <Illustration name="yandex_navigator" width={32} height={32} />
            </LinkIconWrapper>
            <LinkTextWrapper size={textSize}>
              {t('main_information.yandex')}
            </LinkTextWrapper>
          </StyledLink>
          <StyledLink
            onClick={handleLinkClick}
            href={services.common.mapNavigation.buildTwoGisLink(lng, lat)}
            target="_blank"
          >
            <LinkIconWrapper>
              <Illustration name="twogis_navigator" width={32} height={32} />
            </LinkIconWrapper>
            <LinkTextWrapper size={textSize}>
              {t('main_information.twogis')}
            </LinkTextWrapper>
          </StyledLink>
          <StyledLink
            onClick={handleLinkClick}
            href={services.common.mapNavigation.buildGoogleLink(lng, lat)}
            target="_blank"
          >
            <LinkIconWrapper>
              <Illustration name="google_navigator" width={32} height={32} />
            </LinkIconWrapper>
            <LinkTextWrapper size={textSize}>
              {t('main_information.google')}
            </LinkTextWrapper>
          </StyledLink>
        </ListWrapper>
      </Wrapper>
    </Modal>
  )
})
