import {
  type GetHotelRatesFiltersQueryVariables,
  type GetHotelsQueryVariables,
  ProviderRatesSearchingTypeEnum,
} from '@api/gql/generate-types'
import type {ClientRatesSearchCreateRequest} from '@api/rest/api-scheme'
import {store} from '@store/index'
import {getNearestWeekend} from '@utils/date/get-nearest-weekend'
import {format} from 'date-fns'
import {DateFormatPattern} from '@/types/enums/date-format-patterns'

export class GlobalPageSearchFetchQueryBuilder {
  public buildHotelRatesQueryParameters(): GetHotelsQueryVariables['params'] {
    const {sort} = store().pages.global.search.hotels
    const rate = this.buildRates()
    const {
      ratingFrom,
      stars,
      typeIds,
      regionId,
      cityId,
      countryId,
      searchingType,
    } = this.buildQueryOptions()

    return {
      searchingType,
      rate,
      typeIds,
      ratingFrom,
      stars,
      regionIds: regionId ? [regionId] : undefined,
      cityIds: cityId ? [cityId] : undefined,
      countryId,
      sort: sort ? [sort] : undefined,
    }
  }

  public buildSearchMapPointsQuery(
    searchId: string,
    numberOfReceived: number,
  ): ClientRatesSearchCreateRequest {
    const rates = this.buildRates()
    const {
      ratingFrom,
      stars,
      typeIds,
      regionId,
      cityId,
      countryId,
      searchingType,
    } = this.buildQueryOptions()

    return {
      search_id: searchId,
      hotels: {
        rating_from: ratingFrom,
        type_ids: typeIds,
        region_id: regionId,
        city_id: cityId,
        country_id: countryId,
        stars,
      },
      rates: {
        adults: rates?.adults,
        checkin: rates?.checkin,
        checkout: rates?.checkout,
        children: rates?.children,
        price_from: rates?.priceFrom,
        price_to: rates?.priceTo,
      },
      // Тут такая привязка из-за того, что типы которые используются в queryVariables из gql
      // И там enum называется с большой, а в ресте надо отправлять в маленькой, такие дела
      searching_type:
        searchingType.toLowerCase() as ClientRatesSearchCreateRequest['searching_type'],
      number_of_received: numberOfReceived,
    }
  }

  public buildRatesFiltersQuery(): GetHotelRatesFiltersQueryVariables['params'] {
    const rate = this.buildRates()
    const {
      typeIds,
      stars,
      ratingFrom,
      regionId,
      cityId,
      countryId,
      searchingType,
    } = this.buildQueryOptions()

    return {
      searchingType,
      rate,
      stars,
      typeIds,
      ratingFrom,
      regionIds: regionId ? [regionId] : undefined,
      cityIds: cityId ? [cityId] : undefined,
      countryId,
    }
  }

  private buildRates() {
    const {guests, dateFrom, dateTo} = store().search
    const {priceFrom, priceTo} = store().pages.global.search.filters
    const weekend = getNearestWeekend(new Date())

    // eslint-disable-next-line consistent-return
    return {
      adults: guests.adults.amount,
      checkin: format(
        dateFrom.value || weekend.startDate,
        DateFormatPattern.QUERY,
      ),
      checkout: format(
        dateTo.value || weekend.endDate,
        DateFormatPattern.QUERY,
      ),
      children: guests.children.children,
      priceFrom,
      priceTo,
    }
  }

  private buildQueryOptions() {
    const {
      pages: {global},
    } = store()
    const {info, search, searchingType} = global
    const {selectRatingFrom, selectStars, selectHotelTypes} = search.filters

    const locationParameters = {
      regionId: info?.filters.region?.id,
      cityId: info?.filters.city?.id,
      countryId: info?.filters.country?.id,
    }

    if (searchingType === ProviderRatesSearchingTypeEnum.BestOffers) {
      delete locationParameters.regionId
      delete locationParameters.cityId
      delete locationParameters.countryId
    }

    return {
      ratingFrom: selectRatingFrom ?? undefined,
      stars: selectStars.length > 0 ? selectStars : undefined,
      typeIds: selectHotelTypes.length > 0 ? selectHotelTypes : undefined,
      searchingType,
      ...locationParameters,
    }
  }
}
