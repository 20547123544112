import type {FC} from 'react'
import styled from '@emotion/styled'
import {Text} from '@/library/components/ui'
import useTranslation from 'next-translate/useTranslation'

interface PaymentStatusProps {
  isPaid: boolean
  className?: string
}

const Status = styled(Text)<{isPaid: boolean}>`
  color: ${({theme, isPaid}) =>
    isPaid ? theme.palette.textPositive : theme.palette.textAccentPrimary};
`

export const PaymentStatus: FC<PaymentStatusProps> = ({isPaid, className}) => {
  const {t} = useTranslation('profile.bookings')
  return (
    <Status size="S" className={className} isPaid={isPaid}>
      {isPaid ? t('paid') : t('unpaid')}
    </Status>
  )
}
