import type {UserProfile} from '@/library/api/gql/generate-types'
import type {UpdateMyUserProfileFormData} from '@/library/constants/form-validation/update-my-user-profile-form'
import {addPlus} from '../../../../library/utils/phone-numbers/index'
import type {UserProfileField} from './types'

export const mergeFormValues = (
  initialValues: UpdateMyUserProfileFormData,
  profile?: UserProfile | null,
): UpdateMyUserProfileFormData => {
  if (!profile) {
    return initialValues
  }

  return {
    firstname: profile.firstname || initialValues.firstname,
    lastname: profile.lastname || initialValues.lastname,
    citizenship: profile.citizenship || initialValues.citizenship,
    email: profile.email || initialValues.email,
    phone: profile.phone ? addPlus(profile.phone) : initialValues.phone,
    sex: profile.sex || initialValues.sex,
    birthday: profile.birthday || initialValues.birthday,
  }
}

const compareField = (
  field: UserProfileField,
  formValues: UpdateMyUserProfileFormData,
  userProfile: UserProfile,
): boolean => formValues[field] === userProfile[field]

const comparePhone = (
  formPhone?: string | null,
  userPhone?: string | null,
): boolean => (userPhone ? addPlus(userPhone) === formPhone : false)

export const compareProfiles = (
  formValues: UpdateMyUserProfileFormData,
  userProfile?: UserProfile | null,
): boolean => {
  if (!userProfile) {
    return false
  }

  const fieldsToCompare: UserProfileField[] = [
    'firstname',
    'lastname',
    'citizenship',
    'sex',
    'email',
    'birthday',
  ]

  const fieldsMatch = fieldsToCompare.every((field) => {
    return compareField(field, formValues, userProfile)
  })

  return fieldsMatch && comparePhone(formValues.phone, userProfile.phone)
}
