import {Select} from '@/library/components/ui'
import useTranslation from 'next-translate/useTranslation'
import type {FC} from 'react'
import {buildCitizenshipOption, getCitizenshipOptions} from './lib/helpers'
import {DEFAULT_COUNTRY} from './lib/constants'

interface CitizenshipInputProps {
  citizenship?: string | null
  setCitizenship: (citizenship: string) => void
  label?: string
  disabled?: boolean
  className?: string
}

export const CitizenshipInput: FC<CitizenshipInputProps> = ({
  citizenship,
  setCitizenship,
  label,
  disabled,
  className,
}) => {
  const {t} = useTranslation('countries')
  const citizenshipOptions = getCitizenshipOptions(t)
  return (
    <Select
      value={buildCitizenshipOption(citizenship || DEFAULT_COUNTRY, t)}
      setValue={(option) => setCitizenship(t(option.label))}
      options={citizenshipOptions}
      label={label}
      disabled={disabled}
      className={className}
    />
  )
}
