import {BookingFormFields} from '@/library/api/gql/generate-types'
import {Input} from '@/library/components/ui/input'
import {useDebounce} from '@/library/hooks/use-debounce'
import {services} from '@/library/services'
import {store} from '@/library/store'
import {observer} from 'mobx-react'
import useTranslation from 'next-translate/useTranslation'
import {path} from 'ramda'
import {useEffect, type ChangeEvent, type FC} from 'react'
import {useFormContext} from 'react-hook-form'
import {useIsRedirecting} from '@/library/hooks/use-redirecting'
import {getInputDebounceConfig} from './lib/helpers'

interface FirstNameInputProps {
  index: number
  id: string
}

export const FirstNameInput: FC<FirstNameInputProps> = observer(
  ({index, id}) => {
    const {t} = useTranslation('global.hotel')
    const {guestsData, isLoading} = store().bookingModal.modal
    const isRedirecting = useIsRedirecting()

    const sendFirstNameAnalytics = useDebounce(
      ...getInputDebounceConfig(BookingFormFields.FirstName, index),
    )

    const {
      register,
      formState: {errors},
      setValue,
    } = useFormContext()

    const value = guestsData?.[index].first_name

    const name = `guests.${index}.first_name`
    const error = path<string>(
      ['guests', `${index}`, 'first_name', 'message'],
      errors,
    )

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      register(name).onChange(event)
      const {value} = event.target

      services.pages.global.scenario.hotel.booking.bookingState.updateGuestsData(
        index,
        'first_name',
        value,
      )
      if (value) {
        sendFirstNameAnalytics(value)
      }
    }

    useEffect(() => {
      if (value) {
        setValue(name, value)
      }
    }, [])

    return (
      <Input
        type="text"
        key={id}
        disabled={isLoading || isRedirecting}
        placeholder={t('booking_modal.first_name')}
        label={t('booking_modal.first_name')}
        error={error}
        {...register(name)}
        onChange={handleChange}
        value={value}
      />
    )
  },
)
