/**
 * Отступ требуется для того, что бы увеличить приближение к точкам
 * Если нужно увеличить отступ, что бы было больше видно на карте - увеличиваем значение паддинга
 * Значение 0.00018 оптимальное, и при изменении bounds отображает все указанные элементы
 */
const DEFAULT_PADDING = 0.00018

export function getMapBoundsFromCoords(
  coordinates: number[][],
  padding = DEFAULT_PADDING,
) {
  let minX = Number.POSITIVE_INFINITY
  let minY = Number.POSITIVE_INFINITY
  let maxX = Number.NEGATIVE_INFINITY
  let maxY = Number.NEGATIVE_INFINITY

  coordinates.forEach((coord) => {
    minX = Math.min(minX, coord[0])
    minY = Math.min(minY, coord[1])
    maxX = Math.max(maxX, coord[0])
    maxY = Math.max(maxY, coord[1])
  })

  minX -= padding
  minY -= padding
  maxX += padding
  maxY += padding

  return [
    [minX, minY],
    [maxX, maxY],
  ]
}
