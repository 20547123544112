import {css} from '@emotion/react'

export const ButtonTextSizeVariants = {
  default: css`
    font-weight: 400;
    letter-spacing: 0.02em;
  `,
  defaultSemiBold: css`
    font-weight: 500;
    letter-spacing: 0.02em;
  `,
  defaultBold: css`
    font-weight: 600;
    letter-spacing: 0.02em;
  `,
  S: css`
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  `,
  boldS: css`
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
  `,
  semiBoldS: css`
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
  `,
}

export type ButtonTextSize = keyof typeof ButtonTextSizeVariants
