import styled from '@emotion/styled'

export const HorizontalDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({theme}) => theme.palette.borderTertiary};
`

export const VerticalDivider = styled.div`
  height: 100%;
  width: 1px;
  background-color: ${({theme}) => theme.palette.borderTertiary};
`
