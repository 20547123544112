import {GlobalPageHotelUrlQueryParser} from '@services/pages/global/scenario/hotel/url-query-parser'
import {GlobalPageHotelReviewsService} from '@services/pages/global/scenario/hotel/reviews'
import {GlobalPageHotelRatesService} from '@services/pages/global/scenario/hotel/rates'
import {GlobalPageHotelMapService} from '@services/pages/global/scenario/hotel/map'
import {GlobalPageHotelFetchQueryBuilder} from '@services/pages/global/scenario/hotel/fetch-query-builder'
import {GlobalPageMapPollingPointsService} from '@services/pages/global/polling-points'
import {store} from '@/library/store'
import {api} from '@/library/api'
import {GlobalPageHotelBookingService} from './booking'

export class GlobalPageHotelService {
  private readonly fetchQueryBuilder: GlobalPageHotelFetchQueryBuilder =
    new GlobalPageHotelFetchQueryBuilder()

  public readonly booking: GlobalPageHotelBookingService =
    new GlobalPageHotelBookingService()

  public readonly queryParser: GlobalPageHotelUrlQueryParser =
    new GlobalPageHotelUrlQueryParser()

  public readonly reviews: GlobalPageHotelReviewsService =
    new GlobalPageHotelReviewsService()

  public readonly rates: GlobalPageHotelRatesService =
    new GlobalPageHotelRatesService()

  public readonly map: GlobalPageHotelMapService =
    new GlobalPageHotelMapService(this.fetchQueryBuilder)

  private readonly polling: GlobalPageMapPollingPointsService =
    new GlobalPageMapPollingPointsService(this.map, this.fetchQueryBuilder)

  public async load() {
    this.rates.load()
    this.map.load().then((result) => {
      if (result) {
        this.polling.start(result.searchId)
      }
    })
  }

  public loadSimilarHotels() {
    const hotel = store().pages.global.hotel.data

    if (!hotel?.id) {
      return
    }

    store().pages.global.hotel.setIsSimilarHotelsLoading(true)
    api.hotel
      .getSimilarHotels({variables: {hotelId: hotel.id}})
      .then((similarHotels) => {
        if (similarHotels)
          store().pages.global.hotel.setSimilarHotels(similarHotels)
      })
      .finally(() =>
        store().pages.global.hotel.setIsSimilarHotelsLoading(false),
      )
  }
}
