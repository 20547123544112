import type {Translate} from 'next-translate'
import {countries} from './constants'

export const buildCitizenshipOption = (country: string, t: Translate) => {
  return {
    value: t(country),
    label: t(country),
  }
}

export const getCitizenshipOptions = (t: Translate) => {
  return countries.map((country) => {
    return buildCitizenshipOption(country, t)
  })
}
