import {store} from '@store/index'

const selectRegisterError = () => ({
  get error() {
    return store().authorization.register.error
  },
})

const selectLoginError = () => ({
  get error() {
    return store().authorization.login.error
  },
})

const selectResetPasswordError = () => ({
  get error() {
    return store().authorization.resetPassword.error
  },
})

const selectRegisterIsSuccessful = () => ({
  get isSuccessful() {
    return store().authorization.register.isSuccessful
  },
})

const selectAuthorizationModalOpen = () => ({
  get modalOpen() {
    return store().authorization.modalOpen
  },
})

const selectAuthorizationModalAction = () => ({
  get modalAction() {
    return store().authorization.modalAction
  },
})

const selectPasswordResetStep = () => ({
  get step() {
    return store().authorization.resetPassword.step
  },
})

const selectAuthorizationIsLoading = () => ({
  get isLoading() {
    return store().authorization.isLoading
  },
})

const selectIsAuthorized = () => ({
  get isAuthorized() {
    return store().user.isAuthorized
  },
})

export const selectors = {
  selectRegisterError,
  selectLoginError,
  selectResetPasswordError,
  selectRegisterIsSuccessful,
  selectAuthorizationModalOpen,
  selectAuthorizationModalAction,
  selectPasswordResetStep,
  selectAuthorizationIsLoading,
  selectIsAuthorized,
}
