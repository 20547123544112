import {BookingStatusEnum} from '@/library/api/gql/generate-types'
import {parseBackendDateFormat} from '@utils/date/formatting'
import {ARTIFICIAL_BOOKING_NUMBER} from './constants'

export const isBookingStatusPayable = (status: BookingStatusEnum) =>
  status === BookingStatusEnum.Booked || status === BookingStatusEnum.Formed

export const calculatePaymentDate = (mustPayBeforeFinishSecondsLeft: number) =>
  Date.now() + mustPayBeforeFinishSecondsLeft * 1000

export const isCancellable = (freeCancellationBeforeTz?: string | null) => {
  if (!freeCancellationBeforeTz) {
    return false
  }

  const today = new Date()
  const cancellationBeforeMs = parseBackendDateFormat(
    freeCancellationBeforeTz,
  ).getTime()

  if (!cancellationBeforeMs || Number.isNaN(cancellationBeforeMs)) {
    return false
  }

  return today.getTime() <= cancellationBeforeMs
}

export const isRealBooking = (bookingNumber: string) =>
  bookingNumber !== ARTIFICIAL_BOOKING_NUMBER
