import {objectToQuerySearch} from '@utils/http/url'

export enum Routes {
  MAIN = '/',
  NOT_FOUND = '/404',
  SERVER_ERRROR = '/500',
  INTERNAL_ERROR = '/_error',
  HOME = '/',
  ABOUT = '/about',
  MOBILE = '/mobile-app',
  BOOKINGS = '/bookings',
  BOOKING = '/booking/[bookingId]',
  PROFILE = '/profile',
  POLITICS = '/politics',
  RULES = '/rules',
  RATE = '/tariff',
  PARTNER = '/partner',
  SOFTWARE = '/software',
  OFFER = '/offer',
  PUBLIC_OFFER_AGREEMENT = '/public_offer_agreement',
  RESET_PASSWORD = '/password-reset',
  SUPPORT = '/support',
  EMAIL_CONFIRM = '/authorization/email-confirm',
  PAYMENT_CHECK = '/payment/check',
  GLOBAL = '/global/[...all]',
  SUBSCRIPTION = '/subscription',
  TEMPORARY_SESSION = '/temporary-session',
  MOBILE_APP = '/mobile-app',
}

export enum AffiliatePaths {
  INDEX = '/affiliate',
  INVITES = '/affiliate/invites',
  WITHDRAW = '/affiliate/withdraw',
  REFERRALS = '/affiliate/referrals',
  STATISTICS = '/affiliate/statistics',
  WIDGETS = '/affiliate/widgets',
}

export enum ProfilePaths {
  SETTINGS = '/profile/settings',
  BOOKINGS = '/profile/bookings',
  BALANCE = '/profile/balance',
  FAVORITES = '/profile/favorites',
  PASSWORD = '/profile/password',
  REVIEWS = '/profile/reviews',
}

export enum GlobalPaths {
  BEST_OFFERS = '/rossiya',
}

export const getBookingId = (id: number, accessToken?: string | null) => {
  const query = objectToQuerySearch({})

  if (accessToken) {
    query.set('ac', accessToken)
  }

  return `/booking/${id}?${query.toString()}`
}
