import type {FC} from 'react'
import useTranslation from 'next-translate/useTranslation'
import Trans from 'next-translate/Trans'
import {EtInclusion, EtPriceUnit} from '@/library/api/gql/generate-types'
import type {OstrovokCot} from '../lib/types'
import {InlineAmount, InlineCurrency, InlineText} from '../../typography'
import {getCurrency} from '../../../lib/helpers'
import {getInclusion, getPriceUnit} from '../lib/helpers'

interface CotProps {
  cot: OstrovokCot
}

// Cot это детская кроватка

export const Cot: FC<CotProps> = ({cot}) => {
  const {t} = useTranslation('global.hotel')
  const {t: currenciesT} = useTranslation('currencies')

  const isInclusionSpecified =
    cot.inclusion && cot.inclusion !== EtInclusion.Unspecified

  if (cot.amount && cot.price && isInclusionSpecified) {
    return (
      <Trans
        i18nKey="rules.et.cot_with_amount_price_and_inclusion"
        ns="global.hotel"
        components={[
          <InlineAmount key={`cot_${cot.amount}_${cot.priceUnit}_amount`} />,
          <InlineCurrency
            key={`cot_${cot.amount}_${cot.priceUnit}_currency`}
          />,
          <InlineText key={`cot_${cot.amount}_${cot.priceUnit}`} />,
        ]}
        values={{
          amount: cot.amount,
          price: cot.price?.toLocaleString(),
          currency: getCurrency(currenciesT, cot.currency),
          unit: getPriceUnit(t, cot.priceUnit || EtPriceUnit.Unspecified),
          inclusion: getInclusion(t, cot.inclusion || EtInclusion.Unspecified),
        }}
      />
    )
  }

  if (cot.amount && cot.price) {
    return (
      <Trans
        i18nKey="rules.et.cot_with_amount_and_price"
        ns="global.hotel"
        components={[
          <InlineAmount key={`cot_${cot.amount}_${cot.priceUnit}_amount`} />,
          <InlineCurrency
            key={`cot_${cot.amount}_${cot.priceUnit}_currency`}
          />,
          <InlineText key={`cot_${cot.amount}_${cot.priceUnit}`} />,
        ]}
        values={{
          amount: cot.amount,
          price: cot.price?.toLocaleString(),
          currency: getCurrency(currenciesT, cot.currency),
          unit: getPriceUnit(t, cot.priceUnit || EtPriceUnit.Unspecified),
        }}
      />
    )
  }

  if (cot.amount && isInclusionSpecified) {
    return (
      <Trans
        i18nKey="rules.et.cot_with_amount_and_inclusion"
        ns="global.hotel"
        components={[<InlineText key={`cot_${cot.amount}_${cot.inclusion}`} />]}
        values={{
          amount: cot.amount,
          inclusion: getInclusion(t, cot.inclusion || EtInclusion.Unspecified),
        }}
      />
    )
  }

  if (cot.amount) {
    return (
      <Trans
        i18nKey="rules.et.cot_with_amount"
        ns="global.hotel"
        components={[<InlineText key={`cot_${cot.amount}`} />]}
        values={{
          amount: cot.amount,
        }}
      />
    )
  }

  if (cot.price && isInclusionSpecified) {
    return (
      <Trans
        i18nKey="rules.et.cot_with_price_and_inclusion"
        ns="global.hotel"
        components={[
          <InlineAmount key={`cot_${cot.priceUnit}_amount`} />,
          <InlineCurrency key={`cot_${cot.priceUnit}_currency`} />,
          <InlineText key={`cot_${cot.priceUnit}`} />,
        ]}
        values={{
          price: cot.price?.toLocaleString(),
          currency: getCurrency(currenciesT, cot.currency),
          unit: getPriceUnit(t, cot.priceUnit || EtPriceUnit.Unspecified),
          inclusion: getInclusion(t, cot.inclusion || EtInclusion.Unspecified),
        }}
      />
    )
  }

  if (cot.price) {
    return (
      <Trans
        i18nKey="rules.et.cot_with_price"
        ns="global.hotel"
        components={[
          <InlineAmount key={`cot_${cot.priceUnit}_amount`} />,
          <InlineCurrency key={`cot_${cot.priceUnit}_currency`} />,
          <InlineText key={`cot_${cot.priceUnit}`} />,
        ]}
        values={{
          price: cot.price?.toLocaleString(),
          currency: getCurrency(currenciesT, cot.currency),
          unit: getPriceUnit(t, cot.priceUnit || EtPriceUnit.Unspecified),
        }}
      />
    )
  }

  if (isInclusionSpecified) {
    return (
      <Trans
        i18nKey="rules.et.cot_with_inclusion"
        ns="global.hotel"
        components={[<InlineText key={`cot_${cot.inclusion}`} />]}
        values={{
          inclusion: getInclusion(t, cot.inclusion || EtInclusion.Unspecified),
        }}
      />
    )
  }

  return <Trans i18nKey="rules.et.cot" ns="global.hotel" />
}
