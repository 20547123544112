import {makeAutoObservable} from 'mobx'

export class CAffiliateInvitesCreate {
  constructor() {
    makeAutoObservable(this)
  }

  private _error = ''

  public get error(): string {
    return this._error
  }

  public setError(error: string): void {
    this._error = error
  }

  private _isOpen = false

  public get isOpen(): boolean {
    return this._isOpen
  }

  public setIsOpen(flag: boolean): void {
    this._isOpen = flag
  }

  private _isLoading = false

  public get isLoading(): boolean {
    return this._isLoading
  }

  public setIsLoading(flag: boolean): void {
    this._isLoading = flag
  }

  private _companyName = ''

  public get companyName(): string {
    return this._companyName
  }

  public setCompanyName(name: string): void {
    this._companyName = name
  }

  private _referralLink = ''

  public get referralLink(): string {
    return this._referralLink
  }

  public setReferralLink(link: string): void {
    this._referralLink = link
  }
}
