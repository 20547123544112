import {useTheme} from '@emotion/react'
import styled from '@emotion/styled'
import {Slider} from 'antd'
import type {ComponentProps, FC} from 'react'

const StyledSlider = styled(Slider)`
  &:hover {
    .ant-slider-handle::after {
      box-shadow: 0 0 0 3px
        ${({theme}) => theme.palette.backgroundAccentPrimary};
    }
  }

  .ant-slider-handle::after {
    box-shadow: 0 0 0 3px ${({theme}) => theme.palette.backgroundAccentPrimary};
  }

  .ant-slider-handle:focus {
    &::after {
      box-shadow: 0 0 0 5px
        ${({theme}) => theme.palette.backgroundAccentPrimary};
    }
  }

  .ant-slider-handle:hover {
    &::after {
      box-shadow: 0 0 0 5px
        ${({theme}) => theme.palette.backgroundAccentPrimary};
    }
  }
`

type Props = ComponentProps<typeof Slider>

export const RangeSlider: FC<Props> = ({styles, ...properties}) => {
  const theme = useTheme()

  return (
    <StyledSlider
      styles={{
        track: {
          backgroundColor: theme.palette.backgroundAccentPrimary,
        },
        rail: {
          backgroundColor: theme.palette.backgroundTertiary,
        },
        handle: {
          borderColor: theme.palette.borderTertiary,
          boxShadow: 'none',
        },
        ...styles,
      }}
      {...properties}
    />
  )
}
