import type {GetHotelQuery} from '@api/gql/generate-types'
import type {FC} from 'react'
import styled from '@emotion/styled'
import {Icon} from '@ui/icon'
import {Text} from '@components/ui'
import {format} from 'date-fns'
import {DateFormatPattern} from '@/types/enums/date-format-patterns'
import {Illustration} from '@ui/illustration'
import {parseBackendDateFormat} from '@utils/date/formatting'

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 6px;
`

const InformationWrapper = styled.div`
  display: flex;
  gap: 12px;
`

const Avatar = styled(Illustration)`
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
`

const AvatarInformationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
`

const AvatarName = styled(Text)`
  color: ${({theme}) => theme.palette.textSecondary};
  font-weight: 500;
`

const AvatarRating = styled.div`
  color: ${({theme}) => theme.palette.textAccentPrimary};

  svg {
    color: ${({theme}) => theme.palette.textAccentPrimary};
    margin-top: 4px;
  }

  font-weight: 700;
  display: flex;
  justify-content: start;
  align-content: center;
  gap: 5px;
`

const AvatarRatingCount = styled.div`
  color: ${({theme}) => theme.palette.textSecondary};
  font-weight: 500;
`

const Date = styled(Text)`
  color: ${({theme}) => theme.palette.textSecondary};
`

const CommentWrapper = styled.div``

const PositiveWrapper = styled.div``
const PositiveTitle = styled(Text)`
  color: ${({theme}) => theme.palette.textPositive};
  margin-bottom: 5px;
  font-weight: 500;
`

const NegativeWrapper = styled.div`
  margin-top: 10px;
`
const NegativeTitle = styled(Text)`
  color: ${({theme}) => theme.palette.textError};
  margin-bottom: 5px;
  font-weight: 500;
`

const Comment = styled(Text)``

interface Props {
  review: NonNullable<Required<GetHotelQuery['hotelReviews']>['data']>[number]
}

export const HotelScenarioHotelReviewCard: FC<Props> = ({review}) => {
  return (
    <Wrapper>
      <Header>
        <InformationWrapper>
          <Avatar name="avatar" width={40} height={40}>
            <div>{review.author.charAt(0).toUpperCase()}</div>
          </Avatar>
          <AvatarInformationWrapper>
            <AvatarName size="S">{review.author}</AvatarName>
            {review.score && (
              <AvatarRating>
                <Icon name="star" width={14} height={14} />
                <AvatarRatingCount>{review.score}</AvatarRatingCount>
              </AvatarRating>
            )}
          </AvatarInformationWrapper>
        </InformationWrapper>
        <Date size="S">
          {format(
            parseBackendDateFormat(review.created_at),
            DateFormatPattern.TABLE,
          )}
        </Date>
      </Header>
      <CommentWrapper>
        {review.positive && (
          <PositiveWrapper>
            <PositiveTitle size="S">Достоинства:</PositiveTitle>
            <Comment size="S">{review.positive}</Comment>
          </PositiveWrapper>
        )}
        {review.negative && (
          <NegativeWrapper>
            <NegativeTitle size="S">Недостатки:</NegativeTitle>
            <Comment size="S">{review.negative}</Comment>
          </NegativeWrapper>
        )}
      </CommentWrapper>
    </Wrapper>
  )
}
