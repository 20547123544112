import {MINIMAL_PRICE} from '@/library/constants/validation'

const getMaxWriteOff = (
  onlineAmount: number,
  bookingMinAmount: number,
  balance: number,
) => {
  const isBalanceHigher = balance > onlineAmount

  // Если баланс больше суммы, то списываем всё, оставляя bookingMinAmount
  if (isBalanceHigher) {
    return onlineAmount - bookingMinAmount
  }

  const isUnderPricePresent = onlineAmount - balance < bookingMinAmount

  // Если баланс меньше суммы, но списав весь баланс сумма станет меньше bookingMinAmount, то
  // нужно найти разницу между bookingMinAmount - остатка и онять от баланса
  // таким образом, мы сохраняем условиее, что сумма списания с баланса не может быть меньше bookingMinAmount
  if (!isBalanceHigher && isUnderPricePresent) {
    const underPrice = bookingMinAmount - onlineAmount + balance
    return balance - underPrice
  }

  // Иначе даём списывать весь баланс
  return balance
}

export const calculateWriteOff = (
  onlineAmount: number,
  bookingMinAmount: number | null,
  balance?: number | null,
) => {
  if (!balance) {
    return MINIMAL_PRICE
  }

  if (!bookingMinAmount) {
    return Math.floor(Math.min(onlineAmount, balance))
  }

  const maxAmount = getMaxWriteOff(onlineAmount, bookingMinAmount, balance)

  // Результат не может быть меньше 0 и больше MAXIMUM_BALANCE_AMOUNT, а также должен быть округлён до целого числа вниз
  return Math.floor(
    Number.parseFloat(Math.max(maxAmount, MINIMAL_PRICE).toFixed(2)),
  )
}
