import styled from '@emotion/styled'
import {SearchScenarioMap} from '@slices/global/ui/search-scenario/map'
import {SearchScenarioHotels} from '@slices/global/ui/search-scenario/hotels'
import {useRouter} from 'next/router'
import {services} from '@services/index'
import {useEffect, useRef, useState} from 'react'
import {SearchScenarioFilters} from '@slices/global/ui/search-scenario/filters'
import {useDeviceDetection} from '@hooks/use-device-detection'
import {MEDIA_DEVICE_QUERY} from '@assets/theme/media'
import {SearchInput} from '@/library/components/features'
import {EmailSubscription} from '@/library/components/features/email-subscription'
import {GlobalPageBreadcrumbs} from '@slices/global/ui/breadcrumbs'
import {useWindowSize} from '@/library/hooks/use-window-size'
import {store} from '@/library/store'
import {SearchScenarioHotelsSorting} from '@slices/global/ui/search-scenario/hotels/hotel-sorting'
import {ROOT_ID} from '@constants/dom-ids'
import {TRANSITION_STYLES} from '@constants/css'
import {SEARCH_SCENARIO_MOBILE_STICKY_INPUT_THRESHOLD} from '@slices/global/lib/constants'
import {RefreshPopup} from '@/library/components/features/refresh-popup'
import {ENVIRONMENT} from '@/library/utils/guards/environment'
import {TOUCH_VERSION_POINT} from './constants'
import {MobileFiltersTrigger} from './mobile-filters-trigger'

const StyledContainer = styled.main`
  width: 100%;
  height: calc(100vh - 6%);
  max-width: 100%;
  background-color: ${({theme}) => theme.palette.overlaySecondary};
  padding: 20px;
  overflow: hidden;

  @media ${MEDIA_DEVICE_QUERY.LAPTOP_MEDIUM} {
    height: fit-content;
    overflow: unset;
  }

  @media ${MEDIA_DEVICE_QUERY.MOBILE_LARGE} {
    padding: 0;
  }
`

const StickyWrapperPadding = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media ${MEDIA_DEVICE_QUERY.MOBILE_LARGE} {
    padding: 10px;
  }
`

const Wrapper = styled.div<{$isTouch: boolean}>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-content: center;
  flex-direction: row;
  gap: 20px;
`

const BreadcrumbsWrapper = styled.div`
  padding: 0px 10px 0px 5px;

  @media ${MEDIA_DEVICE_QUERY.LAPTOP_MEDIUM} {
    padding: 0px 10px 0px 5px;
    margin: 0;
  }

  @media ${MEDIA_DEVICE_QUERY.MOBILE_LARGE} {
    padding: 12px 10px 0px 5px;
  }
`

const FiltersSortWrapper = styled.div`
  display: flex;
  gap: 10px;

  width: 100%;
  margin-bottom: 10px;
`

const FiltersWrapper = styled.aside`
  min-width: 350px;
  background-color: ${({theme}) => theme.palette.backgroundQuaternary};
  border-right: 1px solid ${({theme}) => theme.palette.borderTertiary};
  border-radius: 12px;
  box-shadow: 0px 4px 20px ${({theme}) => theme.palette.boxShadowPrimary};
  padding-bottom: 16px;

  @media ${MEDIA_DEVICE_QUERY.LAPTOP} {
    display: none;
  }
`

const HotelsWrapper = styled.section<{$isTouch: boolean}>`
  min-width: ${({$isTouch}) => ($isTouch ? 'auto' : '840px')};
  border-radius: 12px;
  padding: 0 10px 16px 0;

  display: flex;
  flex-direction: column;
  gap: 12px;

  @media ${MEDIA_DEVICE_QUERY.LAPTOP_MEDIUM} {
    width: 100%;
    padding: 0;
  }

  @media ${MEDIA_DEVICE_QUERY.MOBILE_LARGE} {
    min-width: 100%;
    padding: 0 10px 0px 12px;
  }
`

const StickyWrapper = styled.div<{$isTouchLayout: boolean; $isOpen: boolean}>`
  position: ${({$isTouchLayout}) => ($isTouchLayout ? 'sticky' : 'unset')};
  transition: transform ${TRANSITION_STYLES};
  top: 75px;
  transform: ${({$isOpen}) =>
    $isOpen ? 'translateY(0)' : 'translateY(-500%)'};
  z-index: 20;
  margin-bottom: 10px;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: ${({theme}) => theme.palette.overlaySecondary};
    z-index: -1;
  }

  @media ${MEDIA_DEVICE_QUERY.MOBILE_LARGE} {
    margin-bottom: 0;
  }
`

const StickyContent = () => {
  const ref = useRef<HTMLDivElement | null>(null)
  const isTouch = useDeviceDetection('touch')
  const windowWidth = useWindowSize()
  const isTouchLayout = isTouch && windowWidth <= TOUCH_VERSION_POINT
  const latestScrollPosition = useRef<number>(0)
  const latestScrollUpdateStatePosition = useRef<number>(0)
  const [isOpen, setIsOpen] = useState(true)

  const handleScrollBodyElement = function (this: HTMLDivElement) {
    const {scrollTop} = this
    const containerHeight = ref.current?.getBoundingClientRect().height

    // Если высота контейнера не определена, выходим из функции
    if (!containerHeight) {
      return
    }

    setIsOpen(() => {
      // Если scrollTop меньше или равен высоте контейнера, открываем
      if (scrollTop <= containerHeight) {
        return true
      }

      // Проверяем, прокручивается ли элемент вверх
      const isScrolledUp = scrollTop <= latestScrollPosition.current

      if (isScrolledUp) {
        // Если позиция последнего обновления состояния прокрутки не установлена, устанавливаем её
        if (!latestScrollUpdateStatePosition.current) {
          latestScrollUpdateStatePosition.current = latestScrollPosition.current
        }

        // Если разница между последней позицией обновления и текущей позицией прокрутки
        // превышает заданный порог, открываем
        if (
          latestScrollUpdateStatePosition.current -
            latestScrollPosition.current >=
          SEARCH_SCENARIO_MOBILE_STICKY_INPUT_THRESHOLD
        ) {
          return true
        }
      } else {
        // Если элемент прокручивается вниз, сбрасываем позицию последнего обновления состояния прокрутки и закрываем
        latestScrollUpdateStatePosition.current = 0
        return false
      }

      // Если ни одно из условий не выполнено, закрываем
      return false
    })

    latestScrollPosition.current = scrollTop
  }

  useEffect(() => {
    const rootElement = document.querySelector(`#${ROOT_ID}`)

    rootElement?.addEventListener('scroll', handleScrollBodyElement)

    if (rootElement) {
      latestScrollPosition.current = rootElement.scrollTop
    }

    return () => {
      rootElement?.removeEventListener('scroll', handleScrollBodyElement) // Fix the cleanup function
    }
  }, [])

  return (
    <StickyWrapper ref={ref} $isTouchLayout={isTouchLayout} $isOpen={isOpen}>
      <StickyWrapperPadding>
        <BreadcrumbsWrapper>
          <GlobalPageBreadcrumbs />
        </BreadcrumbsWrapper>
        {isTouch && <SearchInput />}
        {isTouchLayout && (
          <FiltersSortWrapper>
            <SearchScenarioHotelsSorting />
            <SearchScenarioFilters />
          </FiltersSortWrapper>
        )}
      </StickyWrapperPadding>
    </StickyWrapper>
  )
}

export const SearchScenario = () => {
  const router = useRouter()
  const pathname = router.asPath.split('?')[0]
  const isTouch = useDeviceDetection('touch')
  const windowWidth = useWindowSize()
  const isTouchLayout = isTouch && windowWidth <= TOUCH_VERSION_POINT

  useEffect(() => {
    services.pages.global.scenario.search.load()
  }, [pathname])

  useEffect(() => {
    return () => {
      if (isTouch) {
        store().pages.global.search.map.setModalOpen(false)
      }
    }
  }, [])

  return (
    <StyledContainer>
      <StickyContent />
      <Wrapper $isTouch={isTouch}>
        {!isTouchLayout && (
          <FiltersWrapper>
            <SearchScenarioFilters />
          </FiltersWrapper>
        )}
        <HotelsWrapper $isTouch={isTouch}>
          <SearchScenarioHotels />
        </HotelsWrapper>
        <SearchScenarioMap />
        <EmailSubscription />
        <RefreshPopup delay={ENVIRONMENT.SEARCH_REFRESH_DELAY} />
      </Wrapper>
      {isTouchLayout && <MobileFiltersTrigger />}
    </StyledContainer>
  )
}
