import {memo} from 'react'
import useTranslation from 'next-translate/useTranslation'
import {store} from '@store/index'
import styled from '@emotion/styled'
import {Button} from '@ui/button'
import {Text} from '@components/ui'
import {Icon} from '@ui/icon'
import {TRANSITION_STYLES} from '@/library/constants/css'

const ChildrenChip = styled.div`
  padding: 5px 5px 5px 12px;
  border: 1px solid ${({theme}) => theme.palette.borderTertiary};
  background-color: ${({theme}) => theme.palette.backgroundQuaternary};
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
`

const ChildrenChipLabel = styled(Text)`
  color: ${({theme}) => theme.palette.textSecondary};
`

const ChildrenChipButton = styled(Button)`
  padding: 0;
`

const ChildrenChipIcon = styled(Icon)`
  transition: color ${TRANSITION_STYLES};

  &:hover {
    color: ${({theme}) => theme.palette.textAccentPrimary};
  }
`

interface Props {
  id: number
  age: number
}

export const ChildrenChipsItem = memo(({id, age}: Props) => {
  const {t} = useTranslation('features.search-input')

  const handleRemoveChild = (id: number) => {
    store().search.guests.children.removeChild(id)
  }

  return (
    <ChildrenChip>
      <ChildrenChipLabel size="S">
        {t('guests.children.age', {count: age})}
      </ChildrenChipLabel>
      <ChildrenChipButton variant="text" onClick={() => handleRemoveChild(id)}>
        <ChildrenChipIcon name="close" width={24} height={24} />
      </ChildrenChipButton>
    </ChildrenChip>
  )
})

ChildrenChipsItem.displayName = 'ChildrenChipsItem'
