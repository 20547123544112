import {DEFAULT_COUNTRY} from '@/library/components/features/citizenship-input/lib/constants'
import {store} from '@/library/store'
import {addPlus} from '@/library/utils/phone-numbers'
import {concat, map, times} from 'ramda'
import type {Translate} from 'next-translate'
import {BookingGuestType} from '@/library/api/gql/generate-types'
import type {
  BookingDataGuest,
  BookingFormGuest,
  CreateFormGuestContext,
  CreateGuestContext,
} from './lib/types'

export class BookingGuestBuilder {
  public createData = ({
    t,
    type,
    age,
    profile,
  }: CreateGuestContext): BookingDataGuest => ({
    age,
    citizenship: profile?.citizenship
      ? t(profile?.citizenship)
      : t(DEFAULT_COUNTRY),
    email: profile?.email || '',
    first_name: profile?.firstname || '',
    last_name: profile?.lastname || '',
    phone: profile?.phone ? addPlus(profile?.phone) : '',
    type,
  })

  public createForm = ({
    profile,
    type,
  }: CreateFormGuestContext): BookingFormGuest => ({
    first_name: profile?.firstname || '',
    last_name: profile?.lastname || '',
    phone: profile?.phone ? addPlus(profile.phone) : '',
    email: profile?.email || '',
    hasRequiredFields:
      type === BookingGuestType.Main || type === BookingGuestType.Child,
    isMain: type === BookingGuestType.Main,
  })

  public mapDataToForm = (guest: BookingDataGuest): BookingFormGuest => ({
    first_name: guest.first_name || '',
    last_name: guest.last_name || '',
    phone: guest.phone || '',
    email: guest.email || '',
    hasRequiredFields:
      guest.type === BookingGuestType.Main ||
      guest.type === BookingGuestType.Child,
    isMain: guest.type === BookingGuestType.Main,
  })

  public buildForm = (adults: number, children: number[]) => {
    const {profile} = store().user
    const mainGuest = this.createForm({profile, type: BookingGuestType.Main})
    const guestsWithoutMain = adults - 1
    const adultGuests = times(
      () => this.createForm({type: BookingGuestType.Adult}),
      guestsWithoutMain,
    )
    const childGuests = map(
      () => this.createForm({type: BookingGuestType.Child}),
      children,
    )

    return concat([mainGuest], concat(adultGuests, childGuests))
  }

  public buildBookingData = (
    t: Translate,
    adults: number,
    children: number[],
  ): BookingDataGuest[] => {
    const {profile} = store().user
    const mainGuest = this.createData({
      t,
      type: BookingGuestType.Main,
      profile,
    })
    const guestsWithoutMain = adults - 1

    const adultGuests = times(
      () => this.createData({t, type: BookingGuestType.Adult}),
      guestsWithoutMain,
    )
    const childGuests = map(
      (age) => this.createData({t, type: BookingGuestType.Child, age}),
      children,
    )

    return concat([mainGuest], concat(adultGuests, childGuests))
  }
}
