import {store} from '@store/index'
import {services} from '@services/index'
import {useState} from 'react'
import {userAgentFromString} from 'next/server'
import type {UserAgent} from '@store/utilities'
import {useLocalObservable} from 'mobx-react-lite'
import {useWindowSize} from '@hooks/use-window-size'
import {useIsomorphicLayoutEffect} from './use-isomorphic-layout-effect'

type DeviceDetection = 'mobile' | 'tablet' | 'touch'

const detectDevice = (
  userAgent: UserAgent,
  device: DeviceDetection,
  windowSize: number,
) => {
  const {MOBILE_MAX_SCREEN_WIDTH, TABLET_MAX_SCREEN_WIDTH} =
    services.common.deviceDetection

  const isMobileWindowSize = windowSize <= MOBILE_MAX_SCREEN_WIDTH
  const isTabletWindowSize =
    windowSize > MOBILE_MAX_SCREEN_WIDTH &&
    windowSize <= TABLET_MAX_SCREEN_WIDTH

  if (device === 'touch') {
    return (
      services.common.deviceDetection.isTouch(userAgent) ||
      isTabletWindowSize ||
      isMobileWindowSize
    )
  }

  if (device === 'mobile') {
    return (
      services.common.deviceDetection.isMobile(userAgent) || isMobileWindowSize
    )
  }

  if (device === 'tablet') {
    return (
      services.common.deviceDetection.isTablet(userAgent) || isTabletWindowSize
    )
  }

  return false
}

const selectUserAgent = () => ({
  get userAgent() {
    return store().utilities.userAgent
  },
})

export const useDeviceDetection = (device: DeviceDetection): boolean => {
  const {userAgent} = useLocalObservable(selectUserAgent)

  const windowSize = useWindowSize()

  const [isDetection, setIsDetection] = useState(
    detectDevice(userAgent, device, windowSize),
  )

  useIsomorphicLayoutEffect(() => {
    const handleResize = () => {
      setIsDetection(
        detectDevice(
          userAgentFromString(window.navigator.userAgent),
          device,
          windowSize,
        ),
      )
      store().utilities.setUserAgent(
        userAgentFromString(window.navigator.userAgent),
      )
    }

    window.addEventListener('resize', handleResize)

    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [windowSize])

  return isDetection
}
