import {MEDIA_DEVICE_QUERY} from '@/assets/theme/media'
import styled from '@emotion/styled'
import {Skeleton} from 'antd'

const Wrapper = styled.div`
  border: 1px solid ${({theme}) => theme.palette.borderTertiary};
  border-radius: 12px;
`

const Header = styled.div`
  display: flex;

  @media ${MEDIA_DEVICE_QUERY.MOBILE_VERY_LARGE} {
    flex-direction: column;
  }
`

const Body = styled.div`
  display: flex;
  gap: 10px;
`

const StyledSkeletonImage = styled(Skeleton.Image)`
  min-width: 273px;
  min-height: 188px;
  border-radius: 12px;

  @media ${MEDIA_DEVICE_QUERY.MOBILE_VERY_LARGE} {
    min-height: 260px;
    min-width: 100%;
  }
`

const HeaderEnd = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  flex-direction: column;
  padding: 10px 23px;
  width: calc(100% - 273px);

  & > span {
    width: 100%;
  }

  @media ${MEDIA_DEVICE_QUERY.MOBILE_VERY_LARGE} {
    width: 100%;
    padding: 10px;
  }
`

const BodyContent = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 23px 21px;

  .middle {
    display: flex;
    gap: 10px;
  }

  @media ${MEDIA_DEVICE_QUERY.MOBILE_VERY_LARGE} {
    width: 100%;
    padding: 10px;
    grid-template-columns: auto;

    grid-template-areas:
      'info payment'
      'book book';

    .info {
      grid-area: info;
    }

    .payment {
      grid-area: payment;
    }

    .book {
      grid-area: book;
    }
  }

  @media ${MEDIA_DEVICE_QUERY.MOBILE_LARGE} {
    display: flex;
    flex-direction: column;
  }
`

const StyledSkeletonButton = styled(Skeleton.Button)`
  max-width: 100%;
`

const HeaderEndName = styled(StyledSkeletonButton)`
  max-height: 15px;
`

const HeaderEndArea = styled(StyledSkeletonButton)`
  max-height: 10px;
`

const HeaderEndBeds = styled(StyledSkeletonButton)`
  max-height: 12px;
  margin-bottom: 10px;
`

const HeaderEndFacilities = styled(StyledSkeletonButton)`
  max-height: 10px;

  .ant-skeleton-button {
    width: 150px !important;
  }
`

const BodyRoomLeftover = styled(HeaderEndArea)`
  span {
    width: 140px !important;
  }
`

const BookButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
`

const BookButton = styled(StyledSkeletonButton)`
  max-height: 49px;
  max-width: 170px;
  align-self: end;

  @media ${MEDIA_DEVICE_QUERY.MOBILE_VERY_LARGE} {
    grid-area: book;
    max-width: 100%;
  }
`

const BodyCancellation = styled(HeaderEndArea)``

const BodyMeal = styled(HeaderEndArea)``

const BodyPaymentType = styled(HeaderEndArea)``

const BodyPrice = styled(HeaderEndName)``

const BodyPriceDetails = styled(HeaderEndArea)``

const BodyBookingDetails = styled(BodyRoomLeftover)``

export const HotelScenarioRatesCardsItemSkeleton = () => {
  return (
    <Wrapper>
      <Header>
        <StyledSkeletonImage active />
        <HeaderEnd>
          <span>
            <HeaderEndName active block />
            <HeaderEndArea active />
          </span>
          <span>
            <HeaderEndBeds active block />
            <HeaderEndBeds active block />
            <HeaderEndFacilities active />
          </span>
        </HeaderEnd>
      </Header>
      <Body>
        <BodyContent>
          <span className="info">
            <BodyRoomLeftover active />
            <BodyMeal active block />
            <BodyCancellation active block />
            <BodyPaymentType active block />
          </span>
          <span className="payment">
            <BodyPrice active block />
            <BodyBookingDetails active />
            <BodyPriceDetails active block />
            <span className="middle">
              <HeaderEndArea active />
              <HeaderEndArea active />
            </span>
          </span>
          <span className="book">
            <BookButtonWrapper>
              <BookButton active block />
            </BookButtonWrapper>
          </span>
        </BodyContent>
      </Body>
    </Wrapper>
  )
}
