import {$gqlClient} from '@api/gql/client'
import {
  GetDestinationAutoCompleteDocument,
  type GetDestinationAutoCompleteQuery,
  type GetDestinationAutoCompleteQueryVariables,
  GetPopularCityDestinationsDocument,
  type GetPopularCityDestinationsQuery,
  type GetPopularCityDestinationsQueryVariables,
} from '@api/gql/generate-types'
import {path} from 'ramda'
import {clientHeaders} from '@utils/http/headers'

type GetPopularCityDestinationContext = {
  variables?: GetPopularCityDestinationsQueryVariables
  headers?: Headers
}

/**
 * Получает популярные направления по городам
 */
const getPopularCityDestinations = ({
  variables,
  headers,
}: GetPopularCityDestinationContext) =>
  $gqlClient()
    .query<
      GetPopularCityDestinationsQuery,
      GetPopularCityDestinationsQueryVariables
    >({
      query: GetPopularCityDestinationsDocument,
      variables,
      fetchPolicy: 'no-cache',
      context: {
        headers: headers || clientHeaders(),
      },
    })
    .then(
      path<GetPopularCityDestinationsQuery['popularDestinations']>([
        'data',
        'popularDestinations',
      ]),
    )

type GetDestinationAutoCompleteContext = {
  variables: GetDestinationAutoCompleteQueryVariables
  headers?: Headers
}

/**
 * Получает объекты для поиска, по названию которое передано в variables -> url
 */
const getDestinationAutoComplete = ({
  variables,
  headers,
}: GetDestinationAutoCompleteContext) =>
  $gqlClient()
    .query<
      GetDestinationAutoCompleteQuery,
      GetDestinationAutoCompleteQueryVariables
    >({
      query: GetDestinationAutoCompleteDocument,
      variables,
      fetchPolicy: 'no-cache',
      context: {
        headers: headers || clientHeaders(),
      },
    })
    .then(
      path<GetDestinationAutoCompleteQuery['destinationAutocomplete']>([
        'data',
        'destinationAutocomplete',
      ]),
    )

export const destinationApi = {
  getPopularCityDestinations,
  getDestinationAutoComplete,
}
