import type {PollingConstructorPayload} from '@utils/long-polling'
import {store} from '@store/index'
import {api} from '@api/index'
import {errorService} from '@services/common/error'

export class PaymentCheckServicePollingStatus {
  private readonly duration = 3000

  private get state() {
    return store().pages.payment.check
  }

  public getPollingPayload(): PollingConstructorPayload {
    return {
      duration: this.duration,
      abortController: new AbortController(),
      mergeMap: this.mergeMap.bind(this),
      takeWhile: this.takeWhile.bind(this),
      finalize: this.finalize.bind(this),
    }
  }

  public async mergeMap(): Promise<void> {
    if (!this.state.transactionId) {
      throw new Error('transactionId must be provided')
    }

    api.booking
      .getBookingPaymentStatus({
        variables: {
          transactionId: this.state.transactionId,
          bookingAccessToken: this.state.bookingAccessToken,
        },
      })
      .then((result) => {
        if (result && result.status !== this.state.paymentStatus?.status) {
          this.state.setPaymentStatus(result)
        }
        if (result?.booking) {
          this.state.setBookingId(result.booking.id)
          this.state.setBookingAccessToken(result.booking.access_token)
        }
      })
      .catch((error) => {
        const firstError = errorService.getFirstErrorText(error)
        if (firstError) {
          this.state.setError(firstError)
        }
      })
  }

  private takeWhile(): boolean {
    return true
  }

  private finalize(): void {}
}
