import {type FC, type PropsWithChildren} from 'react'
import {ThemeProvider as Provider} from '@emotion/react'
import {DarkTheme} from '@assets/theme'
import {GlobalStyles} from '@styles/global-styles'

const ThemeProvider: FC<PropsWithChildren> = ({children}) => {
  const theme = DarkTheme

  return (
    <Provider theme={theme}>
      <GlobalStyles />
      {children}
    </Provider>
  )
}

export default ThemeProvider
