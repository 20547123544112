import {makeAutoObservable} from 'mobx'
import {
  type GetHotelQuery,
  type GetHotelRatesFiltersQuery,
  ProviderRatesSearchingTypeEnum,
} from '@api/gql/generate-types'
import {CGlobalPageStoreHotelReviews} from '@store/pages/global/hotel/reviews'
import {CGlobalPageStoreHotelRates} from '@store/pages/global/hotel/rates'
import {CGlobalPageStoreHotelMap} from '@store/pages/global/hotel/map'

type Hotel = NonNullable<GetHotelQuery>['hotel'] | null

type HotelTypes =
  | GetHotelRatesFiltersQuery['hotelRatesFilters']['hotelTypes']
  | null

export class CGlobalPageStoreHotel {
  constructor() {
    makeAutoObservable(this)
  }

  public readonly reviews: CGlobalPageStoreHotelReviews =
    new CGlobalPageStoreHotelReviews()

  public readonly rates: CGlobalPageStoreHotelRates =
    new CGlobalPageStoreHotelRates()

  public readonly map: CGlobalPageStoreHotelMap = new CGlobalPageStoreHotelMap()

  private _data: Hotel = null

  public get data(): Hotel {
    return this._data
  }

  public setData(data: Hotel): void {
    this._data = data
  }

  private _similarHotels: SimilarHotels | null = null

  public get similarHotels(): SimilarHotels | null {
    return this._similarHotels
  }

  public setSimilarHotels(similarHotels: SimilarHotels | null) {
    this._similarHotels = similarHotels
  }

  private _isSimilarHotelsLoading = false

  public get isSimilarHotelsLoading() {
    return this._isSimilarHotelsLoading
  }

  public setIsSimilarHotelsLoading(flag: boolean) {
    this._isSimilarHotelsLoading = flag
  }

  private _searchingType = ProviderRatesSearchingTypeEnum.Geo

  public get searchingType(): ProviderRatesSearchingTypeEnum {
    return this._searchingType
  }

  private _hotelTypes: HotelTypes = null

  public get hotelTypes(): HotelTypes {
    return this._hotelTypes
  }

  public setSearchingType(searchingType: ProviderRatesSearchingTypeEnum): void {
    this._searchingType = searchingType
  }

  public setHotelTypes(
    types: GetHotelRatesFiltersQuery['hotelRatesFilters']['hotelTypes'],
  ): void {
    this._hotelTypes = types
  }

  private _isOpenShareModal = false

  public get isOpenShareModal(): boolean {
    return this._isOpenShareModal
  }

  public setIsOpenShareModal(flag: boolean): void {
    this._isOpenShareModal = flag
  }

  private _isOpenNavigationModal = false

  public get isOpenNavigationModal(): boolean {
    return this._isOpenNavigationModal
  }

  public setIsOpenNavigationModal(flag: boolean): void {
    this._isOpenNavigationModal = flag
  }
}
