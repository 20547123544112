import {makeAutoObservable} from 'mobx'
import type {Option} from '@ui/select'
import {RatePaymentTypeEnum, type RoomRate} from '@api/gql/generate-types'
import {isNull} from '@utils/guards/types'
import {isEmpty} from 'ramda'

type PaymentTypeFilter = Option<RatePaymentTypeEnum | null> | null

type BedType = Option<string | null> | null

type MealIncludes = Option<boolean | null> | null

type FreeCancellation = Option<boolean | null> | null

export class CGlobalPageStoreHotelRatesFilters {
  constructor() {
    makeAutoObservable(this)
  }

  private _paymentTypes: PaymentTypeFilter = null

  public get paymentTypes(): PaymentTypeFilter {
    return this._paymentTypes
  }

  public setPaymentTypes(paymentTypes: PaymentTypeFilter) {
    this._paymentTypes = paymentTypes
  }

  private _bedType: BedType = null

  public get bedType(): BedType {
    return this._bedType
  }

  public setBedType(bedType: BedType) {
    this._bedType = bedType
  }

  private _freeCancellation: FreeCancellation = null

  public get freeCancellation(): FreeCancellation {
    return this._freeCancellation
  }

  public setFreeCancellation(freeCancellation: FreeCancellation) {
    this._freeCancellation = freeCancellation
  }

  private _mealIncludes: MealIncludes = null

  public get mealIncludes(): MealIncludes {
    return this._mealIncludes
  }

  public setMealIncludes(mealIncludes: MealIncludes) {
    this._mealIncludes = mealIncludes
  }

  public hasExistFilters(): boolean {
    if (this._paymentTypes && !isNull(this._paymentTypes.value)) {
      return true
    }

    if (this._mealIncludes && !isNull(this._mealIncludes.value)) {
      return true
    }

    if (this._freeCancellation && !isNull(this._freeCancellation.value)) {
      return true
    }

    if (this._bedType && !isNull(this._bedType.value)) {
      return true
    }

    return false
  }

  public getFilteredData(
    data: HotelScenarioProviders | null,
  ): HotelScenarioProviders | null {
    if (!data) {
      return null
    }

    return data.map((item) => ({
      ...item,
      roomRates: item.roomRates
        .map((room) => ({
          ...room,
          rates: room.rates
            .map((rate) => ({
              ...rate,
              paymentTypes: rate.paymentTypes.filter((paymentType) => {
                return (
                  this.matchesPaymentType(paymentType) &&
                  this.matchesFreeCancellation(paymentType)
                )
              }),
            }))
            .filter((rate) => {
              return (
                this.matchesMealIncludes(rate) && !isEmpty(rate.paymentTypes)
              )
            }),
        }))
        .filter((room) => {
          return this.matchesBedType(room.beds) && !isEmpty(room.rates)
        }),
    }))
  }

  private matchesMealIncludes(rate: HotelScenarioRate): boolean {
    if (!this._mealIncludes) {
      return true
    }

    if (isNull(this._mealIncludes.value)) {
      return true
    }

    return this._mealIncludes.value === rate.meal.isIncluded
  }

  private matchesBedType(beds: RoomRate['beds']): boolean {
    if (!this._bedType) {
      return true
    }

    if (isNull(this._bedType.value)) {
      return true
    }

    return beds.some((bed) => bed?.name === this._bedType?.value)
  }

  private matchesPaymentType(
    paymentType: HotelScenarioRatePaymentType,
  ): boolean {
    if (!this._paymentTypes) {
      return true
    }

    if (isNull(this._paymentTypes.value)) {
      return true
    }

    return paymentType.type === this._paymentTypes.value
  }

  private matchesFreeCancellation(
    paymentType: HotelScenarioRatePaymentType,
  ): boolean {
    if (!this._freeCancellation) {
      return true
    }

    if (isNull(this._freeCancellation.value)) {
      return true
    }

    return Boolean(paymentType.cancellationPolicy.freeCancellationBefore)
  }

  public reset() {
    this.setBedType(null)
    this.setMealIncludes(null)
    this.setPaymentTypes(null)
    this.setFreeCancellation(null)
  }
}
