import {makeAutoObservable} from 'mobx'
import {type LoginStoreInitialStateType} from '../types'
import {LOGIN_STORE_INITIAL_STATE} from '../constants'

export class CLoginStore {
  constructor() {
    makeAutoObservable(this)
  }

  // Текст ошибки
  private _error: LoginStoreInitialStateType['error'] =
    LOGIN_STORE_INITIAL_STATE.error

  public get error(): LoginStoreInitialStateType['error'] {
    return this._error
  }

  public setError(error: LoginStoreInitialStateType['error']) {
    this._error = error
  }
}
