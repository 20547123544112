import {DeviceDetectionService} from 'library/services/common/device-detection'
import {GlobalPropsService} from '@services/next-context/global-props'
import {FingerprintService} from '@services/common/fingerprint'
import {AuthorizationService} from '@services/common/authorization'
import {GlobalPageService} from '@services/pages/global'
import {UserService} from '@services/common/user'
import {UtilitiesService} from '@services/common/utilities'
import {HomePageService} from '@services/pages/home'
import {SearchService} from '@services/components/search'
import {ProfileBalanceService} from '@services/pages/profile/balance'
import {ProfileBookingsService} from '@services/pages/profile/bookings'
import {ProfileFavoritesService} from '@services/pages/profile/favorites'
import {ProfilePasswordService} from '@services/pages/profile/password'
import {ProfileReviewsService} from '@services/pages/profile/reviews'
import {ProfileSettingsService} from '@services/pages/profile/settings'
import {AffiliateMainService} from '@services/pages/affiliate/main'
import {InternalErrorPageService} from '@services/pages/internal-error'
import {NotFoundPageService} from '@services/pages/not-found'
import {AffiliateInvitesService} from '@services/pages/affiliate/invites'
import {AffiliateReferralsService} from '@services/pages/affiliate/referrals'
import {AffiliateStatisticsService} from '@services/pages/affiliate/statistics'
import {AffiliateWidgetsService} from '@services/pages/affiliate/widgets'
import {AffiliateWithdrawService} from '@services/pages/affiliate/withdraw'
import {AuthorizationEmailConfirmService} from '@services/pages/authorization/email-confirm'
import {ShareService} from '@services/common/share'
import {EmailSubscriptionService} from '@services/common/email-subscription'
import {BookingPageService} from '@services/pages/booking'
import {SubscriptionPageService} from '@services/pages/subscription'
import {TemporarySessionService} from '@services/pages/temporary-session'
import {SupportService} from '@services/pages/support'
import {BitrixService} from '@services/common/bitrix'
import {AnalyticsService} from '@services/common/analytics'
import {BookingPaymentModalService} from 'library/services/components/booking-payment-modal'
import {PaymentCheckService} from '@services/pages/payment/check'
import {AdmitAdService} from '@services/common/admit-ad'
import {YanderMetricService} from './common/yandex-metric'
import {MobileAppPageService} from './pages/mobile-app'
import {MapNavigationService} from './common/map-navigation'
import {AboutService} from './pages/about'
import {PartnerService} from './pages/partner'

export const services = {
  common: {
    deviceDetection: new DeviceDetectionService(),
    fingerprint: new FingerprintService(),
    authorization: new AuthorizationService(),
    user: new UserService(),
    utilities: new UtilitiesService(),
    emailSubscription: new EmailSubscriptionService(),
    share: new ShareService(),
    mapNavigation: new MapNavigationService(),
    bitrix: new BitrixService(),
    analytics: new AnalyticsService(),
    yandexMetric: new YanderMetricService(),
    admitAd: new AdmitAdService(),
  },
  components: {
    search: new SearchService(),
    bookingPaymentModal: new BookingPaymentModalService(),
  },
  nextContext: {
    globalProps: new GlobalPropsService(),
  },
  pages: {
    global: new GlobalPageService(),
    home: new HomePageService(),
    about: new AboutService(),
    partner: new PartnerService(),
    notFound: new NotFoundPageService(),
    internalError: new InternalErrorPageService(),
    authorization: {
      emailConfirm: new AuthorizationEmailConfirmService(),
    },
    booking: new BookingPageService(),
    profile: {
      balance: new ProfileBalanceService(),
      bookings: new ProfileBookingsService(),
      favorites: new ProfileFavoritesService(),
      password: new ProfilePasswordService(),
      reviews: new ProfileReviewsService(),
      settings: new ProfileSettingsService(),
    },
    affiliate: {
      invites: new AffiliateInvitesService(),
      main: new AffiliateMainService(),
      referrals: new AffiliateReferralsService(),
      statistics: new AffiliateStatisticsService(),
      widgets: new AffiliateWidgetsService(),
      withdraw: new AffiliateWithdrawService(),
    },
    subscription: new SubscriptionPageService(),
    temporarySession: new TemporarySessionService(),
    support: new SupportService(),
    mobileApp: new MobileAppPageService(),
    payment: {
      check: new PaymentCheckService(),
    },
  },
}
