import styled from '@emotion/styled'
import {Button, Link} from '@ui/index'
import {TRANSITION_STYLES} from '@/library/constants/css'
import {Icon} from '@/library/components/ui/icon'
import useTranslation from 'next-translate/useTranslation'
import {ENVIRONMENT} from '@/library/utils/guards/environment'
import {Routes} from '@/types/enums/routes'
import {HorizontalDivider} from '@/library/components/ui/divider'
import {type FC, type HTMLAttributes} from 'react'
import {services} from '@services/index'
import {useRouter} from 'next/router'
import {MailLink} from '../mail-link'

const LinkList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 15px;
`

const LinkListItem = styled.li``

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border: 1px solid ${({theme}) => theme.palette.borderTertiary};
  border-radius: 12px;
`

const StyledHorizontalDivider = styled(HorizontalDivider)`
  margin: 5px auto;
`

const StyledLink = styled(Link)`
  width: fit-content;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 16px;

  svg {
    transition: color ${TRANSITION_STYLES};
  }

  &:hover {
    svg {
      color: ${({theme}) => theme.palette.textAccentPrimary};
    }
  }
`

const StyledButton = styled(Button)`
  padding: 6px 14px;
  font-weight: 500;
`

interface Props extends HTMLAttributes<HTMLUListElement> {
  onClickToSupport?: () => void
}

export const HelpLinks: FC<Props> = ({onClickToSupport, ...properties}) => {
  const router = useRouter()

  const {t} = useTranslation('features.header')

  const handleToSupport = () => {
    if (router.pathname === Routes.SUPPORT) {
      services.common.bitrix.showBitrixLiveChat()
    } else {
      router.push(Routes.SUPPORT)
    }

    onClickToSupport?.()
  }

  return (
    <LinkList {...properties}>
      <StyledButton variant="primary" onClick={handleToSupport}>
        {t('help.write_to_support')}
      </StyledButton>
      <StyledHorizontalDivider />
      <LinkListItem>
        <StyledLink href={ENVIRONMENT.KNOWLEDGE_BASE} target="_blank">
          <IconWrapper>
            <Icon name="knowledge_base" width={14} height={14} />
          </IconWrapper>
          {t('help.knowledge_base')}
        </StyledLink>
      </LinkListItem>
      <StyledHorizontalDivider />
      <LinkListItem>
        <StyledLink href={ENVIRONMENT.TG} target="_blank">
          <IconWrapper>
            <Icon name="telegram" width={20} height={20} />
          </IconWrapper>
          {t('help.telegram')}
        </StyledLink>
      </LinkListItem>
      <LinkListItem>
        <StyledLink href={ENVIRONMENT.VK} target="_blank">
          <IconWrapper>
            <Icon name="vk" width={18} height={10} />
          </IconWrapper>
          {t('help.vk')}
        </StyledLink>
      </LinkListItem>
      <LinkListItem>
        <StyledLink href={Routes.SUPPORT} target="_blank">
          <IconWrapper>
            <Icon name="feedback" width={16} height={16} />
          </IconWrapper>
          {t('help.feedback')}
        </StyledLink>
      </LinkListItem>
      <StyledHorizontalDivider />
      <LinkListItem>
        <StyledLink href={`tel:${ENVIRONMENT.ZHILIBYLI_PHONE}`} target="_blank">
          <IconWrapper>
            <Icon name="phone" width={17} height={17} />
          </IconWrapper>
          {ENVIRONMENT.ZHILIBYLI_PHONE}
        </StyledLink>
      </LinkListItem>
      <MailLink />
    </LinkList>
  )
}
