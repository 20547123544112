import styled from '@emotion/styled'
import {Button} from '@ui/button'
import useTranslation from 'next-translate/useTranslation'
import {observer} from 'mobx-react'
import {store} from '@store/index'
import {useEffect} from 'react'
import dynamic from 'next/dynamic'
import {Portal} from '@components/layouts'
import {css} from '@emotion/react'
import {LOADING_STYLES} from '@/library/constants/css'
import {CircularLoader} from '@/library/components/ui'
import {MEDIA_DEVICE_QUERY} from '@/assets/theme/media'
import {format} from 'date-fns'
import {DateFormatPattern} from '@/types/enums/date-format-patterns'
import {Icon} from '@ui/icon'

const SearchInputMobileDestinationPageModal = dynamic(() =>
  import('./pages/destination').then(
    (result) => result.SearchInputMobileDestinationPageModal,
  ),
)

const SearchInputMobileGuestsPageModal = dynamic(() =>
  import('./pages/guests').then(
    (result) => result.SearchInputMobileGuestsPageModal,
  ),
)

const SearchInputMobileMainPageModal = dynamic(() =>
  import('./pages/main').then(
    (result) => result.SearchInputMobileMainPageModal,
  ),
)

const SearchInputMobileDatePageModal = dynamic(() =>
  import('./pages/date').then(
    (result) => result.SearchInputMobileDatePageModal,
  ),
)

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`

const InputButton = styled(Button)<{isLoading: boolean}>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: start;
  font-weight: 500;
  height: 56px;
  position: relative;
  padding: 0;

  ${({isLoading}) =>
    isLoading &&
    css`
      ${LOADING_STYLES}
    `}
`

const InputButtonTextWrapper = styled.div`
  padding-left: 17px;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  height: 100%;

  @media ${MEDIA_DEVICE_QUERY.MOBILE_MEDIUM} {
    padding-left: 8px;
  }
`

const InputButtonWithIcon = styled.div`
  background-color: ${({theme}) => theme.palette.backgroundAccentPrimary};
  width: 40px;
  height: 40px;
  margin: 8px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    color: ${({theme}) => theme.palette.textQuaternary} !important;
  }
`

const StyledCircularLoader = styled(CircularLoader)``

const InputTitle = styled.div`
  color: ${({theme}) => theme.palette.textPrimary};
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0.02em;

  max-width: 950px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media ${MEDIA_DEVICE_QUERY.LAPTOP_LARGE} {
    max-width: 550px;
  }
  @media ${MEDIA_DEVICE_QUERY.LAPTOP} {
    max-width: 360px;
  }
  @media ${MEDIA_DEVICE_QUERY.TABLET} {
    max-width: 260px;
  }
  @media ${MEDIA_DEVICE_QUERY.MOBILE_MEDIUM} {
    max-width: 215px;
  }
  @media ${MEDIA_DEVICE_QUERY.MOBILE_SMALL} {
    max-width: 200px;
  }
`

const SubTitlesWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  column-gap: 10px;
  width: 100%;

  @media ${MEDIA_DEVICE_QUERY.MOBILE_LARGE} {
    flex-wrap: wrap;
  }

  @media ${MEDIA_DEVICE_QUERY.MOBILE_MEDIUM} {
    column-gap: 4px;
  }
`

const Circle = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background-color: ${({theme}) => theme.palette.backgroundSecondary};

  @media ${MEDIA_DEVICE_QUERY.MOBILE_MEDIUM} {
    font-size: 10px;
  }
`

const InputSubTitle = styled.div`
  color: ${({theme}) => theme.palette.textSecondary};
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0.02em;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  max-width: 300px;

  @media (max-width: 420px) {
    font-size: 12px;
  }
`

const SearchInput = observer(() => {
  const {t} = useTranslation('features.search-input')
  const {destination, guests, dateFrom, dateTo, isStartSearch} = store().search
  const {inputValue: destinationValue} = destination

  const handleOpenInput = () => {
    if (isStartSearch) {
      return
    }

    store().search.setIsOpenMobileMainPage(true)
  }

  const buildDateTitle = () => {
    if (dateFrom.value && !dateTo.value) {
      return `${format(dateFrom.value, DateFormatPattern.DATE_PICKER_OUTPUT_MIDDLE)} - ${t('date_to.title')}`
    }

    if (dateTo.value && !dateFrom.value) {
      return `${t('date_from.title')} - ${format(dateTo.value, DateFormatPattern.DATE_PICKER_OUTPUT_MIDDLE)}`
    }

    if (dateFrom.value && dateTo.value) {
      return `${format(dateFrom.value, DateFormatPattern.DATE_PICKER_OUTPUT_MIDDLE)} - ${format(dateTo.value, DateFormatPattern.DATE_PICKER_OUTPUT_MIDDLE)}`
    }

    return t('dates.subtitle')
  }

  return (
    <InputButton
      variant="secondary"
      onClick={handleOpenInput}
      isLoading={isStartSearch}
    >
      <InputButtonTextWrapper>
        <InputTitle>
          {destinationValue || t('destination.subtitle_mobile')}
        </InputTitle>
        <SubTitlesWrapper>
          <InputSubTitle>{buildDateTitle()}</InputSubTitle>
          <Circle />
          <InputSubTitle>
            {guests.amount > 0
              ? t('guests.amount', {count: guests.amount})
              : t('guests.subtitle')}
          </InputSubTitle>
        </SubTitlesWrapper>
      </InputButtonTextWrapper>
      <InputButtonWithIcon>
        {isStartSearch ? (
          <StyledCircularLoader size={24} />
        ) : (
          <Icon name="search" width={24} height={24} />
        )}
      </InputButtonWithIcon>
    </InputButton>
  )
})

export const ModalPages = () => {
  return (
    <>
      <Portal>
        <SearchInputMobileDestinationPageModal />
      </Portal>
      <Portal>
        <SearchInputMobileDestinationPageModal />
      </Portal>
      <Portal>
        <SearchInputMobileDatePageModal />
      </Portal>
      <Portal>
        <SearchInputMobileGuestsPageModal />
      </Portal>
      <Portal>
        <SearchInputMobileMainPageModal />
      </Portal>
    </>
  )
}

export const MobileSearchInput = observer(() => {
  useEffect(() => {
    return () => {
      store().search.setIsOpenMobileMainPage(false)
    }
  }, [])

  return (
    <Wrapper>
      <SearchInput />
      <ModalPages />
    </Wrapper>
  )
})
