import {LongPolling} from '@utils/long-polling'
import {api} from '@api/index'
import {isBoolean} from '@utils/guards/types'
import {isEmpty} from 'ramda'
import type {ClientRatesSearchCreateResponse} from '@api/rest/api-scheme'
import {GlobalPageSearchMapService} from '@services/pages/global/scenario/search/map'
import type {MapPoints} from '@ui/yandex-map'
import {GlobalPageSearchFetchQueryBuilder} from '@services/pages/global/scenario/search/fetch-query-builder'
import type {GlobalPageHotelMapService} from '@services/pages/global/scenario/hotel/map'
import type {GlobalPageHotelFetchQueryBuilder} from '@services/pages/global/scenario/hotel/fetch-query-builder'
import type {HotelItem} from '@/library/api/gql/generate-types'
import {store} from '@store/index'
import {services} from '@services/index'
import {ProviderRatesSearchingTypeEnum} from '@api/gql/generate-types'
import {ENVIRONMENT} from '@utils/guards/environment'

export type MapPointData =
  ClientRatesSearchCreateResponse['data']['items'][number] & {
    email?: HotelItem['email']
    phone?: HotelItem['phone']
  }

export class GlobalPageMapPollingPointsService {
  private readonly durationPolling = 1000

  // eslint-disable-next-line no-useless-constructor
  constructor(
    private readonly mapService:
      | GlobalPageSearchMapService
      | GlobalPageHotelMapService,
    private readonly fetchBuilder:
      | GlobalPageSearchFetchQueryBuilder
      | GlobalPageHotelFetchQueryBuilder,
    private readonly onFinish?: () => Promise<void>,
  ) {}

  private _instance: LongPolling | null = null

  private get instance(): LongPolling | null {
    return this._instance
  }

  private _isLast = false

  private get isLast(): boolean {
    return this._isLast
  }

  private _numberOfReceived = 0

  private get numberOfReceived(): number {
    return this._numberOfReceived
  }

  public start(searchId: string) {
    this.stop()

    this.setIsLast(false)
    this.setNumberOfReceived(0)

    const abortController = new AbortController()

    const instance = new LongPolling({
      abortController,
      duration: this.durationPolling,
      mergeMap: async () => this.updatePoints(searchId, abortController.signal),
      takeWhile: () => this.isNeedPolling(abortController),
      finalize: this.finalize.bind(this),
      executionTimeDuration:
        ENVIRONMENT.SEARCH_SCENARIO_EXECUTION_TIME_DURATION,
    })

    instance.start()

    this.setInstance(instance)
  }

  public stop() {
    if (this.instance) {
      this.instance.stop()
      this.setInstance(null)
    }
  }

  private async updatePoints(searchId: string, signal: AbortSignal) {
    try {
      const payload = this.fetchBuilder.buildSearchMapPointsQuery(
        searchId,
        this.numberOfReceived,
      )

      const data = await api.map.getPoints({
        payload,
        signal,
      })

      if (data && !isEmpty(data.items)) {
        const points: MapPoints[] = data.items.map(this.buildItemToMapPoint)

        this.mapService.state.setPoints(points)
        this.setNumberOfReceived(data.items_count)
      }

      if (data && isBoolean(data?.is_last)) {
        this.setIsLast(data.is_last)
      }
    } catch {
      this.setIsLast(true)
    }
  }

  private isNeedPolling(abortController: AbortController): boolean {
    const isNeedUpdating = !this.isLast

    if (!isNeedUpdating) {
      abortController.abort(
        'Queries that are being executed are no longer needed, as data updates have ended',
      )
    }

    return isNeedUpdating
  }

  private async finalize() {
    const mapState = this.mapService.state

    if (this.onFinish) {
      this.onFinish()
      this.pushAnalytics()
    }

    mapState.setLoading(false)
  }

  private setInstance(instance: LongPolling | null) {
    this._instance = instance
  }

  private setIsLast(isLast: boolean): void {
    this._isLast = isLast
  }

  private setNumberOfReceived(value: number): void {
    this._numberOfReceived = value
  }

  private pushAnalytics() {
    const scenarioId = services.pages.global.scenario.getCurrentScenario()

    if (scenarioId === 'static') {
      return
    }

    const {searchingType} = store().pages.global

    if (searchingType === ProviderRatesSearchingTypeEnum.BestOffers) {
      services.common.analytics.pushViewBestOffers()
      return
    }

    if (searchingType === ProviderRatesSearchingTypeEnum.Promotion) {
      services.common.analytics.pushViewGlobalPromotion()
      return
    }

    services.common.analytics.pushSearchParams()
  }

  private buildItemToMapPoint(item: MapPointData): MapPoints {
    return {
      id: String(item.id),
      type: 'Feature',
      properties: {
        ...item,
      },
      geometry: {
        type: 'Point',
        coordinates: [item.lng, item.lat],
      },
    }
  }
}
