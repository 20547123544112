import {Button} from '@ui/button'
import {Icon} from '@ui/icon'
import type {ComponentProps, FC} from 'react'

type Props = ComponentProps<typeof Button>

export const FilterButton: FC<Props> = ({...properties}) => {
  return (
    <Button variant="secondary" {...properties}>
      <Icon name="filters" width={24} height={24} />
    </Button>
  )
}
