import {type TextSize, TextSizes} from '@ui/typography/text/variants/size'
import type {FC, PropsWithChildren} from 'react'
import {css, jsx, useTheme} from '@emotion/react'
import type {ApplicationCustomTheme} from '@assets/theme'

export const VariantTextStyle = (
  theme: ApplicationCustomTheme,
  size: TextSize,
  color?: string,
) => css`
  color: ${theme.palette.textPrimary};

  ${TextSizes[size]};

  ${color &&
  css`
    color: ${color};
  `};
`

interface Props extends PropsWithChildren {
  size: TextSize
  className?: string
  color?: string
  type?: 'p' | 'span' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5'
}

export const Text: FC<Props> = ({
  children,
  size = 'L',
  className,
  color,
  type = 'p',
}) => {
  const theme = useTheme()
  const css = VariantTextStyle(theme, size, color)
  return jsx(type, {className, css}, children)
}

Text.displayName = 'Text'
