import {api} from '@api/index'
import {
  DestinationAutocompleteTypeEnum,
  type GetDestinationAutoCompleteQueryVariables,
} from '@api/gql/generate-types'
import {store} from '@store/index'

export class ServiceSearchDestination {
  private get state() {
    return store().search.destination
  }

  public isValidDestination(): boolean {
    const isExistPlaceAndDestinationValue = Boolean(
      this.state.selectPlace && this.state.inputValue,
    )
    const isSearchValueEqualPlace =
      this.state.inputValue === this.state.selectPlace?.name

    return isExistPlaceAndDestinationValue && isSearchValueEqualPlace
  }

  public async startSearchDestinations(
    query: GetDestinationAutoCompleteQueryVariables['query'],
  ) {
    this.state.setIsLoading(true)

    api.destination
      .getDestinationAutoComplete({
        variables: {
          query,
          types: [
            DestinationAutocompleteTypeEnum.City,
            DestinationAutocompleteTypeEnum.Hotel,
          ],
        },
      })
      .then((result) => {
        if (result) {
          this.state.setData(result)
        }
      })
      .catch(() => {
        this.state.setError('ошибка')
      })
      .finally(() => {
        this.state.setIsLoading(false)
      })
  }
}
