import {autorun, makeAutoObservable, runInAction} from 'mobx'
import {CFooterActionsProfilePage} from '@store/footer-actions/pages/profile'
import {CFooterActionsMorePage} from '@store/footer-actions/pages/more'
import {CFooterActionsHelpPage} from '@store/footer-actions/pages/help'

type Pages = 'profile' | 'more' | 'help'

export class CFooterActionsPages {
  public readonly profile: CFooterActionsProfilePage =
    new CFooterActionsProfilePage()

  public readonly more: CFooterActionsMorePage = new CFooterActionsMorePage()

  public readonly help: CFooterActionsHelpPage = new CFooterActionsHelpPage()

  private readonly pages: Pages[] = ['profile', 'more', 'help']

  constructor() {
    makeAutoObservable(this)
    this.initializeAutoRuns()
  }

  private initializeAutoRuns(): void {
    this.pages.forEach((page) => {
      autorun(() => {
        if (this[page].isOpen) {
          this.togglePageState(page)
        }
      })
    })
  }

  private togglePageState(page: Pages): void {
    runInAction(() => {
      this[page].setIsOpen(true)

      Object.values(this.pages).forEach((currentPage) => {
        if (currentPage !== page) {
          this[currentPage].setIsOpen(false)
        }
      })
    })
  }
}
