import styled from '@emotion/styled'

export const ButtonTransparent = styled.button`
  display: flex;
  justify-content: start;
  align-items: center;

  border: 1px solid transparent;
  border-radius: 0;
  background-color: transparent;

  padding: 0;

  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    background-color: ${({theme}) => theme.palette.backgroundTertiary};
  }
`
