import {type FC} from 'react'
import {Link, Text} from '@ui/index'
import {isString} from '@utils/guards/types'
import {useTheme} from '@emotion/react'
import {ECurrency} from '@/types/enums/currency'
import styled from '@emotion/styled'
import {Icon} from '@ui/icon'
import useTranslation from 'next-translate/useTranslation'
import {ImageWithSkeleton} from '@ui/image-with-skeleton'
import {NO_HOTEL_IMAGE_SOURCE} from '@constants/hotel'
import {MEDIA_DEVICE_QUERY} from '@assets/theme/media'
import type {MapPointData} from '@services/pages/global/polling-points'
import {addPlus} from '@/library/utils/phone-numbers'
import {services} from '@/library/services'

const Wrapper = styled.div`
  display: flex;
  border-radius: 12px;
  box-shadow: 0 2px 4px 1px ${({theme}) => theme.palette.boxShadowPrimary};
  overflow: hidden;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  padding: 12px;
`

const MainInfoWrapper = styled.div``

const HotelName = styled(Text)`
  width: fit-content;
  max-width: 280px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 500;

  @media ${MEDIA_DEVICE_QUERY.MOBILE_LARGE} {
    max-width: 175px;
  }
`

const HotelStar = styled(Icon)`
  margin-right: 6px;
`

const HotelRatingWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`

const ContactsWrapper = styled.div`
  width: fit-content;
  max-width: 280px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 500;

  @media ${MEDIA_DEVICE_QUERY.MOBILE_LARGE} {
    max-width: 175px;
  }
`

const StyledLink = styled(Link)`
  font-weight: 700;
`

const Price = styled(Text)`
  margin-top: 6px;
`

const PriceValue = styled(Text)`
  font-weight: 500;
`

interface IMarkerBalloonComponentProps extends MapPointData {
  className?: string
}

const MarkerBalloonContentComponent: FC<IMarkerBalloonComponentProps> = ({
  price,
  name,
  rating,
  stars,
  image_url: imageUrl,
  className,
  phone,
  email,
}) => {
  const theme = useTheme()
  const {t} = useTranslation('global.search')
  const {share} = services.common

  return (
    <Wrapper className={className}>
      <ImageWithSkeleton
        src={imageUrl || NO_HOTEL_IMAGE_SOURCE}
        alt={name || ''}
        width={116}
        height={116}
        isIntersectionNeed={false}
      />
      <ContentWrapper>
        <MainInfoWrapper>
          <HotelName size="boldS">{name}</HotelName>
          {!!stars &&
            Array.from({length: stars}).map((_, index) => {
              return (
                <HotelStar
                  // eslint-disable-next-line react/no-array-index-key
                  key={`$hotel_${name}_star_${index}`}
                  name="star"
                  color={theme.palette.backgroundYellow}
                  width={12}
                  height={12}
                />
              )
            })}
          {rating && (
            <HotelRatingWrapper>
              <Icon
                name="star"
                width={12}
                height={12}
                color={theme.palette.backgroundAccentPrimary}
              />
              <Text
                size="boldS"
                type="span"
                color={theme.palette.textAccentPrimary}
              >
                {rating}
              </Text>
            </HotelRatingWrapper>
          )}

          {isString(phone) && (
            <ContactsWrapper>
              <Text size="boldXS" type="span">
                {t('hotels.balloon.phone')}
              </Text>
              <StyledLink href={share.getPhone(phone)}>
                {addPlus(phone)}
              </StyledLink>
            </ContactsWrapper>
          )}

          {isString(email) && (
            <ContactsWrapper>
              <Text size="boldXS" type="span">
                {t('hotels.balloon.email')}
              </Text>
              <StyledLink href={share.getEmail(email)}>{email}</StyledLink>
            </ContactsWrapper>
          )}
        </MainInfoWrapper>
        {!!price && (
          <Price size="M">
            {t('hotels.cards.price.amount_before')}{' '}
            <PriceValue
              size="boldM"
              color={theme.palette.textAccentPrimary}
              type="span"
            >
              {price.toLocaleString()}
            </PriceValue>{' '}
            {ECurrency.RUB}
          </Price>
        )}
      </ContentWrapper>
    </Wrapper>
  )
}

interface IMarkerBalloonProps extends MapPointData {
  className?: string
}

export const MarkerBalloonContent: FC<IMarkerBalloonProps> = ({
  seo_url: seoUrl,
  ...properties
}) => {
  return isString(seoUrl) ? (
    <Link
      href={`${seoUrl}?${services.components.search.buildQueryParameters().toString()}`}
      target="_blank"
      style={{cursor: 'pointer'}}
    >
      <MarkerBalloonContentComponent {...properties} />
    </Link>
  ) : (
    <MarkerBalloonContentComponent {...properties} />
  )
}
