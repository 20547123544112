import styled from '@emotion/styled'
import useTranslation from 'next-translate/useTranslation'
import {observer} from 'mobx-react'
import {store} from '@store/index'
import {useMemo} from 'react'
import {HoteliersRules} from './ui/hoteliers-rules'
import {OstrovokRules} from './ui/ostrovok-rules'
import {checkPolicies} from './ui/ostrovok-rules/lib/helpers'

const Wrapper = styled.div`
  padding: 20px 17px;
  border: 1px solid ${({theme}) => theme.palette.borderTertiary};
  border-radius: 12px;
  gap: 20px;
  display: flex;
  flex-direction: column;
`

const Title = styled.h2`
  margin-bottom: 7px;
  font-weight: 500;
  font-size: 20px;
  color: ${({theme}) => theme.palette.textPrimary};
`

export const HotelScenarioRules = observer(() => {
  const {t} = useTranslation('global.hotel')
  const {data} = store().pages.global.hotel

  const ostrovokPoliciesChecks = useMemo(
    () => checkPolicies(data?.metaPolicies?.et),
    [data?.metaPolicies?.et],
  )

  const ostrovokPoliciesExist =
    data?.metaPolicies?.et &&
    ostrovokPoliciesChecks &&
    Object.values(ostrovokPoliciesChecks).some(Boolean)

  if (!data?.metaPolicies?.hoteliers && !ostrovokPoliciesExist) {
    return null
  }

  if (data.metaPolicies?.hoteliers) {
    return (
      <Wrapper>
        <Title>{t('rules.title')}</Title>
        <HoteliersRules rules={data?.metaPolicies?.hoteliers} />
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <Title>{t('rules.title')}</Title>
      <OstrovokRules
        checks={ostrovokPoliciesChecks}
        rules={data?.metaPolicies?.et}
      />
    </Wrapper>
  )
})
