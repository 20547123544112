import {makeAutoObservable} from 'mobx'
import type {ChangePasswordMutationVariables} from '@api/gql/generate-types'

type OldPassword = ChangePasswordMutationVariables['oldPassword']
type NewPassword = ChangePasswordMutationVariables['newPassword']
type NewPasswordConfirmation = ChangePasswordMutationVariables['oldPassword']

export class CProfilePasswordStore {
  constructor() {
    makeAutoObservable(this)
  }

  private _loading = false

  public get loading(): boolean {
    return this._loading
  }

  private _error = ''

  public get error(): string {
    return this._error
  }

  private _isChangedPassword = false

  public get isChangedPassword(): boolean {
    return this._isChangedPassword
  }

  public setLoading(flag: boolean): void {
    this._loading = flag
  }

  public setError(error: string): void {
    this._error = error
  }

  public setIsChangedPassword(isChangedPassword: boolean): void {
    this._isChangedPassword = isChangedPassword
  }

  private _oldPassword: OldPassword = ''

  private _newPassword: NewPassword = ''

  private _newPasswordConfirmation: NewPasswordConfirmation = ''

  public get oldPassword(): OldPassword {
    return this._oldPassword
  }

  public get newPassword(): NewPassword {
    return this._newPassword
  }

  public get newPasswordConfirmation(): NewPasswordConfirmation {
    return this._newPasswordConfirmation
  }

  public setOldPassword(value: OldPassword): void {
    this._oldPassword = value
  }

  public setNewPassword(value: NewPassword): void {
    this._newPassword = value
  }

  public setNewPasswordConfirmation(value: NewPasswordConfirmation): void {
    this._newPasswordConfirmation = value
  }
}
