import type {BookingGuestInput} from '@/library/api/gql/generate-types'
import type {BookingDataGuest} from '@/library/services/pages/global/scenario/hotel/booking/guest-builder/lib/types'
import type {Option} from '@/types/ui'
import {makeAutoObservable} from 'mobx'
import {adjust, assoc} from 'ramda'

type Room = HotelScenarioRoomRate | null
type Rate = HotelScenarioRate | null
type PaymentType = HotelScenarioRatePaymentType | null
type RateProvider = HotelScenarioProvider | null

export class CBookingModal {
  constructor() {
    makeAutoObservable(this)
  }

  private _isOpen = false

  private _rateProvider: RateProvider = null

  private _room: Room = null

  private _rate: Rate = null

  private _paymentType: PaymentType = null

  private _arrivalTime: Option<number> | null = null

  private _showAllGuests = false

  private _guestsData: BookingDataGuest[] | null = null

  private _isLoading = false

  private _formError: string | null = null

  private _minimumBookingAmount: number | null = null

  public get isOpen(): boolean {
    return this._isOpen
  }

  public setIsOpen(isOpen: boolean): void {
    this._isOpen = isOpen
  }

  public get rateProvider(): RateProvider {
    return this._rateProvider
  }

  public setRateProvider(rateProvider: RateProvider): void {
    this._rateProvider = rateProvider
  }

  public get room(): Room {
    return this._room
  }

  public setRoom(room: Room): void {
    this._room = room
  }

  public get rate(): Rate {
    return this._rate
  }

  public setRate(rate: Rate): void {
    this._rate = rate
  }

  public get paymentType(): PaymentType {
    return this._paymentType
  }

  public setPaymentType(paymentType: PaymentType): void {
    this._paymentType = paymentType
  }

  public get arrivalTime(): Option<number> | null {
    return this._arrivalTime
  }

  public setArrivalTime(arrivalTime: Option<number> | null): void {
    this._arrivalTime = arrivalTime
  }

  public get showAllGuests(): boolean {
    return this._showAllGuests
  }

  public setShowAllGuests(flag: boolean): void {
    this._showAllGuests = flag
  }

  public get guestsData(): BookingDataGuest[] | null {
    return this._guestsData
  }

  public setGuestsData(guestsData: BookingDataGuest[] | null) {
    this._guestsData = guestsData
  }

  public updateGuestsData(
    index: number,
    field: keyof BookingGuestInput,
    value: BookingGuestInput[typeof field],
  ): void {
    if (
      !this._guestsData ||
      !this._guestsData[index] ||
      !(field in this._guestsData[index])
    ) {
      return
    }

    this._guestsData = adjust<BookingDataGuest>(
      index,
      assoc(field, value),
      this._guestsData,
    )
  }

  public get isLoading(): boolean {
    return this._isLoading
  }

  public setIsLoading(flag: boolean): void {
    this._isLoading = flag
  }

  public get formError(): string | null {
    return this._formError
  }

  public setFormError(formError: string | null) {
    this._formError = formError
  }

  public get minimumBookingAmount(): number | null {
    return this._minimumBookingAmount
  }

  public setMinimumBookingAmount(amount: number | null) {
    this._minimumBookingAmount = amount
  }

  public reset() {
    this.setIsOpen(false)
    this.setRateProvider(null)
    this.setRoom(null)
    this.setRate(null)
    this.setPaymentType(null)
    this.setArrivalTime(null)
    this.setShowAllGuests(false)
    this.setGuestsData(null)
    this.setIsLoading(false)
    this.setFormError(null)
  }
}
