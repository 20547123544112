import {store} from '@store/index'

const selectIsActiveSearchInput = () => ({
  get isActiveInput() {
    return store().search.isActiveInput()
  },
})

const selectIsStartSearch = () => ({
  get isStartSearch() {
    return store().search.isStartSearch
  },
})

const selectSearchingType = () => ({
  get searchingType() {
    return store().pages.global.searchingType
  },
})

const selectGuestAmount = () => ({
  get guestAmount() {
    return store().search.guests.amount
  },
})

const selectDestinationValue = () => ({
  get inputValue() {
    return store().search.destination.inputValue
  },
})

const selectDestinationIsOpen = () => ({
  get isOpen() {
    return store().search.destination.isOpen
  },
})

const selectHeaderSearchIsOpen = () => ({
  get isOpen() {
    return store().search.isActiveInput()
  },
})

export const selectors = {
  selectIsActiveSearchInput,
  selectGuestAmount,
  selectIsStartSearch,
  selectHeaderSearchIsOpen,
  selectDestinationValue,
  selectDestinationIsOpen,
  selectSearchingType,
}
