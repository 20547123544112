import {YandexMap} from '@components/ui'
import {type FC, useCallback, useEffect, useMemo} from 'react'
import type {LngLat} from '@yandex/ymaps3-types'
import type {MapPoints} from '@ui/yandex-map'
import {
  normalizeMapCoordsToNormal,
  normalizeNormalBoundsToMap,
  normalizeNormalCoordsToMap,
} from '@utils/map/normalize-coords'
import {observer} from 'mobx-react'
import {store} from '@store/index'
import {equals, isEmpty} from 'ramda'
import type {MapPointData} from '@services/pages/global/polling-points'
import {HotelScenarioPlaceMapMarker} from '@slices/global/ui/hotel-scenario/place/ui/map/ui/marker'
import {HotelScenarioMapCluster} from '@slices/global/ui/hotel-scenario/place/ui/map/ui/cluster'
import {useRouter} from 'next/router'
import {useIsRedirecting} from '@/library/hooks/use-redirecting'
import {isNull} from '@/library/utils/guards/types'
import {buildHotelToMapPoint} from '../../lib/helpers'

interface Props {
  hotelPosition: [number, number]
}

export const HotelScenarioPlaceMap: FC<Props> = observer(({hotelPosition}) => {
  const router = useRouter()
  const {loading, zoom, bounds, center, points} = store().pages.global.hotel.map
  const {data: hotel} = store().pages.global.hotel
  const isRedirecting = useIsRedirecting()

  const pointsWithHotel = useMemo(() => {
    const hotelMapPoint = buildHotelToMapPoint(hotel)

    if (!hotelMapPoint) {
      return points
    }

    if (isRedirecting) {
      return []
    }

    if (isNull(points)) {
      return []
    }

    if (isEmpty(points)) {
      return [hotelMapPoint]
    }

    const isHotelAlreadyInPoints = points?.some(
      (point) => point.id === hotelMapPoint.id,
    )

    return isHotelAlreadyInPoints ? points : [hotelMapPoint, ...points]
  }, [hotel, points, isRedirecting])

  const cluster = useCallback((coordinates: LngLat, features: MapPoints[]) => {
    return (
      <HotelScenarioMapCluster coordinates={coordinates} points={features} />
    )
  }, [])

  const marker = useCallback(
    (feature: MapPoints) => {
      return (
        <HotelScenarioPlaceMapMarker
          coordinates={feature.geometry.coordinates}
          properties={feature.properties as MapPointData}
          isSelectHotel={equals(
            hotelPosition,
            normalizeMapCoordsToNormal(feature.geometry.coordinates),
          )}
        />
      )
    },
    [hotelPosition],
  )

  useEffect(() => {
    const handleResetPoints = (url: string, {shallow}: {shallow: boolean}) => {
      if (!shallow) {
        store().pages.global.hotel.map.setPoints(null)
      }
    }
    router.events.on('routeChangeStart', handleResetPoints)

    return () => {
      router.events.off('routeChangeStart', handleResetPoints)
      store().pages.global.hotel.map.setPoints(null)
    }
  }, [router.events])

  return (
    <YandexMap
      loading={loading}
      cluster={cluster}
      marker={marker}
      map={{
        theme: 'light',
        mode: 'vector',
        location: {
          center: normalizeNormalCoordsToMap(center),
          zoom,
          easing: 'ease-in-out',
          duration: 500,
          bounds: normalizeNormalBoundsToMap(bounds),
        },
      }}
      points={pointsWithHotel || []}
    />
  )
})
