import {observer} from 'mobx-react'
import {store} from '@store/index'
import {useLocalObservable} from 'mobx-react-lite'
import {Checkbox} from '@ui/checkbox'
import styled from '@emotion/styled'
import {Text} from '@components/ui'
import useTranslation from 'next-translate/useTranslation'
import {Skeleton} from 'antd'
import {SEARCH_SCENARIO_FILTERS_ID} from '@constants/dom-ids'
import {scrollParentToChild} from '@utils/dom/scroll'
import type {MouseEvent} from 'react'

const RatingReviewsGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-bottom: 1px solid ${({theme}) => theme.palette.borderTertiary};
  padding-bottom: 20px;
`

const StyledSkeleton = styled(Skeleton)`
  border-bottom: 1px solid ${({theme}) => theme.palette.borderTertiary};
  padding-bottom: 20px;
`

const RATING_GROUP_FROM_VALUE = 5

const SkeletonLoader = () => {
  return <StyledSkeleton active paragraph={{rows: RATING_GROUP_FROM_VALUE}} />
}

const select = () => ({
  get countHotelsGroupedByRating() {
    const {countHotelsGroupedByRating} = store().pages.global.search.filters

    if (!countHotelsGroupedByRating) {
      return null
    }

    const parsedCountHotelsGroupedByRating = Array.from(
      {length: RATING_GROUP_FROM_VALUE},
      (_, index) => RATING_GROUP_FROM_VALUE + index,
    ).reduce((result, currentRating) => {
      const totalCount = countHotelsGroupedByRating?.reduce(
        (sum, {count, rating}) => {
          return rating >= currentRating ? sum + count : sum
        },
        0,
      )
      return {...result, [currentRating]: totalCount}
    }, {})

    // Если нет отелей для оценок 5+ и выше
    const isEveryRatingEmpty = Object.entries(
      parsedCountHotelsGroupedByRating,
    ).every(([, hotelCount]) => !hotelCount)

    if (isEveryRatingEmpty) {
      return null
    }

    return parsedCountHotelsGroupedByRating
  },
  get selectRatingFrom() {
    return store().pages.global.search.filters.selectRatingFrom
  },
  get loading() {
    return store().pages.global.search.filters.loading
  },
})

export const SearchScenarioFiltersRatingReviews = observer(() => {
  const {t} = useTranslation('global.search')

  const {countHotelsGroupedByRating, selectRatingFrom, loading} =
    useLocalObservable(select)

  const handleClick = (
    value: boolean,
    ratingId: number,
    event: MouseEvent<HTMLDivElement>,
  ) => {
    const {filters} = store().pages.global.search

    filters.setSelectedRatingFrom(value ? ratingId : null)

    const parent = document.querySelector<HTMLDivElement>(
      `#${SEARCH_SCENARIO_FILTERS_ID}`,
    )
    if (parent) {
      scrollParentToChild(parent, event.currentTarget)
    }
  }

  if (loading) {
    return <SkeletonLoader />
  }

  if (!countHotelsGroupedByRating) {
    return null
  }

  return (
    <RatingReviewsGroupWrapper>
      <Text size="S">{t('filters.rating_reviews.title')}</Text>
      {Object.entries(countHotelsGroupedByRating)?.map(
        ([ratingId, countHotelsByRating]) => (
          <Checkbox
            key={ratingId}
            value={selectRatingFrom === Number(ratingId)}
            label={t(`filters.rating_reviews.rating_from_${ratingId}`)}
            setValue={(value, event) =>
              handleClick(value, Number(ratingId), event)
            }
            count={Number(countHotelsByRating)}
          />
        ),
      )}
    </RatingReviewsGroupWrapper>
  )
})
