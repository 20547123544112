import styled from '@emotion/styled'
import {HeaderIcons} from '@components/features/header/ui/actions/icons'
import {HeaderLinks} from '@components/features/header/ui/actions/links'
import {HeaderProfile} from '@components/features/header/ui/actions/profile'

const ActionWrapper = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
`

export const HeaderActions = () => {
  return (
    <ActionWrapper>
      <HeaderIcons />
      <HeaderLinks />
      <HeaderProfile />
    </ActionWrapper>
  )
}
