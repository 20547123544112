import styled from '@emotion/styled'
import {Text} from '@/library/components/ui'
import type {FC} from 'react'
import {useFieldArray, useFormContext} from 'react-hook-form'
import useTranslation from 'next-translate/useTranslation'
import {FirstNameInput} from './inputs/first-name-input'
import {LastNameInput} from './inputs/last-name-input'
import {EmailInput} from './inputs/email-input'
import {PhoneInput} from './inputs/phone-input'
import {BookingCitizenshipInput} from './inputs/booking-citizenship-input'
import {BOOKING_MOBILE_SIZE} from '../../../lib/constants'

const FormGroup = styled.div`
  margin-top: 16px;
  border-top: 1px solid ${({theme}) => theme.palette.borderTertiary};

  padding-top: 16px;

  &:first-of-type {
    margin-top: unset;
    border-top: unset;
    padding-top: unset;
  }
  &:last-of-type {
    padding-bottom: 16px;
    border-bottom: 1px solid ${({theme}) => theme.palette.borderTertiary};
  }
`

const FormInputsGroup = styled.div`
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: 1fr 1fr;

  @media ${BOOKING_MOBILE_SIZE} {
    grid-template-columns: 1fr;
  }
`

const GuestText = styled(Text)`
  margin-bottom: 16px;
  color: ${({theme}) => theme.palette.textPrimary};
`

interface InputsGroup {
  isFirst?: boolean
  isKid?: boolean
  index: number
}

export const InputsGroup: FC<InputsGroup> = ({isFirst, isKid, index}) => {
  const {t} = useTranslation('global.hotel')
  const guestText = isKid
    ? t('booking_modal.kid_title', {number: index + 1})
    : t('booking_modal.guest_title', {number: index + 1})

  const {control} = useFormContext()
  const {fields} = useFieldArray({control, name: 'guests'})
  return (
    <FormGroup>
      {!isFirst && <GuestText size="XS">{guestText}</GuestText>}
      <FormInputsGroup>
        <FirstNameInput index={index} id={fields?.[index]?.id} />
        <LastNameInput index={index} id={fields?.[index]?.id} />
        {!isKid && (
          <>
            <EmailInput index={index} id={fields?.[index]?.id} />
            <PhoneInput index={index} id={fields?.[index]?.id} />
          </>
        )}
        <BookingCitizenshipInput index={index} />
      </FormInputsGroup>
    </FormGroup>
  )
}
