import {GlobalPageSearchService} from '@services/pages/global/scenario/search'
import {GlobalPageHotelService} from '@services/pages/global/scenario/hotel'
import {GlobalPageStaticService} from '@services/pages/global/scenario/static'
import type {ParsedUrlQuery} from 'node:querystring'
import {ProviderRatesSearchingTypeEnum} from '@api/gql/generate-types'
import {store} from '@store/index'
import Router from 'next/router'
import getT from 'next-translate/getT'
import {GlobalPaths} from '@/types/enums/routes'

export type GlobalScenario = 'search' | 'static' | 'hotel'

export class ServiceGlobalPageScenario {
  private get state() {
    return store().pages.global
  }

  public readonly search: GlobalPageSearchService =
    new GlobalPageSearchService()

  public readonly hotel: GlobalPageHotelService = new GlobalPageHotelService()

  public readonly static: GlobalPageStaticService =
    new GlobalPageStaticService()

  public load(query: ParsedUrlQuery) {
    const scenarioId = this.getCurrentScenario()

    if (scenarioId === 'static') {
      return
    }

    if (scenarioId === 'hotel') {
      this.parseQuery(query)
      this.hotel.rates.load()
    }

    if (scenarioId === 'search') {
      this.parseQuery(query)
      this.search.load()
    }
  }

  public parseQuery(query: ParsedUrlQuery) {
    const scenarioId = this.getCurrentScenario()

    if (scenarioId === 'static') {
      return
    }

    if (scenarioId === 'hotel') {
      this.hotel.queryParser.parse(query)
    }

    if (scenarioId === 'search') {
      this.search.queryParser.parse(query)
    }
  }

  public isStaticScenario(): boolean {
    return Boolean(this.state.static.data)
  }

  public isHotelScenario(): boolean {
    return Boolean(this.state.info?.hotel_id)
  }

  public isSearchScenario(): boolean {
    return !this.isHotelScenario() && !this.isStaticScenario()
  }

  public getCurrentScenario(): GlobalScenario {
    if (this.isStaticScenario()) {
      return 'static'
    }

    if (this.isHotelScenario()) {
      return 'hotel'
    }

    return 'search'
  }

  public handleSwitchToPromotionSearchingType() {
    this.state.setSearchingType(ProviderRatesSearchingTypeEnum.Promotion)

    store().search.setIsOpenMobileMainPage(true)

    store().search.destination.setData({
      cities: store().pages.global.search.promotion.promotionCities,
    })
    store().search.destination.setInputValue('')
    store().search.destination.setIsOpen(true)
  }

  public async handleSwitchToBestOffersSearchingType() {
    const {locale} = Router
    const bestOffersText = await getT(locale, 'global.search').then((t) =>
      t('filters.actions.best_offers.label'),
    )

    this.state.setSearchingType(ProviderRatesSearchingTypeEnum.BestOffers)
    store().search.destination.setInputValue(bestOffersText)
    store().search.destination.setSelectPlace({
      name: bestOffersText,
      url: GlobalPaths.BEST_OFFERS,
    })
  }

  public isSearchTypeEqualValue(
    searchingType: string,
    equalToSearchingType: ProviderRatesSearchingTypeEnum,
  ): searchingType is ProviderRatesSearchingTypeEnum {
    return searchingType === equalToSearchingType
  }
}
