import styled from '@emotion/styled'
import {Button, CircularLoader, Text} from '@components/ui'
import {observer} from 'mobx-react'
import {store} from '@store/index'
import {Icon} from '@ui/icon'
import {HOTEL_RATING_FRACTION_DIGITS} from '@constants/hotel'
import useTranslation from 'next-translate/useTranslation'
import {HotelScenarioHotelReviewCard} from '@slices/global/ui/hotel-scenario/reviews/ui/card'
import {services} from '@services/index'
import {isEmpty} from 'ramda'
import {MEDIA_DEVICE_QUERY} from '@assets/theme/media'
import {Title} from '@/library/components/ui/typography'

const Wrapper = styled.div`
  padding: 20px 17px;
  border: 1px solid ${({theme}) => theme.palette.borderTertiary};
  border-radius: 12px;
`

const MainTitle = styled.h2`
  margin-bottom: 27px;
  font-weight: 500;
  color: ${({theme}) => theme.palette.textPrimary};
`

const ReviewsRatingWrapper = styled.div`
  padding: 5px 10px;
  border-radius: 12px;
  width: fit-content;
  margin-bottom: 32px;

  color: ${({theme}) => theme.palette.textAccentPrimary};

  border: 2px solid ${({theme}) => theme.palette.borderAccentPrimary};

  svg {
    color: ${({theme}) => theme.palette.textAccentPrimary};
    margin-top: 4px;
  }

  font-weight: 700;
  display: flex;
  justify-content: start;
  align-content: center;
  gap: 5px;
`

const ReviewsCount = styled.div`
  font-weight: 700;
  font-size: 20px;
  margin-left: 2px;
`

const DetailedRatingsWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin-bottom: 32px;

  @media ${MEDIA_DEVICE_QUERY.MOBILE_VERY_LARGE} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${MEDIA_DEVICE_QUERY.MOBILE_MEDIUM} {
    grid-template-columns: 1fr;
  }
`

const DetailedRatingsItem = styled.div``

const DetailedRatingsProgressWrapper = styled.div`
  height: 3px;
  margin-bottom: 10px;
  position: relative;
  border-radius: 12px;
`

const DetailedRatingsProgressOutline = styled.div<{$width: number}>`
  width: ${({$width}) => $width}%;
  height: 100%;
  background-color: ${({theme}) => theme.palette.backgroundAccentPrimary};
  position: absolute;
  border-radius: 12px;
  top: 0;
`
const DetailedRatingsProgressInline = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({theme}) => theme.palette.backgroundTertiary};
  position: absolute;
  border-radius: 12px;
  top: 0;
`

const DetailedRatingsInformationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
`

const DetailedRatingsInformationTitle = styled(Text)``

const DetailedRatingsInformationValue = styled(Text)``

const ReviewCount = styled(Title)`
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  text-align: left;
`

const ReviewsCardWrapper = styled.div`
  margin-top: 32px;
`

const LoadMoreWrapper = styled.div`
  margin-top: 32px;

  button {
    text-transform: uppercase;
  }
`

export const HotelScenarioReviews = observer(() => {
  const {t} = useTranslation('global.hotel')
  const {reviews, data} = store().pages.global.hotel

  if (!reviews || !data) {
    return null
  }

  return (
    <Wrapper>
      <MainTitle>{t('reviews.title')}</MainTitle>
      {data.rating && (
        <ReviewsRatingWrapper>
          <Icon name="star" width={16} height={16} />
          <ReviewsCount>
            {data.rating.toFixed(HOTEL_RATING_FRACTION_DIGITS)}
          </ReviewsCount>
        </ReviewsRatingWrapper>
      )}
      {!isEmpty(data.detailedRatings) && (
        <DetailedRatingsWrapper>
          {data.detailedRatings?.map((detail) => {
            if (!detail.rating) {
              return null
            }

            return (
              <DetailedRatingsItem key={detail.id}>
                <DetailedRatingsProgressWrapper>
                  <DetailedRatingsProgressInline />
                  <DetailedRatingsProgressOutline
                    $width={Math.abs(detail.rating * 10)}
                  />
                </DetailedRatingsProgressWrapper>
                <DetailedRatingsInformationWrapper>
                  <DetailedRatingsInformationTitle size="S">
                    {detail.type?.name}
                  </DetailedRatingsInformationTitle>
                  <DetailedRatingsInformationValue size="S">
                    {detail.rating}
                  </DetailedRatingsInformationValue>
                </DetailedRatingsInformationWrapper>
              </DetailedRatingsItem>
            )
          })}
        </DetailedRatingsWrapper>
      )}
      <ReviewCount type="h2">
        {t('reviews.label', {
          count: data.reviews_count || 0,
        })}
      </ReviewCount>
      {!isEmpty(reviews.data) && (
        <ReviewsCardWrapper>
          {reviews.data?.map((review) => (
            <HotelScenarioHotelReviewCard key={review.id} review={review} />
          ))}
        </ReviewsCardWrapper>
      )}
      {reviews.paginatorInfo?.hasMorePages && (
        <LoadMoreWrapper>
          {reviews.isLoading ? (
            <CircularLoader variant="main" size={32} />
          ) : (
            <Button
              variant="secondary"
              onClick={() =>
                services.pages.global.scenario.hotel.reviews.loadMore()
              }
            >
              Показать ещё
            </Button>
          )}
        </LoadMoreWrapper>
      )}
    </Wrapper>
  )
})
