export const parseNumberQueryParameter = (
  parameter?: string | string[],
  defaultValue?: number,
): number => {
  return parameter
    ? Array.isArray(parameter)
      ? Number.parseInt(parameter[0], 10)
      : Number.parseInt(parameter, 10)
    : defaultValue || 0
}
