import {Icon} from '@/library/components/ui/icon'
import styled from '@emotion/styled'
import {useEffect, useRef, useState, type FC} from 'react'
import {Text} from '@/library/components/ui'
import {copyService} from '@/library/services/common/copy'
import {Tooltip} from '@/library/components/ui/tooltip'
import useTranslation from 'next-translate/useTranslation'
import {TRANSITION_STYLES} from '@/library/constants/css'
import {COPY_TOOLTIP_LIFETIME} from '../lib/constants'

const Copy = styled(Icon)`
  color: ${({theme}) => theme.palette.textAccentPrimary};
  margin-left: 8px;
`

const BookingNumberWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`

const BookingNumberText = styled(Text)`
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  color: ${({theme}) => theme.palette.textPrimary};
  white-space: nowrap;
  transition: all ${TRANSITION_STYLES};
  &:hover {
    color: ${({theme}) => theme.palette.textAccentPrimary};
  }
`

interface BookingNumberProps {
  variant: 'desktop' | 'mobile'
  bookingNumber: string
}

export const BookingNumber: FC<BookingNumberProps> = ({
  bookingNumber,
  variant,
}) => {
  const {t} = useTranslation('profile.bookings')
  const [openCopyTooltip, setOpenCopyTooltip] = useState(false)
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        timeoutRef.current = null
      }
    }
  }, [])

  const handleCopy = () => {
    copyService.copy(bookingNumber).then(() => {
      setOpenCopyTooltip(true)

      timeoutRef.current = setTimeout(() => {
        setOpenCopyTooltip(false)
        timeoutRef.current = null
      }, COPY_TOOLTIP_LIFETIME)
    })
  }

  return (
    <BookingNumberWrapper onClick={handleCopy}>
      <Tooltip open={openCopyTooltip} title={t('copy')}>
        <BookingNumberText size="S">
          {variant === 'desktop'
            ? t('booking_number_desktop', {bookingNumber})
            : t('booking_number_mobile', {bookingNumber})}
          <Copy name="copy" width={20} height={20} />
        </BookingNumberText>
      </Tooltip>
    </BookingNumberWrapper>
  )
}
