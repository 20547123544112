import type {GetServerSideProps} from 'next'
import {andThen, call, pipeWith} from 'ramda'
import {checkUser} from '@/library/utils/api/user-authorization'
import {serverHeaders} from '@/library/utils/http/headers'
import type {ApplicationProperties} from '@/types/ui'
import {redirectScenario} from '@utils/next'
import {Routes} from '@/types/enums/routes'
import {api} from '@/library/api'
import {DEFAULT_PAGE} from '@/library/constants/common'
import type {
  ProfileBookingsPageHydrationMapDataKeys,
  ProfileBookingsPageHydrationMapDataValue,
  ProfileBookingsPageProperties,
} from './lib/types'
import {DEFAULT_FIRST_ITEMS_BOOKINGS} from './lib/constants'

export const getProfileBookingsProperties: GetServerSideProps<
  ProfileBookingsPageProperties
> = async ({req}) => {
  const headers = serverHeaders(req.headers, req.cookies)

  const getBookings = () =>
    api.booking.getBookings({
      headers,
      variables: {
        first: DEFAULT_FIRST_ITEMS_BOOKINGS,
        page: DEFAULT_PAGE,
      },
    })

  return call(
    pipeWith(andThen, [
      () => checkUser(headers),
      async (properties: ApplicationProperties) => {
        try {
          if (!properties.user) {
            return redirectScenario(Routes.HOME)
          }

          const bookings = await getBookings()

          const hydrationData = new Map<
            ProfileBookingsPageHydrationMapDataKeys,
            ProfileBookingsPageHydrationMapDataValue
          >()

          if (bookings) {
            hydrationData.set('bookings', bookings)
          }

          return {
            props: {
              hydrationData: {
                ...properties,
                ...Object.fromEntries(hydrationData),
              },
            },
          }
        } catch {
          return redirectScenario(Routes.HOME)
        }
      },
    ]),
  )
}
