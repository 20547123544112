export const parseStringQueryParameter = (
  parameter?: string | string[],
  defaultValue?: string,
): string => {
  return parameter
    ? Array.isArray(parameter)
      ? parameter[0]
      : parameter
    : defaultValue || ''
}
