import {services} from '@/library/services'
import {store} from '@/library/store'
import type {Translate} from 'next-translate'
import type {NextRouter} from 'next/router'
import {map} from 'ramda'
import {removePlus} from '@/library/utils/phone-numbers'
import {
  AnalyticsBookingStatuses,
  BookingStatusEnum,
  type FormBookingMutationVariables,
} from '@/library/api/gql/generate-types'
import {ENVIRONMENT} from '@/library/utils/guards/environment'
import {getBookingId, Routes} from '@/types/enums/routes'
import {format} from 'date-fns'
import {DateFormatPattern} from '@/types/enums/date-format-patterns'
import {api} from '@/library/api'
import {getPathname} from '@/library/utils/router/get-pathname'
import {errorService} from '@/library/services/common/error'
import {type Error} from '@/library/services/common/error/lib/types'
import {buildBookingFormErrors, buildRate} from '../lib/helpers'

export class FormBookingService {
  public get state() {
    return store().bookingModal.formBooking
  }

  private get searchState() {
    return store().search
  }

  private get modalState() {
    return store().bookingModal.modal
  }

  private get promoCodeState() {
    return store().bookingModal.promoCode
  }

  private get balanceState() {
    return store().bookingModal.balance
  }

  private reset() {
    this.modalState.reset()
    this.promoCodeState.reset()
    this.balanceState.reset()
    this.state.reset()
  }

  private buildVariables = (
    t: Translate,
    origin: string,
  ): FormBookingMutationVariables | null => {
    const search = this.searchState
    const {rate, rateProvider, room, paymentType, guestsData, arrivalTime} =
      this.modalState
    const {amount: balanceAmount} = this.balanceState
    const {data: promoCodeData} = this.promoCodeState

    if (!rate || !rateProvider || !room || !paymentType || !guestsData) {
      this.modalState.setFormError(t('booking_modal.errors.try_again'))
      return null
    }

    const firstGuest = guestsData[0]

    if (!firstGuest.phone || !firstGuest.email) {
      this.modalState.setFormError(t('booking_modal.errors.try_again'))
      return null
    }

    const guestNeeds = buildRate(search)

    const mainGuest = guestsData[0]

    const parsedGuestData = map(
      (guest) => ({
        ...guest,
        first_name: guest.first_name || mainGuest.first_name,
        last_name: guest.last_name || mainGuest.last_name,
        phone: guest.phone ? removePlus(guest.phone) : undefined,
      }),
      guestsData,
    )

    const formBookingVariables: FormBookingMutationVariables = {
      providerRateId: rateProvider.id,
      roomId: room.roomId,
      bookHash: rate.bookHash,
      rateAmount: paymentType.totalAmount,
      ratePaymentType: paymentType.type,
      currencyCode: paymentType.currencyCode,
      rooms: [{guests: parsedGuestData}],
      email: firstGuest.email,
      phone: removePlus(firstGuest.phone),
      freeCancellationBefore:
        paymentType.cancellationPolicy.freeCancellationBefore,
      rate: guestNeeds,
      verificationLink:
        ENVIRONMENT.VERIFICATION_LINK || `${origin}${Routes.EMAIL_CONFIRM}`,
    }

    if (arrivalTime && guestNeeds.checkin) {
      formBookingVariables.arrivalCheckinDatetime = format(
        new Date(guestNeeds.checkin).setHours(arrivalTime.value),
        DateFormatPattern.BACKEND_FORMAT,
      )
    }

    if (balanceAmount) {
      formBookingVariables.balanceAmount = balanceAmount
    }

    if (promoCodeData) {
      formBookingVariables.promoCodeData =
        services.pages.global.scenario.hotel.booking.promoCode.getPromoCodeInput(
          promoCodeData,
        )
    }

    return formBookingVariables
  }

  public async formBooking(t: Translate, origin: string, router: NextRouter) {
    const formBookingVariables = this.buildVariables(t, origin)

    if (!formBookingVariables) {
      this.modalState.setFormError(t('booking_modal.errors.try_again'))
      return
    }

    this.modalState.setIsLoading(true)
    services.common.yandexMetric.submitBooking()

    try {
      services.common.analytics.pushBookingStatus(
        AnalyticsBookingStatuses.BookFormSend,
        getPathname(router),
      )

      const formedBooking = await api.booking.formBooking({
        variables: formBookingVariables,
      })

      if (!formedBooking) {
        this.modalState.setFormError(t('booking_modal.errors.try_again'))
        this.modalState.setIsLoading(false)
        return
      }

      const {
        status,
        id,
        access_token: accessToken,
        must_pay_before_finish: mustPayBeforeFinish,
      } = formedBooking

      const hasBookingError =
        status === BookingStatusEnum.Rejected ||
        status === BookingStatusEnum.Error

      if (hasBookingError) {
        this.modalState.setIsLoading(false)
        this.modalState.setFormError(t('booking_modal.errors.try_again'))
        return
      }

      if (formBookingVariables.balanceAmount) {
        services.pages.global.scenario.hotel.booking.balance.withdrawFromUserBalance(
          formBookingVariables.balanceAmount,
        )
      }

      this.state.setStoreData({id, status, accessToken, mustPayBeforeFinish})
    } catch (error) {
      const typedError = error as Error
      this.handleError(typedError, t, router)
    }
  }

  public takeWhile = () => {
    const {status} = this.state

    return status === BookingStatusEnum.InProcess
  }

  public mergeMap = async () => {
    const {id, accessToken} = this.state.getStoreData()

    if (!id || !accessToken) {
      return
    }

    const status = await api.booking.getBookingStatus({
      variables: {id, accessToken},
    })

    if (status) {
      this.state.setStatus(status)
    }
  }

  public finalize = async (router: NextRouter) => {
    this.modalState.setIsLoading(false)
    const {id, status, accessToken, mustPayBeforeFinish} =
      this.state.getStoreData()

    if (!id || !status || !accessToken || mustPayBeforeFinish === null) {
      return
    }

    const bookingUrl = getBookingId(id, accessToken)

    if (mustPayBeforeFinish) {
      await router.push(bookingUrl)
      this.reset()

      services.components.bookingPaymentModal.openPaymentModal(
        id,
        accessToken,
        getPathname(router),
      )
      return
    }

    const isBooked = status === BookingStatusEnum.Booked
    const analyticsBookingStatus = isBooked
      ? AnalyticsBookingStatuses.BookSuccess
      : AnalyticsBookingStatuses.BookDenied

    await services.common.analytics.pushBookingStatus(
      analyticsBookingStatus,
      getPathname(router),
    )

    if (isBooked) {
      services.common.yandexMetric.submitBookingSuccess()
    }

    await router.push(bookingUrl)
    this.reset()
    services.components.bookingPaymentModal.openPaymentModal(id, accessToken)
  }

  private handleError = (error: Error, t: Translate, router: NextRouter) => {
    this.modalState.setIsLoading(false)
    services.common.analytics.pushBookingStatus(
      AnalyticsBookingStatuses.BookDenied,
      getPathname(router),
    )

    if (!error.graphQLErrors) {
      this.modalState.setFormError(t('booking_modal.errors.try_again'))
      return
    }
    const errorCategory = error.graphQLErrors?.[0]?.extensions?.category

    const validationError = errorService.parseValidationError(error)
    const providerRateError = validationError?.providerRateId?.[0]
    const validationErrorText = Object.values(validationError || {})[0]?.[0]

    const errors = buildBookingFormErrors(
      t,
      validationErrorText,
      providerRateError,
    )

    if (!errors[errorCategory]) {
      this.modalState.setFormError(t('booking_modal.errors.try_again'))
      return
    }

    const {text, action} = errors[errorCategory]
    this.modalState.setFormError(text)
    action?.()
  }
}
