import {makeAutoObservable} from 'mobx'
import {type RegisterStoreInitialStateType} from '../types'
import {REGISTER_STORE_INITIAL_STATE} from '../constants'

export class CRegisterStore {
  constructor() {
    makeAutoObservable(this)
  }

  // Текст ошибки
  private _error: RegisterStoreInitialStateType['error'] =
    REGISTER_STORE_INITIAL_STATE.error

  public get error(): RegisterStoreInitialStateType['error'] {
    return this._error
  }

  public setError(error: RegisterStoreInitialStateType['error']) {
    this._error = error
  }

  // Флаг успешной регистрации
  private _isSuccessful: RegisterStoreInitialStateType['isSuccessful'] =
    REGISTER_STORE_INITIAL_STATE.isSuccessful

  public get isSuccessful(): RegisterStoreInitialStateType['isSuccessful'] {
    return this._isSuccessful
  }

  public setIsSuccessful(
    isSuccessful: RegisterStoreInitialStateType['isSuccessful'],
  ) {
    this._isSuccessful = isSuccessful
  }
}
