import {store} from '@/library/store'
import styled from '@emotion/styled'
import {observer, useLocalObservable} from 'mobx-react'
import {type FC, useMemo} from 'react'
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  Link,
  Text,
} from '@/library/components/ui'
import {MEDIA_DEVICE_QUERY} from '@/assets/theme/media'
import {isNull} from '@/library/utils/guards/types'
import {services} from '@/library/services'
import {ProviderRatesSearchingTypeEnum} from '@/library/api/gql/generate-types'
import {CarouselNext, CarouselPrevious} from '@/library/components/ui/carousel'
import useTranslation from 'next-translate/useTranslation'
import {SimilarHotel} from './ui/similar-hotel'
import {ShowAllHotelsSkeleton} from './ui/skeletons/show-all-hotels-skeleton'
import {SimilarHotelCardSkeleton} from './ui/skeletons/similar-hotel-card-skeleton'
import {SIMILAR_SKELETONS_NUMBER} from './lib/constants'
import type {SimilarHotelWithSeoUrl} from './lib/types'

const Wrapper = styled.div``

const Title = styled(Text)`
  margin-bottom: 28px;
`

const StyledCarouselContent = styled(CarouselContent)`
  padding-bottom: 20px;
`

const StyledCarouselItem = styled(CarouselItem)`
  flex-basis: 320px;
  padding-right: 20px;

  & > div {
    height: 100%;
  }

  &:last-of-type {
    padding-right: 0;
  }

  @media ${MEDIA_DEVICE_QUERY.MOBILE_MEDIUM} {
    flex-basis: 280px;
  }
`

const ShowAllSimilarHotels = styled(Link)`
  margin: 6px auto;
  text-transform: uppercase;
  padding: 10px 20px;

  @media ${MEDIA_DEVICE_QUERY.MOBILE_MEDIUM} {
    margin: unset;
    width: 100%;
  }
`

const selectCityBreadcrumb = () => ({
  get cityBreadcrumb(): {url: string; handleRedirect: () => void} | null {
    const urlChains = store().pages.global.info?.urlsChain

    if (!urlChains) {
      return null
    }

    const {city} = urlChains

    if (isNull(city) || !city?.url || !city?.name) {
      return null
    }

    return {
      url: city.url,
      handleRedirect: () => {
        const {name, url} = city

        if (!name || !url) {
          return
        }

        store().search.destination.setSelectPlace({
          url,
          name,
        })
        store().pages.global.setSearchingType(
          ProviderRatesSearchingTypeEnum.Geo,
        )
        store().search.destination.setInputValue(name)
        store().pages.global.search.resetFilters()
        services.components.search.handleSearch()
      },
    }
  },
})

interface SimilarHotelsProps {
  className?: string
}

export const SimilarHotels: FC<SimilarHotelsProps> = observer(({className}) => {
  const {t} = useTranslation('global.hotel')
  const {similarHotels, isSimilarHotelsLoading} = store().pages.global.hotel
  const {cityBreadcrumb} = useLocalObservable(selectCityBreadcrumb)

  const filteredHotels = useMemo(
    () =>
      similarHotels?.filter(
        (similarHotel): similarHotel is SimilarHotelWithSeoUrl =>
          Boolean(similarHotel.seoUrl?.url),
      ),
    [similarHotels],
  )

  if (
    (!filteredHotels || filteredHotels.length === 0) &&
    !isSimilarHotelsLoading
  ) {
    return null
  }

  return (
    <Wrapper className={className}>
      <Title size="M" type="h2">
        {t('similar_hotels.title')}
      </Title>
      <Carousel>
        <StyledCarouselContent>
          {filteredHotels && !isSimilarHotelsLoading
            ? filteredHotels.map((hotel) => (
              <StyledCarouselItem key={hotel.id}>
                  <SimilarHotel hotel={hotel} />
                </StyledCarouselItem>
              ))
            : null}
          {isSimilarHotelsLoading
            ? Array.from({length: SIMILAR_SKELETONS_NUMBER}).map((_, index) => (
              <StyledCarouselItem key={`similar-hotel-loader-${index}`}>
                  <SimilarHotelCardSkeleton />
                </StyledCarouselItem>
              ))
            : null}
        </StyledCarouselContent>
        {!isSimilarHotelsLoading && (
          <>
            <CarouselPrevious />
            <CarouselNext />
          </>
        )}
      </Carousel>
      {cityBreadcrumb && !isSimilarHotelsLoading ? (
        <ShowAllSimilarHotels
          $variant="primary"
          href={`${cityBreadcrumb.url}?${services.components.search.buildQueryParameters().toString()}`}
          onClick={() => {
            cityBreadcrumb.handleRedirect()
          }}
        >
          {t('similar_hotels.see_all_options')}
        </ShowAllSimilarHotels>
      ) : null}
      {isSimilarHotelsLoading ? <ShowAllHotelsSkeleton /> : null}
    </Wrapper>
  )
})
