// Получение юзера

import {
  SaveHotelProposalDocument,
  type SaveHotelProposalMutation,
  type SaveHotelProposalMutationVariables,
} from '@api/gql/generate-types'
import {path} from 'ramda'
import {clientHeaders} from '@utils/http/headers'
import {$gqlClient} from '@api/gql/client'

type SubmitProposalContext = {
  variables: SaveHotelProposalMutationVariables
}

const submitProposal = ({variables}: SubmitProposalContext) =>
  $gqlClient()
    .mutate<SaveHotelProposalMutation, SaveHotelProposalMutationVariables>({
      mutation: SaveHotelProposalDocument,
      variables,
      fetchPolicy: 'no-cache',
      context: {
        headers: clientHeaders(),
      },
    })
    .then(
      path<SaveHotelProposalMutation['saveHotelProposalForm']>([
        'data',
        'saveHotelProposalForm',
      ]),
    )

export const partnerApi = {
  submitProposal,
}
