import type {FC, PropsWithChildren} from 'react'
import useTranslation from 'next-translate/useTranslation'
import {EtInclusion, EtMealTypeEnum} from '@/library/api/gql/generate-types'
import Trans from 'next-translate/Trans'
import {Tooltip} from '@/library/components/ui/tooltip'
import styled from '@emotion/styled'
import type {OstrovokMeal} from '../lib/types'
import {InlineAmount, InlineCurrency, InlineText} from '../../typography'
import {getInclusion, getMeal, getMealExplanation} from '../lib/helpers'
import {getCurrency} from '../../../lib/helpers'
import {MEALS_WITH_EXPLANATION} from '../lib/constants'

const ExplanationWrapper = styled.span``

const StyledInlineText = styled(InlineText)`
  cursor: pointer;
  text-decoration: underline;
`

interface MealProps {
  meal: OstrovokMeal
}

const MealExplanation: FC<PropsWithChildren<MealProps>> = ({
  meal,
  children,
}) => {
  const {t} = useTranslation('global.hotel')

  if (!meal.mealType) {
    return null
  }

  const explanation = getMealExplanation(t, meal.mealType)
  return (
    <Tooltip trigger="hover" title={explanation}>
      <ExplanationWrapper>
        <StyledInlineText>{children}</StyledInlineText>
      </ExplanationWrapper>
    </Tooltip>
  )
}

export const Meal: FC<MealProps> = ({meal}) => {
  const {t} = useTranslation('global.hotel')
  const {t: currenciesT} = useTranslation('currencies')

  const isInclusionSpecified =
    meal.inclusion && meal.inclusion !== EtInclusion.Unspecified

  if (!meal.mealType) {
    return null
  }

  if (meal.price && meal.mealType && isInclusionSpecified) {
    return (
      <Trans
        i18nKey="rules.et.meal_with_inclusion_and_price"
        ns="global.hotel"
        components={[
          <InlineAmount key={`meal_${meal.mealType}_${meal.price}_amount`} />,
          <InlineCurrency
            key={`meal_${meal.mealType}_${meal.price}_currency`}
          />,
          <InlineText key={`meal_${meal.mealType}_${meal.price}`} />,
          MEALS_WITH_EXPLANATION.has(meal.mealType) ? (
            <MealExplanation
              meal={meal}
              key={`meal_${meal.mealType}_${meal.price}_explanation`}
            />
          ) : (
            <InlineText key={`meal_${meal.mealType}_${meal.price}`} />
          ),
        ]}
        values={{
          meal: getMeal(t, meal.mealType),
          price: meal.price?.toLocaleString(),
          currency: getCurrency(currenciesT, meal.currency),

          inclusion: getInclusion(t, meal.inclusion || EtInclusion.Unspecified),
        }}
      />
    )
  }

  if (meal.price && meal.mealType) {
    return (
      <Trans
        i18nKey="rules.et.meal_with_price"
        ns="global.hotel"
        components={[
          <InlineAmount key={`meal_${meal.mealType}_${meal.price}_amount`} />,
          <InlineCurrency
            key={`meal_${meal.mealType}_${meal.price}_currency`}
          />,
          MEALS_WITH_EXPLANATION.has(meal.mealType) ? (
            <MealExplanation
              meal={meal}
              key={`meal_${meal.mealType}_${meal.price}_explanation`}
            />
          ) : (
            <InlineText key={`meal_${meal.mealType}_${meal.price}`} />
          ),
        ]}
        values={{
          meal: getMeal(t, meal.mealType),
          price: meal.price?.toLocaleString(),
          currency: getCurrency(currenciesT, meal.currency),
        }}
      />
    )
  }

  if (isInclusionSpecified && meal.mealType) {
    return (
      <Trans
        i18nKey="rules.et.meal_with_inclusion"
        ns="global.hotel"
        components={[
          <InlineText key={`meal_${meal.mealType}_${meal.inclusion}`} />,
          MEALS_WITH_EXPLANATION.has(meal.mealType) ? (
            <MealExplanation
              meal={meal}
              key={`meal_${meal.mealType}_${meal.inclusion}_explanation`}
            />
          ) : (
            <InlineText key={`meal_${meal.mealType}_${meal.inclusion}`} />
          ),
        ]}
        values={{
          meal: getMeal(t, meal.mealType),
          inclusion: getInclusion(t, meal.inclusion || EtInclusion.Unspecified),
        }}
      />
    )
  }

  return (
    <Trans
      i18nKey="rules.et.meal"
      ns="global.hotel"
      components={[
        MEALS_WITH_EXPLANATION.has(meal.mealType) ? (
          <MealExplanation
            meal={meal}
            key={`meal_${meal.mealType}_explanation`}
          />
        ) : (
          <InlineText key={`meal_${meal.mealType}`} />
        ),
      ]}
      values={{
        meal: getMeal(t, meal.mealType || EtMealTypeEnum.Unspecified),
      }}
    />
  )
}
