import {makeAutoObservable} from 'mobx'
import {CProfileReviewsWithReview} from '@store/pages/profile/reviews/with'
import {CProfileReviewsWithoutReview} from '@store/pages/profile/reviews/without'
import {CProfileReviewsEditorReview} from '@store/pages/profile/reviews/editor'

export class CProfileReviewsStore {
  public readonly withReview: CProfileReviewsWithReview =
    new CProfileReviewsWithReview()

  public readonly withoutReview: CProfileReviewsWithoutReview =
    new CProfileReviewsWithoutReview()

  public readonly editorReview: CProfileReviewsEditorReview =
    new CProfileReviewsEditorReview()

  constructor() {
    makeAutoObservable(this)
  }

  private _selectReview: UserCompleteBookingDataItem | null = null

  public get selectReview(): UserCompleteBookingDataItem | null {
    return this._selectReview
  }

  public setSelectReview(review: UserCompleteBookingDataItem | null): void {
    this._selectReview = review
  }
}
