import {makeAutoObservable} from 'mobx'

export class CAffiliateInvitesRemove {
  constructor() {
    makeAutoObservable(this)
  }

  private _isLoading = false

  public get isLoading(): boolean {
    return this._isLoading
  }

  public setIsLoading(flag: boolean): void {
    this._isLoading = flag
  }
}
