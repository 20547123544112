import type {FC} from 'react'
import styled from '@emotion/styled'
import useTranslation from 'next-translate/useTranslation'
import Trans from 'next-translate/Trans'
import {useRouter} from 'next/router'
import {HoteliersPolicyPetsAllowedTypeEnum} from '@/library/api/gql/generate-types'
import {
  formatHotelScenarioRulesPolicyTime,
  getCurrency,
} from '../../lib/helpers'
import type {IHoteliersRules} from '../../lib/types'
import {Rule} from '../rule'
import {getCalculationType, getDepositType, getPetsType} from './lib/helpers'
import {
  RulesText,
  InlineText,
  InlineAmount,
  InlineCurrency,
} from '../typography'

const Wrapper = styled.div``

const StyledRule = styled(Rule)`
  margin-bottom: 20px;
  &:last-of-type {
    margin-bottom: 0;
  }
`

const CheckInOutWrapper = styled.div`
  display: flex;
  gap: 14px;
`

const CheckInOutDate = styled(RulesText)``

const Deposit = styled(RulesText)``

const PetsImportantInfo = styled(RulesText)`
  margin-bottom: 8px;
`

const PetsText = styled(RulesText)``
const RegistrationText = styled(RulesText)``
const PaymentMethodsText = styled(RulesText)``
const TaxText = styled(RulesText)`
  margin-bottom: 8px;
  &:last-of-type {
    margin-bottom: 0;
  }
`

interface HoteliersRulesProps {
  rules: IHoteliersRules
}

export const HoteliersRules: FC<HoteliersRulesProps> = ({rules}) => {
  const {t} = useTranslation('global.hotel')
  const {locale} = useRouter()
  const {t: currenciesT} = useTranslation('currencies')

  if (!rules) {
    return null
  }

  const translation = rules.translations
    ? rules.translations.find((translation) => translation.locale === locale)
    : null
  const currency = getCurrency(currenciesT, rules.currencyCode)
  const hasNonNullTaxes = rules.taxAndFees?.some((tax) => tax.amount > 0)

  return (
    <Wrapper>
      <StyledRule
        iconName="checkin"
        title={t('rules.hoteliers.titles.checkin_and_checkout')}
      >
        <CheckInOutWrapper>
          <CheckInOutDate size="S">
            <Trans
              i18nKey="rules.check_in"
              ns="global.hotel"
              components={[<InlineText key="checkin" />]}
              values={{
                time: formatHotelScenarioRulesPolicyTime(
                  t,
                  rules.checkinCheckout.checkinStart,
                  rules.checkinCheckout.checkinEnd ===
                    rules.checkinCheckout.checkinStart
                    ? null
                    : rules.checkinCheckout.checkinEnd,
                ),
              }}
            />
          </CheckInOutDate>
          <CheckInOutDate size="S">
            <Trans
              i18nKey="rules.check_out"
              ns="global.hotel"
              components={[<InlineText key="checkout" />]}
              values={{
                time: formatHotelScenarioRulesPolicyTime(
                  t,
                  rules.checkinCheckout.checkoutStart ===
                    rules.checkinCheckout.checkoutEnd
                    ? null
                    : rules.checkinCheckout.checkoutStart,
                  rules.checkinCheckout.checkoutEnd,
                ),
              }}
            />
          </CheckInOutDate>
        </CheckInOutWrapper>
      </StyledRule>
      {rules.deposit.securityDepositAmount ? (
        <StyledRule
          iconName="deposit"
          title={t('rules.hoteliers.titles.deposit')}
        >
          <Deposit size="S">
            <Trans
              i18nKey="rules.hoteliers.deposit_text"
              ns="global.hotel"
              components={[
                <InlineAmount key="deposit_amount" />,
                <InlineCurrency key="deposit_currency" />,
              ]}
              values={{
                amount: rules.deposit.securityDepositAmount?.toLocaleString(),
                currency,
                depositType: rules.deposit.securityDepositType
                  ? getDepositType(t, rules.deposit.securityDepositType)
                  : '',
              }}
            />
          </Deposit>
        </StyledRule>
      ) : null}
      {rules.pets.petsAllowedType && (
        <StyledRule iconName="pets" title={t('rules.hoteliers.titles.pets')}>
          {translation?.petsAllowedDescription && (
            <PetsImportantInfo size="S">
              <Trans
                i18nKey="rules.hoteliers.pets_important_info"
                ns="global.hotel"
                components={[<InlineText key="pets_important_info" />]}
                values={{
                  info: translation.petsAllowedDescription,
                }}
              />
            </PetsImportantInfo>
          )}
          {rules.pets.petsAllowedAmount &&
          rules.pets.petsAllowedType !==
            HoteliersPolicyPetsAllowedTypeEnum.Impossible ? (
              <Trans
              i18nKey="rules.hoteliers.pets_with_payment"
              ns="global.hotel"
              components={[
                <InlineAmount key="pets_amount" />,
                <InlineCurrency key="pets_currency" />,
              ]}
              values={{
                type: getPetsType(t, rules.pets.petsAllowedType),
                amount: rules.pets.petsAllowedAmount?.toLocaleString(),
                currency,
              }}
            />
          ) : (
            <PetsText size="S">
              {getPetsType(t, rules.pets.petsAllowedType)}
            </PetsText>
          )}
        </StyledRule>
      )}
      {rules.registration.feeForForeignCitizens ? (
        <StyledRule
          iconName="foreign_citizen"
          title={t('rules.hoteliers.titles.registration')}
        >
          <RegistrationText size="S">
            <Trans
              i18nKey="rules.hoteliers.registration_service"
              ns="global.hotel"
              components={[
                <InlineAmount key="registration_amount" />,
                <InlineCurrency key="registration_currency" />,
              ]}
              values={{
                amount:
                  rules.registration.feeForForeignCitizens?.toLocaleString(),
                currency,
              }}
            />
          </RegistrationText>
        </StyledRule>
      ) : null}
      {rules.taxAndFees && rules.taxAndFees.length > 0 && hasNonNullTaxes ? (
        <StyledRule iconName="taxes" title={t('rules.hoteliers.titles.taxes')}>
          {rules.taxAndFees.map((tax) => {
            if (!tax.amount) {
              return null
            }
            return (
              <TaxText key={tax.name + tax.calculationType} size="S">
                <Trans
                  i18nKey="rules.hoteliers.tax"
                  ns="global.hotel"
                  components={[
                    <InlineAmount
                      key={`tax_${tax.name}_${tax.calculationType}_amount`}
                    />,
                    <InlineCurrency
                      key={`tax_${tax.name}_${tax.calculationType}_currency`}
                    />,
                  ]}
                  values={{
                    name: tax.name,
                    type: getCalculationType(t, tax.calculationType),
                    amount: tax.amount?.toLocaleString(),
                    currency,
                    kidsText: tax.useForChild
                      ? t('rules.hoteliers.taxes_not_for_kids')
                      : t('rules.hoteliers.taxes_for_kids'),
                  }}
                />
              </TaxText>
            )
          })}
        </StyledRule>
      ) : null}
      {rules.payment.methods && rules.payment.methods.length > 0 ? (
        <StyledRule
          iconName="payment_method"
          title={t('rules.hoteliers.titles.payments')}
        >
          <PaymentMethodsText size="S">
            {t('rules.hoteliers.payments_can_be_received', {
              methods: rules.payment.methods
                .map((method) => method.name)
                .join(', '),
            })}
          </PaymentMethodsText>
        </StyledRule>
      ) : null}
    </Wrapper>
  )
}
