import {$gqlClient} from '@api/gql/client'
import type {AnalyticsType, AnalyticsVariables} from '@/types/analytics'
import {ANALYTICS_DOCUMENTS} from '@constants/analytics'
import {isServer} from '@utils/next'
import {clientHeaders} from '@utils/http/headers'

const send = <
  Type extends AnalyticsType,
  Variables extends AnalyticsVariables[Type],
>(
  type: Type,
  variables: Variables,
) => {
  if (isServer()) {
    throw new Error('This method cannot be used on the server side')
  }

  const mutation = ANALYTICS_DOCUMENTS[type]

  return $gqlClient().mutate<Variables>({
    mutation,
    variables,
    fetchPolicy: 'no-cache',
    context: {
      headers: clientHeaders(),
    },
  })
}

export const analyticsApi = {
  send,
}
