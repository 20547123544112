import {isClient} from '@utils/next'
import createCache from '@emotion/cache'

const EMOTION_CACHE_KEY = 'emotion-cache'

export function createEmotionCache() {
  let insertionPoint

  if (isClient()) {
    const emotionInsertionPoint = document.querySelector<HTMLMetaElement>(
      'meta[name="emotion-insertion-point"]',
    )
    insertionPoint = emotionInsertionPoint ?? undefined
  }

  return createCache({key: EMOTION_CACHE_KEY, insertionPoint})
}
