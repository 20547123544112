import {store} from '@store/index'
import {observer} from 'mobx-react'
import {useLocalObservable} from 'mobx-react-lite'
import type {GetDestinationAutoCompleteQuery} from '@api/gql/generate-types'
import useTranslation from 'next-translate/useTranslation'
import {DestinationSearchObject} from '../ui/object'

const selectDestinationAutoCompleteHotels = () => ({
  get hotels() {
    return store().search.destination.data?.hotels
  },
})

export const SearchInputDestinationHotels = observer(() => {
  const {t} = useTranslation('features.search-input')
  const {hotels} = useLocalObservable(selectDestinationAutoCompleteHotels)

  if (!hotels?.length) {
    return null
  }

  const handleSelectHotel = (
    hotel: NonNullable<
      GetDestinationAutoCompleteQuery['destinationAutocomplete']['hotels']
    >[number],
  ) => {
    store().search.destination.setIsOpen(false)
    if (hotel.name && hotel.id && hotel.seoUrl.url) {
      store().search.destination.setSelectPlace({
        name: hotel.name,
        url: hotel.seoUrl.url,
      })
      store().search.destination.setInputValue(hotel.name)
    }
  }

  return (
    <DestinationSearchObject.Content
      title={t('destination.popover.hotels.title')}
    >
      {hotels.map((hotel) => (
        <DestinationSearchObject.Item
          key={hotel.id}
          onClick={() => handleSelectHotel(hotel)}
          iconName="hotel"
          title={hotel.name || ''}
          subtitle={hotel.address}
        />
      ))}
    </DestinationSearchObject.Content>
  )
})
