import styled from '@emotion/styled'
import {Link} from '@ui/index'
import {TRANSITION_STYLES} from '@/library/constants/css'
import useTranslation from 'next-translate/useTranslation'
import {Icon} from '@/library/components/ui/icon'
import {useEffect, useRef, useState} from 'react'
import {copyService} from '@/library/services/common/copy'
import {Tooltip} from '@/library/components/ui/tooltip'
import {COPY_LINK_TOOLTIP_LIFETIME} from '@/library/constants/common'
import {ENVIRONMENT} from '@/library/utils/guards/environment'

const LinkListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border: 1px solid ${({theme}) => theme.palette.borderTertiary};
  border-radius: 12px;
`

const CopyButton = styled.button`
  background-color: ${({theme}) => theme.palette.backgroundQuaternary};
  border: none;
  cursor: pointer;
  height: 24px;

  svg {
    transition: color ${TRANSITION_STYLES};
  }

  &:hover {
    svg {
      color: ${({theme}) => theme.palette.textAccentPrimary};
    }
  }
`

const StyledLink = styled(Link)`
  width: fit-content;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 16px;

  svg {
    transition: color ${TRANSITION_STYLES};
  }

  &:hover {
    svg {
      color: ${({theme}) => theme.palette.textAccentPrimary};
    }
  }
`

export const MailLink = () => {
  const {t} = useTranslation('features.header')
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)
  const [showTooltip, setShowTooltip] = useState(false)

  const handleCopy = () => {
    copyService.copy(ENVIRONMENT.ZHILIBYLI_EMAIL).then(() => {
      setShowTooltip(true)

      const timeoutId = setTimeout(() => {
        setShowTooltip(false)
        timeoutRef.current = null
      }, COPY_LINK_TOOLTIP_LIFETIME)
      timeoutRef.current = timeoutId
    })
  }

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
        timeoutRef.current = null
      }
    }
  }, [])

  return (
    <LinkListItem>
      <StyledLink
        href={`mailto:${ENVIRONMENT.ZHILIBYLI_EMAIL}`}
        target="_blank"
      >
        <IconWrapper>
          <Icon name="mail" width={20} height={20} />
        </IconWrapper>
        {ENVIRONMENT.ZHILIBYLI_EMAIL}
      </StyledLink>
      <Tooltip title={t('help.mail_copied')} open={showTooltip}>
        <CopyButton onClick={handleCopy}>
          <Icon name="copy" width={24} height={24} />
        </CopyButton>
      </Tooltip>
    </LinkListItem>
  )
}
