import {phone} from 'phone'
import {ENVIRONMENT} from '@utils/guards/environment'
import {string} from 'yup'
import {removePlus, validatePhone} from '@utils/phone-numbers'
import {
  cyrillicRule,
  EMAIL_MAX_LENGTH,
  EMAIL_USERNAME_MAX_LENGTH,
  namesRule,
  numbersRule,
} from '../constants/validation'

export const testEmailLength = (value?: string) => {
  if (!value) {
    return true
  }
  const [emailUsername] = value.split('@')
  return (
    emailUsername.length <= EMAIL_USERNAME_MAX_LENGTH &&
    value.length <= EMAIL_MAX_LENGTH
  )
}

export const testNotCyrillic = (value?: string) => {
  if (!value) {
    return true
  }

  const domain = value?.split('@')?.[1]?.split('.')
  if (
    domain &&
    domain.length > 0 &&
    domain?.some((domainPart) => domainPart?.startsWith('xn--'))
  ) {
    return false
  }
  return !cyrillicRule.test(value)
}

export const testValidName = (value?: string | null) => {
  if (!value) {
    return true
  }
  return namesRule.test(value) && !numbersRule.test(value)
}

export const testPhone = (value?: string | null) => {
  if (!value) {
    return true
  }

  const isValidPhone = validatePhone(value)

  if (!isValidPhone) {
    return false
  }

  const valueToNumber = Number(removePlus(value))

  if (Number.isNaN(valueToNumber)) {
    return false
  }

  return phone(value).isValid
}

export const testReferralLinkUrl = (value?: string | null) => {
  if (!value) {
    return false
  }

  return value.startsWith(ENVIRONMENT.ORIGIN_SITE_DOMAIN)
}

export const testEmail = (value?: string | null) => {
  const emailScheme = string().email()
  try {
    emailScheme.validateSync(value)
    return true
  } catch {
    return false
  }
}
