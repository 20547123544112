import {makeAutoObservable} from 'mobx'

export class CTemporarySession {
  constructor() {
    makeAutoObservable(this)
  }

  private _loading = true

  public get loading(): boolean {
    return this._loading
  }

  public setLoading(flag: boolean): void {
    this._loading = flag
  }

  private _error = ''

  public get error(): string {
    return this._error
  }

  public setError(error: string): void {
    this._error = error
  }

  private _isLoadingUser = false

  public get isLoadingUser(): boolean {
    return this._isLoadingUser
  }

  public setIsLoadingUser(flag: boolean): void {
    this._isLoadingUser = flag
  }

  public resetState() {
    this.setError('')
    this.setLoading(false)
    this.setIsLoadingUser(false)
  }
}
