import styled from '@emotion/styled'
import {Button} from '@ui/button'
import {observer} from 'mobx-react'
import useTranslation from 'next-translate/useTranslation'
import {store} from '@store/index'
import {services} from '@services/index'
import {useLocalObservable} from 'mobx-react-lite'
import {Skeleton} from 'antd'
import {DEFAULT_PAGE} from '@constants/common'

const StyledApplyFilters = styled(Button)`
  width: 100%;
`

const StyledSkeleton = styled(Skeleton.Button)`
  width: 100%;
`

const selectSearchFiltersLoading = () => ({
  get loading() {
    return store().pages.global.search.filters.loading
  },
})

export const SearchScenarioFiltersApplyButton = observer(() => {
  const {t} = useTranslation('global.search')
  const {isStartSearch} = store().search
  const {loading} = useLocalObservable(selectSearchFiltersLoading)

  if (loading) {
    return <StyledSkeleton active block />
  }

  return (
    <StyledApplyFilters
      disabled={isStartSearch}
      onClick={() => {
        store().pages.global.search.filters.setIsOpen(false)
        store().pages.global.search.hotels.setPage(DEFAULT_PAGE)
        services.components.search.handleSearch()
      }}
    >
      {t('filters.apply_filters')}
    </StyledApplyFilters>
  )
})
