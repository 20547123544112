import styled from '@emotion/styled'
import {Button} from '@ui/button'

import {Popover} from '@/library/components/ui/popover'
import {Icon} from '@/library/components/ui/icon'
import useTranslation from 'next-translate/useTranslation'
import {TRANSITION_STYLES} from '@/library/constants/css'
import {HelpLinks} from './ui/help-links'

const Trigger = styled(Button)`
  display: flex;
  align-items: center;
  color: ${({theme}) => theme.palette.textPrimary};

  svg {
    transition: color ${TRANSITION_STYLES};
  }

  &:hover {
    svg {
      color: ${({theme}) => theme.palette.textAccentPrimary};
    }
  }
`

export const Help = () => {
  const {t} = useTranslation('features.header')

  return (
    <Popover
      content={
        <>
          <HelpLinks />
        </>
      }
    >
      <Trigger variant="text" textSize="defaultSemiBold">
        {t('links.help')}
        <Icon name="arrow_small" width={24} height={24} />
      </Trigger>
    </Popover>
  )
}
