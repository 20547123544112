import styled from '@emotion/styled'
import {type Option} from '@ui/select'
import {Popover} from '@ui/popover'
import {Icon} from '@ui/icon'
import {Text} from '@components/ui'
import {useMemo, useState} from 'react'
import useTranslation from 'next-translate/useTranslation'
import {
  RateSearchFieldEnum,
  type RateSearchSorting,
  SortDirectionEnum,
} from '@api/gql/generate-types'
import {store} from '@store/index'
import {observer} from 'mobx-react'
import {services} from '@services/index'
import {useLocalObservable} from 'mobx-react-lite'
import {TRANSITION_STYLES} from '@/library/constants/css'

const Wrapper = styled.div`
  width: 100%;
`

const StyledPopover = styled(Popover)`
  padding: 20px 10px;
`

const TriggerWrapper = styled.div`
  width: 100%;
  border: 1px solid ${({theme}) => theme.palette.borderTertiary};
  box-shadow: 0px 4px 6px 0px ${({theme}) => theme.palette.boxShadowPrimary};
  border-radius: 12px;
  cursor: pointer;
`

const Trigger = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 8px;
  padding: 15px;
  background-color: ${({theme}) => theme.palette.backgroundQuaternary};
  width: 100%;
  color: ${({theme}) => theme.palette.textPrimary};
  border-radius: 12px;
`

const PopoverContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

const PopoverContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 8px;
  cursor: pointer;
  color: ${({theme}) => theme.palette.textPrimary};
  padding: 5px;
  transition: color ${TRANSITION_STYLES};

  svg {
    transition: color ${TRANSITION_STYLES};
  }

  &:hover {
    color: ${({theme}) => theme.palette.textAccentPrimary};

    svg {
      color: ${({theme}) => theme.palette.textAccentPrimary};
    }
  }
`

const HOTEL_SORTING_OPTIONS: Option<RateSearchSorting>[] = [
  {
    label: 'hotels.sort.rating_desc',
    value: {
      direction: SortDirectionEnum.Desc,
      field: RateSearchFieldEnum.Rating,
    },
  },
  {
    label: 'hotels.sort.price_asc',
    value: {
      direction: SortDirectionEnum.Asc,
      field: RateSearchFieldEnum.Price,
    },
  },
  {
    label: 'hotels.sort.price_desc',
    value: {
      direction: SortDirectionEnum.Desc,
      field: RateSearchFieldEnum.Price,
    },
  },
]

const handleSelectSort = () => ({
  get sort() {
    return store().pages.global.search.hotels.sort
  },
})

export const SearchScenarioHotelsSorting = observer(() => {
  const {t} = useTranslation('global.search')
  const [isOpen, setIsOpen] = useState(false)

  const {sort} = useLocalObservable(handleSelectSort)

  const selectOption = useMemo(() => {
    return HOTEL_SORTING_OPTIONS.find((option) => {
      return (
        sort.field === option.value.field &&
        sort.direction === option.value.direction
      )
    })
  }, [sort])

  const hide = () => {
    setIsOpen(false)
  }

  const handleOpenChange = (newOpen: boolean) => {
    setIsOpen(newOpen)
  }

  const handleSelectSorting = (option: Option<RateSearchSorting>) => {
    store().pages.global.search.hotels.setSort(option.value)
    hide()
    services.components.search.handleSearch()
  }

  return (
    <Wrapper>
      <StyledPopover
        open={isOpen}
        placement="bottomRight"
        onOpenChange={handleOpenChange}
        content={
          <PopoverContentWrapper>
            {HOTEL_SORTING_OPTIONS.map((option) => (
              <PopoverContent
                key={option.label}
                onClick={() => handleSelectSorting(option)}
              >
                <Icon name="sort" width={16} height={16} />
                <Text size="S">{t(option.label)}</Text>
              </PopoverContent>
            ))}
          </PopoverContentWrapper>
        }
      >
        <TriggerWrapper>
          <Trigger>
            <Icon name="sort" width={16} height={16} />
            <Text size="S">
              {t(selectOption?.label || t('hotels.sort.not_found'))}
            </Text>
          </Trigger>
        </TriggerWrapper>
      </StyledPopover>
    </Wrapper>
  )
})
