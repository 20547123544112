import {services} from '@/library/services'
import {store} from '@/library/store'
import styled from '@emotion/styled'
import {observer} from 'mobx-react'
import type {FC} from 'react'
import {CitizenshipInput} from '@/library/components/features'
import {useDebounce} from '@/library/hooks/use-debounce'
import {BookingFormFields} from '@/library/api/gql/generate-types'
import useTranslation from 'next-translate/useTranslation'
import {useIsRedirecting} from '@/library/hooks/use-redirecting'
import {getInputDebounceConfig} from './lib/helpers'

const StyledCitizenshipInput = styled(CitizenshipInput)`
  & > .input-wrapper {
    height: 54px;
    box-shadow: unset;
  }

  & > .list-item {
    max-height: 200px;
  }
`

interface BookingCitizenshipInputProps {
  index: number
}

export const BookingCitizenshipInput: FC<BookingCitizenshipInputProps> =
  observer(({index}) => {
    const {t} = useTranslation('global.hotel')
    const {guestsData, isLoading} = store().bookingModal.modal
    const isRedirecting = useIsRedirecting()

    const sendCitizenshipAnalytics = useDebounce(
      ...getInputDebounceConfig(BookingFormFields.Citizenship, index),
    )

    const value = guestsData?.[index].citizenship

    const handleChange = (citizenship: string) => {
      services.pages.global.scenario.hotel.booking.bookingState.updateGuestsData(
        index,
        'citizenship',
        citizenship,
      )
      sendCitizenshipAnalytics(citizenship)
    }

    return (
      <StyledCitizenshipInput
        disabled={isLoading || isRedirecting}
        citizenship={value}
        setCitizenship={handleChange}
        label={t('booking_modal.citizenship')}
      />
    )
  })
