import useTranslation from 'next-translate/useTranslation'
import {observer} from 'mobx-react'
import {store} from '@store/index'
import {useLocalObservable} from 'mobx-react-lite'
import type {GetSearchHistoryQuery} from '@api/gql/generate-types'
import {DestinationSearchObject} from '../ui/object'

const selectSearchHistory = () => ({
  get searchHistory() {
    return store().search.destination.searchHistory
  },
})

const MAX_HISTORY_VIEW_AMOUNT = 3

export const SearchInputDestinationHistory = observer(() => {
  const {t} = useTranslation('features.search-input')

  const {searchHistory} = useLocalObservable(selectSearchHistory)

  if (!searchHistory?.length) {
    return null
  }

  const handleSelect = (
    hotelItem: NonNullable<
      GetSearchHistoryQuery['userHotelSearchHistory'][number]
    >['hotelItem'],
  ) => {
    if (hotelItem.name && hotelItem.id && hotelItem.seoUrl?.url) {
      store().search.destination.setInputValue(hotelItem.name)
      store().search.destination.setIsOpen(false)
      store().search.destination.setSelectPlace({
        name: hotelItem.name,
        url: hotelItem.seoUrl?.url,
      })
    }
  }

  return (
    <DestinationSearchObject.Content
      title={t('destination.popover.history.title')}
    >
      {searchHistory.map((history, index) => {
        const hotelItem = history?.hotelItem
        const normalizeIndex = index + 1
        if (hotelItem && normalizeIndex <= MAX_HISTORY_VIEW_AMOUNT) {
          return (
            <DestinationSearchObject.Item
              key={hotelItem.id}
              iconName="hotel"
              title={hotelItem.name || ''}
              subtitle={hotelItem.address || ''}
              onClick={() => handleSelect(history.hotelItem)}
            />
          )
        }

        return null
      })}
    </DestinationSearchObject.Content>
  )
})
