import {type FC, memo, useEffect, useState} from 'react'
import styled from '@emotion/styled'
import {ImageWithSkeleton} from '@ui/image-with-skeleton'
import {NO_HOTEL_IMAGE_SOURCE} from '@constants/hotel'
import {Button} from '@ui/button'
import useTranslation from 'next-translate/useTranslation'
import {MEDIA_DEVICE_QUERY} from '@assets/theme/media'
import type {Images} from '@/types/common'
import {CarouselImagePopup} from '@ui/carousel-image-popup'
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
  useCarousel,
} from '@ui/carousel'

const Wrapper = styled.div`
  position: relative;
  width: 270px;

  @media ${MEDIA_DEVICE_QUERY.MOBILE_VERY_LARGE} {
    width: 100%;
  }
`

const StyledImageWithSkeleton = styled(ImageWithSkeleton)`
  .skeleton {
    min-width: 100%;
    min-height: 100%;
  }

  .image {
    border-radius: 12px;
    object-fit: cover;
    object-position: center;
    height: 100%;
    @media ${MEDIA_DEVICE_QUERY.MOBILE_VERY_LARGE} {
      width: 100%;
      height: 260px;
    }
  }
`

const CountChip = styled(Button)`
  position: absolute;
  right: 14px;
  bottom: 14px;
  padding: 6px 13px;
  opacity: 0.85;
  background-color: ${({theme}) => theme.palette.backgroundPrimary};
  color: ${({theme}) => theme.palette.textQuaternary};
  border-radius: 100px;
  font-weight: 500;
`

const StyledCarouselContainer = styled(CarouselContent)`
  cursor: pointer;
  border-radius: 12px;

  @media ${MEDIA_DEVICE_QUERY.MOBILE_VERY_LARGE} {
    height: 260px;
  }
`

interface CountChipProps {
  onClick: (index: number) => void
  count: number
}

const CountChipContainer: FC<CountChipProps> = ({onClick, count}) => {
  const {t} = useTranslation('global.hotel')
  const {api} = useCarousel()
  const [current, setCurrent] = useState(0)

  useEffect(() => {
    const onSelect = () => {
      if (api) {
        setCurrent(api.selectedScrollSnap())
      }
    }

    if (api) {
      setCurrent(api.selectedScrollSnap())

      api.on('select', onSelect)
    }

    return () => {
      api?.off('select', onSelect)
    }
  }, [api])

  return (
    <CountChip variant="tertiary" onClick={() => onClick(current)}>
      {t('rates.room.photo_count', {
        count,
      })}
    </CountChip>
  )
}

interface Props {
  images: Images
}

export const HotelScenarioRatesCardsItemImageCarousel: FC<Props> = memo(
  ({images}) => {
    const [currentIndex, setCurrentIndex] = useState<number>(0)
    const [opened, setOpened] = useState(false)

    const handleOpen = (index: number) => {
      setCurrentIndex(index)
      setOpened(true)
    }

    return (
      <Wrapper>
        <CarouselImagePopup
          opened={opened}
          setOpened={setOpened}
          images={images}
          currentIndex={currentIndex}
          alt="room image"
        />
        <Carousel opts={{startIndex: currentIndex}}>
          <StyledCarouselContainer>
            {images.map((image, index) => {
              const imageAlt = 'alt' in image && image.alt

              return (
                <CarouselItem key={image.id}>
                  <StyledImageWithSkeleton
                    src={image.url || NO_HOTEL_IMAGE_SOURCE}
                    width={273}
                    height={188}
                    loading="lazy"
                    alt={imageAlt || 'room image'}
                    onClick={() => handleOpen(index)}
                  />
                </CarouselItem>
              )
            })}
          </StyledCarouselContainer>
          <CarouselPrevious />
          <CarouselNext />
          <CountChipContainer count={images.length} onClick={handleOpen} />
        </Carousel>
      </Wrapper>
    )
  },
)

HotelScenarioRatesCardsItemImageCarousel.displayName =
  'HotelScenarioRatesCardsItemImageCarousel'
