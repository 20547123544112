import styled from '@emotion/styled'
import {Button} from '@ui/button'
import {Text} from '@ui/index'
import {store} from '@store/index'
import {observer} from 'mobx-react'
import {useLocalObservable} from 'mobx-react-lite'
import {selectors} from '@components/features/search-input/store'
import useTranslation from 'next-translate/useTranslation'
import {MEDIA_DEVICE_QUERY} from '@assets/theme/media'
import type {FC} from 'react'
import {SearchInputButton} from '../search-button'
import {SearchInputGuestsPopover} from './popover'

const GuestWrapper = styled.div`
  position: relative;
  height: 100%;

  @media ${MEDIA_DEVICE_QUERY.LAPTOP} {
    width: 100%;
  }
`

const GuestButton = styled.div<{$isOpen?: boolean; $isActiveInput?: boolean}>`
  background-color: ${({theme, $isOpen}) =>
    $isOpen ? theme.palette.backgroundQuaternary : 'transparent'};
  border-radius: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;

  flex-basis: 50%;
  height: 100%;
  min-width: 320px;
  padding: 0 17px;

  @media ${MEDIA_DEVICE_QUERY.TABLET} {
    min-width: unset;
  }

  &:hover {
    background-color: ${({theme, $isActiveInput}) =>
      $isActiveInput
        ? theme.palette.backgroundQuaternary
        : theme.palette.backgroundTertiary};
  }
`

const GuestTextWrapper = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  background-color: transparent;
  height: 100%;
  width: 100%;

  &:hover {
    background-color: transparent;
  }
`

const GuestTitle = styled(Text)`
  margin-bottom: 2px;
  color: ${({theme}) => theme.palette.textSecondary};
  font-weight: 500;
  @media ${MEDIA_DEVICE_QUERY.LAPTOP_LARGE} {
    font-size: 0.875rem;
  }
`

const GuestSubTitle = styled(Text)`
  color: ${({theme}) => theme.palette.textPrimary};
  font-weight: 500;
  @media ${MEDIA_DEVICE_QUERY.LAPTOP_LARGE} {
    font-size: 0.875rem;
  }
`

const GuestSubTitleWrapper = observer(() => {
  const {t} = useTranslation('features.search-input')
  const {guestAmount} = useLocalObservable(selectors.selectGuestAmount)
  return (
    <GuestSubTitle size="boldS" type="span">
      {guestAmount > 0
        ? t('guests.amount', {count: guestAmount})
        : t('guests.subtitle')}
    </GuestSubTitle>
  )
})

interface IProps {
  withPopover?: boolean
}

export const SearchInputGuest: FC<IProps> = observer(({withPopover = true}) => {
  const {t} = useTranslation('features.search-input')
  const {isActiveInput} = useLocalObservable(
    selectors.selectIsActiveSearchInput,
  )
  const {isOpen} = store().search.guests

  const handleOpen = () => {
    store().search.guests.setIsOpen(!isOpen)
  }

  return (
    <GuestWrapper>
      <GuestButton $isOpen={isOpen} $isActiveInput={isActiveInput}>
        <GuestTextWrapper onClick={handleOpen}>
          <GuestTitle size="S" type="span">
            {t('guests.title')}
          </GuestTitle>
          <GuestSubTitleWrapper />
        </GuestTextWrapper>
        <SearchInputButton />
      </GuestButton>
      {withPopover && isOpen && <SearchInputGuestsPopover />}
    </GuestWrapper>
  )
})
