import {GlobalHydrateService} from '@services/common/global-hydrate'
import type {AffiliateWithdrawFormData} from '@slices/affiliate/withdraw/ui/withdraw-modal/form/lib/types'
import {api} from '@api/index'
import {store} from '@store/index'
import {errorService} from '@services/common/error'
import type {AffiliateWithdrawsPageProperties} from '@slices/affiliate/withdraw/lib/types'
import {isNumber} from '@utils/guards/types'
import {DEFAULT_FIRST_PAGE_ITEMS} from '@constants/common'
import {
  type AffiliateWithdrawBalanceMutationVariables,
  AffiliateWithdrawPaymentMethodEnum,
} from '@api/gql/generate-types'

type InitialHydrationData = NonNullable<
  AffiliateWithdrawsPageProperties['hydrationData']
>

export class AffiliateWithdrawService extends GlobalHydrateService<InitialHydrationData> {
  private get state() {
    return store().pages.affiliate.withdraw
  }

  public hydrate(initialData: InitialHydrationData) {
    const balance = initialData.withdraws?.affiliatePartner?.balance

    if (isNumber(balance)) {
      this.state.setBalance(balance)
    }

    const withdraws = initialData.withdraws?.affiliateBalanceWithdraws

    if (withdraws) {
      this.state.setData(withdraws.data)
      this.state.setPaginatorInfo(withdraws.paginatorInfo)
    }

    if (initialData.banks) {
      this.state.modal.setBanks(initialData.banks)
    }

    super.hydrate(initialData)
  }

  private buildWithdrawRequisites(
    formData: AffiliateWithdrawFormData,
  ): Pick<
    AffiliateWithdrawBalanceMutationVariables,
    'requisites_fk_wallet' | 'requisites_sbp'
  > {
    if (
      this.state.modal.selectedPaymentMethod ===
        AffiliateWithdrawPaymentMethodEnum.Sbp &&
      formData.phone &&
      this.state.modal.selectedBank
    ) {
      return {
        requisites_sbp: {
          phone: formData.phone,
          bank_id: this.state.modal.selectedBank.value,
        },
      }
    }

    return {
      requisites_fk_wallet: {
        account_number: formData.account || '',
      },
    }
  }

  public async startWithdraw(formData: AffiliateWithdrawFormData) {
    this.state.modal.setLoading(true)

    api.affiliate
      .withdraw({
        variables: {
          amount: formData.amount,
          payment_method: this.state.modal.selectedPaymentMethod,
          ...this.buildWithdrawRequisites(formData),
        },
      })
      .then((result) => {
        if (result) {
          const newBalance = this.state.balance - formData.amount
          this.state.setBalance(newBalance)
        }
        this.state.modal.reset()
      })
      .catch((error) => {
        this.state.modal.setError(errorService.getFirstErrorText(error))
      })
      .finally(() => {
        this.state.modal.setLoading(false)
      })
  }

  public loadMore(): void {
    if (!this.state.data) {
      return
    }

    if (!this.state.paginatorInfo?.hasMorePages) {
      return
    }

    const updatedPage = this.state.page + 1
    this.state.setIsLoading(true)
    api.affiliate
      .getWithdraws({
        variables: {
          page: updatedPage,
          first: DEFAULT_FIRST_PAGE_ITEMS,
        },
      })
      .then((response) => {
        const withdraws = response.affiliateBalanceWithdraws
        if (withdraws) {
          this.state.setPage(updatedPage)
          this.state.setData([...(this.state.data || []), ...withdraws.data])
          this.state.setPaginatorInfo(withdraws.paginatorInfo)
        }
      })
      .finally(() => {
        this.state.setIsLoading(false)
      })
  }
}
