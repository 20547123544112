import type {CGlobalPageStoreSearchMap} from '@store/pages/global/search/map'
import {store} from '@store/index'
import {api} from '@api/index'
import type {
  GetHotelRatesMapInitQuery,
  GetHotelRatesMapInitQueryVariables,
} from '@api/gql/generate-types'
import {GlobalPageSearchFetchQueryBuilder} from '@services/pages/global/scenario/search/fetch-query-builder'

export class GlobalPageSearchMapService {
  public get state(): CGlobalPageStoreSearchMap {
    return store().pages.global.search.map
  }

  // eslint-disable-next-line no-useless-constructor
  constructor(
    private readonly fetchBuilder: GlobalPageSearchFetchQueryBuilder,
  ) {}

  public async load() {
    try {
      this.state.resetState()
      this.state.setLoading(true)

      const parameters = this.fetchBuilder.buildHotelRatesQueryParameters()

      const {searchId, center, leftTop, rightBottom} =
        await api.map.getHotelRatesMapInit({
          variables: {
            params: parameters,
          },
        })

      if (center && center.lat && center.lng) {
        this.state.setCenter([center.lat, center.lng])
      }

      if (
        this.isValidLeftTop(leftTop) &&
        this.isValidRightBottom(rightBottom)
      ) {
        this.state.setBounds([
          [leftTop.lat, leftTop.lng],
          [rightBottom.lat, rightBottom.lng],
        ])
      }

      return {searchId}
    } catch {
      this.state.resetState()
      this.state.setLoading(false)

      return null
    }
  }

  private isValidLeftTop(
    leftTop: GetHotelRatesMapInitQuery['hotelRatesMapInit']['leftTop'],
  ): leftTop is NonNullable<
    GetHotelRatesMapInitQueryVariables['params']['leftTop']
  > {
    return Boolean(leftTop && leftTop.lng && leftTop.lat)
  }

  private isValidRightBottom(
    rightBottom: GetHotelRatesMapInitQuery['hotelRatesMapInit']['rightBottom'],
  ): rightBottom is NonNullable<
    GetHotelRatesMapInitQueryVariables['params']['rightBottom']
  > {
    return Boolean(rightBottom && rightBottom.lat && rightBottom.lng)
  }
}
