import {GlobalHydrateService} from '@services/common/global-hydrate'
import type {ApplicationProperties} from '@/types/ui'
import {store} from '@/library/store'
import {api} from '@/library/api'
import type {PartnerFormData} from '@/library/constants/form-validation/partner'
import {errorService} from '../../common/error'

type HydrationInitialData = ApplicationProperties

export class PartnerService extends GlobalHydrateService<HydrationInitialData> {
  public hydrate(initialData: HydrationInitialData) {
    super.hydrate(initialData)
  }

  public async submitProposal(values: PartnerFormData, isNewObject: boolean) {
    store().pages.partner.setIsLoading(true)

    const {legalType, email, name, surname, phone, objectName, objectLink} =
      values

    const parsedPayload = {
      contact_name: name,
      contact_last_name: surname || undefined,
      contact_phone: phone,
      contact_email: email || undefined,
      hotel_name: objectName || undefined,
      legal_type: legalType,
      object_url: objectLink || undefined,
      is_new_object: isNewObject,
    }

    api.partner
      .submitProposal({
        variables: parsedPayload,
      })
      .then((result) => {
        if (result) {
          store().pages.partner.setIsSuccessful(true)
        }
      })
      .catch((error) => {
        const firstError = errorService.getFirstErrorText(error)

        store().pages.partner.setError(firstError)
      })
      .finally(() => {
        store().pages.partner.setIsLoading(false)
      })
  }
}
