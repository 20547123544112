import {object, array, string, boolean} from 'yup'
import {MAX_FIRSTNAME_LENGTH, MAX_LASTNAME_LENGTH} from '../../validation'
import {
  TEST_EMAIL_LENGTH,
  TEST_MAX_LENGTH,
  TEST_NOT_CIRYLLIC,
  TEST_PHONE,
  TEST_VALID_NAME,
} from '../constants'

export const bookingFormSchema = object({
  guests: array().of(
    object().shape({
      hasRequiredFields: boolean(),
      isMain: boolean(),
      first_name: string()
        .when('hasRequiredFields', ([hasRequiredFields], schema) => {
          return hasRequiredFields
            ? schema.required('yup:required_field')
            : schema.notRequired()
        })
        .test(TEST_VALID_NAME)
        .max(MAX_FIRSTNAME_LENGTH, TEST_MAX_LENGTH),
      last_name: string()
        .when('hasRequiredFields', ([hasRequiredFields], schema) => {
          return hasRequiredFields
            ? schema.required('yup:required_field')
            : schema.notRequired()
        })
        .test(TEST_VALID_NAME)
        .max(MAX_LASTNAME_LENGTH, TEST_MAX_LENGTH),
      email: string()
        .when('isMain', ([isMain], schema) => {
          return isMain
            ? schema.required('yup:required_field')
            : schema.notRequired()
        })
        .email('yup:not_email')
        .test(TEST_NOT_CIRYLLIC)
        .test(TEST_EMAIL_LENGTH),
      phone: string()
        .when('isMain', ([isMain], schema) => {
          return isMain
            ? schema.required('yup:required_field')
            : schema.notRequired()
        })
        .test(TEST_PHONE),
    }),
  ),
})
