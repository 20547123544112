import {makeAutoObservable} from 'mobx'
import {
  AffiliateWithdrawPaymentMethodEnum,
  type GetAffiliateWithdrawBanksQuery,
} from '@api/gql/generate-types'
import type {Option} from '@/types/ui'

export enum AffiliateWithdrawCurrency {
  RUB = 'RUB',
  USDT = 'USDT',
}

export class CAffiliateWithdrawModal {
  constructor() {
    makeAutoObservable(this)
  }

  private _isOpen = false

  public get isOpen(): boolean {
    return this._isOpen
  }

  public setIsOpen(flag: boolean): void {
    this._isOpen = flag
  }

  private _selectedPaymentMethod: AffiliateWithdrawPaymentMethodEnum =
    AffiliateWithdrawPaymentMethodEnum.FkWallet

  public get selectedPaymentMethod(): AffiliateWithdrawPaymentMethodEnum {
    return this._selectedPaymentMethod
  }

  public setSelectedPaymentMethod(
    selectedPaymentMethod: AffiliateWithdrawPaymentMethodEnum,
  ): void {
    this._selectedPaymentMethod = selectedPaymentMethod
  }

  private _selectedCurrency: AffiliateWithdrawCurrency =
    AffiliateWithdrawCurrency.RUB

  public get selectedCurrency(): AffiliateWithdrawCurrency {
    return this._selectedCurrency
  }

  public setSelectedCurrency(currency: AffiliateWithdrawCurrency) {
    this._selectedCurrency = currency
  }

  private _loading = false

  public get loading(): boolean {
    return this._loading
  }

  public setLoading(flag: boolean): void {
    this._loading = flag
  }

  private _error = ''

  public get error(): string {
    return this._error
  }

  public setError(error: string): void {
    this._error = error
  }

  private _banks: GetAffiliateWithdrawBanksQuery['affiliateBanks'] | null = null

  public get banks(): GetAffiliateWithdrawBanksQuery['affiliateBanks'] | null {
    return this._banks
  }

  public setBanks(
    banks: GetAffiliateWithdrawBanksQuery['affiliateBanks'] | null,
  ) {
    this._banks = banks
  }

  private _selectedBank: Option<number> | null = null

  public get selectedBank(): Option<number> | null {
    return this._selectedBank
  }

  public setSelectedBank(bank: Option<number> | null): void {
    this._selectedBank = bank
  }

  public reset() {
    this.setIsOpen(false)
    this.setError('')
    this.setSelectedBank(null)
    this.setLoading(false)
    this.setSelectedCurrency(AffiliateWithdrawCurrency.RUB)
    this.setSelectedPaymentMethod(AffiliateWithdrawPaymentMethodEnum.FkWallet)
  }
}
