import {type FC, useMemo} from 'react'
import styled from '@emotion/styled'
import {Button} from '@ui/button'
import {Text} from '@components/ui'
import {HotelScenarioRatesCardsItemRoomRate} from '@slices/global/ui/hotel-scenario/rates/cards/card.room.rate'
import useTranslation from 'next-translate/useTranslation'
import {MEDIA_DEVICE_QUERY} from '@assets/theme/media'
import {store} from '@store/index'
import {Icon} from '@ui/icon'
import {HotelScenarioRatesCardsItemImageCarousel} from '@slices/global/ui/hotel-scenario/rates/cards/card.item.image-carousel'
import type {Images} from '@/types/common'
import {isEmpty} from 'ramda'
import {NO_HOTEL_IMAGE_SOURCE} from '@constants/hotel'
import {observer} from 'mobx-react'

const Wrapper = styled.div`
  border-radius: 12px;

  @media ${MEDIA_DEVICE_QUERY.MOBILE_VERY_LARGE} {
    border: transparent;
  }
`

const Header = styled.div`
  display: flex;
  max-height: 200px;

  @media ${MEDIA_DEVICE_QUERY.MOBILE_VERY_LARGE} {
    flex-direction: column;
    border-bottom: transparent;
    gap: 5px;
    max-height: fit-content;
  }
`

const HeaderInformationWrapper = styled.div`
  padding: 10px 23px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: start;
  flex-direction: column;

  @media ${MEDIA_DEVICE_QUERY.MOBILE_VERY_LARGE} {
    width: 100%;
    border-bottom: 1px solid ${({theme}) => theme.palette.borderTertiary};
    padding: 0 0 10px 0;
    margin: 10px 0;
  }
`

const HeaderInformationName = styled(Text)`
  font-weight: 500;
  margin-bottom: 4px;
`

const HeaderInformationFacilitiesButton = styled(Button)`
  font-weight: 500;
  @media ${MEDIA_DEVICE_QUERY.MOBILE_VERY_LARGE} {
    font-size: 14px;
  }
`

const HeaderInformationArea = styled(Text)`
  font-weight: 500;
  color: ${({theme}) => theme.palette.textSecondary};
`

const HeaderInformationBedsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 10px 0;

  @media ${MEDIA_DEVICE_QUERY.MOBILE_VERY_LARGE} {
    margin-top: 15px;
  }
`

const BedsWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 5px;
`

const BedIcon = styled(Icon)`
  margin-right: 5px;
`

const BedName = styled(Text)``

const BedCount = styled(Text)`
  color: ${({theme}) => theme.palette.textAccentPrimary};
`

interface Props {
  rateProvider: HotelScenarioProvider
  room: HotelScenarioRoomRate
}

export const HotelScenarioRatesCardsItem: FC<Props> = observer(
  ({rateProvider, room}) => {
    const {t} = useTranslation('global.hotel')
    const {data} = store().pages.global.hotel
    const imageAlt = data ? `${data.city.name}: ${data.name}` : 'room image'

    const handleOpenFacilityModal = () => {
      const modal = store().pages.global.hotel.rates.facilityModal
      modal.setIsOpen(true)
      modal.setSelectFacility(room.facilities)
    }

    const images: Images = useMemo(() => {
      if (isEmpty(room.images)) {
        return [
          {
            id: 1,
            url: NO_HOTEL_IMAGE_SOURCE,
            alt: imageAlt,
          },
        ]
      }

      return room.images.map((image, index) => ({
        id: index,
        url: image,
        alt: imageAlt,
      }))
    }, [room.images, imageAlt])

    return (
      <Wrapper>
        <Header>
          <HotelScenarioRatesCardsItemImageCarousel images={images} />
          <HeaderInformationWrapper>
            <span>
              <HeaderInformationName size="M">
                {room.roomName.main}
              </HeaderInformationName>
              {room.area && (
                <HeaderInformationArea size="XS">
                  {t('rates.room.area', {
                    area: room.area,
                  })}
                </HeaderInformationArea>
              )}
            </span>
            {room.beds.length > 0 && (
              <HeaderInformationBedsWrapper>
                {room.beds.map((bed) => (
                  <BedsWrapper key={bed?.name}>
                    <BedIcon name="comfort" width={16} height={16} />
                    <BedName size="XS">{bed?.name}</BedName>
                    {bed?.count && (
                      <BedCount size="XS">
                        {t('rates.room.beds_count', {
                          count: bed.count,
                        })}
                      </BedCount>
                    )}
                  </BedsWrapper>
                ))}
              </HeaderInformationBedsWrapper>
            )}
            {room.facilities && room.facilities.length > 0 && (
              <HeaderInformationFacilitiesButton
                variant="text"
                onClick={handleOpenFacilityModal}
              >
                {t('rates.room.facilities')}
              </HeaderInformationFacilitiesButton>
            )}
          </HeaderInformationWrapper>
        </Header>
        {room.rates.map((roomRate) => {
          return roomRate.paymentTypes.map((paymentType) => (
            <HotelScenarioRatesCardsItemRoomRate
              key={`${roomRate.bookHash}-${paymentType.type}`}
              rateProvider={rateProvider}
              room={room}
              rate={roomRate}
              paymentType={paymentType}
            />
          ))
        })}
      </Wrapper>
    )
  },
)

HotelScenarioRatesCardsItem.displayName = 'HotelScenarioRatesCardsItem'
