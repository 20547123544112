import type {Translate} from 'next-translate'
import type {ReactNode} from 'react'
import type {CGlobalPageStoreHotelRates} from '@/library/store/pages/global/hotel/rates'
import {ENVIRONMENT} from '@/library/utils/guards/environment'
import type {HotelTypes} from '@/library/store/pages/global/search/filters'
import {BreadcrumbKeyEnum, type Hotel} from './types'
import {DEFAULT_HOTEL_TYPE_COUNT} from './constants'

export const parseBreadcrumbRenderName = (
  t: Translate,
  name: ReactNode,
  key?: BreadcrumbKeyEnum,
) => {
  if (key === BreadcrumbKeyEnum.HOME) {
    return t('home')
  }

  if (key === BreadcrumbKeyEnum.HOTEL) {
    return t('seo_hotel_placeholder', {name})
  }

  return t('seo_location_placeholder', {name})
}

export const parseStarRating = (rating?: number | null) => {
  if (!rating) {
    return ENVIRONMENT.DEFAULT_STAR_RATING_VALUE
  }

  const result = rating / ENVIRONMENT.DEFAULT_DIVISION_VALUE

  return Math.round(result)
}

export const buildStarRatingContentValue = (stars?: number | null) => {
  if (!stars) {
    return ENVIRONMENT.DEFAULT_STAR_RATING_VALUE
  }

  return stars
}

export const buildRatingContentValue = (rating?: number | null) => {
  if (!rating) {
    return ENVIRONMENT.DEFAULT_RATING_VALUE
  }

  return rating
}

export const getMinimumRatePrice = (rates: CGlobalPageStoreHotelRates) => {
  if (!rates.data) {
    return null
  }

  const ratesData = rates.data

  const allPaymentTypes = ratesData.flatMap(
    (data) =>
      data.roomRates?.flatMap(
        (roomRate) =>
          roomRate.rates?.flatMap((rate) => rate.paymentTypes || []) || [],
      ) || [],
  )

  if (allPaymentTypes.length === 0) {
    return null
  }

  const bestPricePaymentTypes = allPaymentTypes.filter(
    (paymentType) => paymentType.isBestPrice,
  )

  const minTotalAmount = bestPricePaymentTypes.reduce(
    (minAmount, paymentType) => {
      return paymentType.totalAmount < minAmount
        ? paymentType.totalAmount
        : minAmount
    },
    Number.POSITIVE_INFINITY,
  )

  return minTotalAmount === Number.POSITIVE_INFINITY ? null : minTotalAmount
}

export const getHotelsCountFromTypes = (hotelTypes: HotelTypes) => {
  if (!hotelTypes) {
    return DEFAULT_HOTEL_TYPE_COUNT
  }

  return hotelTypes.reduce((previousCount, type) => {
    return type.hotels_count ? previousCount + type.hotels_count : previousCount
  }, DEFAULT_HOTEL_TYPE_COUNT)
}

export const getUrlForCallTaxi = (lat: number, lng: number) => {
  const url = new URL(ENVIRONMENT.NEXT_PUBLIC_YANDEX_TAXI_URL)

  const {searchParams} = url

  searchParams.append('end-lat', String(lat))
  searchParams.append('end-lon', String(lng))
  searchParams.append(
    'tariffClass',
    ENVIRONMENT.NEXT_PUBLIC_YANDEX_TAXI_TARIFF_CLASS,
  )
  searchParams.append('ref', ENVIRONMENT.NEXT_PUBLIC_YANDEX_TAXI_REF)
  searchParams.append(
    'appmetrica_tracking_id',
    ENVIRONMENT.NEXT_PUBLIC_YANDEX_TAXI_APP_METRIC_TRACKING_ID,
  )
  searchParams.append('lang', ENVIRONMENT.NEXT_PUBLIC_YANDEX_TAXI_LANG)
  searchParams.append('erid', ENVIRONMENT.NEXT_PUBLIC_YANDEX_TAXI_ERID)

  return url.toString()
}
