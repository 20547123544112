module.exports = {
  defaultLocale: 'ru',
  locales: ['ru'],
  pages: {
    '*': [
      'common',
      'yup',
      'features.header',
      'features.feedback-socials',
      'features.promotion-header',
      'features.footer',
      'features.authorization-modal',
      'features.touch-footer-actions',
      'internal-error',
      'features.search-input',
    ],
    '/': [
      'main',
      'features.popular-city-destinations',
      'features.search-history',
    ],
    '/global/[...all]': [
      'features.subscription-newsletter',
      'features.refresh',
      'feature.cancellation-policies',
      'global.search',
      'global.hotel',
      'currencies',
      'datetime',
      'breadcrumbs',
      'countries',
    ],
    '/404': ['not-found', 'features.popular-city-destinations'],
    '/authorization/email-confirm': [
      'authorization.email-confirm',
      'features.popular-city-destinations',
    ],
    '/profile/balance': ['profile.balance', 'features.profile-side-menu'],
    '/profile/bookings': [
      'profile.bookings',
      'features.profile-side-menu',
      'feature.booking-status',
      'datetime',
      'feature.cancellation-policies',
      'currencies',
      'features.booking-badge',
      'feature.booking-payment-modal',
    ],
    '/profile/favorites': ['profile.favorites', 'features.profile-side-menu'],
    '/profile/password': ['profile.password', 'features.profile-side-menu'],
    '/profile/reviews': [
      'profile.reviews',
      'features.profile-side-menu',
      'currencies',
    ],
    '/profile/settings': [
      'profile.settings',
      'features.sex-input',
      'features.birthday-input',
      'features.profile-side-menu',
      'countries',
    ],
    '/booking/[bookingId]': [
      'booking',
      'global.search',
      'features.transaction-badge',
      'features.booking-badge',
      'feature.booking-payment-modal',
      'currencies',
    ],
    '/affiliate': ['affiliate.main', 'features.affiliate-top-menu'],
    '/affiliate/invites': [
      'affiliate.invites',
      'features.link-copy',
      'features.affiliate-top-menu',
    ],
    '/affiliate/referrals': [
      'affiliate.referrals',
      'features.affiliate-top-menu',
    ],
    '/affiliate/statistics': [
      'affiliate.statistics',
      'features.link-copy',
      'features.affiliate-top-menu',
    ],
    '/affiliate/widgets': ['affiliate.widgets', 'features.affiliate-top-menu'],
    '/affiliate/withdraw': [
      'affiliate.withdraw',
      'features.affiliate-top-menu',
      'currencies',
    ],
    '/subscription': ['subscription', 'features.popular-city-destinations'],
    '/temporary-session': ['temporary-session'],
    '/support': ['support'],
    '/mobile-app': ['mobile-app'],
    '/payment/check': ['payment-check'],
    '/about': ['about'],
    '/partner': ['partner'],
  },
}
