import styled from '@emotion/styled'
import {Title} from '@ui/typography'
import {Text} from '@/library/components/ui'
import {Illustration} from '@ui/illustration'
import useTranslation from 'next-translate/useTranslation'
import {MEDIA_DEVICE_QUERY} from '@/assets/theme/media'

const PopupTitle = styled(Title)`
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 10px;
  text-align: center;
  color: ${({theme}) => theme.palette.textPrimary};
  @media ${MEDIA_DEVICE_QUERY.LAPTOP} {
    font-size: 20px;
  }
`

const PopupInfo = styled(Text)`
  max-width: 385px;
  margin: 0 auto;
  margin-bottom: 30px;
  text-align: center;
  color: ${({theme}) => theme.palette.textSecondary};
`

const RefreshIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0;
`

export const RefreshBody = () => {
  const {t} = useTranslation('features.refresh')
  return (
    <>
      <RefreshIcon>
        <Illustration name="refresh" width={48} height={48} />
      </RefreshIcon>
      <PopupTitle type="h4">{t('title')}</PopupTitle>
      <PopupInfo size="S">{t('info')}</PopupInfo>
    </>
  )
}
