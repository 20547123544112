import styled from '@emotion/styled'
import type {Option} from '@/types/ui'
import {Checkbox} from '../checkbox'

const Wrapper = styled.div``

export const Label = styled.label`
  width: fit-content;
  font-weight: 500;
  color: ${({theme}) => theme.palette.textPrimary};
`

const OptionsWrapper = styled.div<{variant: 'horizontal' | 'vertical'}>`
  display: flex;
  gap: 20px;
  align-items: ${({variant}) => (variant === 'vertical' ? 'start' : 'center')};
  flex-direction: ${({variant}) => (variant === 'vertical' ? 'column' : 'row')};
`

interface RadioButtonsProps<T> {
  className?: string
  label?: string
  value: T
  options: Option<T>[]
  setValue: (value: T) => void
  variant?: 'horizontal' | 'vertical'
}

export const RadioButtons = <T extends string>({
  className,
  label,
  value,
  options,
  setValue,
  variant = 'horizontal',
}: RadioButtonsProps<T>) => {
  return (
    <Wrapper className={className}>
      {label && <Label>{label}</Label>}
      <OptionsWrapper variant={variant} className="options_wrapper">
        {options.map((option) => (
          <Checkbox
            key={option.value}
            value={option.value === value}
            label={option.label}
            setValue={() => setValue(option.value)}
          />
        ))}
      </OptionsWrapper>
    </Wrapper>
  )
}
