import {GlobalHydrateService} from '@services/common/global-hydrate'
import type {ApplicationProperties} from '@/types/ui'
import type {ParsedUrlQuery} from 'node:querystring'
import {parseNumberQueryParameter} from '@utils/query/parse-to-number'
import {parseStringQueryParameter} from '@utils/query/parse-to-string'
import {store} from '@store/index'
import {api} from '@api/index'
import type {RegisterTemporarySessionInput} from '@api/gql/generate-types'
import {errorService} from '@services/common/error'
import {CookieNames, setCookie} from '@utils/cookie'
import {checkUser} from '@utils/api/user-authorization'
import {clientHeaders} from '@utils/http/headers'

type HydrationInitialData = ApplicationProperties

export class TemporarySessionService extends GlobalHydrateService<HydrationInitialData> {
  private get state() {
    return store().pages.temporarySession
  }

  public hydrate(initialData: HydrationInitialData) {
    super.hydrate(initialData)
  }

  public async registerToken(query: ParsedUrlQuery) {
    const {admin_id: adminId, user_id: userId, auth_token: authToken} = query

    const parsedAdminId = parseNumberQueryParameter(adminId)
    if (!parsedAdminId) {
      this.state.setError('errors.required_admin_id')
      return
    }

    const parsedUserId = parseNumberQueryParameter(userId)
    if (!parsedUserId) {
      this.state.setError('errors.required_user_id')
      return
    }

    const parsedAuthorizationToken = parseStringQueryParameter(authToken)
    if (!parsedAuthorizationToken) {
      this.state.setError('errors.required_authorization_token')
      return
    }

    const input: RegisterTemporarySessionInput = {
      admin_id: parsedAdminId,
      user_id: parsedUserId,
      auth_token: parsedAuthorizationToken,
    }

    const updateUser = () => {
      this.state.setIsLoadingUser(true)
      checkUser(clientHeaders())
        .then((updatedUser) => {
          if (updatedUser.user) {
            store().user.setUser(updatedUser.user)
          }
        })
        .finally(() => this.state.setIsLoadingUser(false))
    }

    this.state.setLoading(true)

    api.authorization
      .registerTemporaryAuthorizationToken({
        variables: {
          input,
        },
      })
      .then((result) => {
        if (result) {
          setCookie(CookieNames.TEMPORARY_AUTHORIZATION, result.access_token)
          updateUser()
        }
      })
      .catch((error) => {
        const errorText = errorService.getFirstErrorText(error)
        this.state.setError(errorText)
      })
      .finally(() => this.state.setLoading(false))
  }
}
