import {Breadcrumb as LibraryBreadcrumbs} from 'antd'
import type {ComponentProps, FC} from 'react'
import styled from '@emotion/styled'

const StyledLibraryBreadcrumbs = styled(LibraryBreadcrumbs)`
  font-family: ${({theme}) => theme.font.main};

  .ant-breadcrumb-link {
    cursor: pointer;

    &:hover {
      background-color: transparent;
      color: ${({theme}) => theme.palette.textAccentPrimary};
    }
  }
`

type Props = ComponentProps<typeof LibraryBreadcrumbs>

export const Breadcrumbs: FC<Props> = ({...properties}) => {
  return <StyledLibraryBreadcrumbs {...properties} />
}
