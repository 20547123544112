import type {FieldError} from 'react-hook-form'
import {store} from '@store/index'
import type {FC} from 'react'
import {observer} from 'mobx-react'
import useTranslation from 'next-translate/useTranslation'
import {useLocalObservable} from 'mobx-react-lite'
import {Input} from '@ui/input'

type Props = {
  error?: FieldError
}

const selectEmail = () => ({
  get email() {
    return store().pages.profile.settings.email
  },
})

export const EmailInput: FC<Props> = observer(({error}) => {
  const {t} = useTranslation('profile.settings')
  const {email} = useLocalObservable(selectEmail)

  return (
    <Input
      label={t('inputs.email')}
      name="email"
      type="email"
      error={error}
      value={email || ''}
      onChange={({target}) => {
        store().pages.profile.settings.setEmail(target.value)
      }}
      placeholder={t('inputs.email')}
    />
  )
})
