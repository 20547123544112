import {useState} from 'react'
import {services} from '@services/index'
import {store} from '@store/index'
import {useIsomorphicLayoutEffect} from '@hooks/use-isomorphic-layout-effect'

const getInitialWindowSize = () => {
  const {userAgent} = store().utilities
  const {
    MOBILE_MAX_SCREEN_WIDTH,
    TABLET_MAX_SCREEN_WIDTH,
    DESKTOP_MAX_SCREEN_WIDTH,
  } = services.common.deviceDetection

  if (services.common.deviceDetection.isTouch(userAgent)) {
    return TABLET_MAX_SCREEN_WIDTH
  }

  if (services.common.deviceDetection.isMobile(userAgent)) {
    return MOBILE_MAX_SCREEN_WIDTH
  }

  if (services.common.deviceDetection.isTablet(userAgent)) {
    return TABLET_MAX_SCREEN_WIDTH
  }

  return DESKTOP_MAX_SCREEN_WIDTH
}

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState(getInitialWindowSize())

  useIsomorphicLayoutEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  })

  return windowSize
}
