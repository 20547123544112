import {GlobalHydrateService} from '@services/common/global-hydrate'
import type {ProfileFavoritesPageProperties} from '@slices/profile/favorites/lib/types'
import {store} from '@store/index'
import {api} from '@api/index'

type InitialHydrationData =
  Required<ProfileFavoritesPageProperties>['hydrationData']

export class ProfileFavoritesService extends GlobalHydrateService<InitialHydrationData> {
  private get state() {
    return store().pages.profile.favorites
  }

  public hydrate(initialData: InitialHydrationData) {
    if (initialData.favoritesHotels) {
      this.state.setHotels(initialData.favoritesHotels.data)
      this.state.setPaginatorInfo(initialData.favoritesHotels.paginatorInfo)
    }
    super.hydrate(initialData)
  }

  public loadMore(): void {
    if (!this.state.hotels) {
      return
    }

    if (!this.state.paginatorInfo?.hasMorePages) {
      return
    }

    const updatedPage = this.state.page + 1
    this.state.setLoading(true)
    api.hotel
      .getFavorites({
        variables: {
          page: updatedPage,
        },
      })
      .then((hotels) => {
        if (hotels) {
          this.state.setPage(updatedPage)
          this.state.setHotels([...(this.state.hotels || []), ...hotels.data])
          this.state.setPaginatorInfo(hotels.paginatorInfo)
        }
      })
      .finally(() => {
        this.state.setLoading(false)
      })
  }
}
