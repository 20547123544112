import {observer} from 'mobx-react'
import {Modal} from '@ui/modal'
import {store} from '@store/index'
import styled from '@emotion/styled'
import {Icon} from '@/library/components/ui/icon'
import {Button} from '@/library/components/ui'

const ChipsWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-content: center;
  flex-wrap: wrap;
  gap: 10px;
  padding: 5px;
`

const Chip = styled.div`
  border: 1px solid ${({theme}) => theme.palette.borderTertiary};
  color: ${({theme}) => theme.palette.textPrimary};
  font-weight: 500;
  padding: 5px 12px;
  border-radius: 100px;
`

const CloseButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-content: center;
  position: fixed;
  transform: translateY(-50%);
  width: 32px;
  height: 32px;
`

export const HotelScenarioRatesCardsItemFacilityModal = observer(() => {
  const {isOpen, selectFacility} =
    store().pages.global.hotel.rates.facilityModal

  const onCancel = () => {
    const modal = store().pages.global.hotel.rates.facilityModal
    modal.setIsOpen(false)
    modal.setSelectFacility(null)
  }

  return (
    <Modal
      open={isOpen}
      onCancel={onCancel}
      closeIcon={
        <CloseButton variant="text">
          <Icon name="close" width={24} height={24} />
        </CloseButton>
      }
    >
      <ChipsWrapper>
        {selectFacility?.map((facility) => (
          <Chip key={facility}>{facility}</Chip>
        ))}
      </ChipsWrapper>
    </Modal>
  )
})
