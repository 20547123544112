import type {LngLat, LngLatBounds} from '@yandex/ymaps3-types'

/**
 * В данном файле можно определить по приписке в начале, что должно быть
 * normalizeMap -> значит что координаты с карты, конвентируем в формат который в сторе
 * normalizeNormal -> значит что мы координаты со стора, конвентируем в карту
 * Из-за того что с бэка приходит [lat, lng] а в карте [lng, lat] мы просто делаем reverse
 */
export const normalizeMapCoordsToNormal = (coords: LngLat): number[] =>
  [...coords].reverse() as number[]

export const normalizeNormalCoordsToMap = (coords: number[]): LngLat =>
  [...coords].reverse() as LngLat

export const normalizeNormalBoundsToMap = (bounds: number[][]): LngLatBounds =>
  bounds.map(normalizeNormalCoordsToMap) as LngLatBounds
