import {
  AddFavouriteHotelDocument,
  type AddFavouriteHotelMutation,
  type AddFavouriteHotelMutationVariables,
  AttachHotelReviewImagesDocument,
  type AttachHotelReviewImagesMutation,
  type AttachHotelReviewImagesMutationVariables,
  CreateHotelReviewDocument,
  type CreateHotelReviewMutation,
  type CreateHotelReviewMutationVariables,
  DetachHotelReviewImageDocument,
  type DetachHotelReviewImageMutation,
  type DetachHotelReviewImageMutationVariables,
  GetActualHotelRatingTypesDocument,
  type GetActualHotelRatingTypesQuery,
  type GetActualHotelRatingTypesQueryVariables,
  GetFavouriteHotelsDocument,
  type GetFavouriteHotelsQuery,
  type GetFavouriteHotelsQueryVariables,
  GetHotelDocument,
  type GetHotelQuery,
  type GetHotelQueryVariables,
  GetHotelRatesDocument,
  GetHotelRatesFiltersDocument,
  type GetHotelRatesFiltersQuery,
  type GetHotelRatesFiltersQueryVariables,
  type GetHotelRatesQuery,
  type GetHotelRatesQueryVariables,
  GetHotelReviewsDocument,
  type GetHotelReviewsQuery,
  type GetHotelReviewsQueryVariables,
  GetHotelsByUrlDocument,
  type GetHotelsByUrlQuery,
  type GetHotelsByUrlQueryVariables,
  GetHotelsDocument,
  type GetHotelsQuery,
  type GetHotelsQueryVariables,
  GetSearchHistoryDocument,
  type GetSearchHistoryQuery,
  type GetSearchHistoryQueryVariables,
  GetSeoHotelsDocument,
  type GetSeoHotelsQuery,
  type GetSeoHotelsQueryVariables,
  GetSimilarHotelsDocument,
  type GetSimilarHotelsQuery,
  type GetSimilarHotelsQueryVariables,
  RemoveFavouriteHotelDocument,
  type RemoveFavouriteHotelMutation,
  type RemoveFavouriteHotelMutationVariables,
  UpdateHotelReviewDocument,
  type UpdateHotelReviewMutation,
  type UpdateHotelReviewMutationVariables,
} from '@api/gql/generate-types'
import {path} from 'ramda'
import {clientHeaders} from '@utils/http/headers'
import {$gqlClient} from '@api/gql/client'

type GetHotelByURLContext = {
  variables: GetHotelsByUrlQueryVariables
  headers?: Headers
}

const getByURL = ({variables, headers}: GetHotelByURLContext) =>
  $gqlClient()
    .query<GetHotelsByUrlQuery, GetHotelsByUrlQueryVariables>({
      query: GetHotelsByUrlDocument,
      variables,
      fetchPolicy: 'no-cache',
      context: {
        headers: headers || clientHeaders(),
      },
    })
    .then(path<GetHotelsByUrlQuery['hotelsByUrl']>(['data', 'hotelsByUrl']))

type GetHotelByIdContext = {
  variables: GetHotelQueryVariables
  headers?: Headers
}
const getById = ({variables, headers}: GetHotelByIdContext) =>
  $gqlClient()
    .query<GetHotelQuery, GetHotelQueryVariables>({
      query: GetHotelDocument,
      variables,
      fetchPolicy: 'no-cache',
      context: {
        headers: headers || clientHeaders(),
      },
    })
    .then(path<GetHotelQuery>(['data'])) as Promise<GetHotelQuery>

type GetHotelsContext = {
  variables: GetHotelsQueryVariables
  headers?: Headers
}
const get = ({variables, headers}: GetHotelsContext) =>
  $gqlClient()
    .query<GetHotelsQuery, GetHotelsQueryVariables>({
      query: GetHotelsDocument,
      variables,
      fetchPolicy: 'no-cache',
      context: {
        headers: headers || clientHeaders(),
      },
    })
    .then(path<GetHotelsQuery>(['data'])) as Promise<GetHotelsQuery>

type GetSeoHotels = {
  headers?: Headers
  variables: GetSeoHotelsQueryVariables
}

const getSeoHotels = ({headers, variables}: GetSeoHotels) =>
  $gqlClient()
    .query<GetSeoHotelsQuery, GetSeoHotelsQueryVariables>({
      query: GetSeoHotelsDocument,
      variables,
      fetchPolicy: 'no-cache',
      context: {
        headers: headers || clientHeaders(),
      },
    })
    .then(
      path<GetSeoHotelsQuery['hotelsForSeoPrerenderInSearch']>([
        'data',
        'hotelsForSeoPrerenderInSearch',
      ]),
    )

type GetSearchHistoryContext = {
  variables?: GetSearchHistoryQueryVariables
  headers?: Headers
}
const getSearchHistory = ({variables, headers}: GetSearchHistoryContext) =>
  $gqlClient()
    .query<GetSearchHistoryQuery, GetSearchHistoryQueryVariables>({
      query: GetSearchHistoryDocument,
      variables,
      fetchPolicy: 'no-cache',
      context: {
        headers: headers || clientHeaders(),
      },
    })
    .then(
      path<GetSearchHistoryQuery['userHotelSearchHistory']>([
        'data',
        'userHotelSearchHistory',
      ]),
    )

type GetHotelRatesFiltersContext = {
  headers?: Headers
  variables?: GetHotelRatesFiltersQueryVariables
}

export const getHotelRatesFilters = ({
  headers,
  variables,
}: GetHotelRatesFiltersContext) =>
  $gqlClient()
    .query<GetHotelRatesFiltersQuery, GetHotelRatesFiltersQueryVariables>({
      query: GetHotelRatesFiltersDocument,
      variables,
      fetchPolicy: 'no-cache',
      context: {
        headers: headers || clientHeaders(),
      },
    })
    .then(
      path<GetHotelRatesFiltersQuery['hotelRatesFilters']>([
        'data',
        'hotelRatesFilters',
      ]),
    ) as Promise<GetHotelRatesFiltersQuery['hotelRatesFilters']>

type GetFavoritesHotelsContext = {
  headers?: Headers
  variables: GetFavouriteHotelsQueryVariables
}

const getFavorites = ({headers, variables}: GetFavoritesHotelsContext) =>
  $gqlClient()
    .query<GetFavouriteHotelsQuery, GetFavouriteHotelsQueryVariables>({
      query: GetFavouriteHotelsDocument,
      variables,
      fetchPolicy: 'no-cache',
      context: {
        headers: headers || clientHeaders(),
      },
    })
    .then(
      path<GetFavouriteHotelsQuery['favouriteHotels']>([
        'data',
        'favouriteHotels',
      ]),
    )

type AddFavouriteHotelsContext = {
  headers?: Headers
  variables: AddFavouriteHotelMutationVariables
}
const addFavoriteHotel = ({headers, variables}: AddFavouriteHotelsContext) =>
  $gqlClient()
    .mutate<AddFavouriteHotelMutation, AddFavouriteHotelMutationVariables>({
      mutation: AddFavouriteHotelDocument,
      variables,
      fetchPolicy: 'no-cache',
      context: {
        headers: headers || clientHeaders(),
      },
    })
    .then(
      path<AddFavouriteHotelMutation['saveFavouriteHotel']>([
        'data',
        'saveFavouriteHotel',
      ]),
    )

type RemoveFavouriteHotelsContext = {
  headers?: Headers
  variables: AddFavouriteHotelMutationVariables
}
const removeFavoriteHotel = ({
  headers,
  variables,
}: RemoveFavouriteHotelsContext) =>
  $gqlClient()
    .mutate<
      RemoveFavouriteHotelMutation,
      RemoveFavouriteHotelMutationVariables
    >({
      mutation: RemoveFavouriteHotelDocument,
      variables,
      fetchPolicy: 'no-cache',
      context: {
        headers: headers || clientHeaders(),
      },
    })
    .then(
      path<RemoveFavouriteHotelMutation['removeFavouriteHotel']>([
        'data',
        'removeFavouriteHotel',
      ]),
    )

type GetReviewsContext = {
  headers?: Headers
  variables: GetHotelReviewsQueryVariables
}

const getReviews = ({headers, variables}: GetReviewsContext) =>
  $gqlClient()
    .query<GetHotelReviewsQuery, GetHotelReviewsQueryVariables>({
      query: GetHotelReviewsDocument,
      variables,
      fetchPolicy: 'no-cache',
      context: {
        headers: headers || clientHeaders(),
      },
    })
    .then(path<GetHotelReviewsQuery['hotelReviews']>(['data', 'hotelReviews']))

type GetRatesContext = {
  headers?: Headers
  variables: GetHotelRatesQueryVariables
}

const getRates = ({headers, variables}: GetRatesContext) =>
  $gqlClient()
    .query<GetHotelRatesQuery, GetHotelRatesQueryVariables>({
      query: GetHotelRatesDocument,
      variables,
      fetchPolicy: 'no-cache',
      context: {
        headers: headers || clientHeaders(),
      },
    })
    .then(path<GetHotelRatesQuery['providerRates']>(['data', 'providerRates']))

type GetActualRatingTypesContext = {
  headers?: Headers
  variables: GetActualHotelRatingTypesQueryVariables
}

const getActualRatingTypes = ({
  headers,
  variables,
}: GetActualRatingTypesContext) =>
  $gqlClient()
    .query<
      GetActualHotelRatingTypesQuery,
      GetActualHotelRatingTypesQueryVariables
    >({
      query: GetActualHotelRatingTypesDocument,
      variables,
      fetchPolicy: 'no-cache',
      context: {
        headers: headers || clientHeaders(),
      },
    })
    .then(
      path<GetActualHotelRatingTypesQuery['actualHotelRatingTypes']>([
        'data',
        'actualHotelRatingTypes',
      ]),
    )

type CreateHotelReviewContext = {
  headers?: Headers
  variables: CreateHotelReviewMutationVariables
}

const createReview = ({headers, variables}: CreateHotelReviewContext) =>
  $gqlClient()
    .mutate<CreateHotelReviewMutation, CreateHotelReviewMutationVariables>({
      mutation: CreateHotelReviewDocument,
      variables,
      fetchPolicy: 'no-cache',
      context: {
        headers: headers || clientHeaders(),
      },
    })
    .then(
      path<CreateHotelReviewMutation['createHotelReview']>([
        'data',
        'createHotelReview',
      ]),
    )

type UpdateHotelReviewContext = {
  headers?: Headers
  variables: UpdateHotelReviewMutationVariables
}

const updateReview = ({headers, variables}: UpdateHotelReviewContext) =>
  $gqlClient()
    .mutate<UpdateHotelReviewMutation, UpdateHotelReviewMutationVariables>({
      mutation: UpdateHotelReviewDocument,
      variables,
      fetchPolicy: 'no-cache',
      context: {
        headers: headers || clientHeaders(),
      },
    })
    .then(
      path<UpdateHotelReviewMutation['updateHotelReview']>([
        'data',
        'updateHotelReview',
      ]),
    )

type AttachHotelReviewImagesContext = {
  headers?: Headers
  variables: AttachHotelReviewImagesMutationVariables
}

const attachReviewImages = ({
  headers,
  variables,
}: AttachHotelReviewImagesContext) =>
  $gqlClient()
    .mutate<
      AttachHotelReviewImagesMutation,
      AttachHotelReviewImagesMutationVariables
    >({
      mutation: AttachHotelReviewImagesDocument,
      variables,
      fetchPolicy: 'no-cache',
      context: {
        headers: headers || clientHeaders(),
      },
    })
    .then(
      path<AttachHotelReviewImagesMutation['attachHotelReviewImages']>([
        'data',
        'attachHotelReviewImages',
      ]),
    )

type DetachReviewImageContext = {
  headers?: Headers
  variables: DetachHotelReviewImageMutationVariables
}

const detachReviewImage = ({headers, variables}: DetachReviewImageContext) =>
  $gqlClient()
    .mutate<
      DetachHotelReviewImageMutation,
      DetachHotelReviewImageMutationVariables
    >({
      mutation: DetachHotelReviewImageDocument,
      variables,
      fetchPolicy: 'no-cache',
      context: {
        headers: headers || clientHeaders(),
      },
    })
    .then(
      path<DetachHotelReviewImageMutation['detachHotelReviewImage']>([
        'data',
        'detachHotelReviewImage',
      ]),
    )

type GetSimilarHotelsContext = {
  variables: GetSimilarHotelsQueryVariables
  headers?: Headers
}

const getSimilarHotels = ({variables, headers}: GetSimilarHotelsContext) =>
  $gqlClient()
    .query<GetSimilarHotelsQuery, GetSimilarHotelsQueryVariables>({
      query: GetSimilarHotelsDocument,
      variables,
      fetchPolicy: 'no-cache',
      context: {
        headers: headers || clientHeaders(),
      },
    })
    .then(
      path<GetSimilarHotelsQuery['similarHotels']>(['data', 'similarHotels']),
    )

export const hotelsApi = {
  createReview,
  updateReview,
  detachReviewImage,
  attachReviewImages,
  addFavoriteHotel,
  removeFavoriteHotel,
  getActualRatingTypes,
  getRates,
  getFavorites,
  getReviews,
  getHotelRatesFilters,
  getSearchHistory,
  getSeoHotels,
  getSimilarHotels,
  getByURL,
  getById,
  get,
}
