import {$gqlClient} from '@api/gql/client'
import {
  GetHotelRatesMapInitDocument,
  type GetHotelRatesMapInitQuery,
  type GetHotelRatesMapInitQueryVariables,
} from '@api/gql/generate-types'
import {clientHeaders} from '@utils/http/headers'
import {path} from 'ramda'
import {request} from '@api/request'
import {url} from '@utils/http/url'
import {
  type ClientRatesSearchCreateRequest,
  type ClientRatesSearchCreateResponse,
} from '@api/rest/api-scheme'

type GetHotelRatesMapInitContext = {
  headers?: Headers
  variables?: GetHotelRatesMapInitQueryVariables
}

export const getHotelRatesMapInit = ({
  headers,
  variables,
}: GetHotelRatesMapInitContext) =>
  $gqlClient()
    .query<GetHotelRatesMapInitQuery, GetHotelRatesMapInitQueryVariables>({
      query: GetHotelRatesMapInitDocument,
      variables,
      fetchPolicy: 'no-cache',
      context: {
        headers: headers || clientHeaders(),
      },
    })
    .then(
      path<NonNullable<GetHotelRatesMapInitQuery['hotelRatesMapInit']>>([
        'data',
        'hotelRatesMapInit',
      ]),
    ) as Promise<GetHotelRatesMapInitQuery['hotelRatesMapInit']>

type GetHotelRatesPointsContext = {
  payload: ClientRatesSearchCreateRequest
  signal: AbortSignal
  headers?: Headers
}

const getPoints = ({payload, signal, headers}: GetHotelRatesPointsContext) =>
  request<ClientRatesSearchCreateResponse>(url('client/rates/search'), {
    method: 'POST',
    headers: headers || clientHeaders(),
    body: JSON.stringify(payload),
    signal,
  }).then(path<ClientRatesSearchCreateResponse['data']>([1, 'data']))

export const mapApi = {
  getHotelRatesMapInit,
  getPoints,
}
