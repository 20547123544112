import type {FieldError} from 'react-hook-form'
import {store} from '@store/index'
import type {ChangeEvent, FC} from 'react'
import {observer} from 'mobx-react'
import useTranslation from 'next-translate/useTranslation'
import {useLocalObservable} from 'mobx-react-lite'
import {Input} from '@ui/input'
import {addPlus} from '@/library/utils/phone-numbers'

type Props = {
  error?: FieldError
}

const selectPhone = () => ({
  get phone() {
    return store().pages.profile.settings.phone
  },
})

export const PhoneInput: FC<Props> = observer(({error}) => {
  const {t} = useTranslation('profile.settings')
  const {phone} = useLocalObservable(selectPhone)

  const handleChange = ({target}: ChangeEvent<HTMLInputElement>) => {
    const value =
      target.value.startsWith('+') || target.value === ''
        ? target.value
        : addPlus(target.value)
    store().pages.profile.settings.setPhone(value)
  }

  return (
    <Input
      label={t('inputs.phone.label')}
      name="phone"
      type="tel"
      error={error}
      value={phone || ''}
      onChange={handleChange}
      placeholder={t('inputs.phone.placeholder')}
    />
  )
})
