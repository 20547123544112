import {services} from '@services/index'
import {useTheme} from '@emotion/react'
import styled from '@emotion/styled'
import {Button} from '@ui/index'

import {observer} from 'mobx-react'

import useTranslation from 'next-translate/useTranslation'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const AuthorizationButtons = observer(() => {
  const {t} = useTranslation('features.header')
  const theme = useTheme()

  return (
    <Wrapper>
      <Button
        variant="text"
        textSize="defaultSemiBold"
        color={theme.palette.textPrimary}
        onClick={services.common.authorization.handleClickLogin}
      >
        {t('authorization.login')}
      </Button>
      <Button
        variant="text"
        textSize="defaultSemiBold"
        color={theme.palette.textPrimary}
        onClick={services.common.authorization.handleClickRegister}
      >
        {t('authorization.register')}
      </Button>
    </Wrapper>
  )
})
