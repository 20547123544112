import {ImageWithSkeleton} from '@ui/image-with-skeleton'
import {NO_HOTEL_IMAGE_SOURCE} from '@constants/hotel'
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '@ui/carousel'
import type {ComponentProps, FC} from 'react'
import styled from '@emotion/styled'
import {MEDIA_DEVICE_QUERY} from '@assets/theme/media'
import type {Images} from '@/types/common'
import {CarouselImageMini} from '@ui/carousel-image/ui/mini-slider'
import type {
  BookingRoomDetailImage,
  HotelImage,
} from '@/library/api/gql/generate-types'

const StyledCarousel = styled(Carousel)`
  width: 100%;
  height: 100%;
`

const StyledCarouselContent = styled(CarouselContent)`
  position: relative;
  border-radius: 12px;
  height: 600px;

  @media ${MEDIA_DEVICE_QUERY.LAPTOP} {
    height: 260px;
  }
`

const CarouselImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  cursor: col-resize;

  .image {
  }
`

const StyledCarouselItem = styled(CarouselItem)`
  @media ${MEDIA_DEVICE_QUERY.LAPTOP} {
    flex-basis: 48%;
    margin-right: 10px;
  }

  @media ${MEDIA_DEVICE_QUERY.MOBILE_VERY_LARGE} {
    flex-basis: 100%;
  }
`

interface Props extends ComponentProps<typeof Carousel> {
  images: Images | HotelImage[] | BookingRoomDetailImage[]
  alt: string
  addMiniSlider?: boolean
  startIndex?: number
}

/**
 * Для того что бы изменить размер самого слайдера, нужно менять у .carousel-content класса
 * @param images
 * @param alt
 * @param addMiniSlider
 * @param properties
 * @constructor
 */
export const CarouselImage: FC<Props> = ({
  images,
  alt,
  addMiniSlider,
  startIndex = 0,
  ...properties
}) => {
  return (
    <StyledCarousel
      orientation="horizontal"
      opts={{startIndex}}
      {...properties}
    >
      <StyledCarouselContent className="carousel-content">
        {images?.map((image) => {
          const imageAlt = 'alt' in image && image.alt

          return (
            <StyledCarouselItem key={image.id} className="carousel-item">
              <CarouselImageWrapper className="carousel-image-wrapper">
                <ImageWithSkeleton
                  className="carousel-image"
                  src={image.url || NO_HOTEL_IMAGE_SOURCE}
                  fill
                  alt={imageAlt || alt}
                  priority
                />
              </CarouselImageWrapper>
            </StyledCarouselItem>
          )
        })}
      </StyledCarouselContent>
      <CarouselPrevious withSlider={addMiniSlider} />
      <CarouselNext withSlider={addMiniSlider} />
      {images && addMiniSlider && <CarouselImageMini images={images} />}
    </StyledCarousel>
  )
}
