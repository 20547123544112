import {useTheme} from '@emotion/react'
import styled from '@emotion/styled'
import useTranslation from 'next-translate/useTranslation'
import type {FC} from 'react'
import {isObject, isString} from '@/library/utils/guards/types'
import {type FieldError} from 'react-hook-form'
import {Text} from '../../../typography'

const ErrorContainer = styled.div`
  min-height: 16px;
  margin: 5px 0 10px 0;
  display: flex;
  justify-content: end;
  align-items: center;
`

export interface InputError extends Omit<FieldError, 'message'> {
  message: {key: string; values: Record<string, number | string>}
}

interface IProps {
  error?: FieldError | string
}

export const Error: FC<IProps> = ({error}) => {
  const theme = useTheme()
  const {t} = useTranslation()

  if (isString(error)) {
    return (
      <ErrorContainer>
        <Text size="XS" color={theme.palette.textError}>
          {t(error)}
        </Text>
      </ErrorContainer>
    )
  }

  if (!error?.message) {
    return <ErrorContainer />
  }

  if (isString(error.message)) {
    return (
      <ErrorContainer>
        <Text size="XS" color={theme.palette.textError}>
          {t(error.message)}
        </Text>
      </ErrorContainer>
    )
  }

  // Нет способа заменить тип ошибок, проверки сверху исключают другой тип
  const typedError = error as unknown as InputError

  if (
    !isObject(typedError) ||
    !isObject(typedError.message) ||
    !('key' in typedError.message) ||
    !('values' in typedError.message)
  ) {
    return <ErrorContainer />
  }

  return (
    <ErrorContainer>
      <Text size="XS" color={theme.palette.textError}>
        {t(typedError.message.key, {...typedError.message.values})}
      </Text>
    </ErrorContainer>
  )
}
