import styled from '@emotion/styled'
import useTranslation from 'next-translate/useTranslation'
import {store} from '@store/index'
import {observer, useLocalObservable} from 'mobx-react'
import {type ChangeEvent, forwardRef, type HTMLAttributes} from 'react'
import {MEDIA_DEVICE_QUERY} from '@assets/theme/media'
import {services} from '@services/index'
import {useDebounce} from '@/library/hooks/use-debounce'
import {ProviderRatesSearchingTypeEnum} from '@/library/api/gql/generate-types'
import {selectors} from '../../../../store'

const DestinationInput = styled.input`
  color: ${({theme}) => theme.palette.textPrimary};
  font-family: ${({theme}) => theme.font.main};
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  border: 0;
  background-color: transparent;
  outline: none;
  width: 100%;

  @media ${MEDIA_DEVICE_QUERY.LAPTOP_LARGE} {
    font-size: 0.875rem;
  }

  &::placeholder {
    color: ${({theme}) => theme.palette.textPrimary};
    font-family: ${({theme}) => theme.font.main};
    font-weight: 500;
  }
`

export const SearchInputDestinationInput = observer(
  // eslint-disable-next-line react/display-name
  forwardRef<HTMLInputElement, HTMLAttributes<HTMLInputElement>>(
    (properties, ref) => {
      const {inputValue} = store().search.destination
      const {t} = useTranslation('features.search-input')
      const {searchingType} = useLocalObservable(selectors.selectSearchingType)

      const startSearchDestinations = useDebounce<string>((parameters) => {
        store().search.destination.setError('')
        services.components.search.destinationService.startSearchDestinations(
          parameters,
        )
      })

      const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        // При лучших предложениях инпут можно очистить только через кнопку очищения (она ставит автоматически geo searching type)
        if (searchingType === ProviderRatesSearchingTypeEnum.BestOffers) {
          return
        }

        store().search.destination.setInputValue(event.target.value)
        startSearchDestinations(event.target.value)
      }

      return (
        <DestinationInput
          ref={ref}
          value={inputValue}
          onChange={handleOnChange}
          placeholder={t('destination.subtitle')}
          readOnly={[
            ProviderRatesSearchingTypeEnum.BestOffers,
            ProviderRatesSearchingTypeEnum.Promotion,
          ].includes(searchingType)}
          {...properties}
        />
      )
    },
  ),
)

SearchInputDestinationInput.displayName = 'SearchInputDestinationInput'
