import {$gqlClient} from '@api/gql/client'
import {
  GetStaticPageByUrlDocument,
  type GetStaticPageByUrlQuery,
  type GetStaticPageByUrlQueryVariables,
  GetStaticPagePathListDocument,
  type GetStaticPagePathListQuery,
  type GetStaticPagePathListQueryVariables,
} from '@api/gql/generate-types'
import {clientHeaders} from '@utils/http/headers'
import {path} from 'ramda'

type GetStaticPagePathListContext = {
  headers?: Headers
}

const getPathList = ({headers}: GetStaticPagePathListContext) =>
  $gqlClient()
    .query<GetStaticPagePathListQuery, GetStaticPagePathListQueryVariables>({
      query: GetStaticPagePathListDocument,
      fetchPolicy: 'no-cache',
      context: {
        headers: headers || clientHeaders(),
      },
    })
    .then(
      path<GetStaticPagePathListQuery['staticPagePathList']>([
        'data',
        'staticPagePathList',
      ]),
    )

type GetStaticPageContentContext = {
  headers?: Headers
  variables: GetStaticPageByUrlQueryVariables
}

const getContent = ({variables, headers}: GetStaticPageContentContext) =>
  $gqlClient()
    .query<GetStaticPageByUrlQuery, GetStaticPageByUrlQueryVariables>({
      query: GetStaticPageByUrlDocument,
      fetchPolicy: 'no-cache',
      variables,
      context: {
        headers: headers || clientHeaders(),
      },
    })
    .then(
      path<GetStaticPageByUrlQuery['staticPageByUrl']>([
        'data',
        'staticPageByUrl',
      ]),
    )

export const staticPageApi = {
  getContent,
  getPathList,
}
