import {store} from '@store/index'
import type {CGlobalPageStoreSearchFilters} from '@store/pages/global/search/filters'
import {services} from '@services/index'
import {api} from '@api/index'
import {autorun, runInAction} from 'mobx'
import {equals} from 'ramda'
import {GlobalPageSearchFetchQueryBuilder} from '@services/pages/global/scenario/search/fetch-query-builder'

export class GlobalPageSearchFiltersService {
  public get state(): CGlobalPageStoreSearchFilters {
    return store().pages.global.search.filters
  }

  constructor(
    private readonly fetchBuilder: GlobalPageSearchFetchQueryBuilder,
  ) {
    this.runCheckAvailableSelectHotelTypes()
  }

  public async load() {
    try {
      this.state.resetState()
      this.state.setLoading(true)

      const parameters = this.fetchBuilder.buildRatesFiltersQuery()

      const {
        hotelTypes,
        countHotelsGroupedByRating,
        countHotelsGroupedByStars,
      } = await api.hotel.getHotelRatesFilters({
        variables: {
          params: parameters,
        },
      })

      this.state.setHotelTypes(hotelTypes)

      this.state.setCountHotelsGroupedByRating(countHotelsGroupedByRating)

      this.state.setCountHotelsGroupedByStars(countHotelsGroupedByStars)

      if (store().pages.global.search.hotels.isLoadedHotels) {
        this.state.setLoading(false)
      }
    } catch {
      this.state.resetState()
      this.state.setLoading(false)
    }
  }

  /**
   * Этот метод нужен для того, если есть выбранные фильтра по типу отелей, и пришли новые, в которых нет id, которые есть в выбранных - то мы их удаляем, что бы не было рассинхрона
   * Если мы нашли рассихнрон - делаем перезапрос на получение данных с синхронизированными данными
   * @private
   */
  private runCheckAvailableSelectHotelTypes(): void {
    autorun(() => {
      if (this.state.hotelTypes) {
        const filteredSelectTypes = this.state.selectHotelTypes.filter(
          (selectType) => {
            return this.state.hotelTypes?.some(
              (hotelType) => hotelType.id === selectType,
            )
          },
        )
        runInAction(() => {
          if (!equals(filteredSelectTypes, this.state.selectHotelTypes)) {
            this.state.setSelectHotelTypes(filteredSelectTypes)
            services.components.search.handleSearch()
          }
        })
      }
    })
  }
}
