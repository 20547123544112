import {Container} from '@components/layouts'
import {ProfileSideMenu} from '@components/features/profile-side-menu'
import styled from '@emotion/styled'
import {Title} from '@ui/typography'
import useTranslation from 'next-translate/useTranslation'
import {BookingsList} from './ui/bookings-list'

const StyledContainer = styled(Container)`
  min-height: 100%;
  height: fit-content;
`

const Wrapper = styled.div`
  margin-top: 40px;
  display: flex;
  gap: 60px;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
  max-width: 100%;
`

const StyledTitle = styled(Title)`
  text-align: left;
  color: ${({theme}) => theme.palette.textPrimary};
`

export const Content = () => {
  const {t} = useTranslation('profile.bookings')
  return (
    <StyledContainer>
      <Wrapper>
        <ProfileSideMenu />
        <ContentWrapper>
          <StyledTitle>{t('title')}</StyledTitle>
          <BookingsList />
        </ContentWrapper>
      </Wrapper>
    </StyledContainer>
  )
}
