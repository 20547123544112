import {MEDIA_DEVICE_QUERY} from '@/assets/theme/media'
import styled from '@emotion/styled'
import {Skeleton} from 'antd'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ButtonSkeleton = styled(Skeleton.Button)`
  margin: 6px 0;
  width: 260px !important;
  height: 49px !important;
  border-radius: 12px !important;

  @media ${MEDIA_DEVICE_QUERY.MOBILE_MEDIUM} {
    margin: unset;
    width: 100% !important;
  }
`

export const ShowAllHotelsSkeleton = () => {
  return (
    <Wrapper>
      <ButtonSkeleton active />
    </Wrapper>
  )
}
