import {GlobalHydrateService} from '@services/common/global-hydrate'
import {store} from '@/library/store'
import {api} from '@/library/api'
import {DEFAULT_FIRST_ITEMS_BOOKINGS} from '@/slices/profile/bookings'
import type {ProfileBookingsPageProperties} from '@slices/profile/bookings/lib/types'

type HydrationInitialData =
  Required<ProfileBookingsPageProperties>['hydrationData']

export class ProfileBookingsService extends GlobalHydrateService<HydrationInitialData> {
  private get state() {
    return store().pages.profile.bookings
  }

  public hydrate(initialData: HydrationInitialData): void {
    if (initialData.bookings) {
      this.state.setBookings(initialData.bookings.data)
      this.state.setPaginatorInfo(initialData.bookings.paginatorInfo)
    }
    super.hydrate(initialData)
  }

  public loadMore(): void {
    if (!this.state.bookings) {
      return
    }

    if (!this.state.paginatorInfo?.hasMorePages) {
      return
    }

    const updatedPage = this.state.page + 1
    this.state.setLoading(true)
    api.booking
      .getBookings({
        variables: {
          page: updatedPage,
          first: DEFAULT_FIRST_ITEMS_BOOKINGS,
        },
      })
      .then((bookings) => {
        if (bookings) {
          this.state.setPage(updatedPage)
          this.state.setBookings([
            ...(this.state.bookings || []),
            ...bookings.data,
          ])
          this.state.setPaginatorInfo(bookings.paginatorInfo)
        }
      })
      .finally(() => {
        this.state.setLoading(false)
      })
  }

  public async cancelBooking(id: number): Promise<void> {
    const data = await api.booking.cancelBooking({variables: {id}})

    const {bookings} = store().pages.profile.bookings

    if (!data?.status || !bookings || bookings.length === 0) {
      return
    }

    store().pages.profile.bookings.setBookingStatus(id, data.status)
  }

  public async getPaymentUrl(id: number): Promise<string | null> {
    const data = await api.booking.getBookingPaymentLink({variables: {id}})

    return data?.payment_url || null
  }
}
