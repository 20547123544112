import type {ReactNode} from 'react'
import {ProfilePaths} from '@/types/enums/routes'
import {Icon} from '@ui/icon'

export type ProfileSideMenuItem = {
  id: ProfilePaths
  icon: ReactNode
  label: string
  href: ProfilePaths
}

export const PROFILE_SIDE_MENU_ITEMS: ProfileSideMenuItem[] = [
  {
    id: ProfilePaths.REVIEWS,
    label: 'reviews',
    icon: <Icon name="stroke_star" width={20} height={20} />,
    href: ProfilePaths.REVIEWS,
  },
  {
    id: ProfilePaths.FAVORITES,
    label: 'favorites',
    icon: <Icon name="like_stroke" width={20} height={20} />,
    href: ProfilePaths.FAVORITES,
  },
  {
    id: ProfilePaths.BOOKINGS,
    label: 'bookings',
    icon: <Icon name="calendar" width={20} height={20} />,
    href: ProfilePaths.BOOKINGS,
  },
  {
    id: ProfilePaths.BALANCE,
    label: 'balance',
    icon: <Icon name="wallet" width={20} height={20} />,
    href: ProfilePaths.BALANCE,
  },
  {
    id: ProfilePaths.PASSWORD,
    label: 'password',
    icon: <Icon name="open_lock" width={20} height={20} />,
    href: ProfilePaths.PASSWORD,
  },
  {
    id: ProfilePaths.SETTINGS,
    icon: <Icon name="settings_profile" width={20} height={20} />,
    label: 'settings',
    href: ProfilePaths.SETTINGS,
  },
]
