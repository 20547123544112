import {isEmpty} from 'ramda'

export type ApiException = readonly [Response, {message: string | string[]}]

export type UnhandledException = any

export const isApiException = (reason: any): reason is ApiException =>
  Array.isArray(reason) &&
  reason[0] instanceof Response &&
  reason[1] instanceof Object &&
  'message' in reason[1] &&
  (typeof reason[1].message === 'string' || Array.isArray(reason[1].message))

export const isUnauthenticatedException = (reason: any): boolean =>
  isApiException(reason) &&
  Array.isArray(reason) &&
  reason[0] instanceof Response &&
  'status' in reason[0] &&
  reason[0].status === 401

/**
 * @description Helper function to use for example within `cond` function as the default case.
 */
export const isUnhandledException = (value: any): value is UnhandledException =>
  !isEmpty(value)
