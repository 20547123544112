import type {Redirect} from 'next/types'

export const isServer = (): boolean => typeof window === 'undefined'

export const isClient = (): boolean => typeof window !== 'undefined'

export const redirectScenario = (
  destination: Redirect['destination'],
  permanent = false,
): {redirect: Redirect} => {
  return {
    redirect: {
      destination,
      statusCode: permanent ? 301 : 302,
    },
  }
}
