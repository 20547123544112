import {store} from '@store/index'
import {api} from '@api/index'

export class GlobalPageHotelRatesService {
  private get state() {
    return store().pages.global.hotel.rates
  }

  public async load() {
    const hotelId = store().pages.global.hotel.data?.id

    if (!hotelId) {
      return
    }

    if (!this.state.ratesSearchParameters) {
      return
    }

    this.state.setIsLoading(true)

    api.hotel
      .getRates({
        variables: {
          id: hotelId,
          rates: {
            ...this.state.ratesSearchParameters,
          },
        },
      })
      .then((rates) => {
        if (rates) {
          this.state.setData(rates)
        }
      })
      .finally(() => this.state.setIsLoading(false))
  }
}
