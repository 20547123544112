import {Link, Text} from '@/library/components/ui'
import styled from '@emotion/styled'
import useTranslation from 'next-translate/useTranslation'
import {useTheme} from '@emotion/react'
import {ENVIRONMENT} from '@/library/utils/guards/environment'
import {VerticalDivider} from '@/library/components/ui/divider'
import {Icon} from '@/library/components/ui/icon'
import {TRANSITION_STYLES} from '@/library/constants/css'
import {SOCIAL_ICON_SIZE} from '../lib/constants'

const StyledLink = styled(Link)`
  transition: opacity ${TRANSITION_STYLES};

  &:hover {
    opacity: 0.7;
  }
`

const SocialWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`

const StyledText = styled(Text)`
  font-weight: 500;
`

export const FeedbackSocialsDesktop = () => {
  const {t} = useTranslation('features.feedback-socials')
  const theme = useTheme()

  return (
    <SocialWrapper>
      <StyledText size="boldXS" color={theme.palette.textSecondary}>
        {t('leave_review')}
      </StyledText>
      <IconWrapper>
        <StyledLink href={ENVIRONMENT.OTZOVIK_SHARE_LINK} target="_blank">
          <Icon
            name="otzovik"
            width={SOCIAL_ICON_SIZE}
            height={SOCIAL_ICON_SIZE}
          />
        </StyledLink>

        <VerticalDivider />
        <StyledLink href={ENVIRONMENT.YANDEX_SHARE_LINK} target="_blank">
          <Icon
            name="yandex"
            width={SOCIAL_ICON_SIZE}
            height={SOCIAL_ICON_SIZE}
          />
        </StyledLink>
      </IconWrapper>
      <StyledText size="boldXS" color={theme.palette.textSecondary}>
        {t('subscribe_to_social')}
      </StyledText>
      <IconWrapper>
        <StyledLink href={ENVIRONMENT.VK} target="_blank">
          <Icon
            name="vk_coloured"
            width={SOCIAL_ICON_SIZE}
            height={SOCIAL_ICON_SIZE}
          />
        </StyledLink>

        <VerticalDivider />
        <StyledLink href={ENVIRONMENT.TG} target="_blank">
          <Icon
            name="telegram_coloured"
            width={SOCIAL_ICON_SIZE}
            height={SOCIAL_ICON_SIZE}
          />
        </StyledLink>

        <VerticalDivider />
        <StyledLink href={ENVIRONMENT.DZEN} target="_blank">
          <Icon
            name="dzen"
            color={theme.palette.backgroundPrimary}
            width={SOCIAL_ICON_SIZE}
            height={SOCIAL_ICON_SIZE}
          />
        </StyledLink>
      </IconWrapper>
    </SocialWrapper>
  )
}
