import {makeAutoObservable} from 'mobx'
import type {UpdateMyUserProfileFormData} from '@/library/constants/form-validation/update-my-user-profile-form'
import {isNull} from '@utils/guards/types'
import {isUser} from '@utils/guards/business'
import {RoleEnum} from '@api/gql/generate-types'
import {USER_STORE_INITIAL_STATE} from './constants'
import type {UserStoreInitialStateType} from './types'

export class CUserStore {
  constructor() {
    makeAutoObservable(this)
  }

  public get isPartner(): boolean {
    if (!this._user?.roles) {
      return false
    }

    return this._user.roles.some((role) => role?.name === RoleEnum.Affiliate)
  }

  // Данные пользователя
  private _user: UserStoreInitialStateType['user'] =
    USER_STORE_INITIAL_STATE.user

  public get user(): UserStoreInitialStateType['user'] {
    return this._user
  }

  public get profile(): UserProfile {
    return this._user?.profile
  }

  public get isAuthorized(): boolean {
    return !isNull(this._user) && isUser(this._user)
  }

  public setUser(user: UserStoreInitialStateType['user']) {
    this._user = user
  }

  public setBalance(balance: number) {
    if (this._user?.balance) {
      this._user.balance.amount = balance
    }
    if (this._user) {
      this._user.balance = {amount: balance}
    }
  }

  public setProfile(profile: UpdateMyUserProfileFormData) {
    if (!this._user) {
      return
    }
    this._user.profile = profile
  }
}
