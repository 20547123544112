import styled from '@emotion/styled'

export const ButtonIcon = styled.button`
  display: flex;
  justify-content: start;
  align-items: center;

  border: 1px solid ${({theme}) => theme.palette.borderTertiary};
  border-radius: 6px;
  background-color: ${({theme}) => theme.palette.backgroundQuaternary};

  padding: 5px;

  cursor: pointer;

  svg {
    color: ${({theme}) => theme.palette.textAccentPrimary};
  }

  &:hover {
    background-color: ${({theme}) => theme.palette.backgroundAccentPrimary};

    svg {
      color: ${({theme}) => theme.palette.textQuaternary};
    }
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${({theme}) => theme.palette.backgroundTertiary};

    svg {
      color: ${({theme}) => theme.palette.textQuaternary};
    }
  }
`
