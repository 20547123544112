import type {DynamicGlobalPageProperties} from '@slices/global/lib/scenarios'
import type {ApplicationProperties} from '@/types/ui'
import type {GetHotelQuery} from '@/library/api/gql/generate-types'
import type {ParsedUrlQuery} from 'node:querystring'

interface Props extends ApplicationProperties, DynamicGlobalPageProperties {
  query: ParsedUrlQuery
}

export type GlobalPageProperties = HydrationPropertiesTemplate<Props>

export type Hotel = NonNullable<GetHotelQuery['hotel']>

export enum BreadcrumbKeyEnum {
  COUNTRY = 'country',
  CITY = 'city',
  HOTEL = 'hotel',
  TYPE = 'type',
  HOME = 'home',
}
