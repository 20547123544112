import {observer} from 'mobx-react'
import {store} from '@store/index'
import styled from '@emotion/styled'
import {HotelScenarioRatesCardsItem} from '@slices/global/ui/hotel-scenario/rates/cards/card.item'
import {HotelScenarioRatesCardsItemSkeleton} from '@slices/global/ui/hotel-scenario/rates/cards/card.item.sleleton'
import {HotelScenarioRatesCardsItemNotFound} from '@slices/global/ui/hotel-scenario/rates/cards/card.item.not-found'
import {isEmpty} from 'ramda'
import {useLocalObservable} from 'mobx-react-lite'
import {HotelScenarioRatesFilters} from '@slices/global/ui/hotel-scenario/rates/filters'
import {HotelScenarioRatesFiltersSkeleton} from '@slices/global/ui/hotel-scenario/rates/filters/filters.skeleton'
import {HotelScenarioRatesCardsItemFacilityModal} from '@slices/global/ui/hotel-scenario/rates/cards/card.item.facility-modal'
import {Fragment} from 'react'
import {HotelScenarioRatesCardsItemRoomRateNotFound} from '@slices/global/ui/hotel-scenario/rates/cards/card.room.rate.not-found'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

const NotFoundWrapper = styled.div``

const selectHotelRates = () => ({
  get data() {
    const {rates} = store().pages.global.hotel

    if (rates.filters.hasExistFilters()) {
      return rates.filters.getFilteredData(rates.data)
    }

    return rates.data
  },
  get isLoading() {
    return store().pages.global.hotel.rates.isLoading
  },
})

export const HotelScenarioRatesCards = observer(() => {
  const {data, isLoading} = useLocalObservable(selectHotelRates)
  const {filters} = store().pages.global.hotel.rates

  if (isLoading) {
    return (
      <>
        <HotelScenarioRatesFiltersSkeleton />
        <HotelScenarioRatesCardsItemSkeleton />
      </>
    )
  }

  if (!data || isEmpty(data)) {
    return <HotelScenarioRatesCardsItemNotFound />
  }

  const isEmptyAllRates = data.every(
    (provider) => provider.roomRates.length === 0,
  )
  if (isEmptyAllRates && filters.hasExistFilters()) {
    return (
      <>
        <HotelScenarioRatesFilters />
        <NotFoundWrapper>
          <HotelScenarioRatesCardsItemRoomRateNotFound />
        </NotFoundWrapper>
      </>
    )
  }

  return (
    <>
      <HotelScenarioRatesFilters />
      <Wrapper>
        {data.map((provider) =>
          provider.roomRates.map((room) => (
            <HotelScenarioRatesCardsItem
              key={room.roomId}
              rateProvider={provider}
              room={room}
            />
          )),
        )}
        <HotelScenarioRatesCardsItemFacilityModal />
      </Wrapper>
    </>
  )
})
