import {MEDIA_DEVICE_QUERY} from '@/assets/theme/media'
import {Title} from '@/library/components/ui/typography'
import styled from '@emotion/styled'
import type {FC, PropsWithChildren} from 'react'
import useTranslation from 'next-translate/useTranslation'
import type {TitleType} from '@ui/typography/title'

const Wrapper = styled.article`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 52px;

  height: 100%;

  @media ${MEDIA_DEVICE_QUERY.LAPTOP} {
    gap: 26px;
  }
`
const DestinationCardWrapper = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-content: center;
  flex-wrap: wrap;

  gap: 20px;

  @media ${MEDIA_DEVICE_QUERY.DESKTOP} {
    justify-content: start;
  }

  @media ${MEDIA_DEVICE_QUERY.LAPTOP} {
    justify-content: start;
  }

  @media ${MEDIA_DEVICE_QUERY.MOBILE_LARGE} {
    justify-content: center;
  }
`

interface ListWrapperProps {
  className?: string
  headlineType?: TitleType
}

export const ListWrapper: FC<PropsWithChildren<ListWrapperProps>> = ({
  className,
  children,
  headlineType,
}) => {
  const {t} = useTranslation('features.popular-city-destinations')

  return (
    <Wrapper className={className}>
      <Title type={headlineType}>{t('title')}</Title>
      <DestinationCardWrapper>{children}</DestinationCardWrapper>
    </Wrapper>
  )
}
