import type {Images} from '@/types/common'
import type {
  BookingRoomDetailImage,
  HotelImage,
} from '@/library/api/gql/generate-types'
import {type Dispatch, type FC, type SetStateAction} from 'react'
import styled from '@emotion/styled'
import {Modal} from '../modal'
import {CarouselImage} from '../carousel-image'
import {DEFAULT_START_INDEX} from './lib/constants'

const CarouselImageWrapper = styled.div`
  width: 100%;

  .carousel-content {
    .image {
      object-fit: contain;
    }
  }
`

const StyledModal = styled(Modal)`
  padding: 40px;
`

interface IProps {
  alt: string
  images: Images | HotelImage[] | BookingRoomDetailImage[]
  currentIndex: number
  opened: boolean
  setOpened: Dispatch<SetStateAction<boolean>>
}

export const CarouselImagePopup: FC<IProps> = ({
  alt,
  images,
  currentIndex,
  opened,
  setOpened,
}) => {
  return (
    <StyledModal width={800} open={opened} onCancel={() => setOpened(false)}>
      <CarouselImageWrapper>
        <CarouselImage
          startIndex={currentIndex || DEFAULT_START_INDEX}
          images={images}
          alt={`${alt} ${currentIndex}`}
          addMiniSlider
        />
      </CarouselImageWrapper>
    </StyledModal>
  )
}
