import {css} from '@emotion/react'

export const TextSizes = {
  '3XS': css`
    font-size: 10px;
    font-weight: 400;
    line-height: 18px;
  `,
  bold3XS: css`
    font-size: 10px;
    font-weight: 600;
    line-height: 18px;
  `,
  '2XS': css`
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  `,
  XS: css`
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  `,
  semiBoldXS: css`
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
  `,
  boldXS: css`
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
  `,
  S: css`
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  `,
  boldS: css`
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
  `,
  semiBoldS: css`
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
  `,
  SM: css`
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
  `,
  boldSM: css`
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
  `,
  M: css`
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
  `,
  semiBoldM: css`
    font-size: 20px;
    font-weight: 500;
    line-height: 25px;
  `,
  boldM: css`
    font-size: 20px;
    font-weight: 600;
    line-height: 25px;
  `,
  L: css`
    font-size: 25px;
    font-weight: 400;
    line-height: 33px;
  `,
  boldL: css`
    font-size: 25px;
    font-weight: 700;
    line-height: 33px;
  `,
  XL: css`
    font-size: 40px;
    font-weight: 400;
    line-height: 46px;
  `,
  boldXL: css`
    font-size: 40px;
    font-weight: 600;
    line-height: 46px;
  `,
  XXL: css`
    font-size: 44px;
    font-weight: 400;
    line-height: 55px;
  `,
  boldXXL: css`
    font-size: 44px;
    font-weight: 600;
    line-height: 55px;
  `,
}

export type TextSize = keyof typeof TextSizes
