import type {DocumentNode} from 'graphql/index'
import {
  CreateAffiliateLinkVisitDocument,
  PushAddHotelToFavoriteDocument,
  PushApplyPromoCodeDocument,
  PushBookingFormDocument,
  PushBookingFormFieldDocument,
  PushBookingStatusDocument,
  PushBreadcrumbsDocument,
  PushFailedFingerprintDocument,
  PushLoadMoreEventDocument,
  PushPayDocument,
  PushPayFromAccountDocument,
  PushPayFromAccountWithSelectDocument,
  PushSearchParamsDocument,
  PushUserVisitDocument,
  PushViewBestOffersDocument,
  PushViewGlobalPromotionDocument,
  PushCallYaTaxiDocument,
} from '@api/gql/generate-types'
import type {AnalyticsType} from '@/types/analytics'

export const ANALYTICS_DOCUMENTS: Record<AnalyticsType, DocumentNode> = {
  ADD_HOTEL_TO_FAVORITE: PushAddHotelToFavoriteDocument,
  APPLY_PROMO_CODE: PushApplyPromoCodeDocument,
  BOOKING_FORM: PushBookingFormDocument,
  BOOKING_FORM_FIELD: PushBookingFormFieldDocument,
  BOOKINGS_STATUS: PushBookingStatusDocument,
  BREADCRUMBS: PushBreadcrumbsDocument,
  FAILED_FINGER_PRINT: PushFailedFingerprintDocument,
  LOAD_MORE_EVENTS: PushLoadMoreEventDocument,
  PAY: PushPayDocument,
  PAY_FROM_ACCOUNT: PushPayFromAccountDocument,
  PAY_FROM_ACCOUNT_WITH_SELECT: PushPayFromAccountWithSelectDocument,
  SEARCH_PARAMS: PushSearchParamsDocument,
  USER_VISIT: PushUserVisitDocument,
  VIEW_BEST_OFFERS: PushViewBestOffersDocument,
  VIEW_GLOBAL_PROMOTIONS: PushViewGlobalPromotionDocument,
  AFFILIATE_LINK_VISIT: CreateAffiliateLinkVisitDocument,
  CALL_YANDEX_TAXI: PushCallYaTaxiDocument,
}
