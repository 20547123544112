import type {ApplicationCustomTheme} from '@assets/theme'
import {css} from '@emotion/react'

export const DEFAULT_LINK_STYLES = (theme: ApplicationCustomTheme) => css`
  all: unset;

  color: ${theme.palette.textQuaternary};
  background-color: ${theme.palette.backgroundAccentPrimary};
  cursor: pointer;
  transition:
    color 0.2s,
    background-color 0.2s,
    border 0.2s;

  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 12px;

  box-sizing: border-box;

  &:focus-visible {
    outline: 1px solid ${theme.palette.outlinePrimary}; /* Устанавливаем свой outline при фокусе */
  }
`
