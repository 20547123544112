import {interceptHttpsStatusCodes} from '@library/api/request/interceptors/catch'
import type {IHttpStatusCodeHandlers} from '@api/request/interceptors/catch/status-code/lib/types'

const mime = (response: Response) =>
  response.headers.get('content-type')?.split(';')[0]

const text = async (response: Response) => {
  const clone = response.clone()

  return response.text().then((value) => [clone, value] as const)
}

const json = async (response: Response) => {
  const clone = response.clone()

  return response.json().then((value) => [clone, value] as const)
}

const blob = async (response: Response) => {
  const clone = response.clone()
  return response.blob().then((value) => [clone, value] as const)
}

const empty = (response: Response) =>
  Promise.resolve([response, undefined] as const)

const parse = (response: Response) => {
  switch (mime(response)) {
    case 'text/html':
    case 'text/plain': {
      return text(response)
    }

    case 'application/json': {
      return json(response)
    }
    case 'application/pdf': {
      return blob(response)
    }

    default: {
      return empty(response)
    }
  }
}

const decide = (parameters: HttpRequestParameters) => {
  const [response] = parameters

  if (response.ok) {
    return parameters
  }

  throw parameters
}

const handleCatch = (
  parameters: HttpRequestParameters,
  customHandlers?: Partial<IHttpStatusCodeHandlers>,
) => {
  const isResponse =
    Array.isArray(parameters) && parameters[0] instanceof Response

  if (isResponse) {
    interceptHttpsStatusCodes(parameters, customHandlers)
  }

  throw parameters
}

export const request = <T>(
  info: URL,
  init?: RequestInit,
  customHandlers?: Partial<IHttpStatusCodeHandlers>,
): Promise<HttpRequestParameters<T>> =>
  fetch(info, init)
    .then(parse)
    .then(decide)
    .catch((error) => handleCatch(error, customHandlers))
