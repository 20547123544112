import {ECurrency} from '@/types/enums/currency'
import type {Translate} from 'next-translate'

export const getCurrency = (t: Translate, currencyCode?: string | null) => {
  if (!currencyCode) {
    return t('currency_ru')
  }

  return currencyCode === ECurrency.RUB ? t('currency_ru') : currencyCode
}
