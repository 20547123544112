import {MapLayers} from '@/slices/global/ui/search-scenario/map/lib/types'
import {makeAutoObservable} from 'mobx'
import type {MapPoints} from '@ui/yandex-map'

const initialState = {
  zoom: 10,
  center: [54.7344226837155, 20.507248878479] as [number, number],
  hotelCenter: null,
  hotelBounds: null,
  bounds: [
    [54.826377868652, 20.39786529541],
    [54.642467498779, 20.616632461548],
  ],
  layer: MapLayers.MAP,
}

type MapCenterLocation = [lat: number, lng: number]

export class CGlobalPageStoreSearchMap {
  constructor() {
    makeAutoObservable(this)
  }

  private _loading = false

  public get loading(): boolean {
    return this._loading
  }

  private _layer = initialState.layer

  public get layer(): MapLayers {
    return this._layer
  }

  private _modalOpen = false

  public get modalOpen(): boolean {
    return this._modalOpen
  }

  private _error = ''

  public get error(): string {
    return this._error
  }

  private _zoom = initialState.zoom

  public get zoom(): number {
    return this._zoom
  }

  private _points: MapPoints[] | null = null

  public get points(): MapPoints[] | null {
    return this._points
  }

  private _bounds: number[][] = initialState.bounds

  public get bounds(): number[][] {
    return this._bounds
  }

  private _hotelBounds: number[][] | null = initialState.hotelBounds

  public get hotelBounds(): number[][] | null {
    return this._hotelBounds
  }

  private _center: MapCenterLocation = initialState.center

  public get center(): MapCenterLocation {
    return this._center
  }

  private _hotelCenter: MapCenterLocation | null = initialState.hotelCenter

  public get hotelCenter(): MapCenterLocation | null {
    return this._hotelCenter
  }

  public setBounds(bounds: number[][]): void {
    this._bounds = bounds
  }

  public setHotelBounds(bounds: number[][] | null): void {
    this._hotelBounds = bounds
  }

  public setLoading(loading: boolean): void {
    this._loading = loading
  }

  public setModalOpen(modalOpen: boolean): void {
    this._modalOpen = modalOpen
  }

  public setLayer(layer: MapLayers): void {
    this._layer = layer
  }

  public setError(error: string): void {
    this._error = error
  }

  public setZoom(zoom: number): void {
    this._zoom = zoom
  }

  public setPoints(points: MapPoints[] | null): void {
    this._points = points
  }

  public setCenter(center: MapCenterLocation): void {
    this._center = center
  }

  public setHotelCenter(center: MapCenterLocation | null): void {
    this._hotelCenter = center
  }

  private _alreadyOpenPoints: number[] = []

  public isAlreadyOpenedPoint(id: number): boolean {
    return this._alreadyOpenPoints.includes(id)
  }

  public setAlreadyOpenPoints(points: number[]) {
    this._alreadyOpenPoints = points
  }

  public addAlreadyOpenPoints(id: number) {
    this._alreadyOpenPoints.push(id)
  }

  public resetState() {
    this.setError('')
    this.setLoading(false)
    this.setPoints(null)
    this.setAlreadyOpenPoints([])
  }
}
