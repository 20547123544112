import type {BookingFormFields} from '@/library/api/gql/generate-types'
import {services} from '@/library/services'
import {INPUT_DEBOUNCE_TIMEOUT} from './constants'

export const getInputDebounceConfig = (
  field: BookingFormFields,
  guestIndex: number,
): [() => void, number] => {
  return [
    (value?: string | null) => {
      if (!value) {
        return
      }

      services.common.analytics.pushBookingFieldInput(field, value, guestIndex)
    },
    INPUT_DEBOUNCE_TIMEOUT,
  ]
}
