import {makeAutoObservable} from 'mobx'
import {DEFAULT_PAGE} from '@constants/common'

export class CProfileReviewsWithoutReview {
  constructor() {
    makeAutoObservable(this)
  }

  private _data: UserCompleteBookingData | null = null

  public get data(): UserCompleteBookingData | null {
    return this._data
  }

  public setData(data: UserCompleteBookingData | null): void {
    this._data = data
  }

  private _paginatorInfo: UserCompleteBookingPaginatorInfo | null = null

  public get paginatorInfo(): UserCompleteBookingPaginatorInfo | null {
    return this._paginatorInfo
  }

  public setPaginatorInfo(data: UserCompleteBookingPaginatorInfo | null): void {
    this._paginatorInfo = data
  }

  private _page: number = DEFAULT_PAGE

  public get page(): number {
    return this._page
  }

  private _isLoading = false

  public get isLoading(): boolean {
    return this._isLoading
  }

  public setPage(page: number): void {
    this._page = page
  }

  public setIsLoading(flag: boolean): void {
    this._isLoading = flag
  }

  public getById(
    bookingId: number,
  ): UserCompleteBookingData[number] | undefined {
    return this._data?.find((review) => review.id === bookingId)
  }

  public remove(bookingId: number) {
    this._data = this._data?.filter((review) => review.id !== bookingId) || null
  }
}
