import useEmblaCarousel, {type UseEmblaCarouselType} from 'embla-carousel-react'
import {
  type ComponentProps,
  createContext,
  type FC,
  forwardRef,
  type HTMLAttributes,
  type KeyboardEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import {Button} from '@ui/button'
import {Icon} from '@ui/icon'
import styled from '@emotion/styled'
import {css} from '@emotion/react'
import {TRANSITION_STYLES} from '@/library/constants/css'

type CarouselApi = UseEmblaCarouselType[1]
type UseCarouselParameters = Parameters<typeof useEmblaCarousel>
type CarouselOptions = UseCarouselParameters[0]
type CarouselPlugin = UseCarouselParameters[1]

type CarouselProps = {
  opts?: CarouselOptions
  plugins?: CarouselPlugin
  orientation?: 'horizontal' | 'vertical'
  setApi?: (api: CarouselApi) => void
}

type CarouselContextProps = {
  carouselRef: ReturnType<typeof useEmblaCarousel>[0]
  api: ReturnType<typeof useEmblaCarousel>[1]
  scrollPrev: () => void
  scrollNext: () => void
  canScrollPrev: boolean
  canScrollNext: boolean
} & CarouselProps

const CarouselContext = createContext<CarouselContextProps | null>(null)

export function useCarousel() {
  const context = useContext(CarouselContext)

  if (!context) {
    throw new Error('useCarousel must be used within a <Carousel />')
  }

  return context
}

const CarouselWrapper = styled.div`
  position: relative;
  height: 100%;
`

const Carousel = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement> & CarouselProps
>(
  (
    {
      orientation = 'horizontal',
      opts,
      setApi,
      plugins,
      className,
      children,
      ...properties
    },
    ref,
  ) => {
    const [carouselRef, api] = useEmblaCarousel(
      {
        ...opts,
        axis: orientation === 'horizontal' ? 'x' : 'y',
      },
      plugins,
    )

    const [canScrollPrevious, setCanScrollPrevious] = useState(false)
    const [canScrollNext, setCanScrollNext] = useState(false)

    const onSelect = useCallback((api: CarouselApi) => {
      if (api) {
        setCanScrollPrevious(api.canScrollPrev())
        setCanScrollNext(api.canScrollNext())
      }
    }, [])

    const scrollPrevious = useCallback(() => {
      api?.scrollPrev()
    }, [api])

    const scrollNext = useCallback(() => {
      api?.scrollNext()
    }, [api])

    const handleKeyDown = useCallback(
      (event: KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'ArrowLeft') {
          event.preventDefault()
          scrollPrevious()
        } else if (event.key === 'ArrowRight') {
          event.preventDefault()
          scrollNext()
        }
      },
      [scrollPrevious, scrollNext],
    )

    useEffect(() => {
      if (api && setApi) {
        setApi(api)
      }
    }, [api, setApi])

    useEffect(() => {
      if (api) {
        onSelect(api)
        api.on('reInit', onSelect)
        api.on('select', onSelect)
      }

      return () => {
        api?.off('select', onSelect)
      }
    }, [api, onSelect])
    return (
      <CarouselContext.Provider
        value={{
          carouselRef,
          api,
          opts,
          orientation:
            orientation || (opts?.axis === 'y' ? 'vertical' : 'horizontal'),
          scrollPrev: scrollPrevious,
          scrollNext,
          canScrollPrev: canScrollPrevious,
          canScrollNext,
        }}
      >
        <CarouselWrapper
          ref={ref}
          onKeyDownCapture={handleKeyDown}
          className={className}
          role="region"
          aria-roledescription="carousel"
          {...properties}
        >
          {children}
        </CarouselWrapper>
      </CarouselContext.Provider>
    )
  },
)
Carousel.displayName = 'Carousel'

const CarouselContentWrapper = styled.div<{
  orientation?: CarouselProps['orientation']
}>`
  display: flex;
  height: 100%;
  width: 100%;

  ${({orientation}) =>
    orientation === 'horizontal'
      ? css``
      : css`
          flex-direction: column;
        `}
`

const CarouselContentContent = styled.div`
  overflow: hidden;
  height: 100%;
`

const CarouselContent = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({className, ...properties}, ref) => {
  const {carouselRef, orientation} = useCarousel()

  return (
    <CarouselContentContent ref={carouselRef} className={className}>
      <CarouselContentWrapper
        ref={ref}
        orientation={orientation}
        {...properties}
      />
    </CarouselContentContent>
  )
})
CarouselContent.displayName = 'CarouselContent'

const CarouselItemWrapper = styled.div<{$orientation?: string}>`
  flex: 0 0 100%;
  min-width: 0;
  position: relative;
`

const CarouselItem = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({...properties}, ref) => {
    const {orientation} = useCarousel()

    return (
      <CarouselItemWrapper
        ref={ref}
        role="group"
        $orientation={orientation}
        aria-roledescription="slide"
        {...properties}
      />
    )
  },
)

CarouselItem.displayName = 'CarouselItem'

const StyledPreviousButton = styled(Button)<{withSlider?: boolean}>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
  opacity: 0.85;
  z-index: 3;

  transition: opacity ${TRANSITION_STYLES};

  &:hover {
    opacity: 1;
  }

  ${({withSlider}) =>
    withSlider &&
    css`
      top: calc(50% - 40px);
    `}
`

interface IPreviousButtonProps extends ComponentProps<typeof Button> {
  withSlider?: boolean
}

const CarouselPrevious: FC<IPreviousButtonProps> = ({
  variant = 'tertiary',
  ...properties
}) => {
  const {scrollPrev, canScrollPrev} = useCarousel()

  if (!canScrollPrev) {
    return null
  }

  return (
    <StyledPreviousButton
      variant={variant}
      onClick={scrollPrev}
      shape="circle"
      paddingSize="S"
      {...properties}
    >
      <Icon name="button_slider_left" width={9} height={9} />
    </StyledPreviousButton>
  )
}

CarouselPrevious.displayName = 'CarouselPrevious'

const StyledNextButton = styled(Button)<{withSlider?: boolean}>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  opacity: 0.85;
  z-index: 3;

  transition: opacity ${TRANSITION_STYLES};

  &:hover {
    opacity: 1;
  }

  ${({withSlider}) =>
    withSlider &&
    css`
      top: calc(50% - 40px);
    `}
`

interface INextButtonProps extends ComponentProps<typeof Button> {
  withSlider?: boolean
}

const CarouselNext: FC<INextButtonProps> = ({
  variant = 'tertiary',
  ...properties
}) => {
  const {scrollNext, canScrollNext} = useCarousel()

  if (!canScrollNext) {
    return null
  }

  return (
    <StyledNextButton
      variant={variant}
      onClick={scrollNext}
      shape="circle"
      paddingSize="S"
      {...properties}
    >
      <Icon name="button_slider_right" width={9} height={9} />
    </StyledNextButton>
  )
}

CarouselNext.displayName = 'CarouselNext'

const CarouselDotsWrapper = styled.div`
  position: absolute;

  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
`

const CarouselDotsItem = styled.span<{isActive: boolean}>`
  width: 8px;
  height: 8px;
  cursor: pointer;

  border-radius: 100%;

  background-color: ${({theme, isActive}) =>
    isActive
      ? theme.palette.backgroundAccentPrimary
      : theme.palette.backgroundTertiary};
`

const CarouselDots = () => {
  const {api} = useCarousel()

  const [current, setCurrent] = useState(0)
  const [count, setCount] = useState(0)

  const handleClick = (index: number) => {
    api?.scrollTo(index)
  }

  useEffect(() => {
    const onSelect = () => {
      if (api) {
        setCurrent(api.selectedScrollSnap() + 1)
      }
    }

    if (api) {
      setCount(api.scrollSnapList().length)
      setCurrent(api.selectedScrollSnap() + 1)

      api.on('select', onSelect)
    }

    return () => {
      api?.off('select', onSelect)
    }
  }, [api])

  return (
    <CarouselDotsWrapper>
      {Array.from({length: count}, (_, index) => (
        <CarouselDotsItem
          key={index}
          onClick={() => handleClick(index)}
          isActive={index === current - 1}
        />
      ))}
    </CarouselDotsWrapper>
  )
}

export {
  type CarouselApi,
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselPrevious,
  CarouselNext,
  CarouselDots,
}
