import styled from '@emotion/styled'
import {observer} from 'mobx-react'
import {useLocalObservable} from 'mobx-react-lite'
import {selectors} from '@components/features/search-input/store'
import {useRef} from 'react'
import {useOutsideClick} from '@hooks/use-outside-click'
import {store} from '@store/index'
import {
  SearchInputDateFrom,
  SearchInputDateTo,
  SearchInputDestination,
  SearchInputGuest,
} from '@components/features/search-input/desktop/ui'
import {MEDIA_DEVICE_QUERY} from '@assets/theme/media'
import {SearchInputDatePopover} from './ui/date-popover'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const InputWrapper = styled.div<{
  $isActiveInput?: boolean
}>`
  position: relative;

  height: 76px;
  display: flex;
  justify-content: start;
  align-items: center;

  background-color: ${({theme, $isActiveInput}) =>
    $isActiveInput
      ? theme.palette.backgroundTertiary
      : theme.palette.backgroundQuaternary};
  border: 1px solid ${({theme}) => theme.palette.borderTertiary};
  box-shadow: 0px 4px 6px 0px ${({theme}) => theme.palette.boxShadowPrimary};

  border-radius: 100px;

  @media ${MEDIA_DEVICE_QUERY.TABLET} {
    width: 100%;
  }
`

export const DesktopSearchInput = observer(() => {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const {isOpen: isDateFromOpen} = store().search.dateFrom
  const {isOpen: isDateToOpen} = store().search.dateTo

  const isOpen = isDateFromOpen || isDateToOpen

  const {isActiveInput} = useLocalObservable(
    selectors.selectIsActiveSearchInput,
  )

  useOutsideClick(
    wrapperRef,
    () => {
      store().search.resetActiveInput()
    },
    isActiveInput,
  )

  return (
    <Wrapper>
      <InputWrapper ref={wrapperRef} $isActiveInput={isActiveInput}>
        <SearchInputDestination />
        <SearchInputDateFrom />
        <SearchInputDateTo />
        <SearchInputGuest />
        {isOpen && <SearchInputDatePopover />}
      </InputWrapper>
    </Wrapper>
  )
})
