import {api} from '@api/index'
import {redirectScenario} from '@utils/next'
import {Routes} from '@/types/enums/routes'
import type {Redirect} from 'next/types'
import {getCountryHotelsScenario} from '@slices/global/lib/scenarios/get-country-hotels'
import {getRegionHotelsScenario} from '@slices/global/lib/scenarios/get-region-hotels'
import {getCityHotelsScenario} from '@slices/global/lib/scenarios/get-city-hotels'
import type {
  GetHotelQuery,
  GetHotelsByUrlQuery,
  GetSeoHotelsQuery,
  GetSimilarHotelsQuery,
  GetStaticPageByUrlQuery,
} from '@api/gql/generate-types'
import {getHotelScenario} from '@slices/global/lib/scenarios/get-hotel'

export interface DynamicGlobalPageProperties {
  info?: NonNullable<GetHotelsByUrlQuery['hotelsByUrl']>['info']
  seoRates?: NonNullable<GetSeoHotelsQuery>['hotelsForSeoPrerenderInSearch']
  hotel?: NonNullable<GetHotelQuery>['hotel']
  similarHotels?: NonNullable<GetSimilarHotelsQuery>['similarHotels']
  hotelReviews?: NonNullable<GetHotelQuery>['hotelReviews']
  staticPageContent?: NonNullable<GetStaticPageByUrlQuery['staticPageByUrl']>
  staticPagePathList?: string[]
}

export type ScenarioProperties = Partial<{
  properties: DynamicGlobalPageProperties
  redirect: Redirect
}>

type Payload = {
  url: string
  headers: Headers
  isBot: boolean
}

export const getGlobalPagePropertiesBuilder = async ({
  url,
  headers,
  isBot,
}: Payload): Promise<ScenarioProperties> => {
  try {
    const meta = await api.hotel.getByURL({
      headers,
      variables: {
        url,
      },
    })

    if (!meta) {
      return redirectScenario(Routes.NOT_FOUND)
    }

    const {info, redirect} = meta

    if (!info && redirect) {
      return redirectScenario(redirect, true)
    }

    if (!info) {
      return redirectScenario(Routes.NOT_FOUND)
    }

    if (info?.hotel_id) {
      return getHotelScenario(headers, info, info.hotel_id)
    }

    const {country} = info.filters

    if (country && country.id && isBot) {
      return getCountryHotelsScenario(headers, info, country.id)
    }

    const {region} = info.filters

    if (region && region.id && isBot) {
      return getRegionHotelsScenario(headers, info, region.id)
    }

    const {city} = info.filters

    if (city && city.id && isBot) {
      return getCityHotelsScenario(headers, info, city.id)
    }

    return {
      properties: {
        info,
      },
    }
  } catch (error) {
    console.error(
      {error, url},
      'getGlobalPageProperties caused an error in the GetHotelsByUrlDocument GQL requests',
    )
    return redirectScenario(Routes.NOT_FOUND)
  }
}
