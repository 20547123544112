import type {FC} from 'react'
import styled from '@emotion/styled'
import {Text} from '../../ui'
import type {TextSize} from '../../ui/typography/text/variants/size'

const BadgeItem = styled(Text)<{color?: string}>`
  width: fit-content;
  padding: 2px 6px;
  margin-bottom: 2px;
  border-radius: 6px;
  font-weight: 500;

  color: ${({theme}) => theme.palette.textQuaternary};
  background: ${({color, theme}) => color || theme.palette.textPositive};
`

interface BadgeProps {
  text: string
  textSize?: TextSize
  className?: string
  color?: string
}
export const Badge: FC<BadgeProps> = ({
  text,
  className,
  color,
  textSize = 'XS',
}) => {
  return (
    <BadgeItem className={className} color={color} size={textSize}>
      {text}
    </BadgeItem>
  )
}
