import {MEDIA_DEVICE_QUERY} from '@/assets/theme/media'
import styled from '@emotion/styled'
import {Skeleton} from 'antd'
import SkeletonImage from 'antd/es/skeleton/Image'

const ImageSkeleton = styled(SkeletonImage)`
  width: 100% !important;
  height: 210px !important;
  border-radius: 12px 12px 0 0 !important;
`

const SkeletonBody = styled.div`
  box-shadow: 0px 4px 20px 0px ${({theme}) => theme.palette.boxShadowPrimary};
  border-radius: 0 0 12px 12px;
  height: 190px !important;
  padding: 10px;
`

const TitleSkeleton = styled(Skeleton.Button)`
  width: 250px !important;

  @media ${MEDIA_DEVICE_QUERY.MOBILE_MEDIUM} {
    width: 220px !important;
  }
`

const AddressSkeleton = styled(Skeleton.Button)`
  width: 250px !important;

  @media ${MEDIA_DEVICE_QUERY.MOBILE_MEDIUM} {
    width: 220px !important;
  }
`

const ActionButtonSkeleton = styled(Skeleton.Button)`
  margin-top: 30px;
  margin-left: 40px;

  @media ${MEDIA_DEVICE_QUERY.MOBILE_MEDIUM} {
    margin-left: 20px;
  }
`

export const SimilarHotelCardSkeleton = () => {
  return (
    <div>
      <ImageSkeleton active />
      <SkeletonBody>
        <Skeleton.Button active style={{width: 80, height: 16}} />
        <TitleSkeleton active style={{height: 16}} />
        <AddressSkeleton active style={{height: 16, marginTop: 10}} />
        <ActionButtonSkeleton
          active
          style={{
            width: 200,
            height: 25,
          }}
        />
      </SkeletonBody>
    </div>
  )
}
