import {DateFormatPattern} from '@/types/enums/date-format-patterns'
import {format, parse} from 'date-fns'

// from 12:30:00 to 12:30
export const parseTime = (value?: string | undefined | null): string =>
  value?.length ? value.split(':').slice(0, 2).join(':') : ''

export const getTimeFromDate = (date: string) => {
  const parsedDate = parse(date, DateFormatPattern.BACKEND_FORMAT, new Date())
  const time = format(parsedDate, DateFormatPattern.TIME_FORMAT)

  return time
}
