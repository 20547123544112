import type {PollingConstructorPayload} from '@utils/long-polling'
import {store} from '@store/index'
import {api} from '@api/index'
import {errorService} from '@services/common/error'
import {
  AnalyticsBookingStatuses,
  TransactionStatusEnum,
} from '@api/gql/generate-types'
import {getBookingId} from '@/types/enums/routes'
import {services} from '@/library/services'

export class BookingPaymentModalPollingPaymentStatusService {
  private readonly duration = 3000

  private get state() {
    return store().bookingPaymentModal
  }

  public getPollingPayload(): PollingConstructorPayload {
    return {
      duration: this.duration,
      abortController: new AbortController(),
      mergeMap: this.mergeMap.bind(this),
      takeWhile: this.takeWhile.bind(this),
      finalize: this.finalize.bind(this),
    }
  }

  public async mergeMap(): Promise<void> {
    if (!this.state.bookingId) {
      return
    }

    if (!this.state.transaction?.id) {
      return
    }

    if (!this.state.bookingAccessToken) {
      return
    }

    api.booking
      .getBookingPaymentStatus({
        variables: {
          transactionId: this.state.transaction.id,
          bookingAccessToken: this.state.bookingAccessToken,
        },
      })
      .then((result) => {
        if (result && result.status !== this.state.paymentStatus?.status) {
          this.state.setPaymentStatus(result)
        }
        if (
          result &&
          result.status !== TransactionStatusEnum.Waiting &&
          this.state.bookingId
        ) {
          if (this.state.bookingHotelUrl) {
            services.common.analytics.pushBookingStatus(
              AnalyticsBookingStatuses.BookSuccess,
              this.state.bookingHotelUrl,
            )
            services.common.yandexMetric.submitBookingSuccess()
          }
          if (window.location.pathname.startsWith('/booking')) {
            window.location.reload()
          } else {
            window.location.assign(
              getBookingId(this.state.bookingId, this.state.bookingAccessToken),
            )
          }
          this.state.reset()
        }
      })
      .catch((error) => {
        const firstError = errorService.getFirstErrorText(error)
        if (firstError) {
          this.state.setPaymentStatusError(firstError)
        }
      })
  }

  private takeWhile(): boolean {
    return true
  }

  private finalize(): void {}
}
