import {Text} from '@/library/components/ui'
import {PriceWithCurrency} from '@/library/components/ui/typography'
import {store} from '@/library/store'
import {getNightsAmount} from '@/library/utils/date/get-nights-amount'
import styled from '@emotion/styled'
import {observer} from 'mobx-react'
import useTranslation from 'next-translate/useTranslation'
import {useMemo, type FC} from 'react'
import {CurrencyWeight} from '@/library/components/ui/typography/price/lib/types'
import {HorizontalDivider} from '@/library/components/ui/divider'
import {PromoCode} from './promo-code'
import {BalanceForm} from './balance-form'
import {BOOKING_MOBILE_SIZE} from '../../lib/constants'

const Wrapper = styled.div`
  border-radius: 12px;
  padding: 16px;
  width: 375px;
  border: 1px solid ${({theme}) => theme.palette.borderTertiary};
  box-sizing: border-box;

  @media (max-width: 1200px) {
    width: calc(50% - 30px);
  }

  @media ${BOOKING_MOBILE_SIZE} {
    width: calc(100% - 20px);
  }
`

const Title = styled(Text)`
  color: ${({theme}) => theme.palette.textPrimary};
`

const SumTitle = styled(Text)`
  margin-top: 22px;
  color: ${({theme}) => theme.palette.textSecondary};
`

const SumTable = styled.div`
  margin-top: 10px;
`

const TableItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
  border-bottom: 1px solid ${({theme}) => theme.palette.borderTertiary};

  &:first-of-type {
    border-top: 1px solid ${({theme}) => theme.palette.borderTertiary};
  }
`

const TableItemText = styled(Text)`
  color: ${({theme}) => theme.palette.textPrimary};
`

const TableItemPrice = styled(PriceWithCurrency)`
  font-size: 14px;
  line-height: 18px;
`

const TaxTitle = styled(Text)`
  margin-bottom: 10px;
  color: ${({theme}) => theme.palette.textSecondary};
`

const TaxTable = styled.div`
  margin-top: 30px;
`

const TotalSum = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
`

const TotalSumPrice = styled(PriceWithCurrency)`
  font-size: 20px;
  line-height: 25px;
`

const TotalSumText = styled(Text)`
  color: ${({theme}) => theme.palette.textPrimary};
`

const StyledPromoCode = styled(PromoCode)`
  margin-top: 30px;
`

const StyledBalanceForm = styled(BalanceForm)`
  margin-top: 30px;
`

interface CostInfoProps {
  className?: string
}

export const CostInfo: FC<CostInfoProps> = observer(({className}) => {
  const {t} = useTranslation('global.hotel')
  const {paymentType} = store().bookingModal.modal
  const {amount: balanceAmount} = store().bookingModal.balance
  const {data: promoCodeData} = store().bookingModal.promoCode
  const {dateFrom, dateTo} = store().search
  const {data} = store().pages.global.hotel

  const initialSearchState = useMemo(
    () => ({
      dateFrom: dateFrom.value,
      dateTo: dateTo.value,
    }),
    [],
  )

  const {paymentType: memoizedPaymentType} = useMemo(() => ({paymentType}), [])

  const onlineAmount =
    promoCodeData?.amount || memoizedPaymentType?.onlineAmount || 0
  const totalOnlineAmount = balanceAmount
    ? onlineAmount - balanceAmount
    : onlineAmount
  const price = (memoizedPaymentType?.onSpotAmount || 0) + totalOnlineAmount

  return (
    <Wrapper className={className}>
      <Title size="semiBoldM">{t('booking_modal.cost_title')}</Title>
      {!balanceAmount &&
      data?.id &&
      data.city.id &&
      memoizedPaymentType?.onlineAmount ? (
        <StyledPromoCode
          onlineAmount={memoizedPaymentType.onlineAmount}
          hotelId={data.id}
          cityId={data.city.id}
        />
      ) : null}
      {memoizedPaymentType?.onlineAmount && !promoCodeData ? (
        <StyledBalanceForm onlineAmount={memoizedPaymentType.onlineAmount} />
      ) : null}

      {initialSearchState.dateFrom && initialSearchState.dateTo ? (
        <SumTitle size="XS">
          {t('booking_modal.cost_guest_needs', {
            guestNeeds: t('rates.guests.night', {
              count: getNightsAmount(
                initialSearchState.dateFrom,
                initialSearchState.dateTo,
              ),
            }),
          })}
        </SumTitle>
      ) : null}
      <SumTable>
        {totalOnlineAmount ? (
          <TableItem>
            <TableItemText size="XS">{t('booking_modal.online')}</TableItemText>
            <TableItemPrice
              price={totalOnlineAmount.toLocaleString()}
              currency={memoizedPaymentType?.currencyCode}
              currencyWeight={CurrencyWeight.normal}
            />
          </TableItem>
        ) : null}
        {memoizedPaymentType?.onSpotAmount ? (
          <TableItem>
            <TableItemText size="XS">
              {t('booking_modal.in_hotel')}
            </TableItemText>
            <TableItemPrice
              price={memoizedPaymentType.onSpotAmount.toLocaleString()}
              currency={memoizedPaymentType?.currencyCode}
              currencyWeight={CurrencyWeight.normal}
            />
          </TableItem>
        ) : null}
      </SumTable>
      {memoizedPaymentType?.taxes && memoizedPaymentType.taxes.length > 0 ? (
        <TaxTable>
          <TaxTitle size="XS">{t('booking_modal.tax_title')}</TaxTitle>
          {memoizedPaymentType.taxes.map((tax) => (
            <TableItem key={tax.name}>
              <TableItemText size="XS">{tax.name}</TableItemText>
              <TableItemPrice
                price={tax.amount.toLocaleString()}
                currency={tax.currency_code}
                currencyWeight={CurrencyWeight.normal}
              />
            </TableItem>
          ))}
        </TaxTable>
      ) : null}
      {price ? (
        <TotalSum>
          <TotalSumText size="boldM">{t('booking_modal.total')}</TotalSumText>
          <TotalSumPrice
            price={price.toLocaleString()}
            currency={memoizedPaymentType?.currencyCode}
            currencyWeight={CurrencyWeight.normal}
          />
        </TotalSum>
      ) : null}
    </Wrapper>
  )
})
