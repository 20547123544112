import {makeAutoObservable} from 'mobx'
import type {
  CreateBookingPaymentTransactionMutation,
  GetActivePaymentMethodsQuery,
  GetBookingPaymentStatusQuery,
} from '@api/gql/generate-types'

type PaymentMethods =
  | GetActivePaymentMethodsQuery['activePaymentMethods']
  | null

type Transaction =
  | CreateBookingPaymentTransactionMutation['bookingTransactionMutation']
  | null

type PaymentStatus = GetBookingPaymentStatusQuery['bookingPaymentCheck'] | null

export class CBookingPaymentModal {
  constructor() {
    makeAutoObservable(this)
  }

  private _isOpen = false

  public get isOpen(): boolean {
    return this._isOpen
  }

  public setIsOpen(isOpen: boolean): void {
    this._isOpen = isOpen
  }

  private _bookingId: number | null = null

  public get bookingId(): number | null {
    return this._bookingId
  }

  public setBookingId(id: number | null): void {
    this._bookingId = id
  }

  private _bookingHotelUrl: string | null = null

  public get bookingHotelUrl(): string | null {
    return this._bookingHotelUrl
  }

  public setBookingHotelUrl(hotelUrl: string | null): void {
    this._bookingHotelUrl = hotelUrl
  }

  private _bookingAccessToken: string | null = null

  public get bookingAccessToken(): string | null {
    return this._bookingAccessToken
  }

  public setBookingAccessToken(token: string | null): void {
    this._bookingAccessToken = token
  }

  private _paymentMethods: PaymentMethods = null

  public get paymentMethods(): PaymentMethods {
    return this._paymentMethods
  }

  public setPaymentMethods(paymentMethods: PaymentMethods): void {
    this._paymentMethods = paymentMethods
  }

  private _isLoadingPaymentMethods = false

  public get isLoadingPaymentMethods(): boolean {
    return this._isLoadingPaymentMethods
  }

  public setIsLoadingPaymentMethods(flag: boolean): void {
    this._isLoadingPaymentMethods = flag
  }

  private _transaction: Transaction = null

  public get transaction(): Transaction {
    return this._transaction
  }

  public setTransaction(transaction: Transaction): void {
    this._transaction = transaction
  }

  private _isLoadingTransaction = false

  public get isLoadingTransaction(): boolean {
    return this._isLoadingTransaction
  }

  public setIsLoadingTransaction(flag: boolean): void {
    this._isLoadingTransaction = flag
  }

  private _paymentStatusError: string | null = null

  public get paymentStatusError(): string | null {
    return this._paymentStatusError
  }

  public setPaymentStatusError(error: string | null): void {
    this._paymentStatusError = error
  }

  private _error: string | null = null

  public get error(): string | null {
    return this._error
  }

  public setError(error: string | null): void {
    this._error = error
  }

  private _paymentStatus: PaymentStatus = null

  public get paymentStatus(): PaymentStatus {
    return this._paymentStatus
  }

  public setPaymentStatus(paymentStatus: PaymentStatus): void {
    this._paymentStatus = paymentStatus
  }

  public reset() {
    this.setBookingAccessToken(null)
    this.setBookingId(null)
    this.setPaymentStatusError(null)
    this.setError(null)
    this.setPaymentStatus(null)
    this.setBookingHotelUrl(null)
    this.setIsOpen(false)
    this.setIsLoadingPaymentMethods(false)
  }
}
