import {makeAutoObservable} from 'mobx'
import type {
  GetDestinationAutoCompleteQuery,
  GetSearchHistoryQuery,
} from '@api/gql/generate-types'

type DestinationData =
  | GetDestinationAutoCompleteQuery['destinationAutocomplete']
  | null

type SearchHistory = NonNullable<
  GetSearchHistoryQuery['userHotelSearchHistory']
> | null

export type DestinationSelectPlace = {
  name: string
  url: string
} | null

export default class CDestination {
  constructor() {
    makeAutoObservable(this)
  }

  private _isOpen = false

  public get isOpen(): boolean {
    return this._isOpen
  }

  private _inputValue = ''

  public get inputValue(): string {
    return this._inputValue
  }

  private _error = ''

  public get error(): string {
    return this._error
  }

  private _isLoading = false

  public get isLoading(): boolean {
    return this._isLoading
  }

  private _data: DestinationData = null

  public get data(): DestinationData {
    return this._data
  }

  private _selectPlace: DestinationSelectPlace = null

  public get selectPlace(): DestinationSelectPlace {
    return this._selectPlace
  }

  private _searchHistory: SearchHistory = null

  public get searchHistory(): SearchHistory {
    return this._searchHistory
  }

  public setSelectPlace(value: DestinationSelectPlace) {
    this._selectPlace = value
  }

  public setIsOpen(isOpen: boolean): void {
    this._isOpen = isOpen
  }

  public setInputValue(value: string): void {
    this._inputValue = value
  }

  public setIsLoading(isLoading: boolean): void {
    this._isLoading = isLoading
  }

  public setError(error: string): void {
    this._error = error
  }

  public setSearchHistory(data: SearchHistory): void {
    this._searchHistory = data
  }

  public setData(data: DestinationData): void {
    this._data = data
  }
}
