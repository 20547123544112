import {type ComponentProps, type FC, useEffect, useRef} from 'react'
import styled from '@emotion/styled'
import {CircularLoader} from '@ui/loader'
import {
  YMap,
  YMapComponentsProvider,
  YMapControls,
  YMapCustomClusterer,
  YMapDefaultSchemeLayer,
  YMapFeatureDataSource,
  YMapGeolocationControl,
  YMapLayer,
  YMapZoomControl,
} from 'ymap3-components'
import {ENVIRONMENT} from '@utils/guards/environment'
import {type YMap as YMapType} from '@yandex/ymaps3-types'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const LoaderWrapper = styled.div`
  position: absolute;
  left: 10px;
  top: 10px;
  padding: 10px;
  background-color: ${({theme}) => theme.palette.backgroundQuaternary};
  box-shadow: 0px 4px 6px 0px ${({theme}) => theme.palette.boxShadowPrimary};
  border-radius: 20px;
`

export const YANDEX_MAP_DATA_SOURCE_ID = 'yandex-map-data-source-id'

export type MapPoints = ComponentProps<
  typeof YMapCustomClusterer
>['features'][number]

interface Props {
  map: ComponentProps<typeof YMap>
  marker: ComponentProps<typeof YMapCustomClusterer>['marker']
  cluster: ComponentProps<typeof YMapCustomClusterer>['cluster']
  points: MapPoints[]
  loading?: boolean
}

export const YandexMap: FC<Props> = ({
  map,
  points,
  marker,
  cluster,
  loading,
}) => {
  const ref = useRef<YMapType | null>(null)

  useEffect(() => {
    return () => {
      ref.current?.destroy()
    }
  }, [])

  return (
    <Wrapper>
      <YMapComponentsProvider
        apiKey={ENVIRONMENT.YANDEX_MAP_API_KEY}
        lang="ru_RU"
      >
        <YMap {...map} ref={ref} key="map">
          <YMapCustomClusterer
            key="ymap-cluster"
            marker={marker}
            cluster={cluster}
            gridSize={128}
            features={points}
          />
          <YMapFeatureDataSource id={YANDEX_MAP_DATA_SOURCE_ID} />
          <YMapDefaultSchemeLayer />
          <YMapLayer
            source={YANDEX_MAP_DATA_SOURCE_ID}
            type="markers"
            zIndex={1800}
          />
          <YMapControls position="bottom" key="ymap-bottom-controls">
            <YMapZoomControl />
          </YMapControls>
          <YMapControls position="bottom left" key="ymap-bottom-left-controls">
            <YMapGeolocationControl />
          </YMapControls>
        </YMap>
        {loading && (
          <LoaderWrapper>
            <CircularLoader variant="main" size={32} />
          </LoaderWrapper>
        )}
      </YMapComponentsProvider>
    </Wrapper>
  )
}
