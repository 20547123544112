import styled from '@emotion/styled'
import {type FC} from 'react'
import {Link, Text} from '@/library/components/ui'
import {Icon} from '@/library/components/ui/icon'
import {ECurrency} from '@/types/enums/currency'
import {Tooltip} from '@/library/components/ui/tooltip'
import {ImageWithSkeleton} from '@/library/components/ui/image-with-skeleton'
import {
  BEST_OFFER_SEARCH_PRIORY_TYPES,
  HOTEL_RATING_FRACTION_DIGITS,
  NO_HOTEL_IMAGE_SOURCE,
} from '@/library/constants/hotel'
import {services} from '@/library/services'
import {isNumber} from '@/library/utils/guards/types'
import useTranslation from 'next-translate/useTranslation'
import {FavoriteHeart} from '@/library/components/features'
import type {SimilarHotelWithSeoUrl} from '../lib/types'

const Wrapper = styled.div`
  box-shadow: 0px 4px 20px 0px ${({theme}) => theme.palette.boxShadowPrimary};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  height: 100%;
`

const HeaderWrapper = styled.div`
  position: relative;
`

const ImageWrapper = styled.div`
  height: 210px;
  width: 100%;

  & > a > div > img {
    border-radius: 12px 12px 0 0;
  }
`

const InfoWrapper = styled.div`
  padding: 10px 10px 0;
  flex: 1;
`

const Type = styled(Text)`
  color: ${({theme}) => theme.palette.textSecondary};
  margin-bottom: 9px;
`
const ReviewsAvgScoreWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  font-weight: 600;
  color: ${({theme}) => theme.palette.textAccentPrimary};

  svg {
    color: ${({theme}) => theme.palette.textAccentPrimary};
    margin-bottom: 2px;
  }
`
const NameWrapper = styled.div`
  width: fit-content;
  max-width: 100%;
`

const Name = styled(Text)`
  color: ${({theme}) => theme.palette.textPrimary};
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const AddressWrapper = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 4px;
  max-width: 100%;
`

const AddressIcon = styled(Icon)`
  color: ${({theme}) => theme.palette.textAccentPrimary};
  transform: translateY(-2px);
`

const AddressText = styled(Text)`
  color: ${({theme}) => theme.palette.textPrimary};
  max-width: calc(100% - 30px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const ReviewsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
`

const ReviewsCount = styled(Text)`
  color: ${({theme}) => theme.palette.textSecondary};
`

const Footer = styled.div`
  margin-top: 10px;
  padding: 0 12px 12px;
`

const TooltipWrapper = styled.div`
  min-width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-family: ${({theme}) => theme.font.main};

  p {
    color: ${({theme}) => theme.palette.textQuaternary};
  }
`

const BestOfferWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  width: fit-content;
  color: ${({theme}) => theme.palette.textAccentPrimary};
`

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  background-color: ${({theme}) => theme.palette.backgroundAccentPrimary};
  border-radius: 100%;

  .icon {
    color: ${({theme}) => theme.palette.textQuaternary};
  }
`

const Price = styled(Text)`
  display: flex;
  gap: 5px;
  margin-top: 5px;
  color: ${({theme}) => theme.palette.textPrimary};

  .amount {
    font-size: 20px;
    font-weight: 500;
    color: ${({theme}) => theme.palette.textAccentPrimary};
  }
`

const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const RedirectToHotelButton = styled(Link)`
  margin-top: 10px;
  color: ${({theme}) => theme.palette.textAccentPrimary};
  text-align: right;
  text-decoration: underline;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }
`

const TooltipBestOfferContent = () => {
  const {t} = useTranslation('global.hotel')
  return (
    <TooltipWrapper>
      <Text size="boldS"> {t('similar_hotels.best_offer.tooltip.title')}</Text>
      <Text size="XS">{t('similar_hotels.best_offer.tooltip.subtitle')}</Text>
    </TooltipWrapper>
  )
}

interface SimilarHotelProps {
  hotel: SimilarHotelWithSeoUrl
}

export const SimilarHotel: FC<SimilarHotelProps> = ({hotel}) => {
  const {t} = useTranslation('global.hotel')
  const {
    rating,
    id,
    seoUrl,
    mainImage,
    address,
    reviews_count: reviewsCount,
    price,
    is_favourite_hotel: isFavouriteHotel,
    search_priority: searchPriority,
  } = hotel

  return (
    <Wrapper>
      <HeaderWrapper>
        <ImageWrapper>
          <FavoriteHeart
            hotelId={id}
            parentIsFavorite={Boolean(isFavouriteHotel)}
          />
          <Link
            href={`${seoUrl.url}?${services.components.search.buildQueryParameters().toString()}`}
            target="_blank"
          >
            <ImageWithSkeleton
              src={mainImage?.url || NO_HOTEL_IMAGE_SOURCE}
              alt="hotel_image"
              fill
            />
          </Link>
        </ImageWrapper>
      </HeaderWrapper>
      <InfoWrapper>
        <div>
          {hotel.type ? <Type size="S">{hotel.type.name}</Type> : null}
          <Tooltip trigger="hover" title={hotel.name}>
            <NameWrapper>
              <Name size="boldS">{hotel.name}</Name>
            </NameWrapper>
          </Tooltip>

          <Tooltip trigger="hover" title={address}>
            <AddressWrapper>
              <AddressIcon name="point" width={19} height={14} />
              <AddressText size="S">{address}</AddressText>
            </AddressWrapper>
          </Tooltip>
          <ReviewsWrapper>
            {isNumber(reviewsCount) && reviewsCount > 0 ? (
              <ReviewsCount size="S">
                {t('similar_hotels.reviews', {
                  count: reviewsCount,
                })}
              </ReviewsCount>
            ) : null}
            {isNumber(rating) && rating > 0 && (
              <ReviewsAvgScoreWrapper>
                <Icon name="star" width={12} height={12} />
                <span>{rating.toFixed(HOTEL_RATING_FRACTION_DIGITS)}</span>
              </ReviewsAvgScoreWrapper>
            )}
          </ReviewsWrapper>
        </div>
      </InfoWrapper>

      <Footer>
        {BEST_OFFER_SEARCH_PRIORY_TYPES.has(searchPriority) && (
          <Tooltip
            trigger="hover"
            title={<TooltipBestOfferContent />}
            overlayStyle={{maxWidth: 280}}
          >
            <BestOfferWrapper>
              <IconWrapper>
                <Icon className="icon" name="acceptMark" width={8} height={8} />
              </IconWrapper>
              <Text className="text" size="boldXS">
                {t('similar_hotels.best_offer.label')}
              </Text>
            </BestOfferWrapper>
          </Tooltip>
        )}
        {price ? (
          <Price size="M">
            <span>{t('similar_hotels.price.amount_before')}</span>
            <span className="amount">{price.toLocaleString()}</span>
            <span>{ECurrency.RUB}</span>
          </Price>
        ) : null}
        <LinkWrapper>
          <RedirectToHotelButton
            href={`${seoUrl.url}?${services.components.search.buildQueryParameters().toString()}`}
            target="_blank"
          >
            {t('similar_hotels.in_details')}
          </RedirectToHotelButton>
        </LinkWrapper>
      </Footer>
    </Wrapper>
  )
}
