import styled from '@emotion/styled'
import {css} from '@emotion/react'
import {type FC, type ReactNode, useEffect, useRef, useState} from 'react'

import {LOADING_STYLES, TRANSITION_STYLES} from '@/library/constants/css'
import {useDeviceDetection} from '@/library/hooks/use-device-detection'
import {Text} from '../typography'
import {Icon} from '../icon'
import {CircularLoader, type LoaderVariant} from '../loader'
import type {TextSize} from '../typography/text/variants/size'

const Wrapper = styled.div<{isLoading: boolean}>`
  display: flex;
  flex-direction: column;

  ${({isLoading}) =>
    isLoading &&
    css`
      opacity: 0.7;
      cursor: not-allowed;
    `}
`

const TitleWrapper = styled.div<{hasButtons?: boolean}>`
  display: flex;
  align-items: baseline;
  column-gap: 20px;

  ${({hasButtons}) =>
    hasButtons &&
    css`
      @media (max-width: 800px) {
        flex-direction: column;
        row-gap: 10px;
      }
    `}
`

const StyledText = styled(Text)<{boldTitleOnOpen: boolean; isOpen: boolean}>`
  ${({boldTitleOnOpen, isOpen}) =>
    isOpen &&
    boldTitleOnOpen &&
    css`
      font-weight: 600;
    `}
`

const StyledButton = styled.button<{
  isOpen: boolean
  disabled: boolean
  isTouch: boolean
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
  border: none;
  border-bottom: 1px solid ${({theme}) => theme.palette.borderTertiary};
  background: none;
  font-family: inherit;
  cursor: pointer;
  text-align: left;

  svg {
    transition:
      color ${TRANSITION_STYLES},
      transform ${TRANSITION_STYLES};
  }

  ${({isTouch, theme}) =>
    !isTouch &&
    css`
      .arrow-text-title {
        transition: color ${TRANSITION_STYLES};
      }

      &:hover {
        svg,
        .arrow-text-title {
          color: ${theme.palette.textAccentPrimary};
        }
      }
    `}

  ${({isOpen}) =>
    isOpen &&
    css`
      border-bottom: none;

      svg {
        transform: rotate(180deg);
      }
    `}

  ${({disabled}) =>
    disabled &&
    css`
      ${LOADING_STYLES}
    `}
`

interface Props {
  dataId?: string | number
  title: string
  children?: ReactNode
  className?: string
  loaderVariant?: LoaderVariant
  size: TextSize
  scrollOnOpen?: boolean
  buttons?: ReactNode
  disabled?: boolean
  parentLoading?: boolean
  initialIsOpen?: boolean
  boldTitleOnOpen?: boolean
}

export const ArrowText: FC<Props> = ({
  dataId,
  title,
  children,
  className,
  size,
  loaderVariant,
  scrollOnOpen,
  buttons = null,
  disabled = false,
  parentLoading = false,
  initialIsOpen = false,
  boldTitleOnOpen = false,
}) => {
  const [isOpen, setIsOpen] = useState(initialIsOpen)
  const [isLoading, setLoading] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  const isTouch = useDeviceDetection('touch')

  const handleIsOpenChange = () => {
    if (disabled) {
      return
    }

    setIsOpen((previous) => !previous)
  }

  useEffect(() => {
    setLoading(parentLoading)
  }, [parentLoading])

  useEffect(() => {
    if (scrollOnOpen && ref && ref.current && isOpen) {
      ref.current.scrollIntoView({behavior: 'smooth'})
    }
  }, [isOpen, scrollOnOpen])

  return (
    <Wrapper
      data-id={dataId}
      className={className}
      ref={ref}
      isLoading={isLoading}
    >
      <StyledButton
        isOpen={isOpen}
        onClick={handleIsOpenChange}
        disabled={isLoading}
        isTouch={isTouch}
      >
        <TitleWrapper hasButtons={Boolean(buttons)}>
          <StyledText
            boldTitleOnOpen={boldTitleOnOpen}
            isOpen={isOpen}
            size={size}
            className="arrow-text-title"
          >
            {title}
          </StyledText>
        </TitleWrapper>
        {isLoading ? (
          <CircularLoader size={32} variant={loaderVariant} />
        ) : (
          <Icon name="arrow_small" width={24} height={24} />
        )}
      </StyledButton>
      {isOpen && children}
    </Wrapper>
  )
}
