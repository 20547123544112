import {makeAutoObservable} from 'mobx'

export class СBookingBalanceStore {
  constructor() {
    makeAutoObservable(this)
  }

  private _amount: number | null = null

  private _input = ''

  public get amount(): number | null {
    return this._amount
  }

  public setAmount(amount: number | null) {
    this._amount = amount
  }

  public get input(): string {
    return this._input
  }

  public setInput(input: string) {
    this._input = input
  }

  public reset() {
    this._amount = null
    this._input = ''
  }
}
