import styled from '@emotion/styled'
import {Icon} from '@ui/icon'
import {Button} from '@ui/button'
import {Link, Text} from '@components/ui'
import {store} from '@store/index'
import {observer} from 'mobx-react'
import useTranslation from 'next-translate/useTranslation'
import Trans from 'next-translate/Trans'
import {services} from '@services/index'
import {MEDIA_DEVICE_QUERY} from '@assets/theme/media'
import {HOTEL_SCENARIO_SIMILAR_HOTELS_ID} from '@/library/constants/dom-ids'
import {useEffect} from 'react'
import {isNull} from '@/library/utils/guards/types'

const Wrapper = styled.div`
  padding: 20px 17px;
  border: 1px solid ${({theme}) => theme.palette.borderTertiary};
  border-radius: 12px;
  display: flex;
  gap: 20px;

  @media ${MEDIA_DEVICE_QUERY.TABLET} {
    flex-direction: column;
  }

  @media ${MEDIA_DEVICE_QUERY.MOBILE_VERY_LARGE} {
    padding: 0;
  }
`

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 40px;
`

const Main = styled.div`
  padding: 20px;
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  gap: 15px;
  width: 100%;
`

const Actions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;

  button {
    width: 50%;
  }

  a {
    width: 50%;
  }

  a > button {
    width: 100%;
  }

  @media ${MEDIA_DEVICE_QUERY.MOBILE_VERY_LARGE} {
    margin-top: 20px;
    gap: 10px;
    flex-direction: column;
    button {
      width: 100%;
    }

    a {
      width: 100%;
    }
  }
`

const StyledText = styled(Text)`
  font-weight: 400;
  color: ${({theme}) => theme.palette.textSecondary};

  span {
    color: ${({theme}) => theme.palette.textPrimary};
  }

  .another-variants {
    text-decoration: underline;
    cursor: pointer;
  }
`

export const HotelScenarioRatesCardsItemNotFound = observer(() => {
  const {t} = useTranslation('global.hotel')
  const {info, hotel} = store().pages.global
  const {similarHotels, rates} = hotel

  useEffect(() => {
    if (!isNull(rates.data)) {
      services.pages.global.scenario.hotel.loadSimilarHotels()
    }
  }, [rates.isLoading])

  const handleSelectDate = () => {
    store().search.setIsOpenMobileMainPage(true)
    store().search.dateFrom.setIsOpen(true)
  }

  const handleRedirectToAnotherVariants = () => {
    const name = info?.urlsChain?.city?.name
    const url = info?.urlsChain?.city?.url

    if (!name || !url) {
      return
    }

    store().search.destination.setInputValue(name)
    store().search.destination.setSelectPlace({
      name,
      url,
    })
    services.components.search.handleSearch()
  }

  return (
    <Wrapper>
      <IconWrapper>
        <Icon name="detailed_calendar" width={128} height={128} />
      </IconWrapper>
      <Main>
        <StyledText size="M">
          <Trans
            ns="global.hotel"
            i18nKey="rates.not_found.main_text"
            components={[
              <span key={0}>{t('rates.not_found.main_text_0')}</span>,
              <span
                key={1}
                className="another-variants"
                onClick={handleRedirectToAnotherVariants}
              >
                {t('rates.not_found.main_text_1')}
              </span>,
            ]}
          />
        </StyledText>
        <Actions>
          <Button onClick={handleSelectDate}>
            {t('rates.not_found.choice_date_button.label')}
          </Button>
          {similarHotels && similarHotels.length > 0 ? (
            <Link href={`#${HOTEL_SCENARIO_SIMILAR_HOTELS_ID}`}>
              <Button variant="secondary">
                {t('rates.not_found.similar_options_button.label')}
              </Button>
            </Link>
          ) : null}
        </Actions>
      </Main>
    </Wrapper>
  )
})
