import {store} from '@store/index'

const selectIsAuthorized = () => ({
  get isAuthorized() {
    return store().user.isAuthorized
  },
})

const selectUser = () => ({
  get user() {
    return store().user.user
  },
  get balance() {
    return store().user.user?.balance?.amount
  },
})

const selectUserPopupOpen = () => ({
  get userPopupOpen() {
    return store().header.userPopupOpen
  },
})

const selectHeaderIsLoading = () => ({
  get isLoading() {
    return store().header.isLoading
  },
})

const selectHeaderSearchIsOpen = () => ({
  get isOpen() {
    return store().search.isActiveInput()
  },
})

export const selectors = {
  selectIsAuthorized,
  selectUser,
  selectUserPopupOpen,
  selectHeaderIsLoading,
  selectHeaderSearchIsOpen,
}
