import styled from '@emotion/styled'
import {Text} from '@components/ui'
import useTranslation from 'next-translate/useTranslation'
import {AdultsCounter} from './adults.counter'

const AdultsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
`

const AdultsInformationWrapper = styled.div``

const AdultsInformationSubTitle = styled(Text)`
  color: ${({theme}) => theme.palette.textSecondary};
  margin-top: 2px;
`

export const SearchInputGuestsPopoverAdults = () => {
  const {t} = useTranslation('features.search-input')

  return (
    <AdultsWrapper>
      <AdultsInformationWrapper>
        {t('guests.adults.title')}
        <AdultsInformationSubTitle size="XS">
          {t('guests.adults.subtitle')}
        </AdultsInformationSubTitle>
      </AdultsInformationWrapper>
      <AdultsCounter />
    </AdultsWrapper>
  )
}
