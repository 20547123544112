type SizesType =
  | 'Bytes'
  | 'KiB'
  | 'MiB'
  | 'GiB'
  | 'TiB'
  | 'PiB'
  | 'EiB'
  | 'ZiB'
  | 'YiB'

const SIZES: Record<SizesType, number> = {
  Bytes: 1,
  KiB: 1024,
  MiB: 1024 ** 2,
  GiB: 1024 ** 3,
  TiB: 1024 ** 4,
  PiB: 1024 ** 5,
  EiB: 1024 ** 6,
  ZiB: 1024 ** 7,
  YiB: 1024 ** 8,
}

export const convertBytes = (bytes: number, type: SizesType): number => {
  return bytes / SIZES[type]
}
