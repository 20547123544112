import {MEDIA_DEVICE_QUERY} from '@assets/theme/media'
import {css, jsx, useTheme} from '@emotion/react'
import type {ApplicationCustomTheme} from '@assets/theme'
import {type FC, type PropsWithChildren} from 'react'

const styles = (theme: ApplicationCustomTheme) => css`
  color: ${theme.palette.textPrimary};
  font-size: 36px;
  text-align: center;
  line-height: 39px;
  font-weight: 400;

  @media ${MEDIA_DEVICE_QUERY.LAPTOP} {
    font-size: 32px;
  }

  @media ${MEDIA_DEVICE_QUERY.TABLET} {
    font-size: 24px;
    line-height: 26px;
  }

  @media ${MEDIA_DEVICE_QUERY.MOBILE_LARGE} {
    font-size: 20px;
  }

  @media ${MEDIA_DEVICE_QUERY.MOBILE_MEDIUM} {
    font-size: 20px;
  }

  @media ${MEDIA_DEVICE_QUERY.MOBILE_SMALL} {
    font-size: 16px;
  }
`

export type TitleType = 'span' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'

interface Props extends PropsWithChildren {
  id?: string
  className?: string
  type?: TitleType
}

export const Title: FC<Props> = ({className, type = 'span', children}) => {
  const theme = useTheme()
  const css = styles(theme)

  return jsx(type, {css, className}, children)
}
