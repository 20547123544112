import styled from '@emotion/styled'
import {Icon} from '@/library/components/ui/icon'
import {PAYMENT_METHODS_ICONS_SIZE} from '../../lib/constants'

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
`

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 24px;
`

export const PaymentMethods = () => {
  const {width, height} = PAYMENT_METHODS_ICONS_SIZE

  return (
    <Wrapper>
      <IconWrapper>
        <Icon name="mir" width={width} height={height} />
      </IconWrapper>
      <IconWrapper>
        <Icon name="visa" width={width} height={height} />
      </IconWrapper>
      <IconWrapper>
        <Icon name="mastercard" width={width} height={height} />
      </IconWrapper>
    </Wrapper>
  )
}
