import {store} from '@store/index'
import {api} from '@api/index'
import type {CGlobalPageStoreSearchPromotion} from '@/library/store/pages/global/search/promotion'
import {services} from '@services/index'

export class GlobalPageSearchPromotionService {
  public get state(): CGlobalPageStoreSearchPromotion {
    return store().pages.global.search.promotion
  }

  public async fetchCities() {
    store().search.destination.setIsLoading(true)

    api.promotion
      .getCities()
      .then((cities) => {
        if (cities) {
          this.state.setPromotionCities(cities)
        }
      })
      .catch((error) => console.error(error))
      .finally(() => store().search.destination.setIsLoading(false))
  }

  public async startSearchBestOffers() {
    store().pages.global.search.resetFilters()
    store().pages.global.search.filters.setIsOpen(false)
    services.pages.global.scenario
      .handleSwitchToBestOffersSearchingType()
      .then(() => services.components.search.handleSearch())
  }

  public async startSearchPromotionCity() {
    store().pages.global.search.resetFilters()
    store().pages.global.search.filters.setIsOpen(false)
    services.pages.global.scenario.handleSwitchToPromotionSearchingType()
    services.components.search.handleSearch()
  }
}
