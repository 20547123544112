// Получение юзера
import {$gqlClient} from '@api/gql/client'
import {
  GetUserBalanceOperationsDocument,
  type GetUserBalanceOperationsQuery,
  type GetUserBalanceOperationsQueryVariables,
  GetUserCompleteBookingsDocument,
  type GetUserCompleteBookingsQuery,
  type GetUserCompleteBookingsQueryVariables,
  GetUserDocument,
  type GetUserQuery,
  type GetUserQueryVariables,
  UpdateMyUserProfileDocument,
  type UpdateMyUserProfileMutation,
  type UpdateMyUserProfileMutationVariables,
  GetUserBalanceDocument,
  type GetUserBalanceQuery,
  type GetUserBalanceQueryVariables,
} from '@api/gql/generate-types'
import {path} from 'ramda'
import {clientHeaders} from '@utils/http/headers'

const getByAuthorizationToken = (headers: Headers) =>
  $gqlClient()
    .query<GetUserQuery, GetUserQueryVariables>({
      query: GetUserDocument,
      fetchPolicy: 'no-cache',
      context: {
        headers,
      },
    })
    .then(path<GetUserQuery['userCheck']>(['data', 'userCheck']))

type GetBalanceOperationContext = {
  headers?: Headers
  variables: GetUserBalanceOperationsQueryVariables
}

const getBalanceOperation = ({
  headers,
  variables,
}: GetBalanceOperationContext) =>
  $gqlClient()
    .query<
      GetUserBalanceOperationsQuery,
      GetUserBalanceOperationsQueryVariables
    >({
      query: GetUserBalanceOperationsDocument,
      variables,
      fetchPolicy: 'no-cache',
      context: {
        headers: headers || clientHeaders(),
      },
    })
    .then(
      path<GetUserBalanceOperationsQuery['userBalanceOperations']>([
        'data',
        'userBalanceOperations',
      ]),
    )

type GetBalanceContext = {
  headers?: Headers
}

const getBalance = ({headers}: GetBalanceContext) =>
  $gqlClient()
    .query<GetUserBalanceQuery, GetUserBalanceQueryVariables>({
      query: GetUserBalanceDocument,
      fetchPolicy: 'no-cache',
      context: {
        headers: headers || clientHeaders(),
      },
    })
    .then(path<GetUserBalanceQuery['userBalance']>(['data', 'userBalance']))

type UpdateProfileContext = {
  headers?: Headers
  variables: UpdateMyUserProfileMutationVariables
}

const updateProfile = ({headers, variables}: UpdateProfileContext) =>
  $gqlClient()
    .mutate<UpdateMyUserProfileMutation, UpdateMyUserProfileMutationVariables>({
      mutation: UpdateMyUserProfileDocument,
      variables,
      fetchPolicy: 'no-cache',
      context: {
        headers: headers || clientHeaders(),
      },
    })
    .then(
      path<UpdateMyUserProfileMutation['updateMyUserProfile']>([
        'data',
        'updateMyUserProfile',
      ]),
    )

type GetCompleteBookingsContext = {
  headers?: Headers
  variables: GetUserCompleteBookingsQueryVariables
}

const getCompleteBookings = ({
  variables,
  headers,
}: GetCompleteBookingsContext) =>
  $gqlClient()
    .query<GetUserCompleteBookingsQuery, GetUserCompleteBookingsQueryVariables>(
      {
        query: GetUserCompleteBookingsDocument,
        variables,
        fetchPolicy: 'no-cache',
        context: {
          headers: headers || clientHeaders(),
        },
      },
    )
    .then(
      path<GetUserCompleteBookingsQuery['myCompletedBookings']>([
        'data',
        'myCompletedBookings',
      ]),
    )

export const userApi = {
  getByAuthorizationToken,
  getBalanceOperation,
  getCompleteBookings,
  getBalance,
  updateProfile,
}
