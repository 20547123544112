import styled from '@emotion/styled'
import {BaseButton} from './button.base'

export const TextButton = styled(BaseButton)<{color?: string}>`
  color: ${({theme, color}) => color || theme.palette.textAccentPrimary};
  background-color: unset;

  justify-content: start;
  padding: 0;

  &:hover {
    color: ${({theme}) => theme.palette.textAccentSecondary};
  }

  &:disabled {
    cursor: not-allowed;
    color: ${({theme}) => theme.palette.textTertiary};
  }
`
