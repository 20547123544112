import {services} from '@services/index'
import {observer, useLocalObservable} from 'mobx-react'
import {EAuthorizationModalAction} from '@/library/store/authorization/types'
import styled from '@emotion/styled'
import dynamic from 'next/dynamic'
import {Modal} from '../../ui/modal'
import {selectors} from './store'
import {CircularLoader} from '../../ui'

const LoginContent = dynamic(
  () => import('./ui/login-content').then((result) => result.LoginContent),
  {
    loading: () => <CircularLoader size={32} />,
  },
)

const RegisterContent = dynamic(
  () =>
    import('./ui/register-content').then((result) => result.RegisterContent),
  {
    loading: () => <CircularLoader size={32} />,
  },
)

const PasswordResetContent = dynamic(
  () =>
    import('./ui/password-reset-content').then(
      (result) => result.PasswordResetContent,
    ),
  {
    loading: () => <CircularLoader size={32} />,
  },
)

const Wrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 340px;
`

export const AuthorizationModal = observer(() => {
  const {modalOpen} = useLocalObservable(selectors.selectAuthorizationModalOpen)
  const {modalAction} = useLocalObservable(
    selectors.selectAuthorizationModalAction,
  )
  const {isLoading} = useLocalObservable(selectors.selectAuthorizationIsLoading)

  return (
    <Modal
      open={modalOpen}
      isLoading={isLoading}
      onCancel={() => services.common.authorization.handleCloseAuthModal()}
    >
      <Wrapper>
        {modalAction === EAuthorizationModalAction.LOGIN && <LoginContent />}
        {modalAction === EAuthorizationModalAction.REGISTER && (
          <RegisterContent />
        )}
        {modalAction === EAuthorizationModalAction.RESET_PASSWORD && (
          <PasswordResetContent />
        )}
      </Wrapper>
    </Modal>
  )
})
