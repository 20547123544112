import {object, string} from 'yup'
import {TEST_SPACES_ONLY_VALUES} from '../constants'

export type PromoCodeFormValues = {
  promoCode?: string
}

export const promoCodeForm = {
  initialState: {
    promoCode: '',
  } as PromoCodeFormValues,
  validationSchema: object().shape({
    promoCode: string().test(TEST_SPACES_ONLY_VALUES),
  }),
}
