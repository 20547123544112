import type {FC} from 'react'
import useTranslation from 'next-translate/useTranslation'
import {EtInclusion, EtPriceUnit} from '@/library/api/gql/generate-types'
import Trans from 'next-translate/Trans'
import type {OstrovokAdditionalBed} from '../lib/types'
import {InlineAmount, InlineCurrency, InlineText} from '../../typography'
import {getCurrency} from '../../../lib/helpers'
import {getInclusion, getPriceUnit} from '../lib/helpers'

interface AdditionalBedProps {
  additionalBed: OstrovokAdditionalBed
}

export const AdditionalBed: FC<AdditionalBedProps> = ({additionalBed}) => {
  const {t} = useTranslation('global.hotel')
  const {t: currenciesT} = useTranslation('currencies')

  const isInclusionSpecified =
    additionalBed.inclusion &&
    additionalBed.inclusion !== EtInclusion.Unspecified

  if (additionalBed.amount && additionalBed.price && isInclusionSpecified) {
    return (
      <Trans
        i18nKey="rules.et.additional_bed_with_amount_price_and_inclusion"
        ns="global.hotel"
        components={[
          <InlineAmount
            key={`additional_bed_${additionalBed.amount}_${additionalBed.price}_${additionalBed.priceUnit}_amount`}
          />,
          <InlineCurrency
            key={`additional_bed_${additionalBed.amount}_${additionalBed.price}_${additionalBed.priceUnit}_currency`}
          />,
          <InlineText
            key={`additional_bed_${additionalBed.amount}_${additionalBed.price}_${additionalBed.priceUnit}`}
          />,
        ]}
        values={{
          amount: additionalBed.amount,
          price: additionalBed.price?.toLocaleString(),
          currency: getCurrency(currenciesT, additionalBed.currency),
          unit: getPriceUnit(
            t,
            additionalBed.priceUnit || EtPriceUnit.Unspecified,
          ),
          inclusion: getInclusion(
            t,
            additionalBed.inclusion || EtInclusion.Unspecified,
          ),
        }}
      />
    )
  }

  if (additionalBed.amount && additionalBed.price) {
    return (
      <Trans
        i18nKey="rules.et.additional_bed_with_amount_and_price"
        ns="global.hotel"
        components={[
          <InlineAmount
            key={`additional_bed_${additionalBed.amount}_${additionalBed.price}_${additionalBed.priceUnit}_amount`}
          />,
          <InlineCurrency
            key={`additional_bed_${additionalBed.amount}_${additionalBed.price}_${additionalBed.priceUnit}_currency`}
          />,
          <InlineText
            key={`additional_bed_${additionalBed.amount}_${additionalBed.price}_${additionalBed.priceUnit}`}
          />,
        ]}
        values={{
          amount: additionalBed.amount,
          price: additionalBed.price?.toLocaleString(),
          currency: getCurrency(currenciesT, additionalBed.currency),
          unit: getPriceUnit(
            t,
            additionalBed.priceUnit || EtPriceUnit.Unspecified,
          ),
        }}
      />
    )
  }

  if (additionalBed.amount && isInclusionSpecified) {
    return (
      <Trans
        i18nKey="rules.et.additional_bed_with_amount_and_inclusion"
        ns="global.hotel"
        components={[
          <InlineText
            key={`additional_bed_${additionalBed.amount}_${additionalBed.inclusion}`}
          />,
        ]}
        values={{
          amount: additionalBed.amount,
          inclusion: getInclusion(
            t,
            additionalBed.inclusion || EtInclusion.Unspecified,
          ),
        }}
      />
    )
  }

  if (additionalBed.price && isInclusionSpecified) {
    return (
      <Trans
        i18nKey="rules.et.additional_bed_with_inclusion_and_price"
        ns="global.hotel"
        components={[
          <InlineAmount
            key={`additional_bed_${additionalBed.price}_${additionalBed.priceUnit}_${additionalBed.inclusion}_amount`}
          />,
          <InlineCurrency
            key={`additional_bed_${additionalBed.price}_${additionalBed.priceUnit}_${additionalBed.inclusion}_currency`}
          />,
          <InlineText
            key={`additional_bed_${additionalBed.price}_${additionalBed.priceUnit}_${additionalBed.inclusion}`}
          />,
        ]}
        values={{
          price: additionalBed.price?.toLocaleString(),
          currency: getCurrency(currenciesT, additionalBed.currency),
          unit: getPriceUnit(
            t,
            additionalBed.priceUnit || EtPriceUnit.Unspecified,
          ),
          inclusion: getInclusion(
            t,
            additionalBed.inclusion || EtInclusion.Unspecified,
          ),
        }}
      />
    )
  }

  if (additionalBed.amount) {
    return (
      <Trans
        i18nKey="rules.et.additional_bed_with_amount"
        ns="global.hotel"
        components={[
          <InlineText key={`additional_bed_${additionalBed.amount}`} />,
        ]}
        values={{
          amount: additionalBed.amount,
        }}
      />
    )
  }

  if (additionalBed.price) {
    return (
      <Trans
        i18nKey="rules.et.additional_bed_with_price"
        ns="global.hotel"
        components={[
          <InlineAmount
            key={`additional_bed_${additionalBed.price}_${additionalBed.priceUnit}_amount`}
          />,
          <InlineCurrency
            key={`additional_bed_${additionalBed.price}_${additionalBed.priceUnit}_currency`}
          />,
        ]}
        values={{
          price: additionalBed.price?.toLocaleString(),
          currency: getCurrency(currenciesT, additionalBed.currency),
          unit: getPriceUnit(
            t,
            additionalBed.priceUnit || EtPriceUnit.Unspecified,
          ),
        }}
      />
    )
  }

  if (isInclusionSpecified) {
    return (
      <Trans
        i18nKey="rules.et.additional_bed_with_inclusion"
        ns="global.hotel"
        components={[
          <InlineText key={`additional_bed_${additionalBed.inclusion}`} />,
        ]}
        values={{
          inclusion: getInclusion(
            t,
            additionalBed.inclusion || EtInclusion.Unspecified,
          ),
        }}
      />
    )
  }

  return <Trans i18nKey="rules.et.additional_bed" ns="global.hotel" />
}
