import {api} from '@/library/api'
import type {
  BookingPromoCodeInput,
  ReservedPromoCode,
} from '@/library/api/gql/generate-types'
import {services} from '@/library/services'
import type {Error} from '@/library/services/common/error/lib/types'
import {store} from '@/library/store'
import {ENVIRONMENT} from '@/library/utils/guards/environment'
import {isTruthy} from '@/library/utils/guards/types'
import {
  codesToPromoCodeCategories,
  PromoCodeErrorCategories,
} from '@/slices/global'

export class PromoCodeService {
  public get state() {
    return store().bookingModal.promoCode
  }

  public getPromoCodeInput = (
    promoCodeData: ReservedPromoCode | null,
  ): BookingPromoCodeInput | undefined => {
    if (!promoCodeData) {
      return undefined
    }
    return {
      code: promoCodeData.promoCode.code,
      amount: promoCodeData.amount,
      reservedUuid: promoCodeData.uuid,
    }
  }

  private parsePromoCodeCategory = (category: string) => {
    return (
      codesToPromoCodeCategories[category] || PromoCodeErrorCategories.unknown
    )
  }

  private parsePromoCodeError = (error: Error<PromoCodeErrorCategories>) => {
    return this.parsePromoCodeCategory(
      error.graphQLErrors[0].extensions.category,
    )
  }

  private isPromoCodeResponseValid = (
    promoCodeData?: ReservedPromoCode | null,
  ): promoCodeData is ReservedPromoCode => {
    return isTruthy(
      promoCodeData?.amount &&
        promoCodeData?.expires_at &&
        promoCodeData?.uuid &&
        promoCodeData?.promoCode.code,
    )
  }

  public submitPromoCode = async (
    hotelId: number,
    cityId: number,
    promoCode?: string,
    price?: number,
  ) => {
    this.state.setError(null)
    const {isAuthorized} = store().user

    if (!promoCode || promoCode.length < ENVIRONMENT.MIN_PROMOCODE_LENGTH) {
      this.state.setError(PromoCodeErrorCategories.validation)
      services.common.analytics.pushPromoCode(promoCode || '', true)
      return
    }

    if (!isAuthorized) {
      this.state.setError(PromoCodeErrorCategories.authorizaiton)
      services.common.analytics.pushPromoCode(promoCode, true)
      return
    }

    if (!price) {
      this.state.setError(PromoCodeErrorCategories.unknown)
      return
    }

    this.state.setIsLoading(true)
    try {
      const promoCodeData = await api.booking.reservePromoCode({
        variables: {code: promoCode, paymentAmount: price, hotelId, cityId},
      })
      if (this.isPromoCodeResponseValid(promoCodeData)) {
        services.common.analytics.pushPromoCode(promoCode, false)

        this.state.setData(promoCodeData)
      } else {
        services.common.analytics.pushPromoCode(promoCode, true)
      }
    } catch (error) {
      const typedError = error as Error<PromoCodeErrorCategories>
      this.state.setError(this.parsePromoCodeError(typedError))
      services.common.analytics.pushPromoCode(promoCode, true)
    } finally {
      this.state.setIsLoading(false)
    }
  }
}
