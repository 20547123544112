import styled from '@emotion/styled'
import {Button} from '@ui/button'
import {Link, Text} from '@ui/index'
import {useTheme} from '@emotion/react'
import useTranslation from 'next-translate/useTranslation'
import {Popover} from '@/library/components/ui/popover'
import {Icon} from '@/library/components/ui/icon'
import {store} from '@store/index'
import {observer, useLocalObservable} from 'mobx-react'
import {services} from '@/library/services'
import {TRANSITION_STYLES} from '@/library/constants/css'
import {selectors} from '@/library/components/features/header/store'
import {AffiliatePaths, ProfilePaths} from '@/types/enums/routes'
import Trans from 'next-translate/Trans'
import {formatPrice} from '@utils/number-formats'
import {isNumber} from '@utils/guards/types'
import {FeedbackSocialsDesktop} from '@/library/components/features/feedback-socials'
import {getFirstCharOfName} from '../../lib/utils'
import {AuthorizationButtons} from '../authorization-buttons'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`

const AboutUserWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  gap: 5px;
`

const UserBalanceWrapper = styled.div`
  color: ${({theme}) => theme.palette.textPrimary};
  font-weight: 500;

  .balance-amount {
    color: ${({theme}) => theme.palette.textAccentPrimary};
  }
`

const LinkList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 15px;
  font-weight: 500;
`

const LinkListItem = styled.li``

const StyledButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 12px;
  position: relative;

  svg,
  p {
    transition: color ${TRANSITION_STYLES};
  }

  &:hover {
    svg,
    p {
      color: ${({theme}) => theme.palette.textAccentPrimary};
    }
  }
`

const StyledBurgerIcon = styled(Icon)`
  margin-right: 10px;
`

const StyledHumanIcon = styled(Icon)`
  * {
    fill: transparent;
  }

  svg {
    fill: currentColor;
  }
`

export const ProfilePopup = observer(() => {
  const theme = useTheme()
  const {t} = useTranslation('features.header')

  const {isAuthorized} = useLocalObservable(selectors.selectIsAuthorized)
  const {user, balance} = useLocalObservable(selectors.selectUser)
  const {userPopupOpen} = useLocalObservable(selectors.selectUserPopupOpen)
  const {isLoading} = useLocalObservable(selectors.selectHeaderIsLoading)
  const isPartner = services.common.authorization.isPartner()

  const handleClickTrigger = () => {
    store().header.setUserPopupOpen(!userPopupOpen)
  }

  const handleLogout = () => {
    services.common.authorization.logout()
  }

  if (isAuthorized) {
    return (
      <Popover
        isLoading={isLoading}
        placement="bottomRight"
        onOpenChange={() => store().header.setUserPopupOpen(false)}
        open={userPopupOpen}
        content={
          <Wrapper>
            <AboutUserWrapper>
              {user?.name && (
                <Text size="S" color={theme.palette.textSecondary}>
                  {user.name}
                </Text>
              )}
              {user?.email && (
                <Text size="S" color={theme.palette.textSecondary}>
                  {user.email}
                </Text>
              )}
            </AboutUserWrapper>
            {isNumber(balance) && (
              <UserBalanceWrapper>
                <Trans
                  ns="features.header"
                  i18nKey="profile.balance_amount"
                  values={{currency: ''}}
                  components={{
                    BalanceAmount: (
                      <span className="balance-amount">
                        {formatPrice(balance || 0)}
                      </span>
                    ),
                  }}
                />
              </UserBalanceWrapper>
            )}
            <LinkList>
              <LinkListItem>
                <Link href={ProfilePaths.BOOKINGS}>
                  {t('profile.bookings')}
                </Link>
              </LinkListItem>
              <LinkListItem>
                <Link href={ProfilePaths.REVIEWS}>{t('profile.reviews')}</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href={ProfilePaths.BALANCE}>{t('profile.balance')}</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href={ProfilePaths.FAVORITES}>
                  {t('profile.favorites')}
                </Link>
              </LinkListItem>
              <LinkListItem>
                <Link
                  href={
                    isPartner ? AffiliatePaths.INVITES : AffiliatePaths.INDEX
                  }
                >
                  {t('profile.partner_programme')}
                </Link>
              </LinkListItem>
              <LinkListItem>
                <Link href={ProfilePaths.SETTINGS}>
                  {t('profile.settings')}
                </Link>
              </LinkListItem>
            </LinkList>

            <FeedbackSocialsDesktop />

            <Button
              variant="text"
              color={theme.palette.textPrimary}
              onClick={handleLogout}
            >
              {t('profile.quit')}
            </Button>
          </Wrapper>
        }
      >
        <StyledButton
          variant="secondary"
          paddingSize="S"
          onClick={handleClickTrigger}
        >
          <StyledBurgerIcon name="burger" width={24} height={24} />
          {isAuthorized ? (
            <Text size="boldS" color={theme.palette.textSecondary}>
              {getFirstCharOfName(user?.email)}
            </Text>
          ) : (
            <StyledHumanIcon name="human" width={24} height={24} />
          )}
        </StyledButton>
      </Popover>
    )
  }

  return (
    <Popover
      open={userPopupOpen}
      placement="bottomRight"
      onOpenChange={() => store().header.setUserPopupOpen(false)}
      content={<AuthorizationButtons />}
    >
      <StyledButton
        variant="secondary"
        paddingSize="S"
        onClick={handleClickTrigger}
      >
        <StyledBurgerIcon name="burger" width={24} height={24} />
        <StyledHumanIcon name="human" width={24} height={24} />
      </StyledButton>
    </Popover>
  )
})
