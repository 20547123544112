import {ROOT_ID} from '@constants/dom-ids'
import {isServer} from '@utils/next'

export const scrollMainWrapper = (top = 0) => {
  if (isServer()) {
    throw new Error('This method cannot be used on the server side')
  }

  document.querySelector(`#${ROOT_ID}`)?.scrollTo({top, behavior: 'auto'})
}

export function scrollParentToChild(parent: HTMLElement, child: HTMLElement) {
  const offset = child.offsetTop - parent.offsetTop
  parent?.scrollTo({top: offset - parent.clientHeight / 2})
}
