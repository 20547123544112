import {Button, CircularLoader} from '@/library/components/ui'
import {store} from '@/library/store'
import styled from '@emotion/styled'
import {observer, useLocalObservable} from 'mobx-react'
import {MEDIA_DEVICE_QUERY} from '@/assets/theme/media'
import {addPlus} from '@/library/utils/phone-numbers'
import useTranslation from 'next-translate/useTranslation'
import type {FC} from 'react'
import {ServerError} from './server-error'
import {compareProfiles} from '../lib/helpers'

const Wrapper = styled.div`
  width: fit-content;

  @media ${MEDIA_DEVICE_QUERY.MOBILE_LARGE} {
    width: 100%;
  }
`

const Save = styled(Button)`
  width: 246px;
  height: 58px;
  text-transform: uppercase;
  margin-top: 40px;

  @media ${MEDIA_DEVICE_QUERY.MOBILE_LARGE} {
    width: 100%;
  }
`

const selectSubmitButtonData = () => ({
  get isLoading() {
    return store().pages.profile.settings.isLoading
  },
  get isDataSubmitted() {
    return store().pages.profile.settings.isDataSubmitted
  },
  get formData() {
    return store().pages.profile.settings.formData
  },
  get profile() {
    const userProfile = store().user.user?.profile
    const phone = userProfile?.phone
    return {...userProfile, phone: phone ? addPlus(phone) : null}
  },
})

interface SubmitButtonProps {
  isValid?: boolean
}

export const SubmitButton: FC<SubmitButtonProps> = observer(({isValid}) => {
  const {t} = useTranslation('profile.settings')
  const {isLoading, isDataSubmitted, profile, formData} = useLocalObservable(
    selectSubmitButtonData,
  )

  const isDisabled = compareProfiles(formData, profile)
  if (isDataSubmitted) {
    return (
      <Wrapper>
        <Save>{t('data_saved')}</Save>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <Save disabled={isDisabled || isLoading || !isValid} type="submit">
        {isLoading ? <CircularLoader size={24} /> : t('save')}
      </Save>
      <ServerError />
    </Wrapper>
  )
})
