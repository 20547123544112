import {MEDIA_DEVICE_QUERY} from '@/assets/theme/media'
import {CancellationPolicies} from '@/library/components/features/cancellation-policies'
import {Text} from '@/library/components/ui'
import {Icon} from '@/library/components/ui/icon'
import {ImageWithSkeleton} from '@/library/components/ui/image-with-skeleton'
import {Tooltip} from '@/library/components/ui/tooltip'
import {NO_HOTEL_IMAGE_SOURCE} from '@/library/constants/hotel'
import {store} from '@/library/store'
import {getNightsAmount} from '@/library/utils/date/get-nights-amount'
import {parseTime} from '@/library/utils/date/parse-time'
import {DateFormatPattern} from '@/types/enums/date-format-patterns'
import styled from '@emotion/styled'
import {format} from 'date-fns'
import {observer} from 'mobx-react'
import useTranslation from 'next-translate/useTranslation'
import {useRouter} from 'next/router'
import {useMemo} from 'react'
import {useDeviceDetection} from '@hooks/use-device-detection'
import {BOOKING_MOBILE_SIZE} from '../../lib/constants'

const Wrapper = styled.div`
  display: flex;
  gap: 80px;
  justify-content: space-between;

  @media ${MEDIA_DEVICE_QUERY.LAPTOP_MEDIUM} {
    padding: 20px 20px 0;
  }

  @media ${BOOKING_MOBILE_SIZE} {
    flex-direction: column-reverse;
    gap: 20px;
    padding: unset;
  }
`

const LeftColumn = styled.div`
  @media ${BOOKING_MOBILE_SIZE} {
    padding: 0 10px;
  }
`
const RightColumn = styled.div`
  max-width: 200px;

  @media ${BOOKING_MOBILE_SIZE} {
    max-width: 100%;
    width: 100%;
  }
`

const HotelTitle = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 400px;
`

const AddressWrapper = styled.div`
  display: flex;
  margin-top: 8px;
  gap: 8px;
  align-items: center;
`

const Address = styled.span`
  color: ${({theme}) => theme.palette.textSecondary};
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  // title max width - address wrapper gap - address icon width to align address with the title
  max-width: calc(400px - 32px);
`

const RatingWrapper = styled.div`
  width: fit-content;
  margin-top: 12px;
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 6px 12px;
  border-radius: 12px;
  border: 3px solid ${({theme}) => theme.palette.borderAccentPrimary};
`

const StarIcon = styled(Icon)`
  color: ${({theme}) => theme.palette.textAccentPrimary};
`

const RatingText = styled.span`
  font-weight: 700;
  font-size: 22px;
  line-height: 22px;
  color: ${({theme}) => theme.palette.textAccentPrimary};
`

const CheckinCheckout = styled.div`
  margin-top: 24px;
  display: flex;
  gap: 50px;
`

const Checkin = styled.div``
const Checkout = styled.div``

const CheckinCheckoutTitle = styled(Text)`
  color: ${({theme}) => theme.palette.textPrimary};
`

const CheckinCheckoutText = styled(Text)`
  color: ${({theme}) => theme.palette.textPrimary};
  margin-top: 8px;
`

const GuestWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  margin-top: 24px;
  gap: 6px;

  p {
    font-weight: 400;
    color: ${({theme}) => theme.palette.textSecondary};
  }
`

const ImportantInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
  width: fit-content;
`

const ImportantInfoText = styled(Text)`
  color: ${({theme}) => theme.palette.textAccentPrimary};
  text-decoration: underline;
`

const ImportantInfoIcon = styled(Icon)`
  color: ${({theme}) => theme.palette.textSecondary};
  border-radius: 100%;
  padding: 2px;
  border: 1px solid ${({theme}) => theme.palette.borderTertiary};
  cursor: pointer;
`

const MealText = styled(Text)<{isIncluded$?: boolean}>`
  display: flex;
  justify-content: start;
  align-items: start;
  gap: 5px;
  color: ${({theme, isIncluded$}) =>
    isIncluded$ ? theme.palette.textPositive : theme.palette.textPrimary};
  margin-top: 8px;
`

const StyledCancellationPolicies = styled(CancellationPolicies)`
  margin-top: 24px;
`

const ImageWrapper = styled.div`
  min-width: 200px;
  max-width: 200px;
  min-height: 140px;
  max-height: 140px;
  width: 100%;
  height: 100%;

  & > div > img {
    border-radius: 12px;
  }

  @media ${BOOKING_MOBILE_SIZE} {
    max-width: unset;
    min-width: unset;
    width: 100%;
    height: 260px;
    max-height: unset;

    & > div {
      height: 260px;
    }

    & > div > img {
      border-radius: 0;
    }
  }
`

const ImageText = styled(Text)`
  margin-top: 8px;
  color: ${({theme}) => theme.palette.textSecondary};

  @media ${BOOKING_MOBILE_SIZE} {
    padding: 0 10px;
  }
`

const ImportantInfoTooltip = styled(Tooltip)`
  .ant-tooltip-content {
    overflow: auto;
    max-height: 400px;
  }
`

export const HotelInfo = observer(() => {
  const {t} = useTranslation('global.hotel')
  const {t: dateTimeT} = useTranslation('datetime')
  const {locale} = useRouter()
  const {room, rate, paymentType} = store().bookingModal.modal
  const {data} = store().pages.global.hotel

  const {dateFrom, dateTo, guests} = store().search

  const isMobile = useDeviceDetection('mobile')

  const initialSearchState = useMemo(
    () => ({
      dateFrom: dateFrom.value,
      dateTo: dateTo.value,
      adults: guests.adults.amount,
      kids: guests.children.children.length,
    }),
    [],
  )

  const {
    room: memoizedRoom,
    rate: memoizedRate,
    paymentType: memoizedPaymentType,
    data: memoizedData,
  } = useMemo(() => ({room, rate, paymentType, data}), [])

  if (!memoizedRoom || !memoizedData) {
    return null
  }

  const {rating} = memoizedData

  const importantInfo = memoizedData.important_info?.find(
    (info) => info?.locale === locale,
  )

  const showCheckinCheckout = Boolean(dateFrom || dateTo)

  const fromTime = memoizedData.policies.policyCheckin?.from
    ? parseTime(memoizedData.policies.policyCheckin.from)
    : null

  const untilTime = memoizedData.policies.policyCheckout?.until
    ? parseTime(memoizedData.policies.policyCheckout.until)
    : null

  return (
    <Wrapper>
      <LeftColumn>
        <Tooltip trigger="hover" title={memoizedData.name}>
          <div>
            <HotelTitle size="semiBoldM">{memoizedData.name}</HotelTitle>
          </div>
        </Tooltip>
        <Tooltip trigger="hover" title={memoizedData.address}>
          <AddressWrapper>
            <Icon name="point" width={22} height={24} />
            <Address>{memoizedData.address}</Address>
          </AddressWrapper>
        </Tooltip>
        {rating ? (
          <RatingWrapper>
            <StarIcon name="star" width={16} height={16} />
            <RatingText>{rating}</RatingText>
          </RatingWrapper>
        ) : null}
        {showCheckinCheckout && (
          <CheckinCheckout>
            {initialSearchState.dateFrom ? (
              <Checkin>
                <CheckinCheckoutTitle size="semiBoldS">
                  {t('booking_modal.check_in')}
                </CheckinCheckoutTitle>
                <CheckinCheckoutText size="XS">
                  {format(
                    initialSearchState.dateFrom,
                    DateFormatPattern.VIEW_DATE,
                  )}
                </CheckinCheckoutText>
                {fromTime ? (
                  <CheckinCheckoutText size="XS">
                    {dateTimeT('time.from', {
                      time: fromTime,
                    })}
                  </CheckinCheckoutText>
                ) : null}
              </Checkin>
            ) : null}
            {initialSearchState.dateTo ? (
              <Checkout>
                <CheckinCheckoutTitle size="semiBoldS">
                  {t('booking_modal.check_out')}
                </CheckinCheckoutTitle>
                <CheckinCheckoutText size="XS">
                  {format(
                    initialSearchState.dateTo,
                    DateFormatPattern.VIEW_DATE,
                  )}
                </CheckinCheckoutText>
                {untilTime ? (
                  <CheckinCheckoutText size="XS">
                    {dateTimeT('time.to', {
                      time: untilTime,
                    })}
                  </CheckinCheckoutText>
                ) : null}
              </Checkout>
            ) : null}
          </CheckinCheckout>
        )}
        <GuestWrapper>
          {initialSearchState.dateFrom && initialSearchState.dateTo && (
            <Text size="XS">
              {t('rates.guests.night', {
                count: getNightsAmount(
                  initialSearchState.dateFrom,
                  initialSearchState.dateTo,
                ),
              })}
            </Text>
          )}
          <Text size="XS">
            {t('rates.guests.adults', {
              count: initialSearchState.adults,
            })}
          </Text>
          {initialSearchState.kids > 0 && (
            <Text size="XS">
              {t('rates.guests.kids', {
                count: initialSearchState.kids,
              })}
            </Text>
          )}
        </GuestWrapper>
        {importantInfo?.info ? (
          <ImportantInfoTooltip
            trigger="hover"
            getPopupContainer={(triggerNode) => triggerNode}
            title={importantInfo.info}
            placement={isMobile ? 'top' : 'right'}
            align={{
              offset: isMobile ? [60, -10] : [20, 0],
            }}
          >
            <ImportantInfo>
              <ImportantInfoText size="XS">
                {t('booking_modal.important_info')}
              </ImportantInfoText>
              <ImportantInfoIcon name="info" width={20} height={20} />
            </ImportantInfo>
          </ImportantInfoTooltip>
        ) : null}
        {memoizedRate?.meal.title ? (
          <MealText isIncluded$={memoizedRate.meal.isIncluded} size="XS">
            <Icon name="food" width={18} height={18} />
            {memoizedRate.meal.title}
          </MealText>
        ) : null}
        {memoizedPaymentType?.cancellationPolicy ? (
          <StyledCancellationPolicies
            cancellationPolicies={memoizedPaymentType.cancellationPolicy}
            titleSize="semiBoldS"
            textSize="XS"
          />
        ) : null}
      </LeftColumn>
      <RightColumn>
        <ImageWrapper>
          <ImageWithSkeleton
            fill
            alt={memoizedRoom.roomName.main}
            src={memoizedRoom.images?.[0] || NO_HOTEL_IMAGE_SOURCE}
          />
        </ImageWrapper>
        <ImageText size="semiBoldXS">{memoizedRoom.roomName.main}</ImageText>
      </RightColumn>
    </Wrapper>
  )
})
