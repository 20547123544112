import {action, makeAutoObservable, observable} from 'mobx'
import {DEFAULT_PAGE} from '@constants/common'
import {CAffiliateWidgetsVote} from '@store/pages/affiliate/widgets/vote'

export class CAffiliateWidgets {
  public readonly vote: CAffiliateWidgetsVote = new CAffiliateWidgetsVote()

  constructor() {
    makeAutoObservable(this)
  }

  @observable
  private _data: AffiliateWidgetsData | null = null

  public get data(): AffiliateWidgetsData | null {
    return this._data
  }

  private _paginatorInfo: AffiliateWidgetsPaginatorInfo | null = null

  public get paginatorInfo(): AffiliateWidgetsPaginatorInfo | null {
    return this._paginatorInfo
  }

  private _page: number = DEFAULT_PAGE

  public get page(): number {
    return this._page
  }

  private _isLoading = false

  public get isLoading(): boolean {
    return this._isLoading
  }

  public setData(data: AffiliateWidgetsData | null): void {
    this._data = data
  }

  public setPaginatorInfo(data: AffiliateWidgetsPaginatorInfo | null): void {
    this._paginatorInfo = data
  }

  public setPage(page: number): void {
    this._page = page
  }

  public setIsLoading(flag: boolean): void {
    this._isLoading = flag
  }

  @action
  public updateUserVoteByIndex(
    id: number,
    userVote: NonNullable<AffiliateWidgetsData[number]['userVote']>,
  ): void {
    if (!this._data) {
      return
    }

    const clone = [...this._data]
    const item = clone[id]

    if (item) {
      item.userVote = {...userVote}
      this._data = clone
    }
  }
}
