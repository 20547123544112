import {makeAutoObservable} from 'mobx'
import {type Utilities} from '@/types/utilities'

export type UserAgent = Utilities['userAgent'] | null
export type Origin = Utilities['origin'] | null

export default class CUtilities {
  constructor() {
    makeAutoObservable(this)
  }

  private _userAgent: UserAgent = null

  private _origin: Origin = null

  public get userAgent(): UserAgent {
    return this._userAgent
  }

  public get origin(): Origin {
    return this._origin
  }

  public setUserAgent(userAgent: NonNullable<UserAgent>): void {
    this._userAgent = userAgent
  }

  public setOrigin(origin: NonNullable<Origin>): void {
    this._origin = origin
  }
}
