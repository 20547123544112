import {getCurrency} from '@/library/utils/currency'
import styled from '@emotion/styled'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'
import type {FC} from 'react'
import {CurrencyWeight} from './lib/types'
import {currencyWeights} from './lib/helpers'

const Wrapper = styled.span`
  white-space: nowrap;
`

export const Price = styled.span`
  color: ${({theme}) => theme.palette.textAccentPrimary};
  font-weight: 700;
`

export const Currency = styled.span<{$currencyWeight: CurrencyWeight}>`
  color: ${({theme}) => theme.palette.textSecondary};
  font-weight: ${({$currencyWeight}) => currencyWeights[$currencyWeight]};
`

interface PriceWithCurrencyProps {
  className?: string
  price: number | string
  currency?: string | null
  currencyWeight?: CurrencyWeight
}

export const PriceWithCurrency: FC<PriceWithCurrencyProps> = ({
  className,
  price,
  currency,
  currencyWeight = CurrencyWeight.bold,
}) => {
  const {t: currenciesT} = useTranslation('currencies')
  return (
    <Wrapper className={className}>
      <Trans
        ns="currencies"
        i18nKey="price"
        components={[
          <Price key="price" className="price" />,
          <Currency
            key="currency"
            className="currency"
            $currencyWeight={currencyWeight}
          />,
        ]}
        values={{
          price,
          currency: getCurrency(currenciesT, currency),
        }}
      />
    </Wrapper>
  )
}
