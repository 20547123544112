import {$gqlClient} from '@api/gql/client'
import {
  GetActivePaymentMethodsDocument,
  type GetActivePaymentMethodsQuery,
  type GetActivePaymentMethodsQueryVariables,
} from '@api/gql/generate-types'
import {clientHeaders} from '@utils/http/headers'
import {path} from 'ramda'

type GetActivePaymentMethodsContext = {
  headers?: Headers
}

const getActivePaymentMethods = ({headers}: GetActivePaymentMethodsContext) =>
  $gqlClient()
    .query<GetActivePaymentMethodsQuery, GetActivePaymentMethodsQueryVariables>(
      {
        query: GetActivePaymentMethodsDocument,
        fetchPolicy: 'no-cache',
        context: {
          headers: headers || clientHeaders(),
        },
      },
    )
    .then(
      path<GetActivePaymentMethodsQuery['activePaymentMethods']>([
        'data',
        'activePaymentMethods',
      ]),
    )

export const paymentApi = {
  getActivePaymentMethods,
}
