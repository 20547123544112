import type {FC} from 'react'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'
import {
  EtMetapolicyAddFeeType,
  EtPriceUnit,
} from '@/library/api/gql/generate-types'
import type {OstrovokAddFeesItem} from '../lib/types'
import {InlineAmount, InlineCurrency, InlineText} from '../../typography'
import {getAddFeesType, getPriceUnit} from '../lib/helpers'
import {getCurrency} from '../../../lib/helpers'

interface AddFeesItemProps {
  addFees: OstrovokAddFeesItem
}

export const AddFeesItem: FC<AddFeesItemProps> = ({addFees}) => {
  const {t} = useTranslation('global.hotel')
  const {t: currenciesT} = useTranslation('currencies')

  if (addFees.price && addFees.feeType) {
    return (
      <Trans
        i18nKey="rules.et.add_fees_row_with_price"
        ns="global.hotel"
        components={[
          <InlineAmount key={`add_fees_${addFees.feeType}_amount`} />,
          <InlineCurrency key={`add_fees_${addFees.feeType}_currency`} />,
          <InlineText key={`add_fees_${addFees.feeType}_price_unit`} />,
        ]}
        values={{
          name: getAddFeesType(t, addFees.feeType),
          price: addFees.price?.toLocaleString(),
          currency: getCurrency(currenciesT, addFees.currency),
          unit: getPriceUnit(t, addFees.priceUnit || EtPriceUnit.Unspecified),
        }}
      />
    )
  }

  return (
    <Trans
      i18nKey="rules.et.add_fees_row_without_price"
      ns="global.hotel"
      components={[<InlineText key={`add_fees_${addFees.feeType}`} />]}
      values={{
        name: getAddFeesType(
          t,
          addFees.feeType || EtMetapolicyAddFeeType.Unspecified,
        ),
      }}
    />
  )
}
