import type {HotelPolicyCheckin} from '@/library/api/gql/generate-types'
import type {Option} from '@/types/ui'
import {map, range} from 'ramda'
import {DEFAULT_NUMBER_BASE} from '@/library/constants/common'
import {MAXIMUM_HOUR, MINIMUM_HOUR} from './constants'

const buildHourLabel = (hour: number) => {
  const normalizeHour = hour <= 9 ? `0${hour}` : hour

  return `${normalizeHour}:00`
}

// input: 10:00:00, output: 10
const getHoursFromString = (time: string) => time.slice(0, 2)

export const buildArrivalTimeOptions = (
  checkin?: HotelPolicyCheckin | null,
): Option<number>[] => {
  const fromHour = checkin?.from
    ? Number.parseInt(getHoursFromString(checkin.from), DEFAULT_NUMBER_BASE)
    : MINIMUM_HOUR
  const untilHour = checkin?.until
    ? Number.parseInt(getHoursFromString(checkin.until), DEFAULT_NUMBER_BASE)
    : MAXIMUM_HOUR

  const hours = range(fromHour, untilHour + 1)
  return map((hour) => {
    return {
      label: buildHourLabel(hour),
      value: hour,
    }
  }, hours)
}

export const getInitialArrivalTime = (
  checkin?: HotelPolicyCheckin | null,
): Option<number> => {
  if (checkin) {
    const fromHour = checkin?.from
      ? Number.parseInt(getHoursFromString(checkin.from), DEFAULT_NUMBER_BASE)
      : MINIMUM_HOUR
    return {
      label: buildHourLabel(fromHour),
      value: fromHour,
    }
  }

  return {
    label: buildHourLabel(MINIMUM_HOUR),
    value: MINIMUM_HOUR,
  }
}
