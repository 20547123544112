import styled from '@emotion/styled'
import useTranslation from 'next-translate/useTranslation'
import {type FC} from 'react'
import {Text} from '@/library/components/ui'
import {PriceWithCurrency} from '@/library/components/ui/typography'

const Wrapper = styled.div``

const Title = styled(Text)`
  margin-bottom: 8px;
`

const Row = styled.div`
  margin-bottom: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid ${({theme}) => theme.palette.borderTertiary};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:last-child {
    margin-bottom: 0;
  }
`

const RowText = styled(Text)<{isFirst?: boolean; isValue?: boolean}>`
  color: ${({theme, isFirst}) =>
    isFirst ? theme.palette.textPositive : theme.palette.textPrimary};
  margin-left: ${({isValue}) => (isValue ? '8px' : 'unset')};
  white-space: ${({isValue}) => (isValue ? 'nowrap' : 'normal')};
`

interface AmountsTableProps {
  className?: string
  onlineAmount?: number
  onSpotAmount?: number
  totalAmount: number
  currency?: string | null
}

export const AmountsTable: FC<AmountsTableProps> = ({
  className,
  onSpotAmount,
  onlineAmount,
  totalAmount,
  currency,
}) => {
  const {t} = useTranslation('profile.bookings')
  return (
    <Wrapper className={className}>
      <Title size="boldS">{t('payment.title')}</Title>
      {onlineAmount ? (
        <Row>
          <RowText size="S" isFirst>
            {t('payment.online')}
          </RowText>
          <RowText isValue size="S" isFirst>
            <PriceWithCurrency price={onlineAmount} currency={currency} />
          </RowText>
        </Row>
      ) : null}
      {onSpotAmount ? (
        <Row>
          <RowText size="S">{t('payment.on_spot')}</RowText>
          <RowText isValue size="S">
            <PriceWithCurrency price={onSpotAmount} currency={currency} />
          </RowText>
        </Row>
      ) : null}
      <Row>
        <RowText size="S">{t('payment.total')}</RowText>
        <RowText isValue size="S">
          <PriceWithCurrency price={totalAmount} currency={currency} />
        </RowText>
      </Row>
    </Wrapper>
  )
}
