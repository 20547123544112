import styled from '@emotion/styled'
import {TRANSITION_STYLES} from '@/library/constants/css'
import {type FC, Fragment, type HTMLAttributes} from 'react'
import {PROFILE_SIDE_MENU_ITEMS} from '@components/features/profile-side-menu/lib/constants'
import {Link} from '@ui/link'
import {useRouter} from 'next/router'
import {css} from '@emotion/react'
import {HorizontalDivider} from '@ui/divider'
import {useDeviceDetection} from '@hooks/use-device-detection'
import useTranslation from 'next-translate/useTranslation'

const MenuWrapper = styled.aside`
  min-width: 225px;
  height: fit-content;
  box-shadow:
    0 6px 16px 0 ${({theme}) => theme.palette.boxShadowSecondary_1},
    0 3px 6px -4px ${({theme}) => theme.palette.boxShadowSecondary_2},
    0 2px 20px 2px ${({theme}) => theme.palette.boxShadowSecondary_3};
  padding: 20px;
  border-radius: 12px;
`

const MenuItemWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 15px;
`

const MenuItem = styled.li`
  width: fit-content;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
  cursor: pointer;
  font-weight: 400;
`

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border: 1px solid ${({theme}) => theme.palette.borderTertiary};
  border-radius: 12px;
`

const StyledLink = styled(Link, {
  shouldForwardProp: (properties) => properties !== '$isActive',
})<{$isActive: boolean}>`
  width: fit-content;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 16px;

  color: ${({theme}) => theme.palette.textPrimary};

  svg {
    transition: color ${TRANSITION_STYLES};
  }

  &:hover {
    svg {
      color: ${({theme}) => theme.palette.textAccentPrimary};
    }
  }

  ${({$isActive, theme}) =>
    $isActive &&
    css`
      color: ${theme.palette.textAccentPrimary};
    `}
`

const StyledHorizontalDivider = styled(HorizontalDivider)`
  margin: 5px auto;
`

type Props = HTMLAttributes<HTMLUListElement>

export const ProfileSideMenu: FC<Props> = ({...properties}) => {
  const router = useRouter()
  const isTouch = useDeviceDetection('touch')
  const {t} = useTranslation('features.profile-side-menu')

  if (isTouch) {
    return null
  }

  return (
    <MenuWrapper>
      <MenuItemWrapper {...properties}>
        {PROFILE_SIDE_MENU_ITEMS.map((item, index, array) => (
          <Fragment key={item.id}>
            <MenuItem>
              <StyledLink
                href={item.href}
                $isActive={router.pathname === item.href}
              >
                <IconWrapper>{item.icon}</IconWrapper>
                {t(item.label)}
              </StyledLink>
            </MenuItem>
            {index % 2 !== 0 && index !== array.length - 1 && (
              <StyledHorizontalDivider />
            )}
          </Fragment>
        ))}
      </MenuItemWrapper>
    </MenuWrapper>
  )
}
