import {type FC, type PropsWithChildren, useEffect, useState} from 'react'
import {createPortal} from 'react-dom'
import {ROOT_ID} from '@constants/dom-ids'

export const Portal: FC<PropsWithChildren> = ({children}) => {
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)

    return () => {}
  }, [])

  if (!mounted) {
    return null
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <>{createPortal(children, document.querySelector(`#${ROOT_ID}`))}</>
}
