// Получение юзера
import {$gqlClient} from '@api/gql/client'
import {
  CancelBookingDocument,
  type CancelBookingMutation,
  type CancelBookingMutationVariables,
  CreateBookingPaymentTransactionDocument,
  type CreateBookingPaymentTransactionMutation,
  type CreateBookingPaymentTransactionMutationVariables,
  FormBookingDocument,
  type FormBookingMutation,
  type FormBookingMutationVariables,
  GetBookingPaymentLinkDocument,
  type GetBookingPaymentLinkQuery,
  type GetBookingPaymentLinkQueryVariables,
  GetBookingPaymentStatusDocument,
  type GetBookingPaymentStatusQuery,
  type GetBookingPaymentStatusQueryVariables,
  GetBookingsDocument,
  type GetBookingsQuery,
  type GetBookingsQueryVariables,
  GetBookingStatusDocument,
  type GetBookingStatusQuery,
  type GetBookingStatusQueryVariables,
  GetMinimumBookingAmountDocument,
  type GetMinimumBookingAmountQuery,
  type GetMinimumBookingAmountQueryVariables,
  GetUserBookingDetailsDocument,
  type GetUserBookingDetailsQuery,
  type GetUserBookingDetailsQueryVariables,
  ReservePromocodeDocument,
  type ReservePromocodeMutation,
  type ReservePromocodeMutationVariables,
} from '@api/gql/generate-types'
import {path} from 'ramda'
import {clientHeaders} from '@utils/http/headers'

type GetBookingContext = {
  variables: GetUserBookingDetailsQueryVariables
  headers?: Headers
}

const getBooking = ({variables, headers}: GetBookingContext) =>
  $gqlClient()
    .query<GetUserBookingDetailsQuery, GetUserBookingDetailsQueryVariables>({
      query: GetUserBookingDetailsDocument,
      fetchPolicy: 'no-cache',
      context: {
        headers: headers || clientHeaders(),
      },
      variables,
    })
    .then(
      path<GetUserBookingDetailsQuery['bookingItem']>(['data', 'bookingItem']),
    )

type GetBookingsContext = {
  headers?: Headers
  variables: GetBookingsQueryVariables
}

const getBookings = ({headers, variables}: GetBookingsContext) =>
  $gqlClient()
    .query<GetBookingsQuery, GetBookingsQueryVariables>({
      query: GetBookingsDocument,
      variables,
      fetchPolicy: 'no-cache',
      context: {
        headers: headers || clientHeaders(),
      },
    })
    .then(path<GetBookingsQuery['myBookingItems']>(['data', 'myBookingItems']))

type GetBookingPaymentLinkContext = {
  headers?: Headers
  variables: GetBookingPaymentLinkQueryVariables
}

const getBookingPaymentLink = ({
  headers,
  variables,
}: GetBookingPaymentLinkContext) =>
  $gqlClient()
    .query<GetBookingPaymentLinkQuery, GetBookingPaymentLinkQueryVariables>({
      query: GetBookingPaymentLinkDocument,
      variables,
      fetchPolicy: 'no-cache',
      context: {
        headers: headers || clientHeaders(),
      },
    })
    .then(
      path<GetBookingPaymentLinkQuery['bookingItem']>(['data', 'bookingItem']),
    )

type CancelBookingContext = {
  headers?: Headers
  variables: CancelBookingMutationVariables
}

const cancelBooking = ({headers, variables}: CancelBookingContext) =>
  $gqlClient()
    .mutate<CancelBookingMutation, CancelBookingMutationVariables>({
      mutation: CancelBookingDocument,
      variables,
      fetchPolicy: 'no-cache',
      context: {
        headers: headers || clientHeaders(),
      },
    })
    .then(
      path<CancelBookingMutation['bookingCancelMutation']>([
        'data',
        'bookingCancelMutation',
      ]),
    )

type CreateBookingPaymentTransactionContext = {
  headers?: Headers
  variables: CreateBookingPaymentTransactionMutationVariables
}

const createBookingPaymentTransaction = ({
  headers,
  variables,
}: CreateBookingPaymentTransactionContext) =>
  $gqlClient()
    .mutate<
      CreateBookingPaymentTransactionMutation,
      CreateBookingPaymentTransactionMutationVariables
    >({
      mutation: CreateBookingPaymentTransactionDocument,
      variables,
      fetchPolicy: 'no-cache',
      context: {
        headers: headers || clientHeaders(),
      },
    })
    .then(
      path<
        CreateBookingPaymentTransactionMutation['bookingTransactionMutation']
      >(['data', 'bookingTransactionMutation']),
    )

type GetBookingPaymentStatusContext = {
  headers?: Headers
  variables: GetBookingPaymentStatusQueryVariables
}

const getBookingPaymentStatus = ({
  headers,
  variables,
}: GetBookingPaymentStatusContext) =>
  $gqlClient()
    .query<GetBookingPaymentStatusQuery, GetBookingPaymentStatusQueryVariables>(
      {
        query: GetBookingPaymentStatusDocument,
        variables,
        fetchPolicy: 'no-cache',
        context: {
          headers: headers || clientHeaders(),
        },
      },
    )
    .then(
      path<GetBookingPaymentStatusQuery['bookingPaymentCheck']>([
        'data',
        'bookingPaymentCheck',
      ]),
    )

type FormBookingContext = {
  headers?: Headers
  variables: FormBookingMutationVariables
}

const formBooking = ({headers, variables}: FormBookingContext) =>
  $gqlClient()
    .mutate<FormBookingMutation, FormBookingMutationVariables>({
      mutation: FormBookingDocument,
      variables,
      fetchPolicy: 'no-cache',
      context: {
        headers: headers || clientHeaders(),
      },
    })
    .then(
      path<FormBookingMutation['bookingFormMutation']>([
        'data',
        'bookingFormMutation',
      ]),
    )

type GetBookingStatusContext = {
  headers?: Headers
  variables: GetBookingStatusQueryVariables
}

const getBookingStatus = ({headers, variables}: GetBookingStatusContext) =>
  $gqlClient()
    .query<GetBookingStatusQuery, GetBookingStatusQueryVariables>({
      query: GetBookingStatusDocument,
      variables,
      fetchPolicy: 'no-cache',
      context: {
        headers: headers || clientHeaders(),
      },
    })
    .then(
      path<GetBookingStatusQuery['bookingItem']['status']>([
        'data',
        'bookingItem',
        'status',
      ]),
    )

type GetMinimumBookingAmountContext = {
  headers?: Headers
}

const getMinimumBookingAmount = ({headers}: GetMinimumBookingAmountContext) =>
  $gqlClient()
    .query<GetMinimumBookingAmountQuery, GetMinimumBookingAmountQueryVariables>(
      {
        query: GetMinimumBookingAmountDocument,
        fetchPolicy: 'no-cache',
        context: {
          headers: headers || clientHeaders(),
        },
      },
    )
    .then(
      path<GetMinimumBookingAmountQuery['minBookingAmountWithUseBalance']>([
        'data',
        'minBookingAmountWithUseBalance',
      ]),
    )

type ReservePromoCodeContext = {
  headers?: Headers
  variables: ReservePromocodeMutationVariables
}

const reservePromoCode = ({headers, variables}: ReservePromoCodeContext) =>
  $gqlClient()
    .mutate<ReservePromocodeMutation, ReservePromocodeMutationVariables>({
      mutation: ReservePromocodeDocument,
      variables,
      fetchPolicy: 'no-cache',
      context: {
        headers: headers || clientHeaders(),
      },
    })
    .then(
      path<ReservePromocodeMutation['reservePromoCode']>([
        'data',
        'reservePromoCode',
      ]),
    )

export const bookingApi = {
  get: getBooking,
  getBookings,
  getBookingPaymentLink,
  cancelBooking,
  createBookingPaymentTransaction,
  getBookingPaymentStatus,
  formBooking,
  getBookingStatus,
  getMinimumBookingAmount,
  reservePromoCode,
}
