import {GlobalHydrateService} from '@services/common/global-hydrate'
import type {AuthorizationEmailConfirmPageProperties} from '@slices/authorization/email-confirm/lib/types'
import {store} from '@store/index'

type InitialHydrationData =
  Required<AuthorizationEmailConfirmPageProperties>['hydrationData']

export class AuthorizationEmailConfirmService extends GlobalHydrateService<InitialHydrationData> {
  private get state() {
    return store().pages.authorization.emailConfirm
  }

  public hydrate(initialData: InitialHydrationData): void {
    if (initialData.status) {
      this.state.setStatus(initialData.status)
    }

    if (initialData.popularCityDestinations) {
      store().popularCityDestinations.setPopularCityDestinations(
        initialData.popularCityDestinations,
      )
    }

    super.hydrate(initialData)
  }
}
