import {makeAutoObservable} from 'mobx'
import {type AuthorizationStoreInitialStateType} from './types'
import {AUTHORIZATION_STORE_INITIAL_STATE} from './constants'
import {CLoginStore} from './login'
import {CRegisterStore} from './register'
import {CResetPasswordStore} from './reset-password'

export class CAuthorizationStore {
  // Инициализация дочерних сторов
  public readonly login: CLoginStore = new CLoginStore()

  public readonly register: CRegisterStore = new CRegisterStore()

  public readonly resetPassword: CResetPasswordStore = new CResetPasswordStore()

  constructor() {
    makeAutoObservable(this)
  }

  // Открытие модального окна авторизации
  private _modalOpen: AuthorizationStoreInitialStateType['modalOpen'] =
    AUTHORIZATION_STORE_INITIAL_STATE.modalOpen

  public get modalOpen(): AuthorizationStoreInitialStateType['modalOpen'] {
    return this._modalOpen
  }

  // Шаг авторизации в модалке - логин/регистрация/сброс пароля
  private _modalAction: AuthorizationStoreInitialStateType['modalAction'] =
    AUTHORIZATION_STORE_INITIAL_STATE.modalAction

  public get modalAction(): AuthorizationStoreInitialStateType['modalAction'] {
    return this._modalAction
  }

  // Флаг загрузки
  private _isLoading: AuthorizationStoreInitialStateType['isLoading'] =
    AUTHORIZATION_STORE_INITIAL_STATE.isLoading

  public get isLoading(): AuthorizationStoreInitialStateType['isLoading'] {
    return this._isLoading
  }

  public setModalOpen(
    modalOpen: AuthorizationStoreInitialStateType['modalOpen'],
  ) {
    this._modalOpen = modalOpen
  }

  public setModalAction(
    modalAction: AuthorizationStoreInitialStateType['modalAction'],
  ) {
    this._modalAction = modalAction
  }

  public setIsLoading(
    isLoading: AuthorizationStoreInitialStateType['isLoading'],
  ) {
    this._isLoading = isLoading
  }
}
