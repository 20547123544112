import {makeAutoObservable} from 'mobx'
import {DEFAULT_PAGE} from '@constants/common'

export class CProfileFavoritesStore {
  constructor() {
    makeAutoObservable(this)
  }

  private _paginatorInfo: FavouriteHotelPaginator | null = null

  public get paginatorInfo(): FavouriteHotelPaginator | null {
    return this._paginatorInfo
  }

  private _loading = false

  public get loading(): boolean {
    return this._loading
  }

  private _hotels: FavouriteHotels | null = null

  public get hotels(): FavouriteHotels | null {
    return this._hotels
  }

  private _page: number = DEFAULT_PAGE

  public get page(): number {
    return this._page
  }

  public setPaginatorInfo(info: FavouriteHotelPaginator): void {
    this._paginatorInfo = info
  }

  public setLoading(flag: boolean): void {
    this._loading = flag
  }

  public setHotels(hotels: FavouriteHotels | null): void {
    this._hotels = hotels
  }

  public setPage(page: number): void {
    this._page = page
  }
}
