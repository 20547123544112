import {observer} from 'mobx-react'
import {store} from '@store/index'
import styled from '@emotion/styled'
import {Nunito} from 'next/font/google'
import {useEffect} from 'react'
import {DynamicSeoProvider} from '@providers/dynamic-seo'
import DOMPurify from 'isomorphic-dompurify'
import {useRouter} from 'next/router'
import {scrollMainWrapper} from '@utils/dom/scroll'

const nunito = Nunito({weight: '400', subsets: ['latin']})

const StaticPageWrapper = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${({theme}) => theme.palette.overlaySecondary};

  a {
    text-decoration: underline;
    color: ${({theme}) => theme.palette.textAccentPrimary};
    cursor: pointer;
  }
`

const StaticContent = styled.div`
  max-width: 1200px;
  width: 100%;
  padding: 20px 20px 20px 20px;
  margin: 20px 0 20px 0;
  border-radius: 12px;
  background-color: ${({theme}) => theme.palette.backgroundQuaternary};
  box-shadow: 0px 4px 6px 4px ${({theme}) => theme.palette.boxShadowPrimary};
`

export const StaticScenario = observer(() => {
  const router = useRouter()
  const {data, pathList} = store().pages.global.static

  useEffect(() => {
    scrollMainWrapper()

    const handle = (url: string) => {
      if (!pathList?.includes(url)) {
        store().pages.global.static.setData(null)
      }
    }

    router.events.on('routeChangeComplete', handle)

    return () => {
      router.events.off('routeChangeComplete', handle)
    }
  }, [pathList, router.events])

  return (
    <DynamicSeoProvider content={data?.seoContent}>
      <StaticPageWrapper className={nunito.className}>
        <StaticContent>
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(data?.content || ''),
            }}
          />
        </StaticContent>
      </StaticPageWrapper>
    </DynamicSeoProvider>
  )
})
