import styled from '@emotion/styled'
import {
  type ButtonShape,
  ButtonShapeVariants,
} from '@ui/button/lib/variants/shape'
import {
  type ButtonPadding,
  ButtonPaddingVariants,
} from '@ui/button/lib/variants/padding'
import {
  type ButtonTextSize,
  ButtonTextSizeVariants,
} from '@ui/button/lib/variants/text-size'

export const BaseButton = styled.button<{
  $shape: ButtonShape
  $paddingSize: ButtonPadding
  $textSize: ButtonTextSize
}>`
  all: unset;

  color: ${({theme}) => theme.palette.textQuaternary};
  background-color: ${({theme}) => theme.palette.backgroundAccentPrimary};
  cursor: pointer;
  transition:
    color 0.2s,
    background-color 0.2s,
    border 0.2s;

  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;

  box-sizing: border-box;

  &:focus-visible {
    outline: 1px solid ${({theme}) => theme.palette.outlinePrimary}; /* Устанавливаем свой outline при фокусе */
  }

  ${({$shape}) => ButtonShapeVariants[$shape]}
  ${({$paddingSize}) => ButtonPaddingVariants[$paddingSize]}
    ${({$textSize}) => ButtonTextSizeVariants[$textSize]}
    svg {
    color: ${({theme}) => theme.palette.textSecondary};
  }
`
