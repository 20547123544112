import type {FC} from 'react'
import styled from '@emotion/styled'
import useTranslation from 'next-translate/useTranslation'
import type {IOstrovokRules} from 'slices/global/ui/hotel-scenario/rules/lib/types'
import {Rule} from '../rule'
import {RulesListItemText, RulesText} from '../typography'
import {
  checkAddFeesItem,
  checkCheckinCheckoutItem,
  checkChildrenItem,
  checkChildrenMeal,
  checkDeposit,
  checkInternet,
  checkMeal,
  checkPolicies,
  checkTransfer,
} from './lib/helpers'

import {AddFeesItem} from './ui/add-fees-item'
import {CheckinCheckoutItem} from './ui/checkin-checkout-item'
import {NoShow} from './ui/no-show'
import {ChildrenItem} from './ui/children-item'
import {ChildrenMeal} from './ui/children-meal'
import {Cot} from './ui/cot'
import {Deposit} from './ui/deposit'
import {AdditionalBed} from './ui/additional-bed'
import {Internet} from './ui/internet'
import {Meal} from './ui/meal'
import {Parking} from './ui/parking'
import {Pets} from './ui/pets'
import {Transfer} from './ui/transfer'

const Wrapper = styled.div``

const StyledRule = styled(Rule)`
  margin-bottom: 20px;

  &:last-of-type {
    margin-bottom: 0;
  }
`

interface OstrovokRulesProps {
  rules: IOstrovokRules
  checks: ReturnType<typeof checkPolicies>
}

export const OstrovokRules: FC<OstrovokRulesProps> = ({rules, checks}) => {
  const {t} = useTranslation('global.hotel')

  if (!rules || !checks) {
    return null
  }

  return (
    <Wrapper>
      {checks.showAddFees && (
        <StyledRule iconName="comfort" title={t('rules.et.titles.add_fee')}>
          {rules.addFee?.map(
            (fee) =>
              checkAddFeesItem(fee) && (
                <RulesListItemText size="S" key={fee.feeType}>
                  <AddFeesItem addFees={fee} />
                </RulesListItemText>
              ),
          )}
        </StyledRule>
      )}
      {checks.showCheckinCheckout && (
        <StyledRule
          iconName="checkin"
          title={t('rules.et.titles.checkin_and_checkout')}
        >
          {rules.checkInCheckOut?.map(
            (checkinCheckout) =>
              checkCheckinCheckoutItem(checkinCheckout) && (
                <RulesListItemText
                  key={checkinCheckout.checkInCheckOutType}
                  size="S"
                >
                  <CheckinCheckoutItem checkinCheckout={checkinCheckout} />
                </RulesListItemText>
              ),
          )}
        </StyledRule>
      )}
      {checks.showNoShow && (
        <StyledRule iconName="checkin" title={t('rules.et.titles.no_show')}>
          <NoShow noShow={rules.noShow} />
        </StyledRule>
      )}
      {checks.showChildren && (
        <StyledRule iconName="family" title={t('rules.et.titles.children')}>
          {rules.children?.map(
            (childrenItem) =>
              checkChildrenItem(childrenItem) && (
                <RulesListItemText
                  size="S"
                  key={`children_bed_${childrenItem.ageStart}_${childrenItem.ageEnd}`}
                >
                  <ChildrenItem childrenItem={childrenItem} />
                </RulesListItemText>
              ),
          )}
        </StyledRule>
      )}
      {checks.showChildrenMeal && (
        <StyledRule iconName="meal" title={t('rules.et.titles.children_meal')}>
          {rules.childrenMeal?.map(
            (childrenMeal) =>
              checkChildrenMeal(childrenMeal) && (
                <RulesListItemText
                  key={`meal_children_${childrenMeal.ageStart}_${childrenMeal.ageEnd}`}
                  size="S"
                >
                  <ChildrenMeal childrenMeal={childrenMeal} />
                </RulesListItemText>
              ),
          )}
        </StyledRule>
      )}
      {checks.showCot && (
        <StyledRule iconName="comfort" title={t('rules.et.titles.cot')}>
          {rules.cot?.map((cot) => (
            <RulesListItemText
              size="S"
              key={`cot_${cot.amount}_${cot.priceUnit}_${cot.inclusion}`}
            >
              <Cot cot={cot} />
            </RulesListItemText>
          ))}
        </StyledRule>
      )}
      {checks.showDeposit && (
        <StyledRule iconName="security" title={t('rules.et.titles.deposit')}>
          {rules.deposit?.map(
            (deposit) =>
              checkDeposit(deposit) && (
                <RulesListItemText
                  size="S"
                  key={`deposit_${deposit.depositType}`}
                >
                  <Deposit deposit={deposit} />
                </RulesListItemText>
              ),
          )}
        </StyledRule>
      )}
      {checks.showAdditionalBed && (
        <StyledRule
          iconName="comfort"
          title={t('rules.et.titles.additional_bed')}
        >
          {rules.extraBed?.map((additionalBed) => (
            <RulesListItemText
              size="S"
              key={`add_bed_${additionalBed.price} _${additionalBed.price}_${additionalBed.inclusion}`}
            >
              <AdditionalBed additionalBed={additionalBed} />
            </RulesListItemText>
          ))}
        </StyledRule>
      )}
      {checks.showInternet && (
        <StyledRule iconName="media" title={t('rules.et.titles.internet')}>
          {rules.internet?.map(
            (internet) =>
              checkInternet(internet) && (
                <RulesListItemText
                  size="S"
                  key={`internet_${internet.internetType}`}
                >
                  <Internet internet={internet} />
                </RulesListItemText>
              ),
          )}
        </StyledRule>
      )}
      {checks.showMeal && (
        <StyledRule iconName="meal" title={t('rules.et.titles.meal')}>
          {rules.meal?.map(
            (meal) =>
              checkMeal(meal) && (
                <RulesListItemText size="S" key={`meal_${meal.mealType}`}>
                  <Meal meal={meal} />
                </RulesListItemText>
              ),
          )}
        </StyledRule>
      )}
      {checks.showParking && (
        <StyledRule iconName="parking" title={t('rules.et.titles.parking')}>
          {rules.parking?.map((parking) => (
            <RulesListItemText
              size="S"
              key={`parking_${parking.territoryType}`}
            >
              <Parking parking={parking} />
            </RulesListItemText>
          ))}
        </StyledRule>
      )}
      {checks.showPets && (
        <StyledRule iconName="pets" title={t('rules.et.titles.pets')}>
          {rules.pets?.map((pets) => (
            <RulesListItemText size="S" key={`pets_${pets.petsType}`}>
              <Pets pets={pets} />
            </RulesListItemText>
          ))}
        </StyledRule>
      )}
      {checks.showTransfer && (
        <StyledRule iconName="outdoors" title={t('rules.et.titles.transfer')}>
          {rules.shuttle?.map(
            (transfer) =>
              checkTransfer(transfer) && (
                <RulesListItemText
                  size="S"
                  key={`transfer_${transfer.destinationType}`}
                >
                  <Transfer transfer={transfer} />
                </RulesListItemText>
              ),
          )}
        </StyledRule>
      )}
      {checks.showVisa && (
        <StyledRule iconName="payment_method" title={t('rules.et.titles.visa')}>
          <RulesText size="S" key="visa">
            {t('rules.et.visa')}
          </RulesText>
        </StyledRule>
      )}
    </Wrapper>
  )
}
