import styled from '@emotion/styled'
import {DISABLED_STYLES} from '@/library/constants/css'
import {BaseButton} from './button.base'

export const TertiaryButton = styled(BaseButton)`
  color: ${({theme}) => theme.palette.textQuaternary};
  background-color: ${({theme}) => theme.palette.backgroundPrimary};

  &:disabled {
    ${DISABLED_STYLES}
  }

  svg {
    color: ${({theme}) => theme.palette.textQuaternary};
  }
`
