import {makeAutoObservable} from 'mobx'

export class CAffiliateMain {
  constructor() {
    makeAutoObservable(this)
  }

  private _isLoading = false

  public get isLoading(): boolean {
    return this._isLoading
  }

  public setIsLoading(flag: boolean): void {
    this._isLoading = flag
  }

  private _error = ''

  public get error(): string {
    return this._error
  }

  public setError(error: string): void {
    this._error = error
  }
}
