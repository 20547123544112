import {store} from '@/library/store'
import type {ChangeEvent} from 'react'

export class BalanceService {
  public get state() {
    return store().bookingModal.balance
  }

  private get userState() {
    return store().user
  }

  public submitAmount = (amount: string) => {
    if (this.state.amount) {
      this.state.setAmount(null)
      return
    }

    if (amount) {
      const parsedAmount = Number.parseInt(amount, 10)
      this.state.setAmount(parsedAmount)
    }
  }

  public setInput = (event: ChangeEvent<HTMLInputElement>) => {
    this.state.setInput(event.target.value)
  }

  public withdrawFromUserBalance = (amount: number) => {
    const {user} = this.userState

    if (!user) {
      return
    }

    this.userState.setBalance(user.balance.amount - amount)
  }
}
