import {autorun, makeAutoObservable, runInAction} from 'mobx'
import CDestination from './destination'
import CDateFrom from './date-from'
import CDateTo from './date-to'
import CGuest from './guests'

type InputsKeys = 'destination' | 'dateFrom' | 'dateTo' | 'guests'

export default class CSearch {
  public readonly destination: CDestination = new CDestination()

  public readonly dateFrom: CDateFrom = new CDateFrom()

  public readonly dateTo: CDateTo = new CDateTo()

  public readonly guests: CGuest = new CGuest()

  private readonly inputsKeys: InputsKeys[] = [
    'destination',
    'dateFrom',
    'dateTo',
    'guests',
  ]

  private _isOpenMobileMainPage = false

  public get isOpenMobileMainPage(): boolean {
    return this._isOpenMobileMainPage
  }

  public setIsOpenMobileMainPage(state: boolean): void {
    this._isOpenMobileMainPage = state
  }

  private _isStartSearching = false

  constructor() {
    makeAutoObservable(this)

    this.initializeAutoRuns()
  }

  public get isStartSearch(): boolean {
    return this._isStartSearching
  }

  public isActiveInput(): boolean {
    return Object.values(this.inputsKeys).some(
      (inputKey) => this[inputKey].isOpen,
    )
  }

  public resetActiveInput(): void {
    Object.values(this.inputsKeys).forEach((inputKey) => {
      this[inputKey].setIsOpen(false)
    })
  }

  public setIsStartSearch(flag: boolean): void {
    this._isStartSearching = flag
  }

  /**
   * Инициализирует проверку стейта isOpen инпутов поиска, и если один из них изменился на true - изменяет другие на false
   * @private
   */
  private initializeAutoRuns(): void {
    this.inputsKeys.forEach((key) => {
      autorun(() => {
        if (this[key].isOpen) {
          this.toggleInput(key)
        }
      })
    })
  }

  private toggleInput(input: InputsKeys): void {
    runInAction(() => {
      this[input].setIsOpen(true)

      Object.values(this.inputsKeys).forEach((inputKey) => {
        if (inputKey !== input) {
          this[inputKey].setIsOpen(false)
        }
      })
    })
  }
}
