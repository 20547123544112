import {services} from '@/library/services'
import type CSearch from '@/library/store/search'
import {ENVIRONMENT} from '@/library/utils/guards/environment'
import {DateFormatPattern} from '@/types/enums/date-format-patterns'
import {format} from 'date-fns'
import type {Translate} from 'next-translate'

export const buildRate = (search: CSearch) => {
  return {
    adults: search.guests.adults.amount,
    checkin: search.dateFrom.value
      ? format(search.dateFrom.value, DateFormatPattern.BACKEND_DATE_FORMAT)
      : '',
    checkout: search.dateTo.value
      ? format(search.dateTo.value, DateFormatPattern.BACKEND_DATE_FORMAT)
      : '',
    children: search.guests.children.children,
  }
}

export const buildBookingFormErrors = (
  t: Translate,
  validationErrorText: string,
  providerRateError?: string,
): Record<string, {text: string; action?: () => void}> => {
  return {
    validation: {
      text: providerRateError
        ? t('booking_modal.errors.provider_rate_error')
        : validationErrorText,
    },
    'rate-limit': {
      text: t('booking_modal.errors.try_again'),
    },
    'external-api': {
      text: t('booking_modal.errors.try_again'),
    },
    'promo-codes.expired': {
      text: t('booking_modal.errors.promo_code_only_for_new_users'),
      action: () =>
        services.pages.global.scenario.hotel.booking.promoCode.state.reset(),
    },
    'promo-codes.already-used': {
      text: t('booking_modal.errors.promo_code_already_used'),
      action: () =>
        services.pages.global.scenario.hotel.booking.promoCode.state.reset(),
    },
    'booking.payment.minimum.incorrect': {
      text: t('booking_modal.errors.incorrect_payment_minimum'),
      action: () =>
        services.pages.global.scenario.hotel.booking.promoCode.state.reset(),
    },
    'user-balance.write-off.insufficient': {
      text: t('booking_modal.errors.insufficient_balance'),
      action: () =>
        services.pages.global.scenario.hotel.booking.balance.state.reset(),
    },
    'user-balance.write-off.incorrect': {
      text: t('booking_modal.errors.incorrect_balance_writeoff', {
        minimalAmount: ENVIRONMENT.MINIMAL_BALANCE_WRITEOFF,
      }),
      action: () =>
        services.pages.global.scenario.hotel.booking.balance.state.reset(),
    },
    'user-balance.cant-be-apply-with-discount': {
      text: t('booking_modal.errors.cant_be_applied_with_discount'),
    },
  }
}
