import styled from '@emotion/styled'
import {useDeviceDetection} from '@hooks/use-device-detection'
import useTranslation from 'next-translate/useTranslation'
import {useWindowSize} from '@/library/hooks/use-window-size'
import {css} from '@emotion/react'
import {MEDIA_DEVICE_QUERY} from '@/assets/theme/media'
import {GuestsColumn} from './ui/guests-column'
import {HoteliersColumn} from './ui/hoteliers-column'
import {InformationColumn} from './ui/information-column'
import {MobileAppColumn} from './ui/mobile-app-column'
import {PaymentMethods} from './ui/payment-methods'
import {Text} from '../../ui'
import {HorizontalDivider} from '../../ui/divider'
import {MOBILE_LAYOUT_BREAKPOINT} from './lib/constants'

const StyledFooter = styled.footer<{isMobile: boolean}>`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: fit-content;
  box-sizing: border-box;

  ${({isMobile}) =>
    isMobile &&
    css`
      margin-top: 60px;
    `}
`

const Container = styled.div<{maxWidth?: number}>`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: ${({maxWidth}) => maxWidth || 1920}px;
  width: 100%;
  padding: 0 110px;

  @media ${MEDIA_DEVICE_QUERY.LAPTOP} {
    padding: 0px 32px;
  }

  @media ${MEDIA_DEVICE_QUERY.MOBILE_LARGE} {
    padding: 0 16px;
  }
`

const TopDivider = styled(HorizontalDivider)`
  margin-bottom: 60px;

  @media ${MEDIA_DEVICE_QUERY.LAPTOP_LARGE} {
    margin-bottom: 20px;
  }
`

const BottomDivider = styled(HorizontalDivider)<{isMobile: boolean}>`
  margin-top: ${({isMobile}) => (isMobile ? 25 : 60)}px;
`

const ColumnsWrapper = styled.div<{isMobile?: boolean}>`
  display: grid;
  justify-content: space-between;
  grid-template-columns: 270px 270px 270px 302px;
  gap: 5px;

  @media ${MEDIA_DEVICE_QUERY.LAPTOP_LARGE} {
    grid-template-columns: 225px 225px 225px 302px;
  }

  ${({isMobile}) =>
    isMobile &&
    css`
      display: flex;
      flex-direction: column;
      gap: 12px;
    `}
`

const CopyrightWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 0;
`

export const Footer = () => {
  const {t} = useTranslation('features.footer')

  const isMobile = useDeviceDetection('mobile')
  const windowSize = useWindowSize()
  const isMobileLayout = isMobile || windowSize < MOBILE_LAYOUT_BREAKPOINT

  return (
    <StyledFooter isMobile={isMobileLayout}>
      <TopDivider />
      <Container>
        <ColumnsWrapper isMobile={isMobileLayout}>
          <GuestsColumn />
          <InformationColumn />
          <HoteliersColumn />
          <MobileAppColumn />
        </ColumnsWrapper>
      </Container>
      <BottomDivider isMobile={isMobileLayout} />
      <Container>
        <CopyrightWrapper>
          <Text size="S">
            {t('copyright', {year: new Date().getFullYear()})}
          </Text>
          <PaymentMethods />
        </CopyrightWrapper>
      </Container>
    </StyledFooter>
  )
}
