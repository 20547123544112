type Environments = {
  GRAPHQL_API: string
  REST_API_CLIENT: string
  NEXT_PUBLIC_HTTPS_PROTOCOL: string
  NEXT_PUBLIC_PROTOCOL: string

  NEXT_PUBLIC_HOSTNAME_HOTELIERS: string
  NEXT_PUBLIC_HOSTNAME_ZHILIBYLI: string
  NEXT_PUBLIC_HOSTNAME_OSTROVOK: string

  NEXT_PUBLIC_HOTELIERS_URL: string
  NEXT_PUBLIC_MAX_SEARCH_DATE_RANGE_COUNT: string
  NEXT_PUBLIC_MAX_SEARCH_INPUT_ADULTS: string
  NEXT_PUBLIC_MAX_SEARCH_INPUT_CHILDREN: string
  NEXT_PUBLIC_MAX_SEARCH_INPUT_CHILDREN_AGE: string
  NEXT_PUBLIC_MIN_PRICE_VALUE: string
  NEXT_PUBLIC_MAX_PRICE_VALUE: string
  NEXT_PUBLIC_MIN_GUEST_AGE: string
  NEXT_PUBLIC_MAX_GUEST_AGE: string
  NEXT_PUBLIC_SEARCH_CALENDAR_MAX_YEARS_COUNT: string
  NEXT_PUBLIC_SEARCH_CALENDAR_MIN_DAYS_COUNT: string
  NEXT_PUBLIC_HEADER_PROMO_CODE: string
  NEXT_PUBLIC_HEADER_PROMO_IS_DISPLAY: string
  NEXT_PUBLIC_DEFAULT_RATING_VALUE: string
  NEXT_PUBLIC_DEFAULT_STAR_RATING_VALUE: string
  NEXT_PUBLIC_DEFAULT_DIVISION_VALUE: string
  NEXT_PUBLIC_BEST_RATING: string
  NEXT_PUBLIC_WORST_RATING: string
  NEXT_PUBLIC_DEFAULT_SEO_COUNT: string
  NEXT_PUBLIC_MAX_HOTELS_REVIEWS_DESCRIPTION_VALUE_LENGTH: string
  NEXT_PUBLIC_MAX_HOTELS_REVIEWS_IMAGE_SIZE: string
  NEXT_PUBLIC_MAX_HOTELS_REVIEWS_IMAGE_AMOUNT: string
  NEXT_PUBLIC_IMAGE_UPLOAD_ACCEPT: string
  NEXT_PUBLIC_MINIMAL_BALANCE_WRITEOFF: string
  NEXT_PUBLIC_VERIFICATION_LINK: string
  NEXT_PUBLIC_MIN_PROMOCODE_LENGTH: string

  NEXT_PUBLIC_FINGERPRINT_COOKIE_KEY: string
  NEXT_PUBLIC_FINGERPRINT_PUBLIC_KEY: string

  NEXT_PUBLIC_ADMIT_AD_UUID_KEY: string
  NEXT_PUBLIC_ADMIT_AD_UUID_VISIT_KEY: string
  NEXT_PUBLIC_ADMIT_AD_UTM_SOURCE: string
  NEXT_PUBLIC_UTM_SOURCE_COOKIE_KEY: string
  NEXT_PUBLIC_AFFILIATE_REFERRAL_CODE_QUERY_KEY: string

  NEXT_PUBLIC_APPLICATION_PUSH_UUID: string

  NEXT_PUBLIC_IS_LOCALHOST: string
  NEXT_PUBLIC_VK: string
  NEXT_PUBLIC_TG: string
  NEXT_PUBLIC_TG_BOT: string
  NEXT_PUBLIC_ZHILIBYLI_BLOG: string
  NEXT_PUBLIC_YOUTUBE: string
  NEXT_PUBLIC_KNOWLEDGE_BASE: string
  NEXT_PUBLIC_HOW_TO_BOOK_ARTICLE: string
  NEXT_PUBLIC_INFO: string
  NEXT_PUBLIC_HOTELIERS_AUTH: string
  NEXT_PUBLIC_HOTELIERS_REGISTRATION: string
  NEXT_PUBLIC_VERIFICATION_ADVANTAGES: string
  NEXT_PUBLIC_ZHILIBYLI_PHONE: string
  NEXT_PUBLIC_ZHILIBYLI_EMAIL: string
  NEXT_PUBLIC_ZHILIBYLI_HR_EMAIL: string
  NEXT_PUBLIC_SITE_DOMAIN: string
  NEXT_PUBLIC_ORIGIN_SITE_DOMAIN: string
  NEXT_PUBLIC_BITRIX: string
  NEXT_PUBLIC_GOSUSLUGI_ITORGS: string
  NEXT_PUBLIC_OBJECT_URL_EXAMPLE_OSTROVOK: string
  NEXT_PUBLIC_OBJECT_URL_EXAMPLE_SUTOCHNO: string
  NEXT_PUBLIC_OBJECT_URL_EXAMPLE_BOOKING: string
  NEXT_PUBLIC_YANDEX_NAVIGATION: string
  NEXT_PUBLIC_TWOGIS_NAVIGATION: string
  NEXT_PUBLIC_GOOGLE_NAVIGATION: string
  NEXT_PUBLIC_SEARCH_SCENARIO_EXECUTION_TIME_DURATION: string

  NEXT_PUBLIC_COOKIE_TAG_VERSION: string

  NEXT_PUBLIC_WHATSAPP_SHARE_LINK: string
  NEXT_PUBLIC_VK_SHARE_LINK: string
  NEXT_PUBLIC_VK_MOBILE_SHARE_LINK: string
  NEXT_PUBLIC_TELEGRAM_SHARE_LINK: string
  NEXT_PUBLIC_TWITTER_SHARE_LINK: string
  NEXT_PUBLIC_VIBER_SHARE_LINK: string
  NEXT_PUBLIC_DZEN: string
  NEXT_PUBLIC_OTZOVIK_SHARE_LINK: string
  NEXT_PUBLIC_YANDEX_SHARE_LINK: string

  NEXT_PUBLIC_GOOGLE_METRIC_ID: string
  NEXT_PUBLIC_YM_ZBRU: string
  NEXT_PUBLIC_ROISTAT_ID: string
  NEXT_PUBLIC_HOTJAR_ID: string

  NEXT_PUBLIC_YANDEX_MAP_API_KEY: string
  NEXT_PUBLIC_SUBSCRIPTION_WIDGET_SHOW_TIME_COOKIE_KEY: string
  NEXT_PUBLIC_GENERAL_NEWSLETTER_SUBSCRIBED_COOKIE_KEY: string
  NEXT_PUBLIC_TIME_TO_DISPLAY_SUBSCRIPTION_WIDGET: string
  NEXT_PUBLIC_SUBSCRIPTION_WIDGET_DELAY: string

  NEXT_PUBLIC_PLAY_MARKET_LINK: string
  NEXT_PUBLIC_DOWNLOAD_APP_LINK: string
  NEXT_PUBLIC_APP_GALLERY_LINK: string
  NEXT_PUBLIC_GET_APP_LINK: string
  NEXT_PUBLIC_APP_STORE_LINK: string

  NEXT_PUBLIC_YANDEX_TAXI_URL: string
  NEXT_PUBLIC_YANDEX_TAXI_TARIFF_CLASS: string
  NEXT_PUBLIC_YANDEX_TAXI_REF: string
  NEXT_PUBLIC_YANDEX_TAXI_APP_METRIC_TRACKING_ID: string
  NEXT_PUBLIC_YANDEX_TAXI_LANG: string
  NEXT_PUBLIC_YANDEX_TAXI_ERID: string

  NEXT_PUBLIC_SCHEMA_URL: string
  NEXT_PUBLIC_GOOD_RELATIONS_URL: string
  NEXT_PUBLIC_SCHEMA_ORGANIZATION_NAME: string
  NEXT_PUBLIC_SCHEMA_ORGANIZATION_LOGO: string
  NEXT_PUBLIC_SCHEMA_ORGANIZATION_STREET: string
  NEXT_PUBLIC_SCHEMA_ORGANIZATION_CITY: string
  NEXT_PUBLIC_SCHEMA_ORGANIZATION_REGION: string
  NEXT_PUBLIC_SCHEMA_ORGANIZATION_POSTAL_CODE: string
  NEXT_PUBLIC_SCHEMA_ORGANIZATION_COUNTRY: string
  NEXT_PUBLIC_ZHILIBYLI_GEO_COORDINATES_LAT: string
  NEXT_PUBLIC_ZHILIBYLI_GEO_COORDINATES_LNG: string

  NEXT_PUBLIC_ZHILIBYLI_TIN: string
  NEXT_PUBLIC_ZHILIBYLI_OGRN: string

  NEXT_PUBLIC_SEARCH_REFRESH_DELAY: string

  NEXT_PUBLIC_MAIL_COUNTER_PIXEL_LINK: string
  NEXT_PUBLIC_MAIL_COUNTER_TMR_ID: string
  NEXT_PUBLIC_MAIL_COUNTER_CODE_LINK: string

  NEXT_PUBLIC_MAX_RATE_ALLOTMENT_COUNT: string
}

const getEnvironment = (
  key: keyof Environments,
  isRequired = true,
): Environments[keyof Environments] => {
  const environment = process.env

  if (!(key in environment) && isRequired) {
    throw new Error(`Environment: ${key} is not defined in environment file`)
  }

  return environment[key] as string
}

export const ENVIRONMENT = {
  HOTELIERS_URL: getEnvironment('NEXT_PUBLIC_HOTELIERS_URL'),
  GRAPHQL_API: getEnvironment('GRAPHQL_API'),
  REST_API_CLIENT: getEnvironment('REST_API_CLIENT'),
  HTTPS_PROTOCOL: getEnvironment('NEXT_PUBLIC_HTTPS_PROTOCOL'),
  HOSTNAME_HOTELIERS: getEnvironment('NEXT_PUBLIC_HOSTNAME_HOTELIERS'),
  HOSTNAME_ZHILIBYLI: getEnvironment('NEXT_PUBLIC_HOSTNAME_ZHILIBYLI'),
  HOSTNAME_OSTROVOK: getEnvironment('NEXT_PUBLIC_HOSTNAME_OSTROVOK'),
  PROTOCOL: getEnvironment('NEXT_PUBLIC_PROTOCOL'),

  MAX_SEARCH_DATE_RANGE_COUNT: Number(
    getEnvironment('NEXT_PUBLIC_MAX_SEARCH_DATE_RANGE_COUNT'),
  ),
  MAX_SEARCH_INPUT_ADULTS: Number(
    getEnvironment('NEXT_PUBLIC_MAX_SEARCH_INPUT_ADULTS'),
  ),
  MAX_SEARCH_INPUT_CHILDREN: Number(
    getEnvironment('NEXT_PUBLIC_MAX_SEARCH_INPUT_CHILDREN'),
  ),
  MAX_SEARCH_INPUT_CHILDREN_AGE: Number(
    getEnvironment('NEXT_PUBLIC_MAX_SEARCH_INPUT_CHILDREN_AGE'),
  ),
  MIN_PRICE_VALUE: Number(getEnvironment('NEXT_PUBLIC_MIN_PRICE_VALUE')),
  MAX_PRICE_VALUE: Number(getEnvironment('NEXT_PUBLIC_MAX_PRICE_VALUE')),
  MIN_GUEST_AGE: Number(getEnvironment('NEXT_PUBLIC_MIN_GUEST_AGE')),
  MAX_GUEST_AGE: Number(getEnvironment('NEXT_PUBLIC_MAX_GUEST_AGE')),
  SEARCH_CALENDAR_MAX_YEARS_COUNT: Number(
    getEnvironment('NEXT_PUBLIC_SEARCH_CALENDAR_MAX_YEARS_COUNT'),
  ),
  SEARCH_CALENDAR_MIN_DAYS_COUNT: Number(
    getEnvironment('NEXT_PUBLIC_SEARCH_CALENDAR_MIN_DAYS_COUNT'),
  ),
  FINGERPRINT_COOKIE_KEY: getEnvironment('NEXT_PUBLIC_FINGERPRINT_COOKIE_KEY'),
  FINGERPRINT_PUBLIC_KEY: getEnvironment('NEXT_PUBLIC_FINGERPRINT_PUBLIC_KEY'),
  ADMIT_AD_UUID_KEY: getEnvironment('NEXT_PUBLIC_ADMIT_AD_UUID_KEY'),
  ADMIT_AD_UUID_VISIT_KEY: getEnvironment(
    'NEXT_PUBLIC_ADMIT_AD_UUID_VISIT_KEY',
  ),
  ADMIT_AD_UTM_SOURCE: getEnvironment('NEXT_PUBLIC_ADMIT_AD_UTM_SOURCE'),
  UTM_SOURCE_COOKIE_KEY: getEnvironment('NEXT_PUBLIC_UTM_SOURCE_COOKIE_KEY'),
  AFFILIATE_REFERRAL_CODE_QUERY_KEY: getEnvironment(
    'NEXT_PUBLIC_AFFILIATE_REFERRAL_CODE_QUERY_KEY',
  ),
  APPLICATION_PUSH_UUID: getEnvironment('NEXT_PUBLIC_APPLICATION_PUSH_UUID'),
  IS_LOCALHOST: JSON.parse(getEnvironment('NEXT_PUBLIC_IS_LOCALHOST')),
  VK: getEnvironment('NEXT_PUBLIC_VK'),
  TG: getEnvironment('NEXT_PUBLIC_TG'),
  TG_BOT: getEnvironment('NEXT_PUBLIC_TG_BOT'),
  ZHILIBYLI_BLOG: getEnvironment('NEXT_PUBLIC_ZHILIBYLI_BLOG'),
  YOUTUBE: getEnvironment('NEXT_PUBLIC_YOUTUBE'),
  KNOWLEDGE_BASE: getEnvironment('NEXT_PUBLIC_KNOWLEDGE_BASE'),
  HOW_TO_BOOK_ARTICLE: getEnvironment('NEXT_PUBLIC_HOW_TO_BOOK_ARTICLE'),
  INFO: getEnvironment('NEXT_PUBLIC_INFO'),
  HOTELIERS_AUTH: getEnvironment('NEXT_PUBLIC_HOTELIERS_AUTH'),
  HOTELIERS_REGISTRATION: getEnvironment('NEXT_PUBLIC_HOTELIERS_REGISTRATION'),
  VERIFICATION_ADVANTAGES: getEnvironment(
    'NEXT_PUBLIC_VERIFICATION_ADVANTAGES',
  ),
  BITRIX: getEnvironment('NEXT_PUBLIC_BITRIX'),
  GOSUSLUGI_ITORGS: getEnvironment('NEXT_PUBLIC_GOSUSLUGI_ITORGS'),
  OBJECT_URL_EXAMPLE_OSTROVOK: getEnvironment(
    'NEXT_PUBLIC_OBJECT_URL_EXAMPLE_OSTROVOK',
  ),
  OBJECT_URL_EXAMPLE_SUTOCHNO: getEnvironment(
    'NEXT_PUBLIC_OBJECT_URL_EXAMPLE_SUTOCHNO',
  ),
  OBJECT_URL_EXAMPLE_BOOKING: getEnvironment(
    'NEXT_PUBLIC_OBJECT_URL_EXAMPLE_BOOKING',
  ),

  DEFAULT_RATING_VALUE: Number(
    getEnvironment('NEXT_PUBLIC_DEFAULT_RATING_VALUE'),
  ),
  DEFAULT_STAR_RATING_VALUE: Number(
    getEnvironment('NEXT_PUBLIC_DEFAULT_STAR_RATING_VALUE'),
  ),
  DEFAULT_DIVISION_VALUE: Number(
    getEnvironment('NEXT_PUBLIC_DEFAULT_DIVISION_VALUE'),
  ),
  IMAGE_UPLOAD_ACCEPT: getEnvironment('NEXT_PUBLIC_IMAGE_UPLOAD_ACCEPT'),
  BEST_RATING: Number(getEnvironment('NEXT_PUBLIC_BEST_RATING')),
  WORST_RATING: Number(getEnvironment('NEXT_PUBLIC_WORST_RATING')),
  DEFAULT_SEO_COUNT: Number(getEnvironment('NEXT_PUBLIC_DEFAULT_SEO_COUNT')),
  MINIMAL_BALANCE_WRITEOFF: Number(
    getEnvironment('NEXT_PUBLIC_MINIMAL_BALANCE_WRITEOFF'),
  ),
  MIN_PROMOCODE_LENGTH: Number(
    getEnvironment('NEXT_PUBLIC_MIN_PROMOCODE_LENGTH'),
  ),
  VERIFICATION_LINK: getEnvironment('NEXT_PUBLIC_VERIFICATION_LINK'),
  WHATSAPP_SHARE_LINK: getEnvironment('NEXT_PUBLIC_WHATSAPP_SHARE_LINK'),
  VK_SHARE_LINK: getEnvironment('NEXT_PUBLIC_VK_SHARE_LINK'),
  VK_MOBILE_SHARE_LINK: getEnvironment('NEXT_PUBLIC_VK_MOBILE_SHARE_LINK'),
  TELEGRAM_SHARE_LINK: getEnvironment('NEXT_PUBLIC_TELEGRAM_SHARE_LINK'),
  TWITTER_SHARE_LINK: getEnvironment('NEXT_PUBLIC_TWITTER_SHARE_LINK'),
  VIBER_SHARE_LINK: getEnvironment('NEXT_PUBLIC_VIBER_SHARE_LINK'),

  ZHILIBYLI_PHONE: getEnvironment('NEXT_PUBLIC_ZHILIBYLI_PHONE'),
  ZHILIBYLI_EMAIL: getEnvironment('NEXT_PUBLIC_ZHILIBYLI_EMAIL'),
  ZHILIBYLI_HR_EMAIL: getEnvironment('NEXT_PUBLIC_ZHILIBYLI_HR_EMAIL'),
  ORIGIN_SITE_DOMAIN: getEnvironment('NEXT_PUBLIC_ORIGIN_SITE_DOMAIN'),
  HEADER_PROMO_CODE: getEnvironment('NEXT_PUBLIC_HEADER_PROMO_CODE'),
  HEADER_PROMO_IS_DISPLAY: JSON.parse(
    getEnvironment('NEXT_PUBLIC_HEADER_PROMO_IS_DISPLAY'),
  ),

  GOOGLE_METRIC_ID: getEnvironment('NEXT_PUBLIC_GOOGLE_METRIC_ID'),
  YM_ZBRU: getEnvironment('NEXT_PUBLIC_YM_ZBRU'),
  ROISTAT_ID: getEnvironment('NEXT_PUBLIC_ROISTAT_ID'),
  HOTJAR_ID: getEnvironment('NEXT_PUBLIC_HOTJAR_ID'),

  YANDEX_MAP_API_KEY: getEnvironment('NEXT_PUBLIC_YANDEX_MAP_API_KEY'),
  SUBSCRIPTION_WIDGET_SHOW_TIME_COOKIE_KEY: getEnvironment(
    'NEXT_PUBLIC_SUBSCRIPTION_WIDGET_SHOW_TIME_COOKIE_KEY',
  ),
  GENERAL_NEWSLETTER_SUBSCRIBED_COOKIE_KEY: getEnvironment(
    'NEXT_PUBLIC_GENERAL_NEWSLETTER_SUBSCRIBED_COOKIE_KEY',
  ),
  TIME_TO_DISPLAY_SUBSCRIPTION_WIDGET: getEnvironment(
    'NEXT_PUBLIC_TIME_TO_DISPLAY_SUBSCRIPTION_WIDGET',
  ),
  SUBSCRIPTION_WIDGET_DELAY: getEnvironment(
    'NEXT_PUBLIC_SUBSCRIPTION_WIDGET_DELAY',
  ),
  PLAY_MARKET_LINK: getEnvironment('NEXT_PUBLIC_PLAY_MARKET_LINK'),
  DOWNLOAD_APP_LINK: getEnvironment('NEXT_PUBLIC_DOWNLOAD_APP_LINK'),
  APP_GALLERY_LINK: getEnvironment('NEXT_PUBLIC_APP_GALLERY_LINK'),
  GET_APP_LINK: getEnvironment('NEXT_PUBLIC_GET_APP_LINK'),
  APP_STORE_LINK: getEnvironment('NEXT_PUBLIC_APP_STORE_LINK'),

  NEXT_PUBLIC_YANDEX_TAXI_URL: getEnvironment('NEXT_PUBLIC_YANDEX_TAXI_URL'),
  NEXT_PUBLIC_YANDEX_TAXI_TARIFF_CLASS: getEnvironment(
    'NEXT_PUBLIC_YANDEX_TAXI_TARIFF_CLASS',
  ),
  NEXT_PUBLIC_YANDEX_TAXI_REF: getEnvironment('NEXT_PUBLIC_YANDEX_TAXI_REF'),
  NEXT_PUBLIC_YANDEX_TAXI_APP_METRIC_TRACKING_ID: getEnvironment(
    'NEXT_PUBLIC_YANDEX_TAXI_APP_METRIC_TRACKING_ID',
  ),
  NEXT_PUBLIC_YANDEX_TAXI_LANG: getEnvironment('NEXT_PUBLIC_YANDEX_TAXI_LANG'),
  NEXT_PUBLIC_YANDEX_TAXI_ERID: getEnvironment('NEXT_PUBLIC_YANDEX_TAXI_ERID'),

  YANDEX_NAVIGATION: getEnvironment('NEXT_PUBLIC_YANDEX_NAVIGATION'),
  TWOGIS_NAVIGATION: getEnvironment('NEXT_PUBLIC_TWOGIS_NAVIGATION'),
  GOOGLE_NAVIGATION: getEnvironment('NEXT_PUBLIC_GOOGLE_NAVIGATION'),
  SEARCH_SCENARIO_EXECUTION_TIME_DURATION: Number(
    getEnvironment('NEXT_PUBLIC_SEARCH_SCENARIO_EXECUTION_TIME_DURATION'),
  ),
  COOKIE_TAG_VERSION: getEnvironment('NEXT_PUBLIC_COOKIE_TAG_VERSION'),
  DZEN: getEnvironment('NEXT_PUBLIC_DZEN'),
  OTZOVIK_SHARE_LINK: getEnvironment('NEXT_PUBLIC_OTZOVIK_SHARE_LINK'),
  YANDEX_SHARE_LINK: getEnvironment('NEXT_PUBLIC_YANDEX_SHARE_LINK'),
  SCHEMA_URL: getEnvironment('NEXT_PUBLIC_SCHEMA_URL'),
  GOOD_RELATIONS_URL: getEnvironment('NEXT_PUBLIC_GOOD_RELATIONS_URL'),
  SCHEMA_ORGANIZATION_NAME: getEnvironment(
    'NEXT_PUBLIC_SCHEMA_ORGANIZATION_NAME',
  ),
  SCHEMA_ORGANIZATION_LOGO: getEnvironment(
    'NEXT_PUBLIC_SCHEMA_ORGANIZATION_LOGO',
  ),
  SCHEMA_ORGANIZATION_STREET: getEnvironment(
    'NEXT_PUBLIC_SCHEMA_ORGANIZATION_STREET',
  ),
  SCHEMA_ORGANIZATION_CITY: getEnvironment(
    'NEXT_PUBLIC_SCHEMA_ORGANIZATION_CITY',
  ),
  SCHEMA_ORGANIZATION_REGION: getEnvironment(
    'NEXT_PUBLIC_SCHEMA_ORGANIZATION_REGION',
  ),
  SCHEMA_ORGANIZATION_POSTAL_CODE: getEnvironment(
    'NEXT_PUBLIC_SCHEMA_ORGANIZATION_POSTAL_CODE',
  ),
  SCHEMA_ORGANIZATION_COUNTRY: getEnvironment(
    'NEXT_PUBLIC_SCHEMA_ORGANIZATION_COUNTRY',
  ),

  MAX_HOTELS_REVIEWS_DESCRIPTION_VALUE_LENGTH: Number(
    getEnvironment('NEXT_PUBLIC_MAX_HOTELS_REVIEWS_DESCRIPTION_VALUE_LENGTH'),
  ),
  MAX_HOTELS_REVIEWS_IMAGE_SIZE: Number(
    getEnvironment('NEXT_PUBLIC_MAX_HOTELS_REVIEWS_IMAGE_SIZE'),
  ),
  MAX_HOTELS_REVIEWS_IMAGE_AMOUNT: Number(
    getEnvironment('NEXT_PUBLIC_MAX_HOTELS_REVIEWS_IMAGE_AMOUNT'),
  ),

  ZHILIBYLI_TIN: getEnvironment('NEXT_PUBLIC_ZHILIBYLI_TIN'),
  ZHILIBYLI_OGRN: getEnvironment('NEXT_PUBLIC_ZHILIBYLI_OGRN'),

  ZHILIBYLI_GEO_COORDINATES_LAT: Number(
    getEnvironment('NEXT_PUBLIC_ZHILIBYLI_GEO_COORDINATES_LAT'),
  ),
  ZHILIBYLI_GEO_COORDINATES_LNG: Number(
    getEnvironment('NEXT_PUBLIC_ZHILIBYLI_GEO_COORDINATES_LNG'),
  ),
  SEARCH_REFRESH_DELAY: Number(
    getEnvironment('NEXT_PUBLIC_SEARCH_REFRESH_DELAY'),
  ),
  MAIL_COUNTER_PIXEL_LINK: getEnvironment(
    'NEXT_PUBLIC_MAIL_COUNTER_PIXEL_LINK',
  ),
  MAIL_COUNTER_TMR_ID: getEnvironment('NEXT_PUBLIC_MAIL_COUNTER_TMR_ID'),
  MAIL_COUNTER_CODE_LINK: getEnvironment('NEXT_PUBLIC_MAIL_COUNTER_CODE_LINK'),

  MAX_RATE_ALLOTMENT_COUNT: Number(
    getEnvironment('NEXT_PUBLIC_MAX_RATE_ALLOTMENT_COUNT'),
  ),
}
