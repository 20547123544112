import {handleApiException} from '@library/utils/exceptions/api'
import type {IHttpHandlerActions} from '@api/request/interceptors/catch/status-code/lib/types'

export const HttpHandlerActions: IHttpHandlerActions = {
  UNAUTHORIZED: ({parameters}) => {
    handleApiException(parameters)
  },
  TOO_MANY_REQUESTS: ({parameters}) => {
    handleApiException(parameters)
  },
  NOT_FOUND: ({parameters}) => {
    handleApiException(parameters)
  },
}
