import DOMPurify from 'isomorphic-dompurify'
import Script from 'next/script'
import {type FC} from 'react'

interface LdScriptProps {
  scriptKey: string
  schema: Record<string, unknown>
}

export const LdScript: FC<LdScriptProps> = ({scriptKey, schema}) => {
  return (
    // eslint-disable-next-line @next/next/no-before-interactive-script-outside-document
    <Script
      id={scriptKey}
      strategy="beforeInteractive"
      type="application/ld+json"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(JSON.stringify(schema)),
      }}
    />
  )
}
