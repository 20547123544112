import {type FC} from 'react'
import {type PopoverProps} from 'antd/lib/popover'
import dynamic from 'next/dynamic'
import {css, useTheme} from '@emotion/react'
import styled from '@emotion/styled'
import {isFunction} from '@/library/utils/guards/types'
import {LOADING_STYLES} from '@/library/constants/css'
import {CircularLoader} from '../loader'

const LibraryPopover = dynamic(() => import('antd/lib/popover'))

const PopoverContentWrapper = styled.div<{$isLoading: boolean}>`
  padding: 20px 24px;
  position: relative;

  ${({$isLoading}) =>
    $isLoading &&
    css`
      ${LOADING_STYLES}
    `}
`

const StyledCircucalLoader = styled(CircularLoader)`
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
`

interface IPopoverProps extends PopoverProps {
  isLoading?: boolean
}

export const Popover: FC<IPopoverProps> = ({
  children,
  trigger = 'click',
  content,
  className,
  isLoading = false,
  ...properties
}) => {
  const theme = useTheme()

  return (
    <LibraryPopover
      trigger={trigger}
      overlayInnerStyle={{
        padding: '0',
        borderRadius: 12,
        color: theme.palette.textPrimary,
        ...properties.overlayInnerStyle,
      }}
      content={
        <PopoverContentWrapper className={className} $isLoading={isLoading}>
          {isLoading && <StyledCircucalLoader size={32} />}
          {isFunction(content) ? content() : content}
        </PopoverContentWrapper>
      }
      {...properties}
    >
      {children}
    </LibraryPopover>
  )
}
