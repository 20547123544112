import type {FC} from 'react'
import {Card, Carousel, CarouselContent, CarouselItem} from '@ui/index'
import styled from '@emotion/styled'
import {MEDIA_DEVICE_QUERY} from '@assets/theme/media'
import {useLocalObservable} from 'mobx-react-lite'
import {store} from '@store/index'
import {observer} from 'mobx-react'
import type {TitleType} from '@ui/typography/title'
import {CarouselNext, CarouselPrevious} from '@ui/carousel'
import {ListWrapper} from '../list-wrapper'
import {NUMBER_OF_SKELETONS} from '../../lib/constants'
import {ItemSkeleton} from '../item-skeleton'
import {CityDestination} from '../city-destination'

const StyledCarousel = styled(Carousel)`
  width: 100%;
  height: 100%;
`

const StyledCarouselItem = styled(CarouselItem)`
  flex-basis: fit-content;

  :not(:first-of-type) {
    padding-left: 20px;
  }
`

const StyledCardContainer = styled(Card.Container)`
  @media ${MEDIA_DEVICE_QUERY.DESKTOP} {
    flex: 0 0 18.7%;
  }

  @media ${MEDIA_DEVICE_QUERY.LAPTOP_LARGE} {
    flex: 0 0 23%;
  }
`

interface PopularCityDestinationsDesktopSkeletonProps {
  className?: string
}

const PopularCityDestinationsDesktopSkeleton: FC<
  PopularCityDestinationsDesktopSkeletonProps
> = ({className}) => {
  return (
    <ListWrapper className={className}>
      {Array.from({length: NUMBER_OF_SKELETONS}).map((_, index) => (
        <StyledCardContainer key={`skeleton-${index}`}>
          <Card.Content>
            <ItemSkeleton active />
          </Card.Content>
        </StyledCardContainer>
      ))}
    </ListWrapper>
  )
}

const selectPopularCityDestinations = () => ({
  get popularCityDestinations() {
    return store().popularCityDestinations.popularCityDestinations
  },
  get isLoading() {
    return store().popularCityDestinations.isLoading
  },
})

interface PopularCityDestinationsDesktopProps {
  className?: string
  headlineType?: TitleType
}

export const PopularCityDestinationsDesktop: FC<PopularCityDestinationsDesktopProps> =
  observer(({className, headlineType}) => {
    const {isLoading, popularCityDestinations} = useLocalObservable(
      selectPopularCityDestinations,
    )

    if (isLoading) {
      return <PopularCityDestinationsDesktopSkeleton className={className} />
    }

    if (!popularCityDestinations) {
      return null
    }

    return (
      <ListWrapper className={className} headlineType={headlineType}>
        <StyledCarousel>
          <CarouselContent>
            {popularCityDestinations.map((cityDestination) => (
              <StyledCarouselItem key={cityDestination.id}>
                <StyledCardContainer
                  coverImage={cityDestination.city.image?.url}
                  linearShadow
                >
                  <Card.Content>
                    <CityDestination cityDestination={cityDestination} />
                  </Card.Content>
                </StyledCardContainer>
              </StyledCarouselItem>
            ))}
          </CarouselContent>
          <CarouselNext />
          <CarouselPrevious />
        </StyledCarousel>
      </ListWrapper>
    )
  })
