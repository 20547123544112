import {api} from '@api/index'
import {clientHeaders} from '@utils/http/headers'
import type {SubscribeToGeneralNewsletterMutationVariables} from '@api/gql/generate-types'
import {store} from '@store/index'
import {errorService} from '@services/common/error'
import {ENVIRONMENT} from '@/library/utils/guards/environment'
import {deleteCookie, getCookie, setCookie} from '@/library/utils/cookie'
import type {CEmailSubscriptionStore} from '@/library/store/email-subscription'

const WIDGET_SUCCESS_CLOSE_DELAY = 2000
const DEFAULT_SUBSCRIBED_PARSED_COOKIE_VALUE = 0
const DEFAULT_TIME_TO_DISPLAY_PARSED_COOKIE_VALUE = 0

export class EmailSubscriptionService {
  public get state(): CEmailSubscriptionStore {
    return store().emailSubscription
  }

  public readonly subscriptionTimeCookeyKey =
    ENVIRONMENT.SUBSCRIPTION_WIDGET_SHOW_TIME_COOKIE_KEY

  // Только для неавторизованных пользователей
  public readonly isSubscribedCookeyKey =
    ENVIRONMENT.GENERAL_NEWSLETTER_SUBSCRIBED_COOKIE_KEY

  public subscribeToNewsletter(
    variables: SubscribeToGeneralNewsletterMutationVariables,
  ) {
    this.state.setIsLoading(true)

    api.newsletter
      .subscribeToGeneral({
        headers: clientHeaders(),
        variables,
      })
      .then((response) => {
        if (response) {
          this.state.setIsSuccess(true)

          setTimeout(() => {
            this.state.setIsSubscribed(true)

            if (!store().user.isAuthorized) {
              this.setIsSubscribedToCookie(true)
            }
          }, WIDGET_SUCCESS_CLOSE_DELAY)
        }
      })
      .catch((error) => {
        const errorCategory = error.graphQLErrors?.[0]?.extensions?.category
        const errorText = errorService.getFirstErrorText(error)

        if (errorCategory === 'newsletter-subscriptions.already-exist') {
          this.state.setError(
            'features.subscription-newsletter:form.errors.subscription_already_exists',
          )
          return
        }

        this.state.setError(errorText)
      })
      .finally(() => this.state.setIsLoading(false))
  }

  public checkNewsletterSubscription() {
    this.clearIsSubscribedCookies()

    api.newsletter
      .getMyGeneralSubscriptions()
      .then((response) => {
        this.state.setIsSubscribed(Boolean(response))
      })
      .catch((error) => console.error(error))
  }

  public closeWidget(): void {
    if (this.state.isSubscribed) {
      this.clearTimeToDisplayWidgetFromCookies()

      return
    }

    this.setTimeToDisplayWidgetToCookie()

    this.state.setIsOpen(false)
  }

  public getTimeToDisplayWidgetFromCookies(): number {
    const timeFromCookies = getCookie(this.subscriptionTimeCookeyKey)

    return timeFromCookies
      ? Number(timeFromCookies)
      : DEFAULT_TIME_TO_DISPLAY_PARSED_COOKIE_VALUE
  }

  public setTimeToDisplayWidgetToCookie(): void {
    if (this.state.isSubscribed || this.getIsSubscribedFromCookies()) {
      return
    }

    const newSubscriptionTime =
      Date.now() + Number(ENVIRONMENT.TIME_TO_DISPLAY_SUBSCRIPTION_WIDGET)

    setCookie(this.subscriptionTimeCookeyKey, String(newSubscriptionTime))
  }

  public clearTimeToDisplayWidgetFromCookies(): void {
    deleteCookie(this.subscriptionTimeCookeyKey)

    this.state.setIsOpen(false)
  }

  public getIsSubscribedFromCookies(): boolean {
    const isSubscribedFromCookies = getCookie(this.isSubscribedCookeyKey)
    const parsedValueFromCookies = isSubscribedFromCookies
      ? Number(isSubscribedFromCookies)
      : DEFAULT_SUBSCRIBED_PARSED_COOKIE_VALUE

    return Boolean(parsedValueFromCookies)
  }

  public setIsSubscribedToCookie(value: boolean): void {
    const parsedValue = Number(value)

    setCookie(this.isSubscribedCookeyKey, String(parsedValue))
  }

  public clearIsSubscribedCookies(): void {
    deleteCookie(this.isSubscribedCookeyKey)
  }
}
