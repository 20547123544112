import styled from '@emotion/styled'
import useTranslation from 'next-translate/useTranslation'
import {Link} from '@ui/link'
import {css} from '@emotion/react'
import {useRouter} from 'next/router'
import {MEDIA_DEVICE_QUERY} from '@assets/theme/media'
import {useEffect, useMemo, useRef, useState} from 'react'
import {
  HOTEL_SCENARIO_FOOTER_ITEM,
  HotelScenarioFooterMenuId,
} from '@slices/global/ui/hotel-scenario/footer-bar/lib/constants'
import {TRANSITION_STYLES} from '@constants/css'
import {checkPolicies} from '@slices/global/ui/hotel-scenario/rules/ui/ostrovok-rules/lib/helpers'
import {observer} from 'mobx-react'
import {store} from '@store/index'

const Wrapper = styled.nav`
  display: flex;
  justify-content: space-between;
  gap: 40px;
  width: 100%;
  border-bottom: 1px solid ${({theme}) => theme.palette.borderTertiary};
  overflow-x: auto;
  height: 40px;

  @media ${MEDIA_DEVICE_QUERY.MOBILE_LARGE} {
    gap: 20px;
  }

  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`

const MenuItem = styled(Link, {
  shouldForwardProp: (properties) => properties !== '$isActive',
})<{$isActive: boolean}>`
  position: relative;
  min-width: fit-content;
  color: ${({theme}) => theme.palette.textSecondary};
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    padding: 0 17px 8px 17px;

    font-size: 14px;
    font-weight: 500;
  }

  @media ${MEDIA_DEVICE_QUERY.MOBILE_LARGE} {
    height: 100%;
    font-size: 16px;
  }

  ${({$isActive, theme}) =>
    $isActive &&
    css`
      color: ${theme.palette.textPrimary};
      border-bottom: 2px solid ${theme.palette.borderAccentPrimary};
    `}
  &::before {
    content: '';
    transition: opacity ${TRANSITION_STYLES};
    opacity: 0;
  }

  &:hover {
    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: ${({theme}) => theme.palette.backgroundAccentPrimary};
      bottom: 0;
      opacity: 1;
    }
  }
`

export const HotelScenarioFooterBar = observer(() => {
  const {t} = useTranslation('global.hotel')
  const router = useRouter()
  const activeRef = useRef<HTMLAnchorElement | null>(null)
  const [hash, setHash] = useState<string>('')
  const {data} = store().pages.global.hotel

  const ostrovokPoliciesChecks = useMemo(
    () => checkPolicies(data?.metaPolicies?.et),
    [data?.metaPolicies?.et],
  )

  const ostrovokPoliciesExist =
    data?.metaPolicies?.et &&
    ostrovokPoliciesChecks &&
    Object.values(ostrovokPoliciesChecks).some(Boolean)

  useEffect(() => {
    if (activeRef.current) {
      activeRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      })
    }
  }, [])

  useEffect(() => {
    setHash(router.asPath.split('#')[1])
  }, [router.asPath])

  return (
    <Wrapper>
      {HOTEL_SCENARIO_FOOTER_ITEM.map((item) => {
        const isActive = item.href === hash

        if (
          item.id === HotelScenarioFooterMenuId.RULES &&
          !data?.metaPolicies?.hoteliers &&
          !ostrovokPoliciesExist
        ) {
          return null
        }

        return (
          <MenuItem
            key={item.id}
            href={`#${item.href}`}
            $isActive={isActive}
            ref={isActive ? activeRef : undefined}
          >
            <span>{t(item.label)}</span>
          </MenuItem>
        )
      })}
    </Wrapper>
  )
})
