import styled from '@emotion/styled'
import {observer} from 'mobx-react'
import type {FC} from 'react'
import {Button, Text} from '@/library/components/ui'
import {PriceWithCurrency} from '@/library/components/ui/typography'
import {Input} from '@/library/components/ui/input'
import {store} from '@/library/store'
import {services} from '@/library/services'
import {FormProvider, useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import {balanceForm} from '@/library/constants/form-validation/balance-form'
import useTranslation from 'next-translate/useTranslation'
import {useIsRedirecting} from '@/library/hooks/use-redirecting'
import {ECurrency} from '@/types/enums/currency'
import {CurrencyWeight} from '@/library/components/ui/typography/price/lib/types'
import {calculateWriteOff} from './lib/helpers'

const Wrapper = styled.div``

const Title = styled(Text)`
  color: ${({theme}) => theme.palette.textSecondary};
`

const BalanceTable = styled.div`
  margin-top: 10px;
`

const TableItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
  border-bottom: 1px solid ${({theme}) => theme.palette.borderTertiary};

  &:first-of-type {
    border-top: 1px solid ${({theme}) => theme.palette.borderTertiary};
  }
`

const TableItemText = styled(Text)<{isBalance$?: boolean}>`
  color: ${({theme, isBalance$}) =>
    isBalance$ ? theme.palette.textPositive : theme.palette.textPrimary};
`

const TableItemPrice = styled(PriceWithCurrency)`
  font-size: 14px;
  line-height: 18px;
`

const BalanceInput = styled(Input)`
  margin-top: 24px;
`

const Apply = styled(Button)`
  width: 100%;
  text-transform: uppercase;
`

interface BalanceFormProps {
  className?: string
  onlineAmount: number
}

export const BalanceForm: FC<BalanceFormProps> = observer(
  ({className, onlineAmount}) => {
    const {t: yupT} = useTranslation()
    const {t} = useTranslation('global.hotel')
    const {user, isAuthorized} = store().user
    const {minimumBookingAmount, isLoading} = store().bookingModal.modal
    const {amount: balanceAmount, input: balanceInput} =
      store().bookingModal.balance
    const {isLoading: promoCodeIsLoading} = store().bookingModal.promoCode
    const isRedirecting = useIsRedirecting()

    const balance = user?.balance.amount

    const maximumWriteoff = calculateWriteOff(
      onlineAmount,
      minimumBookingAmount,
      balance,
    )

    const form = balanceForm(yupT, maximumWriteoff)

    const methods = useForm({
      resolver: yupResolver(form.validationSchema),
      defaultValues: form.initialState,
      mode: 'onChange',
    })

    const {
      register,
      formState: {errors, isValid},
      getValues,
    } = methods
    const formValues = getValues()

    if (!isAuthorized || !balance || !maximumWriteoff) {
      return null
    }

    const leftBalance = balanceAmount ? balance - balanceAmount : balance

    const onSubmit = (amount?: string | null) => {
      if (amount) {
        services.pages.global.scenario.hotel.booking.balance.submitAmount(
          amount,
        )
      }
    }

    return (
      <Wrapper className={className}>
        <Title size="XS">{t('booking_modal.pay_from_balance')}</Title>
        <BalanceTable>
          <TableItem>
            <TableItemText isBalance$ size="XS">
              {t('booking_modal.your_balance')}
            </TableItemText>
            <TableItemPrice
              price={leftBalance.toLocaleString()}
              currency={ECurrency.RUB}
              currencyWeight={CurrencyWeight.normal}
            />
          </TableItem>
          <TableItem>
            <TableItemText size="XS">
              {t('booking_modal.max_balance_writeoff')}
            </TableItemText>
            <TableItemPrice
              price={maximumWriteoff.toLocaleString()}
              currency={ECurrency.RUB}
              currencyWeight={CurrencyWeight.normal}
            />
          </TableItem>
        </BalanceTable>
        <FormProvider {...methods}>
          <BalanceInput
            disabled={Boolean(
              balanceAmount || promoCodeIsLoading || isLoading || isRedirecting,
            )}
            label={t('booking_modal.payment_sum')}
            type="text"
            placeholder={t('booking_modal.payment_sum')}
            error={errors.amount}
            {...register('amount')}
            onChange={(event) => {
              register('amount').onChange(event)
              services.pages.global.scenario.hotel.booking.balance.setInput(
                event,
              )
            }}
            value={balanceInput}
          />
          <Apply
            onClick={() => onSubmit(formValues.amount)}
            disabled={
              !(isValid && formValues.amount) ||
              isLoading ||
              isRedirecting ||
              promoCodeIsLoading
            }
            variant="secondary"
          >
            {balanceAmount
              ? t('booking_modal.cancel')
              : t('booking_modal.apply_sum')}
          </Apply>
        </FormProvider>
      </Wrapper>
    )
  },
)
