import {store} from '@store/index'
import type {CGlobalPageStoreSearchHotels} from '@store/pages/global/search/hotels'
import {api} from '@api/index'
import {DEFAULT_FIRST_PAGE_ITEMS} from '@constants/common'
import {GlobalPageSearchFetchQueryBuilder} from '@services/pages/global/scenario/search/fetch-query-builder'
import {SEARCH_SCENARIO_HOTELS_CARDS_ID} from '@constants/dom-ids'
import {scrollMainWrapper} from '@utils/dom/scroll'

export class GlobalPageSearchHotelsService {
  public get state(): CGlobalPageStoreSearchHotels {
    return store().pages.global.search.hotels
  }

  // eslint-disable-next-line no-useless-constructor
  constructor(
    private readonly fetchBuilder: GlobalPageSearchFetchQueryBuilder,
  ) {}

  public async load() {
    try {
      this.state.resetState()
      this.state.setLoading(true)
      const parameters = this.fetchBuilder.buildHotelRatesQueryParameters()

      const {hotelRates} = await api.hotel.get({
        variables: {
          page: this.state.page,
          first: this.state.first,
          params: parameters,
        },
      })

      if (
        hotelRates.data &&
        (hotelRates.hasFullData ||
          hotelRates.data.length >= DEFAULT_FIRST_PAGE_ITEMS)
      ) {
        this.state.setLoading(false)
        this.state.setIsLoadedHotels(true)
        this.state.setData(hotelRates.data)
        this.state.setPaginatorInfo(hotelRates.paginatorInfo)
      }

      return hotelRates
    } catch {
      this.state.resetState()
      this.state.setLoading(false)
      this.state.setIsLoadedHotels(true)
      return null
    }
  }

  public scrollToTop() {
    document
      .querySelector(`#${SEARCH_SCENARIO_HOTELS_CARDS_ID}`)
      ?.scrollTo({top: 0, behavior: 'smooth'})
    scrollMainWrapper()
  }
}
