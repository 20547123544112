import {Container} from '@components/layouts'
import styled from '@emotion/styled'
import {HotelScenarioMainInformation} from '@slices/global/ui/hotel-scenario/main-information'
import {HotelScenarioRates} from '@slices/global/ui/hotel-scenario/rates'
import {HotelScenarioAboutHotel} from '@slices/global/ui/hotel-scenario/about-hotel'
import {HotelScenarioRules} from '@slices/global/ui/hotel-scenario/rules'
import {HotelScenarioFacilities} from '@slices/global/ui/hotel-scenario/facilities'
import {HotelScenarioPlace} from '@slices/global/ui/hotel-scenario/place'
import {HotelScenarioFaq} from '@slices/global/ui/hotel-scenario/faq'
import {
  HOTEL_SCENARIO_ABOUT_HOTEL_ID,
  HOTEL_SCENARIO_FACILITIES_ID,
  HOTEL_SCENARIO_FAQ_ID,
  HOTEL_SCENARIO_MAIN_INFORMATION_ID,
  HOTEL_SCENARIO_PLACE_ID,
  HOTEL_SCENARIO_RATES_ID,
  HOTEL_SCENARIO_REVIEWS_ID,
  HOTEL_SCENARIO_RULES_ID,
  HOTEL_SCENARIO_SIMILAR_HOTELS_ID,
} from '@constants/dom-ids'
import {HotelScenarioReviews} from '@slices/global/ui/hotel-scenario/reviews'
import {HotelScenarioFooterBar} from 'slices/global/ui/hotel-scenario/footer-bar'
import {MEDIA_DEVICE_QUERY} from '@assets/theme/media'
import {GlobalPageBreadcrumbs} from '@slices/global/ui/breadcrumbs'
import {useEffect} from 'react'
import {services} from '@services/index'
import {AnalyticsBookingStatuses} from '@api/gql/generate-types'
import {getPathname} from '@utils/router/get-pathname'
import {useRouter} from 'next/router'
import {store} from '@store/index'
import {useDeviceDetection} from '@/library/hooks/use-device-detection'
import {SimilarHotels} from './similar-hotels'
import {SeoHotel} from './seo/seo-hotel'
import HotelScenarioComeBack from './hotel-scenario-come-back'

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 58px;

  section {
    scroll-margin-top: 100px;
  }
`

const BreadcrumbsWrapper = styled.div`
  @media ${MEDIA_DEVICE_QUERY.MOBILE_LARGE} {
    margin-top: 10px;
  }
`

const MainInformationWrapper = styled.section``

const RatesWrapper = styled.section``

const AboutHotelWrapper = styled.section``

const ReviewsWrapper = styled.section``

const RulesWrapper = styled.section``

const FacilitiesWrapper = styled.section``

const PlaceWrapper = styled.section``

const SimilarHotelsWrapper = styled.section``

const FaqWrapper = styled.section``

export const HotelScenario = () => {
  const router = useRouter()
  const isMobile = useDeviceDetection('mobile')

  useEffect(() => {
    services.common.analytics.pushBookingStatus(
      AnalyticsBookingStatuses.ViewHotel,
      getPathname(router),
    )
    store().pages.global.hotel.rates.filters.reset()
  }, [router.asPath])

  return (
    <StyledContainer $maxWidth={1050}>
      <SeoHotel />
      <BreadcrumbsWrapper>
        <GlobalPageBreadcrumbs />
        {isMobile && <HotelScenarioComeBack />}
      </BreadcrumbsWrapper>
      <MainInformationWrapper id={HOTEL_SCENARIO_MAIN_INFORMATION_ID}>
        <HotelScenarioMainInformation />
      </MainInformationWrapper>
      <HotelScenarioFooterBar />
      <RatesWrapper id={HOTEL_SCENARIO_RATES_ID}>
        <HotelScenarioRates />
      </RatesWrapper>
      <AboutHotelWrapper id={HOTEL_SCENARIO_ABOUT_HOTEL_ID}>
        <HotelScenarioAboutHotel />
      </AboutHotelWrapper>
      <FacilitiesWrapper id={HOTEL_SCENARIO_FACILITIES_ID}>
        <HotelScenarioFacilities />
      </FacilitiesWrapper>
      <RulesWrapper id={HOTEL_SCENARIO_RULES_ID}>
        <HotelScenarioRules />
      </RulesWrapper>
      <ReviewsWrapper id={HOTEL_SCENARIO_REVIEWS_ID}>
        <HotelScenarioReviews />
      </ReviewsWrapper>
      <PlaceWrapper id={HOTEL_SCENARIO_PLACE_ID}>
        <HotelScenarioPlace />
      </PlaceWrapper>
      <FaqWrapper id={HOTEL_SCENARIO_FAQ_ID}>
        <HotelScenarioFaq />
      </FaqWrapper>
      <SimilarHotelsWrapper id={HOTEL_SCENARIO_SIMILAR_HOTELS_ID}>
        <SimilarHotels />
      </SimilarHotelsWrapper>
    </StyledContainer>
  )
}
