import {
  DestinationAutocompleteTypeEnum,
  type GetDestinationAutoCompleteQueryVariables,
  type SubscribeToCityNewsletterMutationVariables,
} from '@api/gql/generate-types'
import {store} from '@store/index'
import {GlobalHydrateService} from '@services/common/global-hydrate'
import {api} from '@/library/api'
import type {SubscriptionPageProperties} from '@slices/subscription/lib/types'
import {errorService} from '../../common/error'

type HydrationInitialData =
  Required<SubscriptionPageProperties>['hydrationData']

export class SubscriptionPageService extends GlobalHydrateService<HydrationInitialData> {
  private get store() {
    return store().pages.subscription
  }

  public isValidDestination(): boolean {
    const isExistPlaceAndDestinationValue = Boolean(
      this.store.selectPlace && this.store.inputValue,
    )
    const isSearchValueEqualPlace =
      this.store.inputValue === this.store.selectPlace?.name

    return isExistPlaceAndDestinationValue && isSearchValueEqualPlace
  }

  public async startSearchDestinations({
    query,
    disableOpen,
  }: {
    query: GetDestinationAutoCompleteQueryVariables['query']
    disableOpen?: boolean
  }) {
    this.store.setIsLoading(true)

    api.destination
      .getDestinationAutoComplete({
        variables: {
          query,
          types: [DestinationAutocompleteTypeEnum.City],
        },
      })
      .then((result) => {
        if (result) {
          this.store.setData(result)

          if (!disableOpen) {
            this.store.setIsOpen(true)
          }
        }
      })
      .catch(() => {
        this.store.setError('yup:went_wrong')
      })
      .finally(() => {
        this.store.setIsLoading(false)
      })
  }

  public subscribeToCityNewsletter(
    variables: SubscribeToCityNewsletterMutationVariables,
  ) {
    this.store.setIsLoading(true)

    api.newsletter
      .subscribeToCity({
        variables,
      })
      .then((response) => {
        if (response) {
          this.store.setIsSuccess(true)
        }
      })
      .catch((error) => {
        const errorCategory = error.graphQLErrors?.[0]?.extensions?.category
        const errorText = errorService.getFirstErrorText(error)

        if (errorCategory === 'newsletter-subscriptions.already-exist') {
          this.store.setError(
            'subscription:form.errors.subscription_already_exists',
          )
          return
        }

        this.store.setError(errorText)
      })
      .finally(() => this.store.setIsLoading(false))
  }

  public hydrate(initialData: HydrationInitialData) {
    if (initialData.popularCityDestinations) {
      store().popularCityDestinations.setPopularCityDestinations(
        initialData.popularCityDestinations,
      )
    }

    super.hydrate(initialData)
  }
}
