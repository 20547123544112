import styled from '@emotion/styled'
import {Text} from '@ui/index'
import {store} from '@store/index'
import {observer} from 'mobx-react'
import {useLocalObservable} from 'mobx-react-lite'
import {selectors} from '@components/features/search-input/store'
import useTranslation from 'next-translate/useTranslation'
import {type FC, type MouseEvent, useEffect, useRef} from 'react'
import {MEDIA_DEVICE_QUERY} from '@assets/theme/media'
import {TRANSITION_STYLES} from '@/library/constants/css'
import {Icon} from '@/library/components/ui/icon'
import {useDebounce} from '@/library/hooks/use-debounce'
import {services} from '@/library/services'
import {ProviderRatesSearchingTypeEnum} from '@/library/api/gql/generate-types'
import {SearchInputDestinationInput} from './input'
import {SearchInputDestinationPopover} from './popover'
import {MS_TO_SEARCH_INPUT_ANIMATION_END} from '../../lib/constants'

const DestinationWrapper = styled.div`
  height: 100%;
  width: 234px;

  @media ${MEDIA_DEVICE_QUERY.LAPTOP_LARGE} {
    min-width: fit-content;
  }
`

const DestinationButton = styled.div<{
  $isOpen?: boolean
  $isActiveInput: boolean
}>`
  border-radius: 100px;
  min-width: fit-content;
  height: 100%;
  background-color: ${({theme, $isOpen}) =>
    $isOpen ? theme.palette.backgroundQuaternary : 'transparent'};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 24px;

  transition: 0.3s background-color ease-in-out;

  &:active {
    background-color: transparent;
  }

  &:hover {
    background-color: ${({theme, $isActiveInput}) =>
      $isActiveInput
        ? theme.palette.backgroundQuaternary
        : theme.palette.backgroundTertiary};
  }
`

const DestinationInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
`

const DestinationTitle = styled(Text)`
  margin-bottom: 2px;
  color: ${({theme}) => theme.palette.textSecondary};
  font-weight: 500;
  @media ${MEDIA_DEVICE_QUERY.LAPTOP_LARGE} {
    font-size: 0.875rem;
  }
`

export const ClearSearchButton = styled.span`
  min-width: 20px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-left: 2px;
  cursor: pointer;
  background-color: ${({theme}) => theme.palette.backgroundTertiary};
  transition: background-color ${TRANSITION_STYLES};

  svg {
    transition: color ${TRANSITION_STYLES};
  }

  &:hover {
    background-color: ${({theme}) => theme.palette.backgroundAccentPrimary};

    svg {
      color: ${({theme}) => theme.palette.textQuaternary};
    }
  }
`

const CLEAR_STATES_SEARCHING_TYPES = new Set([
  ProviderRatesSearchingTypeEnum.BestOffers,
  ProviderRatesSearchingTypeEnum.Promotion,
])

interface IProps {
  withPopover?: boolean
}

export const SearchInputDestination: FC<IProps> = observer(
  ({withPopover = true}) => {
    const inputRef = useRef<HTMLInputElement>(null)

    const {t} = useTranslation('features.search-input')
    const {isActiveInput} = useLocalObservable(
      selectors.selectIsActiveSearchInput,
    )
    const {searchingType} = useLocalObservable(selectors.selectSearchingType)
    const {inputValue} = useLocalObservable(selectors.selectDestinationValue)
    const {isOpen} = useLocalObservable(selectors.selectDestinationIsOpen)
    const isBestOffers = services.pages.global.scenario.isSearchTypeEqualValue(
      searchingType,
      ProviderRatesSearchingTypeEnum.BestOffers,
    )

    const handleOpen = () => {
      if (document.activeElement !== inputRef.current) {
        store().search.destination.setIsOpen(!isOpen)
      }
    }

    const startSearchDestinations = useDebounce<string>((parameters) => {
      store().search.destination.setError('')
      services.components.search.destinationService.startSearchDestinations(
        parameters,
      )
    })

    const handleOnClear = (event: MouseEvent<HTMLDivElement>) => {
      event.stopPropagation()

      store().search.destination.setInputValue('')
      store().search.destination.setSelectPlace(null)
      store().pages.global.setSearchingType(ProviderRatesSearchingTypeEnum.Geo)
      startSearchDestinations('')
    }

    useEffect(() => {
      setTimeout(() => {
        if (inputRef.current && isOpen) {
          inputRef.current.focus()
        }
      }, MS_TO_SEARCH_INPUT_ANIMATION_END)
    }, [isOpen])

    return (
      <DestinationWrapper>
        <DestinationButton
          $isOpen={isOpen}
          $isActiveInput={isActiveInput}
          onClick={handleOpen}
        >
          <DestinationInputWrapper>
            <DestinationTitle type="span" size="S">
              {t('destination.title')}
            </DestinationTitle>
            <SearchInputDestinationInput
              onFocus={(event) => {
                store().search.destination.setIsOpen(true)

                // сетит ввод текста в конец инпута
                event.currentTarget.setSelectionRange(
                  event.currentTarget.value.length,
                  event.currentTarget.value.length,
                )
              }}
              ref={inputRef}
            />
          </DestinationInputWrapper>
          {(Boolean(inputValue) ||
            CLEAR_STATES_SEARCHING_TYPES.has(searchingType)) && (
            <ClearSearchButton onClick={handleOnClear}>
              <Icon name="close" width={12} height={12} />
            </ClearSearchButton>
          )}
        </DestinationButton>
        {withPopover && isOpen && !isBestOffers && (
          <SearchInputDestinationPopover />
        )}
      </DestinationWrapper>
    )
  },
)
