import type {GlobalScenario} from '@services/pages/global/scenario'
import {HotelScenario} from '@slices/global/ui/hotel-scenario'
import {SearchScenario} from '@slices/global/ui/search-scenario'
import {StaticScenario} from '@slices/global/ui/static-scenario/static-scenario'
import type {FC} from 'react'

export const SCENARIO_COMPONENTS: Record<GlobalScenario, FC> = {
  hotel: HotelScenario,
  search: SearchScenario,
  static: StaticScenario,
}
