import {range} from 'ramda'
import {store} from '@store/index'
import styled from '@emotion/styled'
import {Select} from '@ui/select'
import useTranslation from 'next-translate/useTranslation'
import {observer} from 'mobx-react'

const StyledSelect = styled(Select)`
  margin-top: 24px;

  .input-label {
    font-size: 0.875rem;
  }
`

export const ChildrenSelect = observer(() => {
  const {t} = useTranslation('features.search-input')
  const {children} = store().search.guests
  const {maxChildrenAge, maxChildren} = children

  return (
    <StyledSelect
      disabled={children.children.length >= maxChildren}
      options={range(0, maxChildrenAge + 1).map((age) => ({
        value: age,
        label: t('guests.children.age', {count: age}),
      }))}
      setValue={(option) => children.addChild(Number(option.value))}
      value={{
        value: 'choice_children',
        label: t('guests.children.add_children'),
      }}
    />
  )
})
