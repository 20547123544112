import {type CSSProperties, type FC} from 'react'
import {type ModalProps} from 'antd/lib/modal'
import dynamic from 'next/dynamic'
import styled from '@emotion/styled'
import {LOADING_STYLES, TRANSITION_STYLES} from '@/library/constants/css'

import {css} from '@emotion/react'
import {isNumber} from '@/library/utils/guards/types'
import {Icon} from '../icon'
import {CircularLoader} from '../loader'

const LibraryModal = dynamic(() => import('antd/lib/modal'))

const StyledLibraryModal = styled(LibraryModal)<{
  width?: CSSProperties['width']
}>`
  font-family: ${({theme}) => theme.font.main};

  .ant-modal-content {
    padding: 0;
  }

  ${({width}) =>
    width &&
    css`
      width: ${isNumber(width) ? `${width}px` : width} !important;
    `}
`

const ModalContentWrapper = styled.div<{$isLoading: boolean}>`
  position: relative;
  padding: 20px 24px;

  ${({$isLoading}) =>
    $isLoading &&
    css`
      ${LOADING_STYLES}
    `}
`

const StyledIcon = styled(Icon)`
  transition: color ${TRANSITION_STYLES};

  &:hover {
    color: ${({theme}) => theme.palette.textAccentPrimary};
  }
`

const StyledCircucalLoader = styled(CircularLoader)`
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
`

interface IModalProps extends ModalProps {
  isLoading?: boolean
  width?: CSSProperties['width']
}

export const Modal: FC<IModalProps> = ({
  children,
  isLoading = false,
  className,
  width,
  ...properties
}) => {
  return (
    <StyledLibraryModal
      centered
      footer={null}
      destroyOnClose
      closable={!isLoading}
      maskClosable={!isLoading}
      closeIcon={<StyledIcon name="close" width={24} height={24} />}
      width={width}
      {...properties}
    >
      <ModalContentWrapper $isLoading={isLoading} className={className}>
        {isLoading && <StyledCircucalLoader size={32} />}
        {children}
      </ModalContentWrapper>
    </StyledLibraryModal>
  )
}
