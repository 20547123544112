import {GlobalHydrateService} from '@services/common/global-hydrate'
import type {ApplicationProperties} from '@/types/ui'

type HydrationInitialData = ApplicationProperties

export class SupportService extends GlobalHydrateService<HydrationInitialData> {
  public hydrate(initialData: HydrationInitialData) {
    super.hydrate(initialData)
  }
}
