import {type ComponentProps, forwardRef} from 'react'
import {SVG_ICONS} from '@ui/icon/lib/constants'
import {throwComponentException} from '@utils/exceptions/components'
import styled from '@emotion/styled'
import {useTheme} from '@emotion/react'

const IconWrapper = styled.span`
  display: inline-flex;
`

export type IconName = keyof typeof SVG_ICONS

interface IconProperties extends Omit<ComponentProps<'svg'>, 'name'> {
  name: IconName
  width: string | number
  height: string | number
  color?: string
}

export const Icon = forwardRef<HTMLDivElement, IconProperties>(
  ({name, color, ...properties}, ref) => {
    const theme = useTheme()

    if (!(name in SVG_ICONS)) {
      throwComponentException(
        `Icon ${name.toString()} does not exist in the icons/assets directory.`,
      )
    }

    const Icon = SVG_ICONS[name]

    return (
      <IconWrapper
        ref={ref}
        style={{
          color: color || theme.palette.backgroundSecondary,
        }}
      >
        <Icon {...properties} />
      </IconWrapper>
    )
  },
)

Icon.displayName = 'Icon'
