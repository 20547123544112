import type {FC} from 'react'
import useTranslation from 'next-translate/useTranslation'
import Trans from 'next-translate/Trans'
import {
  EtInclusion,
  EtMetapolicyCheckInCheckOutType,
} from '@/library/api/gql/generate-types'
import type {OstrovokCheckinCheckout} from '../lib/types'
import {getCheckinCheckoutType, getInclusion} from '../lib/helpers'
import {InlineAmount, InlineCurrency, InlineText} from '../../typography'
import {getCurrency} from '../../../lib/helpers'

interface CheckinCheckoutItemProps {
  checkinCheckout: OstrovokCheckinCheckout
}

export const CheckinCheckoutItem: FC<CheckinCheckoutItemProps> = ({
  checkinCheckout,
}) => {
  const {t} = useTranslation('global.hotel')
  const {t: currenciesT} = useTranslation('currencies')
  const isInclusionSpecified =
    checkinCheckout.inclusion &&
    checkinCheckout.inclusion !== EtInclusion.Unspecified

  if (
    !isInclusionSpecified &&
    checkinCheckout.price &&
    checkinCheckout.checkInCheckOutType
  ) {
    return (
      <Trans
        i18nKey="rules.et.checkin_checkout_with_price"
        ns="global.hotel"
        components={[
          <InlineAmount
            key={`checkin_checkout_${checkinCheckout.checkInCheckOutType}_amount`}
          />,
          <InlineCurrency
            key={`checkin_checkout_${checkinCheckout.checkInCheckOutType}_currency`}
          />,
        ]}
        values={{
          name: getCheckinCheckoutType(t, checkinCheckout.checkInCheckOutType),
          price: checkinCheckout.price?.toLocaleString(),
          currency: getCurrency(currenciesT, checkinCheckout.currency),
        }}
      />
    )
  }

  if (checkinCheckout.price && checkinCheckout.checkInCheckOutType) {
    return (
      <Trans
        i18nKey="rules.et.checkin_checkout_with_price_and_inclusion"
        ns="global.hotel"
        components={[
          <InlineAmount
            key={`checkin_checkout_${checkinCheckout.checkInCheckOutType}_amount`}
          />,
          <InlineCurrency
            key={`checkin_checkout_${checkinCheckout.checkInCheckOutType}_currency`}
          />,
          <InlineText
            key={`checkin_checkout_${checkinCheckout.checkInCheckOutType}_inclusion`}
          />,
        ]}
        values={{
          name: getCheckinCheckoutType(t, checkinCheckout.checkInCheckOutType),
          price: checkinCheckout.price?.toLocaleString(),
          currency: getCurrency(currenciesT, checkinCheckout.currency),
          inclusion: getInclusion(
            t,
            checkinCheckout.inclusion || EtInclusion.Unspecified,
          ),
        }}
      />
    )
  }

  return (
    <Trans
      i18nKey="rules.et.checkin_checkout_with_inclusion"
      ns="global.hotel"
      components={[
        <InlineText
          key={`checkin_checkout_${checkinCheckout.checkInCheckOutType}_inclusion`}
        />,
      ]}
      values={{
        name: getCheckinCheckoutType(
          t,
          checkinCheckout.checkInCheckOutType ||
            EtMetapolicyCheckInCheckOutType.Unspecified,
        ),
        inclusion: getInclusion(
          t,
          checkinCheckout.inclusion || EtInclusion.Unspecified,
        ),
      }}
    />
  )
}
