import {destinationApi as destination} from '@api/models/destination'
import {hotelsApi as hotel} from '@api/models/hotels'
import {authorizationApi as authorization} from '@api/models/authorization'
import {mapApi as map} from '@api/models/map'
import {staticPageApi as staticPage} from '@api/models/static-page'
import {userApi as user} from '@api/models/user'
import {affiliateApi as affiliate} from '@api/models/affiliate'
import {promotionApi as promotion} from '@api/models/promotion'
import {newsletterApi as newsletter} from '@api/models/newsletter'
import {bookingApi as booking} from '@api/models/booking'
import {analyticsApi as analytics} from '@api/models/analytics'
import {paymentApi as payment} from '@api/models/payment'
import {admitAdApi as admitAd} from '@api/models/admit-ad'
import {partnerApi as partner} from '@api/models/partner'

export const api = {
  destination,
  hotel,
  authorization,
  map,
  promotion,
  staticPage,
  user,
  affiliate,
  newsletter,
  booking,
  analytics,
  payment,
  admitAd,
  partner,
}
