export const parseURLSearchParametersToObject = (
  parameters: URLSearchParams,
) => {
  const objectParameters: Record<string, string | string[] | undefined> = {}

  const entries = Object.fromEntries(parameters)
  const keys = Object.keys(entries)

  keys.forEach((key) => {
    objectParameters[key] =
      parameters.getAll(key).length > 1
        ? parameters.getAll(key)
        : parameters.get(key) || undefined
  })

  return objectParameters
}
