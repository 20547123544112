import {bookingFormSchema} from '@/library/constants/form-validation/booking-modal'
import {services} from '@/library/services'
import {store} from '@/library/store'
import {yupResolver} from '@hookform/resolvers/yup'
import {observer} from 'mobx-react'
import {useEffect, useMemo, type FC, type PropsWithChildren} from 'react'
import {useForm, FormProvider} from 'react-hook-form'

export const BookingFormProvider: FC<PropsWithChildren> = observer(
  ({children}) => {
    const {guests} = store().search

    const initialSearchState = useMemo(
      () => ({
        adults: guests.adults.amount,
        children: guests.children.children,
      }),
      [],
    )

    const methods = useForm({
      resolver: yupResolver(bookingFormSchema),
      defaultValues: {
        guests:
          services.pages.global.scenario.hotel.booking.guestBuilder.buildForm(
            initialSearchState.adults,
            initialSearchState.children,
          ),
      },
      reValidateMode: 'onChange',
      mode: 'onChange',
    })

    const {trigger} = methods

    useEffect(() => {
      trigger()
    }, [])

    return (
      <FormProvider {...methods}>
        <form>{children}</form>
      </FormProvider>
    )
  },
)
