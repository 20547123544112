import {GlobalHydrateService} from '@services/common/global-hydrate'
import {api} from '@api/index'
import {store} from '@store/index'
import {errorService} from '@services/common/error'
import {CookieNames, deleteCookie, setCookie} from '@utils/cookie'

export class AffiliateMainService extends GlobalHydrateService<any> {
  private get state() {
    return store().pages.affiliate.main
  }

  public hydrate(initialData: any) {
    super.hydrate(initialData)
  }

  public joinToAffiliate() {
    this.state.setIsLoading(true)

    api.affiliate
      .join({})
      .then((result) => {
        if (result) {
          store().user.setUser(result)
          setCookie(CookieNames.AUTHORIZATION, result.api_token)
          deleteCookie(CookieNames.TEMPORARY_AUTHORIZATION)
        }
      })
      .catch((error) => {
        const firstError = errorService.getFirstError(error)
        if (firstError?.message) {
          this.state.setError(firstError?.message)
        }
      })
      .finally(() => this.state.setIsLoading(false))
  }
}
