import {observer} from 'mobx-react'
import {store} from '@store/index'
import {useLocalObservable} from 'mobx-react-lite'
import {Checkbox} from '@ui/checkbox'
import styled from '@emotion/styled'
import {Text} from '@components/ui'
import useTranslation from 'next-translate/useTranslation'
import {Skeleton} from 'antd'
import {Icon} from '@ui/icon'
import type {MouseEvent} from 'react'
import {SEARCH_SCENARIO_FILTERS_ID} from '@constants/dom-ids'
import {scrollParentToChild} from '@utils/dom/scroll'

const RatingReviewsGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-bottom: 1px solid ${({theme}) => theme.palette.borderTertiary};
  padding-bottom: 20px;
`

const StyledSkeleton = styled(Skeleton)`
  border-bottom: 1px solid ${({theme}) => theme.palette.borderTertiary};
  padding-bottom: 20px;
`

const NoExistStars = styled(Text)``

const Star = styled(Icon)`
  color: ${({theme}) => theme.palette.backgroundYellow};
`

const HOTELS_BY_STAR_GROUP_FROM_VALUE = 5

const SkeletonLoader = () => {
  return (
    <StyledSkeleton
      active
      paragraph={{rows: HOTELS_BY_STAR_GROUP_FROM_VALUE}}
    />
  )
}

const select = () => ({
  get countHotelsGroupedByStars() {
    return store().pages.global.search.filters.countHotelsGroupedByStars
  },
  get selectStars() {
    return store().pages.global.search.filters.selectStars
  },
  get loading() {
    return store().pages.global.search.filters.loading
  },
})

export const SearchScenarioFiltersStarsCount = observer(() => {
  const {t} = useTranslation('global.search')

  const {countHotelsGroupedByStars, selectStars, loading} =
    useLocalObservable(select)

  const handleClick = (
    value: boolean,
    stars: number,
    event: MouseEvent<HTMLDivElement>,
  ) => {
    const {filters} = store().pages.global.search

    if (value) {
      filters.addSelectStars(stars)
    } else {
      filters.removeSelectStars(stars)
    }

    const parent = document.querySelector<HTMLDivElement>(
      `#${SEARCH_SCENARIO_FILTERS_ID}`,
    )
    if (parent) {
      scrollParentToChild(parent, event.currentTarget)
    }
  }

  if (loading) {
    return <SkeletonLoader />
  }

  if (!countHotelsGroupedByStars) {
    return null
  }

  return (
    <RatingReviewsGroupWrapper>
      <Text size="S">{t('filters.count_stars.title')}</Text>
      {Object.entries(countHotelsGroupedByStars)?.map(
        ([id, countHotelsByStars]) => (
          <Checkbox
            key={id}
            value={selectStars.includes(countHotelsByStars.stars)}
            setValue={(value, event) =>
              handleClick(value, Number(countHotelsByStars.stars), event)
            }
            count={Number(countHotelsByStars.hotels)}
          >
            {Array.from({length: countHotelsByStars.stars}).map((_, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Star key={`star-${index}`} name="star" width={20} height={20} />
            ))}
            {countHotelsByStars.stars === 1 && (
              <NoExistStars size="S">
                {t('filters.count_stars.no_exist_stars')}
              </NoExistStars>
            )}
          </Checkbox>
        ),
      )}
    </RatingReviewsGroupWrapper>
  )
})
