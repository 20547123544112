import type {GetServerSideProps} from 'next'
import {andThen, call, pipeWith} from 'ramda'
import {checkUser} from '@/library/utils/api/user-authorization'
import {serverHeaders} from '@/library/utils/http/headers'
import type {ApplicationProperties} from '@/types/ui'
import {redirectScenario} from '@utils/next'
import {Routes} from '@/types/enums/routes'

export const getProfileSettingsProperties: GetServerSideProps = async ({
  req,
}) => {
  const headers = serverHeaders(req.headers, req.cookies)

  return call(
    pipeWith(andThen, [
      () => checkUser(headers),
      async (properties: ApplicationProperties) => {
        try {
          if (!properties.user) {
            return redirectScenario(Routes.HOME)
          }

          return {
            props: {
              hydrationData: {
                ...properties,
              },
            },
          }
        } catch {
          return redirectScenario(Routes.HOME)
        }
      },
    ]),
  )
}
