import {GlobalPageSearchFiltersService} from '@services/pages/global/scenario/search/filters'
import {GlobalPageSearchHotelsService} from '@services/pages/global/scenario/search/hotels'
import {GlobalPageSearchMapService} from '@services/pages/global/scenario/search/map'
import {GlobalPageSearchPromotionService} from '@services/pages/global/scenario/search/promotion'
import {GlobalPageSearchUrlQueryParser} from '@services/pages/global/scenario/search/url-query-parser'
import {GlobalPageSearchFetchQueryBuilder} from '@services/pages/global/scenario/search/fetch-query-builder'
import {GlobalPageMapPollingPointsService} from '@services/pages/global/polling-points'

export class GlobalPageSearchService {
  private readonly fetchQueryBuilder: GlobalPageSearchFetchQueryBuilder =
    new GlobalPageSearchFetchQueryBuilder()

  public readonly hotels: GlobalPageSearchHotelsService =
    new GlobalPageSearchHotelsService(this.fetchQueryBuilder)

  public readonly filters: GlobalPageSearchFiltersService =
    new GlobalPageSearchFiltersService(this.fetchQueryBuilder)

  public readonly map: GlobalPageSearchMapService =
    new GlobalPageSearchMapService(this.fetchQueryBuilder)

  public readonly promotion: GlobalPageSearchPromotionService =
    new GlobalPageSearchPromotionService()

  public readonly queryParser: GlobalPageSearchUrlQueryParser =
    new GlobalPageSearchUrlQueryParser()

  private readonly polling: GlobalPageMapPollingPointsService =
    new GlobalPageMapPollingPointsService(
      this.map,
      this.fetchQueryBuilder,
      this.onFinish.bind(this),
    )

  public load() {
    this.hotels.load().then((result) => {
      if (result?.hasFullData && !this.filters.state.hotelTypes?.length) {
        this.filters.load().finally(() => {
          this.filters.state.setLoading(false)
        })
      }
    })
    this.map.load().then((result) => {
      if (result) {
        this.polling.start(result.searchId)
      }
    })
    this.filters.load()
  }

  private async onFinish() {
    const mapState = this.map.state
    const hotelsState = this.hotels.state
    const filtersState = this.filters.state

    if (!hotelsState.data?.length && !hotelsState.isLoadedHotels) {
      await this.hotels.load()
      await this.filters.load()
    }

    if (!filtersState.hotelTypes?.length && hotelsState.isLoadedHotels) {
      await this.filters.load()
    }

    mapState.setLoading(false)
    hotelsState.setLoading(false)
    hotelsState.setIsLoadedHotels(true)
    filtersState.setLoading(false)
  }
}
