import {CancellationPolicies} from '@/library/components/features/cancellation-policies'
import {Button, Link, Text} from '@/library/components/ui'
import {Icon} from '@/library/components/ui/icon'
import {ImageWithSkeleton} from '@/library/components/ui/image-with-skeleton'
import {NO_HOTEL_IMAGE_SOURCE} from '@/library/constants/hotel'
import styled from '@emotion/styled'
import useTranslation from 'next-translate/useTranslation'
import {type FC} from 'react'
import {useTheme} from '@emotion/react'
import {Tooltip} from '@/library/components/ui/tooltip'
import {getBookingId} from '@/types/enums/routes'
import {getCurrency} from '@/library/utils/currency/index'
import {BookingStatusBadge} from '@/library/components/features/booking-status-badge'
import {store} from '@store/index'
import {CallYaTaxiAnalyzingEventEnum} from '@/library/api/gql/generate-types'
import {LinkForCallTaxi} from '@/library/components/features'
import {AmountsTable} from './amounts-table'
import {PaymentStatus} from './payment-status'
import {CheckInTable} from './check-in-table'
import {BookingNumber} from './booking-number'
import {PaymentLinkCopy} from './payment-link-copy'
import {CancelButton} from './cancel-button'
import {BookingTimer} from './booking-timer'
import {isRealBooking} from '../lib/helpers'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 49px 20px 25px 20px;
  gap: 40px;
  border-radius: 12px;
  align-items: stretch;
  box-shadow: ${({theme}) => theme.palette.boxShadowPrimary} 0px 4px 20px;
`

const BookingInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
  flex: 1;
`
const BookingActions = styled.div`
  display: flex;
  flex-direction: column;
`

const TitleWrapper = styled.div`
  margin-bottom: 20px;
  max-width: 500px;
`

const Title = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${({theme}) => theme.palette.textPrimary};
`

const Address = styled(Text)`
  max-width: 432px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
const AddressIcon = styled(Icon)`
  color: ${({theme}) => theme.palette.backgroundAccentPrimary};
`
const AddressText = styled.span`
  margin-left: 4px;
  color: ${({theme}) => theme.palette.backgroundAccentPrimary};
`

const StyledLinkForCallTaxi = styled(LinkForCallTaxi)`
  margin-top: 2px;
`

const StyledPaymentStatus = styled(PaymentStatus)`
  text-align: end;
`

const BookingImageWrapper = styled.div`
  position: relative;
  width: 240px;
  height: 240px;
  max-height: 240px;
  margin-top: 10px;
`

const BookingImage = styled(ImageWithSkeleton)`
  .image {
    border-radius: 12px;
    object-fit: cover;
    object-position: center;
  }
`

const InfoWrapper = styled.div``

const StyledCancellationPolicies = styled(CancellationPolicies)`
  margin-top: 20px;
`

const StyledAmountsTable = styled(AmountsTable)`
  margin-top: 20px;
`

const DetailsButton = styled(Button)`
  margin-top: 12px;
  text-transform: uppercase;
  width: 100%;
`

const HotelButtonLink = styled(Link)`
  position: absolute;
  top: 8px;
  z-index: 2;
  width: 100%;
`

const HotelButtonIcon = styled(Icon)`
  margin-right: 4px;
  color: ${({theme}) => theme.palette.textQuaternary} !important;
`

const HotelButton = styled(Button)`
  text-transform: uppercase;
  margin: 0 8px;
  width: calc(100% - 16px);
`

const StyledCancelButton = styled(CancelButton)`
  margin-top: 12px;
  text-transform: uppercase;
  width: 100%;
`

const StyledBookingTimer = styled(BookingTimer)`
  margin-top: 12px;
`

const PaymentButton = styled(Button)`
  text-transform: uppercase;
  width: 100%;
  margin-top: 12px;
`

const StyledBookingStatusBadge = styled(BookingStatusBadge)`
  margin-top: 10px;
`

interface BookingProps {
  booking: UserBooking
  showCancelButton: boolean
  showPayment: boolean
  checkIn: string
  checkOut: string
  arrivalTime?: string
}

export const DesktopBooking: FC<BookingProps> = ({
  booking,
  showCancelButton,
  showPayment,
  checkIn,
  checkOut,
  arrivalTime,
}) => {
  const {t} = useTranslation('profile.bookings')
  const {t: currenciesT} = useTranslation('currencies')
  const theme = useTheme()

  const handleOpenBookingPaymentModal = () => {
    store().bookingPaymentModal.setBookingId(booking.id)
    if (booking.hotel.seoUrl?.url) {
      store().bookingPaymentModal.setBookingHotelUrl(booking.hotel.seoUrl.url)
    }
    store().bookingPaymentModal.setBookingAccessToken(booking.access_token)
    store().bookingPaymentModal.setIsOpen(true)
  }

  return (
    <StyledBookingStatusBadge isRibbon status={booking.status}>
      <Wrapper>
        <BookingInfo>
          <TitleWrapper>
            <Title size="boldM">
              <Tooltip trigger="hover" title={booking.hotel.name}>
                {booking.hotel.stars ? (
                  <span>
                    {booking.hotel.stars}
                    <sup>
                      <Icon
                        name="star"
                        width={12}
                        height={11}
                        color={theme.palette.backgroundYellow}
                      />
                    </sup>
                  </span>
                ) : null}{' '}
                {booking.hotel.name}
              </Tooltip>
            </Title>

            <Address size="boldS">
              <AddressIcon width={14} height={15} name="point" />
              <Tooltip trigger="hover" title={booking.hotel.address}>
                <AddressText>{booking.hotel.address}</AddressText>
              </Tooltip>
            </Address>

            {booking.hotel.lat && booking.hotel.lng && (
              <StyledLinkForCallTaxi
                $underline
                $greenVariant
                event={CallYaTaxiAnalyzingEventEnum.MyBookings}
                id={booking.id}
                lat={booking.hotel.lat}
                lng={booking.hotel.lng}
              >
                {t('call_taxi')}
              </StyledLinkForCallTaxi>
            )}
          </TitleWrapper>
          <InfoWrapper>
            <CheckInTable
              checkIn={checkIn}
              checkOut={checkOut}
              arrivalTime={arrivalTime}
            />

            <StyledCancellationPolicies
              cancellationPolicies={booking.cancellation_policy}
              currency={booking.currency_code}
            />
            <StyledAmountsTable
              onlineAmount={booking.payment_amount}
              onSpotAmount={booking.on_spot_amount}
              totalAmount={booking.amount}
              currency={booking.currency_code}
            />
            <StyledBookingTimer booking={booking} />
            {showPayment && (
              <>
                <PaymentButton
                  variant="green"
                  onClick={handleOpenBookingPaymentModal}
                >
                  {t('payment_button', {
                    amount: booking.payment_amount,
                    currency: getCurrency(currenciesT, booking.currency_code),
                  })}
                </PaymentButton>
                <PaymentLinkCopy id={booking.id} />
              </>
            )}
          </InfoWrapper>
        </BookingInfo>
        <BookingActions>
          {isRealBooking(booking.booking_number) && (
            <BookingNumber
              variant="desktop"
              bookingNumber={booking.booking_number}
            />
          )}
          <StyledPaymentStatus isPaid={booking.is_paid} />
          <BookingImageWrapper>
            {booking.hotel.seoUrl?.url ? (
              <HotelButtonLink href={booking.hotel.seoUrl.url} target="_blank">
                <HotelButton paddingSize="XS">
                  <HotelButtonIcon name="search" width={20} height={20} />{' '}
                  {t('check_website')}
                </HotelButton>
              </HotelButtonLink>
            ) : null}
            <BookingImage
              fill
              priority
              src={
                booking.roomDetailImages?.[0]?.url ||
                booking.hotel.mainImage?.url ||
                NO_HOTEL_IMAGE_SOURCE
              }
              alt={`hotel-image-${booking.hotel.name}`}
            />
          </BookingImageWrapper>

          <Link href={getBookingId(booking.id)} target="_blank">
            <DetailsButton paddingSize="SM">{t('details')}</DetailsButton>
          </Link>

          {showCancelButton && <StyledCancelButton id={booking.id} />}
        </BookingActions>
      </Wrapper>
    </StyledBookingStatusBadge>
  )
}
