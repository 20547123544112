import {ILLUSTRATION_IMAGES} from '@ui/illustration/lib/constants'
import type {ComponentProps, FC} from 'react'
import {throwComponentException} from '@utils/exceptions/components'

export type IllustrationName = keyof typeof ILLUSTRATION_IMAGES

interface Props extends Omit<ComponentProps<'svg'>, 'name'> {
  name: IllustrationName
  width: string | number
  height: string | number
  color?: string
}

export const Illustration: FC<Props> = ({name, ...properties}) => {
  if (!(name in ILLUSTRATION_IMAGES)) {
    throwComponentException(
      `Illustration ${name.toString()} does not exist in the illustration/assets directory.`,
    )
  }

  const IllustrationImage = ILLUSTRATION_IMAGES[name]

  return <IllustrationImage {...properties} />
}
