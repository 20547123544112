import styled from '@emotion/styled'
import {Text} from '@components/ui'
import {SearchScenarioFiltersHotelTypes} from '@slices/global/ui/search-scenario/filters/hotel-types'
import {SearchScenarioFiltersPriceOfStay} from '@slices/global/ui/search-scenario/filters/price-of-stay'
import {SearchScenarioFiltersRatingReviews} from '@slices/global/ui/search-scenario/filters/rating-reviews'
import useTranslation from 'next-translate/useTranslation'
import {SearchScenarioFiltersResetButton} from '@slices/global/ui/search-scenario/filters/reset-filters'
import {useDeviceDetection} from '@/library/hooks/use-device-detection'
import dynamic from 'next/dynamic'
import {SearchScenarioFiltersStarsCount} from '@slices/global/ui/search-scenario/filters/stars-count'
import {SearchScenarioFiltersApplyButton} from '@slices/global/ui/search-scenario/filters/apply-filters'
import {useWindowSize} from '@/library/hooks/use-window-size'
import {SEARCH_SCENARIO_FILTERS_ID} from '@constants/dom-ids'
import {SearchScenarioFiltersActions} from './actions'
import {TOUCH_VERSION_POINT} from '../constants'

const SearchScenarioFiltersMobile = dynamic(() =>
  import('./mobile-filters').then(
    (result) => result.SearchScenarioFiltersMobile,
  ),
)

const Content = styled.div`
  padding: 20px 20px;
  height: 100%;
  overflow: auto;
`

const Title = styled(Text)`
  color: ${({theme}) => theme.palette.textPrimary};
`

const ContentWrapper = styled.div`
  margin: 25px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const ButtonsWrapper = styled.div`
  position: sticky;
  bottom: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: ${({theme}) => theme.palette.backgroundQuaternary};
`

export const SearchScenarioFilters = () => {
  const {t} = useTranslation('global.search')
  const isTouch = useDeviceDetection('touch')
  const windowWidth = useWindowSize()
  const isTouchLayout = isTouch && windowWidth <= TOUCH_VERSION_POINT

  if (isTouchLayout) {
    return <SearchScenarioFiltersMobile />
  }

  return (
    <Content id={SEARCH_SCENARIO_FILTERS_ID}>
      <Title size="L">{t('filters.title')}</Title>
      <ContentWrapper>
        <SearchScenarioFiltersActions />
        <SearchScenarioFiltersPriceOfStay />
        <SearchScenarioFiltersHotelTypes />
        <SearchScenarioFiltersRatingReviews />
        <SearchScenarioFiltersStarsCount />
      </ContentWrapper>
      <ButtonsWrapper>
        <SearchScenarioFiltersApplyButton />
        <SearchScenarioFiltersResetButton />
      </ButtonsWrapper>
    </Content>
  )
}
