import type {ApplicationCustomTheme} from '@/assets/theme'
import {BookingStatusEnum} from '@/library/api/gql/generate-types'

export const getBookingStatusInfo = (theme: ApplicationCustomTheme) => {
  return {
    [BookingStatusEnum.New]: {
      label: 'status.new',
      color: theme.palette.textBlue,
    },
    [BookingStatusEnum.Booked]: {
      label: 'status.booked',
      color: theme.palette.textPositive,
    },
    [BookingStatusEnum.Cancelled]: {
      label: 'status.cancelled',
      color: theme.palette.textError,
    },
    [BookingStatusEnum.Completed]: {
      label: 'status.completed',
      color: theme.palette.textPositive,
    },
    [BookingStatusEnum.Error]: {
      label: 'status.error',
      color: theme.palette.textError,
    },
    [BookingStatusEnum.Formed]: {
      label: 'status.formed',
      color: theme.palette.textBlue,
    },
    [BookingStatusEnum.InProcess]: {
      label: 'status.in_process',
      color: theme.palette.textBlue,
    },
    [BookingStatusEnum.PaymentTimeout]: {
      label: 'status.payment_timeout',
      color: theme.palette.textError,
    },
    [BookingStatusEnum.Rejected]: {
      label: 'status.rejected',
      color: theme.palette.textError,
    },
  }
}
