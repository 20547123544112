import {addDays, isValid, parseISO} from 'date-fns'
import {getNearestWeekend} from '@utils/date/get-nearest-weekend'
import {isEmpty} from 'ramda'
import {DEFAULT_ADULTS} from '@store/search/guests/adults'
import {ProviderRatesSearchingTypeEnum} from '@/library/api/gql/generate-types'
import {isString} from '@/library/utils/guards/types'
import {ENVIRONMENT} from '@/library/utils/guards/environment'
import {DEFAULT_PAGE} from '@constants/common'

export type QueryParameter = string | string[] | undefined

type ParseDateResult = {dateFrom: Date; dateTo: Date}

export class SearchQueryParserService {
  protected parsePage(page: QueryParameter) {
    if (Array.isArray(page)) {
      return DEFAULT_PAGE
    }

    const normalizePage = Number(page)

    if (Number.isNaN(normalizePage)) {
      return DEFAULT_PAGE
    }

    if (normalizePage <= 0) {
      return DEFAULT_PAGE
    }

    return normalizePage
  }

  protected parseRatingFrom(ratingFrom: QueryParameter) {
    const parsedRatingFrom = Number(ratingFrom)

    return Number.isNaN(parsedRatingFrom) ? null : parsedRatingFrom
  }

  protected parseHotelStars(stars: QueryParameter) {
    if (!stars) {
      return []
    }
    const isParsedStars = Array.isArray(stars)

    if (isParsedStars) {
      const parsedStarsArray = stars
        .map(Number)
        .filter((existStars) => !Number.isNaN(existStars))

      return parsedStarsArray.length > 0 ? parsedStarsArray : []
    }

    const parsedStars = Number(stars)

    if (Number.isNaN(parsedStars)) {
      return []
    }

    return [parsedStars]
  }

  protected parseHotelTypes(hotelTypes?: QueryParameter) {
    if (!hotelTypes) {
      return []
    }

    const isHotelTypesArray = Array.isArray(hotelTypes)

    if (isHotelTypesArray) {
      const parsedHotelTypesArray = hotelTypes
        .map(Number)
        .filter((hotelType) => !Number.isNaN(hotelType))

      return parsedHotelTypesArray.length > 0 ? parsedHotelTypesArray : []
    }

    const parsedHotelType = Number(hotelTypes)

    if (Number.isNaN(parsedHotelType)) {
      return []
    }

    return [parsedHotelType]
  }

  protected parsePrices(priceFrom: QueryParameter, priceTo: QueryParameter) {
    const parsedPriceFrom = Number.isNaN(Number(priceFrom))
      ? ENVIRONMENT.MIN_PRICE_VALUE
      : Number(priceFrom)
    const parsedPriceTo = Number.isNaN(Number(priceTo))
      ? ENVIRONMENT.MAX_PRICE_VALUE
      : Number(priceTo)

    const minPrice = Math.min(parsedPriceFrom, parsedPriceTo)
    const maxPrice = Math.max(parsedPriceFrom, parsedPriceTo)

    const isValidPriceFrom = this.checkIsValidPrice(minPrice)
    const isValidPriceTo = this.checkIsValidPrice(maxPrice)

    return {
      parsedPriceFrom: isValidPriceFrom
        ? minPrice
        : ENVIRONMENT.MIN_PRICE_VALUE,
      parsedPriceTo: isValidPriceTo ? maxPrice : ENVIRONMENT.MAX_PRICE_VALUE,
    }
  }

  private checkIsValidPrice(price: number) {
    return (
      price >= ENVIRONMENT.MIN_PRICE_VALUE &&
      price <= ENVIRONMENT.MAX_PRICE_VALUE
    )
  }

  protected parseKidsAge(kidsAge: QueryParameter): number[] {
    if (!kidsAge) {
      return []
    }

    if (Array.isArray(kidsAge)) {
      return kidsAge
        .map(Number)
        .filter((value) => !Number.isNaN(value))
        .filter((value) => !isEmpty(value))
        .filter((value) => value >= 0)
    }

    if (Number.isNaN(Number(kidsAge))) {
      return []
    }

    return [Number(kidsAge)]
  }

  private isValidDateRange(startDate: Date, endDate: Date) {
    const today = new Date()

    return !(
      startDate < addDays(today, -1) ||
      endDate > addDays(startDate, ENVIRONMENT.MAX_SEARCH_DATE_RANGE_COUNT) ||
      endDate < startDate
    )
  }

  protected parseSearchingType(
    searchingType: QueryParameter,
  ): ProviderRatesSearchingTypeEnum {
    if (!searchingType || !isString(searchingType)) {
      return ProviderRatesSearchingTypeEnum.Geo
    }

    const normalize =
      searchingType.toUpperCase() as ProviderRatesSearchingTypeEnum

    return Object.values(ProviderRatesSearchingTypeEnum).includes(normalize)
      ? normalize
      : ProviderRatesSearchingTypeEnum.Geo
  }

  private isValidDate(date: QueryParameter): date is string {
    if (!date) {
      return false
    }

    if (Array.isArray(date)) {
      return false
    }

    if (!isValid(parseISO(date))) {
      return false
    }

    return true
  }

  protected parseAdults(adults: QueryParameter): number {
    if (!adults) {
      return DEFAULT_ADULTS
    }

    if (Array.isArray(adults)) {
      return DEFAULT_ADULTS
    }

    const normalizeAdults = Number(adults)

    if (Number.isNaN(normalizeAdults)) {
      return DEFAULT_ADULTS
    }

    if (normalizeAdults < 0) {
      return DEFAULT_ADULTS
    }

    if (normalizeAdults > ENVIRONMENT.MAX_SEARCH_INPUT_ADULTS) {
      return DEFAULT_ADULTS
    }

    return normalizeAdults
  }

  protected parseDate(
    dateFrom: QueryParameter,
    dateTo: QueryParameter,
  ): ParseDateResult {
    const weekend = getNearestWeekend(new Date())

    const parsedDateFrom = this.isValidDate(dateFrom) && parseISO(dateFrom)
    const parsedDateTo = this.isValidDate(dateTo) && parseISO(dateTo)

    const isValidRange =
      parsedDateFrom &&
      parsedDateTo &&
      this.isValidDateRange(parsedDateFrom, parsedDateTo)

    return {
      dateFrom: isValidRange ? parsedDateFrom : weekend.startDate,
      dateTo: isValidRange ? parsedDateTo : weekend.endDate,
    }
  }
}
