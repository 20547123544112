import type {Translate} from 'next-translate'
import {object, string} from 'yup'
import {MINIMAL_PRICE, onlyIntegers} from '../../validation'
import {TEST_MAX_VALUE} from '../constants'

export const balanceForm = (t: Translate, maxAmount: number) => {
  return {
    initialState: {
      amount: null,
    },
    validationSchema: object().shape({
      amount: string()
        .nullable()
        .typeError('yup:incorrect_format')
        .matches(onlyIntegers, {
          message: 'yup:incorrect_format',
          excludeEmptyString: true,
        })
        .test(TEST_MAX_VALUE(t, maxAmount))
        .min(MINIMAL_PRICE, t('yup:min_amount', {amount: MINIMAL_PRICE})),
    }),
  } as const
}
