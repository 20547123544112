import {ENVIRONMENT} from '@utils/guards/environment'

type URLSearchParametersInit = ConstructorParameters<typeof URLSearchParams>[0]

export const objectToQuerySearch = <T extends Record<string, string | number>>(
  object: T,
): URLSearchParams => {
  const searchParameters = new URLSearchParams()

  Object.keys(object).forEach((objectKey) => {
    searchParameters.set(objectKey, String(object[objectKey]))
  })

  return searchParameters
}

export const url = (
  path: string,
  query?: ConstructorParameters<typeof URLSearchParams>[0],
  base = ENVIRONMENT.REST_API_CLIENT,
): URL | never => {
  if (!base) {
    throw new Error('Specify "NEXT_PUBLIC_API" within your .env.local please')
  }

  const result = new URL(path, base)
  result.search = new URLSearchParams(query).toString()

  return result
}

export const createUrl = (
  path: string,
  base: string,
  searchParameters?: URLSearchParametersInit,
) => {
  const url = new URL(path, base)

  if (searchParameters) {
    url.search = new URLSearchParams(searchParameters).toString()
  }

  return url
}

export const getCanonicalUrl = (path: string, base: string) =>
  createUrl(path, base).toString()

export const buildOrigin = (port: string, host: string) => `${port}://${host}`
