import type {FC} from 'react'
import {EtAvailability} from '@/library/api/gql/generate-types'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'
import type {OstrovokNoShow} from '../lib/types'
import {InlineText} from '../../typography'
import {getDayPeriod} from '../lib/helpers'

interface NoShowProps {
  noShow?: OstrovokNoShow | null
}

export const NoShow: FC<NoShowProps> = ({noShow}) => {
  const {t} = useTranslation('global.hotel')

  const isNoShowAvailable = noShow?.availability === EtAvailability.Available

  if (!noShow) {
    return null
  }

  if (isNoShowAvailable && noShow.dayPeriod && noShow.time) {
    return (
      <Trans
        i18nKey="rules.et.no_show_available_with_time"
        ns="global.hotel"
        components={[<InlineText key="no_show" />]}
        values={{
          dayPeriod: getDayPeriod(t, noShow.dayPeriod),
          time: noShow.time,
        }}
      />
    )
  }

  if (isNoShowAvailable && noShow.dayPeriod) {
    return (
      <Trans
        i18nKey="rules.et.no_show_available"
        ns="global.hotel"
        components={[<InlineText key="no_show" />]}
        values={{
          dayPeriod: getDayPeriod(t, noShow.dayPeriod),
        }}
      />
    )
  }

  return <Trans i18nKey="rules.et.no_show_unavailable" ns="global.hotel" />
}
