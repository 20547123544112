import type {FC} from 'react'
import useTranslation from 'next-translate/useTranslation'
import Trans from 'next-translate/Trans'
import {EtAvailability} from '@/library/api/gql/generate-types'
import type {OstrovokChildren} from '../lib/types'
import {InlineAmount, InlineCurrency, InlineText} from '../../typography'
import {formatKidsAgeRange, getCurrency} from '../../../lib/helpers'

interface ChildrenItemProps {
  childrenItem: OstrovokChildren
}

export const ChildrenItem: FC<ChildrenItemProps> = ({childrenItem}) => {
  const {t} = useTranslation('global.hotel')
  const {t: currenciesT} = useTranslation('currencies')

  if (
    childrenItem.price &&
    childrenItem.extraBed === EtAvailability.Available
  ) {
    return (
      <Trans
        i18nKey="rules.et.kids_bed_with_price"
        ns="global.hotel"
        components={[
          <InlineText
            key={`children_${childrenItem.ageStart}_${childrenItem.ageEnd}_range`}
          />,
          <InlineAmount
            key={`children_${childrenItem.ageStart}_${childrenItem.ageEnd}_amount`}
          />,
          <InlineCurrency
            key={`children_${childrenItem.ageStart}_${childrenItem.ageEnd}_currency`}
          />,
        ]}
        values={{
          ageRange: formatKidsAgeRange(
            t,
            childrenItem.ageStart,
            childrenItem.ageEnd,
          ),
          price: childrenItem.price?.toLocaleString(),
          currency: getCurrency(currenciesT, childrenItem.currency),
        }}
      />
    )
  }

  if (childrenItem.extraBed === EtAvailability.Available) {
    return (
      <Trans
        i18nKey="rules.et.kids_bed"
        ns="global.hotel"
        components={[
          <InlineText
            key={`children_${childrenItem.ageStart}_${childrenItem.ageEnd}_range`}
          />,
        ]}
        values={{
          ageRange: formatKidsAgeRange(
            t,
            childrenItem.ageStart,
            childrenItem.ageEnd,
          ),
        }}
      />
    )
  }

  return (
    <Trans
      i18nKey="rules.et.kids_bed_unavailable"
      ns="global.hotel"
      components={[
        <InlineText
          key={`children_${childrenItem.ageStart}_${childrenItem.ageEnd}_range`}
        />,
      ]}
      values={{
        ageRange: formatKidsAgeRange(
          t,
          childrenItem.ageStart,
          childrenItem.ageEnd,
        ),
      }}
    />
  )
}
