import {type FC} from 'react'
import {type TooltipProps} from 'antd/lib/tooltip'
import dynamic from 'next/dynamic'
import {useTheme} from '@emotion/react'

const LibraryTooltip = dynamic(() => import('antd/lib/tooltip'))

export const Tooltip: FC<TooltipProps> = ({children, ...properties}) => {
  const theme = useTheme()

  return (
    <LibraryTooltip
      color={theme.palette.backgroundPrimary}
      overlayInnerStyle={{
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '18px',
        ...properties.overlayInnerStyle,
      }}
      {...properties}
    >
      {children}
    </LibraryTooltip>
  )
}
