export const copyService = {
  // Если получилось скопировать, то возвращаем true, иначе false
  // Обработка кейса с false должна происходить, либо не происходить на уровне приложения
  // execCommand и queryCommandSupported deprecated,
  // но единственный аналог это clipboard, который нельзя использовать на HTTP
  copy: async (text: string): Promise<boolean> => {
    try {
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(text)
        return true
      }
      if (document.queryCommandSupported('copy')) {
        const textarea = document.createElement('textarea')
        textarea.value = text
        textarea.style.position = 'fixed'
        textarea.style.opacity = '0'
        document.body.append(textarea)
        textarea.select()
        const success = document.execCommand('copy')
        textarea.remove()
        return success
      }
    } catch {
      return false
    }
    return false
  },
}
