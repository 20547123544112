const MEDIA_DEVICE_SIZE = {
  MOBILE_SMALL: '320px',
  MOBILE_MEDIUM: '375px',
  MOBILE_LARGE: '480px',
  MOBILE_VERY_LARGE: '680px',
  TABLET: '824px',
  LAPTOP: '1024px',
  LAPTOP_MEDIUM: '1280px',
  LAPTOP_LARGE: '1440px',
  DESKTOP: '1920px',
}

/**
 * @warn Медиа запросы должны идти в строгом порядке уменьшения. От большого разрешения - к меньшему
 * example
 * ```css
 *   @media ${MEDIA_DEVICE_QUERY.DESKTOP} {
 *     background-color: green;
 *   }
 *
 *   @media ${MEDIA_DEVICE_QUERY.LAPTOP_LARGE} {
 *     background-color: yellow;
 *   }
 *
 *   @media ${MEDIA_DEVICE_QUERY.MOBILE_MEDIUM} {
 *     background-color: sienna;
 *   }
 *
 *   @media ${MEDIA_DEVICE_QUERY.MOBILE_SMALL} {
 *     background-color: red;
 *   }
 *  ```
 */
export const MEDIA_DEVICE_QUERY = {
  MOBILE_SMALL: `(max-width: ${MEDIA_DEVICE_SIZE.MOBILE_SMALL})`,
  MOBILE_MEDIUM: `(max-width: ${MEDIA_DEVICE_SIZE.MOBILE_MEDIUM})`,
  MOBILE_LARGE: `(max-width: ${MEDIA_DEVICE_SIZE.MOBILE_LARGE})`,
  MOBILE_VERY_LARGE: `(max-width: ${MEDIA_DEVICE_SIZE.MOBILE_VERY_LARGE})`,
  TABLET: `(max-width: ${MEDIA_DEVICE_SIZE.TABLET})`,
  LAPTOP: `(max-width: ${MEDIA_DEVICE_SIZE.LAPTOP})`,
  LAPTOP_MEDIUM: `(max-width: ${MEDIA_DEVICE_SIZE.LAPTOP_MEDIUM})`,
  LAPTOP_LARGE: `(max-width: ${MEDIA_DEVICE_SIZE.LAPTOP_LARGE})`,
  DESKTOP: `(max-width: ${MEDIA_DEVICE_SIZE.DESKTOP})`,
  BIG_SCREEN: `(min-width: ${MEDIA_DEVICE_SIZE.DESKTOP})`,
}
