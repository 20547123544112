import {GlobalHydrateService} from '@services/common/global-hydrate'
import type {ApplicationProperties} from '@/types/ui'
import {store} from '@store/index'
import type {ProfileReviewsPageProperties} from '@slices/profile/reviews/lib/types'
import {api} from '@api/index'
import type {UploadChangeParam, UploadFile} from 'antd/es/upload/interface'
import {errorService} from '@services/common/error'
import {DEFAULT_FIRST_PAGE_ITEMS} from '@constants/common'
import {CompletedBookingsQueryFilterEnum} from '@api/gql/generate-types'
import {scrollMainWrapper} from '@utils/dom/scroll'

type HydrationInitialData =
  Required<ProfileReviewsPageProperties>['hydrationData']

export class ProfileReviewsService extends GlobalHydrateService<ApplicationProperties> {
  private get state() {
    return store().pages.profile.reviews
  }

  public hydrate(initialData: HydrationInitialData) {
    if (initialData.withReviews) {
      this.state.withReview.setData(initialData.withReviews.data)
      this.state.withReview.setPaginatorInfo(
        initialData.withReviews.paginatorInfo,
      )
    }

    if (initialData.withoutReviews) {
      this.state.withoutReview.setData(initialData.withoutReviews.data)
      this.state.withoutReview.setPaginatorInfo(
        initialData.withoutReviews.paginatorInfo,
      )
    }

    super.hydrate(initialData)
  }

  public handleBackFromEdit() {
    store().pages.profile.reviews.setSelectReview(null)
    this.state.editorReview.reset()
    scrollMainWrapper()
  }

  public async createReview(bookingId: number) {
    const hotelReviewImages = this.state.editorReview.images
      .filter((image) => image.status !== 'error')
      .map((image) => image.originFileObj as File)

    return api.hotel
      .createReview({
        variables: {
          bookingId,
          input: {
            negative: this.state.editorReview.negative,
            positive: this.state.editorReview.positive,
            ratings: this.state.editorReview.ratingInputs,
            title: '',
          },
          hotelReviewImages,
        },
      })
      .then((newReview) => {
        if (newReview) {
          const cloneReview = this.state.withoutReview.getById(bookingId)
          if (cloneReview) {
            this.state.withReview.add({
              ...cloneReview,
              hotelReview: {
                ...newReview,
              },
            })
          }
          this.state.withoutReview.remove(bookingId)
          this.handleBackFromEdit()
        }
      })
      .catch((error) =>
        this.state.editorReview.setError(errorService.getFirstErrorText(error)),
      )
  }

  public async updateReview(reviewId: number) {
    return api.hotel
      .updateReview({
        variables: {
          hotelReviewId: reviewId,
          input: {
            negative: this.state.editorReview.negative,
            positive: this.state.editorReview.positive,
            ratings: this.state.editorReview.ratingInputs,
            title: '',
          },
        },
      })
      .then((updateReview) => {
        if (updateReview) {
          this.state.withReview.updateById(updateReview.id, updateReview)
          this.handleBackFromEdit()
        }
      })
      .catch((error) =>
        this.state.editorReview.setError(errorService.getFirstErrorText(error)),
      )
  }

  public async attachImages(hotelReviewId: number, file: UploadFile) {
    return api.hotel
      .attachReviewImages({
        variables: {
          hotelReviewId,
          images: [file.originFileObj],
        },
      })
      .then((result) => {
        if (result) {
          const updateImages = this.state.editorReview.images.map((image) => {
            if (image.uid === file.uid) {
              return {
                ...image,
                uid: result[0].batchId,
              }
            }

            return image
          })
          this.state.editorReview.setImages(updateImages)
        }
      })
      .catch((error) => {
        this.state.editorReview.setImageError(
          file.uid,
          errorService.getFirstErrorText(error),
        )
      })
  }

  public async detachImage(
    batchId: string,
    hotelReviewId: number,
    {fileList}: UploadChangeParam<UploadFile>,
  ) {
    return api.hotel
      .detachReviewImage({
        variables: {
          batchId,
          hotelReviewId,
        },
      })
      .then(() => {
        const oldItems = this.state.withReview.getByReviewId(hotelReviewId)
        if (oldItems && oldItems.hotelReview) {
          this.state.withReview.updateById(hotelReviewId, {
            ...oldItems.hotelReview,
            hotelReviewImagesBatch:
              oldItems.hotelReview?.hotelReviewImagesBatch?.filter(
                (image) => image.batchId !== batchId,
              ),
          })
        }

        store().pages.profile.reviews.editorReview.setImages(fileList)
      })
      .catch((error) => {
        this.state.editorReview.setImageError(
          batchId,
          errorService.getFirstErrorText(error),
        )
      })
  }

  public async loadActualHotelTypes(hotelId: number): Promise<void> {
    this.state.editorReview.setIsLoading(true)

    return api.hotel
      .getActualRatingTypes({
        variables: {
          hotelId,
        },
      })
      .then((result) => {
        if (result) {
          this.state.editorReview.setActualRatingTypes(result)
        }
      })
      .catch((error) => {
        this.state.editorReview.setError(errorService.getFirstErrorText(error))
      })
      .finally(() => this.state.editorReview.setIsLoading(false))
  }

  public loadMoreWithReview(): void {
    if (!this.state.withReview.data) {
      return
    }

    if (!this.state.withReview.paginatorInfo?.hasMorePages) {
      return
    }

    const updatedPage = this.state.withReview.page + 1
    this.state.withReview.setIsLoading(true)
    api.user
      .getCompleteBookings({
        variables: {
          page: updatedPage,
          first: DEFAULT_FIRST_PAGE_ITEMS,
          filter: CompletedBookingsQueryFilterEnum.WithHotelReview,
        },
      })
      .then((newWithReview) => {
        if (newWithReview) {
          this.state.withReview.setPage(updatedPage)
          this.state.withReview.setData([
            ...(this.state.withReview.data || []),
            ...newWithReview.data,
          ])
          this.state.withReview.setPaginatorInfo(newWithReview.paginatorInfo)
        }
      })
      .finally(() => {
        this.state.withReview.setIsLoading(false)
      })
  }

  public loadMoreWithoutReview(): void {
    if (!this.state.withoutReview.data) {
      return
    }

    if (!this.state.withoutReview.paginatorInfo?.hasMorePages) {
      return
    }

    const updatedPage = this.state.withoutReview.page + 1
    this.state.withoutReview.setIsLoading(true)
    api.user
      .getCompleteBookings({
        variables: {
          page: updatedPage,
          first: DEFAULT_FIRST_PAGE_ITEMS,
          filter: CompletedBookingsQueryFilterEnum.WithoutHotelReview,
        },
      })
      .then((newWithoutReview) => {
        if (newWithoutReview) {
          this.state.withoutReview.setPage(updatedPage)
          this.state.withoutReview.setData([
            ...(this.state.withoutReview.data || []),
            ...newWithoutReview.data,
          ])
          this.state.withoutReview.setPaginatorInfo(
            newWithoutReview.paginatorInfo,
          )
        }
      })
      .finally(() => {
        this.state.withoutReview.setIsLoading(false)
      })
  }
}
