import useTranslation from 'next-translate/useTranslation'
import {useEffect, useState, useMemo} from 'react'
import styled from '@emotion/styled'
import {useWindowSize} from '@hooks/use-window-size'
import {ProgressBar} from '@ui/progress-bar'
import {Text} from '@components/ui'
import {observer} from 'mobx-react'
import {store} from '@store/index'
import {useDeviceDetection} from '@hooks/use-device-detection'
import {useRouter} from 'next/router'

const Wrapper = styled.div``
const Title = styled(Text)`
  margin-bottom: 8px;
`

enum RectsNumber {
  MOBILE = 10,
  TABLET = 15,
  SMALL_LAPTOP = 10,
  DESKTOP = 20,
}

const getNumberOfRects = (width: number) => {
  if (width < 450) {
    return RectsNumber.MOBILE
  }
  if (width < 900) {
    return RectsNumber.TABLET
  }
  if (width < 1200) {
    return RectsNumber.SMALL_LAPTOP
  }
  return RectsNumber.DESKTOP
}

enum Duration {
  FAST = 100,
  SLOW = 500,
  FEASIBLE = 1000,
  INFEASIBLE = 10000,
}

const determineDuration = (percent: number) => {
  if (percent < 80) {
    return Duration.FAST
  }
  if (percent < 90) {
    return Duration.SLOW
  }
  if (percent < 95) {
    return Duration.FEASIBLE
  }
  return Duration.INFEASIBLE
}

export const HotelCardProgressBar = observer(() => {
  const {t} = useTranslation('global.search')
  const router = useRouter()
  const windowSize = useWindowSize()
  const numberOfRects = getNumberOfRects(windowSize)
  const isMobile = useDeviceDetection('mobile')
  const [progress, setProgress] = useState(0)

  const destination = useMemo(
    () => store().search.destination.selectPlace?.name,
    [router.asPath],
  )

  const onLoading = () => {
    setProgress((previousState) => {
      if (previousState < 100) {
        return previousState + 1
      }

      return previousState
    })
  }

  useEffect(() => {
    const loadingTimeout = setTimeout(onLoading, determineDuration(progress))
    return () => {
      clearTimeout(loadingTimeout)
    }
  }, [progress])

  return (
    <Wrapper>
      <Title size={isMobile ? 'XS' : 'M'}>
        {t('hotels.cards.progressbar.title', {
          destination,
        })}
      </Title>
      <ProgressBar numberOfRects={numberOfRects} progress={progress} />
    </Wrapper>
  )
})
