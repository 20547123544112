import {makeAutoObservable} from 'mobx'
import {DEFAULT_PAGE} from '@constants/common'
import {map} from 'ramda'
import type {BookingStatusEnum} from '@/library/api/gql/generate-types'

export class CProfileBookingsStore {
  constructor() {
    makeAutoObservable(this)
  }

  private _loading = false

  public get loading(): boolean {
    return this._loading
  }

  private _bookings: UserBookings | null = null

  public get bookings(): UserBookings | null {
    return this._bookings
  }

  private _page: number = DEFAULT_PAGE

  public get page(): number {
    return this._page
  }

  private _paginatorInfo: UserBookingsPaginatorInfo | null = null

  public get paginatorInfo(): UserBookingsPaginatorInfo | null {
    return this._paginatorInfo
  }

  public setLoading(flag: boolean): void {
    this._loading = flag
  }

  public setBookings(bookings: UserBookings | null): void {
    this._bookings = bookings
  }

  public setPage(page: number): void {
    this._page = page
  }

  public setPaginatorInfo(info: UserBookingsPaginatorInfo): void {
    this._paginatorInfo = info
  }

  public setBookingStatus(id: number, status: BookingStatusEnum): void {
    if (!this._bookings) {
      return
    }

    const bookingIndex = this._bookings.findIndex(
      (booking) => booking.id === id,
    )

    if (bookingIndex !== -1) {
      const updatedBookings = map((booking) => {
        return booking.id === id ? {...booking, status} : booking
      }, this._bookings)
      this._bookings = updatedBookings
    }
  }
}
