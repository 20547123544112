import {GlobalHydrateService} from '@services/common/global-hydrate'
import type {AffiliateWidgetsPageProperties} from '@slices/affiliate/widgets/lib/types'
import {store} from '@store/index'
import type {AffiliateWidgetVoteFormData} from '@constants/form-validation/vote-affiliate-widget'
import {api} from '@api/index'
import {errorService} from '@services/common/error'
import {isNumber} from '@utils/guards/types'

type InitialHydrationData = NonNullable<
  AffiliateWidgetsPageProperties['hydrationData']
>

export class AffiliateWidgetsService extends GlobalHydrateService<InitialHydrationData> {
  private get state() {
    return store().pages.affiliate.widgets
  }

  public hydrate(initialData: InitialHydrationData) {
    if (initialData.widgets) {
      this.state.setData(initialData.widgets.data)
      this.state.setPaginatorInfo(initialData.widgets.paginatorInfo)
    }

    super.hydrate(initialData)
  }

  public vote(data: AffiliateWidgetVoteFormData): void {
    if (!data) {
      this.state.vote.setIsOpen(false)
      return
    }

    if (!this.state.vote.selectId) {
      this.state.vote.setIsOpen(false)
      return
    }

    this.state.vote.setIsLoading(true)

    api.affiliate
      .voteWidget({
        variables: {
          widgetId: this.state.vote.selectId,
          siteUrl: this.state.vote.siteURL,
          comment: this.state.vote.comment,
        },
      })
      .then((userVote) => {
        if (userVote && this.state.data) {
          this.state.vote.setIsOpen(false)
          this.state.vote.setError('')
          const updateData = [...this.state.data]

          const findItemIndex = updateData.findIndex(
            (item) => item?.id === this.state.vote.selectId,
          )

          if (isNumber(findItemIndex)) {
            this.state.updateUserVoteByIndex(findItemIndex, userVote)
          }

          store().pages.affiliate.widgets.vote.setSelectId(null)
          store().pages.affiliate.widgets.vote.setSiteURL('')
          store().pages.affiliate.widgets.vote.setComment('')
        }
      })
      .catch((error) => {
        const firstError = errorService.getFirstError(error)
        const errorText =
          firstError?.extensions.validation?.site_url?.[0] ||
          firstError?.message
        this.state.vote.setError(errorText)
      })
      .finally(() => this.state.vote.setIsLoading(false))
  }
}
