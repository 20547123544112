import {BookingStatus, LinkForCallTaxi} from '@/library/components/features'
import {Icon} from '@/library/components/ui/icon'
import {ImageWithSkeleton} from '@/library/components/ui/image-with-skeleton'
import {NO_HOTEL_IMAGE_SOURCE} from '@/library/constants/hotel'
import styled from '@emotion/styled'
import type {FC} from 'react'
import {Button, Link, Text} from '@/library/components/ui'
import {PriceWithCurrency} from '@/library/components/ui/typography'
import {useTheme} from '@emotion/react'
import {Tooltip} from '@/library/components/ui/tooltip'
import {CancellationPolicies} from '@/library/components/features/cancellation-policies'
import {getBookingId} from '@/types/enums/routes'
import {getCurrency} from '@/library/utils/currency'
import useTranslation from 'next-translate/useTranslation'
import {MEDIA_DEVICE_QUERY} from '@/assets/theme/media'
import {store} from '@store/index'
import {CallYaTaxiAnalyzingEventEnum} from '@/library/api/gql/generate-types'
import {PaymentStatus} from './payment-status'
import {CheckInTable} from './check-in-table'
import {AmountsTable} from './amounts-table'
import {BookingNumber} from './booking-number'
import {PaymentLinkCopy} from './payment-link-copy'
import {CancelButton} from './cancel-button'
import {BookingTimer} from './booking-timer'
import {isRealBooking} from '../lib/helpers'

const Wrapper = styled.div`
  border-radius: 12px;
  width: calc(100vw - 64px);
  box-shadow: ${({theme}) => theme.palette.boxShadowPrimary} 0px 4px 20px;

  @media ${MEDIA_DEVICE_QUERY.MOBILE_LARGE} {
    width: calc(100vw - 32px);
  }
`

const BookingImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 270px;
  border-radius: 12px 12px 0 0;
`

const BookingImage = styled(ImageWithSkeleton)`
  .image {
    border-radius: 12px 12px 0 0;
    object-fit: cover;
    object-position: center;
  }
`

const StatusBadge = styled.div`
  background-color: ${({theme}) => theme.palette.backgroundQuaternary};
  position: absolute;
  top: 8px;
  right: 8px;
  padding: 12px;
  border-radius: 12px;
`

const StyledBookingStatus = styled(BookingStatus)`
  text-align: end;
`

const StyledPaymentStatus = styled(PaymentStatus)`
  text-align: end;
`

const BookingPriceWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

const HotelButtonLink = styled(Link)`
  position: absolute;
  bottom: 8px;
  z-index: 2;
  left: calc(50% - 160px);
  max-width: 320px;
  width: 100%;

  @media ${MEDIA_DEVICE_QUERY.MOBILE_LARGE} {
    width: 100%;
    left: unset;
    max-width: unset;
  }
`

const HotelButtonIcon = styled(Icon)`
  margin-right: 4px;
  color: ${({theme}) => theme.palette.textQuaternary} !important;
`

const HotelButton = styled(Button)`
  text-transform: uppercase;
  margin: 0 8px;
  width: calc(100% - 16px);
`

const InfoWrapper = styled.div`
  padding: 12px;
`

const Title = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${({theme}) => theme.palette.textPrimary};
`

const Address = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const StyledLinkForCallTaxi = styled(LinkForCallTaxi)`
  margin-top: 2px;
`

const AddressIcon = styled(Icon)`
  color: ${({theme}) => theme.palette.backgroundAccentPrimary};
`
const AddressText = styled.span`
  margin-left: 4px;
  color: ${({theme}) => theme.palette.backgroundAccentPrimary};
`

const StyledCheckInTable = styled(CheckInTable)`
  margin-top: 16px;
`

const StyledCancellationPolicies = styled(CancellationPolicies)`
  margin-top: 16px;
`

const StyledAmountsTable = styled(AmountsTable)`
  margin-top: 16px;
`

const PaymentInfoWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledLink = styled(Link)`
  width: 100%;
`
const DetailsButton = styled(Button)`
  margin-top: 12px;
  text-transform: uppercase;
  width: 100%;
`

const PaymentButton = styled(Button)`
  text-transform: uppercase;
  width: 100%;
  margin-top: 12px;
`

const StyledCancelButton = styled(CancelButton)`
  margin-top: 12px;
`

interface BookingProps {
  booking: UserBooking
  showCancelButton: boolean
  showPayment: boolean
  checkIn: string
  checkOut: string
  arrivalTime?: string
}

export const MobileBooking: FC<BookingProps> = ({
  booking,
  checkIn,
  checkOut,
  arrivalTime,
  showCancelButton,
  showPayment,
}) => {
  const {t} = useTranslation('profile.bookings')
  const {t: currenciesT} = useTranslation('currencies')
  const theme = useTheme()

  const handleOpenBookingPaymentModal = () => {
    store().bookingPaymentModal.setBookingId(booking.id)
    if (booking.hotel.seoUrl?.url) {
      store().bookingPaymentModal.setBookingHotelUrl(booking.hotel.seoUrl.url)
    }
    store().bookingPaymentModal.setBookingAccessToken(booking.access_token)
    store().bookingPaymentModal.setIsOpen(true)
  }

  return (
    <Wrapper>
      <BookingImageWrapper>
        <BookingImage
          fill
          priority
          src={
            booking.roomDetailImages?.[0]?.url ||
            booking.hotel.mainImage?.url ||
            NO_HOTEL_IMAGE_SOURCE
          }
          alt={`hotel-image-${booking.hotel.name}`}
        />
        <StatusBadge>
          {isRealBooking(booking.booking_number) && (
            <BookingNumber
              variant="mobile"
              bookingNumber={booking.booking_number}
            />
          )}
          <StyledBookingStatus status={booking.status} size="S" />
          <StyledPaymentStatus isPaid={booking.is_paid} />
          <BookingPriceWrapper>
            <PriceWithCurrency
              price={booking.amount}
              currency={booking.currency_code}
            />
          </BookingPriceWrapper>
        </StatusBadge>
        {booking.hotel.seoUrl?.url ? (
          <HotelButtonLink href={booking.hotel.seoUrl.url} target="_blank">
            <HotelButton paddingSize="XS">
              <HotelButtonIcon name="search" width={20} height={20} />{' '}
              {t('check_website')}
            </HotelButton>
          </HotelButtonLink>
        ) : null}
      </BookingImageWrapper>
      <InfoWrapper>
        <Title size="boldM">
          <Tooltip trigger="hover" title={booking.hotel.name}>
            {booking.hotel.stars ? (
              <span>
                {booking.hotel.stars}
                <sup>
                  <Icon
                    name="star"
                    width={12}
                    height={11}
                    color={theme.palette.backgroundYellow}
                  />
                </sup>
              </span>
            ) : null}{' '}
            {booking.hotel.name}
          </Tooltip>
        </Title>
        <Address size="boldS">
          <AddressIcon width={14} height={15} name="point" />
          <Tooltip trigger="hover" title={booking.hotel.address}>
            <AddressText>{booking.hotel.address}</AddressText>
          </Tooltip>
        </Address>
        {booking.hotel.lat && booking.hotel.lng && (
          <StyledLinkForCallTaxi
            $greenVariant
            $underline
            event={CallYaTaxiAnalyzingEventEnum.MyBookings}
            id={booking.id}
            lat={booking.hotel.lat}
            lng={booking.hotel.lng}
          >
            {t('call_taxi')}
          </StyledLinkForCallTaxi>
        )}
        <StyledCheckInTable
          checkIn={checkIn}
          checkOut={checkOut}
          arrivalTime={arrivalTime}
        />
        <StyledCancellationPolicies
          cancellationPolicies={booking.cancellation_policy}
          currency={booking.currency_code}
        />
        <StyledAmountsTable
          onlineAmount={booking.payment_amount}
          onSpotAmount={booking.on_spot_amount}
          totalAmount={booking.amount}
          currency={booking.currency_code}
        />
        <PaymentInfoWrapper>
          <BookingTimer booking={booking} />
          <StyledLink href={getBookingId(booking.id)} target="_blank">
            <DetailsButton paddingSize="SM">{t('details')}</DetailsButton>
          </StyledLink>
          {showPayment && (
            <>
              <PaymentButton
                variant="green"
                onClick={handleOpenBookingPaymentModal}
              >
                {t('payment_button', {
                  amount: booking.payment_amount,
                  currency: getCurrency(currenciesT, booking.currency_code),
                })}
              </PaymentButton>
              <PaymentLinkCopy id={booking.id} />
            </>
          )}

          {showCancelButton && <StyledCancelButton id={booking.id} />}
        </PaymentInfoWrapper>
      </InfoWrapper>
    </Wrapper>
  )
}
