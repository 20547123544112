import styled from '@emotion/styled'
import {Text} from '@components/ui'
import {Icon} from '@ui/icon'
import {services} from '@services/index'
import {store} from '@store/index'
import useTranslation from 'next-translate/useTranslation'
import {MEDIA_DEVICE_QUERY} from '@assets/theme/media'
import {useDeviceDetection} from '@hooks/use-device-detection'

const Wrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  height: 100%;
  width: 100%;
`

const NotFoundWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  @media ${MEDIA_DEVICE_QUERY.TABLET} {
    flex-direction: column;
  }
`

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  .title,
  .subtitle {
    color: ${({theme}) => theme.palette.textPrimary};
  }

  @media ${MEDIA_DEVICE_QUERY.MOBILE_LARGE} {
    .subtitle {
      font-size: 14px;
    }
  }

  @media ${MEDIA_DEVICE_QUERY.TABLET} {
    justify-content: center;
    align-items: center;
  }
`

const Title = styled(Text)`
  @media ${MEDIA_DEVICE_QUERY.TABLET} {
    text-align: center;
  }
`

const ResetFilter = styled.span`
  color: ${({theme}) => theme.palette.textAccentPrimary};
  text-decoration: underline;
  cursor: pointer;
`

export const HotelCardNotFound = () => {
  const {t} = useTranslation('global.search')
  const isMobile = useDeviceDetection('mobile')

  const handleResetFilters = () => {
    store().pages.global.search.resetFilters()
    services.components.search.handleSearch()
  }

  return (
    <Wrapper>
      <NotFoundWrapper>
        <Icon name="sad_cloud" width={124} height={124} />
        <TitleWrapper>
          <Title className="title" size="boldS">
            {t('hotels.cards.not_found.title')}
          </Title>
          <div className="subtitle">
            {t('hotels.cards.not_found.subtitle_1')}{' '}
            <ResetFilter onClick={handleResetFilters}>
              {t('hotels.cards.not_found.subtitle_2')}
            </ResetFilter>{' '}
            {t(`hotels.cards.not_found.subtitle_3${isMobile ? '_short' : ''}`)}
          </div>
        </TitleWrapper>
      </NotFoundWrapper>
    </Wrapper>
  )
}
