import styled from '@emotion/styled'
import {
  CallYaTaxiAnalyzingEventEnum,
  type YaTaxiEventParams,
} from '@/library/api/gql/generate-types'
import {services} from '@/library/services'
import {getUrlForCallTaxi} from '@/slices/global/lib/helpers'
import {type FC} from 'react'
import {Link} from '@/library/components/ui'
import {Icon} from '@ui/icon'
import {TRANSITION_STYLES} from '@/library/constants/css'

const StyledLink = styled(Link)<{
  $greenVariant?: boolean
  $underline?: boolean
}>`
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    transition: color ${TRANSITION_STYLES};
  }

  &:hover {
    div {
      color: ${({theme}) => theme.palette.textAccentPrimary};
    }
    svg {
      color: ${({theme}) => theme.palette.textAccentPrimary};
    }
  }

  ${({$greenVariant, theme}) =>
    $greenVariant &&
    `
    color: ${theme.palette.textGreenPrimary};

    svg {
      color: ${theme.palette.textGreenPrimary};
    }

    &:hover {
      color: ${theme.palette.textGreenSecondary};

      svg {
        color: ${theme.palette.textGreenSecondary};
      }
    }
  `}

  ${({$underline}) =>
    $underline &&
    `
    text-decoration: underline;

    &:hover {
      text-decoration: underline;
    }
  `}
`

interface Props {
  lat: number
  lng: number
  event: CallYaTaxiAnalyzingEventEnum
  id?: number
  className?: string
  children?: string | JSX.Element
  $underline?: boolean
  $greenVariant?: boolean
}

export const LinkForCallTaxi: FC<Props> = ({
  lat,
  lng,
  event,
  id,
  className,
  children,
  $underline = false,
  $greenVariant = false,
}) => {
  const getParameters = () => {
    if (event === CallYaTaxiAnalyzingEventEnum.HotelPage) {
      return {hotelId: id}
    }
    if (event === CallYaTaxiAnalyzingEventEnum.BookingDetails) {
      return {bookingId: id}
    }
    if (event === CallYaTaxiAnalyzingEventEnum.MyBookings) {
      return {bookingId: id}
    }

    return null
  }

  const callTaxiUrl = getUrlForCallTaxi(lat, lng)

  const handleSendAnalytics = () => {
    if (getParameters() === null) {
      return null
    }
    return services.common.analytics.pushCallYaTaxi(
      event,
      getParameters() as YaTaxiEventParams,
    )
  }

  if (!lat || !lng || !getParameters()) {
    return null
  }

  return (
    <StyledLink
      className={className}
      href={callTaxiUrl}
      target="_blank"
      onClick={handleSendAnalytics}
      $greenVariant={$greenVariant}
      $underline={$underline}
    >
      <Icon name="call_taxi" width={24} height={24} />

      {children}
    </StyledLink>
  )
}
