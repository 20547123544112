import {RatePaymentTypeEnum} from '@api/gql/generate-types'

type PaymentTypeInformationValue = {
  label: string
  tooltipLabel: string
}

export const HOTEL_SCENARIO_RATE_PAYMENT_TYPE_INFORMATION: Record<
  RatePaymentTypeEnum,
  PaymentTypeInformationValue
> = {
  [RatePaymentTypeEnum.PayNow]: {
    label: 'rates.price.payment_type.pay_now.label',
    tooltipLabel: 'rates.price.payment_type.pay_now.tooltip.label',
  },
  [RatePaymentTypeEnum.PayBeforeCancellation]: {
    label: 'rates.price.payment_type.pay_online.label',
    tooltipLabel: 'rates.price.payment_type.pay_online.tooltip.label',
  },
  [RatePaymentTypeEnum.PayOnSpot]: {
    label: 'rates.price.payment_type.pay_on_spot.label',
    tooltipLabel: 'rates.price.payment_type.pay_on_spot.tooltip.label',
  },
}
