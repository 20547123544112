import {BookingStatusEnum} from '@/library/api/gql/generate-types'

export const INITIAL_MAP_ZOOM = 18
export const LAYOUT_MAX_WIDTH = 900
export const SECONDS_IN_MINUTE = 60
export const SECONDS_IN_FORTY_FIVE_MINUTES = 2700
export const SOCIAL_ICON_SIZE = 28
// Чтобы при 40 сек, например, показывалось не 0 минут, а 1. Библиотека возвращает полные минуты
export const COUNTDOWN_MINUTES_OFFSET = 1

export const BOOKING_SUCCESSFUL_STATUSES = [
  BookingStatusEnum.Booked,
  BookingStatusEnum.Completed,
]
