import Router from 'next/router'
import {interval, mergeMap, Observable, Subscription, takeWhile} from 'rxjs'

/**
 * Тип функции, возвращающей булево значение.
 */
type TakeWhile = () => boolean

/**
 * Тип функции, возвращающей обещание (Promise) без результата (void).
 */
type MergeMap = () => Promise<void>

/**
 * Тип функции, не принимающей аргументов и не возвращающей значения.
 */
type Finalize = () => void

/**
 * Интерфейс для передачи параметров конструктору класса CLongPolling.
 */
export type PollingConstructorPayload = {
  duration: number
  takeWhile: TakeWhile
  mergeMap: MergeMap
  finalize: Finalize
  abortController: AbortController
  // Указывать в секундах
  executionTimeDuration?: number
}

/**
 * Класс CLongPolling представляет собой реализацию длинного опроса (long polling).
 *
 * @class
 * @description Длинные опросы – это самый простой способ поддерживать постоянное соединение
 * с сервером, не используя при этом никаких специфических протоколов (типа WebSocket или Server Sent Events).
 * @description Если вы хотите использовать данный класс внутри функциональных компонентов, вам понадобится useRef для работы с классом.
 * @example
 * ```tsx
 * const longPollingReference = useRef<CLongPolling | null>(null);
 * useEffect(() => {
 *    const LongPolling = longPollingReference.current;
 *
 *     if (hotel && !LongPolling) {
 *       longPollingReference.current = new CLongPolling({
 *         duration: 5000,
 *         takeWhile: () => hotel.active,
 *         mergeMap: async () => {
 *           // Логика обработки опроса
 *         },
 *         finalize: () => {
 *           // Логика завершения опроса
 *         },
 *         abortController: new AbortController(),
 *       });
 *       longPollingReference.current?.start();
 *     }
 *
 *     return () => {
 *     const LongPolling = longPollingReference.current;
 *
 *       if (LongPolling) {
 *         LongPolling.stop();
 *         LongPolling.subscribe?.unsubscribe();
 *         longPollingReference.current = null;
 *       }
 *     }
 *  }, [hotel])
 * ```
 * @see {@link https://learn.javascript.ru/long-polling}
 */
export class LongPolling {
  /**
   * Период опроса, представляющий собой поток значений типа number (в миллисекундах).
   */
  private readonly interval: Observable<PollingConstructorPayload['duration']>

  /**
   * Объект управления прерывания отправленных запросов в опросах (AbortController).
   */
  private readonly abortController: PollingConstructorPayload['abortController']

  /**
   * Условие продолжения опроса, говорит о том, продолжать ли опрос.
   */
  private readonly takeWhile: PollingConstructorPayload['takeWhile']

  /**
   * Функция обработки элементов потока, отрабатывает каждый раз, когда takeWhile возвращает true (mergeMap).
   */
  private readonly mergeMap: PollingConstructorPayload['mergeMap']

  /**
   * Функция завершения опроса, выполняется после того, как takeWhile вернул false (finalize).
   */
  private readonly finalize: PollingConstructorPayload['finalize']

  private readonly executionTimeDuration: PollingConstructorPayload['executionTimeDuration']

  /**
   * Конструктор класса CLongPolling.
   * @param payload Параметры конструктора.
   * @param payload.duration Период опроса, представляющий собой поток значений типа number (в миллисекундах).
   * @param payload.takeWhile Условие продолжения опроса
   * @param payload.mergeMap Функция обработки элементов потока
   * @param payload.finalize Функция завершения опроса
   * @param payload.abortController Объект управления прерывания отправленных запросов в опросах
   */
  constructor(payload: PollingConstructorPayload) {
    this.interval = interval(payload.duration)
    this.takeWhile = payload.takeWhile
    this.mergeMap = async () => {
      payload.mergeMap()
      if (this.takeUntil()) {
        this.stop()
        this.finalize()
      }
    }
    this.executionTimeDuration = payload.executionTimeDuration

    this.finalize = () => {
      payload.finalize()
      Router.events.off(
        'routeChangeStart',
        this.handleRouteChangeStart.bind(this),
      )
    }

    this.abortController = payload.abortController

    Router.events.on('routeChangeStart', this.handleRouteChangeStart.bind(this))
  }

  /**
   * Подписка на опрос.
   */
  private _subscribe: Subscription | null = null

  /**
   * Получение текущей подписки.
   */
  public get subscribe(): Subscription | null {
    return this._subscribe
  }

  /**
   * Запуск опроса.
   */
  public start(): void {
    if (!this.isSubscribeExist()) {
      const subscribe = this.interval
        .pipe(takeWhile(this.takeWhile), mergeMap(this.mergeMap))
        .subscribe({
          complete: () => {
            this.finalize()
          },
        })

      this.setExecutionTime(Date.now())
      this.setSubscribe(subscribe)
    }
  }

  /**
   * Остановка опроса.
   */
  public stop(): void {
    if (this.isSubscribeExist()) {
      this.subscribe.unsubscribe()
      this.setSubscribe(null)
    }
  }

  /**
   * Обработчик события начала изменения маршрута.
   */
  private handleRouteChangeStart(): void {
    this.stop()
    this.abortController.abort(
      'CLongPolling aborted all requests that could have been called due to a transition to another page',
    )
  }

  /**
   * Установка подписки.
   * @param subscribe Объект подписки.
   */
  private setSubscribe(subscribe: Subscription | null): void {
    this._subscribe = subscribe
  }

  /**
   * Проверка наличия подписки.
   * @returns true, если подписка существует; false в противном случае.
   */
  private isSubscribeExist(): this is {subscribe: Subscription} {
    return Boolean(this.subscribe)
  }

  private _executionTime: number | null = null

  public get executionTime(): number | null {
    return this._executionTime
  }

  public setExecutionTime(time: number | null): void {
    this._executionTime = time
  }

  private takeUntil(): boolean {
    return this.isExecutionTimeFinish()
  }

  private isExecutionTimeFinish(): boolean {
    if (!this.executionTime || !this.executionTimeDuration) {
      return false
    }

    return Date.now() - this.executionTime >= this.executionTimeDuration * 1000
  }
}
