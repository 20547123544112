import {store} from '@store/index'
import {GlobalHydrateService} from '@services/common/global-hydrate'
import type {MainPageProperties} from '@slices/main/lib/types'

type HydrationInitialData = Required<MainPageProperties>['hydrationData']

export class HomePageService extends GlobalHydrateService<HydrationInitialData> {
  public hydrate(initialData: HydrationInitialData) {
    if (initialData.popularCityDestinations) {
      store().popularCityDestinations.setPopularCityDestinations(
        initialData.popularCityDestinations,
      )
    }
    super.hydrate(initialData)
  }
}
