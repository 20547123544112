import {Button, CircularLoader, Text} from '@/library/components/ui'
import {Modal} from '@/library/components/ui/modal'
import {services} from '@/library/services'
import styled from '@emotion/styled'
import useTranslation from 'next-translate/useTranslation'
import {useState, type FC} from 'react'
import {BALANCE_UPDATE_DELAY_AFTER_CANCELLATION} from '../lib/constants'

const StyledButton = styled(Button)`
  text-transform: uppercase;
  width: 100%;
`

const ModalTitle = styled(Text)`
  margin-top: 20px;
`

const ButtonsWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 16px;
  align-items: center;
`

const CloseModalButton = styled(Button)`
  text-transform: uppercase;
  width: calc(50% - 8px);
`

const ActionButton = styled(Button)`
  text-transform: uppercase;
  width: calc(50% - 8px);
`

interface CancelButtonProps {
  className?: string
  id: number
}

export const CancelButton: FC<CancelButtonProps> = ({className, id}) => {
  const {t} = useTranslation('profile.bookings')
  const [cancelIsLoading, setCancelIsLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const handleModalOpen = () => setShowModal(true)

  const handleModalClose = () => setShowModal(false)

  const handleCancel = async () => {
    setShowModal(false)
    setCancelIsLoading(true)
    try {
      await services.pages.profile.bookings.cancelBooking(id)

      // На бэкенде все идет через очереди, поэтому с делеем запрашиваем баланс
      setTimeout(() => {
        services.common.user.updateBalance()
      }, BALANCE_UPDATE_DELAY_AFTER_CANCELLATION)
    } catch (error) {
      console.error(error)
    } finally {
      setCancelIsLoading(false)
    }
  }

  return (
    <>
      <StyledButton
        className={className}
        paddingSize="SM"
        onClick={handleModalOpen}
        variant="secondary"
      >
        {cancelIsLoading ? <CircularLoader size={32} /> : t('cancel')}
      </StyledButton>
      <Modal onCancel={handleModalClose} open={showModal}>
        <ModalTitle size="boldM">{t('are_you_sure_cancel')}</ModalTitle>
        <ButtonsWrapper>
          <CloseModalButton onClick={handleModalClose} variant="secondary">
            {t('back')}
          </CloseModalButton>
          <ActionButton onClick={handleCancel}>{t('cancel')}</ActionButton>
        </ButtonsWrapper>
      </Modal>
    </>
  )
}
