import {Text} from '@/library/components/ui'
import styled from '@emotion/styled'

export const AuthForm = styled.form`
  width: max(330px, 100%);
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 30px;
`

export const ServerErrorMessage = styled(Text)`
  color: ${({theme}) => theme.palette.textError};
`

export const ServerErrorMessageWrapper = styled.div`
  height: 16px;
  margin-bottom: 5px;
  display: flex;
  justify-content: end;
  align-items: center;
`
