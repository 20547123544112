import type {ApplicationCustomTheme} from '@assets/theme/types'

export const DarkTheme: ApplicationCustomTheme = {
  palette: {
    // TEXT AND SVG COLORS
    textPrimary: '#323433',
    textSecondary: '#737875',
    textTertiary: '#DEDEDE',
    textQuaternary: '#FFFFFF',
    textAccentPrimary: '#FF5876',
    textAccentSecondary: '#EC4F6B',
    textAccentFivefold: '#FFACBB',
    textError: '#FA4B46',
    textPositive: 'rgb(30, 158, 30)',
    textPositive_2: '#1BAA76',
    textYellow: '#ae7d0a',
    textBlue: 'rgb(25, 130, 196)',
    textGray: '#5b5b6b',
    textLightGray: 'rgb(146, 146, 170)',
    textBrown: '#AE7D0A',
    textGreenPrimary: '#3FAC3F',
    textGreenSecondary: '#1E9E1E',

    // BACKGROUND COLORS
    backgroundPrimary: '#323433',
    backgroundSecondary: '#737875',
    backgroundTertiary: '#DEDEDE',
    backgroundTertiarySecondary: '#EFEFEF',
    backgroundTertiaryPrimary: '#F9F9F9',
    backgroundQuaternary: '#FFFFFF',
    backgroundQuinary: 'rgb(248, 249, 251)',
    backgroundAccentPrimary: '#FF5876',
    backgroundAccentSecondary: '#EC4F6B',
    backgroundAccentTertiary: '#C34158',
    backgroundYellow: '#FFC331',
    backgroundBlue: '#0057D2',
    backgroundPositive: '#1BAA75',
    backgroundPhone: '#F2F2F2',
    backgroundPromotionHeader:
      'linear-gradient(90deg, #FF5876 0%, #A61D84 152.45%)',

    backgroundLinearShadow:
      'linear-gradient(360deg,rgba(0, 0, 0, 0.6) 30.34%,rgba(0, 0, 0, 0.6) 30.34%,rgba(0, 0, 0, 0) 51.95%)',

    // Loader
    loaderOuterRingColor: '#FFEBEB',
    loaderInnerRingColor: '#FF5876',

    // BORDER COLORS
    borderPrimary: '#323433',
    borderSecondary: '#737875',
    borderTertiary: '#DEDEDE',
    borderQuaternary: '#FFFFFF',
    borderQuinary: 'rgb(248, 249, 251)',
    borderAccentPrimary: '#FF5876',

    // OUTLINE COLORS
    outlinePrimary: '#323433',
    outlineSecondary: '#737875',
    outlineTertiary: '#DEDEDE',
    outlineBackground: '#FFFFFF',
    outlineAccentPrimary: '#FF5876',

    // BOX SHADOW COLORS
    boxShadowPrimary: '#3d3d3d1a',
    boxShadowSecondary_1: 'rgba(0, 0, 0, 0.08)',
    boxShadowSecondary_2: 'rgba(0, 0, 0, 0.12)',
    boxShadowSecondary_3: 'rgba(0, 0, 0, 0.05)',

    // OVERLAY
    overlayPrimary: 'rgba(0, 0, 0, 0.5)',
    overlaySecondary: '#f6f6f6',

    progressRectBackground: '#E8E8E8',
    progressBarBackground: '#F6F7F8',
  },
  font: {
    main: '"Source Sans 3", sans-serif',
    secondary: '"Source Code Pro", monospace',
  },
  textSize: {
    small: '14px',
    medium: '16px',
    large: '20px',
    xl: '36px',
  },
  containerSize: {
    xl: '179px',
  },
  animations: {
    loader: 'loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite',
  },
}
