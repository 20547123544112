import {RightModal} from '@/library/components/ui'
import {services} from '@/library/services'
import {store} from '@/library/store'
import {observer} from 'mobx-react'
import {useWindowSize} from '@/library/hooks/use-window-size'
import {useEffect} from 'react'
import {useRouter} from 'next/router'
import {DesktopModalLayout} from './layouts/desktop-modal-layout'
import {TouchModalLayout} from './layouts/touch-modal-layout'
import {FULLSCREEN_MODE_WIDTH} from './lib/constants'

export const BookingModal = observer(() => {
  const router = useRouter()
  const windowSize = useWindowSize()
  const {isOpen} = store().bookingModal.modal
  const isFullScreen = windowSize <= FULLSCREEN_MODE_WIDTH

  useEffect(() => {
    services.pages.global.scenario.hotel.booking.getMinimumBookingAmount()
  }, [])

  useEffect(() => {
    if (!isOpen) {
      store().bookingModal.modal.reset()
    }
  }, [isOpen])

  return (
    <RightModal
      onCancel={() =>
        services.pages.global.scenario.hotel.booking.stopBooking(router)
      }
      isOpen={isOpen}
      isFullscreen={isFullScreen}
      closable
    >
      {isFullScreen ? <TouchModalLayout /> : <DesktopModalLayout />}
    </RightModal>
  )
})
