import styled from '@emotion/styled'
import {TRANSITION_STYLES} from '@/library/constants/css'
import {Button} from '../../button'

export const PrimaryClose = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 12px;
  right: 12px;
  background-color: transparent;
  width: 32px;
  height: 32px;
  padding: 0;

  svg {
    transition: color ${TRANSITION_STYLES};
  }

  &:hover {
    background-color: transparent;

    svg {
      color: ${({theme}) => theme.palette.textAccentPrimary};
    }
  }
`
