import {
  HOTEL_SCENARIO_ABOUT_HOTEL_ID,
  HOTEL_SCENARIO_FACILITIES_ID,
  HOTEL_SCENARIO_PLACE_ID,
  HOTEL_SCENARIO_RATES_ID,
  HOTEL_SCENARIO_REVIEWS_ID,
  HOTEL_SCENARIO_RULES_ID,
} from '@constants/dom-ids'

export enum HotelScenarioFooterMenuId {
  RATES = 'rates',
  ABOUT_HOTEL = 'about_hotel',
  REVIEWS = 'reviews',
  RULES = 'rules',
  FACILITIES = 'facilities',
  PLACE = 'place',
}

export type HotelScenarioFooterMenu = {
  id: string
  label: string
  href: string
}

export const HOTEL_SCENARIO_FOOTER_ITEM: HotelScenarioFooterMenu[] = [
  {
    id: HotelScenarioFooterMenuId.RATES,
    label: 'footer_bar.rates',
    href: HOTEL_SCENARIO_RATES_ID,
  },
  {
    id: HotelScenarioFooterMenuId.ABOUT_HOTEL,
    label: 'footer_bar.about_hotel',
    href: HOTEL_SCENARIO_ABOUT_HOTEL_ID,
  },
  {
    id: HotelScenarioFooterMenuId.REVIEWS,
    label: 'footer_bar.reviews',
    href: HOTEL_SCENARIO_REVIEWS_ID,
  },
  {
    id: HotelScenarioFooterMenuId.RULES,
    label: 'footer_bar.rules',
    href: HOTEL_SCENARIO_RULES_ID,
  },
  {
    id: HotelScenarioFooterMenuId.FACILITIES,
    label: 'footer_bar.facilities',
    href: HOTEL_SCENARIO_FACILITIES_ID,
  },
  {
    id: HotelScenarioFooterMenuId.PLACE,
    label: 'footer_bar.place',
    href: HOTEL_SCENARIO_PLACE_ID,
  },
]
