import {makeAutoObservable} from 'mobx'

type InitialStateType = {
  isOpen: boolean
  isLoading: boolean
  error: string
  isSuccess: boolean
  isSubscribed: boolean
}

const INITIAL_STATE: InitialStateType = {
  isOpen: false,
  isLoading: false,
  error: '',
  isSuccess: false,
  isSubscribed: false,
}

export class CEmailSubscriptionStore {
  constructor() {
    makeAutoObservable(this)
  }

  private _isSubscribed = INITIAL_STATE.isSubscribed

  public get isSubscribed(): InitialStateType['isSubscribed'] {
    return this._isSubscribed
  }

  private _isOpen = INITIAL_STATE.isOpen

  public get isOpen(): InitialStateType['isOpen'] {
    return this._isOpen
  }

  private _isLoading = INITIAL_STATE.isLoading

  public get isLoading(): InitialStateType['isLoading'] {
    return this._isLoading
  }

  private _isSuccess = INITIAL_STATE.isSuccess

  public get isSuccess(): InitialStateType['isSuccess'] {
    return this._isSuccess
  }

  private _error = INITIAL_STATE.error

  public get error(): InitialStateType['error'] {
    return this._error
  }

  public setIsOpen(isOpen: InitialStateType['isOpen']) {
    this._isOpen = isOpen
  }

  public setIsSuccess(isSuccess: InitialStateType['isSuccess']) {
    this._isSuccess = isSuccess
  }

  public setError(error: InitialStateType['error']) {
    this._error = error
  }

  public setIsSubscribed(isSubscribed: InitialStateType['isSubscribed']) {
    this._isSubscribed = isSubscribed
  }

  public setIsLoading(isLoading: InitialStateType['isLoading']) {
    this._isLoading = isLoading
  }
}
