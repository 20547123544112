import type {ReservedPromoCode} from '@/library/api/gql/generate-types'
import type {PromoCodeErrorCategories} from '@/slices/global'
import {makeAutoObservable} from 'mobx'

export class CBookingPromoCode {
  constructor() {
    makeAutoObservable(this)
  }

  private _isOpen = false

  private _promoCode = ''

  private _isLoading = false

  private _data: ReservedPromoCode | null = null

  private _error: PromoCodeErrorCategories | null = null

  public get isOpen(): boolean {
    return this._isOpen
  }

  public setIsOpen(flag: boolean): void {
    this._isOpen = flag
  }

  public get promoCode(): string {
    return this._promoCode
  }

  public setPromoCode(promoCode: string): void {
    this._promoCode = promoCode
  }

  public get isLoading(): boolean {
    return this._isLoading
  }

  public setIsLoading(flag: boolean) {
    this._isLoading = flag
  }

  public get data(): ReservedPromoCode | null {
    return this._data
  }

  public setData(data: ReservedPromoCode | null) {
    this._data = data
  }

  public get error(): PromoCodeErrorCategories | null {
    return this._error
  }

  public setError(error: PromoCodeErrorCategories | null) {
    this._error = error
  }

  public reset() {
    this.setIsOpen(false)
    this.setPromoCode('')
    this.setIsLoading(false)
    this.setData(null)
    this.setError(null)
  }
}
