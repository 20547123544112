import {DayPicker} from 'react-day-picker'
import {type ComponentProps, type FC} from 'react'
import styled from '@emotion/styled'
import {ru} from 'date-fns/locale'
import {isWeekend} from 'date-fns'
import {css, Global} from '@emotion/react'

const CustomStyles = css`
  .rdp:not([dir='rtl']) .rdp-day_range_start:not(.rdp-day_range_end) {
    border-radius: 6px 0 0 6px;
  }

  .rdp:not([dir='rtl']) .rdp-day_range_end:not(.rdp-day_range_start) {
    border-radius: 0 6px 6px 0;
  }
`

const StyledDayPicker = styled(DayPicker)`
  font-family: ${({theme}) => theme.font.main};

  .rdp-caption_label,
  .rdp-head_cell,
  .rdp-weeknumber,
  .rdp-day {
    font-weight: 500;
  }

  .rdp-button_reset
    .rdp-button
    .rdp-day
    .rdp-day_selected
    .rdp-day_range_end
    .is-day-weekend {
    color: green;
  }

  .rdp-head_cell {
    font-weight: 500;
  }

  .rdp-day_outside {
    opacity: 1;
  }

  .rdp-day_selected,
  .rdp-day_selected:focus-visible,
  .rdp-day_selected:hover {
    background-color: ${({theme}) => theme.palette.backgroundAccentPrimary};
    color: ${({theme}) => theme.palette.textQuaternary};
  }

  .rdp-day_selected {
    background-color: ${({theme}) => theme.palette.backgroundAccentPrimary};
    color: ${({theme}) => theme.palette.textQuaternary};
  }

  .rdp-day_range_start {
    border-radius: 0 6px 6px 0;
  }

  .rdp-day_range_middle {
    background-color: ${({theme}) => theme.palette.backgroundTertiary};
    color: ${({theme}) => theme.palette.textPrimary};
  }

  .rdp-day_range_end {
    border-radius: 6px 0 0 6px;
  }

  .is_day_weekend:not(.rdp-day_selected) {
    color: ${({theme}) => theme.palette.textAccentPrimary};
  }

  .rdp-day_today:not(.rdp-day_selected) {
    background-color: ${({theme}) => theme.palette.backgroundAccentPrimary};
    color: ${({theme}) => theme.palette.textQuaternary};
    transform: scale(0.6);
  }
`

type Props = ComponentProps<typeof DayPicker>

export const Calendar: FC<Props> = ({
  modifiers,
  modifiersClassNames,
  ...properties
}) => {
  return (
    <>
      <StyledDayPicker
        locale={ru}
        modifiers={{
          isDayWeekend: isWeekend,
          ...modifiers,
        }}
        modifiersClassNames={{
          isDayWeekend: 'is_day_weekend',
          ...modifiersClassNames,
        }}
        {...properties}
      />
      <Global styles={CustomStyles} />
    </>
  )
}
