import {makeAutoObservable} from 'mobx'
import type {GetBookingPaymentStatusQuery} from '@api/gql/generate-types'

type PaymentStatus = GetBookingPaymentStatusQuery['bookingPaymentCheck'] | null

export class CPaymentCheck {
  constructor() {
    makeAutoObservable(this)
  }

  private _bookingId: number | null = null

  public get bookingId(): number | null {
    return this._bookingId
  }

  public setBookingId(bookingId: number | null): void {
    this._bookingId = bookingId
  }

  private _transactionId: number | null = null

  public get transactionId(): number | null {
    return this._transactionId
  }

  public setTransactionId(transactionId: number | null): void {
    this._transactionId = transactionId
  }

  private _bookingAccessToken: string | null = null

  public get bookingAccessToken(): string | null {
    return this._bookingAccessToken
  }

  private _bookingHotelUrl: string | null = null

  public get bookingHotelUrl(): string | null {
    return this._bookingHotelUrl
  }

  public setBookingHotelUrl(hotelUrl: string | null): void {
    this._bookingHotelUrl = hotelUrl
  }

  public setBookingAccessToken(token: string | null): void {
    this._bookingAccessToken = token
  }

  private _paymentStatus: PaymentStatus = null

  public get paymentStatus(): PaymentStatus {
    return this._paymentStatus
  }

  public setPaymentStatus(paymentStatus: PaymentStatus): void {
    this._paymentStatus = paymentStatus
  }

  private _error: string | null = null

  public get error(): string | null {
    return this._error
  }

  public setError(error: string | null = null): void {
    this._error = error
  }

  public reset() {
    this.setError(null)
    this.setPaymentStatus(null)
    this.setBookingAccessToken(null)
    this.setBookingId(null)
    this.setTransactionId(null)
    this.setBookingHotelUrl(null)
  }
}
