import {type FC, type ReactNode, useEffect} from 'react'
import {useRouter} from 'next/router'
import {Routes} from '@/types/enums/routes'
import {CookieNames, deleteCookie} from '../utils/cookie'
import type {AuthUser} from '../api/gql/generate-types'
import {isUser} from '../utils/guards/business'

interface IProps {
  serverUser: AuthUser | null
  children: ReactNode
}

const whitelistPathNames = new Set([
  Routes.NOT_FOUND,
  Routes.INTERNAL_ERROR,
  Routes.SERVER_ERRROR,
])

export const AuthorizationProvider: FC<IProps> = ({serverUser, children}) => {
  const router = useRouter()

  useEffect(() => {
    // Если не получили юзера - токен невалидный или его нет, очищаем куки
    if (
      !isUser(serverUser) &&
      !whitelistPathNames.has(router.pathname as Routes)
    ) {
      deleteCookie(CookieNames.AUTHORIZATION)
      deleteCookie(CookieNames.TEMPORARY_AUTHORIZATION)
    }
  }, [serverUser, router.pathname])

  return <>{children}</>
}
