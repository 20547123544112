import {makeAutoObservable} from 'mobx'
import {ENVIRONMENT} from '@utils/guards/environment'

export const DEFAULT_ADULTS = 2

export default class CAdults {
  private readonly maxAdults: number = ENVIRONMENT.MAX_SEARCH_INPUT_ADULTS

  private readonly incrementAmountValue: number = 1

  private readonly decrementAmountValue: number = 1

  constructor() {
    makeAutoObservable(this)
  }

  private _amount = DEFAULT_ADULTS

  public get amount(): number {
    return this._amount
  }

  public setAmount(value: number) {
    this._amount = value
  }

  public clearAmount(): void {
    this._amount = 0
  }

  public incrementAmount(): void {
    if (this.amount + this.incrementAmountValue > this.maxAdults) {
      return
    }

    this._amount += this.incrementAmountValue
  }

  public decrementAmount(): void {
    if (this.amount - this.decrementAmountValue <= 0) {
      return
    }

    this._amount -= this.decrementAmountValue
  }
}
