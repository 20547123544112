import styled from '@emotion/styled'
import {type Option, Select} from '@ui/select'
import {observer} from 'mobx-react'
import {store} from '@store/index'
import useTranslation from 'next-translate/useTranslation'
import {MEDIA_DEVICE_QUERY} from '@assets/theme/media'

const Wrapper = styled.div`
  margin-bottom: 20px;
`

const StyledSelect = styled(Select)`
  max-width: 200px;

  @media ${MEDIA_DEVICE_QUERY.TABLET} {
    max-width: unset;
  }
`

const DEFAULT_OPTIONS: Option<boolean | null>[] = [
  {
    label: 'global.hotel:rates.filters.free_cancellation.all_variants',
    value: null,
  },
  {
    label:
      'global.hotel:rates.filters.free_cancellation.with_free_cancellation',
    value: true,
  },
]

export const HotelScenarioRatesFiltersFreeCancellation = observer(() => {
  const {freeCancellation} = store().pages.global.hotel.rates.filters
  const {t} = useTranslation('global.hotel')
  return (
    <Wrapper>
      <StyledSelect
        label={t('rates.filters.free_cancellation.label')}
        value={freeCancellation || DEFAULT_OPTIONS[0]}
        options={DEFAULT_OPTIONS}
        setValue={(option) =>
          store().pages.global.hotel.rates.filters.setFreeCancellation(option)
        }
      >
        free-cancellation
      </StyledSelect>
    </Wrapper>
  )
})
