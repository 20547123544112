import type {FC} from 'react'
import {Text} from '@/library/components/ui'
import styled from '@emotion/styled'
import useTranslation from 'next-translate/useTranslation'

const Wrapper = styled.div``

const CheckInTitle = styled(Text)`
  color: ${({theme}) => theme.palette.textPrimary};
  margin-bottom: 8px;
`

const ListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 6px;
  margin-bottom: 7px;
  border-bottom: 1px solid ${({theme}) => theme.palette.borderTertiary};
`

const Value = styled(Text)`
  margin-left: 8px;
`

interface CheckInTableProps {
  checkIn: string
  checkOut: string
  arrivalTime?: string
  className?: string
}

export const CheckInTable: FC<CheckInTableProps> = ({
  checkIn,
  checkOut,
  arrivalTime,
  className,
}) => {
  const {t} = useTranslation('profile.bookings')
  return (
    <Wrapper className={className}>
      <CheckInTitle size="boldS">{t('check_in_table_title')}</CheckInTitle>
      <ListItem>
        <Text size="S">{t('check_in')}</Text>
        {checkIn && arrivalTime ? (
          <Value size="S">
            {checkIn} {arrivalTime}
          </Value>
        ) : (
          <Value size="S">{checkIn}</Value>
        )}
      </ListItem>
      <ListItem>
        <Text size="S">{t('check_out')}</Text>
        <Value size="S">{checkOut}</Value>
      </ListItem>
    </Wrapper>
  )
}
