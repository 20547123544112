import type {
  DynamicGlobalPageProperties,
  ScenarioProperties,
} from '@slices/global/lib/scenarios/index'
import {api} from '@api/index'
import {DEFAULT_FIRST_ITEMS_HOTEL_REVIEWS} from '@slices/profile/balance/lib/constants'
import {DEFAULT_PAGE} from '@constants/common'

export const getHotelScenario = async (
  headers: Headers,
  info: DynamicGlobalPageProperties['info'],
  hotelId: number,
): Promise<ScenarioProperties> => {
  const {hotel, hotelReviews} = await api.hotel.getById({
    headers,
    variables: {
      id: hotelId,
      first: DEFAULT_FIRST_ITEMS_HOTEL_REVIEWS,
      page: DEFAULT_PAGE,
    },
  })
  const similarHotels = await api.hotel.getSimilarHotels({
    headers,
    variables: {
      hotelId,
    },
  })

  return {
    properties: {
      info,
      hotel,
      hotelReviews,
      similarHotels,
    },
  }
}
