import {services as servicesBuilder} from '@services/index'
import {AffiliatePaths, ProfilePaths, Routes} from '@/types/enums/routes'
import type {GlobalPageService} from '@services/pages/global'
import type {HomePageService} from '@services/pages/home'
import type {NotFoundPageService} from '@services/pages/not-found'
import {createStore} from '@store/index'
import {isServer} from '@utils/next'

const pages: Record<
  string,
  GlobalPageService | HomePageService | NotFoundPageService
> = {
  [Routes.GLOBAL]: servicesBuilder.pages.global,
  [Routes.MAIN]: servicesBuilder.pages.home,
  [Routes.NOT_FOUND]: servicesBuilder.pages.notFound,
  [Routes.INTERNAL_ERROR]: servicesBuilder.pages.internalError,
  [Routes.BOOKING]: servicesBuilder.pages.booking,
  [Routes.EMAIL_CONFIRM]: servicesBuilder.pages.authorization.emailConfirm,
  [Routes.SUBSCRIPTION]: servicesBuilder.pages.subscription,
  [Routes.TEMPORARY_SESSION]: servicesBuilder.pages.temporarySession,
  [Routes.SUPPORT]: servicesBuilder.pages.support,
  [Routes.ABOUT]: servicesBuilder.pages.about,
  [Routes.PARTNER]: servicesBuilder.pages.partner,
  [Routes.PAYMENT_CHECK]: servicesBuilder.pages.payment.check,
  [ProfilePaths.BALANCE]: servicesBuilder.pages.profile.balance,
  [ProfilePaths.BOOKINGS]: servicesBuilder.pages.profile.bookings,
  [ProfilePaths.FAVORITES]: servicesBuilder.pages.profile.favorites,
  [ProfilePaths.PASSWORD]: servicesBuilder.pages.profile.password,
  [ProfilePaths.REVIEWS]: servicesBuilder.pages.profile.reviews,
  [ProfilePaths.SETTINGS]: servicesBuilder.pages.profile.settings,
  [AffiliatePaths.INVITES]: servicesBuilder.pages.affiliate.invites,
  [AffiliatePaths.INDEX]: servicesBuilder.pages.affiliate.main,
  [AffiliatePaths.REFERRALS]: servicesBuilder.pages.affiliate.referrals,
  [AffiliatePaths.STATISTICS]: servicesBuilder.pages.affiliate.statistics,
  [AffiliatePaths.WIDGETS]: servicesBuilder.pages.affiliate.widgets,
  [AffiliatePaths.WITHDRAW]: servicesBuilder.pages.affiliate.withdraw,
  [Routes.MOBILE_APP]: servicesBuilder.pages.mobileApp,
}

export function hydrateStore(pathname: string, initialData?: any): void {
  if (!initialData) {
    return
  }

  const hydratePageService = pages[pathname]

  if (!hydratePageService) {
    throw new Error(
      'Service page required, if exist hydration data from getServerSideProps',
    )
  }

  if (!('hydrate' in hydratePageService)) {
    throw new Error(
      'Hydrate method required, if exist hydration data from getServerSideProps',
    )
  }

  if (typeof hydratePageService.hydrate === 'function') {
    if (isServer()) {
      createStore()
    }
    hydratePageService.hydrate(initialData)
  }
}
