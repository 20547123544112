import {store} from '@store/index'
import {api} from '@api/index'
import type {PaymentMethod} from '@api/gql/generate-types'
import {BookingPaymentModalPollingPaymentStatusService} from '@services/components/booking-payment-modal/polling-payment-status'
import {services} from '@services/index'
import {errorService} from '@services/common/error'
import {CookieNames, setCookie} from '@utils/cookie'

export class BookingPaymentModalService {
  private get state() {
    return store().bookingPaymentModal
  }

  public readonly pollingPaymentStatus: BookingPaymentModalPollingPaymentStatusService =
    new BookingPaymentModalPollingPaymentStatusService()

  public loadPaymentMethods() {
    this.state.setIsLoadingPaymentMethods(true)

    return api.payment
      .getActivePaymentMethods({})
      .then((methods) => {
        if (methods) {
          this.state.setPaymentMethods(methods)
        }

        return methods
      })
      .catch((error) => {
        const firstError = errorService.getFirstErrorText(error)
        this.state.setError(firstError)
      })
      .finally(() => this.state.setIsLoadingPaymentMethods(false))
  }

  public openPaymentModal(
    bookingId: number,
    accessToken: string,
    bookingHotelUrl?: string | null,
  ) {
    this.state.setBookingId(bookingId)
    this.state.setBookingAccessToken(accessToken)
    if (bookingHotelUrl) {
      this.state.setBookingHotelUrl(bookingHotelUrl)
    }
    this.state.setIsOpen(true)
  }

  public async createPaymentTransaction(paymentMethod: PaymentMethod) {
    if (!this.state.bookingId) {
      throw new Error('bookingId required exist in store')
    }

    if (!this.state.bookingAccessToken) {
      throw new Error('bookingAccessToken required exist in store')
    }

    this.state.setIsLoadingTransaction(true)
    api.booking
      .createBookingPaymentTransaction({
        variables: {
          bookingId: this.state.bookingId,
          paymentMethod,
          bookingAccessToken: this.state.bookingAccessToken,
        },
      })
      .then((transaction) => {
        if (transaction) {
          this.state.setTransaction(transaction)
          setCookie(CookieNames.TRANSACTION_ID, String(transaction.id))
        }
      })
      .catch((error) => {
        const firstError = errorService.getFirstErrorText(error)
        this.state.setError(firstError)
      })
      .finally(() => this.state.setIsLoadingTransaction(false))
  }

  public pushPaymentAnalytic() {
    if (window.location.pathname.startsWith('/booking')) {
      services.common.analytics.pushPayFromAccount()
    } else {
      services.common.analytics.pushPay()
    }
  }
}
