import {type FC, type HTMLAttributes, useRef, useState} from 'react'
import styled from '@emotion/styled'
import {Icon} from '@ui/icon'
import useTranslation from 'next-translate/useTranslation'
import {css} from '@emotion/react'
import {TRANSITION_STYLES} from '@/library/constants/css'
import {useOutsideClick} from '@hooks/use-outside-click'

const Wrapper = styled.div`
  position: relative;
`

const InputWrapper = styled.div<{$disabled?: boolean}>`
  height: 48px;
  padding: 15px;
  gap: 10px;
  border-radius: 12px;
  border: 1px solid ${({theme}) => theme.palette.borderTertiary};
  background-color: ${({theme}) => theme.palette.backgroundQuaternary};
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  transition: 0.3s border ease-in-out;
  // box-shadow: 0px 4px 6px 0px ${({theme}) => theme.palette.boxShadowPrimary};

  &:hover {
    border: 1px solid ${({theme}) => theme.palette.borderPrimary};
  }

  ${({$disabled}) =>
    $disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.4;
    `}
`

const InputLabel = styled.div`
  font-family: ${({theme}) => theme.font.main};
  font-size: 16px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0.02em;
  color: ${({theme}) => theme.palette.textPrimary};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 80%;
`

const InputArrowWrapper = styled.div<{$isOpen: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(0deg);
  transition: transform 0.2s ease-in-out;

  ${({$isOpen}) =>
    $isOpen &&
    css`
      transform: rotate(180deg);
    `}
`

const ListWrapper = styled.ul`
  position: absolute;
  z-index: 9;
  width: 100%;
  margin-top: 8px;
  background-color: ${({theme}) => theme.palette.backgroundQuaternary};
  border: 1px solid ${({theme}) => theme.palette.borderTertiary};
  // box-shadow: 0 4px 6px 0 ${({theme}) => theme.palette.boxShadowPrimary};
  border-radius: 12px;
  max-height: 350px;
  overflow: auto;
  opacity: 0;
  animation: popover 0.5s forwards;

  ::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }
`

const ListItem = styled.li<{$selected: boolean}>`
  font-family: ${({theme}) => theme.font.main};
  font-size: 14px;
  border-bottom: 1px solid ${({theme}) => theme.palette.borderTertiary};
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0.02em;
  text-align: left;
  padding: 15px;
  cursor: pointer;
  transition: 0.1s background-color ease-in-out;
  color: ${({theme}) => theme.palette.textPrimary};

  &:hover {
    background-color: ${({theme}) => theme.palette.backgroundTertiary};
  }

  ${({$selected, theme}) =>
    $selected &&
    css`
      background-color: ${theme.palette.backgroundAccentPrimary};
      color: ${theme.palette.textQuaternary};

      &:hover {
        background-color: ${theme.palette.backgroundAccentSecondary};
      }
    `}
`

const Label = styled.label`
  width: fit-content;
  cursor: pointer;

  position: absolute;

  background-color: ${({theme}) => theme.palette.backgroundQuaternary};
  z-index: 1;
  font-weight: 400;
  color: ${({theme}) => theme.palette.textSecondary};
  transition:
    top ${TRANSITION_STYLES},
    left ${TRANSITION_STYLES},
    opacity ${TRANSITION_STYLES};
  top: -12px;
  left: 10px;
  opacity: 1;
`

export type Option<T = any> = {
  label: string
  value: T
}

interface Props extends HTMLAttributes<HTMLDivElement> {
  value: Option
  setValue: (option: Option) => void
  options: Option[]
  label?: string
  disabled?: boolean
}

export const Select: FC<Props> = ({
  value,
  setValue,
  options,
  label,
  disabled,
  ...properties
}) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const [isOpen, setIsOpen] = useState(false)
  const {t} = useTranslation()

  useOutsideClick(
    ref,
    () => {
      setIsOpen(false)
    },
    isOpen,
  )

  const onInputClick = () => {
    if (disabled) {
      return
    }

    setIsOpen((previous) => !previous)
  }

  const onItemClick = (option: Option) => {
    if (disabled) {
      return
    }

    setIsOpen(false)
    setValue(option)
  }

  return (
    <Wrapper {...properties} ref={ref}>
      <InputWrapper
        $disabled={disabled}
        className="input-wrapper"
        onClick={onInputClick}
      >
        <Label>{label}</Label>
        {value?.label ? (
          <InputLabel className="input-label">{t(value.label)}</InputLabel>
        ) : null}
        <InputArrowWrapper $isOpen={isOpen}>
          <Icon name="arrow_small" width={24} height={24} />
        </InputArrowWrapper>
      </InputWrapper>
      {isOpen && (
        <ListWrapper className="list-item">
          {options.map((option) => (
            <ListItem
              key={`select-option-id-${option.value}-${option.label}`}
              $selected={value.value === option.value}
              onClick={() => onItemClick(option)}
            >
              {t(option.label)}
            </ListItem>
          ))}
        </ListWrapper>
      )}
    </Wrapper>
  )
}

Select.displayName = 'Select'
