export const ROOT_ID = '__next'
export const SEARCH_SCENARIO_HOTELS_CARDS_ID = 'hotels-cards'

// global scenario
export const SEARCH_SCENARIO_FILTERS_ID = 'filters-desktop'

export const HOTEL_SCENARIO_MAIN_INFORMATION_ID = 'main-information'
export const HOTEL_SCENARIO_RATES_ID = 'rates'
export const HOTEL_SCENARIO_REVIEWS_ID = 'reviews'
export const HOTEL_SCENARIO_ABOUT_HOTEL_ID = 'about-hotel'
export const HOTEL_SCENARIO_RULES_ID = 'rules'
export const HOTEL_SCENARIO_FACILITIES_ID = 'facilities'
export const HOTEL_SCENARIO_PLACE_ID = 'place'
export const HOTEL_SCENARIO_SIMILAR_HOTELS_ID = 'similar-hotels'
export const HOTEL_SCENARIO_FAQ_ID = 'hotel-faq'
