import {makeAutoObservable} from 'mobx'
import {DEFAULT_PAGE} from '@constants/common'

export class CProfileReviewsWithReview {
  constructor() {
    makeAutoObservable(this)
  }

  private _data: UserCompleteBookingData | null = null

  public get data(): UserCompleteBookingData | null {
    return this._data
  }

  public setData(data: UserCompleteBookingData | null): void {
    this._data = data
  }

  private _paginatorInfo: UserCompleteBookingPaginatorInfo | null = null

  public get paginatorInfo(): UserCompleteBookingPaginatorInfo | null {
    return this._paginatorInfo
  }

  public setPaginatorInfo(data: UserCompleteBookingPaginatorInfo | null): void {
    this._paginatorInfo = data
  }

  private _page: number = DEFAULT_PAGE

  public get page(): number {
    return this._page
  }

  private _isLoading = false

  public get isLoading(): boolean {
    return this._isLoading
  }

  public setPage(page: number): void {
    this._page = page
  }

  public setIsLoading(flag: boolean): void {
    this._isLoading = flag
  }

  public getByReviewId(
    id: number,
  ): UserCompleteBookingData[number] | undefined {
    return this._data?.find((review) => review.hotelReview?.id === id)
  }

  public updateById(
    reviewId: number,
    data: UserCompleteBookingData[number]['hotelReview'],
  ): void {
    if (!this._data) {
      return
    }

    const elementModifiedIndex = this._data.findIndex(
      (review) => review.hotelReview?.id === reviewId,
    )

    if (this._data[elementModifiedIndex]) {
      this._data[elementModifiedIndex].hotelReview = data
    }
  }

  public add(data: UserCompleteBookingData[number]) {
    this._data = [data, ...(this._data || [])]
  }
}
