import {makeAutoObservable} from 'mobx'
import {CAffiliateWithdrawModal} from '@store/pages/affiliate/withdraw/modal'
import {DEFAULT_PAGE} from '@constants/common'

export class CAffiliateWithdraw {
  public readonly modal: CAffiliateWithdrawModal = new CAffiliateWithdrawModal()

  constructor() {
    makeAutoObservable(this)
  }

  private _balance = 0

  public get balance(): number {
    return this._balance
  }

  public setBalance(balance: number): void {
    this._balance = balance
  }

  private _data: AffiliateWithdrawData | null = null

  public get data(): AffiliateWithdrawData | null {
    return this._data
  }

  private _paginatorInfo: AffiliateWithdrawPaginatorInfo | null = null

  public get paginatorInfo(): AffiliateWithdrawPaginatorInfo | null {
    return this._paginatorInfo
  }

  private _page: number = DEFAULT_PAGE

  public get page(): number {
    return this._page
  }

  public setPage(page: number) {
    this._page = page
  }

  private _isLoading = false

  public get isLoading(): boolean {
    return this._isLoading
  }

  public setIsLoading(flag: boolean): void {
    this._isLoading = flag
  }

  public setData(data: AffiliateWithdrawData | null): void {
    this._data = data
  }

  public setPaginatorInfo(data: AffiliateWithdrawPaginatorInfo | null): void {
    this._paginatorInfo = data
  }
}
