import {useDeviceDetection} from '@hooks/use-device-detection'
import {type FC} from 'react'
import type {TitleType} from '@ui/typography/title'
import {PopularCityDestinationsTouch} from './ui/touch'
import {PopularCityDestinationsDesktop} from './ui/desktop'

interface PopularCityDestinationsProps {
  className?: string
  headlineType?: TitleType
}

export const PopularCityDestinations: FC<PopularCityDestinationsProps> = ({
  className,
  headlineType,
}) => {
  const isTouch = useDeviceDetection('touch')

  if (isTouch) {
    return (
      <PopularCityDestinationsTouch
        className={className}
        headlineType={headlineType}
      />
    )
  }

  return (
    <PopularCityDestinationsDesktop
      className={className}
      headlineType={headlineType}
    />
  )
}
