import styled from '@emotion/styled'
import {Link} from '@/library/components/ui'
import {useDeviceDetection} from '@hooks/use-device-detection'
import {Icon} from '@/library/components/ui/icon'
import {useWindowSize} from '@/library/hooks/use-window-size'
import {css} from '@emotion/react'
import {ENVIRONMENT} from '@/library/utils/guards/environment'
import {TRANSITION_STYLES} from '@/library/constants/css'
import {MOBILE_LAYOUT_BREAKPOINT} from '../../lib/constants'

const Wrapper = styled.nav<{$isMobile: boolean}>`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  ${({$isMobile}) =>
    $isMobile &&
    css`
      gap: 16px;
    `}
`

const LinkWrapper = styled.div<{$isMobile: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({$isMobile}) => ($isMobile ? 48 : 38)}px;
  height: ${({$isMobile}) => ($isMobile ? 48 : 38)}px;
`

const StyledLink = styled(Link)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid ${({theme}) => theme.palette.borderTertiary};
  border-radius: 12px;

  svg {
    transition: color ${TRANSITION_STYLES};
  }

  &:hover {
    svg {
      color: ${({theme}) => theme.palette.textAccentPrimary};
    }
  }
`

export const SocialLinks = () => {
  const isMobile = useDeviceDetection('mobile')
  const windowSize = useWindowSize()
  const isMobileLayout = isMobile || windowSize < MOBILE_LAYOUT_BREAKPOINT

  return (
    <Wrapper $isMobile={isMobileLayout}>
      <LinkWrapper $isMobile={isMobileLayout}>
        <StyledLink href={ENVIRONMENT.VK}>
          <Icon name="vk" width={21} height={12} />
        </StyledLink>
      </LinkWrapper>

      <LinkWrapper $isMobile={isMobileLayout}>
        <StyledLink href={ENVIRONMENT.TG}>
          <Icon name="telegram" width={22} height={22} />
        </StyledLink>
      </LinkWrapper>

      <LinkWrapper $isMobile={isMobileLayout}>
        <StyledLink href={ENVIRONMENT.YOUTUBE}>
          <Icon name="youtube" width={22} height={16} />
        </StyledLink>
      </LinkWrapper>

      <LinkWrapper $isMobile={isMobileLayout}>
        <StyledLink href={ENVIRONMENT.DZEN}>
          <Icon name="dzen" width={22} height={16} />
        </StyledLink>
      </LinkWrapper>
    </Wrapper>
  )
}
