
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import App, { type AppContext, type AppInitialProps, type AppProps, } from 'next/app';
import ThemeProvider from '@providers/theme';
import '@styles/animations.css';
import 'react-day-picker/dist/style.css';
import { services } from '@services/index';
import { type Utilities } from '@/types/utilities';
import '@animxyz/core';
import { Footer } from '@/library/components/features/footer';
import { GoogleTagScript, HotjarScript, RoistatScript, YandexMetricaScript, } from '@components/scripts';
import { AuthorizationModal } from '@/library/components/features/authorization-modal';
import dynamic from 'next/dynamic';
import { AuthorizationProvider } from '@/library/providers/authorization';
import { hydrateStore } from '@store/hydrate';
import { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import ErrorBoundary from '@/library/providers/error';
import PromotionHeader from '@/library/components/features/promotion-header';
import { createEmotionCache } from '@utils/emotion/create-emotion-cache';
import { CacheProvider } from '@emotion/react';
import type { EmotionCache } from '@emotion/cache';
import { RoutingProgressBar } from '@components/features/routing-progress-bar';
import { scrollMainWrapper } from '@utils/dom/scroll';
import { ENVIRONMENT } from '@utils/guards/environment';
import { UtmProvider } from '@providers/utm';
import { usePathname, useSearchParams } from 'next/navigation';
import { MailCounterScript } from '@/library/components/scripts/mail-counter';
const TouchFooterActions = dynamic(() => import('@components/features/touch-footer-actions').then((result) => result.TouchFooterActions));
const clientEmotionCache = createEmotionCache();
export interface AppGlobalProps {
    utilities?: Utilities;
    pathname?: string;
    emotionCache?: EmotionCache;
}
function MyApp({ Component, pageProps, utilities, pathname, emotionCache, }: AppProps & AppGlobalProps) {
    const router = useRouter();
    const store = useRef<boolean | null>(null);
    const routerPathname = usePathname();
    const searchParameters = useSearchParams();
    if (!store.current && pathname) {
        hydrateStore(pathname, { ...pageProps.hydrationData, utilities });
        store.current = true;
    }
    const handleResetStoreHydrate = (url: string, { shallow }: {
        shallow: boolean;
    }) => {
        if (!shallow) {
            store.current = false;
        }
    };
    const handleScrollTop = () => {
        if (!window.location.hash) {
            scrollMainWrapper();
        }
    };
    useEffect(() => {
        router.events.on('routeChangeStart', handleResetStoreHydrate);
        return () => {
            router.events.off('routeChangeStart', handleResetStoreHydrate);
        };
    }, [router.events]);
    useEffect(() => {
        handleScrollTop();
    }, [routerPathname, searchParameters]);
    useEffect(() => {
        services.common.analytics.pushUserVisit();
        services.common.analytics.createAffiliateLinkVisit();
    }, []);
    if (!store.current) {
        return null;
    }
    return (<CacheProvider value={emotionCache || clientEmotionCache}>
      <ThemeProvider>
        <UtmProvider>
          <AuthorizationProvider serverUser={pageProps.hydrationData?.user}>
            <YandexMetricaScript />
            <MailCounterScript />
            <HotjarScript />
            <GoogleTagScript />
            <RoistatScript />
            <RoutingProgressBar />
            <ErrorBoundary>
              {ENVIRONMENT.HEADER_PROMO_IS_DISPLAY && <PromotionHeader />}
              <Component {...pageProps}/>
              <Footer />
              <TouchFooterActions />
              <AuthorizationModal />
            </ErrorBoundary>
          </AuthorizationProvider>
        </UtmProvider>
      </ThemeProvider>
    </CacheProvider>);
}
MyApp.getInitialProps = async (context: AppContext): Promise<AppGlobalProps & AppInitialProps> => {
    const { ctx } = context;
    const { pathname } = ctx;
    const initialProps = await App.getInitialProps(context);
    const userAgent = services.nextContext.globalProps.getUserAgent(ctx);
    const origin = services.nextContext.globalProps.getOrigin(ctx);
    const utilities = {
        userAgent,
        origin,
    };
    return { ...initialProps, pathname, utilities };
};

    export default __appWithI18n(MyApp, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  