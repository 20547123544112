import type {Translate} from 'next-translate'
import {
  EtAvailability,
  EtDayPeriod,
  EtDestinationType,
  EtInclusion,
  EtInternetType,
  EtMealTypeEnum,
  EtMetapolicyAddFeeType,
  EtMetapolicyCheckInCheckOutType,
  EtPriceMethod,
  EtVisaSupport,
} from '@/library/api/gql/generate-types'
import type {IOstrovokRules} from 'slices/global/ui/hotel-scenario/rules/lib/types'
import type {
  OstrovokAddFeesItem,
  OstrovokCheckinCheckout,
  OstrovokChildren,
  OstrovokChildrenMeal,
  OstrovokDeposit,
  OstrovokInternet,
  OstrovokMeal,
  OstrovokNoShow,
  OstrovokTransfer,
} from './types'

export const checkAddFeesItem = (item: OstrovokAddFeesItem) => {
  return item.feeType && item.feeType !== EtMetapolicyAddFeeType.Unspecified
}

export const checkCheckinCheckoutItem = (item: OstrovokCheckinCheckout) => {
  const isCheckinCheckoutTypeSpecified =
    item.checkInCheckOutType &&
    item.checkInCheckOutType !== EtMetapolicyCheckInCheckOutType.Unspecified
  const isInclusionSpecified =
    item.inclusion && item.inclusion !== EtInclusion.Unspecified

  return isCheckinCheckoutTypeSpecified && (isInclusionSpecified || item.price)
}

export const checkNoShow = (noShow: OstrovokNoShow) => {
  return (
    noShow.availability !== EtAvailability.Unspecified &&
    noShow.availability &&
    noShow.dayPeriod &&
    noShow.dayPeriod !== EtDayPeriod.Unspecified
  )
}

export const checkChildrenItem = (children: OstrovokChildren) => {
  return (
    children.extraBed &&
    children.extraBed !== EtAvailability.Unspecified &&
    (children.ageEnd || children.ageStart)
  )
}

export const checkChildrenMeal = (childrenMeal: OstrovokChildrenMeal) => {
  return childrenMeal.ageStart || childrenMeal.ageEnd
}

export const checkDeposit = (deposit: OstrovokDeposit) => {
  const isDepositTypeSpecified =
    deposit.availability === EtAvailability.Available
  const isFixedPriceMethod =
    deposit.priceMethod === EtPriceMethod.Fixed &&
    deposit.price &&
    deposit.currency
  const isPercentPriceMethod =
    deposit.priceMethod === EtPriceMethod.Percent && deposit.price

  return Boolean(
    isDepositTypeSpecified && (isFixedPriceMethod || isPercentPriceMethod),
  )
}

export const checkInternet = (internet: OstrovokInternet) => {
  return internet.internetType !== EtInternetType.Unspecified
}

export const checkMeal = (meal: OstrovokMeal) => {
  return (
    meal.mealType !== EtMealTypeEnum.Unspecified &&
    meal.mealType !== EtMealTypeEnum.Nomeal
  )
}

export const checkTransfer = (transfer: OstrovokTransfer) => {
  return transfer.destinationType !== EtDestinationType.Unspecified
}

export const checkPolicies = (rules?: IOstrovokRules | null) => {
  if (!rules) {
    return null
  }

  return {
    showAddFees:
      rules.addFee &&
      rules.addFee.length > 0 &&
      rules.addFee?.some((fee) => checkAddFeesItem(fee)),
    showAdditionalBed: rules.extraBed && rules.extraBed.length > 0,
    showCheckinCheckout:
      rules.checkInCheckOut &&
      rules.checkInCheckOut.length > 0 &&
      rules.checkInCheckOut?.some((checkinCheckout) =>
        checkCheckinCheckoutItem(checkinCheckout),
      ),
    showChildren:
      rules.children &&
      rules.children.length > 0 &&
      rules.children?.some((childrenItem) => checkChildrenItem(childrenItem)),
    showChildrenMeal:
      rules.childrenMeal &&
      rules.childrenMeal.length > 0 &&
      rules.childrenMeal?.some((childrenMeal) =>
        checkChildrenMeal(childrenMeal),
      ),
    showCot: rules.cot && rules.cot.length > 0,
    showDeposit:
      rules.deposit &&
      rules.deposit.length > 0 &&
      rules.deposit?.some((deposit) => checkDeposit(deposit)),
    showInternet:
      rules.internet &&
      rules.internet.length > 0 &&
      rules.internet?.some((internet) => checkInternet(internet)),
    showMeal:
      rules.meal &&
      rules.meal.length > 0 &&
      rules.meal?.some((meal) => checkMeal(meal)),
    showNoShow: rules.noShow && checkNoShow(rules.noShow),
    showParking: rules.parking && rules.parking.length > 0,
    showPets: rules.pets && rules.pets.length > 0,
    showTransfer:
      rules.shuttle &&
      rules.shuttle.length > 0 &&
      rules.shuttle?.some((transfer) => checkTransfer(transfer)),
    showVisa: rules.visa?.visaSupport === EtVisaSupport.SupportEnable,
  }
}

export const getPriceUnit = (t: Translate, key: string) =>
  t(`rules.et.price_units.${key}`)

export const getInclusion = (t: Translate, key: string) =>
  t(`rules.et.inclusion.${key}`)

export const getDayPeriod = (t: Translate, key: string) =>
  t(`rules.et.day_period.${key}`)

export const getPriceMethod = (t: Translate, key: string) =>
  t(`rules.et.price_method.${key}`)

export const getPaymentType = (t: Translate, key: string) =>
  t(`rules.et.payment_type.${key}`)

export const getDepositType = (t: Translate, key: string) =>
  t(`rules.et.deposit_type.${key}`)

export const getInternetType = (t: Translate, key: string) =>
  t(`rules.et.internet_type.${key}`)

export const getInternetArea = (t: Translate, key: string) =>
  t(`rules.et.internet_area.${key}`)

export const getMeal = (t: Translate, key: string) => t(`rules.et.meals.${key}`)

export const getTerritory = (t: Translate, key: string) =>
  t(`rules.et.territory.${key}`)

export const getDestination = (t: Translate, key: string) =>
  t(`rules.et.destination.${key}`)

export const getTransferType = (t: Translate, key: string) =>
  t(`rules.et.transfer_type.${key}`)

export const getPetsType = (t: Translate, key: string) =>
  t(`rules.et.pets_type.${key}`)

export const getMealExplanation = (t: Translate, key: string) =>
  t(`rules.et.meals_explanation.${key}`)

export const getAddFeesType = (t: Translate, key: string) =>
  t(`rules.et.add_fees.${key}`)

export const getCheckinCheckoutType = (t: Translate, key: string) =>
  t(`rules.et.checkin_checkout.${key}`)
