import {useDeviceDetection} from '@/library/hooks/use-device-detection'
import {type FC} from 'react'
import {useWindowSize} from '@/library/hooks/use-window-size'
import {format} from 'date-fns'
import useTranslation from 'next-translate/useTranslation'
import {BookingStatusEnum} from '@/library/api/gql/generate-types'
import {parseBackendDateFormat} from '@utils/date/formatting'
import {MobileBooking} from './mobile-booking'
import {DesktopBooking} from './desktop-booking'
import {isBookingStatusPayable, isCancellable} from '../lib/helpers'
import {MOBILE_VERSION_POINT} from '../lib/constants'

interface BookingProps {
  booking: UserBooking
}

export const Booking: FC<BookingProps> = ({booking}) => {
  const isTouch = useDeviceDetection('touch')
  const windowWidth = useWindowSize()
  const {t: dateTimeT} = useTranslation('datetime')

  const checkIn = format(
    parseBackendDateFormat(booking.checkin),
    dateTimeT('time.checkin_policy'),
  )
  const checkOut = format(
    parseBackendDateFormat(booking.checkout),
    dateTimeT('time.checkout_policy'),
  )
  const arrivalTime = booking.arrival_checkin_datetime
    ? format(
        parseBackendDateFormat(booking.arrival_checkin_datetime),
        dateTimeT('time.arrival_policy'),
      )
    : ''

  const bookingProps = {
    checkIn,
    checkOut,
    arrivalTime,
    booking,
    showCancelButton:
      isCancellable(booking.cancellation_policy?.freeCancellationBeforeTz) &&
      booking.status === BookingStatusEnum.Booked,
    showPayment: booking.can_be_paid && isBookingStatusPayable(booking.status),
  }
  return isTouch && windowWidth <= MOBILE_VERSION_POINT ? (
    <MobileBooking {...bookingProps} />
  ) : (
    <DesktopBooking {...bookingProps} />
  )
}
