import {pathOr} from 'ramda'
import {isString} from '@library/utils/guards/types'
import type {ApiException} from '@utils/guards/api'
import {showException} from '@utils/exceptions/show-exception'

export const handleApiException = <Exception extends ApiException>(
  exception: Exception,
): never => {
  let errorMessage = pathOr('', [1, 'message'], exception)

  if (Array.isArray(errorMessage)) {
    errorMessage = pathOr('Неизвестная ошибка', [0, 'errors', 0], errorMessage)
  }

  if (isString(errorMessage)) {
    showException(errorMessage)
  }

  throw JSON.stringify(errorMessage, null, 2)
}
