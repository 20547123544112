import {GlobalHydrateService} from '@services/common/global-hydrate'
import {store} from '@store/index'
import {api} from '@api/index'
import {DEFAULT_FIRST_ITEMS_BALANCE_OPERATIONS} from '@slices/profile/balance/lib/constants'
import type {ProfileBalancePageProperties} from '@slices/profile/balance/lib/types'

type HydrationInitialData =
  Required<ProfileBalancePageProperties>['hydrationData']

export class ProfileBalanceService extends GlobalHydrateService<HydrationInitialData> {
  private get state() {
    return store().pages.profile.balance
  }

  public hydrate(initialData: HydrationInitialData): void {
    if (initialData.balanceOperations) {
      this.state.setOperations(initialData.balanceOperations.data)
      this.state.setPaginatorInfo(initialData.balanceOperations.paginatorInfo)
    }
    super.hydrate(initialData)
  }

  public loadMore(): void {
    if (!this.state.operations) {
      return
    }

    if (!this.state.paginatorInfo?.hasMorePages) {
      return
    }

    const updatedPage = this.state.page + 1
    this.state.setLoading(true)
    api.user
      .getBalanceOperation({
        variables: {
          page: updatedPage,
          first: DEFAULT_FIRST_ITEMS_BALANCE_OPERATIONS,
        },
      })
      .then((operations) => {
        if (operations) {
          this.state.setPage(updatedPage)
          this.state.setOperations([
            ...(this.state.operations || []),
            ...operations.data,
          ])
          this.state.setPaginatorInfo(operations.paginatorInfo)
        }
      })
      .finally(() => {
        this.state.setLoading(false)
      })
  }
}
