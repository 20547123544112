import {services} from '@services/index'
import {SCENARIO_COMPONENTS} from '@slices/global/lib/constants/scenario-components'
import {observer} from 'mobx-react'

export const Content = observer(() => {
  const currentScenario = services.pages.global.scenario.getCurrentScenario()

  const ScenarioComponent = SCENARIO_COMPONENTS[currentScenario]
  return <ScenarioComponent />
})
