import useTranslation from 'next-translate/useTranslation'
import {SexEnum} from '@/library/api/gql/generate-types'
import {RadioButtons} from '@/library/components/ui'
import type {FC} from 'react'
import {getSexOptions} from './lib/helpers'
import {DEFAULT_SEX} from './lib/constants'

interface SexInputProps {
  sex?: SexEnum | null
  setSex: (sex: SexEnum) => void
}

export const SexInput: FC<SexInputProps> = ({sex, setSex}) => {
  const {t} = useTranslation('features.sex-input')
  const sexOptions = getSexOptions(t)

  return (
    <RadioButtons<SexEnum>
      label={t('label')}
      value={sex || DEFAULT_SEX}
      setValue={(option) => {
        setSex(option)
      }}
      options={sexOptions}
    />
  )
}
