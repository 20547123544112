import Head from 'next/head'
import type {
  HotelUrlInfoContent,
  StaticPageSeoContent,
} from '@api/gql/generate-types'
import {createElement, type FC, type PropsWithChildren} from 'react'
import {HTML_TAGS} from '@constants/html-tags'
import {ENVIRONMENT} from '@utils/guards/environment'

const DISABLED_META_NAMES = new Set(['title', 'h1'])

type Content = Omit<HotelUrlInfoContent, 'p'> | StaticPageSeoContent

interface DynamicSeoProviderProps extends PropsWithChildren {
  content?: Content | null
  origin?: string | null
}

const META_KEYS = new Set(['author', 'description', 'keywords'])

type SeoContentRendererProps = {
  parentKey: string
  metaName: keyof Content
  content: string
}

export const SeoContentRenderer: FC<SeoContentRendererProps> = ({
  parentKey,
  metaName,
  content,
}) => {
  if (META_KEYS.has(metaName)) {
    return <meta key={parentKey} name={metaName} content={content} />
  }

  if (metaName.toLowerCase().startsWith('og_')) {
    return (
      <meta
        key={parentKey}
        property={metaName.replace('_', ':')}
        content={content}
      />
    )
  }

  if (!HTML_TAGS.includes(metaName)) {
    return null
  }

  const properties = {
    className: 'visually-hidden',
  }

  return createElement(metaName, properties, content)
}

export const DynamicSeoProvider: FC<DynamicSeoProviderProps> = ({
  children,
  content,
  origin,
}) => {
  if (!content) return <>{children}</>

  return (
    <>
      <Head>
        {Object.keys(content)
          .filter((key) => !HTML_TAGS.includes(key))
          .map((metaName, index) => {
            const parentKey = `${metaName}-${index}`

            const value = content[metaName as keyof Content]

            if (!value) {
              return null
            }

            if (META_KEYS.has(metaName)) {
              return <meta key={parentKey} name={metaName} content={value} />
            }

            if (metaName.toLowerCase().startsWith('og_url')) {
              return (
                <meta
                  key={parentKey}
                  property={metaName.replace('_', ':')}
                  content={`${ENVIRONMENT.ORIGIN_SITE_DOMAIN}${content.og_url}`}
                />
              )
            }

            if (metaName.toLowerCase().startsWith('og_')) {
              return (
                <meta
                  key={parentKey}
                  property={metaName.replace('_', ':')}
                  content={value}
                />
              )
            }

            if (!HTML_TAGS.includes(metaName)) {
              return null
            }

            return null
          })}

        {content.title && <title>{content.title}</title>}

        {content.og_url && (
          <link
            rel="canonical"
            href={`${ENVIRONMENT.ORIGIN_SITE_DOMAIN}${content.og_url}`}
          />
        )}
      </Head>

      {Object.keys(content)
        .filter(
          (key) => HTML_TAGS.includes(key) && !DISABLED_META_NAMES.has(key),
        )
        .map((metaName, index) => {
          const value = content[metaName as keyof Content]

          if (!value) {
            return null
          }

          return (
            <SeoContentRenderer
              key={`${metaName}-${value}`}
              parentKey={`${metaName}-${index}`}
              metaName={metaName as keyof Content}
              content={value}
            />
          )
        })}
      {children}
    </>
  )
}
