import {makeAutoObservable} from 'mobx'
import type {GetHotelQuery} from '@api/gql/generate-types'
import {DEFAULT_PAGE} from '@constants/common'

type HotelReviewsData =
  | NonNullable<GetHotelQuery['hotelReviews']>['data']
  | null
type HotelReviewsPaginatorInfo =
  | NonNullable<GetHotelQuery['hotelReviews']>['paginatorInfo']
  | null

export class CGlobalPageStoreHotelReviews {
  constructor() {
    makeAutoObservable(this)
  }

  private _data: HotelReviewsData = null

  public get data(): HotelReviewsData {
    return this._data
  }

  public setData(data: HotelReviewsData): void {
    this._data = data
  }

  private _page: number = DEFAULT_PAGE

  public get page(): number {
    return this._page
  }

  private _isLoading = false

  public get isLoading(): boolean {
    return this._isLoading
  }

  private _paginatorInfo: HotelReviewsPaginatorInfo | null = null

  public get paginatorInfo(): HotelReviewsPaginatorInfo | null {
    return this._paginatorInfo
  }

  public setPaginatorInfo(info: HotelReviewsPaginatorInfo): void {
    this._paginatorInfo = info
  }

  public setPage(page: number): void {
    this._page = page
  }

  public setIsLoading(flag: boolean): void {
    this._isLoading = flag
  }
}
