import {Input} from '@/library/components/ui/input'
import {services} from '@/library/services'
import {store} from '@/library/store'
import styled from '@emotion/styled'
import {observer} from 'mobx-react'
import {Button, CircularLoader, Text} from '@/library/components/ui'
import {type ChangeEvent, type FC, useEffect} from 'react'
import Countdown from 'react-countdown'
import {addSeconds} from 'date-fns'
import {useIsRedirecting} from '@/library/hooks/use-redirecting'
import useTranslation from 'next-translate/useTranslation'
import {FormProvider, useForm} from 'react-hook-form'
import {
  promoCodeForm,
  type PromoCodeFormValues,
} from '@/library/constants/form-validation/promo-code-form'
import {yupResolver} from '@hookform/resolvers/yup'
import {lensPath, set} from 'ramda'
import {useIsMounted} from '@/library/hooks/use-is-mounted'
import {getPromoCodeStatusText} from './lib/helpers'

const Wrapper = styled.div`
  margin-top: 20px;
`

const PromoCodeInput = styled(Input)``

const TimeLeftText = styled(Text)`
  color: ${({theme}) => theme.palette.textPrimary};
`

const ImportantInfo = styled(Text)<{$showTimer?: boolean}>`
  color: ${({theme}) => theme.palette.textSecondary};
  margin-top: ${({$showTimer}) => ($showTimer ? 16 : 0)}px;
`

const Apply = styled(Button)`
  margin-top: 20px;
  width: 100%;
  text-transform: uppercase;
`

interface PromoCodeFormProps {
  onlineAmount: number
  hotelId: number
  cityId: number
}

export const PromoCodeForm: FC<PromoCodeFormProps> = observer(
  ({onlineAmount, hotelId, cityId}) => {
    const {t} = useTranslation('global.hotel')
    const {isLoading} = store().bookingModal.modal
    const {
      promoCode,
      data: promoCodeData,
      error: promoCodeError,
      isLoading: promoCodeIsLoading,
    } = store().bookingModal.promoCode
    const isRedirecting = useIsRedirecting()
    const isCountdownMounted = useIsMounted(promoCodeData)

    const promoCodeStatus = getPromoCodeStatusText(
      t,
      promoCodeError,
      promoCodeData,
    )

    const methods = useForm<PromoCodeFormValues>({
      resolver: yupResolver(promoCodeForm.validationSchema),
      defaultValues: promoCodeForm.initialState,
      mode: 'onChange',
    })

    const {
      formState: {errors},
      getValues,
      register,
      reset,
    } = methods

    const isFormDisabled = Boolean(
      promoCodeData || isRedirecting || promoCodeIsLoading || isLoading,
    )

    const formValues = getValues()

    const resetForm = () => {
      services.pages.global.scenario.hotel.booking.promoCode.state.setPromoCode(
        '',
      )
      services.pages.global.scenario.hotel.booking.promoCode.state.setData(null)
      services.pages.global.scenario.hotel.booking.promoCode.state.setError(
        null,
      )
      reset()
    }

    useEffect(() => {
      resetForm()
      return () => {
        resetForm()
      }
    }, [])

    const handlePromoCodeInput = (event: ChangeEvent<HTMLInputElement>) => {
      const trimValueLens = lensPath(['target', 'value'])
      const trimmedEvent = set<ChangeEvent<HTMLInputElement>, string>(
        trimValueLens,
        event.target.value.trim(),
        event,
      )
      register('promoCode').onChange(trimmedEvent)
      services.pages.global.scenario.hotel.booking.promoCode.state.setPromoCode(
        trimmedEvent.target.value,
      )
    }

    const showTimer = Boolean(
      promoCodeData?.seconds_to_expire &&
        isCountdownMounted &&
        !isRedirecting &&
        !isLoading,
    )

    return (
      <FormProvider {...methods}>
        <Wrapper>
          <PromoCodeInput
            disabled={isFormDisabled}
            label={t('booking_modal.promo_code')}
            type="text"
            isSuccess={Boolean(promoCodeData)}
            placeholder={t('booking_modal.promo_code')}
            error={promoCodeError ? promoCodeStatus || '' : errors.promoCode}
            {...register('promoCode')}
            onChange={handlePromoCodeInput}
            value={promoCode}
          />

          {showTimer && promoCodeData?.seconds_to_expire ? (
            <TimeLeftText size="XS">
              <Countdown
                date={addSeconds(Date.now(), promoCodeData.seconds_to_expire)}
                renderer={({minutes, seconds}) =>
                  t('booking_modal.end_booking', {
                    time: `${minutes}:${seconds.toString().padStart(2, '0')}`,
                  })
                }
                onComplete={resetForm}
              />
            </TimeLeftText>
          ) : null}
          <ImportantInfo $showTimer={showTimer} size="XS">
            {t('booking_modal.promo_code_warning')}
          </ImportantInfo>
          {!promoCodeData && (
            <Apply
              onClick={() =>
                services.pages.global.scenario.hotel.booking.promoCode.submitPromoCode(
                  hotelId,
                  cityId,
                  formValues.promoCode,
                  onlineAmount,
                )
              }
              disabled={isFormDisabled || Boolean(errors.promoCode)}
              variant="secondary"
            >
              {promoCodeIsLoading ? (
                <CircularLoader size={32} />
              ) : (
                t('booking_modal.apply')
              )}
            </Apply>
          )}
        </Wrapper>
      </FormProvider>
    )
  },
)
