import {phoneWithPlus} from '@/library/constants/validation'

export const addPlus = (phone: string) => {
  if (phone.startsWith('+')) {
    return phone
  }
  return `+${phone}`
}

export const removePlus = (phone: string) => {
  if (!phone.startsWith('+')) {
    return phone
  }
  return phone.slice(1)
}

export const validatePhone = (phone: string) => phoneWithPlus.test(phone)
