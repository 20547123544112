import Head from 'next/head'
import type {FC, ReactNode} from 'react'

type OpenGraphMimes =
  | 'image/svg+xml'
  | 'image/png'
  | 'image/jpeg'
  | 'image/x-icon'
  | 'image/gif'
  | 'image/webp'
  | 'image/bmp'

interface OpenGraphConfig {
  locale: string
  type: string
  title: string
  site_name: string
  description: string
  url: string
  keywords: string
  image: string
  image_width: string
  image_height: string
  image_type: OpenGraphMimes
  image_alt: string
}

interface SeoProviderConfig {
  title: string
  keywords: string
  description: string
  author: string
  canonical?: string
  addRobots?: boolean
  openGraph?: OpenGraphConfig
}

interface SeoProviderProps {
  config: SeoProviderConfig
  schemaNode?: ReactNode
  children?: ReactNode
}

export const SeoProvider: FC<SeoProviderProps> = ({
  config,
  children,
  schemaNode,
}) => {
  return (
    <>
      {schemaNode}
      <Head>
        <title>{config.title}</title>
        <meta name="description" content={config.description} />
        <meta name="keywords" content={config.keywords} />
        <meta name="author" content={config.author} />
        <meta name="google" content="notranslate" />
        {config.openGraph && (
          <>
            <meta property="og:locale" content={config.openGraph.locale} />
            <meta property="og:type" content={config.openGraph.type} />
            <meta property="og:title" content={config.openGraph.title} />
            <meta
              property="og:description"
              content={config.openGraph.description}
            />
            <meta
              property="og:site_name"
              content={config.openGraph.site_name}
            />
            <meta property="og:url" content={config.openGraph.url} />
            <meta property="og:keywords" content={config.openGraph.keywords} />
            <meta property="og:image" content={config.openGraph.image} />
            <meta
              property="og:image_width"
              content={config.openGraph.image_width}
            />
            <meta
              property="og:image_height"
              content={config.openGraph.image_height}
            />
            <meta
              property="og:image_type"
              content={config.openGraph.image_type}
            />
            <meta
              property="og:image_alt"
              content={config.openGraph.image_alt}
            />
          </>
        )}
        {config.canonical && <link rel="canonical" href={config.canonical} />}
        {config.addRobots && <meta name="robots" content="noindex,nofollow" />}

        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="/apple-icon-57x57.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="/apple-icon-60x60.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="/apple-icon-72x72.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="/apple-icon-76x76.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="/apple-icon-114x114.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="/apple-icon-120x120.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="/apple-icon-144x144.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="/apple-icon-152x152.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-icon-180x180.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="/android-icon-192x192.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="96x96"
          href="/favicon-96x96.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
      </Head>
      {children}
    </>
  )
}
