import {makeAutoObservable} from 'mobx'

export class CGlobalPageStoreSearchPromotion {
  constructor() {
    makeAutoObservable(this)
  }

  private _promotionCities: PromotionCityType[] = []

  public get promotionCities(): PromotionCityType[] {
    return this._promotionCities
  }

  public setPromotionCities(promotionCities: PromotionCityType[]): void {
    this._promotionCities = promotionCities
  }
}
