import {clientHeaders} from '@/library/utils/http/headers'
import {path} from 'ramda'
import {$gqlClient} from '@api/gql/client'
import {
  GetCitiesForGlobalPromotionDocument,
  type GetCitiesForGlobalPromotionQuery,
  type GetCitiesForGlobalPromotionQueryVariables,
} from '../gql/generate-types'

const getCities = (headers?: Headers) =>
  $gqlClient()
    .query<
      GetCitiesForGlobalPromotionQuery,
      GetCitiesForGlobalPromotionQueryVariables
    >({
      query: GetCitiesForGlobalPromotionDocument,
      fetchPolicy: 'no-cache',
      context: {
        headers: headers || clientHeaders(),
      },
    })
    .then(path<PromotionCityType[]>(['data', 'citiesForGlobalPromotion']))

export const promotionApi = {
  getCities,
}
