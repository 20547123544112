import {observer} from 'mobx-react'
import {store} from '@store/index'
import {useLocalObservable} from 'mobx-react-lite'
import {Checkbox} from '@ui/checkbox'
import styled from '@emotion/styled'
import {isNumber} from '@utils/guards/types'
import {Text} from '@components/ui'
import useTranslation from 'next-translate/useTranslation'
import {Skeleton} from 'antd'
import {getHotelsCountFromTypes} from '@/slices/global/lib/helpers'
import {ALL_HOTEL_TYPE_OPTION_ID} from '@/slices/global/lib/constants'
import {isEmpty} from 'ramda'
import {SEARCH_SCENARIO_FILTERS_ID} from '@constants/dom-ids'
import {scrollParentToChild} from '@utils/dom/scroll'
import type {MouseEvent} from 'react'

const HotelTypesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-bottom: 1px solid ${({theme}) => theme.palette.borderTertiary};
  padding-bottom: 20px;
`

const StyledSkeleton = styled(Skeleton)`
  border-bottom: 1px solid ${({theme}) => theme.palette.borderTertiary};
  padding-bottom: 20px;
`

const SkeletonLoader = () => {
  return <StyledSkeleton active paragraph={{rows: 8}} />
}

const select = () => ({
  get hotelTypes() {
    return store().pages.global.search.filters.hotelTypes
  },
  get selectHotelTypes() {
    return store().pages.global.search.filters.selectHotelTypes
  },
  get loading() {
    return store().pages.global.search.filters.loading
  },
})

export const SearchScenarioFiltersHotelTypes = observer(() => {
  const {t} = useTranslation('global.search')
  const {hotelTypes, selectHotelTypes, loading} = useLocalObservable(select)

  const handleClickCheckbox = (
    value: boolean,
    id: number,
    event: MouseEvent<HTMLDivElement>,
  ) => {
    const {filters} = store().pages.global.search

    if (id === ALL_HOTEL_TYPE_OPTION_ID) {
      filters.resetHotelTypes()

      return
    }

    if (value) {
      filters.addHotelType(id)
    } else {
      filters.removeHotelType(id)
    }

    const parent = document.querySelector<HTMLDivElement>(
      `#${SEARCH_SCENARIO_FILTERS_ID}`,
    )
    if (parent) {
      scrollParentToChild(parent, event.currentTarget)
    }
  }

  if (loading) {
    return <SkeletonLoader />
  }

  if (!hotelTypes) {
    return null
  }

  const allHotelTypesOption = {
    id: ALL_HOTEL_TYPE_OPTION_ID,
    hotels_count: getHotelsCountFromTypes(hotelTypes),
    name: t('filters.hotel_types.all'),
    description: null,
  }

  const hotelTypesWithAllOption = [allHotelTypesOption, ...hotelTypes]

  return (
    <HotelTypesWrapper>
      <Text size="S">{t('filters.hotel_types.title')}</Text>
      {hotelTypesWithAllOption?.map((type, id) => {
        if (type.name && isNumber(type.hotels_count)) {
          return (
            <Checkbox
              key={type.id}
              value={
                id === ALL_HOTEL_TYPE_OPTION_ID
                  ? isEmpty(selectHotelTypes)
                  : selectHotelTypes.includes(type.id)
              }
              label={type.name}
              setValue={(value, event) =>
                handleClickCheckbox(value, type.id, event)
              }
              count={type.hotels_count}
              description={type.description}
            />
          )
        }

        return null
      })}
    </HotelTypesWrapper>
  )
})
