import {PromoCodeErrorCategories} from './types'

export const codesToPromoCodeCategories: Record<
  string,
  PromoCodeErrorCategories
> = {
  validation: PromoCodeErrorCategories.validation,
  'promo-codes.expired': PromoCodeErrorCategories.expired,
  'promo-codes.invalid-data': PromoCodeErrorCategories.invalidData,
  'promo-codes.authorization': PromoCodeErrorCategories.authorizaiton,
  'promo-codes.invalid-amount': PromoCodeErrorCategories.invalidAmount,
  'promo-codes.already-used': PromoCodeErrorCategories.alreadyUsed,
}

export const errorCategoryToKey: Record<PromoCodeErrorCategories, string> = {
  [PromoCodeErrorCategories.authorizaiton]:
    'booking_modal.errors.for_authorized_users_only',
  [PromoCodeErrorCategories.validation]: 'booking_modal.errors.bad_promo_code',
  [PromoCodeErrorCategories.expired]: 'booking_modal.errors.promo_code_expired',
  [PromoCodeErrorCategories.invalidData]: 'booking_modal.errors.invalid_data',
  [PromoCodeErrorCategories.invalidAmount]:
    'booking_modal.errors.invalid_amount',
  [PromoCodeErrorCategories.alreadyUsed]: 'booking_modal.errors.already_used',
  [PromoCodeErrorCategories.unknown]: 'booking_modal.errors.try_again',
}
