import {store} from '@store/index'
import {Icon} from '@ui/icon'
import styled from '@emotion/styled'
import {Button} from '@ui/button'
import {AdultsCountAmount} from './adults.counter.amount'

const AdultsCountButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
`

const AdultsCountButton = styled(Button)`
  width: 32px;
  height: 32px;
  padding: 10px 15px;
  border-radius: 100%;

  svg {
    color: ${({theme}) => theme.palette.backgroundSecondary};
  }
`

export const AdultsCounter = () => {
  const handleDecrementAdults = () => {
    store().search.guests.adults.decrementAmount()
  }

  const handleIncrementAdults = () => {
    store().search.guests.adults.incrementAmount()
  }

  return (
    <AdultsCountButtonWrapper>
      <AdultsCountButton variant="secondary" onClick={handleDecrementAdults}>
        <Icon name="minus" width={24} height={24} />
      </AdultsCountButton>
      <AdultsCountAmount />
      <AdultsCountButton variant="secondary" onClick={handleIncrementAdults}>
        <Icon name="plus" width={24} height={24} />
      </AdultsCountButton>
    </AdultsCountButtonWrapper>
  )
}
