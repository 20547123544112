import {Icon} from '@/library/components/ui/icon'
import styled from '@emotion/styled'
import {useEffect, useRef, useState, type FC} from 'react'
import {CircularLoader, Text} from '@/library/components/ui'
import {copyService} from '@/library/services/common/copy'
import {Tooltip} from '@/library/components/ui/tooltip'
import useTranslation from 'next-translate/useTranslation'
import {TRANSITION_STYLES} from '@/library/constants/css'
import {services} from '@/library/services'
import {COPY_TOOLTIP_LIFETIME} from '../lib/constants'

const Copy = styled(Icon)`
  color: ${({theme}) => theme.palette.textAccentPrimary};
  margin-left: 8px;
`

const CopyWrapper = styled.div`
  cursor: pointer;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 11px;
  margin-top: 12px;
  width: 100%;
  background-color: ${({theme}) => theme.palette.backgroundQuinary};
  transition: all ${TRANSITION_STYLES};

  &:hover {
    background-color: ${({theme}) => theme.palette.backgroundTertiary};
  }
`

const CopyText = styled(Text)`
  color: ${({theme}) => theme.palette.textSecondary};
`

interface PaymentLinkCopyProps {
  id: number
}

export const PaymentLinkCopy: FC<PaymentLinkCopyProps> = ({id}) => {
  const {t} = useTranslation('profile.bookings')
  const [openCopyTooltip, setOpenCopyTooltip] = useState(false)
  const [showError, setShowError] = useState(false)
  const [paymentUrl, setPaymentUrl] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        timeoutRef.current = null
      }
    }
  }, [])

  const copyExistingUrl = (url: string) => {
    copyService.copy(url).then(() => {
      setOpenCopyTooltip(true)
      timeoutRef.current = setTimeout(() => {
        setOpenCopyTooltip(false)
        timeoutRef.current = null
      }, COPY_TOOLTIP_LIFETIME)
    })
  }

  const handleCopyError = () => {
    setShowError(true)
    timeoutRef.current = setTimeout(() => {
      setShowError(false)
      timeoutRef.current = null
    }, COPY_TOOLTIP_LIFETIME)
  }

  const handleCopy = async () => {
    if (paymentUrl) {
      copyExistingUrl(paymentUrl)
    } else {
      try {
        setIsLoading(true)
        const paymentUrl =
          await services.pages.profile.bookings.getPaymentUrl(id)

        if (!paymentUrl) {
          handleCopyError()
          setIsLoading(false)
          return
        }

        setPaymentUrl(paymentUrl)
        copyExistingUrl(paymentUrl)
      } catch {
        handleCopyError()
      } finally {
        setIsLoading(false)
      }
    }
  }

  return (
    <Tooltip
      open={showError || openCopyTooltip}
      title={showError ? t('copy_error') : t('copy')}
    >
      <CopyWrapper onClick={handleCopy}>
        <CopyText size="S">{t('payment_link')}</CopyText>
        {isLoading ? (
          <CircularLoader size={20} />
        ) : (
          <Copy name="copy" width={20} height={20} />
        )}
      </CopyWrapper>
    </Tooltip>
  )
}
