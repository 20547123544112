import {ENVIRONMENT} from '@/library/utils/guards/environment'
import {addDays, isAfter, isBefore, isWithinInterval, subDays} from 'date-fns'
import {TODAY_DATE} from '@/library/constants/date'
import type {Dispatch, SetStateAction} from 'react'
import type {DateRange} from 'react-day-picker'
import {store} from '@/library/store'
import {services} from '@/library/services'

export class ServiceSearchDate {
  public buildMinDateTo(from?: Date): Date {
    // мин дата для выезда (не более месяца до въезда)
    return from
      ? subDays(from, ENVIRONMENT.MAX_SEARCH_DATE_RANGE_COUNT)
      : TODAY_DATE
  }

  public buildMaxDateTo(from?: Date) {
    // макс дата для выезда (не более месяца от въезда)
    return addDays(from || TODAY_DATE, ENVIRONMENT.MAX_SEARCH_DATE_RANGE_COUNT)
  }

  public checkIsDateExceed(from: Date, to: Date) {
    const minDateTo = subDays(from, ENVIRONMENT.MAX_SEARCH_DATE_RANGE_COUNT)
    const maxDateTo = addDays(
      from || TODAY_DATE,
      ENVIRONMENT.MAX_SEARCH_DATE_RANGE_COUNT,
    )

    return !isWithinInterval(to, {start: minDateTo, end: maxDateTo})
  }

  public handleDayClick(
    day: Date,
    setIsDateClicked: Dispatch<SetStateAction<boolean>>,
    setInternalRange: Dispatch<SetStateAction<DateRange>>,
    setDatesChosen: Dispatch<SetStateAction<boolean>>,
    from?: Date,
    to?: Date,
  ) {
    setIsDateClicked(true)

    if (from && to) {
      setInternalRange({from: day, to: undefined})

      return
    }
    if (from) {
      // Меняем дату местами если выбранная перед въездом
      if (isAfter(day, from)) {
        setInternalRange({from, to: day})
        setDatesChosen(true)

        return
      }

      setInternalRange({from: day, to: from})
      setDatesChosen(true)

      return
    }

    setInternalRange({from: day, to: undefined})
  }

  public handleDayClickMobile(
    day: Date,
    setInternalRange: Dispatch<SetStateAction<DateRange>>,
    setRangeExceed: Dispatch<SetStateAction<boolean>>,
    from?: Date,
    to?: Date,
  ) {
    if (from && to) {
      setInternalRange({from: day, to: undefined})

      return
    }

    if (from) {
      // Если дата выезда выбрана до даты отъезда, эта дата становится датой отъезда
      if (isBefore(day, from)) {
        setInternalRange({from: day, to: undefined})

        return
      }

      // Проверяем, что дата выезда не выходит за рамки(месяц до и после въезда)
      const isDateExceed = this.checkIsDateExceed(from, day)

      if (isDateExceed) {
        setRangeExceed(true)
        return
      }

      setInternalRange({from, to: day})

      return
    }

    setInternalRange({from: day, to: undefined})
  }

  public closeDateInputPopupMobile(
    isBestOffers: boolean,
    from?: Date,
    to?: Date,
  ) {
    if (!from || !to) {
      return
    }

    this.setDatesToStore(from, to)

    store().search.dateTo.setIsOpen(false)
    store().search.dateFrom.setIsOpen(false)

    if (isBestOffers) {
      services.components.search.handleSearch()
    }
  }

  public closeDateInputPopup(
    datesChosen: boolean,
    isBestOffers: boolean,
    from?: Date,
    to?: Date,
  ) {
    // Когда выбраны новые даты въезда и выезда, закрывает попап
    if (!datesChosen) {
      return
    }

    if (!from || !to) {
      return
    }

    this.setDatesToStore(from, to)

    store().search.dateTo.setIsOpen(false)
    store().search.dateFrom.setIsOpen(false)

    if (isBestOffers) {
      services.components.search.handleSearch()
    }
  }

  private setDatesToStore(from: Date, to: Date) {
    // Если выбраны от и до - сетить в стор, перед этим проверить чтобы выезд был после въезда
    if (isAfter(to, from)) {
      store().search.dateFrom.setValue(from)
      store().search.dateTo.setValue(to)
    } else {
      store().search.dateFrom.setValue(to)
      store().search.dateTo.setValue(from)
    }
  }
}
