import {css} from '@emotion/react'
import styled from '@emotion/styled'
import {type FC, memo, useEffect, useId, useMemo, useRef, useState} from 'react'
import {useFormContext} from 'react-hook-form'
import {TRANSITION_STYLES} from '@constants/css'
import useTranslation from 'next-translate/useTranslation'
import {Text} from '../typography'
import {Error} from './ui/error'
import type {InputProperties} from './lib/types'
import {InputIcon} from './ui/input-icon'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
`

export const Label = styled.label<{
  $isShow: boolean
  $isError: boolean
  $isSuccess?: boolean
}>`
  width: fit-content;
  cursor: pointer;

  position: absolute;

  background-color: ${({theme}) => theme.palette.backgroundQuaternary};
  z-index: 1;
  font-weight: 400;
  color: ${({theme}) => theme.palette.textSecondary};
  transition:
    top ${TRANSITION_STYLES},
    left ${TRANSITION_STYLES},
    opacity ${TRANSITION_STYLES};
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;

  ${({$isShow}) =>
    $isShow &&
    css`
      top: -12px;
      left: 10px;
      opacity: 1;
      pointer-events: unset;
    `};

  ${({$isSuccess, theme}) =>
    $isSuccess &&
    css`
      color: ${theme.palette.textPositive};
    `}

  ${({$isError, theme}) =>
    $isError &&
    css`
      color: ${theme.palette.textError};
    `};
`

export const StyledInput = styled.input<InputProperties>`
  padding: ${({padding}) => padding || '15px 50px 15px 15px'};
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  outline: none !important;
  text-overflow: ellipsis;

  cursor: ${({cursor}) => `${cursor} !important`};
  background-color: ${({theme}) =>
    theme.palette.backgroundQuaternary} !important;

  border-radius: 12px;
  border: 1px solid ${({theme}) => theme.palette.borderTertiary};

  ::placeholder {
    color: ${({theme}) => theme.palette.textSecondary};
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px
      ${({theme}) => theme.palette.backgroundQuaternary} inset !important;
    caret-color: transparent !important;
  }

  ${({disabled}) =>
    disabled &&
    css`
      cursor: not-allowed !important;
      opacity: 0.4;
    `}

  ${({isSuccess, theme}) =>
    isSuccess &&
    css`
      border: 1px solid ${theme.palette.textPositive};
    `}

  ${({error, theme}) =>
    Boolean(error) &&
    css`
      border: 1px solid ${theme.palette.textError};
    `}
`

const ControlWrapper = styled.div`
  position: relative;
  width: inherit;
  max-width: inherit;
  max-height: inherit;
  height: inherit;
  border-radius: 12px;
`

const Counter = styled(Text)`
  color: ${({theme}) => theme.palette.textSecondary};
  position: absolute;
  bottom: 3px;
  right: 13px;
`

// name должен совпадать с названием поля в модели react-hook-form
const Component: FC<InputProperties> = ({
  name,
  className,
  label,
  disabled,
  maxCount,
  type,
  padding,
  icon,
  value,
  iconOnClick,
  iconWidth = 24,
  iconHeight = 24,
  isSuccess,
  error,
  onChange,
  required,
  // Для кастомных хендлеров
  withHookForm = true,
  placeholder,
  ...properties
}) => {
  const reference = useRef<HTMLInputElement>(null)
  const {t} = useTranslation('common')
  const id = useId()
  const context = useFormContext()
  const [currentType, setCurrentType] = useState(type)
  const requiredSymbolText = required ? t('input.required_symbol') : ''
  const placeholderText = placeholder
    ? `${placeholder} ${requiredSymbolText}`
    : ''

  const handleOnWheel = (event: Event) => {
    event.preventDefault()
  }

  const {onChange: onChangeHook, ...hookProperties} = useMemo(
    () => context?.register(name) || {},
    [context, name],
  )

  useEffect(() => {
    const element = reference.current

    if (currentType === 'number' && element) {
      element.addEventListener('wheel', handleOnWheel)
    }

    return () => element?.removeEventListener('wheel', handleOnWheel)
  }, [reference, currentType])

  useEffect(() => {
    setCurrentType(type)
  }, [type])

  return (
    <Wrapper role="group" className={className}>
      <ControlWrapper>
        {label && (
          <Label
            htmlFor={id}
            $isError={Boolean(error)}
            $isShow={Boolean(value)}
            $isSuccess={isSuccess}
          >
            {label} {requiredSymbolText}
          </Label>
        )}
        <StyledInput
          id={id}
          disabled={disabled}
          autoComplete="new-password"
          type={currentType}
          padding={padding}
          isSuccess={isSuccess}
          error={error}
          value={value}
          required={required}
          placeholder={placeholderText}
          onChange={(event) => {
            onChangeHook?.(event)
            onChange?.(event)
          }}
          {...properties}
          {...(withHookForm ? {...hookProperties} : {name})}
        />
        <InputIcon
          disabled={disabled}
          iconOnClick={iconOnClick}
          iconWidth={iconWidth}
          iconHeight={iconHeight}
          icon={icon}
          type={type}
          currentType={currentType}
          setCurrentType={setCurrentType}
        />
        {maxCount && value ? (
          <Counter size="XS">
            {currentType === 'number' ? value : value?.length}/{maxCount}
          </Counter>
        ) : null}
      </ControlWrapper>
      <Error error={error} />
    </Wrapper>
  )
}

export const Input = memo(Component)
