import {api} from '@api/index'
import {store} from '@store/index'
import {
  AnalyticsBookingStatuses,
  type BasisSearchFilters,
  BookingFormFields,
  CallYaTaxiAnalyzingEventEnum,
  type YaTaxiEventParams,
  PaymentMethod,
} from '@api/gql/generate-types'
import {getNearestWeekend} from '@utils/date/get-nearest-weekend'
import {format} from 'date-fns'
import {DateFormatPattern} from '@/types/enums/date-format-patterns'
import {Routes} from '@/types/enums/routes'
import {ENVIRONMENT} from '@utils/guards/environment'

export class AnalyticsService {
  private readonly api = api.analytics

  // TODO Когда завезут мульти-бронирования, сделать не статическим числом
  private readonly MAX_ROOM_COUNT = 1

  public pushUserVisit(): void {
    const query = new URLSearchParams(window.location.search)

    api.analytics.send('USER_VISIT', {
      utm: query.get('utm') || query.get(ENVIRONMENT.UTM_SOURCE_COOKIE_KEY),
    })
  }

  public createAffiliateLinkVisit(): void {
    const query = new URLSearchParams(window.location.search)
    const referralCode = query.get(
      ENVIRONMENT.AFFILIATE_REFERRAL_CODE_QUERY_KEY,
    )

    if (!referralCode) {
      return
    }

    const variables = window.document.referrer
      ? {
          referral_code: referralCode,
          http_referrer: window.document.referrer,
        }
      : {
          referral_code: referralCode,
        }

    api.analytics.send('AFFILIATE_LINK_VISIT', variables)
  }

  public pushSearchParams(): void {
    this.api.send('SEARCH_PARAMS', {
      filters: this.buildFilters(),
    })
  }

  public pushSelectPaymentMethod(method: PaymentMethod): void {
    this.api.send('PAY_FROM_ACCOUNT_WITH_SELECT', {
      method,
    })
  }

  public pushPayFromAccount(): void {
    this.api.send('PAY_FROM_ACCOUNT', {})
  }

  public pushPay(): void {
    this.api.send('PAY', {})
  }

  public pushBreadcrumbs(): void {
    this.api.send('BREADCRUMBS', {
      filters: this.buildFilters(),
    })
  }

  public pushAddHotelToFavourite(hotelId: number): void {
    this.api.send('ADD_HOTEL_TO_FAVORITE', {
      hotelId,
    })
  }

  public pushViewGlobalPromotion(): void {
    this.api.send('VIEW_GLOBAL_PROMOTIONS', {
      filters: this.buildFilters(),
    })
  }

  public pushViewBestOffers(): void {
    this.api.send('VIEW_BEST_OFFERS', {
      filters: this.buildFilters(),
    })
  }

  private buildFilters(): BasisSearchFilters {
    const {
      pages: {global},
    } = store()
    const {guests, dateFrom, dateTo} = store().search
    const {info, search} = global
    const {priceFrom, priceTo} = search.filters

    const isMain = window.location.pathname === Routes.MAIN
    const weekend = getNearestWeekend(new Date())
    const locationParameters = {
      regionId: info?.filters.region?.id,
      cityId: info?.filters.city?.id,
      countryId: info?.filters.country?.id,
      typeId: info?.filters.type?.id,
    }

    return {
      checkin: format(
        dateFrom.value || weekend.startDate,
        DateFormatPattern.QUERY,
      ),
      checkout: format(
        dateTo.value || weekend.endDate,
        DateFormatPattern.QUERY,
      ),
      adults_count: guests.adults.amount,
      price_from: priceFrom,
      price_to: priceTo,
      results_count: 1,
      rooms_count: this.MAX_ROOM_COUNT,
      childs_count: guests.children.children.length,
      is_main: isMain,
      by_work: false,
      country_ids: locationParameters.countryId
        ? [locationParameters.countryId]
        : undefined,
      object_type_ids: locationParameters.typeId
        ? [locationParameters.typeId]
        : undefined,
      city_ids: locationParameters.cityId
        ? [locationParameters.cityId]
        : undefined,
      region_ids: locationParameters.regionId
        ? [locationParameters.regionId]
        : undefined,
    }
  }

  public pushBookingStatus(
    status: AnalyticsBookingStatuses,
    hotelSeoUrl: string,
  ) {
    this.api.send('BOOKINGS_STATUS', {status, hotel_seo_url: hotelSeoUrl})
  }

  public pushBookingFieldInput(
    field: BookingFormFields,
    value: string,
    guestIndex: number,
  ) {
    this.api.send('BOOKING_FORM_FIELD', {field, value, number: guestIndex})
  }

  public pushPromoCode(code: string, isFailedApplying: boolean) {
    this.api.send('APPLY_PROMO_CODE', {code, isFailedApplying})
  }

  public pushCallYaTaxi(
    concreteEvent: CallYaTaxiAnalyzingEventEnum,
    parameters: YaTaxiEventParams,
  ): void {
    this.api.send('CALL_YANDEX_TAXI', {concreteEvent, params: parameters})
  }
}
