import {makeAutoObservable} from 'mobx'

export class CAffiliateWidgetsVote {
  constructor() {
    makeAutoObservable(this)
  }

  private _selectId: number | null = null

  public get selectId(): number | null {
    return this._selectId
  }

  private _error = ''

  public get error(): string {
    return this._error
  }

  private _isOpen = false

  public get isOpen(): boolean {
    return this._isOpen
  }

  private _isLoading = false

  public get isLoading(): boolean {
    return this._isLoading
  }

  private _siteURL = ''

  public get siteURL(): string {
    return this._siteURL
  }

  private _comment = ''

  public get comment(): string {
    return this._comment
  }

  public setSelectId(id: number | null): void {
    this._selectId = id
  }

  public setError(error: string): void {
    this._error = error
  }

  public setIsOpen(flag: boolean): void {
    this._isOpen = flag
  }

  public setIsLoading(flag: boolean): void {
    this._isLoading = flag
  }

  public setSiteURL(url: string): void {
    this._siteURL = url
  }

  public setComment(comment: string): void {
    this._comment = comment
  }

  public resetState(): void {
    this.setIsOpen(false)
    this.setSelectId(null)
    this.setSiteURL('')
    this.setComment('')
    this.setError('')
  }
}
