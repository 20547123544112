import {makeAutoObservable} from 'mobx'

type InitialStateType = {
  userPopupOpen: boolean
  helpPopupOpen: boolean
  isLoading: boolean
}

const INITIAL_STATE: InitialStateType = {
  userPopupOpen: false,
  helpPopupOpen: false,
  isLoading: false,
}

export class CHeaderStore {
  constructor() {
    makeAutoObservable(this)
  }

  // Открытие окна профиля
  private _userPopupOpen = INITIAL_STATE.userPopupOpen

  public get userPopupOpen(): InitialStateType['userPopupOpen'] {
    return this._userPopupOpen
  }

  // Открытие окна помощи
  private _helpPopupOpen = INITIAL_STATE.helpPopupOpen

  public get helpPopupOpen(): InitialStateType['helpPopupOpen'] {
    return this._helpPopupOpen
  }

  // Флаг загрузки
  private _isLoading = INITIAL_STATE.isLoading

  public get isLoading(): InitialStateType['isLoading'] {
    return this._isLoading
  }

  public setUserPopupOpen(userPopupOpen: InitialStateType['userPopupOpen']) {
    this._userPopupOpen = userPopupOpen
  }

  public setHelpPopupOpen(helpPopupOpen: InitialStateType['helpPopupOpen']) {
    this._helpPopupOpen = helpPopupOpen
  }

  public setIsLoading(isLoading: InitialStateType['isLoading']) {
    this._isLoading = isLoading
  }
}
