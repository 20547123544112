import styled from '@emotion/styled'
import {Link} from '@/library/components/ui'
import {useDeviceDetection} from '@hooks/use-device-detection'
import {Illustration} from '@/library/components/ui/illustration'
import {TRANSITION_STYLES} from '@/library/constants/css'
import {useWindowSize} from '@/library/hooks/use-window-size'
import {css} from '@emotion/react'
import {DEFAULT_ANDROID, DEFAULT_IOS} from '@/library/constants/routes'
import {Routes} from '@/types/enums/routes'

import {MOBILE_LAYOUT_BREAKPOINT} from '../../lib/constants'

const Wrapper = styled.nav<{$isMobile: boolean}>`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 32px;

  ${({$isMobile}) =>
    $isMobile &&
    css`
      gap: 16px;
      margin-bottom: 20px;
    `}
`

const LinkWrapper = styled.div<{$isMobile: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({$isMobile}) => ($isMobile ? 164 : 141)}px;
  height: ${({$isMobile}) => ($isMobile ? 48 : 38)}px;
`

const StyledLink = styled(Link)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid ${({theme}) => theme.palette.borderTertiary};
  border-radius: 12px;
  transition: border-color ${TRANSITION_STYLES};

  &:hover {
    border-color: ${({theme}) => theme.palette.borderSecondary};
  }
`

export const MobileStoreLinks = () => {
  const isMobile = useDeviceDetection('mobile')
  const windowSize = useWindowSize()
  const isMobileLayout = isMobile || windowSize < MOBILE_LAYOUT_BREAKPOINT

  const ANDRIOD_HREF = `${Routes.MOBILE}#${DEFAULT_ANDROID}`
  const IOS_HREF = `${Routes.MOBILE}#${DEFAULT_IOS}`

  return (
    <Wrapper $isMobile={isMobileLayout}>
      <LinkWrapper $isMobile={isMobileLayout}>
        <StyledLink href={ANDRIOD_HREF}>
          <Illustration name="google_play" width={102} height={23} />
        </StyledLink>
      </LinkWrapper>
      <LinkWrapper $isMobile={isMobileLayout}>
        <StyledLink href={IOS_HREF}>
          <Illustration name="app_store" width={95} height={24} />
        </StyledLink>
      </LinkWrapper>
      <LinkWrapper $isMobile={isMobileLayout}>
        <StyledLink href={ANDRIOD_HREF}>
          <Illustration name="get_apps" width={87} height={20} />
        </StyledLink>
      </LinkWrapper>
      <LinkWrapper $isMobile={isMobileLayout}>
        <StyledLink href={ANDRIOD_HREF}>
          <Illustration name="app_gallery" width={115} height={20} />
        </StyledLink>
      </LinkWrapper>
    </Wrapper>
  )
}
