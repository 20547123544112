import {makeAutoObservable} from 'mobx'
import {ConfirmationStatus} from '@store/authorization/types'

export class CAuthorizationEmailConfirm {
  constructor() {
    makeAutoObservable(this)
  }

  private _status: ConfirmationStatus | null = null

  public get status(): ConfirmationStatus | null {
    return this._status
  }

  public setStatus(status: ConfirmationStatus): void {
    this._status = status
  }
}
