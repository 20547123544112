export enum DateFormatPattern {
  // 29 апреля, пн
  DATE_PICKER_OUTPUT = 'd MMMM, EEEEEE',
  DATE_PICKER_OUTPUT_SHORT = 'd MMM',
  DATE_PICKER_OUTPUT_MIDDLE = 'EEEEEE d, MMM',
  // 29 апреля 2024
  DATE_INPUT_OUTPUT = 'd MMMM yyyy',
  DATE_PICKER_DAYS_INDEX = 'd',
  DATE_PICKER_MONTH_NAME = 'MMM',
  // пт, 09 февраля 2024,
  BOOKING_VIEW_DATE = 'EEEEEE, dd MMMM y,',
  // пт, 09 февраля 2024
  VIEW_DATE = 'EEEEEE, dd MMMM y',
  TABLE = 'dd.MM.yyyy',
  SHORT_YEAR_DATE = 'dd.MM.yy',
  QUERY = 'yyyy-MM-dd',
  DATE_WITH_TIME = 'dd.MM.yyyy - p',
  BACKEND_FORMAT = 'yyyy-MM-dd HH:mm:ss',
  BACKEND_DATE_FORMAT = 'yyyy-MM-dd',
  BACKEND_TIME_FORMAT = 'HH:mm:ss',
  TIME_FORMAT = 'HH:mm',
}
