import {NO_HOTEL_IMAGE_SOURCE} from '@/library/constants/hotel'
import {store} from '@/library/store'
import {getCanonicalUrl} from '@/library/utils/http/url'
import {hotelMetaBuilder} from '@/library/utils/seo/schema'
import {
  buildRatingContentValue,
  buildStarRatingContentValue,
} from '@/slices/global/lib/helpers'
import {observer} from 'mobx-react'
import type {FC} from 'react'
import useTranslation from 'next-translate/useTranslation'
import {ECurrency} from '@/types/enums/currency'
import {ENVIRONMENT} from '@/library/utils/guards/environment'

interface HotelCardSeoProps {
  hotel: SearchScenarioHotel | SearchScenarioSeoHotel
}

export const HotelCardSeo: FC<HotelCardSeoProps> = observer(({hotel}) => {
  const {t} = useTranslation('global.search')
  const {origin} = store().utilities
  const urlsChain = store().pages.global.info?.urlsChain

  const canonicalUrl =
    hotel.seoUrl?.url && origin ? getCanonicalUrl(hotel.seoUrl.url, origin) : ''
  const source = hotel.mainImage?.url || NO_HOTEL_IMAGE_SOURCE

  return (
    <div {...hotelMetaBuilder.hotelProps}>
      {hotelMetaBuilder.renderName(hotel.name || '')}
      {hotelMetaBuilder.renderUrl(canonicalUrl)}
      {hotelMetaBuilder.renderImage(source)}
      <div {...hotelMetaBuilder.addressProps}>
        {hotelMetaBuilder.renderStreetAddress(hotel.address)}
        {urlsChain?.country
          ? hotelMetaBuilder.renderCountry(urlsChain.country.name || '')
          : null}
      </div>
      <div {...hotelMetaBuilder.starRatingProps}>
        {hotelMetaBuilder.renderRatingValue(
          buildStarRatingContentValue(hotel.stars),
        )}
      </div>
      {hotel.price
        ? hotelMetaBuilder.renderPriceRange(
            t('hotels.cards.price.seo', {
              price: hotel.price.toLocaleString(),
              currency: ECurrency.RUB,
            }),
          )
        : null}
      {hotelMetaBuilder.renderCurrenciesAccepted(ECurrency.RUB)}
      <div {...hotelMetaBuilder.geoProps}>
        {hotelMetaBuilder.renderLatitude(hotel.lat)}
        {hotelMetaBuilder.renderLongitude(hotel.lng)}
      </div>
      <div {...hotelMetaBuilder.aggregateRatingProps}>
        {hotelMetaBuilder.renderBestRating(ENVIRONMENT.BEST_RATING)}
        {hotelMetaBuilder.renderWorstRating(ENVIRONMENT.WORST_RATING)}
        {hotelMetaBuilder.renderRatingValue(
          buildRatingContentValue(hotel.rating),
        )}
        {hotelMetaBuilder.renderRatingCount(
          hotel.reviews_count || ENVIRONMENT.DEFAULT_SEO_COUNT,
        )}
        {hotelMetaBuilder.renderReviewCount(
          hotel.reviews_count || ENVIRONMENT.DEFAULT_SEO_COUNT,
        )}
      </div>
    </div>
  )
})
