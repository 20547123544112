import {enableStaticRendering} from 'mobx-react-lite'
import {isServer} from '@utils/next'
import CUtilities from '@store/utilities'
import CSearch from '@store/search'
import {CLanguage} from '@store/language'
import {CFooterActions} from '@store/footer-actions'
import {CGlobalPageStore} from '@store/pages/global'
import {CProfileBalanceStore} from '@store/pages/profile/balance'
import {CProfilePasswordStore} from '@store/pages/profile/password'
import {CProfileSettingsStore} from '@store/pages/profile/settings'
import {CProfileFavoritesStore} from '@store/pages/profile/favorites'
import {CAffiliateInvites} from '@store/pages/affiliate/invites'
import {CAffiliateMain} from '@store/pages/affiliate/main'
import {CAffiliateReferrals} from '@store/pages/affiliate/referrals'
import {CAffiliateStatistics} from '@store/pages/affiliate/statistics'
import {CAffiliateWidgets} from '@store/pages/affiliate/widgets'
import {CAffiliateWithdraw} from '@store/pages/affiliate/withdraw'
import {CAuthorizationEmailConfirm} from '@store/pages/authorization/email-confirm'
import {CPopularCityDestinationsStore} from '@store/popular-city-destinations'
import {CUserStore} from '@store/user'
import {CHeaderStore} from '@store/header'
import {CAuthorizationStore} from '@store/authorization'
import {CEmailSubscriptionStore} from '@store/email-subscription'
import {CBookingStore} from '@store/pages/booking'
import {CCitySubscriptionStore} from '@store/pages/subscription'
import {CTemporarySession} from '@store/pages/temporary-session'
import {CBookingPaymentModal} from '@store/booking-payment-modal'
import {CPaymentCheck} from '@store/pages/payment/check'
import {CProfileReviewsStore} from '@store/pages/profile/reviews'
import {CProfileBookingsStore} from './pages/profile/bookings'
import {CBookingModal} from './booking-modal'
import {СBookingBalanceStore} from './booking-modal/balance'
import {CBookingPromoCode} from './booking-modal/promo-code'
import {CFormBooking} from './booking-modal/form-booking'
import {CPartnerStore} from './pages/partner'
import {CAboutStore} from './pages/about'

enableStaticRendering(isServer())

const initializationStore = () => ({
  utilities: new CUtilities(),
  search: new CSearch(),
  language: new CLanguage(),
  authorization: new CAuthorizationStore(),
  user: new CUserStore(),
  header: new CHeaderStore(),
  popularCityDestinations: new CPopularCityDestinationsStore(),
  footerActions: new CFooterActions(),
  emailSubscription: new CEmailSubscriptionStore(),
  bookingPaymentModal: new CBookingPaymentModal(),
  bookingModal: {
    modal: new CBookingModal(),
    balance: new СBookingBalanceStore(),
    promoCode: new CBookingPromoCode(),
    formBooking: new CFormBooking(),
  },
  pages: {
    about: new CAboutStore(),
    global: new CGlobalPageStore(),
    authorization: {
      emailConfirm: new CAuthorizationEmailConfirm(),
    },
    booking: new CBookingStore(),
    profile: {
      balance: new CProfileBalanceStore(),
      bookings: new CProfileBookingsStore(),
      password: new CProfilePasswordStore(),
      reviews: new CProfileReviewsStore(),
      settings: new CProfileSettingsStore(),
      favorites: new CProfileFavoritesStore(),
    },
    affiliate: {
      invites: new CAffiliateInvites(),
      main: new CAffiliateMain(),
      referrals: new CAffiliateReferrals(),
      statistics: new CAffiliateStatistics(),
      widgets: new CAffiliateWidgets(),
      withdraw: new CAffiliateWithdraw(),
    },
    partner: new CPartnerStore(),
    payment: {
      check: new CPaymentCheck(),
    },
    subscription: new CCitySubscriptionStore(),
    temporarySession: new CTemporarySession(),
  },
})

let storeInstance: ReturnType<typeof initializationStore>

export function createStore() {
  storeInstance = initializationStore()
}

export const store = () => {
  if (!storeInstance) {
    createStore()
  }

  return storeInstance
}
