import {CGlobalPageStoreSearchMap} from '@store/pages/global/search/map'
import {CGlobalPageStoreSearchHotels} from '@store/pages/global/search/hotels'
import {CGlobalPageStoreSearchFilters} from '@store/pages/global/search/filters'
import {DEFAULT_PAGE} from '@constants/common'
import {CGlobalPageStoreSearchPromotion} from './promotion'

export class CGlobalPageStoreSearch {
  public readonly map: CGlobalPageStoreSearchMap =
    new CGlobalPageStoreSearchMap()

  public readonly hotels: CGlobalPageStoreSearchHotels =
    new CGlobalPageStoreSearchHotels()

  public readonly filters: CGlobalPageStoreSearchFilters =
    new CGlobalPageStoreSearchFilters()

  public readonly promotion: CGlobalPageStoreSearchPromotion =
    new CGlobalPageStoreSearchPromotion()

  public resetFilters(): void {
    this.hotels.resetSort()
    this.hotels.setPage(DEFAULT_PAGE)
    this.filters.resetQueryStates()
  }
}
