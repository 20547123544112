import {addYears, endOfYear} from 'date-fns'
import {ENVIRONMENT} from '../guards/environment'

export const getDefaultMonth = (date: Date) => {
  return new Date(date.getFullYear(), date.getMonth())
}

export const getEndOfTheNextYear = () => {
  const currentYear = new Date()
  const nextYear = addYears(
    currentYear,
    ENVIRONMENT.SEARCH_CALENDAR_MAX_YEARS_COUNT,
  )

  return endOfYear(nextYear)
}
