import {observer, useLocalObservable} from 'mobx-react'
import {store} from '@store/index'

import styled from '@emotion/styled'
import useTranslation from 'next-translate/useTranslation'
import {Skeleton} from 'antd'
import {Button} from '@/library/components/ui'
import {services} from '@/library/services'

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  border-bottom: 1px solid ${({theme}) => theme.palette.borderTertiary};
  padding-bottom: 20px;
`

const Action = styled(Button)`
  width: 100%;
  padding: 10px 20px;
`

const StyledSkeleton = styled(Skeleton.Button)`
  border-bottom: 1px solid ${({theme}) => theme.palette.borderTertiary};
`

const SkeletonLoader = () => {
  return (
    <ActionsWrapper>
      <StyledSkeleton active block />
      <StyledSkeleton active block />
    </ActionsWrapper>
  )
}

const selectLoading = () => ({
  get loading() {
    return store().pages.global.search.filters.loading
  },
})

export const SearchScenarioFiltersActions = observer(() => {
  const {t} = useTranslation('global.search')
  const {isStartSearch} = store().search

  const {loading} = useLocalObservable(selectLoading)

  const handleClickPromotion = () => {
    services.pages.global.scenario.search.promotion.startSearchPromotionCity()
  }

  const handleClickBestOffers = () => {
    services.pages.global.scenario.search.promotion.startSearchBestOffers()
  }

  if (loading) {
    return <SkeletonLoader />
  }

  return (
    <ActionsWrapper>
      <Action disabled={isStartSearch} onClick={handleClickPromotion}>
        {t('filters.actions.promotion.label')}
      </Action>
      <Action disabled={isStartSearch} onClick={handleClickBestOffers}>
        {t('filters.actions.best_offers.label')}
      </Action>
    </ActionsWrapper>
  )
})
