import styled from '@emotion/styled'
import {type FC, type ReactNode, useRef, useState} from 'react'
import {Icon} from '@ui/icon'
import {api} from '@api/index'
import {isBoolean} from '@utils/guards/types'
import {css} from '@emotion/react'
import {services} from '@services/index'
import {Tooltip} from '@ui/tooltip'
import {observer} from 'mobx-react'
import {store} from '@store/index'
import useTranslation from 'next-translate/useTranslation'
import {SHOW_UNAUTHORIZED_TOOLTIP_LIFETIME} from '@constants/common'

const Wrapper = styled.div<{$isFavorite: boolean}>`
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 2;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;

  border-radius: 100%;
  background-color: ${({theme}) => theme.palette.backgroundQuaternary};

  ${({theme, $isFavorite}) =>
    $isFavorite &&
    css`
      svg {
        color: ${theme.palette.textAccentPrimary};
      }
    `}
`

interface Props {
  className?: string
  hotelId: number
  parentIsFavorite: boolean
  size?: number
  children?: ReactNode
}

export const FavoriteHeart: FC<Props> = observer(
  ({className, hotelId, parentIsFavorite, size = 24, children}) => {
    const [isFavorite, setIsFavorite] = useState(parentIsFavorite)
    const {t} = useTranslation('common')
    const {isAuthorized} = store().user
    const [isOpenTooltip, setIsOpenTooltip] = useState(false)
    const timeoutRef = useRef<NodeJS.Timeout | null>(null)

    const showTooltip = () => {
      setIsOpenTooltip(true)

      timeoutRef.current = setTimeout(() => {
        setIsOpenTooltip(false)
        timeoutRef.current = null
      }, SHOW_UNAUTHORIZED_TOOLTIP_LIFETIME)
    }

    const handleLike = () => {
      showTooltip()

      api.hotel
        .addFavoriteHotel({
          variables: {
            hotelId,
          },
        })
        .then((result) => {
          if (isBoolean(result)) {
            setIsFavorite(result)
            services.common.analytics.pushAddHotelToFavourite(hotelId)
          }
        })
    }

    const handleDislike = () => {
      showTooltip()

      api.hotel
        .removeFavoriteHotel({
          variables: {
            hotelId,
          },
        })
        .then((result) => {
          if (isBoolean(result)) {
            setIsFavorite(false)
          }
        })
    }

    return (
      <Tooltip
        trigger="click"
        title={isAuthorized ? '' : t('only_for_authorized_users')}
        open={!isAuthorized && isOpenTooltip}
      >
        <Wrapper
          className={className}
          onClick={isFavorite ? handleDislike : handleLike}
          $isFavorite={isFavorite}
        >
          <Icon
            name={isFavorite ? 'like_fill' : 'like'}
            width={size}
            height={size}
          />
          {children}
        </Wrapper>
      </Tooltip>
    )
  },
)
