import {makeAutoObservable} from 'mobx'

export class CFooterActionsHelpPage {
  constructor() {
    makeAutoObservable(this)
  }

  private _isOpen = false

  public get isOpen(): boolean {
    return this._isOpen
  }

  public setIsOpen(isOpen: boolean): void {
    this._isOpen = isOpen
  }
}
