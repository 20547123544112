import {makeAutoObservable} from 'mobx'
import {ENVIRONMENT} from '@utils/guards/environment'

export default class CChildren {
  public readonly maxChildren: number = ENVIRONMENT.MAX_SEARCH_INPUT_CHILDREN

  public readonly maxChildrenAge: number =
    ENVIRONMENT.MAX_SEARCH_INPUT_CHILDREN_AGE

  constructor() {
    makeAutoObservable(this)
  }

  private _children: number[] = []

  public get children(): number[] {
    return this._children
  }

  public setChildren(children: number[]): void {
    this._children = children
  }

  public addChild(id: number): void {
    if (this.children.length >= this.maxChildren) {
      return
    }

    this._children.push(id)
  }

  public removeChild(id: number): void {
    this._children = this.children.toSpliced(id, 1)
  }

  public clearChildren(): void {
    this._children = []
  }
}
