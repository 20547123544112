import styled from '@emotion/styled'
import {SearchInputGuestsPopoverChildren} from '@components/features/search-input/desktop/ui/guests/popover/children'
import {SearchInputGuestsPopoverAdults} from '@components/features/search-input/desktop/ui/guests/popover/adults'
import type {FC, HTMLAttributes} from 'react'

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: fit-content;
  border: 1px solid ${({theme}) => theme.palette.borderTertiary};
  border-radius: 10px;
  background-color: ${({theme}) => theme.palette.backgroundQuaternary};
  z-index: 9;
  margin-top: 12px;
  box-shadow: 0px 4px 6px 0px ${({theme}) => theme.palette.boxShadowPrimary};
  opacity: 0;
  animation: popover 0.5s forwards;
  padding: 20px;
`

type Props = HTMLAttributes<HTMLDivElement>

export const SearchInputGuestsPopover: FC<Props> = ({...properties}) => {
  return (
    <Wrapper {...properties}>
      <SearchInputGuestsPopoverAdults />
      <SearchInputGuestsPopoverChildren />
    </Wrapper>
  )
}
