import styled from '@emotion/styled'
import Link from 'next/link'
import {Text} from '@/library/components/ui'
import type {FC} from 'react'
import {MEDIA_DEVICE_QUERY} from '@/assets/theme/media'
import type {PopularCityDestinations} from '../lib/types'

const ContentWrapper = styled(Link)`
  display: flex;
  justify-content: end;
  align-content: center;
  flex-direction: column;
  min-width: 100%;
  height: 100%;
  padding: 20px 20px;
  cursor: pointer;
`

const CountryName = styled(Text)`
  text-shadow: ${({theme}) => theme.palette.textPrimary} 1px 0 15px;
  color: ${({theme}) => theme.palette.textQuaternary};

  @media ${MEDIA_DEVICE_QUERY.LAPTOP} {
    font-size: 16px;
  }
`

const CityName = styled(Text)`
  text-shadow: ${({theme}) => theme.palette.textPrimary} 1px 0 15px;
  color: ${({theme}) => theme.palette.textQuaternary};
`

interface CityDestinationProps {
  cityDestination: NonNullable<PopularCityDestinations>[number]
}

export const CityDestination: FC<CityDestinationProps> = ({
  cityDestination,
}) => {
  return (
    <ContentWrapper href={cityDestination.city.seoUrl.url}>
      <CityName size="S">{cityDestination.city.region.country?.name}</CityName>
      <CountryName size="boldM">{cityDestination.city.name}</CountryName>
    </ContentWrapper>
  )
}
