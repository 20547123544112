import {MEDIA_DEVICE_QUERY} from '@/assets/theme/media'
import {Button, Select, Text} from '@/library/components/ui'
import {Checkbox} from '@/library/components/ui/checkbox'
import {services} from '@/library/services'
import {store} from '@/library/store'
import styled from '@emotion/styled'
import {observer} from 'mobx-react'
import {useEffect, useMemo, type FC} from 'react'
import useTranslation from 'next-translate/useTranslation'
import {useFormContext} from 'react-hook-form'
import {update} from 'ramda'
import {useIsRedirecting} from '@/library/hooks/use-redirecting'
import {HorizontalDivider} from '@/library/components/ui/divider'
import {buildArrivalTimeOptions, getInitialArrivalTime} from './lib/helpers'
import {InputsGroup} from './ui/inputs-group'
import {BOOKING_MOBILE_SIZE} from '../../lib/constants'

const Wrapper = styled.div`
  @media ${MEDIA_DEVICE_QUERY.LAPTOP_MEDIUM} {
    padding: 0 20px;
  }

  @media ${BOOKING_MOBILE_SIZE} {
    padding: 0 10px;
  }
`

const Title = styled(Text)`
  color: ${({theme}) => theme.palette.textPrimary};
`

const LoginEncouragement = styled(Button)`
  margin-top: 6px;
  font-weight: 400;
`

const FormWrapper = styled.div`
  margin-top: 28px;
`

const GuestCheckbox = styled(Checkbox)`
  margin-top: 16px;

  & > .box {
    border-radius: 6px;
    min-height: 18px;
    min-width: 18px;
  }

  & > .label {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    color: ${({theme}) => theme.palette.textPrimary};
  }
`

const ArrivalTimeWrapper = styled.div`
  margin-top: 28px;
`

const ArrivalTimeSelect = styled(Select)`
  margin-top: 16px;
  width: calc(50% - 10px);

  & > .list-item {
    max-height: 180px;
  }

  & > .input-wrapper {
    box-shadow: unset;
  }

  @media ${BOOKING_MOBILE_SIZE} {
    width: 100%;
  }
`

const AllGuestsWrapper = styled.div`
  margin-top: 16px;
`

interface BookingFormProps {
  className?: string
}

export const BookingForm: FC<BookingFormProps> = observer(({className}) => {
  const {t} = useTranslation('global.hotel')
  const {t: countriesT} = useTranslation('countries')
  const {data} = store().pages.global.hotel
  const {arrivalTime, showAllGuests, isLoading} = store().bookingModal.modal
  const isRedirecting = useIsRedirecting()
  const {isAuthorized} = store().user

  const {guests} = store().search

  const initialSearchState = useMemo(
    () => ({
      adults: guests.adults.amount,
      children: guests.children.children,
    }),
    [],
  )

  const showAllGuestsCheckbox =
    initialSearchState.adults > 1 || initialSearchState.children.length > 0

  const initialArrivalTime = getInitialArrivalTime(data?.policies.policyCheckin)

  useEffect(() => {
    services.pages.global.scenario.hotel.booking.bookingState.setArrivalTime(
      initialArrivalTime,
    )
  }, [])

  const {setValue, getValues, trigger} = useFormContext()

  useEffect(() => {
    if (isAuthorized) {
      const formValues = getValues()
      const mainFormGuest =
        services.pages.global.scenario.hotel.booking.setMainGuest(countriesT)
      const updatedGuests = update(0, mainFormGuest, formValues.guests)
      setValue('guests', updatedGuests, {shouldValidate: true})
      trigger()
    }
  }, [isAuthorized])

  return (
    <Wrapper className={className}>
      <Title size="semiBoldS">{t('booking_modal.guests_data')}</Title>
      {!isAuthorized && (
        <LoginEncouragement
          onClick={services.common.authorization.handleClickLogin}
          textSize="default"
          variant="text"
          disabled={isLoading || isRedirecting}
        >
          {t('booking_modal.login_speedup')}
        </LoginEncouragement>
      )}
      <FormWrapper>
        <InputsGroup isFirst index={0} />
        {showAllGuestsCheckbox && (
          <GuestCheckbox
            value={showAllGuests}
            disabled={isLoading || isRedirecting}
            setValue={(flag) =>
              services.pages.global.scenario.hotel.booking.bookingState.setShowAllGuests(
                flag,
              )
            }
          >
            {t('booking_modal.all_guests_data')}
          </GuestCheckbox>
        )}
        {showAllGuests && (
          <AllGuestsWrapper>
            {Array.from({length: initialSearchState.adults - 1}).map(
              (_, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <InputsGroup key={`guest-${index}`} index={index + 1} />
              ),
            )}
            {initialSearchState.children.map((_, index) => (
              <InputsGroup
                // eslint-disable-next-line react/no-array-index-key
                key={`kid-${index}`}
                isKid
                index={initialSearchState.adults + index}
              />
            ))}
          </AllGuestsWrapper>
        )}
        <ArrivalTimeWrapper>
          <ArrivalTimeSelect
            options={buildArrivalTimeOptions(data?.policies.policyCheckin)}
            disabled={isLoading || isRedirecting}
            label={t('booking_modal.enter_arrival_time')}
            setValue={(option) =>
              services.pages.global.scenario.hotel.booking.bookingState.setArrivalTime(
                option,
              )
            }
            value={arrivalTime || initialArrivalTime}
          />
        </ArrivalTimeWrapper>
      </FormWrapper>
    </Wrapper>
  )
})
