import {makeAutoObservable} from 'mobx'
import type {UploadFile} from 'antd/es/upload/interface'
import type {HotelReviewRatingInput} from '@api/gql/generate-types'

export class CProfileReviewsEditorReview {
  constructor() {
    makeAutoObservable(this)
  }

  private _error = ''

  public get error() {
    return this._error
  }

  public setError(error: string): void {
    this._error = error
  }

  private _images: UploadFile[] = []

  public get images(): UploadFile[] {
    return this._images
  }

  public setImages(images: UploadFile[]): void {
    this._images = images
  }

  private _actualRatingTypes: ActualHotelRatingTypes | null = null

  public get actualRatingTypes(): ActualHotelRatingTypes | null {
    return this._actualRatingTypes
  }

  public setActualRatingTypes(types: ActualHotelRatingTypes | null) {
    this._actualRatingTypes = types
  }

  private _isLoading = false

  public get isLoading(): boolean {
    return this._isLoading
  }

  public setIsLoading(flag: boolean): void {
    this._isLoading = flag
  }

  private _positive = ''

  public get positive(): string {
    return this._positive
  }

  public setPositive(text: string): void {
    this._positive = text
  }

  private _negative = ''

  public get negative(): string {
    return this._negative
  }

  public setNegative(text: string): void {
    this._negative = text
  }

  private _ratingInputs: HotelReviewRatingInput[] | null = null

  public get ratingInputs(): HotelReviewRatingInput[] | null {
    return this._ratingInputs
  }

  public setRatingInputs(ratingInputs: HotelReviewRatingInput[] | null): void {
    this._ratingInputs = ratingInputs
  }

  public addRatingInput(input: HotelReviewRatingInput): void {
    if (!this._ratingInputs) {
      this._ratingInputs = [input]
      return
    }

    const newInputs = [...this._ratingInputs, input]
    this.setRatingInputs(newInputs)
  }

  public setRatingValueById(
    id: HotelReviewRatingInput['hotelRatingTypeId'],
    rating: number,
  ) {
    const item = this.getRatingInput(id)

    if (item) {
      item.rating = rating
    }
  }

  public removeRatingInput(
    id: HotelReviewRatingInput['hotelRatingTypeId'],
  ): void {
    const newInputs = this._ratingInputs?.filter(
      (input) => input.hotelRatingTypeId !== id,
    )
    if (newInputs) {
      this.setRatingInputs(newInputs)
    }
  }

  public getRatingInput(id: HotelReviewRatingInput['hotelRatingTypeId']) {
    return this._ratingInputs?.find((input) => input.hotelRatingTypeId === id)
  }

  public setImageError(id: string, text: string) {
    if (!this._images) {
      return
    }

    this._images = this._images.map((image) => {
      if (image.uid === id) {
        return {
          ...image,
          status: 'error',
          response: text,
        }
      }

      return image
    })
  }

  public isValidInputs(ratingLength: number): boolean {
    const isExistActualTypes =
      this._actualRatingTypes && this._actualRatingTypes.length > 0

    if (!this._ratingInputs && isExistActualTypes) {
      return false
    }

    if (isExistActualTypes && this._ratingInputs?.length !== ratingLength) {
      return false
    }

    if (!this._negative) {
      return false
    }

    if (!this._positive) {
      return false
    }

    if (this._images) {
      return !this._images.some(
        (image) => image.status === 'error' || image.status === 'uploading',
      )
    }

    return true
  }

  public reset() {
    this.setIsLoading(false)
    this.setNegative('')
    this.setPositive('')
    this.setImages([])
    this.setError('')
    this.setRatingInputs(null)
    this.setActualRatingTypes(null)
  }
}
