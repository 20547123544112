import {BookingFormFields} from '@/library/api/gql/generate-types'
import {Input} from '@/library/components/ui/input'
import {useDebounce} from '@/library/hooks/use-debounce'
import {services} from '@/library/services'
import {store} from '@/library/store'
import {observer} from 'mobx-react'
import useTranslation from 'next-translate/useTranslation'
import phone from 'phone'
import {path} from 'ramda'
import {useEffect, type ChangeEvent, type FC} from 'react'
import {useFormContext} from 'react-hook-form'
import {useIsRedirecting} from '@/library/hooks/use-redirecting'
import {addPlus} from '@utils/phone-numbers'
import {getInputDebounceConfig} from './lib/helpers'

interface PhoneInputProps {
  index: number
  id: string
}

export const PhoneInput: FC<PhoneInputProps> = observer(({index, id}) => {
  const {t} = useTranslation('global.hotel')
  const {guestsData, isLoading} = store().bookingModal.modal
  const isRedirecting = useIsRedirecting()

  const sendPhoneAnalytics = useDebounce(
    ...getInputDebounceConfig(BookingFormFields.Phone, index),
  )

  const {
    register,
    formState: {errors},
    setValue,
  } = useFormContext()

  const value = guestsData?.[index].phone

  const name = `guests.${index}.phone`
  const error = path<string>(['guests', `${index}`, 'phone', 'message'], errors)

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    // eslint-disable-next-line no-param-reassign
    event.target.value = addPlus(event.target.value)

    register(name).onChange(event)

    const {value} = event.target
    services.pages.global.scenario.hotel.booking.bookingState.updateGuestsData(
      index,
      'phone',
      value,
    )
    if (value && phone(value).isValid) {
      sendPhoneAnalytics(value)
    }
  }

  useEffect(() => {
    if (value) {
      setValue(name, value)
    }
  }, [])

  return (
    <Input
      type="tel"
      key={id}
      disabled={isLoading || isRedirecting}
      placeholder={t('booking_modal.phone')}
      label={t('booking_modal.phone')}
      error={error}
      {...register(name)}
      onChange={handleChange}
      value={value || ''}
    />
  )
})
