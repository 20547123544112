import {ENVIRONMENT} from '@/library/utils/guards/environment'
import {createUrl, objectToQuerySearch} from '@/library/utils/http/url'
import {INITIAL_MAP_ZOOM} from '@/slices/booking/lib/constants'

export class MapNavigationService {
  private googleApiVersion = 1

  buildYandexLink = (lng: number, lat: number) => {
    const searchParameters = objectToQuerySearch({
      'whatshere[point]': `${lng},${lat}`,
      'whatshere[zoom]': INITIAL_MAP_ZOOM,
    })

    return createUrl(
      '',
      ENVIRONMENT.YANDEX_NAVIGATION,
      searchParameters,
    ).toString()
  }

  buildTwoGisLink = (lng: number, lat: number) => {
    return `${ENVIRONMENT.TWOGIS_NAVIGATION}${lng},${lat}`
  }

  buildGoogleLink = (lng: number, lat: number) => {
    const searchParameters = objectToQuerySearch({
      api: this.googleApiVersion,
      query: `${lat},${lng}`,
    })

    return createUrl(
      '',
      ENVIRONMENT.GOOGLE_NAVIGATION,
      searchParameters,
    ).toString()
  }
}
