import {MEDIA_DEVICE_QUERY} from '@/assets/theme/media'
import {Button, CircularLoader, Link, Text} from '@/library/components/ui'
import {useDeviceDetection} from '@/library/hooks/use-device-detection'
import {useIsRedirecting} from '@/library/hooks/use-redirecting'
import {services} from '@/library/services'
import {store} from '@/library/store'
import {Routes} from '@/types/enums/routes'
import {css} from '@emotion/react'
import styled from '@emotion/styled'
import {observer} from 'mobx-react'
import useTranslation from 'next-translate/useTranslation'
import {useRouter} from 'next/router'
import {type FC, type MouseEvent, useEffect, useRef} from 'react'
import {useFormContext} from 'react-hook-form'
import {LongPolling} from '@/library/utils/long-polling'
import {BOOKING_MOBILE_SIZE} from '../../lib/constants'

const Wrapper = styled.div`
  @media ${MEDIA_DEVICE_QUERY.LAPTOP_MEDIUM} {
    padding: 0 20px;
  }

  @media ${BOOKING_MOBILE_SIZE} {
    padding: 0 10px;
  }
`

const Submit = styled(Button)<{isTouch$?: boolean}>`
  text-transform: uppercase;
  width: calc(50% - 10px);

  &:hover {
    background-color: ${({theme}) => theme.palette.backgroundPositive};
  }

  &:disabled:hover {
    background-color: ${({theme}) => theme.palette.backgroundTertiary};
  }

  ${({isTouch$}) =>
    isTouch$ &&
    css`
      width: 100%;
    `};

  @media ${MEDIA_DEVICE_QUERY.LAPTOP_MEDIUM} {
    width: calc(50% - 10px);
  }

  @media ${BOOKING_MOBILE_SIZE} {
    width: 100%;
  }
`

const CorporateTitle = styled(Text)`
  margin-top: 28px;
  color: ${({theme}) => theme.palette.textPrimary};
`

const CorporateText = styled(Text)<{isTouch$?: boolean}>`
  margin-top: 12px;
  color: ${({theme}) => theme.palette.textSecondary};
  max-width: 600px;

  ${({isTouch$}) =>
    isTouch$ &&
    css`
      max-width: 375px;
      width: 100%;
      margin-bottom: 20px;
    `};

  @media ${BOOKING_MOBILE_SIZE} {
    max-width: 100%;
  }
`

const CorporateLink = styled(Link)`
  text-decoration: underline;
  color: ${({theme}) => theme.palette.textAccentPrimary};
`

const FormError = styled(Text)`
  margin-top: 5px;
  max-width: 400px;
  color: ${({theme}) => theme.palette.textAccentPrimary};
`

interface BookingSubmitProps {
  className?: string
}

export const BookingSubmit: FC<BookingSubmitProps> = observer(({className}) => {
  const {t} = useTranslation('global.hotel')
  const router = useRouter()
  const {origin} = store().utilities
  const {isLoading, formError} = store().bookingModal.modal
  const {status} = store().bookingModal.formBooking
  const isTouch = useDeviceDetection('touch')
  const isRedirecting = useIsRedirecting()
  const longPollingReference = useRef<LongPolling | null>(null)

  const {
    formState: {isValid},
  } = useFormContext()

  useEffect(() => {
    const longPolling = longPollingReference.current
    if (status && !longPolling) {
      longPollingReference.current = new LongPolling({
        duration: 3000,
        takeWhile:
          services.pages.global.scenario.hotel.booking.formBooking.takeWhile,
        mergeMap:
          services.pages.global.scenario.hotel.booking.formBooking.mergeMap,
        finalize: () =>
          services.pages.global.scenario.hotel.booking.formBooking.finalize(
            router,
          ),
        abortController: new AbortController(),
      })
      longPollingReference.current?.start()
    }

    return () => {
      const longPolling = longPollingReference.current

      if (longPolling) {
        longPolling.stop()
        longPolling.subscribe?.unsubscribe()
        longPollingReference.current = null
      }
    }
  }, [status])

  const handleSubmit = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()

    if (!origin) {
      services.pages.global.scenario.hotel.booking.bookingState.setFormError(
        t('booking_modal.errors.try_again'),
      )
      return
    }

    services.pages.global.scenario.hotel.booking.formBooking.formBooking(
      t,
      origin,
      router,
    )
  }

  return (
    <Wrapper className={className}>
      <Submit
        disabled={!isValid || isLoading || isRedirecting}
        type="submit"
        onClick={handleSubmit}
        isTouch$={isTouch}
        paddingSize="M"
      >
        {isLoading ? (
          <CircularLoader size={32} />
        ) : (
          t('booking_modal.start_booking_modal_button')
        )}
      </Submit>
      {formError ? <FormError size="XS">{formError}</FormError> : null}
      <CorporateTitle size="semiBoldS">
        {t('booking_modal.corporate_title')}
      </CorporateTitle>
      <CorporateText isTouch$={isTouch} size="XS">
        {t('booking_modal.corporate_text_part_1')}
        <CorporateLink href={Routes.SUPPORT} target="_blank">
          {t('booking_modal.corporate_text_part_2')}
        </CorporateLink>
      </CorporateText>
    </Wrapper>
  )
})
