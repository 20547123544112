import type {FieldError} from 'react-hook-form'
import {store} from '@store/index'
import type {FC} from 'react'
import {observer} from 'mobx-react'
import useTranslation from 'next-translate/useTranslation'
import {useLocalObservable} from 'mobx-react-lite'
import {Input} from '@ui/input'

type Props = {
  error?: FieldError
}

const selectFirstName = () => ({
  get firstName() {
    return store().pages.profile.settings.firstName
  },
})

export const FirstNameInput: FC<Props> = observer(({error}) => {
  const {t} = useTranslation('profile.settings')
  const {firstName} = useLocalObservable(selectFirstName)

  return (
    <Input
      label={t('inputs.firstname')}
      name="firstname"
      type="text"
      error={error}
      value={firstName}
      onChange={({target}) => {
        store().pages.profile.settings.setFirstName(target.value)
      }}
      placeholder={t('inputs.firstname')}
    />
  )
})
