import {type FC, memo, useMemo} from 'react'
import styled from '@emotion/styled'
import {useTheme} from '@emotion/react'
import {HOTEL_SCENARIO_RATE_PAYMENT_TYPE_INFORMATION} from '@slices/global/ui/hotel-scenario/rates/cards/lib/constants'
import useTranslation from 'next-translate/useTranslation'
import {store} from '@store/index'
import {useRouter} from 'next/router'
import {Icon, type IconName} from '@ui/icon'
import {Button, Text} from '@components/ui'
import {getNightsAmount} from '@utils/date/get-nights-amount'
import {Tooltip} from '@ui/tooltip'
import DOMPurify from 'isomorphic-dompurify'
import {services} from '@services/index'
import {format} from 'date-fns'
import {parseBackendDateFormat} from '@utils/date/formatting'
import {DateFormatPattern} from '@/types/enums/date-format-patterns'
import Trans from 'next-translate/Trans'
import {MEDIA_DEVICE_QUERY} from '@assets/theme/media'
import {ENVIRONMENT} from '@/library/utils/guards/environment'

const Chip = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  gap: 10px;

  p {
    font-weight: 500;
  }
`

const ChipIcon = styled.div<{$color: string}>`
  background-color: ${({$color}) => $color};
  padding: 8px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;

  svg {
    color: ${({theme}) => theme.palette.textQuaternary};
  }
`

interface ChipWithIconTextProps {
  iconName: IconName
  text: string
  color: string
}

const ChipWithIconText: FC<ChipWithIconTextProps> = ({
  iconName,
  text,
  color,
}) => {
  return (
    <Chip>
      <ChipIcon $color={color}>
        <Icon name={iconName} width={20} height={20} />
      </ChipIcon>
      <Text size="S" color={color}>
        {text}
      </Text>
    </Chip>
  )
}

const TextWithIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;

  p {
    margin-left: 8px;
  }
`

const TextWithIconInfoWrapper = styled.div`
  cursor: pointer;
  margin-left: 5px;
  border-radius: 100%;
  min-height: 18px;
  min-width: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({theme}) => theme.palette.borderTertiary};

  svg {
    color: ${({theme}) => theme.palette.textSecondary};
  }
`

interface TextWithIcon {
  iconName: IconName
  text: string
  textColor?: string
  informationText?: string
}

const TextWithIcon: FC<TextWithIcon> = ({
  iconName,
  text,
  textColor,
  informationText,
}) => {
  return (
    <TextWithIconWrapper>
      <Icon name={iconName} width={18} height={18} />
      <Text size="XS" color={textColor}>
        {text}
      </Text>
      {informationText && (
        <Tooltip
          trigger="hover"
          title={
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(informationText),
              }}
            />
          }
        >
          <TextWithIconInfoWrapper>
            <Icon name="info" width={10} height={10} />
          </TextWithIconInfoWrapper>
        </Tooltip>
      )}
    </TextWithIconWrapper>
  )
}

const Wrapper = styled.div`
  background-color: ${({theme}) => theme.palette.backgroundTertiaryPrimary};
  height: fit-content;
  margin-top: 20px;
  border-radius: 12px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  border: 1px solid ${({theme}) => theme.palette.borderTertiary};

  @media ${MEDIA_DEVICE_QUERY.MOBILE_VERY_LARGE} {
    flex-direction: column;
    height: auto;
    padding: 16px;
    margin-top: 0;
    margin-bottom: 16px;
  }
`

const RateName = styled(Text)`
  padding-top: 20px;
  text-wrap: wrap;
  max-width: 500px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: wrap;
`

const Information = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const InformationChips = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  @media ${MEDIA_DEVICE_QUERY.MOBILE_MEDIUM} {
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
  }
`

const InformationAboutRate = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`
const InformationAboutRatePayment = styled.div`
  display: flex;
  gap: 16px;
  @media ${MEDIA_DEVICE_QUERY.MOBILE_VERY_LARGE} {
    flex-direction: column;
  }
`

const Actions = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 8px;

  @media ${MEDIA_DEVICE_QUERY.MOBILE_VERY_LARGE} {
    margin-top: 32px;
  }
`

const OpenBookingFormButton = styled(Button)`
  text-transform: uppercase;
  width: 100%;

  &:hover {
    background-color: ${({theme}) => theme.palette.backgroundPositive};
  }
`

const GuestWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 5px;

  p {
    color: ${({theme}) => theme.palette.textSecondary};
  }
`

const ActionsPriceDetailsAmountWrapper = styled.div`
  padding: 8px 12px;
  background-color: ${({theme}) => theme.palette.backgroundTertiarySecondary};
  border-radius: 12px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
`

const ActionsPriceDetailsAmount = styled(Text)`
  .amount,
  .currency {
    font-weight: 600;
    color: ${({theme}) => theme.palette.textAccentPrimary};
    margin-left: 5px;
  }
`

interface Props {
  rateProvider: HotelScenarioProvider
  room: HotelScenarioRoomRate
  rate: HotelScenarioRate
  paymentType: HotelScenarioRatePaymentType
}

export const HotelScenarioRatesCardsItemRoomRate: FC<Props> = memo(
  ({rateProvider, room, rate, paymentType}) => {
    const router = useRouter()
    const theme = useTheme()
    const {t} = useTranslation('global.hotel')
    const {t: countriesT} = useTranslation('countries')
    const {dateFrom, dateTo, guests} = store().search

    const initialSearchState = useMemo(
      () => ({
        dateFrom: dateFrom.value,
        dateTo: dateTo.value,
        adults: guests.adults.amount,
        kids: guests.children.children.length,
      }),
      [],
    )

    const paymentTypeInformation =
      HOTEL_SCENARIO_RATE_PAYMENT_TYPE_INFORMATION[paymentType.type]

    return (
      <Wrapper>
        <Information>
          <InformationChips>
            {rate.allotment <= ENVIRONMENT.MAX_RATE_ALLOTMENT_COUNT && (
              <ChipWithIconText
                iconName="info_2"
                text={t('rates.allotment.label', {
                  count: rate.allotment,
                })}
                color={theme.palette.backgroundAccentPrimary}
              />
            )}
            {paymentType.isBestPrice && (
              <ChipWithIconText
                iconName="best_offer"
                text={t('rates.is_best_price.label')}
                color={theme.palette.textPositive_2}
              />
            )}
          </InformationChips>
          <InformationAboutRate>
            <TextWithIcon
              iconName="food"
              text={rate.meal.title}
              textColor={
                rate.meal.isIncluded
                  ? theme.palette.textPositive
                  : theme.palette.textPrimary
              }
            />
            {paymentType.cancellationPolicy.freeCancellationBefore && (
              <TextWithIcon
                iconName="free_cancellation"
                text={t('rates.free_cancellation_before.title', {
                  date: format(
                    parseBackendDateFormat(
                      paymentType.cancellationPolicy.freeCancellationBefore,
                    ),
                    DateFormatPattern.TABLE,
                  ),
                })}
                textColor={theme.palette.textPositive}
                informationText={
                  paymentType.cancellationPolicy.freeCancellationBeforeTz
                    ? t('rates.free_cancellation_before.in_hotel', {
                        date: format(
                          parseBackendDateFormat(
                            paymentType.cancellationPolicy
                              .freeCancellationBeforeTz,
                          ),
                          DateFormatPattern.DATE_WITH_TIME,
                        ),
                      })
                    : ''
                }
              />
            )}
            <InformationAboutRatePayment>
              <TextWithIcon
                iconName="deposit_2"
                text={t(paymentTypeInformation.label)}
                informationText={t(paymentTypeInformation.tooltipLabel)}
              />
              {!paymentType.cancellationPolicy.freeCancellationBefore && (
                <TextWithIcon
                  iconName="deposit_2"
                  text={t('rates.free_cancellation_before.not_exist')}
                />
              )}
            </InformationAboutRatePayment>
          </InformationAboutRate>
          {!!rate.name && <RateName size="SM">{rate.name}</RateName>}
        </Information>
        <Actions>
          <GuestWrapper>
            {initialSearchState.dateFrom && initialSearchState.dateTo && (
              <Text size="XS">
                {t('rates.guests.night', {
                  count: getNightsAmount(
                    initialSearchState.dateFrom,
                    initialSearchState.dateTo,
                  ),
                })}
              </Text>
            )}
            <Text size="XS">
              {t('rates.guests.adults', {
                count: initialSearchState.adults,
              })}
            </Text>
            {initialSearchState.kids > 0 && (
              <Text size="XS">
                {t('rates.guests.kids', {
                  count: initialSearchState.kids,
                })}
              </Text>
            )}
          </GuestWrapper>
          <OpenBookingFormButton
            onClick={() =>
              services.pages.global.scenario.hotel.booking.startBooking({
                t: countriesT,
                router,
                rateProvider,
                room,
                rate,
                paymentType,
              })
            }
          >
            Забронировать
          </OpenBookingFormButton>
          <ActionsPriceDetailsAmountWrapper>
            {Boolean(paymentType.onlineAmount) && (
              <ActionsPriceDetailsAmount size="S">
                <Trans
                  ns="global.hotel"
                  i18nKey="rates.price_amount.online"
                  components={{
                    Amount: (
                      <span className="amount">{`${paymentType.onlineAmount.toLocaleString()} ${paymentType.currencyCode}`}</span>
                    ),
                  }}
                />
              </ActionsPriceDetailsAmount>
            )}
            {Boolean(paymentType.onSpotAmount) && (
              <ActionsPriceDetailsAmount size="S">
                <Trans
                  ns="global.hotel"
                  i18nKey="rates.price_amount.on_spot"
                  components={{
                    Amount: (
                      <span className="amount">{`${paymentType.onSpotAmount.toLocaleString()} ${paymentType.currencyCode}`}</span>
                    ),
                  }}
                />
              </ActionsPriceDetailsAmount>
            )}
            {paymentType.taxes.map((tax) => (
              <ActionsPriceDetailsAmount size="S" key={tax.name}>
                <span>{`${tax.name}: `}</span>
                <span className="amount">{`${tax.amount.toLocaleString()} ${tax.currency_code}`}</span>
              </ActionsPriceDetailsAmount>
            ))}
          </ActionsPriceDetailsAmountWrapper>
        </Actions>
      </Wrapper>
    )
  },
)

HotelScenarioRatesCardsItemRoomRate.displayName =
  'HotelScenarioRatesCardsItemRoomRate'
