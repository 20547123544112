import type {FC} from 'react'
import useTranslation from 'next-translate/useTranslation'
import Trans from 'next-translate/Trans'
import {EtInclusion} from '@/library/api/gql/generate-types'
import type {OstrovokChildrenMeal} from '../lib/types'
import {InlineAmount, InlineCurrency, InlineText} from '../../typography'
import {formatKidsAgeRange, getCurrency} from '../../../lib/helpers'
import {getInclusion} from '../lib/helpers'

interface ChildrenMealProps {
  childrenMeal: OstrovokChildrenMeal
}

export const ChildrenMeal: FC<ChildrenMealProps> = ({childrenMeal}) => {
  const {t} = useTranslation('global.hotel')
  const {t: currenciesT} = useTranslation('currencies')

  const isInclusionSpecified =
    childrenMeal.inclusion && childrenMeal.inclusion !== EtInclusion.Unspecified

  if (childrenMeal.price && isInclusionSpecified) {
    return (
      <Trans
        i18nKey="rules.et.kids_meal_with_price_and_inclusion"
        ns="global.hotel"
        components={[
          <InlineText
            key={`children_meal_${childrenMeal.ageStart}_${childrenMeal.ageEnd}_range`}
          />,
          <InlineAmount
            key={`children_meal_${childrenMeal.ageStart}_${childrenMeal.ageEnd}_amount`}
          />,
          <InlineCurrency
            key={`children_meal_${childrenMeal.ageStart}_${childrenMeal.ageEnd}_currency`}
          />,
        ]}
        values={{
          ageRange: formatKidsAgeRange(
            t,
            childrenMeal.ageStart,
            childrenMeal.ageEnd,
          ),
          price: childrenMeal.price?.toLocaleString(),
          currency: getCurrency(currenciesT, childrenMeal.currency),
          inclusion: getInclusion(
            t,
            childrenMeal.inclusion || EtInclusion.Unspecified,
          ),
        }}
      />
    )
  }

  if (childrenMeal.price) {
    return (
      <Trans
        i18nKey="rules.et.kids_meal_with_price"
        ns="global.hotel"
        components={[
          <InlineText
            key={`children_meal_${childrenMeal.ageStart}_${childrenMeal.ageEnd}_range`}
          />,
          <InlineAmount
            key={`children_meal_${childrenMeal.ageStart}_${childrenMeal.ageEnd}_amount`}
          />,
          <InlineCurrency
            key={`children_meal_${childrenMeal.ageStart}_${childrenMeal.ageEnd}_currency`}
          />,
        ]}
        values={{
          ageRange: formatKidsAgeRange(
            t,
            childrenMeal.ageStart,
            childrenMeal.ageEnd,
          ),
          price: childrenMeal.price?.toLocaleString(),
          currency: getCurrency(currenciesT, childrenMeal.currency),
        }}
      />
    )
  }

  if (isInclusionSpecified) {
    return (
      <Trans
        i18nKey="rules.et.kids_meal_with_inclusion"
        ns="global.hotel"
        components={[
          <InlineText
            key={`children_meal_${childrenMeal.ageStart}_${childrenMeal.ageEnd}_range`}
          />,
        ]}
        values={{
          ageRange: formatKidsAgeRange(
            t,
            childrenMeal.ageStart,
            childrenMeal.ageEnd,
          ),
          inclusion: getInclusion(
            t,
            childrenMeal.inclusion || EtInclusion.Unspecified,
          ),
        }}
      />
    )
  }

  return (
    <Trans
      i18nKey="rules.et.kids_meal"
      ns="global.hotel"
      components={[
        <InlineText
          key={`children_meal_${childrenMeal.ageStart}_${childrenMeal.ageEnd}_range`}
        />,
      ]}
      values={{
        ageRange: formatKidsAgeRange(
          t,
          childrenMeal.ageStart,
          childrenMeal.ageEnd,
        ),
      }}
    />
  )
}
