export const getNearestWeekend = (input: Date) => {
  const date = new Date(+input)

  if (date.getDay() < 5 && date.getDay() > 0) {
    date.setDate(date.getDate() + 5 - date.getDay())
  } else if (date.getDay() === 5) {
    date.setDate(date.getDate() + 1)
  } else {
    date.setDate(date.getDate() + (date.getDay() === 6 ? 6 : 5))
  }

  return {
    startDate: new Date(date),
    endDate: new Date(date.setDate(date.getDate() + 1)),
  }
}
