import {type FC, useEffect, useRef, useState} from 'react'
import {useCarousel} from '@ui/carousel'
import styled from '@emotion/styled'
import {ImageWithSkeleton} from '@ui/image-with-skeleton'
import {NO_HOTEL_IMAGE_SOURCE} from '@constants/hotel'
import {css} from '@emotion/react'
import {TRANSITION_STYLES} from '@constants/css'
import type {Images} from '@/types/common'
import type {HotelImage} from '@/library/api/gql/generate-types'

const Wrapper = styled.div`
  margin-top: 12px;
  position: relative;
`

const StyledCarouselContent = styled.div`
  height: 83px;
  width: 100%;
  display: flex;
  overflow-x: auto;
  padding-bottom: 10px;
`

const CarouselImageWrapper = styled.div`
  height: 100%;
  position: relative;

  .ant-skeleton-image {
    max-height: 100%;
  }

  .image {
    border-radius: 6px;
  }
`

const StyledCarouselItem = styled.div<{$isActive: boolean}>`
  min-width: 100px;
  height: 100%;
  margin-right: 12px;
  border-radius: 6px;
  border: 2px solid transparent;
  transition: border ${TRANSITION_STYLES};
  cursor: pointer;

  ${({theme, $isActive}) =>
    $isActive &&
    css`
      border: 2px solid ${theme.palette.borderAccentPrimary};
    `};
`

const WHEEL_PADDING_VALUE = 50

interface Props {
  images: Images | HotelImage[]
}

export const CarouselImageMini: FC<Props> = ({images}) => {
  const {api} = useCarousel()

  const [current, setCurrent] = useState(1)
  const activeRef = useRef<HTMLDivElement | null>(null)
  const wrapperRef = useRef<HTMLDivElement | null>(null)

  const handleClick = (index: number) => {
    api?.scrollTo(index)
  }

  useEffect(() => {
    if (activeRef.current && current > 1) {
      activeRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      })
    }
  }, [current])

  useEffect(() => {
    const handleScroll = function (this: HTMLDivElement, event: WheelEvent) {
      event.preventDefault()

      const {scrollLeft} = this

      // eslint-disable-next-line react/no-this-in-sfc
      this.scrollLeft =
        event.deltaY > 0
          ? scrollLeft + -WHEEL_PADDING_VALUE
          : scrollLeft + WHEEL_PADDING_VALUE
    }

    const carouselContent = wrapperRef.current
    if (carouselContent) {
      /**
       * Добавлена для того, что бы человек мог мышкой скроллить по горизонтали
       * Сделано через addEventLister, потому что без passive: true - нельзя сделать preventDefault, что бы остановить скролл всей страницы
       */
      carouselContent.addEventListener('wheel', handleScroll, {passive: false})
    }

    return () => {
      if (carouselContent) {
        carouselContent.removeEventListener('wheel', handleScroll)
      }
    }
  }, [])

  useEffect(() => {
    const onSelect = () => {
      if (api) {
        setCurrent(api.selectedScrollSnap() + 1)
      }
    }

    if (api) {
      setCurrent(api.selectedScrollSnap() + 1)

      api.on('select', onSelect)
    }

    return () => {
      api?.off('select', onSelect)
    }
  }, [api])

  return (
    <Wrapper>
      <StyledCarouselContent ref={wrapperRef}>
        {images?.map((image, index) => {
          const isActive = index === current - 1
          const imageAlt = 'alt' in image && image.alt

          return (
            <StyledCarouselItem
              key={image.id}
              $isActive={isActive}
              onClick={() => handleClick(index)}
              ref={isActive ? activeRef : undefined}
            >
              <CarouselImageWrapper>
                <ImageWithSkeleton
                  src={image.url || NO_HOTEL_IMAGE_SOURCE}
                  fill
                  alt={imageAlt || ''}
                  priority
                />
              </CarouselImageWrapper>
            </StyledCarouselItem>
          )
        })}
      </StyledCarouselContent>
    </Wrapper>
  )
}
