import {store} from '@store/index'
import {ServiceGlobalPageScenario} from '@services/pages/global/scenario'
import type {CGlobalPageStore} from '@store/pages/global'
import {GlobalHydrateService} from '@services/common/global-hydrate'
import type {DestinationSelectPlace} from '@store/search/destination'
import type {GlobalPageProperties} from '@slices/global'

type HydrationInitialData = Required<GlobalPageProperties>['hydrationData']

export class GlobalPageService extends GlobalHydrateService<HydrationInitialData> {
  private get state(): CGlobalPageStore {
    return store().pages.global
  }

  public readonly scenario: ServiceGlobalPageScenario =
    new ServiceGlobalPageScenario()

  public hydrate(initialData: HydrationInitialData) {
    if (initialData.info) {
      this.state.setInfo(initialData.info)
      const place = this.parsePlace(initialData.info, initialData.hotel)
      if (place) {
        store().search.destination.setSelectPlace(place)
        store().search.destination.setInputValue(place.name)
      }
    }
    if (initialData.seoRates) {
      this.state.setSeoRates(initialData.seoRates)
    }
    if (initialData.hotel) {
      this.state.hotel.setData(initialData.hotel)
    }

    if (initialData.hotelReviews) {
      this.state.hotel.reviews.setData(initialData.hotelReviews.data)
      this.state.hotel.reviews.setPaginatorInfo(
        initialData.hotelReviews.paginatorInfo,
      )
    }
    if (initialData.staticPageContent) {
      this.state.static.setData(initialData.staticPageContent)
    }
    if (initialData.staticPagePathList) {
      this.state.static.setPathList(initialData.staticPagePathList)
    }
    if (initialData.query) {
      this.scenario.parseQuery(initialData.query)
    }
    if (initialData.similarHotels) {
      this.state.hotel.setSimilarHotels(initialData.similarHotels)
    }
    super.hydrate(initialData)
  }

  public parsePlace(
    info: NonNullable<HydrationInitialData['info']>,
    hotel: HydrationInitialData['hotel'],
  ): DestinationSelectPlace {
    const country = info.urlsChain?.country
    const region = info.urlsChain?.region
    const city = info.urlsChain?.city

    if (hotel && hotel.name && hotel.navSeoUrls?.hotel) {
      return {
        name: hotel.name,
        url: hotel.navSeoUrls?.hotel,
      }
    }

    if (city && city.name && city.id && city.url) {
      return {
        name: city.name,
        url: city.url,
      }
    }

    if (region && region.name && region.id && region.url) {
      return {
        name: region.name,
        url: region.url,
      }
    }

    if (country && country.name && country.id && country.url) {
      return {
        name: country.name,
        url: country.url,
      }
    }

    return null
  }
}
