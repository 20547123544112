import {Text} from '@/library/components/ui'
import styled from '@emotion/styled'
import {MOBILE_LAYOUT_BREAKPOINT} from '../../lib/constants'

export const LinkListWrapper = styled.nav`
  display: flex;
  flex-direction: column;
`

export const LinkList = styled.ul`
  display: flex;
  flex-direction: column;
  row-gap: 15px;

  margin-right: 20px;

  @media (max-width: ${MOBILE_LAYOUT_BREAKPOINT}px) {
    margin-right: 0;
    padding: 15px 0;
  }
`

export const LinkListItem = styled.li``

export const LinkListTitle = styled(Text)`
  display: flex;
  align-items: center;
  margin-bottom: 25px;

  @media (max-width: ${MOBILE_LAYOUT_BREAKPOINT}px) {
    margin-bottom: 15px;
  }
`
