import {makeAutoObservable} from 'mobx'
import type {MapPoints} from '@ui/yandex-map'

const initialState = {
  zoom: 10,
  center: [54.7344226837155, 20.507248878479] as [number, number],
  bounds: [
    [54.826377868652, 20.39786529541],
    [54.642467498779, 20.616632461548],
  ],
}

type MapCenterLocation = [lat: number, lng: number]

export class CGlobalPageStoreHotelMap {
  constructor() {
    makeAutoObservable(this)
  }

  private _loading = false

  public get loading(): boolean {
    return this._loading
  }

  private _zoom = initialState.zoom

  public get zoom(): number {
    return this._zoom
  }

  private _points: MapPoints[] | null = null

  public get points(): MapPoints[] | null {
    return this._points
  }

  private _bounds: number[][] = initialState.bounds

  public get bounds(): number[][] {
    return this._bounds
  }

  private _center: MapCenterLocation = initialState.center

  public get center(): MapCenterLocation {
    return this._center
  }

  public setBounds(bounds: number[][]): void {
    this._bounds = bounds
  }

  public setLoading(loading: boolean): void {
    this._loading = loading
  }

  public setZoom(zoom: number): void {
    this._zoom = zoom
  }

  public setPoints(points: MapPoints[] | null): void {
    this._points = points
  }

  public setCenter(center: MapCenterLocation): void {
    this._center = center
  }

  public resetState() {
    this.setLoading(false)
    this.setPoints(null)
  }
}
