export const getNightsAmount = (arriving: Date, departure: Date) => {
  const difference =
    departure.setHours(0, 0, 0, 0) - arriving.setHours(0, 0, 0, 0)

  const seconds = difference / 1000

  const secondsInHour = 3600
  const hoursInDay = 24

  return Math.ceil(seconds / secondsInHour / hoursInDay)
}
