import styled from '@emotion/styled'
import useTranslation from 'next-translate/useTranslation'
import {store} from '@/library/store'
import {observer} from 'mobx-react'
import {useLocalObservable} from 'mobx-react-lite'
import {ENVIRONMENT} from '@/library/utils/guards/environment'
import {services} from '@/library/services'
import {useTheme} from '@emotion/react'
import {Button} from '../../ui'
import {Icon} from '../../ui/icon'

const Wrapper = styled.div<{isAuthorized: boolean}>`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${({isAuthorized}) => (isAuthorized ? 'unset' : 'pointer')};
  background: ${({theme}) => theme.palette.backgroundPromotionHeader};

  span {
    margin: 0 auto;
  }

  svg {
    opacity: 0.8;
  }

  @media (max-width: 900px) {
    justify-content: center;
    flex-direction: column;
    height: fit-content;
    padding: 20px;
    box-sizing: border-box;
  }

  @media (max-width: 550px) {
    padding: 15px 20px;
  }
`

const TextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: fit-content;

  @media (max-width: 1024px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 900px) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
  }
`

const StyledText = styled.p`
  display: inline;
  color: ${({theme}) => theme.palette.backgroundQuaternary};
  text-align: center;

  strong {
    color: ${({theme}) => theme.palette.backgroundQuaternary};
    font-size: 26px;
  }

  font-size: 26px;
  font-weight: 300;
  line-height: 33px;

  @media (max-width: 1024px) {
    font-size: 22px;
    line-height: 25px;
    margin: 0;

    strong {
      color: ${({theme}) => theme.palette.backgroundQuaternary};
      font-size: 22px;
    }
  }
`

const StyledRedirectButton = styled(Button)`
  margin-left: 49px;
  text-transform: uppercase;
  font-weight: 800;

  padding: 15px 20px;

  @media (max-width: 1024px) {
    font-size: 14px;
    padding: 10px;
  }

  @media (max-width: 900px) {
    width: 100%;
    margin-left: 0;
  }
`

const StyledIcon = styled(Icon)`
  margin: auto;

  @media (max-width: 900px) {
    position: absolute;

    width: 50px;
    height: 50px;
  }
`

const StarPromotion = styled(StyledIcon)`
  @media (max-width: 900px) {
    left: 0;
    top: -10px;

    transform: rotate(160deg);
  }
`

const FirePromotion = styled(StyledIcon)`
  @media (max-width: 900px) {
    right: -0;
    bottom: 0;
  }
`

const CalendarPromotion = styled(StyledIcon)`
  @media (max-width: 900px) {
    left: 0;
    top: -10px;

    transform: rotate(160deg);
  }
`

const TicketPromotion = styled(StyledIcon)`
  @media (max-width: 900px) {
    right: 0px;
    bottom: 0;
  }
`

const selectIsAuthorized = () => ({
  get isAuthorized() {
    return store().user.isAuthorized
  },
})

const PromotionHeader = observer(() => {
  const {t} = useTranslation('features.promotion-header')
  const theme = useTheme()

  const {isAuthorized} = useLocalObservable(selectIsAuthorized)

  if (isAuthorized) {
    return (
      <Wrapper isAuthorized={isAuthorized}>
        <CalendarPromotion
          color={theme.palette.textQuaternary}
          width={92}
          height={62}
          name="calendar_promotion"
        />
        <TextWrapper>
          <StyledText>
            {t('authorization.title')}{' '}
            <strong>{ENVIRONMENT.HEADER_PROMO_CODE}</strong>
          </StyledText>
        </TextWrapper>
        <TicketPromotion
          color={theme.palette.textQuaternary}
          width={114}
          height={69}
          name="ticket_promotion"
        />
      </Wrapper>
    )
  }

  return (
    <Wrapper
      isAuthorized={isAuthorized}
      onClick={services.common.authorization.handleClickRegister}
    >
      <StarPromotion
        color={theme.palette.textQuaternary}
        width={96}
        height={70}
        name="star_promotion"
      />
      <TextWrapper>
        <StyledText>
          <strong>{t('not_authorization.title_strong')}</strong>
          {t('not_authorization.title')}
        </StyledText>
        <StyledRedirectButton variant="secondary">
          {t('not_authorization.get_promo_code_button_label')}
        </StyledRedirectButton>
      </TextWrapper>
      <FirePromotion
        color={theme.palette.textQuaternary}
        width={80}
        height={74}
        name="fire_promotion"
      />
    </Wrapper>
  )
})

export default PromotionHeader
