import styled from '@emotion/styled'
import {type Option, Select} from '@ui/select'
import useTranslation from 'next-translate/useTranslation'
import {observer} from 'mobx-react'
import {store} from '@store/index'
import {useCallback} from 'react'
import {MEDIA_DEVICE_QUERY} from '@assets/theme/media'

const Wrapper = styled.div`
  margin-bottom: 20px;
`

const StyledSelect = styled(Select)`
  max-width: 200px;

  @media ${MEDIA_DEVICE_QUERY.TABLET} {
    max-width: unset;
  }
`

const DEFAULT_OPTIONS: Option<string | null>[] = [
  {
    label: 'global.hotel:rates.filters.beds.all_variants',
    value: null,
  },
]

export const HotelScenarioRatesFiltersBeds = observer(() => {
  const {t} = useTranslation('global.hotel')
  const {data, filters} = store().pages.global.hotel.rates

  const getOptions = useCallback((): Option[] => {
    const bedNames = data
      ?.flatMap((provider) => provider.roomRates.flatMap((room) => room.beds))
      .filter(Boolean)
      .map((bed) => bed?.name) as string[]

    if (!bedNames) {
      return DEFAULT_OPTIONS
    }

    const uniqueBedNames = new Set(bedNames)

    // eslint-disable-next-line unicorn/prefer-spread
    const options: Option[] = Array.from(uniqueBedNames).map((name) => ({
      label: name,
      value: name,
    }))

    return [...DEFAULT_OPTIONS, ...options]
  }, [data])

  return (
    <Wrapper>
      <StyledSelect
        label={t('rates.filters.beds.label')}
        value={filters.bedType || DEFAULT_OPTIONS[0]}
        options={getOptions()}
        setValue={(option) => filters.setBedType(option)}
      >
        free-cancellation
      </StyledSelect>
    </Wrapper>
  )
})
