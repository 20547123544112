import {
  type AuthorizationStoreInitialStateType,
  EAuthorizationModalAction,
  EPasswordResetStep,
  type LoginStoreInitialStateType,
  type RegisterStoreInitialStateType,
  type ResetPasswordStoreInitialStateType,
} from './types'

export const AUTHORIZATION_STORE_INITIAL_STATE: AuthorizationStoreInitialStateType =
  {
    isAuthorized: false,
    modalOpen: false,
    modalAction: EAuthorizationModalAction.LOGIN,
    isLoading: false,
  }

export const LOGIN_STORE_INITIAL_STATE: LoginStoreInitialStateType = {
  error: '',
}

export const REGISTER_STORE_INITIAL_STATE: RegisterStoreInitialStateType = {
  error: '',
  isSuccessful: false,
}

export const RESET_PASSWORD_STORE_INITIAL_STATE: ResetPasswordStoreInitialStateType =
  {
    confirmationMessage: '',
    error: '',
    resetPasswordEmail: '',
    step: EPasswordResetStep.EMAIL,
  }
