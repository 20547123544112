import styled from '@emotion/styled'
import {Button} from '@/library/components/ui'
import {Icon} from '@/library/components/ui/icon'
import type {ButtonHTMLAttributes, FC} from 'react'
import {TRANSITION_STYLES} from '@/library/constants/css'

const StyledButton = styled(Button)<{$underline?: boolean}>`
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
  white-space: nowrap;

  svg {
    transition: color ${TRANSITION_STYLES};
    color: ${({theme}) => theme.palette.textAccentPrimary};
  }

  &:hover {
    svg {
      color: ${({theme}) => theme.palette.textAccentSecondary};
    }
  }

  &:disabled {
    svg {
      color: ${({theme}) => theme.palette.backgroundTertiary};
    }
  }

  ${({$underline}) =>
    $underline &&
    `
     text-decoration: underline;
  `}
`

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: string
  className?: string
  $underline?: boolean
  onClick?: () => void
}

export const ButtonOnMap: FC<Props> = ({
  children,
  className,
  $underline = false,
  onClick,
  ...properties
}) => {
  return (
    <StyledButton
      $underline={$underline}
      className={className}
      onClick={onClick}
      variant="text"
      {...properties}
    >
      <Icon name="on_map" width={24} height={24} />
      {children}
    </StyledButton>
  )
}
