export const TRANSITION_STYLES = '0.3s ease-in-out'

export const ANIMATION_STYLES = '0.15s ease-in-out'

export const LOADING_STYLES = `
  opacity: 0.7;
  cursor: not-allowed;

  * {
    pointer-events: none;
  }
`

export const DISABLED_STYLES = `
  opacity: 0.7;
  cursor: not-allowed;
`
