import {useDeviceDetection} from '@hooks/use-device-detection'
import {type FC, useEffect} from 'react'
import {observer} from 'mobx-react'
import {services} from '@/library/services'
import styled from '@emotion/styled'
import {DesktopSearchInput} from './desktop'
import {MobileSearchInput} from './mobile'

const Wrapper = styled.div``

interface Props {
  className?: string
}

export const SearchInput: FC<Props> = observer(({className}) => {
  const isTouch = useDeviceDetection('touch')

  useEffect(() => {
    services.pages.global.scenario.search.promotion.fetchCities()
    services.components.search.loadDefaultDestinations()
    services.components.search.loadSearchHistory()
  }, [])

  if (isTouch) {
    return (
      <Wrapper className={className}>
        <MobileSearchInput />
      </Wrapper>
    )
  }

  return (
    <Wrapper className={className}>
      <DesktopSearchInput />
    </Wrapper>
  )
})
