import {buildSchemaItemType, buildSchemaPaymentType} from '../helpers'

const schemaItemTypes = {
  hotel: buildSchemaItemType('Hotel'),
  address: buildSchemaItemType('PostalAddress'),
  aggregateRating: buildSchemaItemType('AggregateRating'),
  geoCoordinates: buildSchemaItemType('GeoCoordinates'),
  locationFeatureSpecification: buildSchemaItemType(
    'LocationFeatureSpecification',
  ),
  country: buildSchemaItemType('Country'),
  review: buildSchemaItemType('Review'),
  rating: buildSchemaItemType('Rating'),
  true: buildSchemaItemType('True'),
  person: buildSchemaItemType('Person'),
  offer: buildSchemaItemType('Offer'),
  unitPriceSpecification: buildSchemaItemType('UnitPriceSpecification'),
  paymentMethod: buildSchemaItemType('PaymentMethod'),
}

const schemaProps = {
  image: 'image',
  url: 'url',
  name: 'name',
  priceRange: 'priceRange',
  currenciesAccepted: 'currenciesAccepted',
  starRating: 'starRating',
  telephone: 'telephone',
  petsAllowed: 'petsAllowed',
  description: 'description',
  checkinTime: 'checkinTime',
  checkoutTime: 'checkoutTime',

  address: 'address',
  streetAddress: 'streetAddress',
  addressLocality: 'addressLocality',
  addressRegion: 'addressRegion',
  addressCountry: 'addressCountry',

  aggregateRating: 'aggregateRating',
  ratingValue: 'ratingValue',
  bestRating: 'bestRating',
  worstRating: 'worstRating',
  reviewCount: 'reviewCount',
  ratingCount: 'ratingCount',
  datePublished: 'datePublished',

  geo: 'geo',
  latitude: 'latitude',
  longitude: 'longitude',

  amenityFeature: 'amenityFeature',
  value: 'value',

  review: 'review',
  reviewBody: 'reviewBody',
  reviewRating: 'reviewRating',
  author: 'author',

  makesOffer: 'makesOffer',
  priceSpecification: 'priceSpecification',
  price: 'price',
  priceCurrency: 'priceCurrency',
  unitCode: 'unitCode',
  acceptedPaymentMethod: 'acceptedPaymentMethod',
}

const paymentMethods = {
  cash: buildSchemaPaymentType('Cash'),
  masterCard: buildSchemaPaymentType('MasterCard'),
  visa: buildSchemaPaymentType('VISA'),
}

export const schemaPaymentMethods = [
  paymentMethods.cash,
  paymentMethods.masterCard,
  paymentMethods.visa,
]

export const hotelMetaBuilder = {
  props: schemaProps,
  hotelProps: {
    itemScope: true,
    itemType: schemaItemTypes.hotel,
  },
  starRatingProps: {
    itemScope: true,
    itemProp: schemaProps.starRating,
    itemType: schemaItemTypes.rating,
  },
  geoProps: {
    itemScope: true,
    itemProp: schemaProps.geo,
    itemType: schemaItemTypes.geoCoordinates,
  },
  aggregateRatingProps: {
    itemScope: true,
    itemProp: schemaProps.aggregateRating,
    itemType: schemaItemTypes.aggregateRating,
  },
  addressProps: {
    itemScope: true,
    itemProp: schemaProps.address,
    itemType: schemaItemTypes.address,
  },
  amenityFeatureProps: {
    itemScope: true,
    itemProp: schemaProps.amenityFeature,
    itemType: schemaItemTypes.locationFeatureSpecification,
  },
  addressCountryProps: {
    itemScope: true,
    itemProp: schemaProps.addressCountry,
    itemType: schemaItemTypes.country,
  },
  reviewProps: {
    itemScope: true,
    itemProp: schemaProps.review,
    itemType: schemaItemTypes.review,
  },
  reviewRatingProps: {
    itemScope: true,
    itemProp: schemaProps.reviewRating,
    itemType: schemaItemTypes.rating,
  },
  authorProps: {
    itemScope: true,
    itemProp: schemaProps.author,
    itemType: schemaItemTypes.person,
  },
  makesOfferProps: {
    itemScope: true,
    itemProp: schemaProps.makesOffer,
    itemType: schemaItemTypes.offer,
  },
  priceSpecificationProps: {
    itemScope: true,
    itemProp: schemaProps.priceSpecification,
    itemType: schemaItemTypes.unitPriceSpecification,
  },
  paymentMethodProps: {
    itemScope: true,
    itemProp: schemaProps.acceptedPaymentMethod,
    itemType: schemaItemTypes.paymentMethod,
  },

  renderString: (property: keyof typeof schemaProps, content: string) => (
    <meta itemProp={schemaProps[property]} content={content} />
  ),
  renderNumber: (property: keyof typeof schemaProps, content?: number | null) =>
    hotelMetaBuilder.renderString(property, content ? content.toString() : ''),
  renderName: (name: string) => hotelMetaBuilder.renderString('name', name),
  renderImage: (source: string) =>
    hotelMetaBuilder.renderString('image', source),
  renderUrl: (url: string) => hotelMetaBuilder.renderString('url', url),
  renderRatingValue: (ratingValue: number) =>
    hotelMetaBuilder.renderNumber('ratingValue', ratingValue),
  renderLatitude: (latitude?: number | null) =>
    hotelMetaBuilder.renderNumber('latitude', latitude),
  renderLongitude: (longitude?: number | null) =>
    hotelMetaBuilder.renderNumber('longitude', longitude),
  renderStreetAddress: (address: string) =>
    hotelMetaBuilder.renderString('streetAddress', address),
  renderCountry: (country: string) =>
    hotelMetaBuilder.renderString('addressCountry', country),
  renderBestRating: (bestRating: number) =>
    hotelMetaBuilder.renderNumber('bestRating', bestRating),
  renderRatingCount: (ratingCount: number) =>
    hotelMetaBuilder.renderNumber('ratingCount', ratingCount),
  renderCurrenciesAccepted: (currenciesAccepted: string) =>
    hotelMetaBuilder.renderString('currenciesAccepted', currenciesAccepted),
  renderPriceRange: (priceRange: string) =>
    hotelMetaBuilder.renderString('priceRange', priceRange),
  renderReviewCount: (reviewCount: number) =>
    hotelMetaBuilder.renderNumber('reviewCount', reviewCount),
  renderValue: () =>
    hotelMetaBuilder.renderString('value', schemaItemTypes.true),
  renderDescription: (description: string) =>
    hotelMetaBuilder.renderString('description', description),
  renderPetsAllowed: (petsAllowed: string) =>
    hotelMetaBuilder.renderString('petsAllowed', petsAllowed),
  renderAddressLocality: (addressLocality: string) =>
    hotelMetaBuilder.renderString('addressLocality', addressLocality),
  renderAddressRegion: (addressRegion: string) =>
    hotelMetaBuilder.renderString('addressRegion', addressRegion),
  renderReviewBody: (reviewBody: string) =>
    hotelMetaBuilder.renderString('reviewBody', reviewBody),
  renderWorstRating: (worstRating: number) =>
    hotelMetaBuilder.renderNumber('worstRating', worstRating),
  renderDatePublished: (datePublished: string) =>
    hotelMetaBuilder.renderString('datePublished', datePublished),
  renderCheckinTime: (checkinTime: string) =>
    hotelMetaBuilder.renderString('checkinTime', checkinTime),
  renderCheckoutTime: (checkoutTime: string) =>
    hotelMetaBuilder.renderString('checkoutTime', checkoutTime),
  renderPrice: (price: number) => hotelMetaBuilder.renderNumber('price', price),
  renderPriceCurrency: (priceCurrency: string) =>
    hotelMetaBuilder.renderString('priceCurrency', priceCurrency),
  renderUnitCode: (unitCode: string) =>
    hotelMetaBuilder.renderString('unitCode', unitCode),
}
