import {
  type GetHotelRatesMapInitQueryVariables,
  ProviderRatesSearchingTypeEnum,
} from '@api/gql/generate-types'
import type {ClientRatesSearchCreateRequest} from '@api/rest/api-scheme'
import {store} from '@store/index'

export class GlobalPageHotelFetchQueryBuilder {
  public buildHotelRatesMapInitQuery(): GetHotelRatesMapInitQueryVariables['params'] {
    const {searchingType, cityId, countryId, regionId} =
      this.buildQueryOptions()
    const rate = store().pages.global.hotel.rates.ratesSearchParameters

    return {
      searchingType,
      cityIds: cityId ? [cityId] : undefined,
      countryId,
      regionIds: regionId ? [regionId] : undefined,
      rate,
    }
  }

  public buildSearchMapPointsQuery(
    searchId: string,
    numberOfReceived: number,
  ): ClientRatesSearchCreateRequest {
    const rates = store().pages.global.hotel.rates.ratesSearchParameters
    const {regionId, cityId, countryId, searchingType} =
      this.buildQueryOptions()

    return {
      search_id: searchId,
      hotels: {
        region_id: regionId ?? undefined,
        city_id: cityId ?? undefined,
        country_id: countryId ?? undefined,
      },
      rates: rates
        ? {
            price_from: rates.priceFrom ?? undefined,
            price_to: rates.priceTo ?? undefined,
            checkout: rates.checkout ?? undefined,
            checkin: rates.checkin ?? undefined,
            adults: rates.adults ?? undefined,
            children: rates.children ?? undefined,
          }
        : undefined,
      searching_type:
        searchingType.toLowerCase() as ClientRatesSearchCreateRequest['searching_type'],
      number_of_received: numberOfReceived,
    }
  }

  private buildQueryOptions() {
    const {
      pages: {global},
    } = store()
    const {info, hotel} = global

    const {searchingType} = hotel

    const locationParameters = {
      regionId: info?.urlsChain?.region?.id,
      cityId: info?.urlsChain?.city?.id,
      countryId: info?.urlsChain?.country?.id,
    }

    if (searchingType === ProviderRatesSearchingTypeEnum.BestOffers) {
      delete locationParameters.regionId
      delete locationParameters.cityId
      delete locationParameters.countryId
    }

    return {
      searchingType,
      ...locationParameters,
    }
  }
}
