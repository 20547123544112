import styled from '@emotion/styled'
import {Icon} from '@/library/components/ui/icon'
import {Link} from '@/library/components/ui'
import {store} from '@/library/store'
import useTranslation from 'next-translate/useTranslation'
import {useRouter} from 'next/router'

const BackWrapper = styled(Link)`
  display: flex;
  align-items: center;
  margin-left: -8px;
  margin-top: 12px;
  font-size: 14px;
  line-height: 20px;
  color: ${({theme}) => theme.palette.textLightGray};

  svg {
    color: ${({theme}) => theme.palette.textAccentPrimary};
    transform: rotate(90deg);
  }
`

const HotelScenarioComeBack = () => {
  const router = useRouter()
  const {t} = useTranslation('global.hotel')
  const urlChains = store().pages.global.info?.urlsChain

  if (!urlChains) {
    return null
  }

  const {city} = urlChains

  if (!city?.url) {
    return null
  }

  const asPath = router.asPath.split('?')[1]

  const url = `${city.url}${asPath ? `?${asPath}` : ''}`

  if (!url) {
    return null
  }

  return (
    <BackWrapper href={url}>
      <Icon name="arrow_small" width={24} height={24} />
      {t('come_back')}
    </BackWrapper>
  )
}

export default HotelScenarioComeBack
