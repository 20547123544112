import {isNull} from '@utils/guards/types'
import {store} from '@store/index'
import type {ApplicationProperties} from '@/types/ui'

export class GlobalHydrateService<T extends ApplicationProperties> {
  public hydrate(initialData: T) {
    if (isNull(initialData.user) || initialData.user) {
      store().user.setUser(initialData.user)
    }
    if (initialData.utilities?.userAgent) {
      store().utilities.setUserAgent(initialData.utilities.userAgent)
    }

    if (initialData?.utilities?.origin) {
      store().utilities.setOrigin(initialData.utilities.origin)
    }
  }
}
