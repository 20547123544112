import {$gqlClient} from '@api/gql/client'
import {
  AddAffiliateReferralLinkDocument,
  type AddAffiliateReferralLinkMutation,
  type AddAffiliateReferralLinkMutationVariables,
  AffiliateWithdrawBalanceDocument,
  type AffiliateWithdrawBalanceMutation,
  type AffiliateWithdrawBalanceMutationVariables,
  GetAffiliateReferralLinksDocument,
  type GetAffiliateReferralLinksQuery,
  type GetAffiliateReferralLinksQueryVariables,
  GetAffiliateReferralStatisticsDocument,
  type GetAffiliateReferralStatisticsQuery,
  type GetAffiliateReferralStatisticsQueryVariables,
  GetAffiliateRewardsDocument,
  type GetAffiliateRewardsQuery,
  type GetAffiliateRewardsQueryVariables,
  GetAffiliateWidgetsDocument,
  type GetAffiliateWidgetsQuery,
  type GetAffiliateWidgetsQueryVariables,
  GetAffiliateWithdrawBanksDocument,
  type GetAffiliateWithdrawBanksQuery,
  type GetAffiliateWithdrawBanksQueryVariables,
  GetAffiliateWithdrawsDocument,
  type GetAffiliateWithdrawsQuery,
  type GetAffiliateWithdrawsQueryVariables,
  JoinAffiliateDocument,
  type JoinAffiliateMutation,
  type JoinAffiliateMutationVariables,
  RemoveAffiliateReferralLinkDocument,
  type RemoveAffiliateReferralLinkMutation,
  type RemoveAffiliateReferralLinkMutationVariables,
  UpdateAffiliateReferralLinkDocument,
  type UpdateAffiliateReferralLinkMutation,
  type UpdateAffiliateReferralLinkMutationVariables,
  VoteAffiliateWidgetDocument,
  type VoteAffiliateWidgetMutation,
  type VoteAffiliateWidgetMutationVariables,
} from '@api/gql/generate-types'
import {clientHeaders} from '@utils/http/headers'
import {path} from 'ramda'

type GetReferralStatisticsContent = {
  headers?: Headers
  variables: GetAffiliateReferralStatisticsQueryVariables
}

const getReferralStatistics = ({
  variables,
  headers,
}: GetReferralStatisticsContent) =>
  $gqlClient()
    .query<
      GetAffiliateReferralStatisticsQuery,
      GetAffiliateReferralStatisticsQueryVariables
    >({
      query: GetAffiliateReferralStatisticsDocument,
      variables,
      fetchPolicy: 'no-cache',
      context: {
        headers: headers || clientHeaders(),
      },
    })
    .then(
      path<AffiliateReferralStatistic>([
        'data',
        'affiliateReferralLinkStatistics',
      ]),
    )

type GetReferralLinksContext = {
  headers?: Headers
  variables: GetAffiliateReferralLinksQueryVariables
}

const getReferralLinks = ({variables, headers}: GetReferralLinksContext) =>
  $gqlClient()
    .query<
      GetAffiliateReferralLinksQuery,
      GetAffiliateReferralLinksQueryVariables
    >({
      query: GetAffiliateReferralLinksDocument,
      variables,
      fetchPolicy: 'no-cache',
      context: {
        headers: headers || clientHeaders(),
      },
    })
    .then(path<AffiliateReferralLinks>(['data', 'affiliateReferralLinks']))

type GetRewardsContext = {
  headers?: Headers
  variables: GetAffiliateRewardsQueryVariables
}

const getRewards = ({variables, headers}: GetRewardsContext) =>
  $gqlClient()
    .query<GetAffiliateRewardsQuery, GetAffiliateRewardsQueryVariables>({
      query: GetAffiliateRewardsDocument,
      variables,
      fetchPolicy: 'no-cache',
      context: {
        headers: headers || clientHeaders(),
      },
    })
    .then(path<AffiliateRewards>(['data', 'affiliateRewards']))

type RemoveReferralsLink = {
  headers?: Headers
  variables: RemoveAffiliateReferralLinkMutationVariables
}

const removeReferralLink = ({variables, headers}: RemoveReferralsLink) =>
  $gqlClient()
    .query<
      RemoveAffiliateReferralLinkMutation,
      RemoveAffiliateReferralLinkMutationVariables
    >({
      query: RemoveAffiliateReferralLinkDocument,
      variables,
      fetchPolicy: 'no-cache',
      context: {
        headers: headers || clientHeaders(),
      },
    })
    .then(
      path<RemoveAffiliateReferralLinkMutation['deleteReferralLink']>([
        'data',
        'deleteReferralLink',
      ]),
    )

type AddReferralsLink = {
  headers?: Headers
  variables: AddAffiliateReferralLinkMutationVariables
}

const addReferralLink = ({variables, headers}: AddReferralsLink) =>
  $gqlClient()
    .query<
      AddAffiliateReferralLinkMutation,
      AddAffiliateReferralLinkMutationVariables
    >({
      query: AddAffiliateReferralLinkDocument,
      variables,
      fetchPolicy: 'no-cache',
      context: {
        headers: headers || clientHeaders(),
      },
    })
    .then(
      path<AddAffiliateReferralLinkMutation['createReferralLink']>([
        'data',
        'createReferralLink',
      ]),
    )

type UpdateReferralsLink = {
  headers?: Headers
  variables: UpdateAffiliateReferralLinkMutationVariables
}

const updateReferralLink = ({variables, headers}: UpdateReferralsLink) =>
  $gqlClient()
    .query<
      UpdateAffiliateReferralLinkMutation,
      UpdateAffiliateReferralLinkMutationVariables
    >({
      query: UpdateAffiliateReferralLinkDocument,
      variables,
      fetchPolicy: 'no-cache',
      context: {
        headers: headers || clientHeaders(),
      },
    })
    .then(
      path<UpdateAffiliateReferralLinkMutation['updateReferralLink']>([
        'data',
        'updateReferralLink',
      ]),
    )

type GetWidgetsContext = {
  headers?: Headers
  variables: GetAffiliateWidgetsQueryVariables
}

const getWidgets = ({variables, headers}: GetWidgetsContext) =>
  $gqlClient()
    .query<GetAffiliateWidgetsQuery, GetAffiliateWidgetsQueryVariables>({
      query: GetAffiliateWidgetsDocument,
      variables,
      fetchPolicy: 'no-cache',
      context: {
        headers: headers || clientHeaders(),
      },
    })
    .then(path<AffiliateWidgets>(['data', 'affiliateWidgets']))

type VoteWidgetContext = {
  headers?: Headers
  variables: VoteAffiliateWidgetMutationVariables
}

const voteWidget = ({variables, headers}: VoteWidgetContext) =>
  $gqlClient()
    .query<VoteAffiliateWidgetMutation, VoteAffiliateWidgetMutationVariables>({
      query: VoteAffiliateWidgetDocument,
      variables,
      fetchPolicy: 'no-cache',
      context: {
        headers: headers || clientHeaders(),
      },
    })
    .then(
      path<VoteAffiliateWidgetMutation['voteAffiliateWidget']>([
        'data',
        'voteAffiliateWidget',
      ]),
    )

type JoinContext = {
  headers?: Headers
  variables?: JoinAffiliateMutationVariables
}

const join = ({variables, headers}: JoinContext) =>
  $gqlClient()
    .query<JoinAffiliateMutation, JoinAffiliateMutationVariables>({
      query: JoinAffiliateDocument,
      variables,
      fetchPolicy: 'no-cache',
      context: {
        headers: headers || clientHeaders(),
      },
    })
    .then(
      path<JoinAffiliateMutation['joinAffiliateProgram']>([
        'data',
        'joinAffiliateProgram',
      ]),
    )

type GetWithdrawsContext = {
  headers?: Headers
  variables?: GetAffiliateWithdrawsQueryVariables
}

const getWithdraws = ({variables, headers}: GetWithdrawsContext) =>
  $gqlClient()
    .query<GetAffiliateWithdrawsQuery, GetAffiliateWithdrawsQueryVariables>({
      query: GetAffiliateWithdrawsDocument,
      variables,
      fetchPolicy: 'no-cache',
      context: {
        headers: headers || clientHeaders(),
      },
    })
    .then(path(['data'])) as Promise<GetAffiliateWithdrawsQuery>

type WithdrawContext = {
  headers?: Headers
  variables?: AffiliateWithdrawBalanceMutationVariables
}
const withdraw = ({variables, headers}: WithdrawContext) =>
  $gqlClient()
    .mutate<
      AffiliateWithdrawBalanceMutation,
      AffiliateWithdrawBalanceMutationVariables
    >({
      mutation: AffiliateWithdrawBalanceDocument,
      variables,
      fetchPolicy: 'no-cache',
      context: {
        headers: headers || clientHeaders(),
      },
    })
    .then(
      path<AffiliateWithdrawBalanceMutation['withdrawAffiliateBalance']>([
        'data',
        'withdrawAffiliateBalance',
      ]),
    )

type GetWithdrawBanks = {
  headers?: Headers
  variables?: GetAffiliateWithdrawBanksQueryVariables
}

const getWithdrawBanks = ({variables, headers}: GetWithdrawBanks) =>
  $gqlClient()
    .query<
      GetAffiliateWithdrawBanksQuery,
      GetAffiliateWithdrawBanksQueryVariables
    >({
      query: GetAffiliateWithdrawBanksDocument,
      variables,
      fetchPolicy: 'no-cache',
      context: {
        headers: headers || clientHeaders(),
      },
    })
    .then(
      path<GetAffiliateWithdrawBanksQuery['affiliateBanks']>([
        'data',
        'affiliateBanks',
      ]),
    )

export const affiliateApi = {
  join,
  voteWidget,
  withdraw,
  getWithdrawBanks,
  getWithdraws,
  getWidgets,
  getReferralStatistics,
  getRewards,
  getReferralLinks,
  addReferralLink,
  updateReferralLink,
  removeReferralLink,
}
