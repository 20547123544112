import {MobileModalPage, Text, YandexMap} from '@components/ui'
import {observer} from 'mobx-react'
import {store} from '@store/index'
import {useLocalObservable} from 'mobx-react-lite'
import styled from '@emotion/styled'
import {Illustration} from '@/library/components/ui/illustration'
import useTranslation from 'next-translate/useTranslation'
import {useTheme} from '@emotion/react'
import {XyzTransitionGroup} from '@animxyz/react'
import {Portal} from '@/library/components/layouts'
import {Icon} from '@/library/components/ui/icon'
import {
  normalizeNormalBoundsToMap,
  normalizeNormalCoordsToMap,
} from '@utils/map/normalize-coords'
import {useEffect, type ComponentProps, type FC} from 'react'
import {Skeleton} from 'antd'
import {selectMapOptions} from '../../lib/selectors'

const MapTrigger = styled.div`
  position: fixed;
  right: 10px;
  bottom: 100px;

  width: 100px;
  height: 42px;
  background-color: ${({theme}) => theme.palette.backgroundPrimary};
  z-index: 19;
  border-radius: 12px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  cursor: pointer;
`

const StyledXyzTransitionGroup = styled(XyzTransitionGroup)`
  width: 90vw;
  --xyz-duration-default: 0.3s;
`

const StyledMobileModalPageContainer = styled(MobileModalPage.Container)`
  width: 100%;
  max-width: 750px;
  height: 100%;
  right: 0;
  z-index: 35;
`

const StyledMobileModalPageHeader = styled(MobileModalPage.Header)`
  position: absolute;
  top: 20px;
  right: 20px;

  z-index: 36;
  background-color: transparent;
  padding: 0;
  border-bottom: none;
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 34;

  width: 100%;
  height: 100%;

  background-color: ${({theme}) => theme.palette.overlayPrimary};
  opacity: 0.25;
`

const StyledIcon = styled(Icon)`
  border-radius: 12px;
  background-color: ${({theme}) => theme.palette.backgroundAccentPrimary};
  margin-left: 20px;
  width: 35px;
  height: 35px;
`

const TriggerSkeletonWrapper = styled.div`
  position: fixed;
  right: 10px;
  bottom: 100px;

  width: 100px !important;
  height: 42px !important;
  background-color: ${({theme}) => theme.palette.backgroundQuaternary};
  border-radius: 12px;
  overflow: hidden;
  z-index: 19;
`

const TriggerSkeleton = styled(Skeleton.Button)`
  height: 100% !important;
`

interface Props {
  cluster: ComponentProps<typeof YandexMap>['cluster']
  marker: ComponentProps<typeof YandexMap>['marker']
}

export const SearchScenarioMapTouch: FC<Props> = observer(
  ({cluster, marker}) => {
    const {
      zoom,
      center,
      hotelCenter,
      hotelBounds,
      bounds,
      points,
      loading,
      modalOpen,
    } = useLocalObservable(selectMapOptions)

    const {t} = useTranslation('global.search')
    const theme = useTheme()

    useEffect(() => {
      if (!modalOpen) {
        store().pages.global.search.map.setHotelBounds(null)
        store().pages.global.search.map.setHotelCenter(null)
      }
    }, [modalOpen])

    return (
      <Portal>
        <StyledXyzTransitionGroup
          appear
          xyz="fade-0 in-right-100% out-right-100%"
        >
          {modalOpen && (
            <StyledMobileModalPageContainer>
              <StyledMobileModalPageHeader>
                <StyledIcon
                  name="close"
                  width={32}
                  height={32}
                  color={theme.palette.textQuaternary}
                  onClick={() =>
                    store().pages.global.search.map.setModalOpen(false)
                  }
                />
              </StyledMobileModalPageHeader>
              <YandexMap
                loading={loading}
                cluster={cluster}
                marker={marker}
                map={{
                  theme: 'light',
                  mode: 'vector',
                  location: {
                    center: normalizeNormalCoordsToMap(hotelCenter || center),
                    zoom,
                    easing: 'ease-in-out',
                    duration: 500,
                    bounds: normalizeNormalBoundsToMap(hotelBounds || bounds),
                  },
                }}
                points={points || []}
              />
            </StyledMobileModalPageContainer>
          )}
        </StyledXyzTransitionGroup>
        <StyledXyzTransitionGroup appear xyz="fade-0">
          {modalOpen && (
            <Overlay
              onClick={() =>
                store().pages.global.search.map.setModalOpen(false)
              }
            />
          )}
        </StyledXyzTransitionGroup>
        {loading ? (
          <TriggerSkeletonWrapper>
            <TriggerSkeleton active block />
          </TriggerSkeletonWrapper>
        ) : (
          <MapTrigger
            onClick={() =>
              store().pages.global.search.map.setModalOpen(!modalOpen)
            }
          >
            <Text size="boldS" color={theme.palette.textQuaternary}>
              {t('map.trigger_label')}
            </Text>
            <Illustration
              name="map"
              width={25}
              height={25}
              color={theme.palette.textQuaternary}
            />
          </MapTrigger>
        )}
      </Portal>
    )
  },
)
