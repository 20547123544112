import styled from '@emotion/styled'
import useTranslation from 'next-translate/useTranslation'
import {Icon} from '@/library/components/ui/icon'
import {useDeviceDetection} from '@/library/hooks/use-device-detection'
import {useWindowSize} from '@/library/hooks/use-window-size'
import {Link} from '@/library/components/ui'
import {Routes} from '@/types/enums/routes'
import {TRANSITION_STYLES} from '@/library/constants/css'
import {LinkListTitle} from '../link-list'
import {MobileStoreLinks} from '../mobile-store-links'
import {SocialLinks} from '../social-links'
import {MOBILE_LAYOUT_BREAKPOINT} from '../../lib/constants'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledIcon = styled(Icon)`
  transform: rotate(-90deg);
`

const StyledLink = styled(Link)`
  svg {
    transition: color ${TRANSITION_STYLES};
  }

  &:hover {
    svg {
      color: ${({theme}) => theme.palette.textAccentPrimary};
    }
  }
`

export const MobileAppColumn = () => {
  const {t} = useTranslation('features.footer')
  const isMobile = useDeviceDetection('mobile')
  const windowSize = useWindowSize()
  const isMobileLayout = isMobile || windowSize < MOBILE_LAYOUT_BREAKPOINT

  return (
    <Wrapper>
      <StyledLink href={Routes.MOBILE}>
        <LinkListTitle size={isMobileLayout ? 'boldS' : 'boldM'}>
          {t('mobile_application')}
          <StyledIcon name="arrow_small" width={24} height={24} />
        </LinkListTitle>
      </StyledLink>

      <MobileStoreLinks />
      <SocialLinks />
    </Wrapper>
  )
}
