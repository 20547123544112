import {setCookie} from '@utils/cookie'
import {api} from '@api/index'
import {ENVIRONMENT} from '@utils/guards/environment'
import {
  ADMI_AD_MAX_UUID_LENGTH,
  ADMI_AD_MIN_UUID_LENGTH,
} from '@constants/validation'

export class AdmitAdService {
  private readonly UUID_KEY = ENVIRONMENT.ADMIT_AD_UUID_KEY

  private readonly UUID_VISIT_KEY = ENVIRONMENT.ADMIT_AD_UUID_VISIT_KEY

  private readonly UTM_SOURCE = ENVIRONMENT.ADMIT_AD_UTM_SOURCE

  private isValidUUID(value: string) {
    const {length} = value
    return (
      length >= ADMI_AD_MIN_UUID_LENGTH && length <= ADMI_AD_MAX_UUID_LENGTH
    )
  }

  public check() {
    const query = new URLSearchParams(window.location.search)
    const newUUID = query.get(this.UUID_KEY)
    const utmSource = query.get(ENVIRONMENT.UTM_SOURCE_COOKIE_KEY)

    if (!newUUID || !utmSource || utmSource !== this.UTM_SOURCE) {
      return
    }

    if (!this.isValidUUID(newUUID)) {
      return
    }

    api.admitAd
      .createVisit({
        payload: {
          admitad_uid: newUUID,
        },
      })
      .then((response) => {
        if (response) {
          const expiredDate = new Date(response.expired_at)
          setCookie(this.UUID_KEY, newUUID, expiredDate)
          setCookie(this.UUID_VISIT_KEY, response.uuid, expiredDate)
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }
}
