import {formatDuration, intervalToDuration} from 'date-fns'
import {ru} from 'date-fns/locale'
import type {Translate} from 'next-translate'
import type {ReservedPromoCode} from '@/library/api/gql/generate-types'
import {PromoCodeErrorCategories} from './types'
import {errorCategoryToKey} from './constants'

const getErrorText = (errorCategory: PromoCodeErrorCategories) => {
  return errorCategoryToKey[errorCategory]
}

export const getFormattedTime = (seconds: number) => {
  const currentTime = new Date()
  const expirationTime = new Date(currentTime.getTime() + seconds * 1000)

  const duration = intervalToDuration({
    start: currentTime,
    end: expirationTime,
  })
  const formattedTime = formatDuration(duration, {
    delimiter: ', ',
    locale: ru,
  })
  return formattedTime
}

export const getPromoCodeStatusText = (
  t: Translate,
  promoCodeError: PromoCodeErrorCategories | null,
  promoCodeData: ReservedPromoCode | null,
) => {
  if (promoCodeError) {
    return t(getErrorText(promoCodeError))
  }

  if (promoCodeData) {
    return t('booking_modal.good_promo_code')
  }

  return null
}
