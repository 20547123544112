import {type FC, useMemo, useState} from 'react'
import styled from '@emotion/styled'
import {type MapPoints, YANDEX_MAP_DATA_SOURCE_ID} from '@ui/yandex-map'
import {isNumber} from '@utils/guards/types'
import type {LngLat} from '@yandex/ymaps3-types'
import {YMapMarker} from 'ymap3-components'
import useTranslation from 'next-translate/useTranslation'
import {ECurrency} from '@/types/enums/currency'
import {store} from '@store/index'
import {getMapBoundsFromCoords} from '@utils/map/get-bounds-from-coords'
import {getCenterFromBounds} from '@utils/map/get-center-from-bounds'
import {normalizeMapCoordsToNormal} from '@utils/map/normalize-coords'
import {PopoverWithClose} from '@/library/components/ui/popover-with-close'
import {observer, useLocalObservable} from 'mobx-react-lite'
import {equals} from 'ramda'
import type {MapPointData} from '@services/pages/global/polling-points'
import {BalloonContent} from '@components/features'

const Wrapper = styled.div`
  background-color: ${({theme}) => theme.palette.backgroundAccentPrimary};
  border-radius: 8px;
  box-shadow: 0 2px 4px ${({theme}) => theme.palette.boxShadowPrimary};
  box-sizing: border-box;
  color: ${({theme}) => theme.palette.textQuaternary};
  display: inline-block;
  font-size: 12px;
  font-weight: 700;
  height: 24px;
  line-height: 24px;
  padding: 0 8px;
  position: relative;
  top: -5px;
  transform: translate(-50%, -100%);
  white-space: nowrap;
  cursor: pointer;

  &::after {
    background-color: ${({theme}) => theme.palette.backgroundAccentPrimary};
    bottom: 5px;
    box-shadow: 4px 4px 4px ${({theme}) => theme.palette.boxShadowPrimary};
    content: '';
    height: 8px;
    left: 50%;
    position: absolute;
    transform: translate(-50%, 100%) rotate(45deg);
    width: 8px;
  }
`

const StyledPopoverWithClose = styled(PopoverWithClose)`
  padding: 0;
`

const selectMapBounds = () => ({
  get bounds() {
    return store().pages.global.search.map.bounds
  },
})

interface Props {
  coordinates: LngLat
  points: MapPoints[]
}

export const SearchScenarioMapCluster: FC<Props> = observer(
  ({coordinates, points}) => {
    const {t} = useTranslation('global.search')
    const [open, setOpen] = useState(false)
    const {bounds} = useLocalObservable(selectMapBounds)

    const minPrice = useMemo(() => {
      const parsePrices = points
        .filter((item) => isNumber(item.properties?.price))
        .map((item) =>
          isNumber(item.properties?.price) ? item.properties.price : 0,
        )
      return Reflect.apply(
        Math.min,
        null,
        parsePrices.length > 0 ? parsePrices : [0],
      )
    }, [points])

    const pointsData = useMemo(() => {
      const parsedPoints = points.map((point) => ({...point.properties}))

      return parsedPoints as MapPointData[]
    }, [points])

    const handleClick = () => {
      const newBounds = getMapBoundsFromCoords(
        points.map((point) =>
          normalizeMapCoordsToNormal(point.geometry.coordinates),
        ),
      )

      if (!newBounds) {
        return
      }

      // кейс, когда 2 точки находятся в одном месте и приблизить еще невозможно
      if (equals(bounds, newBounds)) {
        setOpen(true)
        return
      }

      const center = getCenterFromBounds(newBounds)
      store().pages.global.search.map.setBounds(newBounds)
      store().pages.global.search.map.setCenter(center)
    }

    return (
      <YMapMarker
        coordinates={coordinates}
        onClick={handleClick}
        source={YANDEX_MAP_DATA_SOURCE_ID}
      >
        <StyledPopoverWithClose
          closeVariant="secondary"
          parentOpen={open}
          parentSetOpen={setOpen}
          content={<BalloonContent.Cluster pointsData={pointsData} />}
        />
        <Wrapper>
          {minPrice > 0 ? (
            <span>
              {t('map.cluster.exist_rates_label', {
                count: points.length,
                amount: minPrice.toLocaleString(),
                currency: ECurrency.RUB,
              })}
            </span>
          ) : (
            <span>{points.length}</span>
          )}
        </Wrapper>
      </YMapMarker>
    )
  },
)
