import {store} from '@/library/store'
import styled from '@emotion/styled'
import {observer, useLocalObservable} from 'mobx-react'
import {type FC, useEffect, useState} from 'react'
import {FormProvider, useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import {
  updateMyUserProfileForm,
  type UpdateMyUserProfileFormData,
} from '@/library/constants/form-validation/update-my-user-profile-form'
import {services} from '@/library/services'
import {SubmitButton} from './submit-button'
import {FirstNameInput} from './inputs/first-name-input'
import {LastNameInput} from './inputs/last-name-input'
import {EmailInput} from './inputs/email-input'
import {PhoneInput} from './inputs/phone-input'
import {SettingsCitizenshipInput} from './inputs/settings-citizenship-input'
import {SettingsSexInput} from './inputs/settings-sex-input'
import {SettingsBirthdayInput} from './inputs/settings-birthday-input'
import {mergeFormValues} from '../lib/helpers'

const Wrapper = styled.div``
const Form = styled.form``

const FieldsWrapper = styled.div`
  display: grid;
  grid-template-columns: 440px 440px;
  gap: 16px;
  margin-bottom: 20px;

  @media (max-width: 1120px) {
    grid-template-columns: 100%;
  }
`

const selectProfile = () => ({
  get formData() {
    return store().pages.profile.settings.formData
  },
})

interface SettingsFormProps {
  className?: string
}

export const SettingsForm: FC<SettingsFormProps> = observer(({className}) => {
  const [timeout, setTimeout] = useState<NodeJS.Timeout | null>(null)
  const {formData} = useLocalObservable(selectProfile)

  const formInitialValues = mergeFormValues(
    updateMyUserProfileForm.initialValues,
    formData,
  )

  const methods = useForm<UpdateMyUserProfileFormData>({
    resolver: yupResolver(updateMyUserProfileForm.schema),
    defaultValues: formInitialValues,
    mode: 'onChange',
  })

  const {
    handleSubmit,
    formState: {errors, isValid},
    watch,
  } = methods

  const onSubmit = handleSubmit(() => {
    services.pages.profile.settings.updateProfile(setTimeout)
  })

  useEffect(() => {
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [])

  useEffect(() => {
    // Очищение ошибки сервера при изменении value инпутов
    const subscription = watch(() =>
      services.pages.profile.settings.cleanServerError(),
    )
    return () => subscription.unsubscribe()
  }, [watch])

  return (
    <Wrapper className={className}>
      <FormProvider {...methods}>
        <Form onSubmit={onSubmit}>
          <FieldsWrapper>
            <FirstNameInput error={errors.firstname} />
            <LastNameInput error={errors.lastname} />
            <EmailInput error={errors.email} />
            <PhoneInput error={errors.phone} />
            <SettingsCitizenshipInput />
          </FieldsWrapper>
          <SettingsSexInput />
          <SettingsBirthdayInput />
          <SubmitButton isValid={isValid} />
        </Form>
      </FormProvider>
    </Wrapper>
  )
})
