import {GlobalHydrateService} from '@services/common/global-hydrate'
import type {PaymentCheckProperties} from '@slices/payment/check/lib/types'
import {store} from '@store/index'
import {PaymentCheckServicePollingStatus} from '@services/pages/payment/check/polling-status'

type HydrationInitialData = Required<PaymentCheckProperties>['hydrationData']

export class PaymentCheckService extends GlobalHydrateService<HydrationInitialData> {
  private get state() {
    return store().pages.payment.check
  }

  public readonly pollingStatus: PaymentCheckServicePollingStatus =
    new PaymentCheckServicePollingStatus()

  public hydrate(initialData: HydrationInitialData) {
    if (initialData.bookingId) {
      this.state.setBookingId(initialData.bookingId)
    }
    if (initialData.transactionId) {
      this.state.setTransactionId(initialData.transactionId)
    }
    if (initialData.bookingAccessToken) {
      this.state.setBookingAccessToken(initialData.bookingAccessToken)
    }
    super.hydrate(initialData)
  }
}
