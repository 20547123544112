import type {FC} from 'react'
import useTranslation from 'next-translate/useTranslation'
import {
  EtDestinationType,
  EtInclusion,
  EtShuttleType,
} from '@/library/api/gql/generate-types'
import Trans from 'next-translate/Trans'
import type {OstrovokTransfer} from '../lib/types'
import {InlineAmount, InlineCurrency, InlineText} from '../../typography'
import {getCurrency} from '../../../lib/helpers'
import {getInclusion, getTransferType, getDestination} from '../lib/helpers'

interface TransferProps {
  transfer: OstrovokTransfer
}

export const Transfer: FC<TransferProps> = ({transfer}) => {
  const {t} = useTranslation('global.hotel')
  const {t: currenciesT} = useTranslation('currencies')

  const isInclusionSpecified =
    transfer.inclusion && transfer.inclusion !== EtInclusion.Unspecified

  if (transfer.price && isInclusionSpecified) {
    return (
      <Trans
        i18nKey="rules.et.transfer_with_price_and_inclusion"
        ns="global.hotel"
        components={[
          <InlineAmount
            key={`transfer_${transfer.destinationType}_${transfer.price}_amount`}
          />,
          <InlineCurrency
            key={`transfer_${transfer.destinationType}_${transfer.price}_currency`}
          />,
          <InlineText
            key={`transfer_${transfer.destinationType}_${transfer.price}`}
          />,
        ]}
        values={{
          destination: getDestination(
            t,
            transfer.destinationType || EtDestinationType.Unspecified,
          ),
          transferType: getTransferType(
            t,
            transfer.shuttleType || EtShuttleType.Unspecified,
          ),
          price: transfer.price?.toLocaleString(),
          currency: getCurrency(currenciesT, transfer.currency),
          inclusion: getInclusion(
            t,
            transfer.inclusion || EtInclusion.Unspecified,
          ),
        }}
      />
    )
  }

  if (transfer.price) {
    return (
      <Trans
        i18nKey="rules.et.transfer_with_price"
        ns="global.hotel"
        components={[
          <InlineAmount
            key={`transfer_${transfer.destinationType}_${transfer.price}_amount`}
          />,
          <InlineCurrency
            key={`transfer_${transfer.destinationType}_${transfer.price}_currency`}
          />,
          <InlineText
            key={`transfer_${transfer.destinationType}_${transfer.price}`}
          />,
        ]}
        values={{
          destination: getDestination(
            t,
            transfer.destinationType || EtDestinationType.Unspecified,
          ),
          transferType: getTransferType(
            t,
            transfer.shuttleType || EtShuttleType.Unspecified,
          ),
          price: transfer.price?.toLocaleString(),
          currency: getCurrency(currenciesT, transfer.currency),
        }}
      />
    )
  }

  if (isInclusionSpecified) {
    return (
      <Trans
        i18nKey="rules.et.transfer_with_inclusion"
        ns="global.hotel"
        components={[
          <InlineText
            key={`transfer_${transfer.destinationType}_${transfer.inclusion}`}
          />,
        ]}
        values={{
          destination: getDestination(
            t,
            transfer.destinationType || EtDestinationType.Unspecified,
          ),
          transferType: getTransferType(
            t,
            transfer.shuttleType || EtShuttleType.Unspecified,
          ),
          inclusion: getInclusion(
            t,
            transfer.inclusion || EtInclusion.Unspecified,
          ),
        }}
      />
    )
  }

  return (
    <Trans
      i18nKey="rules.et.transfer"
      ns="global.hotel"
      components={[<InlineText key={`transfer_${transfer.destinationType}`} />]}
      values={{
        destination: getDestination(
          t,
          transfer.destinationType || EtDestinationType.Unspecified,
        ),
        transferType: getTransferType(
          t,
          transfer.shuttleType || EtShuttleType.Unspecified,
        ),
      }}
    />
  )
}
