import {store} from '@store/index'
import Router from 'next/router'
import {format} from 'date-fns'
import {DateFormatPattern} from '@/types/enums/date-format-patterns'
import {ServiceSearchDestination} from '@services/components/search/destination'
import {ServiceSearchDate} from '@services/components/search/date'
import {services} from '@services/index'
import {ENVIRONMENT} from '@/library/utils/guards/environment'
import {parseURLSearchParametersToObject} from '@utils/query/parse-to-object'
import {api} from '@api/index'
import {DestinationAutocompleteTypeEnum} from '@api/gql/generate-types'

type HandleSearchParameters = {
  isSearchInputHandler?: boolean
}

export class SearchService {
  private get state() {
    return store().search
  }

  public readonly destinationService: ServiceSearchDestination =
    new ServiceSearchDestination()

  public readonly dateService: ServiceSearchDate = new ServiceSearchDate()

  public handleSearch(parameters?: HandleSearchParameters): void {
    if (!this.destinationService.isValidDestination()) {
      this.state.destination.setIsOpen(true)
      return
    }

    if (this.state.guests.adults.amount === 0) {
      this.state.guests.setIsOpen(true)
      return
    }

    if (!this.state.dateFrom.value) {
      this.state.dateFrom.setIsOpen(true)
      return
    }

    if (!this.state.dateTo.value) {
      this.state.dateTo.setIsOpen(true)
      return
    }

    const selectDestinationPlace = this.state.destination.selectPlace

    if (selectDestinationPlace?.url) {
      if (parameters?.isSearchInputHandler) {
        store().pages.global.search.resetFilters()
      }

      const query = this.buildQueryParameters()

      store().search.setIsStartSearch(true)
      store().search.resetActiveInput()
      store().search.setIsOpenMobileMainPage(false)

      if (selectDestinationPlace.url === window.location.pathname) {
        Router.replace(
          {
            query: query.toString(),
            pathname: selectDestinationPlace.url,
          },
          undefined,
          {
            shallow: true,
          },
        )
          .then(() => {
            services.pages.global.scenario.load(
              parseURLSearchParametersToObject(query),
            )
          })
          .finally(() => {
            store().search.setIsStartSearch(false)
          })
        return
      }

      Router.push({
        query: query.toString(),
        pathname: selectDestinationPlace.url,
      }).finally(() => {
        store().search.setIsStartSearch(false)
      })
    }
  }

  public buildQueryParameters() {
    const query = new URLSearchParams()

    const {dateFrom, dateTo, guests} = this.state
    const {
      selectHotelTypes,
      priceFrom,
      priceTo,
      selectRatingFrom,
      selectStars,
    } = store().pages.global.search.filters
    const {page} = store().pages.global.search.hotels

    if (dateFrom.value) {
      query.set('dateFrom', format(dateFrom.value, DateFormatPattern.QUERY))
    }

    if (dateTo.value) {
      query.set('dateTo', format(dateTo.value, DateFormatPattern.QUERY))
    }

    if (guests.adults.amount) {
      query.set('adults', String(guests.adults.amount))
    }

    if (guests.children.children.length > 0) {
      guests.children.children.forEach((child) => {
        query.append('kidsAge', String(child))
      })
    } else {
      query.delete('kidsAge')
    }

    // Акции/лучшие предложения/гео
    query.set('searchingType', store().pages.global.searchingType)

    // Типы отелей
    if (selectHotelTypes.length > 0) {
      selectHotelTypes.forEach((typeId) => {
        query.append('hotelTypes', String(typeId))
      })
    } else {
      query.delete('hotelTypes')
    }

    // оценки
    if (selectRatingFrom) {
      query.set('ratingFrom', String(selectRatingFrom))
    } else {
      query.delete('ratingFrom')
    }

    if (selectStars) {
      selectStars.forEach((stars) => {
        query.append('stars', String(stars))
      })
    } else {
      query.delete('stars')
    }

    const parsedPriceFrom = Math.min(priceFrom, priceTo)
    const parsedPriceTo = Math.max(priceFrom, priceTo)

    // Цена от
    if (parsedPriceFrom > ENVIRONMENT.MIN_PRICE_VALUE) {
      query.set('priceFrom', String(parsedPriceFrom))
    } else {
      query.delete('priceFrom')
    }

    // Цена до
    if (
      parsedPriceTo < ENVIRONMENT.MAX_PRICE_VALUE &&
      parsedPriceTo > ENVIRONMENT.MIN_PRICE_VALUE
    ) {
      query.set('priceTo', String(parsedPriceTo))
    } else {
      query.delete('priceTo')
    }

    if (page > 0) {
      query.set('page', String(page))
    } else {
      query.delete('page')
    }

    return query
  }

  public loadSearchHistory() {
    api.hotel
      .getSearchHistory({})
      .then((result) => {
        if (result) {
          store().search.destination.setSearchHistory(result)
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }

  public loadDefaultDestinations() {
    api.destination
      .getDestinationAutoComplete({
        variables: {
          query: '',
          types: [
            DestinationAutocompleteTypeEnum.City,
            DestinationAutocompleteTypeEnum.Hotel,
          ],
        },
      })
      .then((result) => {
        if (result) {
          this.state.destination.setData(result)
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }
}
