import styled from '@emotion/styled'
import {Button, Text} from '@components/ui'
import {store} from '@store/index'
import useTranslation from 'next-translate/useTranslation'
import {Illustration} from '@ui/illustration'
import {useWindowSize} from '@hooks/use-window-size'
import {MEDIA_DEVICE_QUERY} from '@assets/theme/media'

const Wrapper = styled.div`
  display: flex;
  gap: 5px;
  height: 168px;
  width: 100%;
  border: 1px solid ${({theme}) => theme.palette.borderTertiary};
  border-radius: 12px;

  @media ${MEDIA_DEVICE_QUERY.MOBILE_VERY_LARGE} {
    border: transparent;
    height: fit-content;
    margin-top: 15px;
  }
`

const LeftBox = styled.div`
  width: 300px;
  height: 100%;
  border-right: 1px solid ${({theme}) => theme.palette.borderTertiary};
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${MEDIA_DEVICE_QUERY.MOBILE_VERY_LARGE} {
    border-right: transparent;
    width: fit-content;
  }
`

const RightBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  height: 100%;
  margin-left: 20px;
`

const Title = styled(Text)`
  margin-bottom: 5px;
  font-weight: 500;
`
const SubTitle = styled(Text)`
  margin-bottom: 28px;
`

const StyledResetButton = styled(Button)`
  @media ${MEDIA_DEVICE_QUERY.MOBILE_VERY_LARGE} {
    width: 100%;
  }
`

export const HotelScenarioRatesCardsItemRoomRateNotFound = () => {
  const {t} = useTranslation('global.hotel')
  const windowSize = useWindowSize()
  const isMobile = windowSize <= 680
  const illustrationSize = isMobile ? 50 : 125

  const handleResetFilters = () => {
    store().pages.global.hotel.rates.filters.reset()
  }

  return (
    <>
      <Wrapper>
        <LeftBox>
          <Illustration
            name="not_found_logo"
            width={illustrationSize}
            height={illustrationSize}
          />
        </LeftBox>
        <RightBox>
          <Title size="M">{t('rates.not_found.filters.title')}</Title>
          <SubTitle size="S">{t('rates.not_found.filters.sub_title')}</SubTitle>
          {!isMobile && (
            <StyledResetButton onClick={handleResetFilters}>
              {t('rates.not_found.filters.reset')}
            </StyledResetButton>
          )}
        </RightBox>
      </Wrapper>
      {isMobile && (
        <StyledResetButton onClick={handleResetFilters}>
          {t('rates.not_found.filters.reset')}
        </StyledResetButton>
      )}
    </>
  )
}
