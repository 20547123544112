import {makeAutoObservable} from 'mobx'
import CAdults from '@store/search/guests/adults'
import CChildren from '@store/search/guests/children'

export default class CGuests {
  constructor() {
    makeAutoObservable(this)
  }

  private _isOpen = false

  public get isOpen(): boolean {
    return this._isOpen
  }

  private _adults: CAdults = new CAdults()

  public get adults(): CAdults {
    return this._adults
  }

  private _children: CChildren = new CChildren()

  public get children(): CChildren {
    return this._children
  }

  public get amount(): number {
    return this.children.children.length + this.adults.amount
  }

  public setIsOpen(isOpen: boolean): void {
    this._isOpen = isOpen
  }
}
