import type {
  DynamicGlobalPageProperties,
  ScenarioProperties,
} from '@slices/global/lib/scenarios/index'
import {api} from '@api/index'
import {SearchHotelItemsByPlaceForSeoPrerenderEnum} from '@api/gql/generate-types'

export const getCityHotelsScenario = async (
  headers: Headers,
  info: DynamicGlobalPageProperties['info'],
  cityId: number,
): Promise<ScenarioProperties> => {
  const seoRates = await api.hotel.getSeoHotels({
    headers,
    variables: {
      searchByPlace: SearchHotelItemsByPlaceForSeoPrerenderEnum.City,
      placeId: cityId,
      hotelTypeId: info?.filters?.type?.id,
    },
  })

  return {
    properties: {
      info,
      seoRates,
    },
  }
}
