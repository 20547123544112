import {type FC, useEffect, useRef, useState} from 'react'
import styled from '@emotion/styled'
import {useWindowSize} from '@hooks/use-window-size'

const ProgressBarWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 8px;
  border-radius: 6px;
  overflow: hidden;
  z-index: 1;
  background: ${({theme}) => theme.palette.progressBarBackground};
`

const ProgressRect = styled.div`
  position: absolute;
  height: 6px;
  top: 1px;
  z-index: 1;
  background-color: ${({theme}) => theme.palette.progressRectBackground};
`

const ProgressTracker = styled.div<{progress: number}>`
  position: absolute;
  width: ${({progress}) => `${progress}%`};
  height: 6px;
  background-color: ${({theme}) => theme.palette.backgroundAccentPrimary};
  left: 1px;
  border-radius: 6px 0 0 6px;
  top: 1px;
  opacity: 0.7;
  z-index: 2;
  transition: 0.8s linear;
`
const RECTANGLES_OFFSET = 4
const SPEED = 10

interface ProgressBarProps {
  progress: number
  className?: string
  numberOfRects: number
}

export const ProgressBar: FC<ProgressBarProps> = ({
  progress,
  className,
  numberOfRects,
}) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const rectsReferences = useRef<Array<HTMLDivElement | null>>([])
  const windowSize = useWindowSize()
  const [rectWidth, setRectWidth] = useState(0)

  useEffect(() => {
    const containerWidth = containerRef.current?.clientWidth || 0
    // Ширина прямоугольников определяется с помощью длины всего прогресс бара.
    // Коэффициент 2 учитывает сам прямоугольник и отступ до следующего прямоугольника. Отступ = Ширине
    const totalRectWidth = containerWidth / (2 * numberOfRects)
    setRectWidth(totalRectWidth)
  }, [numberOfRects, windowSize])

  useEffect(() => {
    let animationFrameId: number

    // Использовал JS вместо CSS анимации, так как тут нужно анимировать динамическое количество прямоугольников
    // Каждый прямоугольник начинает движение с некоторой позиции, идёт до конца прогресс бара и потом начинает с
    // самого начала прогресс бара
    const animateRects = (timestamp: number) => {
      if (!containerRef.current) return

      const containerWidth = containerRef.current?.clientWidth || 0

      rectsReferences.current.forEach((rectRef, index) => {
        if (!rectRef) return

        // Пройденное расстояние
        const movement = (timestamp / SPEED) % containerWidth
        // Позицию рассчитываем на основе пройденного расстояния
        const position =
          (movement + (RECTANGLES_OFFSET + index * (rectWidth * 2))) %
          containerWidth

        // eslint-disable-next-line no-param-reassign
        rectRef.style.transform = `translateX(${position}px) skewX(-20deg)`
      })

      animationFrameId = requestAnimationFrame(animateRects)
    }

    animationFrameId = requestAnimationFrame(animateRects)

    return () => cancelAnimationFrame(animationFrameId)
  }, [numberOfRects, rectWidth, windowSize])

  return (
    <ProgressBarWrapper className={className} ref={containerRef}>
      <ProgressTracker progress={progress} />
      {Array.from({length: numberOfRects}, (_, index) => (
        <ProgressRect
          style={{width: rectWidth, marginRight: rectWidth}}
          key={index}
          ref={(element) => {
            rectsReferences.current[index] = element
          }}
        />
      ))}
    </ProgressBarWrapper>
  )
}
