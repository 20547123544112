import {GlobalHydrateService} from '@services/common/global-hydrate'
import {api} from '@api/index'
import {clientHeaders} from '@utils/http/headers'
import type {ChangePasswordMutationVariables} from '@api/gql/generate-types'
import {store} from '@store/index'
import {errorService} from '@services/common/error'
import type {ApplicationProperties} from '@/types/ui'

export class ProfilePasswordService extends GlobalHydrateService<ApplicationProperties> {
  private get state() {
    return store().pages.profile.password
  }

  public changePassword(variables: ChangePasswordMutationVariables) {
    this.state.setLoading(true)

    api.authorization
      .changePassword({
        headers: clientHeaders(),
        variables,
      })
      .then(() => {
        this.state.setIsChangedPassword(true)
      })
      .catch((error) => {
        const errorText = errorService.getFirstErrorText(error)
        this.state.setError(errorText)
      })
      .finally(() => this.state.setLoading(false))
  }
}
