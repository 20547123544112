import {object, string} from 'yup'
import {TEST_EMAIL_LENGTH, TEST_NOT_CIRYLLIC} from '../constants'

export const emailSubscriptionForm = {
  initialValues: {email: ''},
  schema: object().shape({
    email: string()
      .required('yup:required_field')
      .email('yup:not_email')
      .test(TEST_NOT_CIRYLLIC)
      .test(TEST_EMAIL_LENGTH),
  }),
}
