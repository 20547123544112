import styled from '@emotion/styled'
import {css} from '@emotion/react'
import {type FC, type TextareaHTMLAttributes} from 'react'
import useTranslation from 'next-translate/useTranslation'
import {Button, Text} from '@ui/index'

const Wrapper = styled.div<{
  noPadding?: boolean
  noBorder?: boolean
  maxWidth?: boolean
}>`
  position: relative;
  padding: ${({noPadding}) => (noPadding ? '0px' : '20px')};
  border: ${({theme, noBorder}) =>
    noBorder ? 'none' : `1px solid ${theme.palette.borderTertiary}`};
  border-radius: 6px;

  &:focus-within {
    border-color: ${({theme}) => theme.palette.textAccentPrimary};
  }

  ${({maxWidth}) =>
    maxWidth &&
    css`
      width: 100%;
    `}
`

const StyledArea = styled.textarea`
  width: 100%;
  min-height: 100px;
  border: none;
  outline: none;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  cursor: initial;
  resize: none;

  &:disabled {
    background: none;
    cursor: not-allowed;
  }
`

const Counter = styled(Text)`
  color: ${({theme}) => theme.palette.textPrimary};
  position: absolute;
  bottom: 3px;
  right: 13px;
`

const ClearButtonWrapper = styled.div`
  position: absolute;
  bottom: 3px;
  left: 13px;
`

type Props = {
  value: string
  maxCount?: number
  onClear?: () => void
  noPadding?: boolean
  noBorder?: boolean
  maxWidth?: boolean
} & TextareaHTMLAttributes<HTMLTextAreaElement>

export const Textarea: FC<Props> = ({
  value,
  maxCount,
  onClear,
  noPadding,
  noBorder,
  maxWidth,
  ...properties
}) => {
  const {t} = useTranslation()

  return (
    <Wrapper noPadding={noPadding} noBorder={noBorder} maxWidth={maxWidth}>
      <StyledArea value={value} {...properties} />
      {maxCount ? (
        <Counter size="boldXS">
          {value?.length}/{maxCount}
        </Counter>
      ) : null}
      {onClear ? (
        <ClearButtonWrapper>
          <Button
            variant="text"
            onClick={onClear}
            disabled={value.length === 0}
          >
            {t('components:clear')}
          </Button>
        </ClearButtonWrapper>
      ) : null}
    </Wrapper>
  )
}
