import {store} from '@/library/store'

export const selectFiltersOptions = () => ({
  get isApply() {
    return store().pages.global.search.filters.isApply
  },
  get loading() {
    return store().pages.global.search.filters.loading
  },
  get hotelTypes() {
    return store().pages.global.search.filters.hotelTypes
  },
})
