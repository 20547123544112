import styled from '@emotion/styled'
import {observer} from 'mobx-react'
import {store} from '@store/index'
import DOMPurify from 'isomorphic-dompurify'
import useTranslation from 'next-translate/useTranslation'

const Wrapper = styled.div`
  padding: 20px 17px;
  border: 1px solid ${({theme}) => theme.palette.borderTertiary};
  border-radius: 12px;
`

const Title = styled.h2`
  margin-bottom: 27px;
  font-weight: 500;
  color: ${({theme}) => theme.palette.textPrimary};
  font-size: 20px;
`

const DescriptionWrapper = styled.div`
  overflow: hidden;

  * {
    text-wrap: wrap;
  }
`

const Description = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: ${({theme}) => theme.palette.textPrimary};
  word-wrap: break-word;

  p {
    margin-bottom: 10px;
  }
`

export const HotelScenarioAboutHotel = observer(() => {
  const {data} = store().pages.global.hotel
  const {t} = useTranslation('global.hotel')

  return (
    <Wrapper>
      <Title>{t('about_hotel.title')}</Title>
      <DescriptionWrapper>
        <Description
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              data?.description || t('about_hotel.not_exist_description'),
            ),
          }}
        />
      </DescriptionWrapper>
    </Wrapper>
  )
})
