import {type FC, useRef, useState} from 'react'
import useTranslation from 'next-translate/useTranslation'
import {copyService} from '@services/common/copy'
import {COPY_LINK_TOOLTIP_LIFETIME} from '@constants/common'
import {Tooltip} from '@ui/tooltip'
import {Icon} from '@ui/icon'
import {Button} from '@ui/button'

type Props = {
  url: string
}

export const LinkCopy: FC<Props> = ({url}) => {
  const {t} = useTranslation('features.link-copy')
  const [openCopyTooltip, setOpenCopyTooltip] = useState(false)
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  const handleCopy = (url: string) => {
    copyService.copy(url).then(() => {
      setOpenCopyTooltip(true)

      timeoutRef.current = setTimeout(() => {
        setOpenCopyTooltip(false)
        timeoutRef.current = null
      }, COPY_LINK_TOOLTIP_LIFETIME)
    })
  }

  return (
    <Tooltip open={openCopyTooltip} title={t('copy')}>
      <Button variant="icon" onClick={() => handleCopy(url)}>
        <Icon name="copy" width={24} height={24} />
      </Button>
    </Tooltip>
  )
}
