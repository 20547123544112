import {Icon} from '@ui/icon'
import styled from '@emotion/styled'
import {Button} from '@ui/button'
import {TRANSITION_STYLES} from '@/library/constants/css'
import {Popover} from '@/library/components/ui/popover'
import useTranslation from 'next-translate/useTranslation'
import {Link, Text} from '@/library/components/ui'
import {Tooltip} from '@/library/components/ui/tooltip'
import {observer, useLocalObservable} from 'mobx-react'
import {ProfilePaths} from '@/types/enums/routes'
import {selectors} from '../../../store'

const StyledNotificationButton = styled(Button)`
  position: relative;

  svg {
    transition: color ${TRANSITION_STYLES};
  }

  &:hover {
    svg {
      color: ${({theme}) => theme.palette.textAccentPrimary};
    }
  }

  &:before {
    content: attr(data-notification-amount);
    font-family: ${({theme}) => theme.font.secondary};

    position: absolute;
    bottom: 12px;
    left: 11px;

    width: 20px;
    height: 20px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 50%;

    padding: 2px;

    background-color: ${({theme}) => theme.palette.backgroundAccentPrimary};
    color: ${({theme}) => theme.palette.textQuaternary};
    font-size: 14px;
  }

  &[data-notification-amount='0'] {
    &:before {
      display: none;
    }
  }
`

const StyledIcon = styled(Icon)`
  cursor: pointer;
  transition: color ${TRANSITION_STYLES};

  &:hover {
    color: ${({theme}) => theme.palette.textAccentPrimary};
  }
`

const StyledLink = styled(Link)`
  height: 24px;
`

const PopupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  p {
    color: ${({theme}) => theme.palette.textQuaternary};
  }

  &.notification {
    p {
      color: ${({theme}) => theme.palette.textPrimary};
    }
  }
`

export const HeaderIcons = observer(() => {
  const {t} = useTranslation('features.header')

  const {isAuthorized} = useLocalObservable(selectors.selectIsAuthorized)

  if (isAuthorized) {
    return (
      <>
        <StyledLink href={ProfilePaths.BOOKINGS}>
          <StyledIcon name="calendar" width={24} height={24} />
        </StyledLink>
        <StyledLink href={ProfilePaths.FAVORITES}>
          <StyledIcon name="like" width={24} height={24} />
        </StyledLink>
        <Popover
          content={
            <PopupWrapper className="notification">
              <Text size="boldS">{t('notifications.title')}</Text>
              <Text size="S">{t('notifications.subtitle')}</Text>
            </PopupWrapper>
          }
        >
          <StyledNotificationButton variant="text" data-notification-amount="0">
            <StyledIcon name="bell" width={24} height={24} />
          </StyledNotificationButton>
        </Popover>
      </>
    )
  }

  return (
    <>
      <Tooltip
        trigger="click"
        title={
          <PopupWrapper>
            <Text size="XS">{t('links.bookings')}</Text>
            <Text size="XS">{t('links.only_for_authorized_users')}</Text>
          </PopupWrapper>
        }
      >
        <StyledIcon name="calendar" width={24} height={24} />
      </Tooltip>
      <Tooltip
        trigger="click"
        title={
          <PopupWrapper>
            <Text size="XS">{t('links.favorites')}</Text>
            <Text size="XS">{t('links.only_for_authorized_users')}</Text>
          </PopupWrapper>
        }
      >
        <StyledIcon name="like" width={24} height={24} />
      </Tooltip>
      <Popover
        content={
          <PopupWrapper className="notification">
            <Text size="boldS">{t('notifications.title')}</Text>
            <Text size="S">{t('notifications.subtitle')}</Text>
          </PopupWrapper>
        }
      >
        <StyledNotificationButton variant="text" data-notification-amount="0">
          <StyledIcon name="bell" width={24} height={24} />
        </StyledNotificationButton>
      </Popover>
    </>
  )
})
