import {useRouter} from 'next/router'
import {useState, useEffect} from 'react'

export const useIsRedirecting = () => {
  const [isRedirecting, setIsRedirecting] = useState(false)
  const router = useRouter()

  useEffect(() => {
    const handleRouteChangeStart = () => setIsRedirecting(true)
    const handleRouteChangeComplete = () => setIsRedirecting(false)
    const handleRouteChangeError = () => setIsRedirecting(false)

    router.events.on('routeChangeStart', handleRouteChangeStart)
    router.events.on('routeChangeComplete', handleRouteChangeComplete)
    router.events.on('routeChangeError', handleRouteChangeError)

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart)
      router.events.off('routeChangeComplete', handleRouteChangeComplete)
      router.events.off('routeChangeError', handleRouteChangeError)
    }
  }, [router])

  return isRedirecting
}
