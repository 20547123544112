// Получение юзера
import {$gqlClient} from '@api/gql/client'
import {
  GetUserActiveGeneralNewsletterSubscriptionDocument,
  type GetUserActiveGeneralNewsletterSubscriptionQuery,
  type GetUserActiveGeneralNewsletterSubscriptionQueryVariables,
  SubscribeToCityNewsletterDocument,
  type SubscribeToCityNewsletterMutation,
  type SubscribeToCityNewsletterMutationVariables,
  SubscribeToGeneralNewsletterDocument,
  type SubscribeToGeneralNewsletterMutation,
  type SubscribeToGeneralNewsletterMutationVariables,
} from '@api/gql/generate-types'
import {path} from 'ramda'
import {clientHeaders} from '@utils/http/headers'

type SubscribeToGeneralContext = {
  headers?: Headers
  variables: SubscribeToGeneralNewsletterMutationVariables
}

const subscribeToGeneral = ({headers, variables}: SubscribeToGeneralContext) =>
  $gqlClient()
    .mutate<
      SubscribeToGeneralNewsletterMutation,
      SubscribeToGeneralNewsletterMutationVariables
    >({
      mutation: SubscribeToGeneralNewsletterDocument,
      variables,
      fetchPolicy: 'no-cache',
      context: {
        headers: headers || clientHeaders(),
      },
    })
    .then(
      path<
        SubscribeToGeneralNewsletterMutation['subscribeToGeneralNewsletter']
      >(['data', 'subscribeToGeneralNewsletter']),
    )

const getMyGeneralSubscriptions = (headers?: Headers) =>
  $gqlClient()
    .query<
      GetUserActiveGeneralNewsletterSubscriptionQuery,
      GetUserActiveGeneralNewsletterSubscriptionQueryVariables
    >({
      query: GetUserActiveGeneralNewsletterSubscriptionDocument,
      fetchPolicy: 'no-cache',
      context: {
        headers: headers || clientHeaders(),
      },
    })
    .then(
      path<
        GetUserActiveGeneralNewsletterSubscriptionQuery['myActiveGeneralNewsletterSubscription']
      >(['data', 'myActiveGeneralNewsletterSubscription']),
    )

type SubscribeToCityContext = {
  headers?: Headers
  variables: SubscribeToCityNewsletterMutationVariables
}

const subscribeToCity = ({headers, variables}: SubscribeToCityContext) =>
  $gqlClient()
    .mutate<
      SubscribeToCityNewsletterMutation,
      SubscribeToCityNewsletterMutationVariables
    >({
      mutation: SubscribeToCityNewsletterDocument,
      variables,
      fetchPolicy: 'no-cache',
      context: {
        headers: headers || clientHeaders(),
      },
    })
    .then(
      path<SubscribeToCityNewsletterMutation['subscribeToCityNewsletter']>([
        'data',
        'subscribeToCityNewsletter',
      ]),
    )

export const newsletterApi = {
  subscribeToGeneral,
  getMyGeneralSubscriptions,
  subscribeToCity,
}
