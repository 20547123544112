import {ConfirmationStatus} from '@/library/store/authorization/types'

export const LOGIN_ERROR_TEXT: Record<string, string | undefined> = {
  authentication: 'login.incorrect_credentials',
  'rate-limit': 'login.spam_checking',
}

export const COMMON_ERROR_TEXT: Record<string, string> = {
  WENT_WRONG: 'common.went_wrong',
}

export const RESET_PASSWORD_ERROR_TEXT: Record<string, string> = {
  [ConfirmationStatus.WRONG_CODE]: 'password_reset.wrong_code',
}
