import type {NextPageContext} from 'next/dist/shared/lib/utils'
import {userAgentFromString} from 'next/server'
import type {Utilities} from '@/types/utilities'
import {buildOrigin} from '@/library/utils/http/url'
import {ENVIRONMENT} from '@/library/utils/guards/environment'

export class GlobalPropsService {
  public getUserAgent(context: NextPageContext): Utilities['userAgent'] {
    const input = context.req?.headers['user-agent']

    return userAgentFromString(input)
  }

  public getOrigin(context: NextPageContext): Utilities['origin'] {
    const host = context.req?.headers.host
    return host ? buildOrigin(ENVIRONMENT.PROTOCOL, host) : undefined
  }
}
