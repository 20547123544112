import {observer} from 'mobx-react'
import {store} from '@store/index'
import {useLocalObservable} from 'mobx-react-lite'
import {services} from '@services/index'
import {Pagination} from '@components/ui'
import type {ReactNode} from 'react'

const selectPaginatorInfo = () => ({
  get paginatorInfo() {
    return store().pages.global.search.hotels.paginatorInfo
  },
  get currentPage() {
    return store().pages.global.search.hotels.page
  },
})

export const SearchScenarioHotelPagination = observer(() => {
  const {paginatorInfo, currentPage} = useLocalObservable(selectPaginatorInfo)

  if (!paginatorInfo) {
    return null
  }

  const handleOnChange = (nextPage: number) => {
    services.pages.global.scenario.search.hotels.scrollToTop()
    store().pages.global.search.hotels.setPage(nextPage)
    services.components.search.handleSearch()
  }

  const itemRender = (
    page: number,
    type: 'page' | 'prev' | 'next' | 'jump-prev' | 'jump-next',
    element: ReactNode,
  ): ReactNode => {
    if (type === 'page') {
      return null
    }

    return element
  }

  return (
    <Pagination
      current={currentPage}
      total={paginatorInfo.lastPage * 10}
      onChange={handleOnChange}
      hideOnSinglePage
      showSizeChanger={false}
      showLessItems={false}
      showQuickJumper={false}
      itemRender={itemRender}
    />
  )
})
