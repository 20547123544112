import {
  SexEnum,
  type UpdateMyUserProfileMutationVariables,
} from '@/library/api/gql/generate-types'
import {ObjectSchema, object, string} from 'yup'
import {
  TEST_EMAIL_LENGTH,
  TEST_MAX_LENGTH,
  TEST_NOT_CIRYLLIC,
  TEST_PHONE,
  TEST_VALID_NAME,
} from '../constants'
import {MAX_FIRSTNAME_LENGTH, MAX_LASTNAME_LENGTH} from '../../validation'

export interface UpdateMyUserProfileFormData {
  firstname: UpdateMyUserProfileMutationVariables['firstname']
  citizenship?: UpdateMyUserProfileMutationVariables['citizenship']
  lastname?: UpdateMyUserProfileMutationVariables['lastname']
  sex?: UpdateMyUserProfileMutationVariables['sex']
  email?: UpdateMyUserProfileMutationVariables['email']
  phone?: UpdateMyUserProfileMutationVariables['phone']
  birthday?: UpdateMyUserProfileMutationVariables['birthday']
}
export const updateMyUserProfileForm = {
  initialValues: {
    firstname: '',
    citizenship: null,
    lastname: '',
    sex: null,
    email: '',
    phone: '',
    birthday: null,
  } as UpdateMyUserProfileFormData,
  schema: object().shape({
    firstname: string()
      .required('yup:required_field')
      .test(TEST_VALID_NAME)
      .max(MAX_FIRSTNAME_LENGTH, TEST_MAX_LENGTH),
    citizenship: string(),
    lastname: string()
      .required('yup:required_field')
      .test(TEST_VALID_NAME)
      .max(MAX_LASTNAME_LENGTH, TEST_MAX_LENGTH),
    sex: string()
      .oneOf([SexEnum.Male, SexEnum.Female] as const)
      .nullable(),
    email: string()
      .email('yup:incorrect_format')
      .required('yup:required_field')
      .test(TEST_NOT_CIRYLLIC)
      .test(TEST_EMAIL_LENGTH),
    phone: string().required('yup:required_field').test(TEST_PHONE),
    birthday: string().nullable(),
  }) as ObjectSchema<UpdateMyUserProfileFormData>,
}
