import type {FC} from 'react'
import useTranslation from 'next-translate/useTranslation'
import {
  EtInclusion,
  EtPetsType,
  EtPriceUnit,
} from '@/library/api/gql/generate-types'
import Trans from 'next-translate/Trans'
import type {OstrovokPets} from '../lib/types'
import {InlineAmount, InlineCurrency, InlineText} from '../../typography'
import {getCurrency} from '../../../lib/helpers'
import {getInclusion, getPetsType, getPriceUnit} from '../lib/helpers'

interface PetsProps {
  pets: OstrovokPets
}

export const Pets: FC<PetsProps> = ({pets}) => {
  const {t} = useTranslation('global.hotel')
  const {t: currenciesT} = useTranslation('currencies')

  const isInclusionSpecified =
    pets.inclusion && pets.inclusion !== EtInclusion.Unspecified

  if (pets.price && isInclusionSpecified) {
    return (
      <Trans
        i18nKey="rules.et.pets_with_price_and_inclusion"
        ns="global.hotel"
        components={[
          <InlineAmount key={`pets_${pets.petsType}_${pets.price}_amount`} />,
          <InlineCurrency
            key={`pets_${pets.petsType}_${pets.price}_currency`}
          />,
          <InlineText key={`pets_${pets.petsType}_${pets.price}`} />,
        ]}
        values={{
          petType: getPetsType(t, pets.petsType || EtPetsType.Unspecified),
          price: pets.price?.toLocaleString(),
          currency: getCurrency(currenciesT, pets.currency),
          inclusion: getInclusion(t, pets.inclusion || EtInclusion.Unspecified),
          unit: getPriceUnit(t, pets.priceUnit || EtPriceUnit.Unspecified),
        }}
      />
    )
  }

  if (pets.price) {
    return (
      <Trans
        i18nKey="rules.et.pets_with_price"
        ns="global.hotel"
        components={[
          <InlineAmount key={`pets_${pets.petsType}_${pets.price}_amount`} />,
          <InlineCurrency
            key={`pets_${pets.petsType}_${pets.price}_currency`}
          />,
          <InlineText key={`pets_${pets.petsType}_${pets.price}`} />,
        ]}
        values={{
          petType: getPetsType(t, pets.petsType || EtPetsType.Unspecified),
          price: pets.price?.toLocaleString(),
          currency: getCurrency(currenciesT, pets.currency),
          unit: getPriceUnit(t, pets.priceUnit || EtPriceUnit.Unspecified),
        }}
      />
    )
  }

  if (isInclusionSpecified) {
    return (
      <Trans
        i18nKey="rules.et.pets_with_inclusion"
        ns="global.hotel"
        components={[
          <InlineText key={`pets_${pets.petsType}_${pets.inclusion}`} />,
        ]}
        values={{
          petType: getPetsType(t, pets.petsType || EtPetsType.Unspecified),
          inclusion: getInclusion(t, pets.inclusion || EtInclusion.Unspecified),
        }}
      />
    )
  }

  return (
    <Trans
      i18nKey="rules.et.pets"
      ns="global.hotel"
      components={[<InlineText key={`pets_${pets.petsType}`} />]}
      values={{
        petType: getPetsType(t, pets.petsType || EtPetsType.Unspecified),
      }}
    />
  )
}
