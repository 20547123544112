import {type AuthUser, SexEnum} from '@/library/api/gql/generate-types'
import {__, all, has} from 'ramda'
import type {Images} from '@/types/common'
import {isNumber, isString} from '@utils/guards/types'

export const isUser = (value: unknown): value is AuthUser =>
  all(has(__, value), [
    'id',
    'name',
    'email',
    'email_verified',
    'api_token',
    'profile',
    'roles',
  ])

export const isSex = (value: unknown): value is SexEnum => {
  return value === SexEnum.Male || value === SexEnum.Female
}

export const isImage = (image: unknown): image is Images[number] => {
  if (!image) {
    return false
  }

  if (typeof image !== 'object') {
    return false
  }

  if (!('id' in image) || !isNumber(image.id)) {
    return false
  }

  if (!('url' in image) || !isString(image.url)) {
    return false
  }

  return true
}
