import {store} from '@store/index'
import {observer} from 'mobx-react'
import {useLocalObservable} from 'mobx-react-lite'
import {BirthdayInput} from '@/library/components/features'
import styled from '@emotion/styled'
import {
  getBackendDateFormat,
  outputDateFormatter,
} from 'library/utils/date/formatting'
import {parseISO} from 'date-fns'

const StyledBirthdayInput = styled(BirthdayInput)`
  margin-top: 20px;
`

const selectBirthday = () => ({
  get birthday() {
    return store().pages.profile.settings.birthday
  },
})

export const SettingsBirthdayInput = observer(() => {
  const {birthday} = useLocalObservable(selectBirthday)

  const birthdayDate = birthday ? parseISO(birthday) : null
  return (
    <StyledBirthdayInput
      birthday={birthdayDate}
      outputFormatter={outputDateFormatter}
      setBirthday={(birthday) =>
        store().pages.profile.settings.setBirthday(
          getBackendDateFormat(birthday),
        )
      }
    />
  )
})
