import type {ShortFacility} from '@/library/api/gql/generate-types'
import {Icon} from '@/library/components/ui/icon'
import {ImageWithSkeleton} from '@/library/components/ui/image-with-skeleton'
import styled from '@emotion/styled'
import type {FC} from 'react'
import {Text} from '@components/ui'

const Wrapper = styled.div``
const IconWrapper = styled.div``

const FacilitiesItem = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`

const FacilityText = styled(Text)`
  overflow: hidden;
  color: ${({theme}) => theme.palette.textPrimary};
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 180px;
  font-weight: 300;

  @media (max-width: 500px) {
    width: 300px;
  }
  @media (max-width: 380px) {
    width: 240px;
  }
`

const StyledImage = styled(ImageWithSkeleton)`
  .skeleton {
    max-width: 16px;
    max-height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const FacilitiesDefaultIcon = styled(Icon)`
  color: ${({theme}) => theme.palette.textPrimary};
`

type Props = {
  facility: ShortFacility
}

const HotelFacility: FC<Props> = ({facility}) => {
  return (
    <Wrapper key={facility.id}>
      <FacilitiesItem>
        <IconWrapper>
          {facility.iconPath ? (
            <StyledImage
              src={facility.iconPath}
              alt={facility.name}
              width={15}
              height={15}
            />
          ) : (
            <FacilitiesDefaultIcon name="help" width={15} height={15} />
          )}
        </IconWrapper>
        <FacilityText size="S">{facility.name}</FacilityText>
      </FacilitiesItem>
    </Wrapper>
  )
}

export default HotelFacility
