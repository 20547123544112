import styled from '@emotion/styled'
import {useEffect} from 'react'
import {services} from '@services/index'
import {useRouter} from 'next/router'
import {HotelScenarioRatesCards} from 'slices/global/ui/hotel-scenario/rates/cards'
import useTranslation from 'next-translate/useTranslation'
import {BookingModal} from './booking-modal'

const Wrapper = styled.div``

const Title = styled.h2`
  margin-bottom: 27px;
  font-weight: 500;
  font-size: 20px;
  color: ${({theme}) => theme.palette.textPrimary};
`

export const HotelScenarioRates = () => {
  const {t} = useTranslation('global.hotel')
  const router = useRouter()
  const pathname = router.asPath.split('?')[0]

  useEffect(() => {
    services.pages.global.scenario.hotel.load()
  }, [pathname])

  return (
    <Wrapper>
      <Title>{t('rates.title')}</Title>
      <HotelScenarioRatesCards />
      <BookingModal />
    </Wrapper>
  )
}
